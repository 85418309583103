import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-iac-individual-type-graph',
    templateUrl: './iac-individual-type-graph.component.html',
    styleUrls: ['./iac-individual-type-graph.component.scss']
})
export class IacIndividualTypeGraphComponent implements OnChanges {

    @ViewChild('IacIndividualTypeGraph', {static: true}) private chartContainer: ElementRef;

    @Input() marginBottom = true;
    @Input() id: string;
    @Input() score: number;
    @Input() type = 'score'; // can be selector


    constructor() {}

    ngOnChanges() {
        this.createTalentGraph();
    }

    createTalentGraph() {
        let score = !!(this.score !== null && (typeof this.score !== 'undefined')) ? this.score : null;

        let paperHeight = 105;
        if (!this.marginBottom) {
            paperHeight = 80;
        }

        let horz = [65, 130, 195, 260, 325, 390, 455, 520, 585];
        let ratio = .2;
        let bkLineLenght = 650;

        // console.log(bkLineLenght);

        d3.select('#' + this.id).remove();

        // Define "element" to work on
        let element = this.chartContainer.nativeElement;

        let svg = d3.select(element).append('svg')
            .attr('id', this.id)
            .attr('width', bkLineLenght * ratio + 50)
            .attr('height', paperHeight * ratio + 10)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('viewBox', '0 0 ' + bkLineLenght + ' ' + paperHeight);


        let corr = -4;


        // First Background Line

        svg.append('rect')
            .attr('x', 0)
            .attr('y', 40)
            .attr('width', bkLineLenght)
            .attr('height', 20)
            .attr('stroke', 'none')
            .style('fill', 'rgb(85.0%,85.0%,85.0%)');

        let circleColor = 'rgb(85.0%,85.0%,85.0%)';

        if (score !== null) {
            svg.append('circle')
                .attr('cx', horz[score] + corr)
                .attr('cy', 45)
                .attr('r', 32.5).attr('stroke', circleColor)
                .attr('stroke-width', 9)
                .style('fill', 'white');

            svg.append('text')
                .attr('x', horz[score] + corr + 1)
                .attr('y', 61)
                .attr('text-anchor', 'middle')
                .text(this.score)
                .attr('font-size', 46);

        }
    }
}
