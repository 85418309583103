import { Component, OnInit } from '@angular/core';
import {EnvService} from '../../../app/services/env.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
    stats_url: string;
    stats_url_usa: string;

    constructor(private environment: EnvService) {
    }

    ngOnInit() {
        this.stats_url = this.environment.apiBaseUrl + 'scrpts/stats.php';
        this.stats_url_usa = this.environment.apiBaseUrl + 'scrpts/stats.php?usa=1';
    }
}
