import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ComponentList} from 'src/app/shared/classes/components/component_list';
import {StateService} from 'src/app/core/services/state/state.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {UserService} from 'src/app/core/services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-mobile-graphboards-list',
  templateUrl: './mobile-graphboards-list.component.html',
  styleUrls: ['./mobile-graphboards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileGraphboardsListComponent extends ComponentList implements OnInit, OnDestroy {

    analysisTabs = [];
    selectedTab = 2;
    addAnalysis = false;

    public orderedBy;

    lastSearch: string;
    curSubAccounts: any;

    private subscriptions = new Subscription();

    constructor(
        protected router: Router,
        private route: ActivatedRoute,
        public stateService: StateService,
        public user: UserService,
        protected deviceService: DeviceDetectorService,
        public graphBoard: GraphBoardsAnalysisService,
        public graphBoardsList: GraphBoardsListService,
        private changeDetectorRef: ChangeDetectorRef,
        protected translateService: TranslateService
    ) {
        super(stateService, translateService, router, deviceService);
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('rightmatch');
        }
        if (this.stateService.hasCommunication) {
            this.analysisTabs.push('idwmatrix');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('graphboard');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('jobsatisfaction');
        }
        this.orderedBy = this.graphBoardsList.graphBoardState.listColumns.filter(col => col.order !== undefined);

        if (!this.stateService.hasPersonality) {
            if (this.stateService.hasCommunication) {
                this.router.navigate(['/m/analysis/idwmatrix/list']).then(() => {});
            } else {
                this.router.navigate(['/m/people/list']).then(() => {});
            }
        }
        // set the sidebar status
        this.graphBoardsList._opened = !this.smallSize;
    }

    ngOnInit() {
        if (!this.graphBoardsList.archive) {
            this.setLastAddress();
        }
        this.lastSearch = this.graphBoardsList.graphBoardState.search;
        this.graphBoardsList.checkModuleUrl();
        this.graphBoardsList.checkUserGraphBoardDisplayPreferences();

        // check items list without selection
        this.graphBoardsList.checkTotalItems();

        if (this.graphBoard.id) {
            this.graphBoard.setItemToDisplayWithId(this.graphBoard.id, this.specificState());
        }

        this.graphBoardsList.graphBoardState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.graphBoardsList.getGraphBoardsList(true, null, this);

        // this.curSubAccounts = (this.state.listSearchFilters.subAccount) ? this.state.listSearchFilters.subAccount : [];
        // if (typeof this.curSubAccounts === 'string') {
        //     this.curSubAccounts = this.state.listSearchFilters.subAccount.split(',');
        // }
        // if (this.curSubAccounts.length < this.stateService.session.sessionData.structure.subAccounts.length) {
        //     this.curSubAccounts = [];
        //   for (let i = 0; i < this.stateService.session.sessionData.structure.subAccounts.length; i++) {
        //       this.curSubAccounts.push(this.stateService.session.sessionData.structure.subAccounts[i].id);
        //   }
        //   this.state.listSearchFilters.subAccount = this.curSubAccounts.join();
        // }

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.graphBoardsList.getGraphBoardsList(true, null, this);
            }
        ));
    }

    /**
    * Set search parameter on event from shared/main-list/component/mobile/mobile-main-list-search-bar
    * @param event
    */
    setSearchFilter(event: string): void {
        this.lastSearch = event;
        this.graphBoardsList.setSearchFilter(event);
        this.refreshList();
        this.blurSearchElement();
    }

    /**
     * Remove focus from the search input
     */
    blurSearchElement() {
        let activeElement = document.activeElement as HTMLElement;
        activeElement.blur();

        // check the case when the button Search/Clear was clicked
        setTimeout(() => {
            if (document.activeElement.tagName === 'INPUT') {
                activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
            }
        }, 0 );
    }

    switchTab(index) {
        const tabName = this.analysisTabs.find((e, i) => {
            if (i === index) {
                return e;
            } else {
                return;
            }
        });
        this.router.navigate(['/m/analysis/' + tabName + '/list']).then(() => {
            this.selectedTab = index;
        });
    }

    refreshList() {
        this.graphBoardsList.getGraphBoardsList(true, null, this);
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.graphBoardsList.setOrder(event);
        this.refreshList();
    }

    /**
     * Set selected item on event from shared/main-list/component/main-list
     * @param item
     */
    itemSelected(item): void {
        this.closed = false;
        this.graphBoardsList.graphBoardState.lastSideBarStatus = 'reports';
        this.graphBoardsList.itemSelected(item);
    }

    createNewAnalysis() {
        this.addAnalysis = true;
    }

    backToItemsList() {
        this.router.navigate(['m/analysis/graphboard/list', {cancel: true, moduleName: 'analysis'}]).then(() => {
            this.addAnalysis = false;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
