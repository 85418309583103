import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
    @Input() addAnalysis = false;
    @Input() searchValue: string;
    @Output() reloadAction = new EventEmitter();
    @Output() searchAction = new EventEmitter();

    constructor(public router: Router) { }

    ngOnInit() {

    }

    refreshPage() {
        this.reloadAction.emit();
    }

    goToPeople() {
        this.router.navigate(['m/people/']).then(() => {});
    }

    onSearchEvent(event) {
        this.searchAction.emit(event);
    }

}
