import {
    Component, OnInit, Input, Output, EventEmitter, IterableDiffers, ChangeDetectorRef, ChangeDetectionStrategy
} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-jobs-selected-items',
    templateUrl: './jobs-selected-items.component.html',
    styleUrls: ['./jobs-selected-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsSelectedItemsComponent implements OnInit {

    selectedItems: any = [];
    @Input() width = '';
    @Input() archive = false;

    @Output() switchOff = new EventEmitter(); // Put the switch of the filter button off
    @Output() removeElmt = new EventEmitter();
    @Output() actionOnRecords = new EventEmitter();
    @Output() completeAction = new EventEmitter();

    action: any;
    buttonsFolder = '/assets/buttons/';
    actualPage = 1;
    maxPerPage = 30; /** mod this */
    maxPage = 1;

    names: Array<Array<string>> = [];
    iterableDiffer: any;

    informationVisibility = true;

    constructor(private _iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef,
        private stateService: StateService,
        private router: Router) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    act() {
        if (this.action) {
            if (
                this.action === 'create_graphboard' ||
                this.action === 'add_graphboard'
            ) {
                let actionItems = this.action.split('_');
                this.stateService[actionItems[1]].multiAction['action'] = actionItems[0];
                this.stateService[actionItems[1]].multiAction['idType'] = 'profiler';
                for (let item of this.selectedItems) {
                    this.stateService[actionItems[1]].multiAction['ids'].push(item.id);
                }

                this.router.navigate(['/analysis/' + actionItems[1].toLowerCase() + '/list']);
            } else {
                let event = {
                    actionType: this.action,
                    idType: 'profiler',
                    ids: []
                };
                for (let item of this.selectedItems) {
                    event.ids.push(item.id);
                }
                if (this.action === 'subaccount' || this.action === 'department' || this.action === 'transferOtherAcc') {
                    this.completeAction.emit(event);
                } else {
                    this.actionOnRecords.emit(event);
                }
            }
        }
    }

    actualModuleName() {
        let actualModule = 'jobs';
        if (this.archive) {
            let capitalized = actualModule[0].toUpperCase() +
                actualModule.slice(1);
            actualModule = 'archives' + capitalized;
        }
        return actualModule;
    }

    get isSuperAdmin() {
        return !!(this.permissions && this.permissions.roles && this.permissions.roles.includes('ROLE_SUPER_ADMIN'));
    }

    get permissions() {
        return (this.sessionData) ? this.stateService.session.sessionData.permissions : null;
    }

    get sessionData() {
        return (this.stateService && this.stateService.session) ? this.stateService.session.sessionData : null;
    }

    ngOnInit() {
        this.stateService[this.actualModuleName()].multiselectWatch$.subscribe(
            res => {
                if (res) {
                    this.selectedItems = this.stateService[this.actualModuleName()].multiselect;
                    this.updateList();
                }
            }
        );
    }

    updateList(selectedItems = this.selectedItems) {
        this.names = [];

        for (let record of selectedItems) {
            let name = record.jobTitle;
            if (this.names.map((el) => el[0]).indexOf(record.id) === -1) {
                this.names.push([record, name]);
            } else {
                this.names.splice(this.names.indexOf([record.id, name]), 1);
            }
        }
        this.maxPage = Math.ceil(this.names.length / this.maxPerPage);
        this.changeDetectorRef.markForCheck();
    }

    goToNews() {
        this.switchOff.emit(true);
        this.stateService[this.actualModuleName()].multiselect = [];
        this.navigate('setSideBarStatus', {
            sideBarStatus: 'recentActivities'
        });
    }

    removeItem(item) {
        this.stateService[this.actualModuleName()].multiselect.splice(
            this.selectedItems.indexOf(item),
            1
        );
        this.selectedItems = this.stateService[this.actualModuleName()].multiselect;
        this.stateService[this.actualModuleName()].multiselectWatch.next(true);

        this.changeDetectorRef.markForCheck();
    }

    navigate(action, params) {
        this.stateService.navigate(
            this.router,
            'jobs/list',
            action,
            params
        );
    }

    createRange(actualPage) {
        let items: number[] = [];
        let start = (actualPage - 1) * this.maxPerPage;
        let end = actualPage * this.maxPerPage;
        for (let i = start; i < end; i++) {
            items.push(i);
        }
        this.changeDetectorRef.markForCheck();
        return items;
    }

    goToPage(factor) {
        this.actualPage = this.actualPage + factor;
    }

    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

}
