// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h2personalityReport {
  font-size: 15pt;
}

.traitsTitlePersonality {
  text-transform: uppercase;
}

.subTitlesUpperCase {
  text-transform: uppercase;
  margin-bottom: 7px;
}

ul.sublistSpacing li {
  padding: 5px 0px;
}

ul.sublistSpacing {
  margin-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-report-personality/people-report-personality.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".h2personalityReport {\n    font-size: 15pt;\n}\n\n.traitsTitlePersonality {\n    text-transform: uppercase;\n}\n\n.subTitlesUpperCase {\n    text-transform: uppercase;\n    margin-bottom: 7px;\n}\n\nul.sublistSpacing li {\n    padding: 5px 0px;\n}\n\nul.sublistSpacing {\n    margin-bottom: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
