import {Component, Input, OnChanges} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-job-satisfaction-graphics',
  templateUrl: './job-satisfaction-graphics.component.html',
  styleUrls: ['./job-satisfaction-graphics.component.scss']
})
export class JobSatisfactionGraphicsComponent implements OnChanges {

    @Input() background;
    @Input() score;
    @Input() size: any = 30;
    @Input() type = 'smiley';
    @Input() id = 'job-satisfaction-graphics';

    backgroundColor = 'st4';
    strokeColor = 'st1';

  constructor() { }

  ngOnChanges(): void {
      d3.select('#' + this.id).remove();
      if (this.type === 'smiley') {
          if (this.background !== 'regular') {
              this.strokeColor = 'st5';
          }
          if (this.score === 2) {
              this.backgroundColor = 'st4';
          } else if (this.score === 1) {
              this.backgroundColor = 'st3';
          } else if (this.score === 0) {
              this.backgroundColor = 'st0';
          } else {
              this.backgroundColor = 'st2';
          }
      } else if (this.type === 'pcr_redo' && this.size !== 'legend') {
          this.size = 20;
      } else if (this.type === 'pcr_redo' && this.size === 'legend') {
          this.size = 15;
      }
  }

}
