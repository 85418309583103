import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuardService} from '../app/services/auth-guard.service';

import {ArchivesPeopleListComponent} from './archives-people-list/archives-people-list.component';
import {MobileArchivesPeopleListComponent} from './mobile/mobile-archives-people-list/mobile-archives-people-list.component';
import {ArchivesJobsListComponent} from './archives-jobs-list/archives-jobs-list.component';
import {ArchivesRightmatchListComponent} from './archives-rightmatch-list/archives-rightmatch-list.component';
import {ArchivesIdwMatrixListComponent} from './archives-idw-matrix-list/archives-idw-matrix-list.component';
import {ArchivesGraphboardListComponent} from './archives-graphboard-list/archives-graphboard-list.component';

@NgModule({
    imports: [
        RouterModule.forChild(
            [
                {
                    path: 'archives',
                    redirectTo: '/archives/list/people',
                    pathMatch: 'full'
                },
                {
                    path: 'archives/list',
                    redirectTo: '/archives/list/people',
                    pathMatch: 'full'
                },
                {
                    path: 'archives/list/people',
                    component: ArchivesPeopleListComponent,
                    canActivate: [AuthGuardService]
                },
                {
                    path: 'm/archives',
                    redirectTo: '/m/archives/list/people',
                    pathMatch: 'full'
                },
                {
                    path: 'm/archives/list',
                    redirectTo: '/m/archives/list/people',
                    pathMatch: 'full'
                },
                {
                    path: 'm/archives/list/people',
                    component: MobileArchivesPeopleListComponent,
                    canActivate: [AuthGuardService]
                },
                {
                    path: 'archives/list/jobs',
                    component: ArchivesJobsListComponent,
                    canActivate: [AuthGuardService]
                },
                {
                    path: 'archives/list/rightmatch',
                    component: ArchivesRightmatchListComponent,
                    canActivate: [AuthGuardService]
                },
                {
                    path: 'archives/list/idwmatrix',
                    component: ArchivesIdwMatrixListComponent,
                    canActivate: [AuthGuardService]
                },
                {
                    path: 'archives/list/graphboard',
                    component: ArchivesGraphboardListComponent,
                    canActivate: [AuthGuardService]
                }
            ]
        )
    ],
    exports: [RouterModule]
})
export class ArchivesRoutingModule {
}
