// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#candidateList {
  max-height: 250px;
  overflow-y: scroll;
}

.candidateRow {
  overflow: hidden;
  padding: 5px 7px;
}

.candidateRow:nth-child(odd) {
  background: #f5f5f5;
}

.addingFooter {
  text-align: right;
  padding-top: 10px;
}

.addingFooter button:first-child {
  margin-right: 10px;
}

.mpo-flex-column {
  display: flex;
  flex-direction: column;
}
.mpo-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.full-height {
  height: 300px;
  overflow-y: scroll;
}

button.btn-search {
  background-color: #ffffff !important;
}
button.btn-search .icon-search {
  background-color: #fff !important;
  position: relative;
  top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/right-matches/components/mobile/mobile-right-matches-person-adding/mobile-right-matches-person-adding.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGI;EACI,aAAA;EACA,sBAAA;AAAR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;AAAR;;AAIA;EACI,aAAA;EACA,kBAAA;AADJ;;AAIA;EACI,oCAAA;AADJ;AAEI;EACI,iCAAA;EACA,kBAAA;EACA,SAAA;AAAR","sourcesContent":["#candidateList {\n    max-height: 250px;\n    overflow-y: scroll;\n}\n\n.candidateRow {\n    overflow: hidden;\n    padding: 5px 7px;\n}\n\n.candidateRow:nth-child(odd) {\n    background: #f5f5f5;\n}\n\n.addingFooter {\n    text-align: right;\n    padding-top: 10px;\n}\n\n.addingFooter button:first-child {\n    margin-right: 10px;\n}\n\n.mpo-flex {\n    &-column {\n        display: flex;\n        flex-direction: column;\n    }\n    &-row {\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-end;\n    }\n}\n\n.full-height {\n    height: 300px;\n    overflow-y: scroll;\n}\n\nbutton.btn-search {\n    background-color: #ffffff!important;\n    .icon-search {\n        background-color: #fff!important;\n        position: relative;\n        top: 10px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
