// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actJobSelect {
  width: 100%;
}

.creditsAvailable, .creditCost {
  padding: 6.5px;
  border: 1px solid #cccccc;
  width: 100px;
  height: 50px;
  margin-bottom: 5px;
}

.creditsAvailable p, .creditCost p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.creditsAvailable {
  background-color: #bebebe;
}

.justifyRight {
  text-align: right;
}

::ng-deep .titlesGrid .mat-figure {
  justify-content: flex-start;
}

::ng-deep .titlesGrid .mat-grid-tile:last-child .mat-figure {
  justify-content: center;
}

::ng-deep .rightTile .mat-figure {
  align-items: flex-start;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  padding-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/mobile/people-report-activation/mobile-people-report-activation.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AACA;EACI,cAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,SAAA;EACA,UAAA;EACA,kBAAA;AAGJ;;AADA;EACI,yBAAA;AAIJ;;AAFA;EACI,iBAAA;AAKJ;;AAHA;EACI,2BAAA;AAMJ;;AAJA;EACI,uBAAA;AAOJ;;AALA;EACI,uBAAA;AAQJ;;AANA;EACI,yBAAA;AASJ","sourcesContent":[".actJobSelect {\n    width: 100%;\n}\n.creditsAvailable, .creditCost {\n    padding: 6.5px;\n    border: 1px solid #cccccc;\n    width: 100px;\n    height: 50px;\n    margin-bottom: 5px;\n}\n.creditsAvailable p, .creditCost p {\n    margin: 0;\n    padding: 0;\n    text-align: center;\n}\n.creditsAvailable {\n    background-color: #bebebe;\n}\n.justifyRight {\n    text-align: right;\n}\n::ng-deep .titlesGrid .mat-figure {\n    justify-content: flex-start;\n}\n::ng-deep .titlesGrid .mat-grid-tile:last-child .mat-figure {\n    justify-content: center;\n}\n::ng-deep .rightTile .mat-figure {\n    align-items: flex-start;\n}\n::ng-deep .mat-form-field.mat-focused .mat-form-field-label {\n    padding-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
