import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-idw-matrix',
    templateUrl: './idw-matrix.component.html',
    styleUrls: ['./idw-matrix.component.scss']
})
export class IdwMatrixComponent implements OnChanges {

    @ViewChild('IdwMatrix', {static: true}) private chartContainer: ElementRef;

    @Input() itemToDisplay: any;
    @Input() viewType = 'trait';
    @Input() lang: string;
    type: any;
    quadCount: any;

    quadCountDefault = {
        R: {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        },
        V: {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        },
        J: {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        },
        B: {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        }
    };

    constructor() {
    }

    ngOnChanges() {
        this.quadCount = null;
        this.type = null;

        this.quadCount = this.quadCountDefault;

        this.createIdwGraph();
    }

    writeName(svg, x_pos, y_pos, quadLoad, quad, name) {
        svg.append('text')
            .attr('x', x_pos)
            .attr('y', y_pos + (15 * quadLoad[quad[0]][quad[1]]['lineCount']))
            .text(name)
            .attr('text-anchor', 'left')
            .attr('font-family', 'sans-serif')
            .attr('font-weigth', 'bolder')
            .style('fill', 'black')
            .attr('font-size', 11);
    }

    createIdwGraph() {
        d3.select('#ditMainGraph').remove();
        // need quad, ad, lang
        let element = this.chartContainer.nativeElement;

        let svg = d3.select(element).append('svg')
            .attr('id', 'ditMainGraph')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '-40 -40 695 700');

        svg.style('background-color', 'rgb(206,206,206)');

        svg.append('rect').attr('x', -30).attr('y', -30).attr('width', 675).attr('height', 680).style('fill', 'white');

        // Color definition:

        const colors = {
            green: 'rgb(119,198,175)',
            red: 'rgb(240,123,126)',
            blue: 'rgb(119,156,197)',
            yellow: 'rgb(238,207,123)',
            centralCross: 'rgb(128,128,128)',
            greyLine: 'rgb(102,102,102)',
            adaptabilityBar: 'rgb(191,191,191)',
            adaptabilityText: 'rgb(128, 128, 128)'
        };

        // Quadrant

        const quadrants = [
            {x: 0, y: -14, color: 'green'},
            {x: 320, y: -14, color: 'red'},
            {x: 0, y: 306, color: 'blue'},
            {x: 320, y: 306, color: 'yellow'}
        ];

        quadrants.forEach(
            quad => {
                // console.log(colors[quad.color]);
                svg.append('rect').style('fill', colors[quad.color]).attr('x', (quad.x + 20))
                    .attr('y', (quad.y + 20)).attr('width', 260).attr('height', 260);

                svg.append('rect').style('fill', 'white').attr('x', (quad.x + 20) + 10)
                    .attr('y', (quad.y + 20) + 10).attr('width', 260 - 20).attr('height', 260 - 20);

                svg.append('line').attr('class', 'inQuadrantLines').attr('stroke-width', 1)
                    .attr('stroke', colors.greyLine).attr('x1', (quad.x + 20) + 260 / 2).attr('y1', (quad.y + 20) + 10) // + 10 to draw only on the white part
                    .attr('x2', (quad.x + 20) + 260 / 2).attr('y2', ((quad.y + 20) + 10) + (260 - 2 * 10)); // idem

                svg.append('line').attr('class', 'inQuadrantLines').attr('stroke-width', 1)
                    .attr('stroke', colors.greyLine).attr('x1', (quad.x + 20) + 10) // + 10 to draw only on the white part
                    .attr('y1', (quad.y + 20) + 260 / 2).attr('x2', ((quad.x + 20) + 10) + (260 - 2 * 10)) // idem
                    .attr('y2', (quad.y + 20) + 260 / 2);

            }
        );
        if (this.itemToDisplay && this.viewType) {
            for (let item of this.itemToDisplay[this.viewType]) {
                if (item.quad !== 'INV' && item.quad !== 'SEP') {
                    // console.log(this.quadCount);
                    // console.log(item);
                    if (item.quad) {
                        this.quadCount[item.quad[0]][item.quad[1]]++;
                    }
                }
            }
        }


        // Names //

        let quadLoad = {};
        let quadColor = ['R', 'V', 'J', 'B'];
        for (let j = 0; j < 4; j++) {
            quadLoad[quadColor[j]] = [];
            for (let i = 1; i < 5; i++) {
                let truncate = (this.quadCount[quadColor[j]][i] > 7);
                let first = (this.quadCount[quadColor[j]][i] > 7) ? '' : null;
                quadLoad[quadColor[j]][i] = {
                    lineCount: 0,
                    truncate: truncate,
                    first: first // first on a line / max of two; check max characters...
                };
            }
        }

        if (this.itemToDisplay && this.viewType) {
            for (let item of this.itemToDisplay[this.viewType]) {
                if (item.quad) {
                    let quadTmp = item.quad;
                    let quad = [];
                    quad[0] = quadTmp.charAt(0).toString();
                    quad[1] = parseInt(quadTmp.charAt(1), 10);
                    if (quadTmp !== 'INV' && quadTmp !== 'SEP') {

                        let name = item.firstName + ' ' + item.lastName;
                        if (name.length > 18) {
                            name = name.substring(0, 15) + '...';
                        }
                        let x_pos = 0;
                        let y_pos = 0;
                        // console.log(quad, quad[0], quad[1]);
                        if (quad[0] === 'V') {
                            if (quad[1] === 1) {
                                x_pos = 35;
                                y_pos = 30;
                            } else if (quad[1] === 2) {
                                x_pos = 155;
                                y_pos = 30;
                            } else if (quad[1] === 3) {
                                x_pos = 35;
                                y_pos = 150;
                            } else if (quad[1] === 4) {
                                x_pos = 155;
                                y_pos = 150;
                            }
                        } else if (quad[0] === 'R') {
                            if (quad[1] === 1) {
                                x_pos = 475;
                                y_pos = 30;
                            } else if (quad[1] === 2) {
                                x_pos = 355;
                                y_pos = 30;
                            } else if (quad[1] === 3) {
                                x_pos = 475;
                                y_pos = 150;
                            } else if (quad[1] === 4) {
                                x_pos = 355;
                                y_pos = 150;
                            }
                        } else if (quad[0] === 'B') {
                            if (quad[1] === 1) {
                                x_pos = 35;
                                y_pos = 470;
                            } else if (quad[1] === 2) {
                                x_pos = 155;
                                y_pos = 470;
                            } else if (quad[1] === 3) {
                                x_pos = 35;
                                y_pos = 350;
                            } else if (quad[1] === 4) {
                                x_pos = 155;
                                y_pos = 350;
                            }
                        } else if (quad[0] === 'J') {
                            if (quad[1] === 1) {
                                x_pos = 475;
                                y_pos = 470;
                            } else if (quad[1] === 2) {
                                x_pos = 355;
                                y_pos = 470;
                            } else if (quad[1] === 3) {
                                x_pos = 475;
                                y_pos = 350;
                            } else if (quad[1] === 4) {
                                x_pos = 355;
                                y_pos = 350;
                            }
                        }
                        this.writeName(svg, x_pos, y_pos, quadLoad, quad, name);
                        quadLoad[quad[0]][quad[1]]['lineCount'] = quadLoad[quad[0]][quad[1]]['lineCount'] + 1;

                    }
                }
            }
        }

        // Text for quandrants

        const headTitles = {
            'fr': ['ANALYTIQUE', 'DIRECTIF', 'COOPÉRATIF', 'EXPRESSIF'],
            'en': ['ANALYTICAL', 'AUTHORITATIVE', 'COOPERATIVE', 'EXPRESSIVE']
        };

        const squareTitles = [
            {x: 147.5, y: 0, text: headTitles[this.lang][0], color: 'green'},
            {x: 467.5, y: 0, text: headTitles[this.lang][1], color: 'red'},
            {x: 147.5, y: 605, text: headTitles[this.lang][2], color: 'blue'},
            {x: 467.5, y: 605, text: headTitles[this.lang][3], color: 'yellow'}
        ];

        squareTitles.forEach(title => {
            svg.append('text')
                .attr('x', title.x)
                .attr('y', title.y)
                .text(title.text)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'sans-serif')
                .attr('font-weigth', 'bolder')
                .style('fill', colors[title.color])
                .attr('font-size', 16);
        });

        // Central cross

        const centralCrossLines = (this.lang === 'fr') ? [
            {x1: 75, y1: 296, x2: 520, y2: 296},
            {x1: 75, y1: 296, x2: 85, y2: 286},
            {x1: 75, y1: 296, x2: 85, y2: 306},
            {x1: 520, y1: 296, x2: 510, y2: 286},
            {x1: 520, y1: 296, x2: 510, y2: 306},

            {x1: 310, y1: 96, x2: 310, y2: 511},
            {x1: 310, y1: 96, x2: 300, y2: 106},
            {x1: 310, y1: 96, x2: 320, y2: 106},
            {x1: 310, y1: 511, x2: 320, y2: 501},
            {x1: 310, y1: 511, x2: 300, y2: 501}
        ] : [
            {x1: 90, y1: 296, x2: 520, y2: 296},
            {x1: 90, y1: 296, x2: 100, y2: 286},
            {x1: 90, y1: 296, x2: 100, y2: 306},
            {x1: 520, y1: 296, x2: 510, y2: 286},
            {x1: 520, y1: 296, x2: 510, y2: 306},

            {x1: 310, y1: 96, x2: 310, y2: 511},
            {x1: 310, y1: 96, x2: 300, y2: 106},
            {x1: 310, y1: 96, x2: 320, y2: 106},
            {x1: 310, y1: 511, x2: 320, y2: 501},
            {x1: 310, y1: 511, x2: 300, y2: 501}
        ];

        centralCrossLines.forEach(line => {
            svg.append('line')
                .attr('class', 'centralCross')
                .attr('x1', line.x1)
                .attr('y1', line.y1)
                .attr('x2', line.x2)
                .attr('y2', line.y2)
                .attr('stroke', colors.centralCross)
                .attr('stroke-linecap', 'round')
                .attr('stroke-width', 3);
        });

        // Central cross texts

        const crossTexts = {
            'fr': ['Prudence', 'Affirmation', 'Contrôle de soi', 'Spontanéité'],
            'en': ['Cautiousness', 'Assertiveness', 'Self control', 'Spontaneity']
        };

        const axisTitles = [
            {x: 35, y: 300, text: crossTexts[this.lang][0], rotation: 0},
            {x: 573, y: 300, text: crossTexts[this.lang][1], rotation: 0},
            {x: 306, y: 34.5, text: crossTexts[this.lang][2], rotation: 90},
            {x: 306, y: 569, text: crossTexts[this.lang][3], rotation: 90}
        ];

        axisTitles.forEach(title => {
            svg.append('text')
                .attr('x', title.x)
                .attr('y', title.y)
                .text(title.text)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'serif')
                .attr('font-style', 'italic')
                .attr('font-size', 16)
                .attr('transform', 'rotate(' + title.rotation + ', ' + title.x + ', ' + title.y + ')')
                .style('fill', colors.centralCross);
        });

    }
}

