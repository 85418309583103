import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    OnDestroy,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

import {Score} from '../../../models/job.model';

import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';

import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-mobile-jobs-profiler',
    templateUrl: './mobile-jobs-profiler.component.html',
    styleUrls: ['./mobile-jobs-profiler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileJobsProfilerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() status: string;
    @Output() close = new EventEmitter();

    jobProfilerForm: UntypedFormGroup;

    requiredBy: string;
    sessionStructure: any = {};

    scoreToDisplay: Score;
    scoreIndex = 0;

    override = false;

    selectedProfile;

    readonly profiles = {
        titles: [
            {
                key: 'gens',
                name: {
                    fr: 'GÉNÉRALISTES (A/S)',
                    en: 'GENERALISTS (A/S)'
                },
            },
            {
                key: 'specs',
                name: {
                    fr: 'SPÉCIALISTES (S/A)',
                    en: 'SPECIALISTS (S/A)'
                },
            },
        ],
        gens: [
            {
                key: 'explorateur',
                name: {
                    fr: 'L\'Explorateur',
                    en: 'Explorator'
                },
                score: [7, 6, 4, 3]
            },
            {
                key: 'pionnier',
                name: {
                    fr: 'Pionnier',
                    en: 'The Pioneer'
                },
                score: [7, 6, 3, 4]
            },
            {
                key: 'fonceur',
                name: {
                    fr: 'Le Fonceur',
                    en: 'The Driver'
                },
                score: [7, 4, 3, 4]
            },
            {
                key: 'architecte',
                name: {
                    fr: 'L\'Architecte',
                    en: 'The Architect'
                },
                score: [7, 4, 3, 6]
            },
            {
                key: 'chercheur',
                name: {
                    fr: 'Le Chercheur',
                    en: 'The Researcher'
                },
                score: [7, 3, 6, 3]
            },
            {
                key: 'expressif',
                name: {
                    fr: 'L\'Expressif',
                    en: 'The Persuader'
                },
                score: [6, 7, 3, 4]
            },
            {
                key: 'promoteur',
                name: {
                    fr: 'Le Promoteur',
                    en: 'The Promoter'
                },
                score: [6, 7, 4, 3]
            },
            {
                key: 'ambassadeur',
                name: {
                    fr: 'L\'Ambassadeur',
                    en: 'The Ambassador'
                },
                score: [6, 7, 7, 3]
            },
        ],
        specs: [
            {
                key: 'perfectionniste',
                name: {
                    fr: 'Le Perfectionniste',
                    en: 'The Perfectionist'
                },
                score: [3, 4, 6, 7]
            },
            {
                key: 'prevoyant',
                name: {
                    fr: 'Le Prévoyant',
                    en: 'The Supporter'
                },
                score: [3, 4, 7, 6]
            },
            {
                key: 'cooperatif',
                name: {
                    fr: 'Le Coopératif',
                    en: 'The Collaborator '
                },
                score: [3, 6, 7, 6]
            },
            {
                key: 'facilitateur',
                name: {
                    fr: 'Le Facilitateur',
                    en: 'The Facilitator'
                },
                score: [3, 6, 7, 4]
            },
            {
                key: 'coordonnateur',
                name: {
                    fr: 'Le Coordonnateur',
                    en: 'The Coordinator'
                },
                score: [3, 7, 3, 7]
            },
            {
                key: 'analyste',
                name: {
                    fr: 'L\'Analyste',
                    en: 'The Analyst'
                },
                score: [4, 3, 7, 6]
            },
            {
                key: 'specialiste',
                name: {
                    fr: 'Le Spécialiste',
                    en: 'The Specialist'
                },
                score: [4, 3, 6, 7]
            },
            {
                key: 'controleur',
                name: {
                    fr: 'Le Contrôleur',
                    en: 'The Controller'
                },
                score: [5, 3, 3, 7]
            }
        ]
    };

    profileList;
    profileListGroups;

    private subscriptions = new Subscription();

    constructor(
        public jobService: JobService,
        private apiJobsService: ApiJobsService,
        private stateService: StateService,
        private fb: UntypedFormBuilder,
        public translate: TranslateService
    ) {
        this.profileList = this.generateProfiles(this.translate.currentLang);
        this.profileListGroups = this.generateProfiles(this.translate.currentLang, true);
    }

    ngOnInit() {
        this.subscriptions.add(this.stateService.session.sessionDataWatch$.subscribe(
            (res) => {
                if (res) {
                    this.requiredBy = this.stateService.session.sessionData.userData.username;
                    this.sessionStructure = this.stateService.session.sessionData.structure;
                }
            }
        ));
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            (event) => {
                this.profileList = this.generateProfiles(event.lang);
                this.profileListGroups = this.generateProfiles(event.lang, true);
            }
        ));
    }

    ngOnChanges() {
        // Score
        this.override = false;
        if (this.jobService.score && this.jobService.score.length > 0) { // removed: && this.status === 'edit' for override questionnaire => quick
            this.scoreToDisplay = this.numerizeScore(this.jobService.score[this.scoreIndex]);
        } else {
            this.scoreToDisplay = new Score();
        }

        let currentScore = (this.jobService.score && this.jobService.score[0]) ?
            [this.jobService.score[0].A, this.jobService.score[0].E, this.jobService.score[0].P, this.jobService.score[0].S] :
            [];
        for (let title of this.profiles.titles) {
            for (let profile of this.profiles[title.key]) {
                if (JSON.stringify(currentScore) === JSON.stringify(profile.score)) {
                    this.selectedProfile = profile.key;
                }
            }
        }

        this.initJobProfilerForm();
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    numerizeScore(scores): Score {
        return {
            'OR': +scores.OR,
            'SE': +scores.SE,
            'A': +scores.A,
            'E': +scores.E,
            'P': +scores.P,
            'S': +scores.S
        };
    }

    initJobProfilerForm() {
        this.jobProfilerForm = this.fb.group({
            OR: [this.scoreToDisplay.OR],
            SE: [this.scoreToDisplay.SE],
            A: [this.scoreToDisplay.A],
            E: [this.scoreToDisplay.E],
            P: [this.scoreToDisplay.P],
            S: [this.scoreToDisplay.S]
        });
    }

    closeForm() {
        // delete this.scoreToDisplay;
        this.scoreToDisplay = undefined;
        this.close.emit(true);
    }

    generateProfiles(lang, group = false) {
        let dropdownGroups = [];
        let dropdownContent = [];
        for (let title of this.profiles.titles) {
            if (group) {
                dropdownContent = [];
            }
            for (let profile of this.profiles[title.key]) {
                dropdownContent.push({
                    value: profile.key,
                    text: profile.name[lang],
                    score: profile.score
                });
            }
            dropdownGroups.push({
                value: title.key,
                text: title.name[lang],
                opt: dropdownContent
            });
        }
        if (group) {
            return dropdownGroups;
        }
        return dropdownContent;
    }

    loadValues(selectedProfile) {
        if (selectedProfile !== 'none') {
            let score = _.result(_.find(this.profiles.gens.concat(this.profiles.specs), function (obj) {
                return obj.key === selectedProfile;
            }), 'score');
            this.jobProfilerForm = this.fb.group({
                OR: [this.scoreToDisplay.OR],
                SE: [this.scoreToDisplay.SE],
                A: [score[0]],
                E: [score[1]],
                P: [score[2]],
                S: [score[3]]
            });
        }
    }

    cleanScore(scores, local = false) {
        let numericScore: any = {};
        // tslint:disable-next-line:forin
        for (let score in scores) {
            if (scores.hasOwnProperty(score)) {
                let alphaScore = '' + scores[score];
                let number = +alphaScore.replace(/\D/g, '');
                if (number > 10) {
                    number = 10;
                }
                numericScore[score] = number;
                if (!local) {
                    if (score === 'ES') {
                        score = 'SE';
                    }
                    (<HTMLInputElement>document.getElementById('profiler' + score)).value = numericScore[score];
                }
            }
        }
        return numericScore;
    }

    onSubmit(model: UntypedFormGroup) {

        let scores = this.cleanScore(model.value, true);

        // Clear AD if exists
        if (scores.AD) {
            delete scores.AD;
        }

        if (this.status === 'edit' || this.override) {
            this.saveEditJobProfiler(scores);
        } else {
            this.saveNewJobProfiler(scores);
        }
    }

    saveNewJobProfiler(jobProfilerData: Score) {
        // call api to save the modification
        this.subscriptions.add(this.apiJobsService.jobProfiler([this.jobService.id], {score: jobProfilerData}, false).subscribe(
            () => {
                this.jobService.setItemToDisplayWithId(this.jobService.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id, tab: 'personality'});
                this.close.emit(true);
            }
        ));
    }

    scoreUpdate(id, index, score) {
        return this.apiJobsService.jobProfiler([id, index], {score: score}, true).subscribe(
            () => {
                this.jobService.setItemToDisplayWithId(this.jobService.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id, tab: 'personality'});
                this.close.emit(true);
            });
    }

    saveEditJobProfiler(jobProfilerData: Score) {
        // call api to save the modification
        if (this.override) {
            this.subscriptions.add(this.apiJobsService.putJob(
                [this.jobService.id],
                {type: 'short'}
            ).subscribe(
                () => {
                    this.subscriptions.add(this.scoreUpdate(this.jobService.id, this.scoreIndex, jobProfilerData));
                }
            ));
        } else {
            this.subscriptions.add(this.scoreUpdate(this.jobService.id, this.scoreIndex, jobProfilerData));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
