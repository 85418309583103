export class PermissionHelper {

    /**
     * Get the account / client permissions
     * @param accountData account data object
     */
    public static getAccountPermissions(accountData: any): any {
        if (accountData.permissions) {
            return accountData.permissions;
        }

        return {};
    }

    /**
     * Can select a satellite user
     * @param roleName user role name
     * @param accessPermissions access permissions object
     */
    public static canSelectSatelliteUser(roleName: string, accessPermissions: any): boolean {
        if (roleName === 'ROLE_USER_SATELLITE') {
            return accessPermissions.satellite;
        }

        return true;
    }
}
