import {ActualModule} from '../../core/interfaces/state';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';

export abstract class Global {

    public module = {
        name: 'people',
        archive: false
    };
    protected constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
    }

    get actualModule(): ActualModule {
        let modulesName = ['people', 'jobs', 'admin', 'graphboard', 'rightmatch', 'idwmatrix', 'jobsatisfaction'];
        let urlSplit = this.router.url.split('/');
        let moduleName = urlSplit.filter(value => modulesName.includes(value))[0];

        if (urlSplit.includes('archives')) {
            if (moduleName === 'rightmatch' || moduleName === 'idwmatrix') {
                moduleName = moduleName.replace('m', 'M');
            }
            if (moduleName === 'jobsatisfaction') {
                moduleName = 'jobSatisfaction';
            }
            let capitalized = moduleName[0].toUpperCase() +
                moduleName.slice(1);
            moduleName = 'archives' + capitalized;
        }
        if (urlSplit.includes('analysis')) {
            // we have to use the uppercase letter 'M' for the modules names like 'rightMatch' and 'idwMatrix'
            moduleName = moduleName.replace('m', 'M');
            // rewrite for jobsatisfaction
            if (moduleName === 'jobsatisfaction') {
                moduleName = 'jobSatisfaction';
            }
        }

        return {
            name: moduleName,
            archive: (moduleName.substring(0, 8) === 'archives')
        };
    }

    get isMobile() {
        return this.deviceService.isMobile();
    }

    get urlReportType() {
        let typeIndex = 4;
        if (this.isMobile) {
            typeIndex++;
        }
        return this.router.url.split('/')[typeIndex];
    }

    get smallSize() {
        return (window.innerWidth < 1235);
    }
}
