import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {TalentsDefinitions} from '../../../../shared/talents/talentsDefinitions';
import {StateService} from '../../../../core/services/state/state.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {environment} from '../../../../../environments/environment';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    EventEmitter,
    Component,
    Input,
    Output,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import {PermissionsService} from 'src/app/shared/permissions/permissions.service';
import {UserService} from '../../../../core/services/user/user.service';
import {JobsModalHandlingService} from '../../../services/jobs-modal-handling.service';
import {JobsListService} from '../../../services/jobs-list.service';
import {JobService} from '../../../services/job.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-jobs-sidebar',
    templateUrl: './jobs-sidebar.component.html',
    styleUrls: ['./jobs-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsSidebarComponent extends ComponentSidebar implements OnInit, OnDestroy {
    @Input() reportType: any;
    @Input() isLeftAndNotTop = true;
    @Input() mobile = false;

    @Input() width = '';
    @Input() config: any;
    widthTitle: any = '';

    jobType: string;

    orderedTalents = [];

    @Output() reportChangeType = new EventEmitter();
    @Output() reportActivation = new EventEmitter;
    @Output() refreshWindow = new EventEmitter;

    @ViewChild('dataOffsetBlock') private elementRef: ElementRef;

    isSatellite: boolean;
    titleSep: string;
    jobDateFormat: string;

    onDestroy$: Subject<null> = new Subject();

    // Jobs
    //// Trait
    talentsScoresKeys: Array<any> = undefined;
    talentsName: any;
    mpoGraphType = 'plain';
    mpoDate: any;

    maxPerPage = 15;

    requiredBy: string;
    profilerFormUrl = ''; // environment.profilerFormUrl;
    nativeWindow: any;
    canDelete = false;

    dateFormat;

    private subscriptions = new Subscription();

    constructor(
        protected router: Router,
        private location: Location,
        protected route: ActivatedRoute,
        public stateService: StateService,
        public user: UserService,
        public jobsList: JobsListService,
        public jobService: JobService,
        public jobsModalHandlingService: JobsModalHandlingService,
        private apiJobsService: ApiJobsService,
        private alertService: AlertService,
        private windowRef: WindowRef,
        private changeDetectorRef: ChangeDetectorRef,
        public translateService: TranslateService,
        private permissionsService: PermissionsService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
        this.nativeWindow = windowRef.getNativeWindow();
    }

    onResize() {
        this.titleWidth();
    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        this.canDelete = this.permissionsService.canDelete();

        if (!this.isLeftAndNotTop) { this.width = null; }
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);
        this.jobDateFormat = TextHelper.dateFormat(this.translateService.currentLang);

        this.titleWidth();
        this.checkReportType();
        this.setTalentsName();
        this.setTalentsScoresKeys();
        this.setJobType();

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.checkReportType();
                    this.setTalentsName();
                    this.setTalentsScoresKeys();
                    this.setJobType();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.jobDateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

    setJobType() {
        this.jobType = (this.jobService.type && this.jobService.type !== 'undefined')
            ? this.jobService.type
            : 'undefined';
    }

    isCorrectLanguage() {
        return !!(this.jobService.language === 'en' || this.jobService.language === 'fr');
    }

    titleWidth() {
        this.widthTitle = (this.width) ? (Number(this.width.substr(0, this.width.length - 2)) - 70) + 'px' : false;
        return;
    }

    checkReportType() {
        if (!this.reportType || this.reportType === 'mpo' || (this.reportType === 'ra' && !this.user.hasIacAccess)) {
            this.reportType = 'personality';
            this.state.report.reportType = 'personality';
            this.state.report.display = 'report';
        }
        if (this.mpoScores && this.reportType === 'personality') {
            this.mpoGraphType = 'plain';
            this.isSatellite = (this.jobService.score[0] &&
                this.jobService.score[0].satellite &&
                this.jobService.score[0].satellite !== undefined);
            this.mpoDate = this.jobService.date;
        } else {
            this.mpoGraphType = 'inactive';
            this.isSatellite = false;
        }
    }

    setTalentsName() {
        this.talentsName = {
            fr: new TalentsDefinitions().talents.fr,
            en: new TalentsDefinitions().talents.en
        };
    }

    setTalentsScoresKeys() {
        if (this.jobService.competences) {
            if (this.talentsScores) {
                this.talentsScoresKeys = Object.keys(this.talentsScores);
                let talents = {};
                talents['en'] = [];
                talents['fr'] = [];
                for (let scoreKey of this.talentsScoresKeys) {
                    talents['en'].push([
                        this.fuseTalentName(this.talentsName['en'][scoreKey]),
                        this.talentsScores[scoreKey]
                    ]);
                    talents['fr'].push([
                        this.fuseTalentName(this.talentsName['fr'][scoreKey]),
                        this.talentsScores[scoreKey]
                    ]);
                }

                this.orderedTalents['en'] = talents['en'].sort(this.orderTalents);
                this.orderedTalents['fr'] = talents['fr'].sort(this.orderTalents);
                this.maxPage = Math.ceil(_.size(this.jobService.competences[0]) / this.maxPerPage);
            }
        } else {
            this.maxPage = null;
        }
    }

    orderTalents(a, b) {
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) < 0) {
            return -1;
        }
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) > 0) {
            return 1;
        }
        return 0;
    }

    fuseTalentName(talent) {
        if (talent && talent.length > 1) {
            return talent.join(' ');
        }
        return talent[0];
    }

    /**
     * Job description modal
     */
    openJobEditModal() {
        this.jobsModalHandlingService.jobEditModal({status: 'edit'}, this.config);
    }

    openJobCopyModal() {
        this.jobsModalHandlingService.jobEditModal({status: 'copy', section: 'jobDescription'}, this.config);
    }

    openJobProfilerEmailModal() {
        if (this.jobType === 'quick' || this.jobType === 'short') {
            const txtDeleteConf = {
                paragraph: this.translateService.instant('jobs.sidebar.profileChangeWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txtDeleteConf);
            this.subscriptions.add(this.alertService.nextAction.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.profilerEmailModalAction();
                    }
                }
            ));
        } else {
            this.profilerEmailModalAction();
        }
    }

    profilerEmailModalAction() {
        this.subscriptions.add(this.apiJobsService.profilerRequestTempUser([this.jobService.id], []).subscribe(
            tempUser => {
                this.jobsModalHandlingService.jobProfilerEmailModal({job: this.jobService.jobToDisplay, tempUser: tempUser}, this.config);
            }
        ));
    }

    openProfilerForm() {
        if (this.jobType === 'quick' || this.jobType === 'short') {
            const txtDeleteConf = {
                paragraph: this.translateService.instant('jobs.sidebar.profileChangeWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txtDeleteConf);
            this.subscriptions.add(this.alertService.nextAction.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.profilerFormAction();
                    }
                }
            ));
        } else {
            this.profilerFormAction();
        }
    }

    profilerFormAction() {
        // Open window to avoid popup blocking
        let profilerWindow = this.nativeWindow.open('', '_blank');

        this.subscriptions.add(this.apiJobsService.profilerRequestToken([this.jobService.id], []).subscribe(
            res => {
                // Modification of URL in window open after getting data
                profilerWindow.document.location.href = this.profilerFormUrl + '/autoconnect/' + res.token;
            }
        ));
        this.refreshWindow.emit(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        let actionObservable;

        // noinspection IfStatementWithTooManyBranchesJS
        if (action === 'archive') {
            if (act) {
                this.apiJobsService.checkDelete([this.jobService.id]).subscribe(
                    res => {
                        p[0] = this.translateService.instant('jobs.commons.multipleArchivedJob',
                            {
                                total: 1,
                                plural: ''
                            });
                        h1[0] = this.translateService.instant('commons.archiving');
                        p[1] = this.translateService.instant('commons.tryAgain');
                        h1[1] = this.translateService.instant('commons.archivingError');

                        let pAction = this.translateService.instant('commons.archivingAction');
                        let paragraph;

                        if (Array.isArray(res) && res.length === 0) {
                            paragraph = this.translateService.instant('jobs.commons.jobActionConfirmation', {
                                action: pAction,
                                total: 1,
                                plural: ''
                            });
                        } else {
                            paragraph = this.translateService.instant('jobs.commons.jobLinkedAnalysisConfirmation', {
                                action: pAction,
                                res: ''
                            });
                        }

                        let warnMessage = {
                            paragraph: paragraph,
                            texth1: 'Confirmation',
                            position: 'center-center',
                            template: 'warningConfirm'
                        };

                        this.alertService.setConfig(warnMessage);
                        this.alertService.nextAction.subscribe(
                            (res2) => {
                                if (res2 === 'confirm' && action === 'archive') {
                                    this.apiJobsService.putJob([this.jobService.id], {archive: act}).subscribe(
                                        () => {

                                            this.alertService.setConfig({
                                                paragraph: p[0],
                                                texth1: h1[0],
                                                position: 'top-right',
                                                type: 'green'
                                            });

                                            this.jobsList.getJobList(true);
                                            if (this.config === 'list') {
                                                this.goToNews();
                                            } else {
                                                this.jobService.resetItemToDisplay(this.specificState());
                                                this.goToList();
                                            }
                                        },
                                        () => {
                                            this.alertService.setConfig({
                                                paragraph: p[1],
                                                texth1: h1[1],
                                                position: 'center-center',
                                                type: 'red'
                                            });
                                        }
                                    );
                                    action = undefined;
                                }
                            }
                        );
                    });
                ///////////////////////////////////////
            } else {
                p[0] = this.translateService.instant('jobs.commons.multipleReactivatedJob', {total: 1, plural: ''});
                h1[0] = this.translateService.instant('commons.reactivation');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[1] = this.translateService.instant('commons.reactError');

                let pAction = this.translateService.instant('commons.reactAction');
                const warnMessage = {
                    paragraph: this.translateService.instant('jobs.commons.jobActionConfirmation', {action: pAction, total: 1, plural: ''}),
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(warnMessage);
                this.alertService.nextAction.subscribe(
                    (res) => {
                        if (res === 'confirm' && action === 'archive') {
                            this.apiJobsService.putJob([this.jobService.id], {archive: act}).subscribe(
                                () => {

                                    this.alertService.setConfig({
                                        paragraph: p[0],
                                        texth1: h1[0],
                                        position: 'top-right',
                                        type: 'green'
                                    });

                                    this.jobsList.getJobList(true);
                                    if (this.config === 'list') {
                                        this.goToNews();
                                    } else {
                                        this.jobService.resetItemToDisplay(this.specificState());
                                        this.goToList();
                                    }
                                },
                                () => {
                                    this.alertService.setConfig({
                                        paragraph: p[1],
                                        texth1: h1[1],
                                        position: 'center-center',
                                        type: 'red'
                                    });
                                }
                            );
                            action = undefined;
                        }
                    }
                );
            }
        } else if (action === 'delete') {
            this.apiJobsService.checkDelete([this.jobService.id]).subscribe(
                res => {
                    let pAction = this.translateService.instant('commons.deletionAction');
                    p[0] = this.translateService.instant('jobs.commons.jobsDeleted', {
                        total: 1,
                        plural: ''
                    });
                    h1[0] = this.translateService.instant('commons.deleted');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.wasError');

                    let jobsWillBeRemoved = this.translateService.instant('jobs.commons.jobWillBeRemoved');
                    let paragraph;
                    if (Array.isArray(res) && res.length === 0) {
                        paragraph = this.translateService.instant('jobs.commons.jobActionConfirmation', {
                            action: pAction,
                            total: 1,
                            plural: ''
                        });
                    } else {
                        paragraph = this.translateService.instant('jobs.commons.jobLinkedAnalysisConfirmation', {
                            action: pAction,
                            res: jobsWillBeRemoved
                        });
                    }

                    let txtDeletionConf = {
                        paragraph: paragraph,
                        texth1: 'Confirmation',
                        position: 'center-center',
                        template: 'warningConfirm'
                    };
                    this.alertService.setConfig(txtDeletionConf);
                    this.alertService.nextAction.subscribe(
                        (res2) => {
                            if (res2 === 'confirm' && action === 'delete') {
                                this.apiJobsService.deleteJob([this.jobService.id]).subscribe(
                                    () => {

                                        this.alertService.setConfig({
                                            paragraph: p[0],
                                            texth1: h1[0],
                                            position: 'top-right',
                                            type: 'green'
                                        });

                                        this.jobsList.getJobList(true);
                                        if (this.config === 'list') {
                                            this.goToNews();
                                        } else {
                                            this.jobService.resetItemToDisplay(this.specificState());
                                            this.goToList();
                                        }
                                    },
                                    () => {
                                        this.alertService.setConfig({
                                            paragraph: p[1],
                                            texth1: h1[1],
                                            position: 'center-center',
                                            type: 'red'
                                        });
                                    }
                                );
                                action = undefined;
                            }
                        }
                    );
                });
        } else if (action === 'deleteProfiler') {
            p[0] = this.translateService.instant('jobs.commons.targetPersonProfileDeleted');
            h1[0] = this.translateService.instant('commons.deleted');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            const text = {
                paragraph: this.translateService.instant('jobs.commons.targetPersonProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobsService.deleteProfiler([this.jobService.id, 0]);
            this.action(actionObservable, action, p, h1);
        } else if (action === 'deleteJobCompetence') {
            p[0] = this.translateService.instant('jobs.commons.targetCompProfileDeleted');
            h1[0] = this.translateService.instant('commons.deleted');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            const text = {
                paragraph: this.translateService.instant('jobs.commons.targetCompProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobsService.deleteCompetence([this.jobService.id, 0]);
            this.action(actionObservable, action, p, h1);
        } else if (action === 'deleteJobCai') {
            p[0] = this.translateService.instant('jobs.commons.targetCaiProfileDeleted');
            h1[0] = this.translateService.instant('commons.deleted');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            const text = {
                paragraph: this.translateService.instant('jobs.commons.targetCaiProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobsService.deleteIac([this.jobService.id]);
            this.action(actionObservable, action, p, h1);
        } else if (action === 'resendProfiler') {
            this.jobsModalHandlingService.jobProfilerResendModal();
        }
        return;
    }

    action(actionObservable, action, p, h1) {
        this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm' && action) {
                    actionObservable.subscribe(
                        () => {

                            this.alertService.setConfig({
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            });

                            this.jobsList.getJobList(true);
                            if (this.config === 'list') {
                                if (action !== 'delete') {
                                    this.jobService.setItemToDisplayWithId(this.jobService.id);
                                } else {
                                    this.jobService.resetItemToDisplay(this.specificState());
                                }
                                if (action === 'delete' || action === 'archive') {
                                    this.goToNews();
                                }
                            }

                            if (this.config === 'report') {
                                if (action === 'delete' || action === 'archive') {
                                    if (action === 'delete') {
                                        this.jobService.resetItemToDisplay(this.specificState());
                                    }
                                    this.goToList();
                                } else {
                                    this.jobService.setItemToDisplayWithId(this.jobService.id);
                                }
                            }
                        },
                        (err) => {
                            if (err.error.code === 500 && action === 'deleteJobCai') {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (this.config === 'list') {
                                    this.jobsList.getJobList(true);
                                } else {
                                    this.jobService.setItemToDisplayWithId(this.jobService.id);
                                }
                            } else {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        }
                    );
                    action = undefined;
                }
            }
        );
    }

    get raTexts() {
        let txt;
        if (this.jobService.jobReport && this.jobService.jobReport.RAtexts) {
            txt = this.jobService.jobReport.RAtexts;
        }
        return txt;
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    jobIsBlank() {
        // noinspection OverlyComplexBooleanExpressionJS
        return !
        (
         this.jobService.competences || this.mpoScores || this.jobService.raScore);
    }

    difDates(date1, date2) {
        let dateOnly1 = date1.split('T');
        let dateOnly2 = date2.split('T');
        return dateOnly1[0] !== dateOnly2[0];

    }

    switchTab(index) {
        if (this.selectedInfoQuestionTab !== index) {
            this.informationVisibility = true;
            this.selectedInfoQuestionTab = index;
            this.state.stateChanged.next({selectedInfoQuestionTab: index});
        }
    }

    get mpoScores() {
        if (this.jobService.score) {
            return this.jobService.score[0];
        }
        return;
    }

    get talentsScores() {
        if (this.jobService.competences) {
            return this.jobService.competences[0];
        }
        return;
    }

    get hasTalentsScores() {
        return !!(this.talentsScores && !(_.isEmpty(this.talentsScores)));
    }

    get sessionData() {
        return _.get(this.stateService, 'session.sessionData');
    }

    get testAccessPermissions() {
        return _.get(this.sessionData, 'structure.testAccessPermissions');
    }

    switchEvaluationType(newType) {
        if (!this.testAccessPermissions[newType]) {
            // TODO : remove comments here when test-access are set in administration
            // this.jobsModalHandlingService.showModalUnaccessibleTest(newType);
            // return;
        }
        // Check if this evaluation type exists
        if (
            (newType === 'talents' && this.talentsScores) ||
            (newType === 'personality' && this.mpoScores) ||
            (newType === 'ra' && this.jobService.raScore)
        ) {
            this.stateService[this.specificState()].report.reportType = newType;
            this.reportType = newType;
            this.checkReportType();
        } else {
            if (this.actualModule.archive) {
                return;
            }
            if (newType === 'personality') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'personality'}, this.config);
            } else if (newType === 'talents') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'talents'}, this.config);

            } else if (newType === 'ra') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'iac'}, this.config);
            }
            this.stateService[this.specificState()].report.reportType = newType;
            this.reportType = newType;
        }
    }

    get snapshotUrl() {
        return this.route.snapshot.url;
    }

    get stateReportType() {
        return this.stateService[this.specificState()].report.reportType;
    }

    get isComplete() {
        return !!(this.jobService.score && this.jobService.score.length === 1);

    }

    get profilerStatus() {
        return (this.isComplete) ? 'jobs.sidebar.completed' : 'jobs.sidebar.notCompleted';
    }

    onPdfShare() {
        if (this.jobIsBlank()) {
            return;
        }
        this.jobsModalHandlingService.openPdfModal({jobToDisplay: this.jobService.jobToDisplay});
    }
}
