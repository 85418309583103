import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

import {StateService} from '../../../../core/services/state/state.service';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {JobsListService} from '../../../services/jobs-list.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {JobService} from '../../../services/job.service';
import {TranslateService} from '@ngx-translate/core';
import {JobsMultiselectActionComponent} from '../jobs-multiselect-action/jobs-multiselect-action.component';
import {JobsModalHandlingService} from '../../../services/jobs-modal-handling.service';
import {ComponentList} from 'src/app/shared/classes/components/component_list';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {HttpClient} from '@angular/common/http';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-jobs-list',
    templateUrl: './jobs-list.component.html',
    styleUrls: ['./jobs-list.component.scss'],
    animations: [
        trigger('selected', [
            state('none', style({
                display: 'none',
                transform: 'scale(0)'
            })),
            state('some', style({
                display: '',
                transform: 'scale(1)'
            })),
            transition('none => some', animate('120ms ease-in')),
            transition('some => none', animate('120ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsListComponent extends ComponentList implements OnInit, OnDestroy {
    @ViewChild('gSidebar') gSidebar;
    multiselectData: any = {
        actionType: null,
        ids: []
    };

    multiselectActionModal: BsModalRef;

    displayConstant = {
        adjustment: {left: -5, right: -14},
        widthRatio: 2 / 3,
        leftColumnWidth: 440
    };

    action;
    selectAction = false;
    listWidth: any;
    sideWidth: any;

    requiredTest: string;

    private subscriptions = new Subscription();

    onDestroy$ = new Subject();

    constructor(
        private httpClient: HttpClient,
        protected router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        public userPreferencesService: UserPreferencesService,
        public stateService: StateService,
        public translate: TranslateService,
        public jobsList: JobsListService,
        public jobService: JobService,
        public jobsModalHandlingService: JobsModalHandlingService,
        protected deviceService: DeviceDetectorService,
        private apiJobs: ApiJobsService,
        private alertService: AlertService,
        private changeDetectorRef: ChangeDetectorRef,
        private environment: EnvService
    ) {
        super(stateService, translate, router, deviceService);
        if (this.jobsList.jobsState.sideBarStatus === 'clickMode') {
            this.jobsList.jobsState.sideBarStatus = 'recentActivities';
        }

        this.requiredTest = (!stateService.hasPersonality) ? 'talents' : 'personality';
        if (!this.jobsList.jobsState.report.reportType) {
            this.jobsList.jobsState.report.reportType = this.requiredTest;
        }

        // set the sidebar status
        this.jobsList._opened = !this.smallSize;
    }

    ngOnInit() {
        this.jobsList.resetList();
        this.jobsList.multiAction = null;
        this.jobsList.clickMode = false;
        this.jobsList.checkModuleUrl();
        this.jobsList.checkUserJobsDisplayPreferences();

        // check items list without selection
        this.jobsList.checkTotalItems();

        if (this.jobService.id) {
            let action = 'resetSidebarToReport';
            if (this.jobsList.jobsState.sideBarStatus === 'searchFilters') {
                action = 'resetSidebarToFilters';
            }
            this.jobService.setItemToDisplayWithId(this.jobService.id, this.jobsList.specificState, action);
        }

        // WATCHES ////////////////////////////////////////////////////////////////////////

        this.jobsList.jobsState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.jobsList.getJobList(true);
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            () => {
                this.jobsList.getJobList(true);
            }
        ));

        // Change Route event
        this.stateService.session.actualModule = this.jobsList.specificState;

        // Set list width
        this.computeListWidth(window);

        if (this.jobsList.jobsState.action === 'activateSatellite') {
            if (!this.jobsList.toList) {
                this.jobsModalHandlingService.openNoJobModal();
            }
            this.jobsList.jobsState.action = undefined;
        }
    }


    /**
     * Set search parameter on event from shared/main-list/component/main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.jobsList.setSearchFilter(event);
        this.refreshList();
    }

    /**
     * Set selected job
     * @param item
     */
    jobSelected(item): void {
        this.closed = false;
        this.jobsList.jobsState.lastSideBarStatus = 'reports';
        this.jobsList.jobSelected(item);

        this.selectAction = true;
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.jobsList.setOrder(event);
        this.refreshList();
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.jobsList._opened = true;
            this.gSidebar.open();
        }
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);

        this.jobsList._opened = !this.smallSize;
        this.closed = this.smallSize;
    }

    computeListWidth(window: Window): void {
        let innerWidth = window.innerWidth;
        if (window.innerWidth < 1235) {
            this.listWidth = innerWidth - this.displayConstant.leftColumnWidth + this.displayConstant.adjustment.left + 'px';
            this.sideWidth = '400px';
        } else {
            this.sideWidth = (innerWidth - this.displayConstant.leftColumnWidth) *
                (1 - this.displayConstant.widthRatio) + this.displayConstant.adjustment.right;
            if (this.sideWidth < 400) {
                this.sideWidth = 400;
            }
            // @ilya 2020-10-16 - fix the side width to 400px
            this.sideWidth = 400;
            this.listWidth = innerWidth - (this.sideWidth * 1.25) + 'px';
            this.sideWidth = this.sideWidth + 'px';
        }
    }

    completeAction(event) {
        this.multiselectData = event;
        const initialState = {
            multiselectData: this.multiselectData
        };
        this.multiselectActionModal = this.modalService.show(JobsMultiselectActionComponent, {initialState, backdrop : 'static'});

        this.multiselectActionModal.content.transfer.subscribe(res => {
            if (res && res.multiselectData) {
                this.actOnRecords(res.multiselectData);
            }
        });
        this.multiselectActionModal.content.close.subscribe(res => {
            if (res && res.closed) {
                this.jobService.setItemToDisplayWithId(res.id, this.jobsList.specificState);
                this.refreshList();
            }
        });
    }

    actOnRecords(event) {
        const total = event.ids.length;
        let handled = 0;
        this.action = event.actionType;
        // noinspection IfStatementWithTooManyBranchesJS
        if (this.action === 'multigraph') {

        } else if (this.action === 'rightmatch') {

        } else if (this.action === 'delete') {
            this.apiJobs.checkDelete(event.ids).subscribe(
                res => {
                    let pAction = this.translate.instant('commons.deletionAction');
                    let plural = (total > 1) ? 's' : '';
                    let jobsWillBeRemoved = this.translate.instant('jobs.commons.jobsWillBeRemoved');
                    let paragraph;
                    if (Array.isArray(res) && res.length === 0) {
                        paragraph = this.translate.instant('jobs.commons.jobActionConfirmation', {
                            action: pAction,
                            total: total,
                            plural: plural
                        });
                    } else {
                        paragraph = this.translate.instant('jobs.commons.multiJobLinkedAnalysisConfirmation', {action: pAction, res: jobsWillBeRemoved});
                    }

                    let txtDeletionConf = {
                        paragraph: paragraph,
                        texth1: 'Confirmation',
                        position: 'center-center',
                        template: 'warningConfirm'
                    };
                    this.alertService.setConfig(txtDeletionConf);
                    this.alertService.nextAction.subscribe(
                        res2 => {
                            if (res2 === 'confirm' && this.action === 'delete') {
                                // Delete all selected ids
                                this.subscriptions.add(this.apiJobs.deleteJobs(event.ids).subscribe(
                                    () => {
                                        this.jobsList.loading = true;
                                        paragraph = this.translate.instant('jobs.commons.jobsDeleted', {
                                            total: total,
                                            plural: plural
                                        });
                                        const confMessage = {
                                            paragraph: paragraph,
                                            texth1: this.translate.instant('jobs.commons.jobsDeletionTitle'),
                                            position: 'top-right',
                                            type: 'green'
                                        };
                                        this.jobsList.getJobList(true, confMessage);
                                        this.jobsList.revertToNews();
                                        this.jobsList.cleanMultiselectMode(true);
                                    },
                                    () => {
                                        this.jobsList.loading = true;
                                        const errMessage = {
                                                paragraph: this.translate.instant('commons.tryAgain'),
                                                texth1: this.translate.instant('commons.wasError'),
                                                position: 'center-center',
                                                type: 'red'
                                            };
                                        this.jobsList.getJobList(true, errMessage);
                                    }
                                ));
                                this.action = undefined;
                            }
                        }
                    );
                });
        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            let plural;
            switch (this.action) {
                case 'transferOtherAcc':
                    this.jobsList.switchOffFilterPanelIndicator(true);
                    if (this.jobsList._opened && this.smallSize) {
                        this.jobsList._opened = false;
                    } else {
                        this.jobsList.revertToNews();
                    }
                    this.multiselectActionModal.hide();
                    break;
                case 'subaccount':
                    this.jobsList.switchOffFilterPanelIndicator(true);
                    if (this.jobsList._opened && this.smallSize) {
                        this.jobsList._opened = false;
                    } else {
                        this.jobsList.revertToNews();
                    }
                    this.multiselectActionModal.hide();
                    putObject = {subAccount: event.container};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('jobs.commons.subAccountChange', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.subAccountChangeTitle');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.wasError');

                    break;
                case 'department':
                    this.jobsList.switchOffFilterPanelIndicator(true);
                    if (this.jobsList._opened && this.smallSize) {
                        this.jobsList._opened = false;
                    } else {
                        this.jobsList.revertToNews();
                    }
                    this.multiselectActionModal.hide();
                    putObject = {department: event.container};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('jobs.commons.depChange', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.depChangeTitle');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.wasError');

                    break;
                case 'archive':
                    putObject = {archive: true};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('jobs.commons.multipleArchivedJob', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.multipleArchiving');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.archivingError');

                    break;
                case 'reactivate':
                    putObject = {archive: false};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('jobs.commons.multipleReactivatedJob', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.multipleReactivation');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.reactError');

                    break;
            }
            if (this.action === 'archive') {
                this.apiJobs.checkDelete(event.ids).subscribe(
                    res => {
                        // console.log(event.ids);
                        let pAction = this.translate.instant('commons.archivingAction');
                        plural = (total > 1) ? 's' : '';
                        let paragraph: string;
                        if (Array.isArray(res) && res.length === 0) {
                            paragraph = this.translate.instant('jobs.commons.jobActionConfirmation', {action: pAction, total: total, plural: plural});
                        } else {
                            paragraph = this.translate.instant('jobs.commons.multiJobLinkedAnalysisConfirmation', {action: pAction, res: ''});
                        }

                        let warnMessage = {
                            paragraph: paragraph,
                            texth1: 'Confirmation',
                            position: 'center-center',
                            template: 'warningConfirm'
                        };

                        this.alertService.setConfig(warnMessage);
                        this.subscriptions.add(this.alertService.nextAction.subscribe(
                            res2 => {
                                if (res2 === 'confirm' && this.action === 'archive') {
                                    this.actionLoop(event, putObject, handled, total, p, h1);
                                    this.action = undefined;

                                    this.alertService.nextAction = undefined;
                                    this.alertService.nextAction$ = undefined;

                                    this.alertService.nextAction = new BehaviorSubject(null);
                                    this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                                }
                            }
                        ));
                    });
            } else if (this.action === 'reactivate') {
                let pAction = this.translate.instant('commons.reactAction');
                plural = (total > 1) ? 's' : '';
                const txtArchivingConfirmation = {
                    paragraph: this.translate.instant('jobs.commons.jobActionConfirmation', {action: pAction, total: total, plural: plural}),
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.subscriptions.add(this.alertService.nextAction.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                ));
            } else if (this.action === 'transferOtherAcc') {
                let successMessage;
                let errorMessage;
                event.ids.forEach((indId) => {
                    let originAccount = (this.sessionData && this.sessionData.accountData) ? this.sessionData.accountData.accountNumber : null;
                    if (indId && originAccount && event.container) {
                        let suffix = 'id_job=' + indId + '&' + 'origin_account=' + originAccount + '&' + 'dest_account=' + event.container;
                        this.subscriptions.add(this.httpClient.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/transferJobs?' + suffix).subscribe(
                            (res) => {
                                this.jobsList.revertToNews();
                                this.jobsList.cleanMultiselectMode(true);
                                if (res['result'] === 'success') {
                                    if (!successMessage) {
                                        successMessage = TextHelper.accountTransferConfirm(this.translate.currentLang);
                                        this.alertService.setConfig(successMessage);
                                    }
                                    errorMessage = null;
                                } else {
                                    if (!errorMessage) {
                                        errorMessage = TextHelper.errorMessage(this.translate.currentLang);
                                        this.alertService.setConfig(errorMessage);
                                    }
                                    successMessage = null;
                                }
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.jobsList.revertToNews();
                                this.jobsList.cleanMultiselectMode(true);
                                if (!errorMessage) {
                                    errorMessage = TextHelper.errorMessage(this.translate.currentLang);
                                    this.alertService.setConfig(errorMessage);
                                }
                                successMessage = null;
                                this.changeDetectorRef.markForCheck();
                            })
                        );
                    }
                });
            } else if (this.action) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }
        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.action) {
            let handledCounter = handled;
            for (let id of event.ids) {
                this.apiJobs.putJob([id], putObject).subscribe(
                    () => {
                        handledCounter++;
                        if (handledCounter === total) {
                            this.jobsList.loading = true;
                            this.jobsList.getJobList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            });
                        }
                        this.jobsList.cleanMultiselectMode(true);
                        this.jobsList.revertToNews();
                    },
                    () => {
                        this.jobsList.loading = true;
                        this.jobsList.getJobList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        });
                    }
                );
            }
            this.action = undefined;
        }
    }

    scrollJobsModalTop(elem) {
        elem.nativeElement.scrollIntoView({behavior: 'smooth'});
    }

    refreshList() {
        this.jobsList.getJobList(true);
    }

    refreshWindow() {
        const warning = {
            texth1: this.translate.instant('jobs.sidebar.targetPersonalityProfileWarningTitle'),
            paragraph: this.translate.instant('jobs.sidebar.targetPersonalityProfileWarning'),
            template: 'freezeAction'
        };
        this.alertService.setConfig(warning);
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm') {
                    this.refreshList();
                }
            }
        ));
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.subscriptions.unsubscribe();
    }

    checkIsPreferenceSet() {
        return this.userPreferencesService.isPreferenceSet(this.stateService.session.actualModule);
    }

    get sessionData() {
        if (this.stateService.session) {
            return this.stateService.session.sessionData;
        }
        return;
    }

    /**
     * Close jobs sidebar tablet device
     */

    closeJobsSidebar(closeJobsSidebar: boolean): void {
        this.jobsList.jobsState.lastSideBarStatus = 'recentActivities';
        if (closeJobsSidebar && this.jobsList._opened && this.smallSize) {
            this.jobsList._opened = false;
            this.closed = true;
        }
    }

}
