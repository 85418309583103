import {Injectable} from '@angular/core';
import {ContentItem} from '../../../shared/classes/services/content_item';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';
import {take} from 'rxjs/operators';
import {TalentsDefinitions} from '../../../shared/talents/talentsDefinitions';
import * as _ from 'lodash';
import {ApiJobSatisfactionsService} from './api-job-satisfactions.service';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class JobSatisfactionsAnalysisService extends ContentItem {

    private jobSatisfaction;

    readonly persistentVariables = [
        'jobSatisfaction'
    ];

    setRecordsWatch = new BehaviorSubject(null);
    setRecordsWatch$ = this.setRecordsWatch.asObservable();

    constructor(
        private apiJobSatisfactionsService: ApiJobSatisfactionsService,
        private translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('jobSatisfactionService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setItemToDisplayWithId(id, specificState = 'jobSatisfaction', action = null) {
        if (!id) {
            return;
        }

        let obsResult = {};
        let jobSatisfactionObservable = this.apiJobSatisfactionsService.jobSatisfaction([id]).pipe(take(1)).subscribe(
            res => {
                if (res) {
                    this.jobSatisfaction = res;
                    this.storeServiceData('jobSatisfactionService', this.persistentVariables, this);
                    this.stateService[specificState].itemToDisplayId = res.id;
                    if (action === 'forceSidebarToReport' && this.isRightModule(specificState)) {
                        obsResult = this.resetSidebarToReport(specificState);
                    } else if (action === 'resetSidebarToFilters') {
                        this.stateService[specificState].sideBarStatus = 'searchFilters';
                    } else {
                        this.stateService[specificState].sideBarStatus = 'recentActivities';
                    }
                    obsResult['itemToDisplayId'] = res.id;
                    this.stateService[specificState].stateChanged.next(obsResult);
                    jobSatisfactionObservable.unsubscribe();
                    if (action === 'mobileView') {
                        this.router.navigate(['m/analysis/jobsatisfaction/report/' + res.id]).then(() => {
                        });
                    }
                }
            }
        );
    }

    isRightModule(specificState) {
        if (specificState === 'jobSatisfaction' && !this.archive) {
            return true;
        }
        return !!(specificState === 'archivesJobSatisfaction' && this.archive);
    }

    resetItemToDisplay(specificState = 'jobSatisfaction') {
        this.itemToDisplay = {};
        this.stateService[specificState].stateChanged.next({
            'itemDeleted': true
        });
        this.stateService[specificState].report.itemToDisplayId = undefined;
        if (this.isMobile) {
            this.router.navigate(['m/analysis/jobsatisfaction/list']).then(() => {
            });
        } else {
            this.stateService.jobSatisfaction.sideBarStatus = 'recentActivities';
            this.stateService.jobSatisfaction.sideBarStatus = 'recentActivities';
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get itemToDisplay(): any {
        return _.get(this, 'jobSatisfaction');
    }

    set itemToDisplay(item) {
        this.jobSatisfaction = item;
        this.storeServiceData('jobSatisfactionService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'jobSatisfaction.id');
    }

    get name(): string {
        return _.get(this, 'jobSatisfaction.name');
    }

    get consultant(): any {
        return _.get(this, 'jobSatisfaction.consultant');
    }

    get date() {
        let date = _.get(this, 'jobSatisfaction.date');
        if (date !== null && date !== undefined) {
            if (date.sec) {
                date = date.sec * 1000;
            }
            if (date.hasOwnProperty('sec') && !date.sec) {
                return;
            }
            return ('en' === this.translateService.currentLang) ?
                moment(new Date(date)).format('MM/DD/YYYY') :
                moment(new Date(date)).format('DD/MM/YYYY');
        } else {
            return;
        }

    }

    get subAccount(): any {
        return _.get(this, 'jobSatisfaction.subAccount');
    }

    set records(records) {
        this.jobSatisfaction.records = records;
        this.setRecordsWatch.next(records);
        this.storeServiceData('jobSatisfactionService', this.persistentVariables, this);
    }

    get records(): any {
        return _.get(this, 'jobSatisfaction.records');
    }

    get department(): any {
        let department = _.get(this, 'jobSatisfaction.department');
        if (department !== undefined && department.length > 0) {
            let currentLang = this.translateService.currentLang;
            if (currentLang === '' || currentLang === undefined) {
                currentLang = 'fr';

            }
            department = department[0][currentLang];
        }
        return department;
    }

    get archive() {
        return _.get(this, 'jobSatisfaction.archive');
    }

    get circle() {
        return _.get(this, 'jobSatisfaction.circle');
    }

    get subCircle() {
        return _.get(this, 'jobSatisfaction.subCircle');
    }
}
