// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-header-container .mobile-nav-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mobile-header-container .mobile-search-bar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  padding: 0 10px;
}
.mobile-header-container .mobile-search-bar-container .mat-mdc-form-field-flex {
  padding-bottom: 0 !important;
}

.mobile-header-logo {
  width: 30% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/shared/mobile/mobile-header/mobile-header.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AAER;AADQ;EACI,4BAAA;AAGZ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".mobile-header-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    .mobile-nav-logo-container {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n    }\n    .mobile-search-bar-container {\n        display: flex;\n        justify-content: flex-end;\n        align-items: center;\n        flex: 1 1 auto;\n        padding: 0 10px;\n        .mat-mdc-form-field-flex {\n            padding-bottom: 0!important;\n        }\n    }\n}\n\n.mobile-header-logo {\n    width: 30%!important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
