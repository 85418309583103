export class EvaluationHelper {

    /**
     * Get the PRB related index
     * @param evaluations evaluations object
     */
    public static getPrbRelatedFromEvaluations(evaluations: any): number | null {
        const mpo: any = evaluations['mpo'];
        if (mpo && mpo.info.hasOwnProperty('prbRelatedIndex')) {
            return mpo.info.prbRelatedIndex;
        }

        return null;
    }

    public static getPrbEnableParams(type: string, prbRelatedIndex: number | null): any {
        let prbParams = {};
        if (type === 'mpo' && prbRelatedIndex !== null) {
            prbParams = {prbIndex: prbRelatedIndex, variant: '1,1'};
        }

        return prbParams;
    }
}
