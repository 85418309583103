import { Injectable } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';
import {JobSatisfactionsAnalysisService} from './job-satisfactions-analysis.service';
import {JobSatisfactionsListService} from './job-satisfactions-list.service';
import {Content} from '../../../shared/classes/services/content';
import { JobSatisfactionsModalComponent } from '../components/web/job-satisfactions-modal/job-satisfactions-modal.component';
import { JobSatisfactionsPersonModalComponent } from '../components/web/job-satisfactions-person-modal/job-satisfactions-person-modal.component';
import { JobSatisfactionsReportsFiltersModalComponent } from '../components/web/job-satisfactions-reports-filters-modal/job-satisfactions-reports-filters-modal.component';
import {
    JobSatisfactionsPdfModalComponent
} from '../components/web/job-satisfactions-pdf-modal/job-satisfactions-pdf-modal.component';

@Injectable({
  providedIn: 'root'
})
export class JobSatisfactionsModalHandlingService extends Content {

    jobSatisfactionModalStatus = 'new';
    jsModalRef: BsModalRef;
    pdfModalRef: BsModalRef;
    personModalRef: BsModalRef;
    reportsFiltersModalRef: BsModalRef;

    constructor(
        protected router: Router,
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        public jobSatisfactionsListService: JobSatisfactionsListService,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    openJobSatisfactionModal(config: any) {
        this.jobSatisfactionModalStatus = config.status;
        this.jobSatisfactionsListService.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.jobSatisfactionsListService.multiAction,
            status: this.jobSatisfactionModalStatus
        };
        this.jsModalRef = this.modalService.show(JobSatisfactionsModalComponent, {initialState, backdrop : 'static'});
        this.jsModalRef.content.multiActionDestroy.subscribe(res => {
            if (res.destroy) {
                this.jobSatisfactionsListService.multiAction = null;
            }
        });

        this.jsModalRef.content.close.subscribe(res => {
            if (res.closed && res.id) {
                // if (res.profilerHasChanged) {
                this.jobSatisfaction.setItemToDisplayWithId(res.id, 'jobSatisfaction', 'forceSidebarToReport');
                // }
                this.jobSatisfactionsListService.getJobSatisfactionList(true);
            }
        });
    }

    /**
    openRightMatchPdfModal() {
        this.jobSatisfactionsListService.cleanMultiselectMode(true);
        this.pdfModalRef = this.modalService.show(RightMatchesPdfModalComponent, {backdrop : 'static'});
    }*/

    openJobSatisfactionPersonModal() {
        this.jobSatisfactionsListService.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.jobSatisfactionsListService.multiAction
        };
        this.personModalRef = this.modalService.show(JobSatisfactionsPersonModalComponent, {initialState, backdrop : 'static'});
        this.personModalRef.content.close.subscribe(res => {
            if (res.closed) {
                this.jobSatisfaction.setItemToDisplayWithId(res.id);
            }
        });
    }

    openJobSatisfactionPdfModal() {
        this.pdfModalRef = this.modalService.show(JobSatisfactionsPdfModalComponent, {backdrop : 'static'});
        return this.pdfModalRef.content.close;
    }

    openJobSatisfactionReportsFiltersModal() {
        this.reportsFiltersModalRef = this.modalService.show(JobSatisfactionsReportsFiltersModalComponent, {backdrop : 'static'});
        return this.reportsFiltersModalRef.content.close;
    }
}
