import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../core/services/state/state.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-jobs-pdf',
    templateUrl: './mobile-jobs-pdf.component.html',
    styleUrls: ['./mobile-jobs-pdf.component.scss']
})
export class MobileJobsPdfComponent implements OnChanges, OnDestroy {

    @Input() raPermission = false;
    @Output() close = new EventEmitter();

    pdfStructure = {
        jobDesign: undefined,
        profile: undefined,
        graph: undefined,
        competences: undefined,
        intQuestions: undefined,
        ra: undefined
    };
    pdfEmail = {
        emailTo: '',
        subject: '',
        message: '',
    };
    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    mailto: string;
    serverText: string;

    selectMethod = false;

    method = 'pdf';
    share = 'none';

    private subscriptions = new Subscription();

    constructor(
        public jobService: JobService,
        private apiJobsService: ApiJobsService,
        private changeDetectorRef: ChangeDetectorRef,
        private windowRef: WindowRef,
        public translate: TranslateService,
        public state: StateService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnChanges() {
        this.initPdfStructure();
    }

    hasJobDesign() {
        // noinspection OverlyComplexBooleanExpressionJS
        if ( this.jobService.jobDescription &&
            (
                this.jobService.purposeOfThePosition ||
                (this.jobService.tasks && this.jobService.tasks.length !== 0)
            )
        ) {
            return 1;
        }
        return 0;
    }

    hasProfile() {
        if (
            this.jobService.score &&
            this.jobService.score.length > 0
        ) {
            return 1;
        }
        return 0;
    }

    hasCompetencies() {
        if (
            this.jobService.competences &&
            this.jobService.competences.length > 0
        ) {
            return 1;
        }
        return 0;
    }

    hasRa() {
        if (this.jobService.raScore) {
            return 1;
        }
        return 0;
    }

    initPdfStructure() {

        // PDF structure
        this.pdfStructure = Object.assign({}, {
            jobDesign: this.hasJobDesign(),
            profile: this.hasProfile(),
            graph: this.hasProfile(),
            competences: this.hasCompetencies(),
            intQuestions: this.hasCompetencies(),
            ra: this.hasRa()
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: this.translate.instant('jobs.pdfModal.jobReport'),
            message: '',
        });

        // PDF link
        this.pdfToken = null;
        this.pdfReportUrl = this.environment.pdfReportUrl;
    }

    getProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    getJobReportData(data: any) {
        let sectionRequested = [];

        if (this.pdfStructure.jobDesign === 1) {
            sectionRequested.push('jobDesign');
        }
        if (this.pdfStructure.profile === 1) {
            sectionRequested.push('profile');
        }
        if (this.pdfStructure.graph === 1) {
            sectionRequested.push('graph');
        }
        if (this.pdfStructure.competences === 1) {
            sectionRequested.push('competences');
        }
        if (this.pdfStructure.intQuestions === 1) {
            sectionRequested.push('intQuestions');
        }

        let obs = this.apiJobsService.reportJob([this.jobService.id], [
            sectionRequested,
            0,
            this.translate.currentLang
        ]);


        this.subscriptions.add(obs.subscribe(
            res => {
                this.pdfToken = encodeURI(res.pdfToken);

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + this.pdfToken;
                let emailSubject = this.translate.instant('jobs.pdfModal.jobReportFor', {
                    jobTitle: this.jobService.jobTitle
                });
                let linkInstruction = this.translate.instant('jobs.pdfModal.linkInstruction', {
                    jobTitle: this.jobService.jobTitle
                });

                if ('mailto' === data.type) {
                    this.mailto = '?subject=' + emailSubject + '&body=' + linkInstruction + '%0A%0A'
                        + this.pdfReportUrl;
                } else if ('share' === data.type) {
                    this.serverText = linkInstruction +
                        `

                        ` + this.pdfReportUrl + this.pdfToken;
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.onComplete();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getJobReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = this.translate.instant('jobs.pdfModal.jobReport');
        }
        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.jobService.jobTitle,
            'jobs',
            this.translate.currentLang
        ];

        this.subscriptions.add(this.apiJobsService.reportEmail(dataEmail).subscribe());

        this.onComplete();
    }

    handleReport() {
        switch (this.method) {
            case 'pdf':
                this.downloadReport();
                return;
            case 'cutpaste':
                this.share = 'cutpaste';
                this.getJobReportData({type: 'token'});
                return;
            case 'mailto':
                this.share = 'mailto';
                this.getJobReportData({type: 'mailto'});
                return;
            case 'server':
                this.share = 'server';
                this.getJobReportData({type: 'share'});
                return;
        }
        this.selectMethod = true;
        return;

    }

    changeSelectMethodState() {
        if (this.method !== 'none') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
    }

    get testAccessPermissions() {
        return this.state.session.sessionData.structure.testAccessPermissions;
    }

    onComplete() {
        this.initPdfStructure();
        this.method = 'pdf';
        this.share = 'none';
        this.selectMethod = false;
        this.close.emit();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


}
