// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 25px 15px;
}

.failure, .warning, .success {
  padding: 10px 0;
}

.failure, .warning {
  color: red;
}

.success {
  color: green;
}

table#profilesTransferForm tr td {
  padding: 3px;
}

input[type=text] {
  margin-left: 10px;
  width: 230px;
}`, "",{"version":3,"sources":["webpack://./src/app/tools/components/profile-transfers/profile-transfers.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,iBAAA;EACA,YAAA;AACJ","sourcesContent":[".content {\n    padding: 25px 15px;\n}\n\n.failure, .warning, .success {\n    padding: 10px 0;\n}\n\n.failure, .warning {\n    color: red;\n}\n\n.success {\n    color: green;\n}\n\ntable#profilesTransferForm tr td {\n    padding: 3px;\n}\n\ninput[type=text] {\n    margin-left: 10px;\n    width: 230px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
