import { Injectable } from '@angular/core';
import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    constructor(private translate: TranslateService) {
        super(translate.currentLang);
    }

    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            
            return this.translate.currentLang === 'fr'
            ? this.formatDate(day, month, year)
            : this.formatDate(month, day, year);
        }
         else {
            return date.toLocaleDateString(this.translate.currentLang, {
                weekday: 'short', // ex: MON/LUN
                day: 'numeric', // ex: 01
                month: 'short', // ex: FEB/FEV
                year: 'numeric' // ex: 2024
            });
         }
    }

    private formatDate(firstNumber: number, secondNumber: number, year: number): string {
        return `${this.convertTo2digit(firstNumber)}/${this.convertTo2digit(secondNumber)}/${year}`;
    }

    private convertTo2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
