import {ChangeDetectorRef, Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../../app/services/env.service';

@Component({
    selector: 'app-profile-transfers',
    templateUrl: './profile-transfers.component.html',
    styleUrls: ['./profile-transfers.component.scss']
})
export class ProfileTransfersComponent {

    id_ind: string;
    origin_account: string;
    dest_account: string;

    transferStatus = null;

    warning = {
        id_ind: false,
        origin_account: false,
        dest_account: false
    };

    subscriptions = new Subscription();

    constructor(
        private httpClient: HttpClient,
        private cd: ChangeDetectorRef,
        private environment: EnvService
    ) {
    }


    transfer() {
        this.warning = {
            id_ind: false,
            origin_account: false,
            dest_account: false
        };

        if (this.id_ind && this.origin_account && this.dest_account) {

            this.transferStatus = null;
            let suffix = 'id_ind=' + this.id_ind + '&' + 'origin_account=' + this.origin_account + '&' + 'dest_account=' + this.dest_account;
            this.subscriptions.add(this.httpClient.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/transferScript?' + suffix).subscribe(
                (res) => {
                    if (res['result'] === 'success') {
                        this.transferStatus = 'success';
                        this.cd.markForCheck();
                    } else {
                        this.transferStatus = 'failure';
                        this.cd.markForCheck();
                    }
                },
                () => {
                    this.transferStatus = 'failure';
                    this.cd.markForCheck();
                }
            ));

        } else {
            this.transferStatus = 'warning';
            this.warning.id_ind = !!(!this.id_ind);
            this.warning.origin_account = !!(!this.origin_account);
            this.warning.dest_account = !!(!this.dest_account);
        }

        this.cd.markForCheck();
    }

}
