import {Injectable} from '@angular/core';
import {EnvService} from '../../../app/services/env.service';

@Injectable()
export class ApiUploadFiles {

    apiBaseUrl: string;

    constructor(
        protected environment: EnvService
    ) {
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    /**
     * Upload file promise
     */
    uploadRequestPromise(url, formData, method = 'POST') {
        return new Promise(function (resolve, reject) {
            let apiBaseUrl: string = this.apiBaseUrl + 'api/';
            let bearerToken = 'Bearer ' + localStorage.getItem('id_token');

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open(method, apiBaseUrl + url, true);
            xhr.setRequestHeader('Authorization', bearerToken);
            xhr.send(formData);
        });
    }

}
