import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Rest} from 'src/app/core/services/api/rest';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../app/services/env.service';

@Injectable()
export class ApiAdministrationService extends Rest {

    constructor(
        protected http: HttpClient,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    structure(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'structure/clients',
            null,
            dataParams,
            ['clientTypesUpTo', 'clientTypeFilter']
        );
    }

    accountParentsForType(dataParams: Array<string>): Observable<any> {
        return this.apiGet(
            'accounts',
            null,
            dataParams,
            ['parentListForType']
        );
    }

    hierarchy(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'account/hierarchy',
            urlParams,
            null,
            null
        );
    }

    descendents(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'account/descendents',
            urlParams,
            null,
            null
        );
    }

    client(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'account',
            urlParams,
            null,
            null
        );
    }

    clients(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'accounts',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    clientsSwitch(urlParams: Array<string>) {
        return this.apiGet(
            'account/UrlParameter0/switch',
            urlParams,
            null,
            null
        );
    }

    clientSubaccountCredits(urlParams: Array<string>) {
        return this.apiGet(
            'account/UrlParameter0/subaccount/UrlParameter1/credits',
            urlParams,
            null,
            null
        );
    }

    clientSubaccountsCredits(urlParams: Array<string>) {
        return this.apiGet(
            'account/UrlParameter0/subaccounts/credits',
            urlParams,
            null,
            null
        );
    }

    frontVersion(): Observable<any> {
        return this.apiGet(
            'front-version',
            null,
            null,
            null
        );
    }

    file(dataParams: Array<string>): Observable<any> {
        return this.apiGet(
            'file',
            null,
            dataParams,
            ['type', 'filename']
        );
    }

    productEnable(urlParams: Array<any>, dataParams: Array<string>) {
        return this.apiGet(
            'product/UrlParameter0/enable',
            urlParams,
            dataParams,
            ['subAccount', 'recordId', 'evaluationIndex', 'prbIndex', 'variant']
        );
    }

    purchaseAdjustment(dataParams: Array<string>) {
        return this.apiGet(
            'purchase/adjustement',
            null,
            dataParams,
            ['credits', 'accountNumber', 'subAccount', 'extraDescription']
        );
    }

    products(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'products',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    productsClient(urlParams: Array<string>) {
        return this.apiGet(
            'products/client/UrlParameter0',
            urlParams,
            null,
            null
        );
    }

    user(urlParams: Array<string>) {
        return this.apiGet(
            'user',
            urlParams,
            null,
            null
        );
    }

    users(dataParams: Array<any>) {
        if (dataParams[0].accountNumber[0] === undefined) return;
        return this.apiGet(
            'users',
            null,
            dataParams,
            /*
            ['search', 'order', 'skip', 'limit', 'accountNumber', 'subAccount', 'creationStartDate', 'creationEndDate',
                'isArchived']
            */
            ['@fromObject']
        );
    }

    usernameIsValid(urlParams: Array<string>) {
        return this.apiGet(
            'validateUsername',
            urlParams,
            null,
            null
        );
    }

    actionLogs(dataParams: Array<string>) {
        return this.apiGet(
            'action-logs',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    purchases(dataParams: Array<string>) {
        return this.apiGet(
            'purchases',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    departments(dataParams: Array<string>) {
        return this.apiGet(
            'departments',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    updateTransaction(urlParams: Array<string>, dataParams: Array<any>) {
        dataParams = (Array.isArray(dataParams)) ? dataParams : [dataParams];
        return this.apiPut(
            'transaction/UrlParameter0',
            urlParams,
            dataParams
        );
    }

    transactions(urlParams: Array<string>, dataParams: Array<any>) {
        if (dataParams['subAccount'] && dataParams['subAccount'][0] === null) {
            delete dataParams['subAccount'];
        }
        dataParams = (Array.isArray(dataParams)) ? dataParams : [dataParams];
        return this.apiGet(
            'transactions/UrlParameter0',
            urlParams,
            dataParams,
            ['@fromObject']
        );
    }

    postAccount(data): Observable<any> {
        return this.apiPost(
            'account',
            null,
            data
        );
    }

    postUser(data): Observable<any> {
        return this.apiPost(
            'user',
            null,
            data
        );
    }

    deleteTransaction(urlParams: [string], data: any): Observable<any> {
        if (!Array.isArray(data) ) {
            data = [data];
        }
        return this.apiDelete(
            'transaction/UrlParameter0',
            urlParams,
            data,
            ['@fromObject']
        );
    }

    subAccount(urlParams: any, data: any, put = false): Observable<any> {
        if (put) {
            return this.apiPut(
                'account/UrlParameter0/subaccount/UrlParameter1',
                urlParams,
                data
            );
        } else {
            return this.apiPost(
                'account/UrlParameter0/subaccount',
                urlParams,
                data
            );
        }
    }

    moveCredit(data): Observable<any> {
        return this.apiPost(
            'transaction/move-subaccount-credit',
            null,
            data
        );
    }

    postPurchaseProduct(data): Observable<any> {
        return this.apiPost(
            'purchase/product',
            null,
            data
        );
    }

    postPurchaseAdjustment(data): Observable<any> {
        return this.apiPost(
            'purchase/adjustement',
            null,
            data
        );
    }

    addFiles(urlParams: [string], data): Observable<any> {
        return this.apiPost(
            'client/UrlParameter0/add-files',
            urlParams,
            data
        );
    }

    addSubAccountFiles(urlParams: [string, string], data): Observable<any> {
        return this.apiPost(
            'account/UrlParameter0/subaccount/UrlParameter1/add-files',
            urlParams,
            data
        );
    }

    addUserFiles(urlParams: [string], data): Observable<any> {
        return this.apiPost(
            'user/UrlParameter0/add-files',
            urlParams,
            data
        );
    }

    postDepartment(data): Observable<any> {
        return this.apiPost(
            'department',
            null,
            data
        );
    }

    putAccount(urlParams: [string], account: any): Observable<any> {
        return this.apiPut(
            'account/UrlParameter0',
            urlParams,
            account
        );
    }

    putUser(urlParams: [string], user: any): Observable<any> {
        return this.apiPut(
            'user/UrlParameter0',
            urlParams,
            user
        );
    }

    putDepartment(urlParams: [string], department: any): Observable<any> {
        return this.apiPut(
            'department/UrlParameter0',
            urlParams,
            department
        );
    }

    deleteDepartment(urlParams: [any, any]): Observable<any> {
        return this.apiDelete(
            'department/UrlParameter0/account/UrlParameter1',
            urlParams
        );
    }

    deleteAccount(urlParams: [string]): Observable<any> {
        return this.apiDelete(
            'account',
            urlParams
        );
    }

    deleteSubAccount(urlParams: [string, number], data): Observable<any> {
        return this.apiPut(
            'delete/account/UrlParameter0/subaccount/UrlParameter1',
            urlParams,
            data
        );
    }

    deleteUser(urlParams: [string]): Observable<any> {
        return this.apiDelete(
            'user',
            urlParams
        );
    }

    deleteFile(urlParams: [string, number, any]): Observable<any> {
        return this.apiDelete(
            'client/UrlParameter0/subaccount/UrlParameter1/file/UrlParameter2',
            urlParams
        );
    }

    deleteUserFile(urlParams: [any, any]): Observable<any> {
        return this.apiDelete(
            'user/UrlParameter0/file/UrlParameter1',
            urlParams
        );
    }
}
