import {ChangeDetectorRef, Component, Input, Directive} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';
import {Subscription} from 'rxjs';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ApiPeopleService} from '../../services/api-people.service';
import {PersonService} from '../../services/person.service';

@Directive()
export abstract class PeoplePdfModalComponentCommon {

    @Input() report: any;

    pdfStructure: any;
    graphOnly = false;
    pdfEmail: any;
    readyToContinue = false;

    personalityMatch = {
        index: 1,
        behavior: 2,
        portrait: 3,
        prb: 4
    };

    satelliteMatch = {
        on: 3
    };

    communicationMatch = {
        on: 3
    };

    talentsMatch = {
        on: 3
    };

    iacMatch = {
        on: 3
    };

    mailto: string;
    mailToJson: any;
    pdfToken: string;
    pdfReportUrl: string;

    reportTypeTalent = 'analyst';

    hasProfilerAndIsAnalyst = false;

    reportName: string;

    public subcriptions = new Subscription();

    protected constructor(
        protected translate: TranslateService,
        protected state: StateService,
        protected apiJobService: ApiJobsService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiPeopleService: ApiPeopleService,
        public person: PersonService
    ) {
    }

    initPdfStructure(changes = null) {
        // PDF structure
        this.pdfStructure = Object.assign({}, {
            reportType: 'analyst',
            multiple: '0',
            personality: {},
            communication: {},
            satellite: {},
            talents: {},
            iac: {}
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: this.translate.instant('commons.mpoReportTitle'),
            message: '',
        });

        this.pdfStructure.report = (changes && changes.report) ? this.report : this.state.people.reportType;

        this.reportChange();

        // Default
        this.pdfStructure.reportType = 'analyst';

        // PDF link
        this.mailto = null;
        this.mailToJson = null;
        this.pdfToken = null;
        this.reportName = null;
        this.pdfReportUrl = null;

        this.sideMenuChanges(this.person.jobId);
        this.checkReadinessToContinue();
    }

    checkReadinessToContinue() {
        let report = this.pdfStructure.report;
        if (report === 'personality') {
            report = 'mpo';
        }
        if (report === 'communication') {
            report = 'dit';
        }
        this.readyToContinue = (this.pdfStructure.report && this.person.evaluations[report]);
    }

    private saveAndRestoreSections() {
        const backupSuffix = '_backup';

        if (this.pdfStructure['reportType'] === '1' || this.pdfStructure['reportType'] === 'analyst') {
            // Analyst version of the report, reset the values with what was selected

            // Loop through the different report type structures, to search for values to be restored
            ['personality', 'iac', 'talents'].forEach(reportType => {
                if (this.pdfStructure[reportType] != null) {
                    for (let [key, value] of Object.entries(this.pdfStructure[reportType])) {
                        // Restore values
                        if (key.endsWith(backupSuffix)) {
                            this.pdfStructure[reportType][key.replace(backupSuffix, '')] = value;
                        }
                    }
                }
            });
        } else {
            if (this.graphOnly) {
                this.toggleGraphOnly();
            }

            // General version of the report, backup values and set default
            if (this.pdfStructure.personality != null) {
                this.pdfStructure.personality['homepage' + backupSuffix] = this.pdfStructure.personality['homepage'];
                this.pdfStructure.personality['homepage'] = true;

                this.pdfStructure.personality['warning' + backupSuffix] = this.pdfStructure.personality['warning'];
                this.pdfStructure.personality['warning'] = true;

                this.pdfStructure.personality['extraPoints' + backupSuffix] = this.pdfStructure.personality['extraPoints'];
                this.pdfStructure.personality['extraPoints'] = true;

                this.pdfStructure.personality['graph' + backupSuffix] = this.pdfStructure.personality['graph'];
                this.pdfStructure.personality['graph'] = false;
            }

            if (this.pdfStructure.talents != null) {
                this.pdfStructure.talents['homepage' + backupSuffix] = this.pdfStructure.talents['homepage'];
                this.pdfStructure.talents['homepage'] = true;

                this.pdfStructure.talents['presentation' + backupSuffix] = this.pdfStructure.talents['presentation'];
                this.pdfStructure.talents['presentation'] = true;

                this.pdfStructure.talents['interpretation' + backupSuffix] = this.pdfStructure.talents['interpretation'];
                this.pdfStructure.talents['interpretation'] = true;
            }
        }

    }

    sideMenuChanges(job, type = null) {
        this.saveAndRestoreSections();

        if (this.pdfStructure.report === 'talents') {
            this.pdfStructure.reportType = this.reportTypeTalent;
            if (typeof job === 'string' && job !== '') {
                this.subcriptions.add(this.apiJobService.job([job]).subscribe(
                    (res) => {
                        if (res.competences && res.competences.length > 0) {
                            this.pdfStructure.talents.jobVsCandidate = (this.reportTypeTalent === 'manager' || this.reportTypeTalent === 'analyst');
                            this.pdfStructure.talents.interviewQuestions = (this.reportTypeTalent === 'manager' || this.reportTypeTalent === 'analyst');
                            this.hasProfilerAndIsAnalyst = (this.reportTypeTalent === 'manager' || this.reportTypeTalent === 'analyst');
                        } else {
                            this.pdfStructure.talents.jobVsCandidate = false;
                            this.pdfStructure.talents.interviewQuestions = false;
                            this.hasProfilerAndIsAnalyst = false;
                        }
                        this.changeDetectorRef.markForCheck();
                    },
                    () => {
                        this.pdfStructure.talents.jobVsCandidate = false;
                        this.pdfStructure.talents.interviewQuestions = false;
                        this.hasProfilerAndIsAnalyst = false;
                    }
                ));
            } else {
                if (this.pdfStructure.talents) {
                    this.pdfStructure.talents.jobVsCandidate = false;
                    this.pdfStructure.talents.interviewQuestions = false;
                } else {
                    this.pdfStructure.talents = {
                        on: true,
                        homepage: true,
                        presentation: true,
                        interpretation: true,
                        generalView: true,
                        aptitudeSpheres: true,
                        compReport: true,
                        index: true,
                        jobVsCandidate: false,
                        interviewQuestions: false
                    };
                }
                this.hasProfilerAndIsAnalyst = false;
            }
        } else if (this.pdfStructure.report === 'personality') {
            if (type === 'analyst') {
                this.pdfStructure.personality = {
                    on: true,
                    homepage: true,
                    warning: true,
                    index: true,
                    behavior: true,
                    portrait: true,
                    extraPoints: true,
                    prb: !!(this.person.evaluations.prb),
                    graph: true
                };
            } else if (type === 'manager') {
                this.pdfStructure.personality = {
                    on: true,
                    homepage: true,
                    warning: true,
                    index: true,
                    behavior: true,
                    portrait: true,
                    extraPoints: true,
                    prb: false,
                    graph: false
                };
            } else if (type === 'respondent') {
                this.pdfStructure.personality = {
                    on: true,
                    homepage: true,
                    warning: true,
                    index: false,
                    behavior: false,
                    portrait: true,
                    extraPoints: true,
                    prb: false,
                    graph: false
                };
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     *
     */
    reportChange() {
        this.checkReadinessToContinue();
        if (this.pdfStructure.report === 'personality') {
            this.graphOnly = false;
            this.pdfStructure.talents = undefined;
            this.pdfStructure.communication = undefined;
            this.pdfStructure.satellite = undefined;
            this.pdfStructure.iac = undefined;
            this.pdfStructure.reportType = 'analyst';
            this.pdfStructure.personality = {
                on: true,
                homepage: true,
                warning: true,
                index: true,
                behavior: true,
                portrait: true,
                extraPoints: true,
                prb: !!(this.person.evaluations.prb),
                graph: true
            };
            return;
        }
        if (this.pdfStructure.report === 'iac') {
            this.pdfStructure.personality = undefined;
            this.pdfStructure.talents = undefined;
            this.pdfStructure.communication = undefined;
            this.pdfStructure.satellite = undefined;
            this.pdfStructure.reportType = undefined;
            this.pdfStructure.iac = {
                on: true,
                homepage: true,
                warning: true,
                results: true
            };
            return;
        }
        if (this.pdfStructure.report === 'talents') {
            this.pdfStructure.personality = undefined;
            this.pdfStructure.communication = undefined;
            this.pdfStructure.satellite = undefined;
            this.pdfStructure.iac = undefined;
            this.pdfStructure.reportType = '1';
            this.pdfStructure.talents = {
                on: true,
                homepage: true,
                presentation: true,
                interpretation: true,
                generalView: true,
                aptitudeSpheres: true,
                compReport: true,
                index: true,
                jobVsCandidate: undefined,
                interviewQuestions: undefined
            };
            return;
        }
        if (this.pdfStructure.report === 'communication') {
            this.pdfStructure.personality = undefined;
            this.pdfStructure.talents = undefined;
            this.pdfStructure.satellite = undefined;
            this.pdfStructure.iac = undefined;
            this.pdfStructure.communication = {on: true};
            this.pdfStructure.reportType = undefined;
            return;
        }
        if (this.pdfStructure.report === 'satellite') {
            this.pdfStructure.personality = undefined;
            this.pdfStructure.talents = undefined;
            this.pdfStructure.communication = undefined;
            this.pdfStructure.iac = undefined;
            this.pdfStructure.reportType = undefined;
            this.pdfStructure.satellite = {
                on: true,
                graph: true,
                jobDescription: true,
                personalityProfile: true
            };
            return;
        }
    }

    /**
     *
     */
    toggleGraphOnly() {

        this.graphOnly = !this.graphOnly;

        if (this.pdfStructure.talents) {
            this.pdfStructure.talents.on = undefined;
        }
        if (this.pdfStructure.communication) {
            this.pdfStructure.communication.on = undefined;
        }
        if (this.pdfStructure.satellite) {
            this.pdfStructure.satellite.on = undefined;
        }

        if (this.graphOnly) {
            this.pdfStructure.personality = {
                on: true,
                homepage: false,
                warning: false,
                index: false,
                behavior: false,
                portrait: false,
                extraPoints: false,
                prb: false,
                graph: true
            };
        } else {
            this.pdfStructure.personality = {
                on: true,
                homepage: true,
                warning: true,
                index: true,
                behavior: true,
                portrait: true,
                extraPoints: true,
                prb: !!(this.person.evaluations.prb),
                graph: true
            };
        }
    }

    getReportDataCommon() {
        const evaluationData = this.getEvaluationData();
        let obs: any;
        if (evaluationData.type === 'iac') {
            obs = this.apiPeopleService.reportRa(
                [this.person.id],
                [this.translate.currentLang]
            );
        } else if (evaluationData.type === 'satellite') {
            obs = this.apiPeopleService.reportEvaluationSatellite([this.person.id], [
                0,
                0,
                this.translate.currentLang,
                evaluationData.parts
            ]);
        } else {
            let type = evaluationData.type;
            type = (type === 'communication') ? 'dit' : type;
            type = (type === 'personality') ? 'mpo' : type;
            obs = this.apiPeopleService.reportEvaluation([type], [
                this.person.id,
                evaluationData.parts,
                0,
                (this.state.people.prbIndex) ? this.state.people.prbIndex : 0,
                this.translate.currentLang, // Force lang
                this.pdfStructure.reportType ?? 'analyst'
            ]);
        }

        return obs;
    }

    getEvaluationData(): any {
        let evaluationData = {};

        if (this.hasPersonality) {
            evaluationData = {type: 'personality', parts: this.personalityParts};
        }

        if (this.hasSatellite) {
            evaluationData = {type: 'satellite', parts: this.satelliteParts};
        }

        if (this.hasTalents) {
            evaluationData = {type: 'talents', parts: this.talentsParts};
        }

        if (this.hasCommunication) {
            evaluationData = {type: 'communication', parts: this.communicationParts};
        }

        if (this.hasIac) {
            evaluationData = {type: 'iac', parts: this.iacParts};
        }

        return evaluationData;
    }

    get hasTalents(): boolean {
        return !!(this.pdfStructure.talents && this.pdfStructure.talents.on);
    }


    get hasPersonality(): boolean {
        return !!(this.pdfStructure.personality && this.pdfStructure.personality.on);
    }

    get hasSatellite(): boolean {
        return !!(this.pdfStructure.satellite && this.pdfStructure.satellite.on);
    }

    get hasCommunication(): boolean {
        return !!(this.pdfStructure.communication && this.pdfStructure.communication.on);
    }

    get hasIac(): boolean {
        return !!(this.pdfStructure.iac && this.pdfStructure.iac.on);
    }

    get personalityParts(): any {

        let personalityParts = [];

        if (this.hasPersonality) {
            for (let item in this.pdfStructure.personality) {
                if (this.pdfStructure.personality.hasOwnProperty(item)) {
                    if (this.pdfStructure.personality[item]) {
                        let push = (this.personalityMatch[item]) ? this.personalityMatch[item] : item;
                        personalityParts.push(push);
                    }
                }
            }
        }

        return personalityParts;
    }

    get iacParts(): any {

        let iacParts = [];

        if (this.hasIac) {
            for (let item in this.pdfStructure.personality) {
                if (this.pdfStructure.personality.hasOwnProperty(item)) {
                    if (this.pdfStructure.iac[item]) {
                        let push = (this.iacMatch[item]) ? this.iacMatch[item] : item;
                        iacParts.push(push);
                    }
                }
            }
        }

        return iacParts;
    }

    get satelliteParts(): any {
        let satelliteParts = [];

        if (this.hasSatellite) {
            for (let item in this.pdfStructure.satellite) {
                if (this.pdfStructure.satellite.hasOwnProperty(item)) {
                    let push = (this.satelliteMatch[item]) ? this.satelliteMatch[item] : item;
                    satelliteParts.push(push);
                }
            }
        }

        return satelliteParts;
    }

    get talentsParts(): any {
        let talentsParts = [];
        if (this.hasTalents) {
            for (let item in this.pdfStructure.talents) {
                if (this.pdfStructure.talents.hasOwnProperty(item) && this.pdfStructure.talents[item]) {
                    let push = (this.talentsMatch[item]) ? this.talentsMatch[item] : item;
                    talentsParts.push(push);
                }
            }
        }

        return talentsParts;
    }

    get communicationParts(): any {
        let communicationParts = [];

        if (this.hasCommunication) {
            for (let item in this.pdfStructure.communication) {
                if (this.pdfStructure.communication[item]) {
                    let push = (this.communicationMatch[item]) ? this.communicationMatch[item] : item;
                    communicationParts.push(push);
                }
            }
        }

        return communicationParts;
    }
}
