import { Injectable } from '@angular/core';
import { Person } from '../../models/person.model';

@Injectable()
export class PeopleViewService {
    _selectedPerson: Person;
  constructor() { }

    set selectedPerson(person: Person) {
        this._selectedPerson = person;
    }

    get selectedPerson(): Person {
        return this._selectedPerson;
    }
}
