import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ComponentFullView} from '../../../../../shared/classes/components/component_full_view';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {UserService} from '../../../../../core/services/user/user.service';
import {JobSatisfactionPcrReportService} from '../../../services/job-satisfaction-pcr-report.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import * as _ from 'lodash';

@Component({
    selector: 'app-mobile-job-satisfactions-report',
    templateUrl: './mobile-job-satisfactions-report.component.html',
    styleUrls: ['./mobile-job-satisfactions-report.component.scss']
})
export class MobileJobSatisfactionsReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    @ViewChild('top') top: ElementRef;
    jobSatisfactionModalStatus = 'edit';

    isLandscape = false;
    showPortraitButtons = false;

    titleSep: string;
    reportWidth: string;
    sideWidth: string;
    graphWidth: string;
    scoreWidth: string;
    listViewDisplay = true;

    personToSend: any;


    sortResult = 6;

    showJobSatisfactionPopup = false;
    showPrbRequest = false;

    small = true;

    records = [];

    tabName = 'concordanceAnalysis';

    minimizeState = false;
    minimizeAll = false;

    private subscriptions = new Subscription();

    nameListing = {
        'green': [],
        'yellow': [],
        'red': [],
        'grey': [],
    };

    countAndOver: any = {
        'green': '0',
        'yellow': '0',
        'red': '0',
        'grey': '0',
    };

    @HostListener('window:mousemove') refreshUserState() {
        // this.idleTime = 0;
    }

    constructor(
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        private route: ActivatedRoute,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        public stateService: StateService,
        public user: UserService,
        public jobSatisfactionPcrReport: JobSatisfactionPcrReportService,
        public apiJobSatisfactions: ApiJobSatisfactionsService
    ) {
        super(stateService, translate, router, deviceService);
    }


    ngOnInit() {
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;

        let thisObject = this;
        window.addEventListener('orientationchange', function() {
            /**
             * If orientation change is needed
             */
            /**
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            // set the graph width by the previous window height
            thisObject.graphWidth = window.innerHeight;
            thisObject.graphHeight = thisObject.graphWidth / 2.4256;
            thisObject.graphHeight = +thisObject.graphHeight.toFixed(2);
            thisObject.changeDetectorRef.markForCheck();
             */
        }, false);

        this.records = this.jobSatisfaction.records;
        this.jobSatisfaction.setRecordsWatch$.subscribe(
            (res) => {
                if (res !== undefined && res !== null) {
                    // console.log('here');
                    this.records = this.sortRecordsWithPrefs(res);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        // Preset display w.r.t available target.s
        this.small = (window.innerWidth < 1235);
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);

        // reload the Right Match context after coming back from jobs
        this.jobSatisfaction.setItemToDisplayWithId(this.jobSatisfaction.id);
        this.jobSatisfactionState.goTo = null;


        if (this.jobSatisfaction.itemToDisplay && !this.jobSatisfactionState.report.reportType) {
            // Set 'analysis' tab to show by default
            this.switchEvaluationType('analysis');
        }
        // Set width
        this.computeWidths(window);

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );

        let divs = Array.from(document.querySelectorAll('body > div:not(#menu_wrapper)')) as unknown as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style.overflowY = 'hidden';
        }
        if (document.getElementById('scrollBody') !== null) {
            document.getElementById('scrollBody').style.overflowY = 'scroll';
        }

        this.records = this.sortRecordsWithPrefs(this.records);

        this.stateService.jobSatisfaction.stateChanged$.subscribe(
            () => {
                this.records = this.sortRecordsWithPrefs(this.jobSatisfaction.records);
                this.changeDetectorRef.markForCheck();
            }
        );
        this.changeDetectorRef.markForCheck();
    }

    sortParticipantsByColor() {
        let count = {
            'green': 0,
            'yellow': 0,
            'red': 0,
            'grey': 0,
            'total': 0
        };

        let nameListing = {
            'green': [],
            'yellow': [],
            'red': [],
            'grey': [],
        };

        if (this.jobSatisfaction.records) {
            this.jobSatisfaction.records.forEach(function (record, index) {
                if (record.jobSatisfactionScore === 2) {
                    nameListing.green.push(record.firstName + ' ' + record.lastName);
                    count['green']++;
                    count['total']++;
                } else if (record.jobSatisfactionScore === 1) {
                    nameListing.yellow.push(record.firstName + ' ' + record.lastName);
                    count['yellow']++;
                    count['total']++;
                } else if (record.jobSatisfactionScore === 0) {
                    nameListing.red.push(record.firstName + ' ' + record.lastName);
                    count['red']++;
                    count['total']++;
                } else {
                    nameListing.grey.push(record.firstName + ' ' + record.lastName);
                    count['grey']++;
                    count['total']++;
                }
            });
        }

        this.nameListing = nameListing;
        this.countAndOver = {
            'green': (count['green'] !== 0) ? count['green'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'yellow': (count['yellow'] !== 0) ? count['yellow'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'red': (count['red'] !== 0) ? count['red'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'grey': (count['grey'] !== 0) ? count['grey'] + '/' + count['total'] : '0'  + '/' + count['total']
        };
        this.changeDetectorRef.markForCheck();
    }

    removePerson(personId) {
        let newRecords = [];
        let newRecordsId = [];
        for (let person of this.records) {
            if (person.id !== personId) {
                newRecords.push(person);
                newRecordsId.push(person.id);
            }
        }
        this.records = newRecords;
        this.apiJobSatisfactions.putJobSatisfaction(
            [this.jobSatisfaction.id],
            {
                records: newRecordsId
            }
        ).subscribe(() => {
            this.jobSatisfaction.setItemToDisplayWithId(this.jobSatisfaction.id);
            this.stateService.jobSatisfaction.stateChanged.next(true);
            this.changeDetectorRef.markForCheck();
        });
    }

    toggleJobSatisfactionPopup(event) {
        this.showJobSatisfactionPopup = !this.showJobSatisfactionPopup;
    }

    togglePrbRequest(event) {
        this.personToSend = event.person;
        this.showPrbRequest = !this.showPrbRequest;
    }

    sortRecordsWithPrefs(records) {
        if (records === null || records === undefined || records.length === 0) {
            return;
        }

        // last n characters of this.filter.sortBy
        let sortBy = this.stateService.jobSatisfaction.displayPreferences.sortBy;
        let order = 'desc';
        if (sortBy.slice(-4) === 'Desc') {
            sortBy = sortBy.substring(0, sortBy.length - 4);
        } else if (sortBy.slice(-3) === 'Asc') {
            sortBy = sortBy.substring(0, sortBy.length - 3);
            order = 'asc';
        }

        let removed = [];

        if (sortBy === 'name') {
            // sort records by lastName
            records = _.sortBy(records, ['lastName']);
        } else if (
            sortBy === 'jobSatisfactionScore' ||
            sortBy === 'lastPcr'
        ) {
            let recordsTmp = [];
            for (let i in records) {
                if (this.jobSatisfaction.records[i]) {
                    if (this.checkIfValid(this.jobSatisfaction.records[i].jobSatisfactionScore) ||
                        this.jobSatisfaction.records[i].jobSatisfactionScore === -1
                    ) {
                        removed.push(records[i]);
                    } else {
                        recordsTmp.push(records[i]);
                    }
                }
            }
            // sort records by lastName
            if (sortBy === 'lastPcr') {
                records = _.sortBy(recordsTmp, ['prb[0].date'], order);
            } else {
                records = _.sortBy(recordsTmp, ['jobSatisfactionScore']);
            }
        }
        if (order === 'desc') {
            records = records.reverse();
        }

        this.sortParticipantsByColor();

        records = records.concat(removed);
        return records;
    }

    checkIfValid(number) {
        return (
            number === null ||
            number === undefined ||
            number === false
        );
    }

    onResize(event) {
        // re-Set list width
        this.computeWidths(event.target);
        this.small = (window.innerWidth < 1400);
    }

    toggleListView() {
        this.listViewDisplay = !this.listViewDisplay;
        this.changeDetectorRef.markForCheck();
    }

    get listView() {
        /**
         let countActiveReport = 0;
         if (this.displayPreferences.cai === true) {
            countActiveReport++;
        }
         if (this.displayPreferences.personality === true) {
            countActiveReport++;
        }
         if (this.displayPreferences.talents === true) {
            countActiveReport++;
        }
         let moreThanOneReport = (countActiveReport > 1);
         if (moreThanOneReport) {
            return this.listViewDisplay;
        } else if (countActiveReport === 1) {
         */
        return this.listViewDisplay;
        /**
         }
         */
    }

    computeWidths(startWidth) {
        let adjustment = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.graphWidth = ((innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right) - 75 + 'px';
        this.scoreWidth = ((innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right) / 4 + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
        // @ilya 2020-10-16 - fix the side width to 400px
        this.sideWidth = '400px';
        this.reportWidth = (innerWidth - 505) + 'px';
        return;
    }

    switchEvaluationType(newEvaluation: string) {
        this.jobSatisfactionState.report.reportType = newEvaluation;
    }

    sortResults(event) {
        this.sortResult = event;
        this.switchEvaluationType('analysis');
        this.changeDetectorRef.markForCheck();

    }

    togglePersonsCards() {
        this.minimizeState = !this.minimizeAll;
        this.minimizeAll = !this.minimizeAll;
    }

    goToTop() {
        if (this.top) {
            this.top.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    get targetToDisplay() {
        let display = 0;

        if (this.stateService.jobSatisfaction.displayPreferences.display.personality === true) {
            display++;
        }
        if (this.stateService.jobSatisfaction.displayPreferences.display.talents === true) {
            display++;
        }
        if (this.stateService.jobSatisfaction.displayPreferences.display.cai === true) {
            display++;
        }

        return display;
    }

    get numberOfLines() {
        let nol = 0;
        if (this.jobSatisfaction &&
            this.jobSatisfaction.records &&
            this.jobSatisfaction.records.length
        ) {
            nol = Math.ceil(this.jobSatisfaction.records.length / 3);
        }
        return nol;
    }

    get jobSatisfactionState(): any {
        return this.stateService.jobSatisfaction;
    }

    // From rm-report //////////////////////////////////////////////////////////////////////////////////////////////////

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //// TO REVIEW /////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * NO MODAL IN MOBILE VERSION
     */
        // openJobSatisfactionReportsFiltersModal() {
    //    this.jobSatisfactionModalHandlingService.openJobSatisfactionReportsFiltersModal().subscribe(
    //        res => {
    //            if (res.closed) {
    //                this.changeDetectorRef.markForCheck();
    //            }
    //        }
    //    );
    // }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //// MECHANICS --- KEEP FOR NOW ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Variables
    addingPanelOpenState;
    infoPanelOpenState;
    editRequest;
    pdfRequest;

    // Methods
    openPanel() {
        this.addingPanelOpenState = true;
    }

    openInfoPanel() {
        this.infoPanelOpenState = true;
    }

    closePanel() {
        this.addingPanelOpenState = false;
    }

    closeInfoPanel() {
        this.infoPanelOpenState = false;
    }

    itemEdit() {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.editRequest = false;
    }

    closeAllRequestsPanels(action = null) {
        this.pdfRequest = false;
        this.editRequest = false;
        this.showPrbRequest = false;
        this.showJobSatisfactionPopup = false;
        if (action === 'refresh') {
            this.jobSatisfaction.setRecordsWatch.next(true);
            // this.records = this.sortRecordsWithPrefs(this.jobSatisfaction.records);
            // this.changeDetectorRef.markForCheck();
        }
    }

}
