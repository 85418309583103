import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    OnDestroy
} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonService} from 'src/app/people/services/person.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {ComponentFullView} from 'src/app/shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-mobile-graphboards-report',
    templateUrl: './mobile-graphboards-report.component.html',
    styleUrls: ['./mobile-graphboards-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileGraphboardsReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    graphBoardSize: number;
    graphboardTab = 'individus';
    selectedTab = 0;
    pdfRequest = false;
    editRequest = false;
    itemList: any = [];
    evaluationTypes = ['individus', 'profiler'];
    actualPage: any = {
        individus: 1,
        profiler: 1
    };
    maxPerPage = 5;
    maxPage: any = {
        individus: null,
        profiler: null
    };
    addingPanelOpenState = false;
    graphWidth: number;
    graphHeight: number;

    newBoard: any;
    currentLang: string;
    titleSep: string;
    isLandscape = false;
    showPortraitButtons = false;
    idleTime = 0;
    // idleInterval; // to use when we want to automatically hide the portrait buttons after few seconds : see togglePortraitButtons() function

    private subscriptions = new Subscription();

    constructor(
        protected deviceService: DeviceDetectorService,
        public graphBoard: GraphBoardsAnalysisService,
        public person: PersonService,
        private route: ActivatedRoute,
        protected router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private apiGraphboardsService: ApiGraphboardsService,
        protected stateService: StateService,
        public dragulaService: DragulaService,
        protected translate: TranslateService
    ) {
            super(stateService, translate, router, deviceService);
            const bag: any = this.dragulaService.find('first-bag');
            if (bag !== undefined) {
                this.dragulaService.destroy('first-bag');
            }
            this.dragulaService.createGroup('first-bag', {revertOnSpill: true});

            this.newBoard = [[], [], []];
    }

    @HostListener('window:mousemove') refreshUserState() {
        this.idleTime = 0;
    }

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
        // if (this.showPortraitButtons) {
        //     this.idleInterval = setInterval(() => {
        //         this.idleTime++;
        //         if (this.idleTime > 3) { // 3 sec
        //             this.showPortraitButtons = false;
        //             clearInterval(this.idleInterval);
        //             this.changeDetectorRef.markForCheck();
        //         }
        //     }, 1000);
        // }
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    ngOnInit() {
        this.graphWidth = window.innerWidth;
        this.graphHeight = this.graphWidth / 2.4256;
        this.graphHeight = +this.graphHeight.toFixed(2);
        this.currentLang = this.translate.currentLang;
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
        let thisObject = this;
        window.addEventListener('orientationchange', function() {
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            // set the graph width by the previous window height
            thisObject.graphWidth = window.innerHeight;
            thisObject.graphHeight = thisObject.graphWidth / 2.4256;
            thisObject.graphHeight = +thisObject.graphHeight.toFixed(2);
            thisObject.changeDetectorRef.markForCheck();
        }, false);

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.itemList = [];
        this.subscriptions.add(this.dragulaService.drop('first-bag')
            .subscribe(() => {
                this.act();
            })
        );

        if (
            this.dragulaService &&
            this.dragulaService.find('first-bag') &&
            this.dragulaService.find('first-bag').drake
        ) {
            this.dragulaService.find('first-bag').drake.containers = [];
        }

        if (this.graphBoard.alignment && this.graphBoard.alignment.length) {
            this.graphBoardSize = this.graphBoard.alignment.length;
        } else {
            this.graphBoardSize = 3;
        }
        this.setItemToDisplay();

        this.graphBoardState.stateChanged$.subscribe(
            (res) => {
                if (res && res.action === 'itemSelected') {
                    this.setItemToDisplay();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    setItemToDisplay() {
        if (this.graphBoard.id) {
            let itemList = {};
            itemList['individus'] = [];
            itemList['profiler'] = [];
            if (this.graphBoard.alignment) {
                for (let line of this.graphBoard.alignment) {
                    for (let item of line) {
                        let pushedItem = JSON.parse(JSON.stringify(item));
                        let analysisType = 'profiler';
                        if (item.analysisType === 'trait' || item.analysisType === 'prb') {
                            analysisType = 'individus';
                            if (item.analysisType === 'trait') {
                                pushedItem['hasTrait'] = true;
                            }
                            if (item.analysisType === 'prb') {
                                pushedItem['hasPrb'] = true;
                            }
                            for (let index in itemList['individus']) {
                                if (itemList['individus'].hasOwnProperty(index)) {
                                    if (itemList['individus'][index].id === item.id) {
                                        pushedItem['hasTrait'] = true;
                                        pushedItem['hasPrb'] = true;
                                        itemList['individus'].splice(Number(index), 1);
                                    }
                                }
                            }
                            pushedItem.analysisType = 'individus';
                        }
                        if (item.analysisType === 'individus') {
                            analysisType = 'individus';
                        }
                        itemList[analysisType].push(pushedItem);
                    }
                }
                itemList['cIndividus'] = (itemList['individus']) ? itemList['individus'].length : 0;
                itemList['cProfiler'] = (itemList['profiler']) ? itemList['profiler'].length : 0;

                this.maxPage['individus'] = Math.ceil(itemList['cIndividus'] / this.maxPerPage);
                this.maxPage['profiler'] = Math.ceil(itemList['cProfiler'] / this.maxPerPage);
                itemList['individus'].sort(
                    function(a, b) {
                        let textA = a.firstName.toUpperCase();
                        let textB = b.firstName.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }
                );
                itemList['profiler'].sort(
                    function(a, b) {
                        let textA = a.jobTitle.toUpperCase();
                        let textB = b.jobTitle.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }
                );

                this.itemList = itemList;
            } else {
                this.itemList['cIndividus'] = 0;
                this.itemList['cProfiler'] = 0;
                this.maxPage['individus'] = 0;
                this.maxPage['profiler'] = 0;
                this.itemList['individus'] = [];
                this.itemList['profiler'] = [];
            }
        }
    }

    idBuilding(item, root = false) {
        // console.log(item);
        let analysisType = 'profiler';

        if (item.analysisType === 'trait') {
            analysisType = 'trait';
        }
        if (item.analysisType === 'prb') {
            analysisType = 'prb';
        }

        if (root) {
            return analysisType;
        } else {
            return analysisType + '_' + item.id;
        }

    }

    act() {
        let newAlignment: Array<Array<any>> = [];
        for (let i = 0; i < 3; i++) {
            newAlignment[i] = [];
            let j = 0;
            let type = '';
            for (let item of this.graphBoard.alignment[i]) {

                if (item.evaluationType && item.evaluationType === 'mpo') {
                    type = item.analysisType;
                } else {
                    type = 'profiler';
                }

                let payload = {
                    type: type,
                    record: item.id
                };
                if (type === 'prb') {
                    payload['dash'] = 0;
                }

                newAlignment[i][j] = payload;
                j++;
            }
        }

        let data = {
            'alignment': newAlignment
        };
        this.subscriptions.add(this.apiGraphboardsService.putGraphboard([this.graphBoard.id], data).subscribe(
            (res) => {
                this.graphBoard.setItemToDisplayWithId(res.id);
            }
        ));
    }

    setSubAccountName(subAccountNumber) {
        let name = null;
        if (subAccountNumber >= 0) {
            for (let subAccData of this.stateService.session.sessionData.structure.subAccounts) {
                if (subAccData.id === subAccountNumber) {
                    name = subAccData.name;
                    return name;
                }
            }
        }
        return name;
    }

    switchEvaluationType(index: number) {
        this.graphboardTab = this.evaluationTypes.find((e, i) => {
            if (i === index) {
                this.selectedTab = index;
                return e;
            } else {
                return;
            }
        });
    }

    openPanel() {
        this.addingPanelOpenState = true;
    }

    closePanel() {
        this.addingPanelOpenState = false;
    }

    showProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    goToPage(factor, type) {
        this.actualPage[type] += factor;
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'mobileView');
    }

    itemEdit() {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.editRequest = false;
    }

    closeAllRequestsPanels() {
        this.pdfRequest = false;
        this.editRequest = false;
    }

    analysisTypeForId(item) {
        if (item.analysisType === 'profiler') {
            return 'profiler';
        } else {

            if (item.analysisType === 'prb') {
                return 'individus_prb';
            } else {
                return 'individus_trait';
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get graphBoardState(): any {
        return this.stateService[this.specificState()];
    }

}
