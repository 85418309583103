import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from "lodash";
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'listFilter'})
export class ListFilterPipe implements PipeTransform {
    dateFields: any = ['creationDate', 'date'];

    constructor(public translate: TranslateService) {

    }

    transform(value: any, field?: string): any {
        if (_.includes(this.dateFields, field)) {
            let datePipe = new DatePipe('en');
            if (value && value[0] !== '-') {
                let format = 'dd/MM/y';
                if(this.translate.currentLang == 'en'){
                    format = 'MM/dd/y';
                }
                return datePipe.transform(value, format);
            }
        }
        return value;
    }
}
