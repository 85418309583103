export class EnvService {

    // The values that are defined here are the default values that can
    // be overridden by env.js

    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    public production = false;
    public versionJson = false;
    public loc = true;
    public docker = true;

    // API url
    profilerUrl = '';
    apiBaseUrl = '';
    evaluationFormUrl = '';
    consoleBaseUrl = '';
    pdfReportUrl = '';
    satellite = '';

    constructor() {
    }

}
