// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.mat-input-element {
  color: #ffffff !important;
}

div.formClear {
  clear: both;
}

.padding10 {
  padding: 10px 0;
}

.submitIcon {
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 3px;
}

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field .mat-mdc-form-field-infix {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/forms/components/form-table/mobile/form-table-mobile.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AAAI;EACI,wCAAA;AAER","sourcesContent":["input.mat-input-element {\n    color: #ffffff !important;\n}\n\ndiv.formClear {\n    clear: both;\n}\n\n.padding10 {\n    padding: 10px 0;\n}\n\n.submitIcon {\n    display: inline-block;\n    margin-bottom: 3px;\n    margin-right: 3px;\n}\n\n.mat-mdc-form-field {\n    width: 100%;\n    .mat-mdc-form-field-infix {\n        background-color: transparent!important;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
