import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';

import {StateService} from '../../../core/services/state/state.service';
import {UserService} from '../../services/user.service';
import {AdminSidebar} from '../classes/admin_sidebar';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-administration-user-detail-left-sidebar',
    templateUrl: './administration-user-left-sidebar.component.html',
    styleUrls: ['./administration-user-left-sidebar.component.scss']
})
export class AdministrationUserLeftSidebarComponent extends AdminSidebar implements OnInit, OnDestroy {
    @Input() accountData: any;
    @Input() width: string;
    @Input() userMod: boolean;
    @Input() small = false;

    constructor(
        public clientUser: UserService,
        public translateService: TranslateService,
        protected deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        protected stateService: StateService,
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnInit() {
        this.clientUser.checkFiles();
        this.stateService.admin.stateChanged$.subscribe(
            (res) => {
                if (res) {
                    this.clientUser.checkFiles();
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    onResize(event) {
        return event.target;
    }

    goToAccount() {
        this.router.navigate(['admin/account/' + this.accountData.id]).then(() => {});
    }

    ngOnDestroy() {}
}
