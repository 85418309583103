import {Injectable} from '@angular/core';
import {GlobalHelpers} from '../../../shared/helpers/globalHelpers';
import * as _ from 'lodash';
import {Content} from '../../../shared/classes/services/content';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../state/state.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Handles users' preferences and sessionData
 */

export class UserService extends Content {
    /**
     * Holds the userData part of the actual sessionData.userData
     * @private
     */
    public user;
    /**
     * Holds the permissions part of the actual sessionData.permissions
     * @private
     */
    private globalPermissions;

    readonly persistentVariables = [
        'user', 'globalPermissions'
    ];

    constructor(
        router: Router,
        deviceService: DeviceDetectorService,
        stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('userService', this.persistentVariables, this);
    }

    // Generic mutators

    set loggedUser(sessionData) {
        this.user = sessionData.userData;
        this.globalPermissions = _.get(sessionData, 'permissions');
        this.user = _.get(sessionData, 'userData');
        this.storeServiceData('userService', this.persistentVariables, this);
    }

    get loggedUser(): any {
        return this.user;
    }

    get loggedUserGlobalPermissions(): any {
        return this.globalPermissions;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Global permissions //////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // global permissions mutators

    get accessClients(): boolean {
        return this.globalPermissions.accessClients;
    }

    get accountType(): number {
        return this.globalPermissions.accountType;
    }

    get isAccountActive(): boolean {
        return this.globalPermissions.isAccountActive;
    }

    get isNgenio(): boolean {
        return this.globalPermissions.isNgenio;
    }

    get mainRole(): string {
        return (this.globalPermissions) ? this.globalPermissions.mainRole : null;
    }

    get roles(): [string] {
        return (this.globalPermissions) ? this.globalPermissions.roles : null;
    }

    get userType(): string {
        return (this.globalPermissions) ? this.globalPermissions.userType : null;
    }

    get isSender() {
        return (this.userType === 'snd' || this.mainRole === 'ROLE_USER_SENDER');
    }

    get isSuperAdmin() {
        return !!(this.globalPermissions.roles.includes('ROLE_SUPER_ADMIN'));
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // User ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    get usersDataId(): string {
        return _.get(this.user, 'id');
    }

    get userName(): string {
        return _.get(this.user, 'username');
    }

    get address(): any {
        let address = _.get(this.user, 'address');
        return (address.length !== 0 && address !== null) ? address : null;
    }

    get email(): string {
        return _.get(this.user, 'email');
    }

    get firstName(): string {
        return _.get(this.user, 'firstName');
    }

    get lastName(): string {
        return _.get(this.user, 'lastName');
    }

    get fullName(): string {
        return _.get(this.user, 'fullName');
    }

    get reportsPermissions(): any {
        return _.get(this.user, 'permissions');
    }

    get hasPersonalityAccess(): boolean {
        let personalityAccess = _.get(this.reportsPermissions, 'mpo');
        return (personalityAccess !== null) ? personalityAccess : false;
    }

    get hasCommunicationAccess(): boolean {
        let communicationAccess = _.get(this.reportsPermissions, 'dit');
        return (communicationAccess !== null) ? communicationAccess : false;
    }

    get hasTalentsAccess(): boolean {
        let talentsAccess = _.get(this.reportsPermissions, 'talents');
        return (talentsAccess !== null) ? talentsAccess : false;
    }

    get hasSatelliteAccess(): boolean {
        let satelliteAccess = _.get(this.reportsPermissions, 'satellite');
        return (satelliteAccess !== null) ? satelliteAccess : false;
    }

    get hasJilAccess(): boolean {
        let jilAccess = _.get(this.reportsPermissions, 'jil');
        return (jilAccess !== null) ? jilAccess : false;
    }

    get hasJobAccess() {
        return (
            !!this.hasPersonalityAccess ||
            !!this.hasTalentsAccess ||
            !!this.hasIacAccess
        );
    }

    get hasIacAccess(): boolean {
        let iacAccess = _.get(this.reportsPermissions, 'ra');
        return (iacAccess !== null) ? iacAccess : false;
    }

    get hasReportAccess(): boolean {
        return (
            !!this.hasPersonalityAccess ||
            !!this.hasCommunicationAccess ||
            !!this.hasSatelliteAccess ||
            !!this.hasTalentsAccess ||
            !!this.hasIacAccess
        );
    }

    get preferences(): any {
        return _.get(this.user, 'preferences');
    }

    get principalSubAccount(): number {
        return _.get(this.user, 'principalSubAccount');
    }

    get subAccountAccess(): [number] {
        return _.get(this.user, 'principalSubAccount');
    }

    get canDelete(): boolean {
        return ['s_admin', 'm_admin', 'admin'].includes(this.userType);
    }
}
