import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-talent-graph',
    templateUrl: './talent-graph.component.html',
    styleUrls: ['./talent-graph.component.scss']
})
export class TalentGraphComponent implements OnChanges {

    @ViewChild('TalentGraph', {static: true}) private chartContainer: ElementRef;
    // @Input() private ratio: number;
    @Input() marginBottom = true;
    @Input() id: string;
    @Input() score: number;
    @Input() target: string; // med, high or low for selector
    @Input() size: string;
    @Input() type = 'score'; // can be selector
    @Input() selected: string;
    @Input() activeTalent: boolean;
    @Input() level: any;
    @Input() mobile = false;

    @Output() activateTalent = new EventEmitter();

    talentNumber: number = null;

    constructor() {
        if (this.type === 'selector' || this.type === 'empty') {
            this.score = null;
            this.target = null;
        }
    }

    ngOnChanges() {
        if (this.level) {
            this.level = +this.level;
        }
        this.createTalentGraph();
        if (this.type === 'selector' || this.type === 'empty') {
            this.talentNumber = parseInt(this.id.split('_')[this.id.split('_').length - 1], 10);
            if (!this.activeTalent) {
                this.clickedScore(null);
            }
        }

    }

    createTalentGraph() {

        let score = this.score - 1;
        let id = 'talents_' + this.id;

        let paperHeight = 105;
        if (!this.marginBottom) {
            paperHeight = 80;
        }

        let horz = [65, 130, 195, 260, 325, 390, 455, 520, 585];
        let ratio = .2;
        let bkLineLenght = 650;
        let circleRadius = 32.5;
        let corr = -4;
        if (this.id.includes('talent_selector')) {
            horz = [93, 186, 279, 372, 465, 558, 651, 744, 837];
            ratio = .25;
            bkLineLenght = 930;
            corr = 4;
        }

        let textY = 61;
        let widthAdjustment = 50;

        if (this.mobile) {
            bkLineLenght = 450;
            horz = [45, 90, 135, 180, 225, 270, 315, 360, 405];
            circleRadius = 27;
            textY = 59;
            if (id.includes('talents_desc') || id.includes('talents_jobVsInd')) {
                widthAdjustment = 40;
            }
        } else if ((this.size === 'large' || this.size === 'large_masked') && !this.target && this.type === 'score') {
            ratio = .25;
            horz = [75, 150, 225, 300, 375, 450, 525, 600, 675];
            bkLineLenght = 750;
        } else if (this.target || this.type === 'selector' || this.type === 'empty') {
            ratio = .25;
            horz = [95, 190, 285, 380, 475, 570, 665, 760, 855];
            bkLineLenght = 930;
        }


        // console.log(bkLineLenght);

        d3.select('#' + id).remove();

        // Define "element" to work on
        let element = this.chartContainer.nativeElement;

        let svg = d3.select(element).append('svg')
            .attr('id', id);

        if (this.size !== 'rm') {
            svg = svg
                .attr('width', bkLineLenght * ratio + widthAdjustment)
                .attr('preserveAspectRatio', 'xMidYMid meet')
                .attr('height', paperHeight * ratio + 10)
                .attr('viewBox', '0 0 ' + bkLineLenght + ' ' + paperHeight);
        } else {
            svg = svg
                .attr('width', '110px')
                .attr('height', '20px')
                .attr('viewBox', '0 0 930 65');
        }


        // First Background Line
        if (this.size !== 'rm') {
            svg.append('rect')
                .attr('x', 0)
                .attr('y', 40)
                .attr('width', bkLineLenght)
                .attr('height', 20)
                .attr('stroke', 'none')
                .style('fill', 'rgb(85.0%,85.0%,85.0%)');
        } else {
            svg.append('rect')
                .attr('x', 0)
                .attr('y', 35)
                .attr('width', bkLineLenght)
                .attr('height', 25)
                .attr('stroke', 'none')
                .style('fill', 'rgb(85.0%,85.0%,85.0%)');
        }

        let circleColor = 'rgb(85.0%,85.0%,85.0%)';

        if (this.score || (this.score === null && this.size === 'rm')) {
            if (!this.target) {
                circleColor = '#779cc5';
                if (this.score > 3 && this.score <= 6) {
                    circleColor = '#efce7c'; // '#e59950';//
                } else if (this.score > 6 && this.score <= 9) {
                    circleColor = '#78c7af'; // '#ea7300'; //rgb(227, 94, 36)//
                }
            }

            let startPoint = horz[3];
            let rectWidth = (this.mobile) ? 172 : 285;
            let rectHeight = (this.mobile) ? 80 : 90;


            let x = 0;
            let y = (this.mobile) ? 5 : 0;
            let rx = 50;
            let ry = 50;

            let corrective = (this.mobile) ? 46 : 52;
            // TODO: correct this.mobile

            if (this.target) { // bubble mod this
                if ((this.target !== 'med' && this.target !== 'high') && (this.type !== 'selector' && this.type !== 'empty')) {
                    throw 'Illegal target value declared in talent-graph.component.ts';
                }
                if (this.target === 'high') {
                    startPoint = horz[6];
                }

                x = startPoint - corrective;

                let bubble;
                if (this.size !== 'rm') {
                    bubble = svg.append('rect')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('rx', rx)
                        .attr('ry', ry)
                        .attr('width', rectWidth)
                        .attr('height', rectHeight)
                        .attr('fill', 'none')
                        .attr('stroke-width', 6);
                } else {
                    bubble = svg.append('rect')
                        .attr('x', x)
                        .attr('y', y - 5)
                        .attr('rx', rx + 10)
                        .attr('ry', ry + 10)
                        .attr('width', rectWidth)
                        .attr('height', rectHeight + 10)
                        .attr('fill', 'none')
                        .attr('stroke-width', 13);

                }
                if (this.score !== null) {
                    if (this.target === 'med') {
                        if (this.score <= 3) {
                            bubble.attr('stroke', '#f17c7f');
                        } else if (this.score <= 6) {
                            bubble.attr('stroke', '#78c7af');
                        } else {
                            bubble.attr('stroke', '#efce7c');
                        }
                    } else if (this.target === 'high') {
                        if (this.score <= 6) {
                            bubble.attr('stroke', '#f17c7f');
                        } else {
                            bubble.attr('stroke', '#78c7af');
                        }
                    }
                } else {
                    bubble.attr('stroke', '#ccc');
                }
            }

            let cxPos = horz[score];
            if (this.mobile) {
                if (this.target === 'high' && this.score === 6) {
                    cxPos = horz[score - 1];
                } else if (this.target === 'med') {
                    if (this.score === 7) {
                        cxPos = horz[score + 1];
                    } else if (this.score === 3) {
                        cxPos = horz[score - 1];
                    }
                }
            }
            if (this.score !== null) {
                if (this.size !== 'rm') {
                    svg.append('circle')
                        .attr('cx', cxPos + corr)
                        .attr('cy', 45)
                        .attr('r', circleRadius).attr('stroke', circleColor)
                        .attr('stroke-width', 9)
                        .style('fill', 'white');

                    svg.append('text')
                        .attr('x', cxPos + corr + 1)
                        .attr('y', textY)
                        .attr('text-anchor', 'middle')
                        .text(this.score)
                        .attr('font-size', 46);
                } else {
                    svg.append('circle')
                        .attr('cx', cxPos + corr)
                        .attr('cy', 45)
                        .attr('r', 35)
                        .attr('stroke', circleColor)
                        .attr('stroke-width', 9)
                        .style('fill', circleColor);
                }
            }
        } else if (this.type === 'selector' || this.type === 'empty') {
            if (this.type === 'selector') {
                if (this.selected === 'mid' || this.level === 1) {
                    let x = 336;
                    let y = 0;
                    let rx = 50;
                    let ry = 50;
                    let rectWidth = 285;
                    let rectHeight = 90;
                    if (this.mobile) {
                        x = 188;
                        y = 5;
                        rx = 35;
                        ry = 35;
                        rectWidth = 215;
                        rectHeight = 75;
                    }
                    svg.append('rect')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('rx', rx)
                        .attr('ry', ry)
                        .attr('width', rectWidth)
                        .attr('height', rectHeight)
                        .attr('fill', 'none')
                        .attr('stroke-width', 6)
                        .attr('stroke', '#efce7c');
                } else if (this.selected === 'high' || this.level === 2) {
                    let x = 622;
                    let y = 0;
                    let rectWidth = 285;
                    let rectHeight = 90;
                    if (this.mobile) {
                        x = 370;
                        y = 5;
                        rectWidth = 215;
                        rectHeight = 83;
                    }
                    svg.append('rect')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('rx', 50)
                        .attr('ry', 50)
                        .attr('width', rectWidth)
                        .attr('height', rectHeight)
                        .attr('fill', 'none')
                        .attr('stroke-width', 6)
                        .attr('stroke', '#78c7af');
                }
            }

            let start = 0;
            let stop = 9;
            if (this.size === 'large_masked' && this.type === 'selector') {
                if (this.level === 1) {
                    start = 3;
                    stop = 6;
                }
                if (this.level === 2) {
                    start = 6;
                    stop = 9;
                }
            }
            for (let it = start; it < stop; it++) {
                let circle = svg.append('circle')
                    .attr('cx', horz[it] + corr)
                    .attr('cy', 45)
                    .attr('r', 32.5)
                    .attr('stroke', circleColor)
                    .attr('fill', 'white')
                    .attr('stroke-width', 9);

                let number = svg.append('text')
                    .attr('class', 'text')
                    .attr('x', horz[it] + corr + 1)
                    .attr('y', textY)
                    .attr('text-anchor', 'middle')
                    .text('' + (it + 1))
                    .attr('font-size', 46);

                if (this.type === 'empty') {
                    number.attr('fill', '#777');
                }

                if (this.size === 'large') {
                    circle.on('click', () => {
                        this.clickedScore(it);
                    })
                        .attr('style', 'cursor: pointer');

                    number.on('click', () => {
                        this.clickedScore(it);
                    })
                        .attr('style', 'cursor: pointer');
                }
            }
        }
    }

    clickedScore(score) {
        if (this.activeTalent && score) {
            score = score + 1;
            if (score <= 3) {
                return;
            }
            if (score <= 6) {
                this.selected = 'mid';
                this.activateTalent.emit({
                    talentNumber: this.talentNumber,
                    level: 'mid'
                });
                this.createTalentGraph();
                return;
            }
            if (score <= 9) {
                this.selected = 'high';
                this.activateTalent.emit({
                    talentNumber: this.talentNumber,
                    level: 'high'
                });
                this.createTalentGraph();
                return;
            }
        }
        this.selected = null;
        this.activateTalent.emit({
            talentNumber: this.talentNumber,
            level: null
        });
        this.createTalentGraph();
        return;
    }
}
