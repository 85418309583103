import {ApplicationRef, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ContentList} from 'src/app/shared/classes/services/content_list';
import {UserService} from 'src/app/core/services/user/user.service';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {JobStateColumns} from '../../core/interfaces/state';
import {ApiJobsService} from './api-jobs.service';
import {JobService} from './job.service';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalizeHelper} from 'src/app/shared/helpers/canonicalize.helper';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class JobsListService extends ContentList {
    public _opened;
    public switchOff: boolean;
    public clickMode = false;

    public loading = false;
    public url = 'jobs/list';
    public toList: any = null;
    public totalItems = 0;
    public recordsLoaded = 0;
    public multiAction: any;
    public amount: Array<number> = [];

    public listColumns;
    public listColumnsInitialConfig: Array<JobStateColumns> = [
        {key: 'jobTitle', display: true},
        {key: 'location', display: true},
        {key: 'date', display: true, order: 'desc'}

    ];
    readonly columns = [
        'jobTitle',
        'location',
        'date',
        'subAccountName',
        'requiredBy',
        'departmentName'
    ];
    readonly columnsName = {
        fr: {
            jobTitle: 'Titre',
            location: 'Lieu',
            date: 'Date',
            subAccountName: 'Sous-compte',
            requiredBy: 'Demandeur',
            departmentName: 'Département',
        },
        en: {
            jobTitle: 'Title',
            location: 'Location',
            date: 'Date',
            subAccountName: 'Sub-account',
            requiredBy: 'Requester',
            departmentName: 'Département',
        }
    };

    constructor(
        public user: UserService,
        private userPreferencesService: UserPreferencesService,
        public stateService: StateService,
        private alertService: AlertService,
        private apiJobs: ApiJobsService,
        public jobService: JobService,
        protected applicationRef: ApplicationRef,
        protected router: Router,
        protected translate: TranslateService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, router, applicationRef, deviceService);
    }

    resetList() {
        this.toList = null;
    }

    checkModuleUrl() {
        let prefix = (this.isMobile && !this.url.includes('m/')) ? 'm/' : '';
        this.url = prefix + this.url;
        if (this.archive) {
            this.url = prefix + 'archives/list/jobs';
        } else {
            this.url = prefix + 'jobs/list';
        }
    }

    setInitialColumnsList() {
        this.listColumnsInitialConfig = [
            {key: 'jobTitle', display: true},
            {key: 'location', display: true},
            {key: 'date', display: true, order: 'desc'}
        ];
        if (this.sessionData.structure.testAccessPermissions.ra) {
            this.listColumnsInitialConfig.push({key: 'targetIac', display: true});
        }
        if (this.sessionData.structure.testAccessPermissions.talents) {
            this.listColumnsInitialConfig.push({key: 'targetTalents', display: true});
        }
        if (this.sessionData.structure.testAccessPermissions.mpo) {
            this.listColumnsInitialConfig.push({key: 'targetPersonality', display: true});
        }
    }

    getList(params, recordsLoaded, total, append = false, archive = false) {
        let step = 100;

        let amountLoaded = (recordsLoaded + step > total && append) ? total - recordsLoaded : step;
        recordsLoaded = append ? recordsLoaded : 0;

        let request: any = {
            limit: amountLoaded,
            skip: recordsLoaded
        };
        let parameters =    [
            'search',
            'jobStartDate',
            'jobEndDate',
            'subAccount',
            'department'
        ];

        let arrayParameters = ['subAccount'];

        for (let parameter of parameters) {
            if (params.listSearchFilters[parameter]) {
                request[parameter] = params.listSearchFilters[parameter];
            }
        }

        for (let parameter of arrayParameters) { // subAccount handling
            if (request[parameter] && request[parameter].length > 0) {
                request[parameter] = request[parameter].toString();
            } else {
                delete request[parameter];
            }
        }

        if (request.subAccount) {
            request.subAccount = request.subAccount.split(',');
        }

        if (archive) {
            request['isArchived'] = 1;
        } else {
            request['isArchived'] = 0;
        }

        if (this.stateService.session.hasSatelliteRole) {
            request['isSatellite'] = 1;
        }


        // Build order parameter
        for (let index in params.listColumns) {
            if (params.listColumns.hasOwnProperty(index)) {
                if (params.listColumns[index] && params.listColumns[index]['order']) {
                    let orderObject = {};
                    let colKey: string;
                    switch (params.listColumns[index]['key']) {
                            case 'subAccountName':
                            colKey = 'subAccount';
                            break;
                        case 'departmentName':
                            colKey = 'department';
                            break;
                        default:
                            colKey = params.listColumns[index]['key'];
                    }
                    orderObject[colKey] = params.listColumns[index]['order'];
                    request['order'] = orderObject;
                }
            }
        }
        if (typeof request.department === 'string') {
            request.department = request.department.split(',');
        }

        request = CanonicalizeHelper.addSuffix('jobs', request);

        return {
            listObservable: this.apiJobs.jobs([request]),
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }

    checkTotalItems() {
        // check items list without selection
        let initList = this.getList({listSearchFilters: {}}, 0,
            null, false, this.archive);
        initList.listObservable.subscribe(list => {
            this.totalItems = list.count;
            this.jobsState.stateChanged.next({
                totalItems: this.totalItems
            });
        });
    }

    getJobList(unsubscribe = false, callback = null) {
        let list = this.getList(this.jobsState, 0,
            null, false, this.archive);
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList = this.setSubAccountName(
                    this.truncateDate(list)
                );
                if (unsubscribe) {
                    listObservableSubscription.unsubscribe();
                }

                if (callback) {
                    this.alertService.setConfig(callback);
                }
                this.loading = false;
                this.jobsState.stateChanged.next({
                    list: list
                });
            }
        );
    }

    listScrolled(): void {
        if (this.toList['count'] && this.recordsLoaded >= this.toList['count']) {
            return;
        }
        let list = this.getList(this.jobsState, this.recordsLoaded, this.toList['count'], true, this.archive);
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                let truncatedList = this.truncateDate(list);
                this.toList['data'] =
                    [...this.toList['data'], ...truncatedList['data']];
                this.loading = false;
                listObservableSubscription.unsubscribe();
                this.jobsState.stateChanged.next({
                    list: list
                });

            }
        );
    }

    /**
     * Set search parameter on event from shared/main-list/component/main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.jobsState.listSearchFilters.search = event;
        this.jobsState.stateChanged.next({
            listSearchFilters: { search: event }
        });
    }

    /**
     * Set selected job
     * @param item
     */
    jobSelected(item) {
        if (!this.isMobile) {
            this.jobService.setItemToDisplayWithId(item[0].id, this.specificState, 'resetSidebarToReport');
            // open sidebar
            this._opened = true;
        } else {
            this.jobService.setItemToDisplayWithId(item[0].id, this.specificState, 'mobileView');
        }
    }

    goToPrec() {
        this.switchOffFilterPanelIndicator(true);
        if (this.jobsState.lastSideBarStatus === 'recentActivities') {
            this.revertToNews();
        }
    }

    checkUserJobsDisplayPreferences() {
        let listColumn = this.listColumnsInitialConfig;
        let display = _.get(this.userData, 'preferences.jobs.display');
        if (display) {
            for (let k in display) {
                if (display.hasOwnProperty(k)) {
                    let colIndex = listColumn.findIndex(col => col.key === k);
                    if (colIndex > -1) {
                        listColumn[colIndex].display = display[k];
                    } else {
                        listColumn.push({key: this.userPreferencesService.plusToDot(k), display: display[k]});
                    }
                }
            }
        }
        this.listColumns = JSON.parse(JSON.stringify(listColumn));
    }

    get archive() {
        return this.actualModule.archive;
    }

    get specificState() {
        return (!this.archive) ? 'jobs' : 'archivesJobs';
    }

    get jobsState(): any {
        return this.stateService[this.specificState];
    }

    get jobsStartDate() {
        return (this.jobsState && this.jobsState.listSearchFilters) ?
            this.jobsState.listSearchFilters.jobsStartDate :
            null;
    }

    get jobsEndDate() {
        return (this.jobsState && this.jobsState.listSearchFilters) ?
            this.jobsState.listSearchFilters.jobsEndDate :
            null;
    }

    get userPreferences() {
        return (this.sessionData && this.sessionData.userData) ? this.sessionData.userData.preferences : null;
    }

    get totalListItems() {
        return (this.toList && this.toList.count !== this.totalItems) ? this.toList.count : null;
    }

    get resMention() {
        return (this.totalListItems > 1) ? 'commons.results' : 'commons.result';
    }

    get tableDefinition() {
        const statusIacIndex = this.jobsState.listColumns.findIndex(col => col.key === 'statusIac');
        if (statusIacIndex > -1) {
            // remove statusIac from the list if it was found
            this.jobsState.listColumns.splice(statusIacIndex, 1);
        }
        let listColumnsNonTrivial = _.compact(_.uniqBy(this.jobsState.listColumns, 'key'));

        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.jobsState.listColumns;

        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            let colKey = col.key;
            colKey = colKey.replace('.', '_');
            col['title'] = this.columnsName[this.translate.currentLang][colKey];
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }

}
