import { ActivatedRoute } from '@angular/router';
import { AdministrationCommonListComponent } from '@admin/components/administration-common-list/administration-common-list.component';
import { Component } from '@angular/core';
import { StateService } from '@core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-administration-users-list',
    standalone: false,
    imports: [],
    templateUrl: './administration-users-list.component.html',
    styleUrl: './administration-users-list.component.scss'
})
export class AdministrationUsersListComponent extends AdministrationCommonListComponent {

    constructor(
        protected translate: TranslateService,
        protected route: ActivatedRoute,
        protected stateService: StateService
    ) {
        super(translate, route, stateService);
    }
    
    onUserSearch(value: string): void {
        const pattern = new RegExp(value, 'i');
        this.list = this.originalList.filter((item: any) => pattern.test(item.firstName) || pattern.test(item.lastName));
    }
    
    UsersListCellProperties(columnIndex) {
        let styleObject = {
            width: '',
            vertical_align: '',
            text_align: ''
        };

        switch (columnIndex) {
            case 2:
                styleObject.width = '200px';
                return styleObject;
            case 4:
                styleObject.width = '100px';
                return styleObject;
            default:
                styleObject.width = 'auto';
                return styleObject;
        }
    }
}
