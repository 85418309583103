import {ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-right-matches-person-sub-card-cai',
    templateUrl: './right-matches-person-sub-card-cai.component.html',
    styleUrls: ['./right-matches-person-sub-card-cai.component.scss',
        '../../right-matches-person-card.component.scss'
    ]
})
export class RightMatchesPersonSubCardCaiComponent implements OnChanges {

    @Input() record;
    @Input() job;
    @Input() pos;
    @Input() informationVisibility = false;
    @Input() listView = true;

    constructor(
        public translateService: TranslateService,
        public cd: ChangeDetectorRef
    ) {
    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    get timeSpent() {
        let ret: any = false;
        if (this.record['RA'] && this.record['RA']['passationTime']) {
            let time = this.record['RA']['passationTime'];
            let mins = Math.floor(time / 60);
            let secs = time % 60;

            ret = '';

            ret = '' + mins + ' mins ';
            ret += '' + secs + 's';
        }

        return ret;
    }

    get cai() {
        if (
            this.record &&
            this.record.displayValues &&
            this.record.displayValues.cai
        ) {
            return this.record.displayValues.cai;
        } else {
            return null;
        }
    }
}
