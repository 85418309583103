import {
    ChangeDetectorRef,
    EventEmitter,
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy
} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from '../../../../../app/services/window-ref.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {EnvService} from '../../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-graphboards-pdf',
    templateUrl: './mobile-graphboards-pdf.component.html',
    styleUrls: ['./mobile-graphboards-pdf.component.scss']
})
export class MobileGraphboardsPdfComponent implements OnInit, OnDestroy {

    @Input() itemToDisplay;
    @Output() close = new EventEmitter();

    pdfStructure: any;
    pdfEmail: any;
    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    mailto: string;
    serverText: string;

    selectMethod = false;

    method = 'pdf';
    share = 'none';

    private subscriptions = new Subscription();

    constructor(
        private apiGraphboardsService: ApiGraphboardsService,
        private changeDetectorRef: ChangeDetectorRef,
        private windowRef: WindowRef,
        public translate: TranslateService,
        public environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.initPdfStructure();
    }

    initPdfStructure() {
        // PDF structure
        this.pdfStructure = Object.assign({}, {
            designDePoste: 0,
            graphiques: 0
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: (this.translate.currentLang === 'fr') ? 'Rapport Multigraphe' : 'Graphboard Report',
            message: '',
        });

        // PDF link
        this.pdfToken = null;
        this.pdfReportUrl = this.environment.pdfReportUrl;
    }

    getReportData(data: any) {

        let obs = this.apiGraphboardsService.reportGraphboard([this.itemToDisplay.id], [
            [
                3
            ],
            this.translate.currentLang
        ]);

        this.subscriptions.add(obs.subscribe(
            res => {
                this.pdfToken = encodeURI(res.pdfToken);

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + this.pdfToken;
                if (this.translate.currentLang === 'fr') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=Rapport pour l\'analyse Graphboard ' + this.itemToDisplay.name + '&body=Vous pouvez voir le rapport pour' +
                            ' l\'analyse Graphboard ' + this.itemToDisplay.name + ' en cliquant sur le lien suivant :%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `Vous pouvez voir le rapport pour l'analyse Graphboard ` +
                            this.itemToDisplay.name +
                            ` en cliquant sur le lien suivant :

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                } else if (this.translate.currentLang === 'en') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=' + this.itemToDisplay.name + ' Graphboard Analysis Report&body=You can see the report for' +
                            ' the Graphboard Analysis ' + this.itemToDisplay.name + ' by clicking here:%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `You can see the report for the Graphboard Analysis ` +
                            this.itemToDisplay.name +
                            ` by clicking here:

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                }


                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.onComplete();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport Multigraphe' : 'Graphboard Report';
        }
        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.itemToDisplay.name,
            'graphboards',
            this.translate.currentLang
        ];

        this.subscriptions.add(this.apiGraphboardsService.reportEmail(dataEmail).subscribe());

        this.onComplete();
    }

    handleReport() {
        switch (this.method) {
            case 'pdf':
                this.downloadReport();
                return;
            case 'cutpaste':
                this.share = 'cutpaste';
                this.getReportData({type: 'token'});
                return;
            case 'mailto':
                this.share = 'mailto';
                this.getReportData({type: 'mailto'});
                return;
            case 'server':
                this.share = 'server';
                this.getReportData({type: 'share'});
                return;
            default:
                this.share = 'none';

        }
        this.selectMethod = true;
        return;

    }

    changeSelectMethodState() {
        if (this.method !== 'none') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
    }

    onComplete() {
        this.cancel();
        this.initPdfStructure();
        this.close.emit();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
