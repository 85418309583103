import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import * as _ from 'lodash';
import {UserService} from '../../../../../core/services/user/user.service';

@Component({
    selector: 'app-right-matches-reports-filters-modal',
    templateUrl: './right-matches-reports-filters-modal.component.html',
    styleUrls: ['./right-matches-reports-filters-modal.component.scss']
})
export class RightMatchesReportsFiltersModalComponent implements OnInit {

    filters: any;
    tmpRecords: any = null;
    /**
     = { // default same as default sate
        sortBy: 'personality',
        display: {
            personality: true,
            talents: true,
            cai: true,
            targetPersonalityProfile: true
        }
    };*/

    public close: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public stateService: StateService,
        public rightMatch: RightMatchAnalysisService,
        public cd: ChangeDetectorRef,
        public user: UserService
    ) {
    }

    ngOnInit() {
        // clone
        this.tmpRecords = null;
        this.filters = (JSON.parse(JSON.stringify(this.state.displayPreferences)));
        this.filters.display.personality = (!this.user.hasPersonalityAccess) ? false : this.filters.display.personality;
        this.filters.display.talents = (!this.user.hasTalentsAccess) ? false : this.filters.display.talents;
        this.filters.display.cai = (!this.user.hasIacAccess) ? false : this.filters.display.cai;
    }

    checkIfValid(number) {
        return (
            number === null ||
            number === undefined ||
            number === false
        );
    }

    sortRecords() {
        let sortBy = this.filters.sortBy;
        this.stateService.rightMatch.displayPreferences.sortBy = sortBy;
        let removed = [];
        let records = [];
        // sort
        if (sortBy === 'cai') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].displayValues.cai.iacDeltaLevel)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }
            records = _.sortBy(records,
                [function (o) {
                    return o.displayValues.cai.iacDeltaLevel;
                }]).reverse();
            records = records.concat(removed);
        } else if (sortBy === 'talents') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].displayValues.competencies.competenciesGreenTotal)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }
            records = _.sortBy(records,
                [function (o) {
                    return o.displayValues.competencies.competenciesGreenTotal;
                }]).reverse();
            records = records.concat(removed);
        } else if (sortBy === 'personality') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].rightMatchScore)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }

            records = _.sortBy(records,
                [function (o) {
                    return o.rightMatchScore;
                }]).reverse();
            records = records.concat(removed);

        }
        this.tmpRecords = records;
        this.cd.markForCheck();
    }

    onSubmit() {
        if (this.tmpRecords !== null) {
            this.rightMatch.records = this.tmpRecords;
            this.stateService.rightMatch.stateChanged.next({
                reorder: true,
                itemToDisplayId: this.rightMatch.id
            });
        }

        this.state.displayPreferences = (JSON.parse(JSON.stringify(this.filters)));

        this.closeModal();
    }

    closeModal() {
        this.close.emit({closed: true});
        this.bsModalRef.hide();
        this.cd.markForCheck();
    }

    filter(filter) {
        if (!this.canClickFilter(filter)) {
            return false;
        } else {
            if (filter === 'personality' && this.filters.display.personality) {
                if (this.filters.display.talents) {
                    this.filters.sortBy = 'talents';
                } else if (this.filters.display.cai) {
                    this.filters.sortBy = 'cai';
                }
                this.sortRecords();
            }
            if (filter === 'talents' &&  this.filters.display.talents) {
                if (this.filters.display.cai) {
                    this.filters.sortBy = 'cai';
                } else if (this.filters.display.personality) {
                    this.filters.sortBy = 'personality';
                }
                this.sortRecords();
            }
            if (filter === 'cai' && this.filters.display.cai) {
                if (this.filters.display.personality) {
                    this.filters.sortBy = 'personality';
                } else if (this.filters.display.talents) {
                    this.filters.sortBy = 'talents';
                }
                this.sortRecords();
            }
        }
        return true;
    }

    canClickFilter(filter) {
        let active = 0;
        let last: string;
        if (this.filters.display.personality) {
            last = 'personality';
            active++;
        }
        if (this.filters.display.talents) {
            last = 'talents';
            active++;
        }
        if (this.filters.display.cai) {
            last = 'cai';
            active++;
        }
        return (active > 1 || (active === 1 && last !== filter));
    }

    get totalCompetencies() {
        return _.size(this.rightMatch.itemToDisplay.profiler['competences'][0]);
    }

    get state() {
        return this.stateService.rightMatch;
    }
}
