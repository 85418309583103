import {Component, EventEmitter, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ChangeDetectionStrategy} from '@angular/core';
import {Subscription} from 'rxjs';

import {StateService} from '../../../../../core/services/state/state.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {RightMatch} from '../../../models/right-match.model';
import {Tools} from '../../../../../shared/misc/tools';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    styleUrls: ['./right-matches-modal.component.scss'],
    selector: 'app-right-matches-modal',
    templateUrl: './right-matches-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightMatchesModalComponent implements OnInit, OnDestroy {
    @Input() status;
    @Input() multiAction;

    @ViewChild('rightMatchNgForm') rightMatchNgForm;
    public close: EventEmitter<any> = new EventEmitter();
    public multiActionDestroy: EventEmitter<any> = new EventEmitter();

    rightMatch: RightMatch;
    rightMatchForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    profilers: any;
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    profilerHasChanged = false;

    public loading = false;

    headerText: string;

    submitted = false;

    private subscriptions = new Subscription();

    constructor(
        public rightMatchAnalysis: RightMatchAnalysisService,
        public bsModalRef: BsModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private router: Router,
        private stateService: StateService,
        private translate: TranslateService,
        private apiRightMatchesService: ApiRightMatchesService,
        private apiJobsService: ApiJobsService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.subscriptions.add(this.apiJobsService.jobs([{
            'hasScore': 1,
            'fields': 'id,jobTitle',
            'limit': 500,
            'order[date]': 'desc',
            'isArchived': 0,
            'statuses[score]': 1
        }]).subscribe(
            (res) => {
                this.profilers = res.data;
                this.loading = false;
                this.changeDetectorRef.markForCheck();
            }
        ));

        this.requestedBy = this.sessionData.userData.username;
        this.sessionStructure = this.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.rightMatch = Object.assign({}, new RightMatch());
            this.rightMatch.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.rightMatch = Object.assign({}, this.rightMatchAnalysis.itemToDisplay);
        }
        if (this.multiAction && this.multiAction.ids && this.multiAction.ids.length) {
            this.headerText = (this.multiAction.ids.length === 1) ?
                'analysisCommons.addCandidateToAnalysis' :
                'analysisCommons.addCandidatesToAnalysis';
        }

        this.initForm();
    }

    closeModal(rmId = this.rightMatch.id) {
        if (this.multiAction === true) {
            this.router.navigate(['/people/list']).then(() => {
            });
            return;
        }
        this.resetForm();
        // TODO: Check if profilerHasChanged really is useful or what it is useful for... Had to remove branching in rm modal handling service
        this.close.emit({closed: true, id: rmId, profilerHasChanged: this.profilerHasChanged});
        this.bsModalRef.hide();
    }

    formCheck(specificField = null) {
        if (this.rightMatchNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.rightMatchForm.controls[specificField], this.rightMatchNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.rightMatchForm.controls[field], this.rightMatchNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.submitted = false;

        let idProfiler = (this.rightMatch.profiler) ? this.rightMatch.profiler.id : '';
        if (this.rightMatch.profiler && typeof this.rightMatch.profiler === 'string') {
            idProfiler = this.rightMatch.profiler;
        }
        const departmentId = (this.rightMatch.department && this.rightMatch.department.length > 0)
            ? this.rightMatch.department[0].id
            : null;

        let subAccount = (this.sessionData.structure.principalSubAccount) ?
            this.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.rightMatch.subAccount;
        }

        this.rightMatchForm = this.fb.group({
            name: [this.rightMatch.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.rightMatch.consultant],
            profiler: [idProfiler],
            department: [departmentId]
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (model.valid) {
            // console.log(model.value);
            let data = model.value;
            if (this.rightMatch && this.rightMatch.profiler && this.rightMatch.profiler.id) {
                this.profilerHasChanged = (data.profiler !== this.rightMatch.profiler.id);
            }


            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    saveNew(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiRightMatchesService.postRightMatch(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'records': this.multiAction.ids};
                    this.subscriptions.add(this.apiRightMatchesService.putRightMatch([res.id], data).subscribe(
                        () => {
                            this.multiActionDestroy.emit({destroy: true});
                            this.closeModal(res.id);
                        }
                    ));
                } else {
                    this.closeModal(res.id);
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiRightMatchesService.putRightMatch([this.rightMatch.id], data).subscribe(
            () => {
                this.closeModal(this.rightMatch.id);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.rightMatchForm.reset();
            this.rightMatchNgForm.submitted = false;
            this.initForm();
        });
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}

