import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import * as d3 from "d3";

@Component({
    selector: 'app-mini-right-match-scale',
    templateUrl: './mini-right-match-scale.component.html',
    styleUrls: ['./mini-right-match-scale.component.scss']
})
export class MiniRightMatchScaleComponent implements OnChanges {

    @ViewChild('MiniRightMatchScale', {static: true}) private chartContainer: ElementRef;
    @Input() fives: number;
    @Input() fours: number;
    @Input() threes: number;
    @Input() twos: number;
    @Input() ones: number;

    amount = [];

    @Output() sortWhich = new EventEmitter();

    sizeFactor = 1;

    constructor() {
    }

    ngOnChanges() {
        this.amount = [
            0,
            this.ones,
            this.twos,
            this.threes,
            this.fours,
            this.fives
        ];
        const colors = {
            gray: "#E2E2E2",
            darkGray: "rgb(85, 85, 85)"
        };



        let element = this.chartContainer.nativeElement;

        let width = 350 * this.sizeFactor;
        let height = 40 * this.sizeFactor;

        d3.select('#concordanceRMScale').remove();

        let svg = d3.select(element).append('svg').attr('id', 'concordanceRMScale').attr("preserveAspectRatio", "xMidYMid meet").
            attr("width", width).
            attr("height", height).
            attr("viewBox", "0 0 " + width + " " + height);

        for(let i=5; i>0; i--){
            let dist = 75 * (5-i);
        svg.append("circle")
            .attr("cx", (20 + dist) * this.sizeFactor)
            .attr("cy", 20 * this.sizeFactor)
            .attr("r", 17 * this.sizeFactor)
            .style('fill', 'white')
            .style("stroke-width", 1.5)
            .style("stroke", colors.gray)
            .on('click', ()=>{this.sort(i)});


        svg.append("text").attr("x", (16 + dist)*this.sizeFactor).attr("y", 26*this.sizeFactor).text(i).
            attr("font-family",  "sans-serif").
            attr("font-weight", "bold").attr("font-size", 15).
            attr("fill", "rgb(85, 85, 85)")
            .on('click', ()=>{this.sort(i)});

        svg.append("circle")
            .attr("cx", (34 + dist) * this.sizeFactor)
            .attr("cy", 10 * this.sizeFactor)
            .attr("r", 9 * this.sizeFactor)
            .style('fill', 'white')
            .style("stroke-width", 1.5)
            .style("stroke", colors.gray)
        .on('click', ()=>{this.sort(i)});
        //if(!this.amount[i]) this.amount[i] = 0;
        let x = (this.amount[i]>9)? (29 + dist)*this.sizeFactor : (32 + dist)*this.sizeFactor;
        svg.append("text").attr("x", x).attr("y", 13*this.sizeFactor).text(this.amount[i]).
            attr("font-family",  "sans-serif").
            attr("font-size", 10).
            attr("fill", "rgb(227, 94, 36)")
            .on('click', ()=>{this.sort(i)});

        }

    }

    sort(which){
        this.sortWhich.emit(which);
    }

}
