import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Content} from 'src/app/shared/classes/services/content';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ApiIdwMatricesService} from './api-idw-matrices.service';
import {IdwMatrixAnalysisService} from './idw-matrix-analysis.service';
import {IdwMatrixListService} from './idw-matrix-list.service';
import {IdwMatricesModalComponent} from '../components/web/idw-matrices-modal/idw-matrices-modal.component';
import {IdwMatrixPersonModalComponent} from '../components/web/idw-matrix-person-modal/idw-matrix-person-modal.component';
import {IdwMatricesPdfModalComponent} from '../components/web/idw-matrices-pdf-modal/idw-matrices-pdf-modal.component';
import {AddPeopleAnalysisModalComponent} from '../../shared/add-people-analysis-modal/add-people-analysis-modal.component';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IdwMatrixModalHandlingService extends Content {
    idwMatrixModalStatus = 'new';
    mModalRef: BsModalRef;
    personModalRef: BsModalRef;
    peopleAnalysisModalRef: BsModalRef;
    pdfModalRef: BsModalRef;

    constructor(
        public apiIdwMatrices: ApiIdwMatricesService,
        public idwMatrix: IdwMatrixAnalysisService,
        public idwMatricesList: IdwMatrixListService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    openIdwMatrixModal(config) {
        this.idwMatrixModalStatus = config.status;
        this.idwMatricesList.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.idwMatricesList.multiAction,
            status: this.idwMatrixModalStatus
        };
        this.mModalRef = this.modalService.show(IdwMatricesModalComponent, {initialState, backdrop : 'static'});
        this.mModalRef.content.multiActionDestroy.subscribe(res => {
            if (res.destroy) {
                this.idwMatricesList.idwMatrixState.resetMultiAction();
                this.idwMatricesList.multiAction = null;
            }
        });
        this.mModalRef.content.close.subscribe(res => {
            if (res.closed && res.id) {
                this.idwMatrix.setItemToDisplayWithId(res.id);
                this.idwMatricesList.getIdwMatricesList(true);
            }
        });
    }

    openIdwMatrixPersonModal() {
        this.idwMatricesList.cleanMultiselectMode(true);
        this.personModalRef = this.modalService.show(IdwMatrixPersonModalComponent, {backdrop : 'static'});
        this.personModalRef.content.close.subscribe(res => {
            if (res.closed && res.id) {
                this.idwMatrix.setItemToDisplayWithId(res.id);
                this.idwMatricesList.getIdwMatricesList(true);
            }
        });
    }

    openIdwMatrixPeopleToAnalysisModal() {
        this.apiIdwMatrices.ditGrams([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = this.idwMatricesList.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [{type: 'trait', record: record.id}, {type: 'prb', dash: 0, record: record.id}]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                modalTitle: 'commons.addToTeamMatrix',
                analysisType: 'idw'
            };

            // Open modal
            this.peopleAnalysisModalRef = this.modalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });

            this.peopleAnalysisModalRef.content.close.subscribe(res => {
                if (res && res.id) {
                    this.idwMatrix.setItemToDisplayWithId(res.id);
                    this.idwMatricesList.getIdwMatricesList(true);
                }
            });
        });
    }

    openIdwMatrixPdfModal() {
        this.idwMatricesList.cleanMultiselectMode(true);
        this.pdfModalRef = this.modalService.show(IdwMatricesPdfModalComponent, {backdrop : 'static'});
    }
}
