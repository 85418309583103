import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {StateService} from '../../../../../core/services/state/state.service';
import {UserService} from '../../../../../core/services/user/user.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-job-satisfactions-reports-filters-modal',
  templateUrl: './job-satisfactions-reports-filters-modal.component.html',
  styleUrls: ['./job-satisfactions-reports-filters-modal.component.scss']
})
export class JobSatisfactionsReportsFiltersModalComponent implements OnInit {

    filters: any;
    tmpRecords: any = null;

    ordering;

    public close: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public stateService: StateService,
        public jobSatisfactions: JobSatisfactionsAnalysisService,
        public cd: ChangeDetectorRef,
        public user: UserService
    ) {
    }

    ngOnInit() {
        // clone
        this.tmpRecords = null;
        this.filters = (JSON.parse(JSON.stringify(this.state.displayPreferences)));
    }

    checkIfValid(number) {
        return (
            number === null ||
            number === undefined ||
            number === false
        );
    }

    sortRecords() {
        // last n characters of this.filter.sortBy
        let sortBy = this.filters.sortBy;
        let order = 'desc';
        if (sortBy.slice(-4) === 'Desc') {
            sortBy = sortBy.substring(0, sortBy.length - 4);
        } else if (sortBy.slice(-3) === 'Asc') {
            sortBy = sortBy.substring(0, sortBy.length - 3);
            order = 'asc';
        }
        this.stateService.jobSatisfaction.displayPreferences.sortBy = this.filters.sortBy;


        let records = this.jobSatisfactions.records;
        let removed = [];

        if (sortBy === 'name') {
            // sort records by lastName
            records = _.sortBy(records, ['lastName']);
        } else if (
            sortBy === 'jobSatisfactionScore' ||
            sortBy === 'lastPcr'
        ) {
            records = [];
            for (let i in this.jobSatisfactions.records) {
                if (this.checkIfValid(this.jobSatisfactions.records[i].jobSatisfactionScore) ||
                    this.jobSatisfactions.records[i].jobSatisfactionScore === - 1
                ) {
                    removed.push(this.jobSatisfactions.records[i]);
                } else {
                    records.push(this.jobSatisfactions.records[i]);
                }
            }
            // sort records by lastName
            records = _.sortBy(records, ['jobSatisfactionScore']);
        } else if (sortBy === 'lastPcr') {
            records = _.sortBy(records, ['prb[0].date'], order);
        }

        if (order === 'desc') {
            records = records.reverse();
        }

        records = records.concat(removed);

        this.tmpRecords = records;
        this.cd.markForCheck();
    }

    onSubmit() {
        this.sortRecords();
        if (this.tmpRecords !== null) {
            this.jobSatisfactions.records = this.tmpRecords;
            this.stateService.jobSatisfaction.stateChanged.next({
                reorder: true,
                itemToDisplayId: this.jobSatisfactions.id
            });
        }

        this.state.displayPreferences = (JSON.parse(JSON.stringify(this.filters)));

        this.closeModal();
    }

    closeModal() {
        this.close.emit({closed: true});
        this.bsModalRef.hide();
        this.cd.markForCheck();
    }

    filter(filter) {
        if (!this.canClickFilter(filter)) {
            return false;
        }
        this.sortRecords();
        return true;
    }

    canClickFilter(filter) {
        let active = 0;
        let last: string;
        return (active > 1 || (active === 1 && last !== filter));
    }

    get state() {
        return this.stateService.jobSatisfaction;
    }
}
