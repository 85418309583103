import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {
    JobSatisfactionsAnalysisService
} from '../../../analysis/jobSatisfactions/services/job-satisfactions-analysis.service';
import {JobSatisfactionsGraphs} from '../job-satisfactions-graphs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';
import {
    JobSatisfactionTeamGraphHelper
} from '../../../analysis/jobSatisfactions/components/commonClasses/jobSatisfactionTeamGraphHelper';

@Component({
    selector: 'app-job-satisfactions-team-graph',
    templateUrl: './job-satisfactions-team-graph.component.html',
    styleUrls: ['./job-satisfactions-team-graph.component.scss']
})
export class JobSatisfactionsTeamGraphComponent extends JobSatisfactionsGraphs implements OnChanges {

    @ViewChild('jobSatisfactionTeamGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id = 'actual';
    @Input() display = 'web';
    @Input() months = 3;

    actualIndex = 0;
    prbs = [];
    isMobile = false;
    fontSize: any = 8;

    constructor(
        public translate: TranslateService,
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        public deviceService: DeviceDetectorService,
        public stateService: StateService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }


    ngOnChanges(): void {
        this.isMobile = this.deviceService.isMobile();
        this.fontSize = (this.isMobile) ? '15pt' : 8;
        this.months = (this.isMobile) ? 3 : 12;
        this.prbs = JobSatisfactionTeamGraphHelper.setData(this.jobSatisfaction.records, this.months);
        this.stateService.jobSatisfaction.stateChanged$.subscribe(
            (res) => {
                this.prbs = JobSatisfactionTeamGraphHelper.setData(this.jobSatisfaction.records, this.months);
                this.actualIndex = this.prbs.length - 1;
                this.graph();
            }
        );
        this.translate.onLangChange.subscribe(
            (res) => {
                this.prbs = JobSatisfactionTeamGraphHelper.setData(this.jobSatisfaction.records, this.months);
                this.actualIndex = this.prbs.length - 1;
                this.graph();
            }
        );

    }

    precText() {
        if (this.isMobile) {
            return (this.translate.currentLang === 'fr') ? 'Précédent' : 'Previous';
        } else {
            return (this.translate.currentLang === 'fr') ? 'Année précédente' : 'Previous year';
        }

    }

    nextText() {
        if (this.isMobile) {
            return (this.translate.currentLang === 'fr') ? 'Suivant' : 'Next';
        } else {
            return (this.translate.currentLang === 'fr') ? 'Année suivante' : 'Next year';
        }

    }

    get hasPredecessor() {
        return (this.actualIndex > 0);
    }

    get hasSuccessor() {
        if (this.prbs) {
            return (this.actualIndex < this.prbs.length - 1);
        } else {
            return false;
        }
    }

    onPreviousYear() {
        if (this.hasPredecessor) {
            this.actualIndex--;
            this.graph();
        }
    }

    onNextYear() {
        if (this.hasSuccessor) {
            this.actualIndex++;
            this.graph();
        }
    }

    graph() {
        // set the dimensions and margins of the graph
        let margin = {top: 10, right: 30, bottom: 30, left: 60},
            width = 728.5 - margin.left - margin.right,
            height = 130 - margin.top - margin.bottom;

        let element = this.chartContainer.nativeElement;

        // Remove duplicates
        d3.select('#' + 'jobSatisfactionTeamGraph_' + this.id).remove();

        let viewBoxLeftX = (this.isMobile) ? '-40' : '-20';
        let viewBoxRightX = (this.isMobile) ? '680' : '660';
        let svg = d3.select(element)
            .append('svg')
            .attr('id', 'jobSatisfactionTeamGraph_' + this.id)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', viewBoxLeftX + ' -8 ' + viewBoxRightX + ' 125')
            .attr('preserveAspectRatio', 'none');


        // Add X axis --> it is a date format
        // console.log(this.prbs[this.actualIndex]);
        let x = d3.scaleTime().domain(d3.extent(this.prbs[this.actualIndex], function (d: any) {
            let date = new Date(d.date);
            // console.log(date);
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMonth(date.getMonth() + 1);
            // console.log(date);
            return date;
        })).range([20, width - 20]);

        // Add Y axis
        let y = d3.scaleLinear()
            .domain([0, 4])
            .range([height, 0]);
        this.writeGrid(svg, width, height, x, y);

        // End of axis, remove first and last ticks
        // this.prbs.shift();
        // Gradient definition


        svg.append('linearGradient')
            .attr('id', 'svgGradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', 0)
            .attr('y1', 130 - margin.top - margin.bottom) // 110
            .attr('x2', 0)
            .attr('y2', 0)// 0
            .selectAll('stop')
            .data(d3.ticks(0, 2, 2))
            .join('stop')
            .attr('offset', d => d / 2)
            .attr('stop-color', d => this.color[d + 1]);

        // Add the curve

        let prbs = this.prbs[this.actualIndex];
        // console.log(this.prbs[this.actualIndex]);
        svg
            .append('path')
            .datum(prbs)
            .attr('fill', 'none')
            .attr('stroke', 'url(#svgGradient)')
            .attr('stroke-width', 1.25)
            .attr('d', d3.line()
                .defined(function (d) {
                    // @ts-ignore
                    return d.percent;
                })
                .x(function (d: any) {
                    return x(new Date(d.date));
                })
                .y(function (d: any) {
                    return y(d.percent);
                })
                .curve(d3.curveMonotoneX));

        // Add the points
        for (let datum of this.prbs[this.actualIndex]) {
            if (datum.percent !== null && !isNaN(datum.percent)) {
                svg
                    .append('circle')
                    .datum(datum)
                    .attr('fill', 'url(#svgGradient)')
                    .attr('cx', function (d: any) {
                        return x(new Date(d.date));
                    })
                    .attr('cy', function (d: any) {
                        return y(d.percent);
                    })
                    .attr('r', 3.5);
            }
        }
        // Text
        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 4  + ((this.isMobile) ? 4 : 0))
            .attr('text-anchor', 'middle')
            .text('100%')
            .attr('font-size', this.fontSize);

        if (!this.isMobile) {
            svg
                .append('text')
                .attr('x', -11)
                .attr('y', 26)
                .attr('text-anchor', 'middle')
                .text('75%')
                .attr('font-size', this.fontSize);
        }


        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 48 + ((this.isMobile) ? 2 : 0))
            .attr('text-anchor', 'middle')
            .text('50%')
            .attr('font-size', this.fontSize);

        if (!this.isMobile) {
            svg
                .append('text')
                .attr('x', -11)
                .attr('y', 71)
                .attr('text-anchor', 'middle')
                .text('25%')
                .attr('font-size', this.fontSize);
        }

        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 93 + ((this.isMobile) ? 0 : 0))
            .attr('text-anchor', 'middle')
            .text('0%')
            .attr('font-size', this.fontSize);


        ///// MOUSE ////////////

        // This allows to find the closest X index of the mouse:
        let bisect = d3.bisector(function (d: any) {
            return d.date;
        }).left;
        // Create the circle that travels along the curve of chart
        let focus = svg
            .append('g')
            .append('circle')
            .style('fill', 'none')
            .attr('stroke', '#bbb')
            .attr('stroke-width', 3)
            .attr('r', 4.5)
            .style('opacity', 0);

        // Create the text that travels along the curve of chart
        let focusText = svg
            .append('g')
            .append('text')
            .attr('class', 'focusText')
            .style('opacity', 0)
            .attr('text-anchor', 'left')
            .attr('alignment-baseline', 'middle');

        // Create a rect on top of the svg area: this rectangle recovers mouse position
        svg
            .append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseover)
            .datum(this.translate.currentLang)
            .on('mousemove', mousemoveFunction)
            .on('mouseout', mouseout);

        // What happens when the mouse move -> show the annotations at the right positions.
        function mouseover() {
            focusText.style('opacity', 1);
            tooltip.style('visibility', 'visible');
        }

        let data = this.prbs[this.actualIndex];

        let tooltip = d3.select('body')
            .append('div')
            .attr('id', 'teamTooltip')
            .style('position', 'absolute')
            .style('z-index', '100000000')
            .style('visibility', 'hidden')
            .style('background', '#ddd')
            .style('padding', '5px');

        function mousemoveFunction(translate) {
            // recover coordinate we need
            let x0 = x.invert(d3.mouse(this)[0]).toISOString().split('T')[0];
            let i = bisect(data, x0, 0);
            let selectedData: any = data[i];
            if (selectedData && selectedData.percent !== null) {
                let date = new Date(selectedData.date);
                let formatedDate = selectedData.date.split('-');
                formatedDate = formatedDate[1] + '/' + formatedDate[0];
                let persons = (translate === 'fr') ? 'Individus' : 'Persons';
                if (!isNaN(Math.round(((data[i].percent / 4) * 100)))) {
                    focus
                        .attr('color', '#bbb')
                        .attr('class', 'graphPopUp')
                        .attr('cx', x(date))
                        .attr('cy', y(selectedData.percent))
                        .style('opacity', 1);
                    tooltip
                        .style('top', (d3.event.pageY - 10) + 'px')
                        .style('left', (d3.event.pageX - 125) + 'px')
                        .style('height', '65px')
                        .html(
                            '<p><b>Date</b>&nbsp;: ' + formatedDate + '<br>' +
                            '<b>' + persons + '</b>&nbsp;: ' + data[i].amount + '<br>' +
                            '<b>Score</b>&nbsp;: ' + Math.round(((data[i].percent / 4) * 100)) + ' %</p>'
                        );
                }

            }
        }

        function mouseout() {
            d3.selectAll('#teamTooltip').style('visibility', 'hidden');
            // focus.style('opacity', 0);
            // focusText.style('opacity', 0);
            // tooltip;
        }
    }



    writeGrid(svg, width, height, x, y) {

        // @ts-ignore
        svg.selectAll('line.verticalGrid').data(x.ticks(d3.timeMonth)).enter()
            .append('line')
            .attr('id', function (d, index) {
                return 'horizontalGrid_x_' + index;
            })
            .attr('class', 'horizontalGrid')
            .attr('class', 'verticalGrid')
            .attr('x1', function (d) {
                return x(d);
            })
            .attr('x2', function (d) {
                return x(d);
            })
            .attr('y1', 0)
            .attr('y2', height)
            .attr('fill', 'none')
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', '#ddd')
            .attr('stroke-width', '1px');

        if (this.actualIndex === (this.prbs.length - 1)) {
            svg.select('#horizontalGrid_x_' + (this.months - 1)).attr('stroke', 'rgb(227, 94, 36)');
        }

        let firstMonthFlagged = false;
        svg.append('g')
            .attr('id', 'xAxis')
            .attr('class', 'axis xAxis')
            .attr('transform', 'translate(0,' + (height + 3) + ')')
            .call(d3.axisBottom(x)
                // .scale(xLabels)
                .ticks(d3.timeMonth, 1)
                .tickSize(0)
                //    .tickFormat(quarter)
                .tickFormat(
                    (date) => {
                        // console.log(date);
                        // @ts-ignore
                        let textDate = this.produceMonth(d3.timeFormat('%m')(date));
                        // @ts-ignore
                        if (d3.timeFormat('%m')(date) === '01') {
                            firstMonthFlagged = true;
                            // @ts-ignore
                            textDate += ' ' + d3.timeFormat('%Y')(date);
                        }
                        return textDate;
                    }
                ));

        d3.select('#xAxis').selectAll('text').attr('id', function (d: string, i) {
            // id="axisTextSun Jan 01 2023 00:00:00 GMT-0500 (heure normale de l’Est nord-américain)"
            if (firstMonthFlagged) {
                if (d.toString().split(' ')[1] === 'Jan') {
                    return 'monthChange';
                }
            } else {
                if (i === 0) {
                    return 'monthChange';
                }
            }

            return 'axisText' + i;
        });

        d3.select('#monthChange').style('color', 'rgb(227, 94, 36)');

        d3.select('.domain').remove();

        /**
         * x1 = 0
         * x2 = 638.5
         * y1 et y2 = 90
         */
        svg.append('rect')
            .attr('x', 0)
            .attr('y', 89)
            .attr('width', 641)
            .attr('height', 5)
            .attr('stroke', 'none')
            .style('fill', 'white');

        svg.selectAll('line.horizontalGrid').data(y.ticks(4)).enter()
            .append('line')
            .attr('id', function (d) {
                return 'horizontalGrid_y_' + d;
            })
            .attr('class', 'horizontalGrid')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', function (d) {
                return y(d);
            })
            .attr('y2', function (d) {
                return y(d);
            })
            .attr('fill', 'none')
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', function (d, index) {
                return (index === 0) ? '#000' : '#ddd';
            })
            .attr('stroke-width', '1px');

        if (this.isMobile) {
            svg.selectAll('.xAxis>.tick>text')
                .each(function () {
                    d3.select(this).style('font-size', '15pt');
                    d3.select(this).style('text-transform', 'uppercase');
                });
        } else {
            svg.selectAll('.xAxis>.tick>text')
                .each(function () {
                    d3.select(this).style('font-size', 8);
                    d3.select(this).style('text-transform', 'uppercase');
                });
        }


        return svg;
    }

    produceMonth(number) {
        let lang = this.translate.currentLang;
        switch (number) {
            case '01':
                return (lang === 'fr') ? 'Janvier' : 'January';
            case '02':
                return (lang === 'fr') ? 'Février' : 'February';
            case '03':
                return (lang === 'fr') ? 'Mars' : 'March';
            case '04':
                return (lang === 'fr') ? 'Avril' : 'April';
            case '05':
                return (lang === 'fr') ? 'Mai' : 'May';
            case '06':
                return (lang === 'fr') ? 'Juin' : 'June';
            case '07':
                return (lang === 'fr') ? 'Juillet' : 'July';
            case '08':
                return (lang === 'fr') ? 'Août' : 'August';
            case '09':
                return (lang === 'fr') ? 'Septembre' : 'September';
            case '10':
                return (lang === 'fr') ? 'Octobre' : 'October';
            case '11':
                return (lang === 'fr') ? 'Novembre' : 'November';
            case '12':
                return (lang === 'fr') ? 'Décembre' : 'December';
            default:
                return '';
        }
    }
}
