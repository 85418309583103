import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {ComponentPrimitive} from '../../../../../shared/classes/components/componentPrimitive';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {JobReportService} from '../../../../../jobs/services/job-report.service';
import {ApiJobsService} from '../../../../../jobs/services/api-jobs.service';

@Component({
    selector: 'app-right-matches-report-job-personality',
    templateUrl: './right-matches-report-job-personality.component.html',
    styleUrls: ['./right-matches-report-job-personality.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesReportJobPersonalityComponent extends ComponentPrimitive implements OnInit {

    @Input() archive = false;
    @Input() mobile = false;
    @Output() openModQuickProfiler = new EventEmitter();
    traits: Array<string> = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];
    mpoScores: any = undefined;
    mpoGraphType = 'plain';
    currentLang = 'fr';
    hasSatellite;
    isSatellite: boolean;
    titleSep: string;
    reportDateFormat: string;
    report: any;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private translate: TranslateService,
        protected stateService: StateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        public rightMatchService: RightMatchAnalysisService,
        public jobReportService: JobReportService,
        private apiJobService: ApiJobsService
    ) {
        super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.hasSatellite = this.stateService.hasSatellite;
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.reportDateFormat = TextHelper.dateFormat(this.translate.currentLang);


        this.checkMpoScores();

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.checkMpoScores();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.getReport();

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.reportDateFormat = TextHelper.dateFormat(res.lang);

                    this.getReport();
                }
            }
        );
    }

    checkMpoScores() {
        this.mpoScores = (this.rightMatchService.jobDescription.mpoScores) ? this.rightMatchService.jobDescription.mpoScores[0] : undefined;
        this.isSatellite = false;
        if (this.mpoScores) {
            this.mpoGraphType = 'plain';
        } else {
            this.mpoGraphType = 'inactive';
        }
    }

    onResize() {
    }

    getReportSubscription;

    getReport() {
        this.getReportSubscription =
            this.apiJobService.reportJob(
                [this.rightMatchService.profilerId],
                [
                    [1], // was [1,3] but part 3 is not required and cause bugs from the back thrown when no MPO target.
                    0, // Index of the evaluation object is (so far) always 0
                    this.translate.currentLang
                ]
            ).subscribe(
                res => {
                    this.rightMatchService.jobDescription.jobReport = res;
                    this.changeDetectorRef.markForCheck();
                    this.getReportSubscription.unsubscribe();
                    // console.log(res);
                },
                err => console.log(err)
            );
    }

    /**
     * Getters
     */

    get state(): any {
        return this.stateService.jobs;
    }

    get traitsSummary(): any {
        if (this.rightMatchService.jobDescription.jobReport) {
            return this.rightMatchService.jobDescription.jobReport.traitsSummary;
        }
        return false;
    }

    get predictableBehavior(): any {
        if (this.rightMatchService.jobDescription.jobReport) {
            return this.rightMatchService.jobDescription.jobReport.predictableBehavior;
        }
        return false;
    }

    get profile(): any {
        if (this.rightMatchService.jobDescription.jobReport) {
            return this.rightMatchService.jobDescription.jobReport.profile;
        }
        return false;
    }

    get hasWarning(): boolean {
        return (this.rightMatchService.jobDescription.jobReport && this.rightMatchService.jobDescription.jobReport.warning);
    }
}
