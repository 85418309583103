import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StateService} from './services/state/state.service';

@NgModule({
    imports     : [
        CommonModule
    ],
    declarations: [],
    providers   : [StateService]
})
export class CoreModule {
}
