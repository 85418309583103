import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Subscription, timer} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {StateService} from '../../../../core/services/state/state.service';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {Location} from '@angular/common';
import {NavigationCancel, NavigationEnd, Router, ActivatedRoute, ResolveEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AdminService} from '../../../services/admin.service';
import {UserPreferencesService} from '../../../../administration/services/user-preferences.service';
import {NavigationService} from '../navigation.service';
import * as _ from 'lodash';
import {EnvService} from '../../../services/env.service';

@Component({
    selector: 'app-mobile-navigation',
    templateUrl: './mobile-navigation.component.html',
    styleUrls: ['./mobile-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavigationComponent implements OnInit, OnDestroy {

    assetsMenuFolder = '/assets/menu_gauche/';
    credits: string;
    lang: string;
    role = '';

    principalAccountCredits;

    loggedAccount = '';
    showAccount = false;
    doFetchCredits = true;

    apiBaseUrl: string;

    phoneNumber: string;

    creditListing: any = [];

    private subscriptions = new Subscription();

    constructor(
        private apiAdministrationService: ApiAdministrationService,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        public stateService: StateService,
        public translate: TranslateService,
        private adminService: AdminService,
        private userPreferencesService: UserPreferencesService,
        private navigationService: NavigationService,
        private environment: EnvService
    ) {

        // Set current locale
        if (this.translate.getDefaultLang()) {
            this.lang = this.translate.getDefaultLang();
        } else {
            let lang = this.translate.getBrowserLang();
            if (lang !== 'fr' && lang !== 'en') {
                lang = 'en';
            }
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            this.lang = lang;
        }

        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    isInUserPrefs() {
        const urlArray = this.location.path().split('/');

        // noinspection OverlyComplexBooleanExpressionJS
        return urlArray[1] === 'admin' &&
            urlArray[2] === 'account' &&
            urlArray[4] === 'user' &&
            urlArray[6] === 'preferences'
            ;
    }

    ngOnInit() {
        if (!this.stateService.session.actualModule) {
            this.setActualModuleName('people');
        }
        if (
            this.stateService &&
            this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.permissions &&
            this.stateService.session.sessionData.permissions.userType
        ) {
            this.role = this.stateService.session.sessionData.permissions.userType;
        } else {
            this.role = 'rw';
        }

        const supportUrlIndex = this.route.snapshot.url.findIndex((f) => {
            return f.path === 'support';
        });
        if (supportUrlIndex > -1) {
            this.setActualModuleName('support');
        }

        const adminUrlIndex = this.route.snapshot.url.findIndex((f) => {
            return f.path === 'admin';
        });
        if (adminUrlIndex > -1) {
            this.setActualModuleName('administration');
        }

        this.subscriptions.add(this.router.events.subscribe(
            (res) => {
                if (res instanceof NavigationEnd || res instanceof NavigationCancel || res instanceof ResolveEnd) {
                    let newMod = res.url.split('/')[2];
                    if (newMod === 'admin') {
                        newMod = 'administration';
                    }
                    if (this.stateService.session.actualModule !== newMod) {
                        this.setActualModuleName(newMod);
                    }
                }
            }
        ));

        this.subscriptions.add(this.route.params.subscribe((p) => {
                if (p.moduleName !== undefined) {
                    this.setActualModuleName(p.moduleName);
                }
            }
        ));

        this.fetchCredit();
        if (this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.accountData
        ) {

            // Timer to get credits
            this.subscriptions.add(timer(1680, 30000).subscribe(() => {
                if (this.doFetchCredits) {
                    this.fetchCredit();
                }
            }));
        }


        this.subscriptions.add(this.stateService.session.sessionCreditUpdateWatch$.subscribe(
            () => {
                this.fetchCredit();
            }
        ));


        this.subscriptions.add(this.navigationService.switchSubAccountName$.pipe(distinctUntilChanged()).subscribe(res => {
            if (res) {
                if (res === 'back') {
                    this.loggedAccount = '';
                    this.showAccount = false;
                } else {
                    this.loggedAccount = res;
                    this.fetchCredit();
                    this.showAccount = true;
                }
            }
        }));
    }

    switchBack(switchAccountDataId) {
        this.subscriptions.add(this.apiAdministrationService.clientsSwitch([switchAccountDataId]).subscribe(
            data => {
                // Reset state
                this.stateService.resetState('restoreOrigin');

                // Clear session Data (to have a clean session for the switch user)
                let sessionStorageOrigin: any = sessionStorage.getItem('origin');
                sessionStorageOrigin = JSON.parse(sessionStorageOrigin);
                sessionStorage.clear();
                for (let item in sessionStorageOrigin) {
                    if (sessionStorageOrigin.hasOwnProperty(item)) {
                        if (item) {
                            sessionStorage.setItem(item, sessionStorageOrigin[item]);
                        }
                    }
                }

                // Add key isLoggedIn (why? we already have a `is_logged` var in local storage ...)
                sessionStorage.setItem('session.isLoggedIn', 'true');
                sessionStorage.setItem('reload', 'true');
                // store in local storage
                localStorage.setItem('is_logged', 'yes');
                localStorage.setItem('id_token', data.token);
                this.stateService.session.redirectUrl = '';

                this.navigationService.updateSwitchSubAccountName('back');


                // Init session
                this.subscriptions.add(this.adminService.getSession(this.translate.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;

                        // Init preferences
                        this.userPreferencesService.initUserPreferences();

                        // this.router.navigate([`/m/admin/account/${currentAccountId}`]);
                        this.router.navigate(['/m/admin/list']).then(() => {});

                        this.fetchCredit();
                    }
                ));
            }));
    }

    fetchCredit() {
        this.doFetchCredits = false;
        let subAccountAccess = (this.stateService.session.sessionData.userData && this.stateService.session.sessionData.userData.subAccountAccess) ?
            this.stateService.session.sessionData.userData.subAccountAccess : false;
        if (this.stateService.session.sessionData.userData.username === 'ngadmin') {
            subAccountAccess = [0];
        } else {
            // Get sub-account access from session
            while (subAccountAccess === false) {
                timer(1680, 2000).subscribe(() => {
                    subAccountAccess = (this.stateService.session.sessionData.userData && this.stateService.session.sessionData.userData.subAccountAccess) ?
                        this.stateService.session.sessionData.userData.subAccountAccess : false;
                });
            }
        }

        /**
         * TODO: Fetch all at once...
         */
        this.creditListing = [];
        if (this.stateService.session.sessionData.accountData) {
            for (let subaccountNumber of subAccountAccess) {
                if (this.stateService.session.sessionData.accountData.subAccounts[subaccountNumber]) {
                    if (this.stateService.session.sessionData.accountData) {
                        this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
                            [this.stateService.session.sessionData.accountData.id, subaccountNumber]
                        ).subscribe(
                            res => {
                                if (res.unlimited) {
                                    this.creditListing[res.id] = [res.name, 'unlimited'];
                                } else {
                                    this.creditListing[res.id] = [res.name, res.credits];
                                }
                                let principalAccount = (this.stateService.session.sessionData.structure && this.stateService.session.sessionData.structure.principalSubAccount) ?
                                    this.stateService.session.sessionData.structure.principalSubAccount : '0';
                                if (this.creditListing[principalAccount] && this.creditListing[principalAccount][1] !== 'unlimited') {
                                    let credits = (this.translate.currentLang === 'fr') ? ' crédits' : ' credits';
                                    if (this.creditListing[principalAccount]) {
                                        this.principalAccountCredits = this.creditListing[principalAccount][1] + credits;
                                    } else {
                                        this.principalAccountCredits = credits;
                                    }

                                } else {
                                    this.principalAccountCredits = (this.translate.currentLang === 'fr') ? 'Illimité' : 'Unlimited';
                                }
                                this.doFetchCredits = true;
                                this.changeDetectorRef.markForCheck();
                            },
                            err => {
                                console.log(err);
                            }
                        ));
                    }
                }
            }
        }
    }

    setActualModuleName(name) {
        this.stateService.session.actualModule = name;
        this.changeDetectorRef.markForCheck();
    }

    goToTools() {
        // manage permission
        const permissions = this.stateService.session.sessionData.permissions;

        if (_.includes(permissions.roles, 'ROLE_SUPER_ADMIN')) {
            this.router.navigate(['m/tools/']).then(() => {});
        }
    }

    goToAdmin() {
        // manage permission
        const permissions = this.stateService.session.sessionData.permissions;

        const accountData = this.sessionData.accountData;

        if (_.includes(permissions.roles, 'ROLE_USER_READONLY')
            || _.includes(permissions.roles, 'ROLE_USER_READWRITE')
        ) {
            // all users go to user detail page
            const userData = this.sessionData.userData;
            this.router.navigate(['m/admin/account/' + accountData.id + '/user/' + userData.id]).then(() => {});
            return;
        }

        if (_.includes(permissions.roles, 'ROLE_ADMIN') && this.stateService.session.sessionData.permissions.accountType <= 4) {
            this.router.navigate(['m/admin/account/' + accountData.id]).then(() => {});
            return;
        }

        this.router.navigate(['/m/admin/list']).then(() => {});
    }

    navigateTo(link: [string]) {
        this.router.navigate(link).then(() => {});
    }

    onLogout() {
        this.doFetchCredits = false;

        this.router.navigate(['/']).then(() => {});
        // Clear session Data
        sessionStorage.clear();

        // delete - ToDo: use constant from angular2-jwt ???
        localStorage.removeItem('id_token');
        localStorage.setItem('is_logged', '');

        // Remove termsAccepted
        localStorage.removeItem('termsAccepted');

        // Redirect onLoggout
        localStorage.setItem('remember_me', 'false');
        this.router.navigateByUrl('/login;lang=' + this.translate.currentLang).then(() => {});

        // Clear state
        this.stateService.session.isLoggedIn = false;
        this.stateService.clearState();
    }

    switchLanguage(locale: string) {
        this.translate.use(locale);
        this.lang = locale;

        this.subscriptions.add(this.adminService.getSession(locale).subscribe(
            sessionData => {
                this.stateService.sessionData = sessionData;
                this.changeDetectorRef.markForCheck();
            }
        ));
        return false;
    }

    get isSuperAdmin() {
        return (this.role === 's_admin');
    }

    get isAdmin() {
        return (this.role === 'admin' || this.role === 'm_admin' || this.role === 's_admin');
    }

    get isSender() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isSender;
        }
        return false;
    }

    get sessionData() {
        if (this.stateService.session) {
            return this.stateService.session.sessionData;
        }
        return null;
    }

    get lastAddress() {
        if (this.sessionData && this.sessionData.lastAddress) {
            return this.sessionData.lastAddress;
        }
        return;
    }

    get switchAccountDataId() {
        if (this.sessionData.switchAccountData && this.sessionData.switchAccountData.accountDataId) {
            return this.sessionData.switchAccountData.accountDataId;
        }
        return null;
    }

    get parentAccounts() {
        return (this.sessionData &&
                this.sessionData.structure &&
                this.sessionData.structure.parentAccounts) ?
            this.sessionData.structure.parentAccounts : false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
