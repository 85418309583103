import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    OnDestroy
} from '@angular/core';
import {Router} from '@angular/router';

import {StateService} from '../../../../../core/services/state/state.service';

import * as _ from 'lodash';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';
import {PeopleViewService} from '../../../../../people/services/mobile/people-view.service';
import {take} from 'rxjs/operators';
import {TestAndReportHandlingService} from '../../../../../people/services/test-and-report-handling.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {PersonToDisplay} from 'src/app/people/interfaces';
import {PersonService} from 'src/app/people/services/person.service';

@Component({
    selector: 'app-right-matches-report-analysis',
    templateUrl: './right-matches-report-analysis.component.html',
    styleUrls: ['./right-matches-report-analysis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesReportAnalysisComponent implements OnInit, OnDestroy {

    lang: string;

    @Input() graphWidth;
    @Input() sortResult;
    @Input() mobile = false;

    private subscriptions = new Subscription();

    constructor(private stateService: StateService,
                private router: Router,
                private apiRightMatchService: ApiRightMatchesService,
                private apiPeople: ApiPeopleService,
                private peopleViewService: PeopleViewService,
                private changeDetectorRef: ChangeDetectorRef,
                public rightMatch: RightMatchAnalysisService,
                public testAndReportHandlingService: TestAndReportHandlingService,
                public translate: TranslateService,
                public person: PersonService
                ) {
    }

    ngOnInit() {
        this.stateService.rightMatch.stateChanged$.subscribe(
            (res) => {
                if (res) {
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        if (!this.mobile) {
            this.person.setPersonToDisplayWithId(id, 'navigate');
        } else {
            this.subscriptions.add(this.apiPeople.record([id]).pipe(take(1)).subscribe(
                res => {
                    this.peopleViewService.selectedPerson = res;
                    this.router.navigate(['m/people/view/' + id + '/personality']).then();
                }
            ));
        }
    }

    removeItem(id) {
        let records = JSON.parse(JSON.stringify(this.rightMatch.records));
        let newRecords = _.remove(records, function (i) {
            return i.id !== id;
        });
        let items = [];
        for (let record of newRecords) {
            items.push(record.id);
        }
        if (!items.length) {
            // for the case when we try to remove the last associated record
            items.push('0');
        }
        this.subscriptions.add(this.apiRightMatchService.putRightMatch([this.rightMatch.id], {'records': items}).subscribe(
            (res) => {
                if (res) {
                    this.rightMatch.records = newRecords;
                    this.stateService.rightMatch.stateChanged.next({
                        refreshComponent: true,
                        itemToDisplayId: this.rightMatch.id
                    });
                }
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
