import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ComponentPrimitive} from '../../../../../shared/classes/components/componentPrimitive';
import {StateService} from '../../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UserService} from '../../../../../core/services/user/user.service';
import {PersonService} from '../../../../../people/services/person.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {TestAndReportHandlingService} from '../../../../../people/services/test-and-report-handling.service';
import {PeopleModalHandlingService} from '../../../../../people/services/people-modal-handling.service';
import {JobSatisfactionPcrReportService} from '../../../services/job-satisfaction-pcr-report.service';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';
import {TextHelper} from '../../../../../shared/misc/text.helper';

@Component({
    selector: 'app-job-satisfactions-person-list-display',
    templateUrl: './job-satisfactions-person-list-display.component.html',
    styleUrls: ['./job-satisfactions-person-list-display.component.scss']
})
export class JobSatisfactionsPersonListDisplayComponent extends ComponentPrimitive implements OnChanges {


    @Input() record: any;
    @Input() minimizeAll;
    @Output() openPcrReport = new EventEmitter;
    @Output() remove: EventEmitter<any> = new EventEmitter;
    @Output() openPrbRequest: EventEmitter<any> = new EventEmitter;

    informationVisibility = false;

    text: string;
    personFetched: any;

    constructor(
        protected stateService: StateService,
        public translateService: TranslateService,
        protected router: Router,
        public deviceService: DeviceDetectorService,
        public cd: ChangeDetectorRef,
        public user: UserService,
        private person: PersonService,
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        public testAndReportHandlingService: TestAndReportHandlingService,
        public peopleModalHandlingService: PeopleModalHandlingService,
        public jobSatisfactionPcrReport: JobSatisfactionPcrReportService,
        public apiPeopleService: ApiPeopleService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minimizeAll']) {
            this.informationVisibility = !changes['minimizeAll'].currentValue;
        }
        this.cd.markForCheck();
    }

    cleanedName(name) {
        return name.replace(' ', '');
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    /**
     produceTexts(record) {
        switch (record.jobSatisfactionScore) {
            case 2:
                return 'Cet individu a un potentiel de bien-être élevé. Le comportement qu’il perçoit devoir adopter dans' +
                    ' son environnement de travail correspond bien à ses besoins motivationnels. Ce résultat devrait se ' +
                    'traduire par un niveau de bien-être tout à fait adéquat pour poursuivre facilement ses activités ' +
                    'professionnelles avec un minimum d’ajustements ou d’irritant.';
            case 1:
                return 'Cet individu a un potentiel de bien-être moyen. Le comportement qu’il perçoit devoir adopter ' +
                    'dans son environnement de travail peut correspondre assez bien à ses besoins motivationnels avec ' +
                    'certains nombres d’ajustements ou d’irritants. Ce résultat devrait se traduire par un niveau de ' +
                    'bien-être variable ou parfois incertain. Une rencontre pour discuter des différents enjeux ressentis ' +
                    'par cet individu peut vous permettre d’apporter les changements nécessaires afin de maximiser son ' +
                    'bien-être dans votre organisation.';
            case 0:
                return 'Cet individu a un potentiel de bien-être faible. Le comportement qu’il perçoit devoir adopter ' +
                    'dans son environnement de travail ne correspond pas du tout à ses besoins motivationnels. Cet ' +
                    'individu vit très probablement de grands irritants provoquant de l’insatisfaction. Une attention ' +
                    'particulière est fortement recommandée afin de discuter des changements nécessaires à apporter afin ' +
                    'que cet individu puisse progressivement augmenter son niveau de bien-être dans votre organisation.';
            default:
                return 'Cet individu a un potentiel de bien-être non défini. Pour connaître son niveau de bien-être ' +
                    'potentiel, vous devez lui faire compléter un questionnaire de perception MPO en cliquant sur le ' +
                    'lien « Envoyer un nouveau questionnaire de perception » ci-bas.';
        }
    }*/

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        let action = (this.isMobile) ? 'mobileView' : 'navigate';
        this.person.setPersonToDisplayWithId(id, action);
    }
    redoPcr(record): any {
        let date = (record['prb'][0] && record['prb'][0]['date']) ? record['prb'][0]['date'] : null;
        // console.log(record['lastName'], date);
        if (date !== null) {
            let today = new Date();
            let pcrDate = new Date(date);
            let diffDate = (today.getTime() - pcrDate.getTime()) / 86400000;
            if (diffDate >= 120) {
                return 2;
            } else if (diffDate >= 60) {
                return 1;
            }
            return 0;
        }
        return false;
    }

    openModal(type, evaluationRequest = null) {
        if (!this.isMobile) {
            this.apiPeopleService.record([this.record.id]).subscribe(
                (person) => {
                    this.personFetched = person;
                    this.testAndReportHandlingService.prepareGetRequestSendData(type, evaluationRequest).then(
                        (data: any) => {
                            return this.peopleModalHandlingService.openSendExtraPrbModal({person: this.personFetched});
                        }
                    );
                }
            );
        } else {
            this.apiPeopleService.record([this.record.id]).subscribe(
                (person) => {
                    this.personFetched = person;
                    this.openPrbRequest.emit({type: type, evaluationRequest: evaluationRequest, person: person});
                }
            );

        }


    }

    removeThis() {
        this.remove.emit(this.record.id);
    }

    openJobSatisfactionPcrReport(data) {
        if (data.prbScores !== null) {
            this.jobSatisfactionPcrReport.personData = data;
            this.openPcrReport.emit(true);
        }
    }

    flagPrbToSend(record) {
        return (
            !(record && record['scores']) ||
            !(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ||
            (this.redoPcr(record) === 2)
        );
    }


    protected readonly TextHelper = TextHelper;
}
