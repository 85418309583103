import {Injectable} from '@angular/core';
//import { Location } from '@angular/common';

//import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LocaleService {
    //defaultLocale = 'fr';
    //locale: string;

    constructor(//public translate: TranslateService,
                //private location: Location,
                //private zone: NgZone
    ) {
    }

    /*getDefaultLocale() {
        return this.defaultLocale;
    }

    getLocale() {
        if (!this.locale) {
            // Check in local storage
            const localeStored = localStorage.getItem('locale');
            if (!localeStored) {
                // Get Browser lang
                this.locale = (this.translate.getBrowserLang()) ? this.translate.getBrowserLang() : null;
                this.locale = (this.locale) ? this.locale : navigator.language;
                this.locale = (this.locale) ? this.locale : this.defaultLocale;
                // Push in local storage
                this.setLocale(this.locale);
            } else {
                this.locale = localeStored;
            }

        }
        return this.locale;
    }

    getDefaultTranslate() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang(this.getLocale());
        this.setTranslateLocale(this.getLocale());
    }

    setTranslateLocale(locale: string) {
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use(locale);
    }

    setLocale(locale: string) {
        // Set locale to local storage
        localStorage.setItem('locale', locale);
        this.locale = locale;
    }

    switchLocale(locale: string) {
        // reload with new locale
        this.setLocale(locale);

        this.location.go(this.location.path());
        this.reload();
    }

    /!**
     * Reload page
     *!/
    reload(): any {
        return this.zone.runOutsideAngular(() => {
            location.reload()
        });
    }*/
}
