import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {JobReportService} from 'src/app/jobs/services/job-report.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from 'src/app/shared/misc/text.helper';

@Component({
    selector: 'app-right-matches-report-job-description',
    templateUrl: './right-matches-report-job-description.component.html',
    styleUrls: ['./right-matches-report-job-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesReportJobDescriptionComponent implements OnInit {

    report: any;
    titleSep: string;
    dateFormat: string;

    constructor(
            public translate: TranslateService,
            public rightMatch: RightMatchAnalysisService,
        ) {
    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

}
