import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {JobsRoutingModule} from '../../jobs/jobs-routing.module';

import {ApiJobsService} from '../../jobs/services/api-jobs.service';
import {PeopleRoutingModule} from '../../people/people-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {RightMatchesListSearchFiltersComponent} from './components/web/right-matches-list-search-filters/right-matches-list-search-filters.component';

import {RightMatchesListComponent} from './components/web/right-matches-list/right-matches-list.component';
import {RightMatchesModalComponent} from './components/web/right-matches-modal/right-matches-modal.component';
import {RightMatchesPersonModalComponent} from './components/web/right-matches-person-modal/right-matches-person-modal.component';
import {RightMatchesReportAnalysisComponent} from './components/web/right-matches-report-analysis/right-matches-report-analysis.component';
import {RightMatchesReportJobDescriptionComponent} from './components/web/right-matches-report-job-description/right-matches-report-job-description.component';
import {RightMatchesReportComponent} from './components/web/right-matches-report/right-matches-report.component';
import {RightMatchesSidebarComponent} from './components/web/right-matches-sidebar/right-matches-sidebar.component';
import {RightMatchAnalysisService} from './services/right-match-analysis.service';
import {ApiRightMatchesService} from './services/api-right-matches.service';
import {RightMatchesSelectedItemsComponent} from './components/web/right-matches-selected-items/right-matches-selected-items.component';
import { NgxLoadingModule } from 'ngx-loading';

import {TranslateModule} from '@ngx-translate/core';
import { RightMatchesPdfModalComponent } from './components/web/right-matches-pdf-modal/right-matches-pdf-modal.component';
import { SidebarModule } from 'ng-sidebar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {PeopleModule} from '../../people/people.module';
import { MobileRightMatchesListComponent } from './components/mobile/mobile-right-matches-list/mobile-right-matches-list.component';
import { MobileRightMatchesReportComponent } from './components/mobile/mobile-right-matches-report/mobile-right-matches-report.component';
import { MobileRightMatchesPersonAddingComponent } from './components/mobile/mobile-right-matches-person-adding/mobile-right-matches-person-adding.component';
import { MobileRightMatchesEditComponent } from './components/mobile/mobile-right-matches-edit/mobile-right-matches-edit.component';
import { MobileRightMatchesPdfComponent } from './components/mobile/mobile-right-matches-pdf/mobile-right-matches-pdf.component';
import {GraphboardsModule} from '../graphboards/graphboards.module';
import {AnalysisSharedModule} from '../shared/analysis-shared.module';
import { RightMatchesPersonCardComponent } from './components/commonClasses/right-matches-person-card/right-matches-person-card.component';
import { RightMatchesReportJobCompetenciesComponent } from './components/web/right-matches-report-job-competencies/right-matches-report-job-competencies.component';
import { RightMatchesReportJobIacComponent } from './components/web/right-matches-report-job-iac/right-matches-report-job-iac.component';
import { RightMatchesPersonSubCardCaiComponent } from './components/commonClasses/right-matches-person-card/sub-card/app-right-matches-person-sub-card-cai/right-matches-person-sub-card-cai.component';
import { RightMatchesPersonSubCardTalentsComponent } from './components/commonClasses/right-matches-person-card/sub-card/app-right-matches-person-sub-card-talents/right-matches-person-sub-card-talents.component';
import { RightMatchesPersonSubCardPersonalityComponent } from './components/commonClasses/right-matches-person-card/sub-card/app-right-matches-person-sub-card-personality/right-matches-person-sub-card-personality.component';
import { RightMatchesReportsFiltersModalComponent } from './components/web/right-matches-reports-filters-modal/right-matches-reports-filters-modal.component';
import { RightMatchesReportJobPersonalityComponent } from './components/web/right-matches-report-job-personality/right-matches-report-job-personality.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        ModalModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgMultiSelectDropDownModule,
        SharedModule,
        JobsRoutingModule,
        PeopleRoutingModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxLoadingModule,
        TranslateModule,
        SidebarModule.forRoot(),
        MatGridListModule,
        MatButtonModule,
        MatTabsModule,
        PeopleModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, GraphboardsModule, AnalysisSharedModule, MatSelectModule
    ],
    declarations: [
        RightMatchesListComponent, RightMatchesListSearchFiltersComponent, RightMatchesSidebarComponent, RightMatchesReportComponent,
        RightMatchesModalComponent, RightMatchesPersonModalComponent, RightMatchesReportJobDescriptionComponent,
        RightMatchesReportAnalysisComponent,
        RightMatchesSelectedItemsComponent,
        RightMatchesPdfModalComponent,
        MobileRightMatchesListComponent,
        MobileRightMatchesReportComponent,
        MobileRightMatchesPersonAddingComponent,
        MobileRightMatchesEditComponent,
        MobileRightMatchesPdfComponent,
        RightMatchesPersonCardComponent,
        RightMatchesReportJobCompetenciesComponent,
        RightMatchesReportJobIacComponent,
        RightMatchesPersonSubCardCaiComponent,
        RightMatchesPersonSubCardTalentsComponent,
        RightMatchesPersonSubCardPersonalityComponent,
        RightMatchesReportsFiltersModalComponent,
        RightMatchesReportJobPersonalityComponent, RightMatchesReportJobPersonalityComponent
    ],
    exports: [
        RightMatchesListComponent,
        RightMatchesListSearchFiltersComponent,
        RightMatchesSidebarComponent,
        MobileRightMatchesPersonAddingComponent
    ],
    providers: [
        RightMatchAnalysisService,
        ApiRightMatchesService,
        ApiJobsService
    ]
})
export class RightMatchesModule {
}
