export class EvaluationRequestNext {
    emailTo: string;
    subject: string;
    message: string;

    constructor(options: {
            emailTo?: string,
            subject?: string,
            message?: string,
        } = {})
    {
        this.emailTo = options.emailTo;
        this.subject = options.subject;
        this.message = options.message;
    }
}
