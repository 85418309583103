// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile {
  padding-top: 70px;
  height: 100vh;
}

.prb:last-of-type {
  margin-bottom: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/jobSatisfactions/components/commonClasses/job-satisfaction-analysis-popup/job-satisfaction-analysis-popup.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;AACJ;;AAEA;EACI,+BAAA;AACJ","sourcesContent":[".mobile {\n    padding-top: 70px;\n    height: 100vh;\n}\n\n.prb:last-of-type {\n    margin-bottom: 100px!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
