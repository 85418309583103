import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {JobsModalHandlingService} from '../../../services/jobs-modal-handling.service';
import {JobService} from '../../../services/job.service';
import {TextHelper} from '../../../../shared/misc/text.helper';

@Component({
    selector: 'app-jobs-report',
    templateUrl: './jobs-report.component.html',
    styleUrls: ['./jobs-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsReportComponent implements OnInit, AfterViewInit, OnDestroy {

    onDestroy$: Subject<null> = new Subject();

    reportWidth: string;
    sideWidth: string;
    display = 'report';

    sessionData: any;

    titleSep: string;
    lastPrbsDatedateFormat: string;
    requiredTest: string;
    tasksList: boolean;
    small = true;
    dateFormat;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public jobsModalHandlingService: JobsModalHandlingService,
        public jobService: JobService,
        private apiJobs: ApiJobsService,
        public stateService: StateService,
        public translate: TranslateService
        ) {
    }

    switchMain(type, test) {
        // Check if this evaluation type exists
        if (type === 'this option is not activated' && !this.stateService.session.sessionData.structure.testAccessPermissions[test] && type !== 'description') {
            this.requiredTest = test;
            this.jobsModalHandlingService.showModalUnaccessibleTest(test, 'report');
            return;
        }
        if (this.state.report.display === type && this.state.report.reportType === test) {
            return;
        }
        let reportUrl = '/jobs/report/' + this.jobService.id + '/' + test;
        // noinspection OverlyComplexBooleanExpressionJS
        if (
            (test === 'ra' && this.jobService.raScore) ||
            (test === 'talents' && (this.jobService.competences && this.jobService.competences.length > 0)) ||
            (test === 'personality' && (this.jobService.score && this.jobService.score.length > 0)) ||
            (test === 'personality' && type === 'description')
        ) {
            this.router.navigate([reportUrl]).then(() => {
                this.state.report.display = type;
                this.state.report.reportType = test;
            });
        } else {
            if (type === 'description') {
                return;
            }
            if (test === 'personality') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'personality'}, 'report');
            } else if (test === 'talents') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'talents'}, 'report');

            } else if (test === 'ra') {
                this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'iac'}, 'report');
            }
            this.router.navigate([reportUrl]).then(() => {
                this.state.report.display = type;
                this.state.report.reportType = test;
            });
        }
    }

    ngOnInit() {
        let id = this.route.snapshot.url[2].path;
        if (id && id !== this.jobService.id) {
            this.jobService.setItemToDisplayWithId(id);
        }
        this.sessionData = this.stateService.session.sessionData;
        this.small = (window.innerWidth < 1235);
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);

        let reportType = this.route.snapshot.url[3].path;
        this.jobService.checkReportDisplay(reportType);
        this.tasksList = this.jobService.checkTask();

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.tasksList = this.jobService.checkTask();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );

        // Change Route event
        this.stateService.session.actualModule = 'jobs';

        // Set list width
        this.computeListWidth(window);

        let divs = Array.from(document.querySelectorAll('body > div:not(#menu_wrapper)')) as unknown as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style.overflowY = 'hidden';
        }
        document.getElementById('scrollBody').style.overflowY = 'scroll';
    }

    get raPermission() {
        return (this.stateService.session &&
            this.sessionData &&
            this.sessionData.userData &&
            this.sessionData.userData.permissions) ? this.sessionData.userData.permissions.ra : false;
    }

    ngAfterViewInit(): void {
        if (
            this.state &&
            this.state.state &&
            this.state.state.jobToDisplayId &&
            this.state.state.reportBack &&
            this.state.state.reportBack.wasFor &&
            this.state.state.reportBack.wasFor === 'mod'
        ) {
            let checkJob = this.apiJobs.job([this.state.state.jobToDisplayId]).subscribe(
                res => {
                    this.jobService.setItemToDisplayWithId(res.id);
                    this.jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'jobDescription'}, 'report');
                    this.state.state.reportBack.wasFor = undefined;
                    checkJob.unsubscribe();
                });
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onResize(event) {
        // re-Set list width
        this.computeListWidth(event.target);
        this.small = (event.target.innerWidth < 1235);
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
        // @ilya 2020-10-16 - fix the side width to 400px
        this.sideWidth = '400px';
        this.reportWidth = (innerWidth - 550) + 'px';
        return;
    }

    comparePriority(a, b) {
        return a.priority - b.priority;
    }

    get state(): any {
        return this.stateService.jobs;
    }

    get jobTasks() {
        if (this.jobService.tasks) {
            return this.jobService.tasks.sort(this.comparePriority);
        }
        return;
    }

}
