import {ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-idw-graph',
    templateUrl: './idw-graph.component.html',
    styleUrls: ['./idw-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwGraphComponent implements OnChanges {

    @ViewChild('IdwGraph', {static: true}) private chartContainer: ElementRef;
    @Input() lang = 'fr';
    @Input() quad: string = undefined;
    @Input() ad: string;
    @Input() width: any;
    @Input() graphType: string;
    @Input() mobile = false;
    @Input() request = false;
    @Input() requestArr: any;

    initialQuad: string;

    constructor(
        private translateService: TranslateService,
        public stateService: StateService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.request) {
            this.request = false;
        }

        if (!this.initialQuad) {
            this.initialQuad = this.quad;
        }
        this.quad = this.quad ? this.quad.charAt(0).toUpperCase() + this.quad.charAt(1) : null;
        // validate quad before continuing.
        if (this.quad && this.isValid(this.quad)) {
            this.graphType = 'active';
        } else {
            this.graphType = 'inactive';
        }
        this.createIdwGraph();
    }

    isValid(quad) {
        const validQuads = [
            'V1', 'V2', 'V3', 'V4',
            'J1', 'J2', 'J3', 'J4',
            'B1', 'B2', 'B3', 'B4',
            'R1', 'R2', 'R3', 'R4'
        ];
        return (validQuads.indexOf(quad) > -1);
    }

    createIdwGraph() {
        d3.select('#ditMainGraph').remove();

        // need quad, ad, lang
        let element = this.chartContainer.nativeElement;
        let tWidth = 441;
        if (this.width) {
            tWidth = this.width.substring(0, this.width.length - 2);
        }
        let outerWidth = (tWidth && tWidth < 440) ? tWidth - 40 : 400;
        let rat = 760 / 695;

        let svg = d3.select(element)
            .append('svg')
            .attr('id', 'ditMainGraph')
            .attr('viewBox', '-40 -40 695 760');

        if (this.mobile) {
            svg.attr('height', '100%')
                .attr('width', '100%');
        } else {
            if (outerWidth) {
                svg.attr('height', outerWidth * rat + 'px')
                    .attr('width', outerWidth + 'px');
            }
        }

        svg.style('background-color', 'rgb(206,206,206)');

        svg.append('rect').attr('x', -30).attr('y', -30).attr('width', 675).attr('height', 740).style('fill', 'white');

        // Color definition:

        const colors = {
            green: 'rgb(119,198,175)',
            red: 'rgb(240,123,126)',
            blue: 'rgb(119,156,197)',
            yellow: 'rgb(238,207,123)',
            centralCross: 'rgb(128,128,128)',
            greyLine: 'rgb(102,102,102)',
            adaptabilityBar: 'rgb(191,191,191)',
            adaptabilityText: 'rgb(128, 128, 128)'
        };

        let inactiveColor = 'rgb(217, 217, 217)';

        if (this.graphType === 'inactive') {
            for (let color in colors) {
                if (color) {
                    colors[color] = inactiveColor;
                }
            }
        }

        // Quadrant

        const quadrants = [
            {x: 0, y: 0, color: 'green'},
            {x: 320, y: 0, color: 'red'},
            {x: 0, y: 320, color: 'blue'},
            {x: 320, y: 320, color: 'yellow'},
        ];

        quadrants.forEach(
            quad => {
                // console.log(colors[quad.color]);
                svg.append('rect')
                    .style('fill', colors[quad.color])
                    .attr('x', (quad.x + 20))
                    .attr('y', (quad.y + 20)).attr('width', 260)
                    .attr('height', 260);

                svg.append('rect')
                    .style('fill', 'white')
                    .attr('x', (quad.x + 20) + 10)
                    .attr('y', (quad.y + 20) + 10).attr('width', 260 - 20)
                    .attr('height', 260 - 20);

                svg.append('line')
                    .attr('class', 'inQuadrantLines').attr('stroke-width', 1)
                    .attr('stroke', colors.greyLine)
                    .attr('x1', (quad.x + 20) + 260 / 2)
                    .attr('y1', (quad.y + 20) + 10) // + 10 to draw only on the white part
                    .attr('x2', (quad.x + 20) + 260 / 2)
                    .attr('y2', ((quad.y + 20) + 10) + (260 - 2 * 10)); // idem

                svg.append('line')
                    .attr('class', 'inQuadrantLines').attr('stroke-width', 1)
                    .attr('stroke', colors.greyLine)
                    .attr('x1', (quad.x + 20) + 10) // + 10 to draw only on the white part
                    .attr('y1', (quad.y + 20) + 260 / 2)
                    .attr('x2', ((quad.x + 20) + 10) + (260 - 2 * 10)) // idem
                    .attr('y2', (quad.y + 20) + 260 / 2);

            }
        );

        // Result's Position

        if (this.graphType !== 'inactive') {
            const results = {
                V1: {x: 30, y: 30, color: 'green'},
                V2: {x: 150, y: 30, color: 'green'},
                V3: {x: 30, y: 150, color: 'green'},
                V4: {x: 150, y: 150, color: 'green'},

                R1: {x: 470, y: 30, color: 'red'},
                R2: {x: 350, y: 30, color: 'red'},
                R3: {x: 470, y: 150, color: 'red'},
                R4: {x: 350, y: 150, color: 'red'},

                B1: {x: 30, y: 470, color: 'blue'},
                B2: {x: 150, y: 470, color: 'blue'},
                B3: {x: 30, y: 350, color: 'blue'},
                B4: {x: 150, y: 350, color: 'blue'},

                J1: {x: 470, y: 470, color: 'yellow'},
                J2: {x: 350, y: 470, color: 'yellow'},
                J3: {x: 470, y: 350, color: 'yellow'},
                J4: {x: 350, y: 350, color: 'yellow'}
            };
            if (results[this.quad] && results[this.quad].color) {
                svg.append('rect').style(
                    'fill',
                    colors[results[this.quad].color]
                )
                    .attr('x', results[this.quad].x)
                    .attr('y', results[this.quad].y)
                    .attr('width', 120)
                    .attr('height', 120);
            }
        }

        // Text for quandrants

        const headTitles = {
            'fr': ['ANALYTIQUE', 'DIRECTIF', 'COOPÉRATIF', 'EXPRESSIF'],
            'en': ['ANALYTICAL', 'AUTHORITATIVE', 'COOPERATIVE', 'EXPRESSIVE']
        };

        const squareTitles = [
            {x: 147.5, y: 14, text: headTitles[this.lang][0], color: 'green'},
            {x: 467.5, y: 14, text: headTitles[this.lang][1], color: 'red'},
            {x: 147.5, y: 625, text: headTitles[this.lang][2], color: 'blue'},
            {x: 467.5, y: 625, text: headTitles[this.lang][3], color: 'yellow'}
        ];

        squareTitles.forEach(title => {
            svg.append('text')
                .attr('x', title.x)
                .attr('y', title.y)
                .text(title.text)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'sans-serif')
                .attr('font-weigth', 'bolder')
                .style('fill', colors[title.color]).attr('font-size', 22);
        });

        // Central cross

        const centralCrossLines = (this.lang === 'fr') ? [
            {x1: 125, y1: 310, x2: 470, y2: 310},
            {x1: 125, y1: 310, x2: 135, y2: 300},
            {x1: 125, y1: 310, x2: 135, y2: 320},
            {x1: 470, y1: 310, x2: 460, y2: 300},
            {x1: 470, y1: 310, x2: 460, y2: 320},
            {x1: 310, y1: 190, x2: 310, y2: 475},
            {x1: 310, y1: 190, x2: 300, y2: 200},
            {x1: 310, y1: 190, x2: 320, y2: 200},
            {x1: 310, y1: 475, x2: 320, y2: 465},
            {x1: 310, y1: 475, x2: 300, y2: 465}
        ] : [
            {x1: 145, y1: 310, x2: 455, y2: 310},
            {x1: 145, y1: 310, x2: 155, y2: 300},
            {x1: 145, y1: 310, x2: 155, y2: 320},
            {x1: 455, y1: 310, x2: 445, y2: 300},
            {x1: 455, y1: 310, x2: 445, y2: 320},
            {x1: 310, y1: 190, x2: 310, y2: 475},
            {x1: 310, y1: 190, x2: 300, y2: 200},
            {x1: 310, y1: 190, x2: 320, y2: 200},
            {x1: 310, y1: 475, x2: 320, y2: 465},
            {x1: 310, y1: 475, x2: 300, y2: 465}
        ];

        centralCrossLines.forEach(line => {
            svg.append('line')
                .attr('class', 'centralCross')
                .attr('x1', line.x1)
                .attr('y1', line.y1)
                .attr('x2', line.x2)
                .attr('y2', line.y2)
                .attr('stroke', colors.centralCross)
                .attr('stroke-linecap', 'round').attr('stroke-width', 3);
        });

        // Central cross texts

        const crossTexts = {
            'fr': ['Prudence', 'Affirmation', 'Contrôle de soi', 'Spontanéité'],
            'en': ['Cautiousness', 'Assertiveness', 'Self control', 'Spontaneity']
        };

        const axisTitles = [
            {x: 60, y: 314, text: crossTexts[this.lang][0], rotation: 0},
            {x: 547, y: 314, text: crossTexts[this.lang][1], rotation: 0},
            {x: 306, y: 86.5, text: crossTexts[this.lang][2], rotation: 90},
            {x: 306, y: 558, text: crossTexts[this.lang][3], rotation: 90}
        ];

        axisTitles.forEach(title => {
            svg.append('text')
                .attr('x', title.x)
                .attr('y', title.y)
                .text(title.text)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'serif')
                .attr('font-style', 'italic').attr('font-size', 28.5)
                .attr('transform', 'rotate(' + title.rotation + ', ' + title.x + ', ' + title.y + ')')
                .style('fill', colors.centralCross);
        });

        // Adaptability bar

        const adaptText = (this.lang === 'fr') ? 'Adaptabilité' : 'Adaptability';

        svg.append('line')
            .attr('x1', 40)
            .attr('y1', 650)
            .attr('x2', 580)
            .attr('y2', 650).attr('stroke', colors.adaptabilityBar).attr('stroke-width', 15);

        svg.append('text')
            .attr('x', 310).attr('y', 685)
            .text(adaptText)
            .attr('text-anchor', 'middle')
            .attr('font-family', 'serif')
            .attr('font-style', 'italic').attr('font-size', 26.5).style('fill', colors.adaptabilityText);

        // Adaptability score
        if (this.graphType !== 'inactive') {
            const positions = [
                {lb: 40, ub: 220},
                {lb: 220, ub: 400},
                {lb: 400, ub: 580}
            ];

            let res = {lb: 0, ub: 0};

            // low, mid, high
            if (this.ad === 'low') {
                res = positions[0];
            } else if (this.ad === 'mid') {
                res = positions[1];
            } else {
                res = positions[2];
            }

            let adColor = '';

            switch (this.quad.charAt(0)) {
                case 'J':
                    adColor = 'yellow';
                    break;
                case 'B':
                    adColor = 'blue';
                    break;
                case 'R':
                    adColor = 'red';
                    break;
                case 'V':
                    adColor = 'green';
                    break;
            }

            svg.append('line')
                .attr('x1', res.lb)
                .attr('y1', 650)
                .attr('x2', res.ub)
                .attr('y2', 650)
                .attr('stroke', colors[adColor])
                .attr('stroke-width', 15);
        }

        let notCompleted = this.translateService.instant('people.commons.notCompleted');
        if ((this.requestArr && !this.requestArr.includes('dit')) || !this.stateService.hasCommunication) {
            notCompleted = this.translateService.instant('commons.none');
        }
        if (this.initialQuad === 'INV') {
            notCompleted = this.translateService.instant('commons.invalid');
        }

        if (this.graphType === 'inactive') {
            svg.append('text')
                .attr('x', 317.5)
                .attr('y', 325)
                .text(notCompleted)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'sans-serif')
                .attr('font-size', 50)
                .attr('fill', 'rgb(86, 86, 86)');
        }
    }
}
