import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-people-card-no-report',
    templateUrl: './people-card-no-report.component.html',
    styleUrls: ['./people-card-no-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleCardNoReportComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('NoReportGraph', {static: true}) private chartContainer: ElementRef;

    @Input() id: string;
    @Input() cardId: any;
    @Input() type: string;
    @Input() which = 'none';
    @Input() card = false;
    @Input() loc: string;
    @Input() hasMpoReport = false;
    @Input() anotherTitle: any; // TODO: is this still useful??
    @Input() ditIsInvalid = false;

    textHead: string;
    textSecLine: string;
    currentTitle;

    private subscriptions = new Subscription();

    constructor(
        private translate: TranslateService
    ) {

    }

    colorMod(val) {
        let newVal = val + 50;
        return (newVal > 255) ? 255 : newVal;
    }

    ngOnInit() {
        // To make sure, we generate an id.
        if (this.id === null || this.id === '' || this.id === undefined) {
            this.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 42);
        }
        this.currentTitle = this.anotherTitle;
        this.drawInactive();
    }

    ngOnChanges(changes) {
        if (changes.anotherTitle &&  changes.anotherTitle.currentValue) {
            this.currentTitle = changes.anotherTitle.currentValue;
            this.drawInactive();
        }
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    drawInactive() {
        let graphId = 'inactive_' + this.type.replace('.', '_') + '_' + this.which;
        if (this.card) {
            graphId = graphId + '_' + this.cardId;
        }
        d3.select( '#' + graphId).remove();

        // noinspection OverlyComplexBooleanExpressionJS
        if (
            this.type !== 'questionnaire' &&
            this.type !== 'activate.1' &&
            this.type !== 'activate.2' &&
            this.type !== 'activate.4' &&
            this.type !== 'activate.8'
        ) {
            throw 'Invalid @Input() type for people-card-no-report';
        }

        const colors = {
            gray: '#E2E2E2',
            darkGray: 'rgb(85, 85, 85)',
            paleGreen: 'rgb(' + this.colorMod(119) + ',' + this.colorMod(198) + ',' + this.colorMod(175) + ')',
            paleRed: 'rgb(' + this.colorMod(240) + ',' + this.colorMod(123) + ',' + this.colorMod(126) + ')',
            paleBlue: 'rgb(' + this.colorMod(119) + ',' + this.colorMod(156) + ',' + this.colorMod(197) + ')',
            paleYellow: 'rgb(' + this.colorMod(238) + ',' + this.colorMod(207) + ',' + this.colorMod(123) + ')',
            paleGray: 'rgb(215, 215, 215)'
        };

        let element = this.chartContainer.nativeElement;

        let side = 60;
        if (this.which === 'personality') {
            side = 240;
        } else if (this.which === 'communication') {
            side = 40;
        }


        let svg;
        if (this.which === 'talents' || this.which === 'talents_jobs') {
            svg = d3.select(element)
                .append('svg')
                .attr('id', graphId)
                .attr('width', 60)
                .attr('height', 60)
                .attr('class', 'radial')
                .append('g')
                .attr('transform', 'translate(30, 30)')
                .attr('preserveAspectRatio', 'xMidYMid meet');
        } else {
            svg = d3.select(element)
                .append('svg')
                .attr('id', graphId)
                .attr('width', 60)
                .attr('height', 60)
                .attr('viewBox', '0 0 ' + side + ' ' + side)
                .attr('preserveAspectRatio', 'xMidYMid meet');
        }

        if (this.which === 'personality') {
            svg.append('circle').attr('cx', 120).attr('cy', 120).attr('r', 110).style('fill', 'white').style('stroke-width', 7).style('stroke', 'rgb(226,226,226)');
        } else if (this.which === 'communication') {
            svg.append('circle').attr('cx', 20).attr('cy', 20).attr('r', 18).style('fill', 'white').style('stroke-width', 1).style('stroke', colors.gray);
        } else if (this.which === 'talents' || this.which === 'talents_jobs') {
            svg.append('circle').attr('cx', 0).attr('cy', 0).attr('r', 27).style('fill', 'white').style('stroke-width', 1.5).style('stroke', colors.gray);
        } else {
            svg.append('circle').attr('cx', 30).attr('cy', 30).attr('r', 27).style('fill', 'white').style('stroke-width', 1.5).style('stroke', colors.gray);
        }

        let typeArr = this.type.split('.');
        if (typeArr[0] === 'activate') {
            let optFr = (this.which === 'iac' || (!this.hasMpoReport && this.which !== 'iac')) ? 'Envoyer' : 'Activer';
            let optEn = (this.which === 'iac' || (!this.hasMpoReport && this.which !== 'iac')) ?  'Send' : 'Activate';
            if (this.loc === 'jobs') {
                optFr = 'Ajouter';
                optEn = 'Add';
            }
            if (this.currentTitle) {
                optFr = this.currentTitle['fr'];
                optEn = this.currentTitle['en'];
            }
            this.textHead = (this.translate.currentLang === 'fr') ? optFr : optEn;
            if (this.currentTitle && this.currentTitle['sec' + this.translate.currentLang]) {
                this.textSecLine = this.currentTitle['sec' + this.translate.currentLang];
            }

            switch (this.which) {
                case 'mpo':
                case 'personality':
                    this.displayInactiveMpo(svg);
                    break;
                case 'satellite':
                    this.displayInactiveSatellite(svg);
                    break;
                case'communication':
                case 'idw':
                    this.displayInactiveIdw(svg);
                    break;
                case 'talents':
                case 'talents_jobs':
                    this.displayInactiveTalents(svg);
                    break;
                case 'iac':
                    this.displayInactiveIac(svg);
                    break;
            }

            this.appendOpaqueCircle(svg);

            let textData = {
                x: 30,
                y: (this.currentTitle && this.currentTitle['y']) ? this.currentTitle['y'] : 34,
                fontSize: 11
            };

            if (this.which === 'personality') {
                textData = {
                    x: 120,
                    y: 138.25,
                    fontSize: 45
                };
            } else if (this.which === 'communication') {
                textData = {
                    x: 20,
                    y: 23,
                    fontSize: 7
                };
            } else if (this.which === 'talents' || this.which === 'talents_jobs') {
                textData = {
                    x: 0,
                    y: 4,
                    fontSize: 11
                };
            }
            let secTextY = textData.y + 12;
            if (this.which !== 'none') {
                // @ilya - 2021-04-08 - The line below is unnecessary. It's duplicated in the appendText function
                // svg.append('text').attr('x', textData.x).attr('y', textData.y).text(this.textHead).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('font-weigth', 'bold').attr('fill', 'white');
                if (this.textSecLine) {
                    svg.append('text').attr('x', textData.x).attr('y', secTextY).text(this.textSecLine).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('font-weigth', 'bold').attr('fill', 'white');
                }
            } else {
                svg.append('text').attr('x', textData.x).attr('y', textData.y).text(this.textHead).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('fill', colors.darkGray);
                if (this.textSecLine) {
                    svg.append('text').attr('x', textData.x).attr('y', secTextY).text(this.textSecLine).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('fill', colors.darkGray);
                }
            }

            let id = (this.cardId) ? this.cardId : this.id;
            this.appendText(svg, textData, colors, id);
            this.translate.onLangChange.subscribe(
            () => {
                this.appendText(svg, textData, colors, id);
            });
        }
    }

    displayInactiveMpo(svg) {
        const scores = {
            A: 3,
            E: 5,
            P: 7,
            S: 4
        };

        function uniq(a) {
            return Array.from(new Set(a));
        }

        let scoreArr = [scores['A'], scores['E'], scores['P'], scores['S']];
        let sorted = uniq(scoreArr.sort(function (a, b) {
            return a - b;
        }));

        const traits = ['A', 'E', 'P', 'S'];

        let depth = sorted.length - 1;

        sorted.forEach((score, index) => {
            traits.forEach(trait => {
                if (scores[trait] === score) {
                    scores[trait] = index;
                }
            });
        });

        const grids = [
            [120],
            [90, 150],
            [75, 120, 165],
            [66, 102, 138, 174]
        ];

        const height = {
            A: 66,
            E: 102,
            P: 138,
            S: 174
        };

        svg.append('line').attr('x1', grids[depth][scores.A]).attr('y1', height.A).attr('x2', grids[depth][scores.E]).attr('y2', height.E).attr('stroke-width', 2).attr('stroke', 'black');

        svg.append('line').attr('x1', grids[depth][scores.E]).attr('y1', height.E).attr('x2', grids[depth][scores.P]).attr('y2', height.P).attr('stroke-width', 2).attr('stroke', 'black');

        svg.append('line').attr('x1', grids[depth][scores.P]).attr('y1', height.P).attr('x2', grids[depth][scores.S]).attr('y2', height.S).attr('stroke-width', 2).attr('stroke', 'black');

        traits.forEach(trait => {
            // let fill = '#FFF';
            let fill = 'rgb(233,118,6)';
            svg.append('circle').attr('cx', grids[depth][scores[trait]]).attr('cy', height[trait]).attr('r', 14).attr('stroke-width', 2).attr('stroke', '#232323').style('fill', fill);
        });

        return svg;
    }

    displayInactiveSatellite(svg) {
        let endAngle = 2 * Math.PI * 3 / 5;
        let arcBG = d3.arc().innerRadius(19).outerRadius(23).startAngle(0).endAngle(2 * Math.PI);
        svg.append('path').attr('transform', 'translate(30,30)').attr('class', 'arc').attr('d', arcBG).attr('fill', '#CCC');

        svg.append('circle').attr('cx', 30).attr('cy', 30).attr('r', 27).style('fill', 'white').style('stroke-width', 1.5).style('stroke', '#E2E2E2');

        let arc = d3.arc().innerRadius(21).outerRadius(27).startAngle(0).endAngle(-endAngle);
        svg.append('path').attr('transform', 'translate(30,30)').attr('class', 'arc').attr('d', arc).attr('fill', '#CCC');

        return svg;
    }

    displayInactiveIdw(svg) {

        const startAngleConfig = 3 * (2 * Math.PI / 4);
        const endAngleConfig = 2 * Math.PI;

        let arcBG = d3.arc().innerRadius(12).outerRadius(18)
            .startAngle(startAngleConfig)
            .endAngle(endAngleConfig);
        //
        svg.append('path').attr('transform', 'translate(20,20)').attr('class', 'arc').attr('d', arcBG).attr('fill', '#E2E2E2');

        return svg;
    }

    displayInactiveTalents(svg) {
        const mainScores = (this.which === 'talents_jobs') ? [9, 9, 9, 9, 9, 9] : [2, 4, 6, 7, 9, 1];
        const barHeight = 9;

        svg.style('background-color', '1px solid blue');

        // Background circle
        svg.append('circle')
            .attr('r', barHeight + 17.5)
            .classed('outer', true)
            .style('fill', 'white')
            .style('stroke', '#ddd')
            .style('stroke-width', '1px');

        let extent = [0, 10];
        let barScale = d3.scaleLinear()
            .domain(extent)
            .range([0, barHeight]);


        // Outer circles

        let a = 0;
        let b = (2 * Math.PI) / 6;
        let dist = (b - a); // (angle opening)

        // Create arcs
        let arc = d3.arc()
            .startAngle(function (d, i) {
                return i * dist;
            })
            .endAngle(function (d, i) {
                return (i + 1) * dist;
            })
            .innerRadius(6);


        // Extract this scores

        let scores = [];

        for (let datum of mainScores) {
            scores.push({
                value: datum,
                outerRadius: null
            });
        }


        // Render colored arcs
        svg.selectAll('path')
            .data(scores)
            .enter().append('path')
            .each(function (d) {
                d.outerRadius = barScale(+d.value + 3) * 2.3;
            })
            .style('fill', (d) => {
                if (this.type === 'job' || this.which === 'talents_jobs') {
                    return 'rgb(227, 94, 36)';
                } else {
                    let val = d.value;
                    if (val <= 3) {
                        return '#779cc5'; // '#e2bb95';//
                    } else if (val <= 6) {
                        return '#efce7c'; // '#e59950';//
                    } else {
                        return '#78c7af'; // '#ea7300'; //rgb(227, 94, 36)//
                    }
                }
            })
            .style('stroke', 'white')
            .style('stroke-width', '1px')
            .attr('d', arc);

        return svg;
    }

    displayInactiveIac(svg) {
        svg.append('svg:image')
            .attr('x', 2.5)
            .attr('y', 1.5)
            .attr('width', 55)
            .attr('height', 55)
            .attr('xlink:href', 'assets/graphicalElements/bellCurveCircle.png');
        return svg;
    }

    appendOpaqueCircle(svg) {
        let circleChars = {
            c: 30,
            r: 27
        };
        if (this.which === 'personality') {
            circleChars = {
                c: 120,
                r: 110
            };
        } else if (this.which === 'communication') {
            circleChars = {
                c: 20,
                r: 18
            };
        } else if (this.which === 'talents' || this.which === 'talents_jobs') {
            circleChars = {
                c: 0,
                r: 27
            };
        }


        svg.append('circle').attr('cx', circleChars.c).attr('cy', circleChars.c).attr('r', circleChars.r).style('fill', 'white')
            .style('fill-opacity', '.5');

        svg.append('circle').attr('cx', circleChars.c).attr('cy', circleChars.c).attr('r', circleChars.r).style('fill', 'black')
            .style('fill-opacity', '.3');

    }

    appendText(svg, textData, colors, id) {
        const currentId = 'textNodeNoReport_' + this.which + '_' + id;
        d3.selectAll('#' + currentId).remove();
        let optFr = (this.which === 'iac' || (!this.hasMpoReport && this.which !== 'iac')) ? 'Envoyer' : 'Activer';
        let optEn = (this.which === 'iac' || (!this.hasMpoReport && this.which !== 'iac')) ?  'Send' : 'Activate';

        if (this.loc === 'jobs') {
            optFr = 'Ajouter';
            optEn = 'Add';
        }

        if (this.currentTitle) {
            optFr = this.currentTitle['fr'];
            optEn = this.currentTitle['en'];
        }
        this.textHead = (this.translate.currentLang === 'fr') ? optFr : optEn;
        if (this.which !== 'none') {
            svg.append('text').attr('id', currentId).attr('x', textData.x).attr('y', textData.y).text(this.textHead).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('font-weigth', 'bold').attr('fill', 'white');
        } else {
            svg.append('text').attr('id', currentId).attr('x', textData.x).attr('y', textData.y).text(this.textHead).attr('text-anchor', 'middle').attr('font-family', 'sans-serif').attr('font-size', textData.fontSize).attr('fill', colors.darkGray);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
