export class SearchHelper {

    public static selectionDone(state, except = null) {
        let selDone = false;
        if (state && state.listSearchFilters) {
            for (let f in state.listSearchFilters) {
                if (f && state.listSearchFilters.hasOwnProperty(f) && except !== f) {
                    // check for a boolean filter
                    // excepting 'status' and 'isEnabled' of the admin section
                    if (typeof state.listSearchFilters[f] === 'boolean' &&
                        state.listSearchFilters[f] && f !== 'status' && f !== 'isEnabled') {
                            selDone = true;
                    }
                    if (f === 'isEnabled' && state.listSearchFilters[f] !== null) {
                        selDone = true;
                    }
                    // check for a string filter/search
                    if (typeof state.listSearchFilters[f] === 'string' &&
                        state.listSearchFilters[f] !== '' &&
                        state.listSearchFilters[f] !== null) {
                            selDone = true;
                    }
                    // check for a number filter/search
                    if (typeof state.listSearchFilters[f] === 'number' &&
                        (state.listSearchFilters[f] || state.listSearchFilters[f] === 0)) {
                            selDone = true;
                    }
                    // check for an object filter
                    if (typeof state.listSearchFilters[f] === 'object' &&
                        !Array.isArray(state.listSearchFilters[f])) {
                        for (let ff in state.listSearchFilters[f]) {
                            if (ff && state.listSearchFilters[f].hasOwnProperty(ff) && state.listSearchFilters[f][ff]) {
                                selDone = true;
                            }
                        }
                    } else if (state.listSearchFilters[f] && state.listSearchFilters[f].length) {
                        // check for an array filter
                        // excepting completely filled 'accountTypes' array of the admin section
                        if (f !== 'accountTypes' || (f === 'accountTypes' && state.listSearchFilters[f].length !== 6)) {
                            selDone = true;
                        }
                    }
                }
            }
        }
        return selDone;
    }

    public static checkSearch(state) {
        if (state && state.listSearchFilters && state.listSearchFilters.search !== undefined && state.listSearchFilters.search !== null) {
            if (state.listSearchFilters.search.length >= 0) {
                return true;
            }
        }
        return false;
    }
}
