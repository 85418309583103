import {
    ChangeDetectorRef,
    EventEmitter,
    OnInit,
    OnDestroy,
    Component,
    Output
} from '@angular/core';
import {PeopleEditModalComponentCommon} from '../../commonClasses/people-edit-modal-common';
import {ApiPeopleService} from '../../../services/api-people.service';
import {StateService} from '../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiJobsService} from '../../../../jobs/services/api-jobs.service';
import {PersonService} from '../../../services/person.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-mobile-people-edit',
  templateUrl: './mobile-people-edit.component.html',
  styleUrls: ['./mobile-people-edit.component.scss']
})
export class MobilePeopleEditComponent extends PeopleEditModalComponentCommon implements OnInit, OnDestroy {
    @Output() cancelEdit = new EventEmitter;

    peopleEditForm: UntypedFormGroup;
    emptyName: boolean;

    constructor(
        private apiPeopleService: ApiPeopleService,
        protected stateService: StateService,
        public translate: TranslateService,
        protected apiJobs: ApiJobsService,
        public person: PersonService,
        protected cd: ChangeDetectorRef,
        protected fb: UntypedFormBuilder
    ) {
        super(stateService, apiJobs, cd, person, fb);
    }

    ngOnInit() {
        this.initSessionVars();
        this.emptyName = (this.person.firstName === '' || this.person.lastName === '');

        // people edit form
        this.peopleEditForm = this.initPeopleEditForm();
        this.fetchJobs();
    }

    onSubmit(model: any) {
        // department
        model.value.department = (model.value.department) ? [model.value.department] : [];
        // add subAccount
        model.value.subAccount = (model.value.subAccount) ? model.value.subAccount : this.person.subAccount;
        // call api to save the modification
        this.subscriptions.add(this.apiPeopleService.editPerson([this.person.id], model.value).subscribe(
            (res) => {
                if (res) {
                    res.updateData = true;
                    this.person.personToDisplay = res;
                }
                this.closeEditPanel();
            }
        ));
    }

    closeEditPanel() {
        this.stateService.people.stateChanged.next({refreshComponent: true});
        this.cancelEdit.emit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
