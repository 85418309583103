// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.popUpInput {
  position: absolute;
  background: white;
  z-index: 1000;
  border: 4px solid #aaa;
  right: calc(50% - 400px);
  top: 250px;
  padding: 10px;
  min-width: 300px;
}

.inactiveReportButton {
  background-color: #999;
  padding: 5px 10px;
  border: 1px solid #AAA;
  color: #DCDCDC;
  border-radius: 4px;
}

.inactiveReportButton:hover {
  background-color: #CCC;
}

#buttonContainer {
  margin-bottom: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-report/people-report.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;EAGA,kBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".displayBlock {\n    display: block;\n}\n\n.displayNone {\n    display: none;\n}\n\n.popUpInput {\n    position: absolute;\n    background: white;\n    z-index: 1000;\n    border: 4px solid #aaa;\n    right: calc(50% - 400px);\n    top: 250px;\n    padding: 10px;\n    min-width: 300px;\n}\n\n.inactiveReportButton {\n    background-color: #999;\n    padding: 5px 10px;\n    border: 1px solid #AAA;\n    color: #DCDCDC;\n    -moz-border-radius: 4px;\n    -webkit-border-radius: 4px;\n    border-radius: 4px;\n}\n\n.inactiveReportButton:hover {\n    background-color: #CCC;\n}\n\n#buttonContainer {\n    margin-bottom: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
