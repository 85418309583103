import * as _ from 'lodash';
import {List} from '../../../people/interfaces';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../core/services/state/state.service';
import {AccountService} from '../../services/account.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {MainListAdministrationService} from '../../services/main-list-administration.service';
import {Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {
    PurchaseAdjustementModalComponent
} from '../administration-purchase-adjustement-modal/administration-purchase-adjustement-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {SearchHelper} from 'src/app/shared/misc/search.helper';
import {AccountTypesHelper} from '../../commons/AccountTypesHelper';

@Component({
    selector: 'app-administration-list',
    templateUrl: './administration-list.component.html',
    styleUrls: ['./administration-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationListComponent implements OnInit, OnDestroy {

    accountModalRef: BsModalRef;
    purchaseAdjustmentModal: BsModalRef;
    clickMode = false;
    // The list width (corrected)
    listWidth: string;
    sideWidth: string;
    isSelected = 'none';
    toList: List = {
        count: null,
        data: []
    };

    dateStart = '';
    dateEnd = '';

    resetCalled = false;

    showTools = false;

    dateStartModel = {};
    dateEndModel = {};

    public loading = false;
    totalItems = 0;
    recordsLoaded = 0; // How much records of the list is loaded
    clientToDisplay: any; // Client;
    clientToDisplayId: string;
    checkedItems: Array<string> = [];
    typeSelected: string;
    switchOff: boolean;
    tableDefinition: Array<any> = [];
    initList = false;

    clientStartDate;
    clientEndDate;

    sidebarOpened = false;

    status = true;
    subsidiary: string;
    distributor: string;
    partner: string;
    currentAccountTypes;
    distributorsList;
    partnersList;
    searchTerm: string;
    levels: any;
    small;

    private subscriptions = new Subscription();

    onDestroy$ = new Subject();

    constructor(public router: Router,
                private route: ActivatedRoute, // used in state do not remove
                private modalService: BsModalService,
                public stateService: StateService,
                public userPreferencesService: UserPreferencesService,
                public clientAccount: AccountService,
                private apiAdministration: ApiAdministrationService,
                private mainListAdministrationService: MainListAdministrationService,
                public translate: TranslateService,
                private changeDetectorRef: ChangeDetectorRef  // Even if flagged as unused, it is used in getList(this, search) below
    ) {
        if (this.state.sideBarStatus === 'clickMode') {
            this.state.sideBarStatus = 'recentActivities';
        }
        if (
            this.permissionsAccountType === 'client' ||
            this.permissionsAccountType === 'demo'
        ) {

            this.router.navigate(['/admin/account/' + this.stateService.session.sessionData.accountData.id]).then(() => {
            });
        }

        this.currentAccountTypes = AccountTypesHelper.setCurrentAccountTypesArray(this.permissionsAccountType);

    }

    ngOnInit() {
        this.stateService.setPropagation(this, 'admin');

        this.small = (window.innerWidth < 1235);

        // WATCHES ////////////////////////////////////////////////////////////////////////
        // Update personToDisplay when needed
        this.subscriptions.add(this.state.clientToDisplayWatch$.subscribe(
            () => {
                if (this.toList) {
                    this.clientToDisplay =
                        this.setClientToDisplay(this.toList, this.state.clientToDisplayId);
                }
            }
        ));

        // Update list when needed
        this.subscriptions.add(this.state.listWatch$.subscribe(
            () => {
                this.loading = true;
                this.getAccountList();
            }
        ));

        // Reload data after language switch
        this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            // reset list
            this.toList = {
                count: null,
                data: []
            };

            this.loading = true;
            this.getAccountList();
        });

        // Set list width
        this.computeListWidth(window);
        // TODO: Check if Is the following used??
        if (!this.state.clientToDisplayId) {
            this.clickMode = false;
            this.state.sideBarStatus = 'recentActivities';
        }

        if (!this.loading) {
            this.loading = true;
            this.getAccountList();
        }
    }

    initLevels() {
        this.levels = this.mainListAdministrationService.initLevels();
    }

    /**
     * SEARCH FILTER STUFF
     */
    async resetSearchFilters() {
        this.searchTerm = undefined;
        this.status = null;
        this.resetCalled = true;
        this.dateStart = '';
        this.dateEnd = '';
        this.dateStartModel = null;
        this.dateEndModel = null;
        this.distributor = undefined;
        this.partner = undefined;
        this.partnersList = undefined;
        this.currentAccountTypes = AccountTypesHelper.setCurrentAccountTypesArray(this.permissionsAccountType);
        this.navigate('resetSearchFilters', {
            resetSearchFilters: true,
            userPreferences: this.stateService.session.sessionData.userData.preferences
        });
    }

    setSubsidiary(subsidiary) {
        this.subsidiary = subsidiary;
        this.distributor = null;
        this.partner = null;
        this.getList();
    }

    setDistributor(distributor) {
        this.distributor = distributor;
        this.partner = null;
        this.getList();
    }

    setPartner(partner) {
        this.partner = partner;
        this.getList();
    }

    setCurrentAccountType(currentAccountTypes: number[]) {
        this.currentAccountTypes = currentAccountTypes;
        this.getList();
    }


    setStatus(status) {
        this.status = status;
        this.getList();
    }

    setClientStartDate(clientStartDate) {
        this.clientStartDate = clientStartDate;
        this.getList();
    }

    setClientEndDate(clientEndDate) {
        this.clientEndDate = clientEndDate;
        if (this.clientEndDate < this.clientStartDate) {
            this.toList = {
                count: null,
                data: []
            };
            this.loading = false;
        } else {
            this.getList();
        }
    }

    setSearchFilter(searchTerm) {
        // Has changed
        if (this.searchTerm !== searchTerm || this.searchTerm === '') {
            this.searchTerm = searchTerm;
            this.getList();
        }
    }

    getList() {
        if (this.loading === true) {
            return;
        }

        // re-init
        this.recordsLoaded = 0;
        this.toList = {
            count: null,
            data: []
        };

        let accountNumberIsUpInHierarchy;

        if (this.partner) {
            accountNumberIsUpInHierarchy = this.partner;
        } else if (this.distributor) {
            accountNumberIsUpInHierarchy = this.distributor;
        } else if (this.subsidiary) {
            accountNumberIsUpInHierarchy = this.subsidiary;
        }

        let isEnabled = this.status;

        if (this.searchTerm && this.searchTerm !== '') {
            isEnabled = undefined;
        }

        let payload = {
            search: this.searchTerm,
            accountTypes: this.currentAccountTypes,
            isEnabled: isEnabled,
            // subsidiary: this.subsidiary,
            // distributor: this.distributor,
            // partner: this.partner,
            accountNumberIsUpInHierarchy: accountNumberIsUpInHierarchy,
            clientStartDate: this.getClientsDate(this.clientStartDate),
            clientEndDate: this.getClientsDate(this.clientEndDate),
            selfExclude: 1
        };
        this.navigate('getFilteredList', payload);
    }

    // getClientsDate(date) {
    //     if (!date || !date.epoc) {
    //         return null;
    //     } else {
    //         return this.dpToMongoDate(date);
    //     }
    // }

    getClientsDate(date: Date) {
        if (!date) {
            return null;
        } else {
            // Convert the JavaScript Date object to the format your API expects
            return this.dpToMongoDate(date);
        }
    }


    /////////////////////////
    toggleSidebar() {
        this.sidebarOpened = !this.sidebarOpened;
    }

    //
    // filters for the list ***************************************************************
    //

    // dpToMongoDate(dateObject) {
    //     if (dateObject.date.day === 0) {
    //         return '';
    //     } else {
    //         let month = (dateObject.date.month > 9) ? dateObject.date.month : '0' + dateObject.date.month;
    //         let day = (dateObject.date.day > 9) ? dateObject.date.day : '0' + dateObject.date.day;
    //         return dateObject.date.year + '-' + month + '-' + day;
    //     }
    // }

    dpToMongoDate(date: Date) {
        if (!date) {
            return '';
        } else {
            let month = (date.getMonth() + 1 > 9) ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
            let day = (date.getDate() > 9) ? date.getDate() : '0' + date.getDate();
            return date.getFullYear() + '-' + month + '-' + day;
        }
    }


    //
    // ************************************************************************************
    //
    getAccountList() {
        if (this.stateService.admin.listSearchFilters) {
            let prefAccountTypes = [];
            if (this.userData) {
                let preferences = this.userData.preferences;
                if (preferences && preferences.admin && preferences.admin.search) {
                    prefAccountTypes = preferences.admin.search.accountTypes;
                }
            }
        }

        let list = this.mainListAdministrationService.getList(
            this.stateService.admin,
            this.recordsLoaded,
            (this.toList && this.toList['count']) ? this.toList['count'] : null
        );
        this.recordsLoaded = list.recordsLoaded;
        let listObservableSubscription: Subscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.totalItems = list.total;
                this.toList = this.setColumnsValues(list, this.stateService.session.sessionData);

                if (this.state.clientToDisplayId && !this.clientToDisplay) {
                    this.clientToDisplay =
                        this.setClientToDisplay(this.toList, this.state.clientToDisplayId);
                }
                this.initList = true;
                this.loading = false;
                listObservableSubscription.unsubscribe();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    editRefresh() {
        // update list
        this.state.listWatch.next(true);
    }

    /**
     * Will use sessionData to transform the subAccount number into subAccount's name
     *
     * @param list
     * @param sessionData
     * @returns {any}
     */
    setColumnsValues(list, sessionData) {
        for (let record in list['data']) {
            if (list['data'].hasOwnProperty(record)) {
                if (list.data[record]['status']) {
                    list.data[record]['status'] = ('en' === this.translate.currentLang) ? 'Active' : 'Actif';
                } else {
                    list.data[record]['status'] = ('en' === this.translate.currentLang) ? 'Inactive' : 'Inactif';
                }
                // unlimited credit translation
                if (list.data[record]['totalCredits'] && list.data[record]['totalCredits'] === 'unlimited') {
                    list.data[record]['totalCredits'] = ('en' === this.translate.currentLang) ? 'Unlimited' : 'Illimité';

                }
                if (list.data[record]['type']) {
                    list.data[record]['type'] = AccountTypesHelper.accountTypeNameByIdAndLanguage(list.data[record]['type'], this.translate.currentLang);
                }

            }
        }
        return list;
    }

    setClientToDisplay(toList, clientToDisplayId) {
        if (toList) {
            for (let i in toList['data']) {
                if (toList['data'].hasOwnProperty(i)) {
                    if (toList['data'][i].id === clientToDisplayId) {
                        return toList['data'][i];
                    }
                }
            }
        }
        // fetchHere
        if (clientToDisplayId) {
            this.subscriptions.add(this.apiAdministration.client([clientToDisplayId]).subscribe(
                res => {
                    return (res) ? res : undefined;
                }
            ));
        }
    }

    /**
     * Update list on scroll
     */
    listScrolled(): void {
        if (this.toList['count'] && this.recordsLoaded >= this.toList['count']) {
            return;
        }
        let list = this.mainListAdministrationService.getList(
            this.stateService.admin,
            this.recordsLoaded,
            (this.toList && this.toList['count']) ? this.toList['count'] : null,
            true
        );
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        this.subscriptions.add(list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList['data'] =
                    [...this.toList['data'], ...this.setColumnsValues(list, this.stateService.session.sessionData)['data']];
                this.loading = false;
                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    switchMode() {
        this.clickMode = !this.clickMode;
        let newState = 'clickMode';
        if (!this.clickMode) { // reset
            this.checkedItems = [];
            newState = 'recentActivities';
        }
        this.navigate('setSideBarStatus', {
            sideBarStatus: newState
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Triggers hidden menu items when some items are selected on the list in
     * shared/main-list/component/main-list
     * @param event
     */
    /**
     * This might not be used ####################################################################
     *
     */
    checkableHandling(event) {
        if (event.state === 'checked') {
            if (!_.includes(this.checkedItems, event)) {
                this.checkedItems.push(event);
            }
            if (this.checkedItems.length > 0) {
                this.isSelected = 'some';
            }
        } else if (event.state === 'unchecked') {
            this.checkedItems.splice(
                this.checkedItems.indexOf(event),
                1
            );
            if (this.checkedItems.length < 1) {
                this.isSelected = 'none';
            }
        } else if (event.state === 'clickMode') {
            if (_.some(this.checkedItems, event)) {
                this.checkedItems.splice(
                    this.checkedItems.indexOf(event),
                    1
                );
            } else {
                this.checkedItems.push(event);
            }
            this.isSelected = (this.checkedItems.length > 0) ? 'some' : 'none';
        }

    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);
    }

    computeListWidth(window: Window): void {
        let adjustment = {left: -17, right: -14};
        let widthRatio = 1;
        let leftColumnWidth = 100;
        let innerWidth = window.innerWidth;
        // if (window.innerWidth < 1280) {
        // innerWidth = 1280;
        // }
        this.listWidth = ((innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 15) + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
    }

    /**
     * TODO MODALS :::
     */

    switchOffFilterPanelIndicator(event) {
        this.switchOff = event;
    }

    clientSelected(item) {
        this.clientAccount.setAccountToDisplayWithId(item[0].id, 'admin', 'fullClient');
    }

    goToMyAccount() {
        this.clientAccount.setAccountToDisplayWithId(
            this.sessionData['accountData'].id,
            'admin',
            'fullClient'
        );
    }

    goToTools() {
        if (this.canGoToTools()) {
            this.showTools = true;
        }
    }

    goToList() {
        this.showTools = false;
    }

    canGoToTools() {
        return !!(_.includes(this.permissionsRoles, 'ROLE_SUPER_ADMIN'));
    }

    toggleFilterPanel(event: boolean): void {
        this.switchOff = false;
        this.navigate('setSideBarStatus', {
            sideBarStatus: (event) ? 'searchFilters' : 'recentActivities'
        });
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.navigate('setListConfig', {
            key: event.key,
            order: event.order
        });
    }

    navigate(action, optParams) {
        this.stateService.navigate(
            this.router,
            'admin/list',
            action,
            optParams
        );
    }

    canCreateClient() {
        // Only super admin can create client
        const permissions = this.stateService.session.sessionData.permissions;
        return !!(permissions && permissions.roles.includes('ROLE_SUPER_ADMIN'));
    }

    onCreateClient() {
        this.router.navigate(['admin/account/new']).then(() => {
        });
    }

    onClickPurchase() {
        const initialState = {
            account: this.clientToDisplay
        };
        this.purchaseAdjustmentModal = this.modalService.show(PurchaseAdjustementModalComponent, {
            initialState,
            backdrop: 'static'
        });
        this.purchaseAdjustmentModal.content.editRefresh.subscribe(res => {
            if (res.success) {
                this.editRefresh();
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();

        this.subscriptions.unsubscribe();
    }

    get sessionData() {
        return _.get(this.stateService, 'session.sessionData');
    }

    get userData() {
        return _.get(this.sessionData, 'userData');
    }

    get sessionPermissions() {
        return _.get(this.sessionData, 'permissions');
    }

    get permissionsRoles() {
        return _.get(this.sessionPermissions, 'roles');
    }

    get selectionDone() {
        return SearchHelper.selectionDone(this.state);
    }

    get selectionByFilters() {
        return SearchHelper.selectionDone(this.state, 'search');
    }

    get totalListItems() {
        return (this.toList && this.toList.count !== this.totalItems) ? this.toList.count : null;
    }

    get resMention() {
        return (this.totalListItems > 1) ? 'commons.results' : 'commons.result';
    }

    get state() {
        return this.stateService.admin;
    }

    get permissionsAccountType() {
        if (this.stateService.session && this.stateService.session.sessionData && this.stateService.session.sessionData.permissions) {
            return this.stateService.session.sessionData.permissions.accountType;
        }
        return;
    }
}
