// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "MontserratLight", sans-serif;
}

.modal-body {
  background-color: #eeeeee;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/web/job-profiler-request-resend-modal/job-profiler-request-resend-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":["* {\n    font-family: 'MontserratLight',  sans-serif;\n}\n\n.modal-body {\n    background-color: #eeeeee;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
