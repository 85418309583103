import {Content} from './content';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';

export abstract class ContentMultiselect extends Content {
    protected constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
    }
}
