type JobDescriptionType = 'short' | 'survey';

export class JobDescriptionData {
    purposeOfThePosition: string;
    tasks: Array<JobTask>;

    constructor(options: {
        purposeOfThePosition?: string,
        tasks?: Array<JobTask>
    } = {}) {
        this.purposeOfThePosition   = options.purposeOfThePosition;
        this.tasks                  = options.tasks;
    }
}

export class JobTask {
    responsibility: string;
    priority: number;
    percentage: number;

    constructor(options: {
        responsibility?: string,
        priority?: number,
        percentage?: number
    } = {}) {
        this.responsibility = options.responsibility;
        this.priority       = options.priority;
        this.percentage     = options.percentage;
    }
}

// Extern score because it can be used by several classes?
export class Score {
    OR: number;
    SE: number;
    A: number;
    E: number;
    P: number;
    S: number;

    constructor(options: {
        OR?: number,
        SE?: number,
        A?: number,
        E?: number,
        P?: number,
        S?: number
    } = {}) {
        this.OR = options.OR;
        this.SE = options.SE;
        this.A  = options.A;
        this.E  = options.E;
        this.P  = options.P;
        this.S  = options.S;
    }
}

export class Job {

    id: string;
    jobTitle: string;
    subAccount: number;
    language: string;
    location: string;
    isComplete: boolean;
    type: JobDescriptionType;
    industry: number;
    jobType: number;
    requiredBy: string;
    reportsTo: string;
    sharedToSubaccounts: any;
    jobDescription: JobDescriptionData;
    score: any;
    date: number;
    creationDate: number;
    subAccountsAccess: string;
    department: any;
    OR: number;
    SE: number;
    A: number;
    E: number;
    P: number;
    S: number;
    competences: any;
    raScore: any;

    constructor(options: {
        id?: string,
        jobTitle?: string,
        subAccount?: number,
        language?: string,
        location?: string,
        isComplete?: boolean,
        type?: JobDescriptionType,
        industry?: number,
        jobType?: number,
        requiredBy?: string,
        reportsTo?: string,
        sharedToSubaccounts?: any,
        jobDescription?: JobDescriptionData,
        score?: Array<Score>,
        date?: number,
        creationDate?: number,
        subAccountsAccess?: string,
        department?: any,
        OR?: number,
        SE?: number,
        A?: number,
        E?: number,
        P?: number,
        S?: number,
        competences?: any,
        raScore?: any
    } = {}) {
        this.id                  = options.id;
        this.jobTitle            = options.jobTitle || '';
        this.subAccount          = options.subAccount;
        this.language            = options.language;
        this.location            = options.location || '';
        this.isComplete          = options.isComplete;
        this.type                = options.type;
        this.industry            = options.industry;
        this.jobType             = options.jobType;
        this.requiredBy          = options.requiredBy;
        this.reportsTo           = options.reportsTo;
        this.sharedToSubaccounts = options.sharedToSubaccounts;
        this.jobDescription      = options.jobDescription || new JobDescriptionData();
        this.score               = options.score;
        this.date                = options.date;
        this.creationDate        = options.creationDate;
        this.subAccountsAccess   = options.subAccountsAccess;
        this.department          = options.department;
        this.A                   = options.A;
        this.E                   = options.E;
        this.P                   = options.P;
        this.S                   = options.S;
        this.OR                  = options.OR;
        this.SE                  = options.SE;
        this.competences         = options.competences;
        this.raScore             = options.raScore;
    }
}
