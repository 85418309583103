import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    OnDestroy
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {StateService} from 'src/app/core/services/state/state.service';
import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {takeUntil} from 'rxjs/operators';
import {JobsCompetencesModalCommon} from '../../commonClasses/jobs-competences-modal-common';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-jobs-competences',
    templateUrl: './mobile-jobs-competences.component.html',
    styleUrls: ['./mobile-jobs-competences.component.scss']
})
export class MobileJobsCompetencesComponent extends JobsCompetencesModalCommon implements OnInit, OnChanges, OnDestroy {

    @Input() status: any;

    @Output() close = new EventEmitter();

    onDestroy$: Subject<null> = new Subject();
    scoreIndex = 0;
    readonly apiBaseUrl: string;
    value: any;
    subMenu = [];

    constructor(
        public translateService: TranslateService,
        public jobService: JobService,
        protected stateService: StateService,
        private apiJobsService: ApiJobsService,
        private environment: EnvService
    ) {
        super(translateService);
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    ngOnInit() {
        this.initTalentsContext();
        this.checkExistingTalents();
        this.profileDataLoad();

        this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.profileDataLoad();
        });
    }

    ngOnChanges() {
        this.initTalentsContext();
        this.checkExistingTalents();
    }

    initTalentsContext() {
        for (let i = 0; i < 46; i++) {
            this.selectedTalents[i] = null;
            this.subMenu[i] = false;
        }
    }

    checkExistingTalents() {
        if (this.jobService.competences) {
            for (let competence in this.jobService.competences[0]) {
                if (this.jobService.competences[0].hasOwnProperty(competence)) {
                    this.updateTalent(Number(competence), this.jobService.competences[0][competence], null);
                }
            }
        }
    }

    toggleMastering(i) {
        this.subMenu[i] = !this.subMenu[i];
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    proceed() {
        let payload: any = {};
        for (let talent in this.selectedTalents) {
            if (this.selectedTalents.hasOwnProperty(talent) &&
                (this.selectedTalents[talent] === 1 || this.selectedTalents[talent] === 2)) {
                    payload['' + talent] = this.selectedTalents[talent];
            }
        }
        if (this.status === 'edit') {
            this.saveEditCompetences(payload);
        } else {
            this.saveNewCompetences(payload);
        }

    }

    saveEditCompetences(payload) {
        // call api to save the modification
        this.apiJobsService.jobTalents([this.jobService.id, this.scoreIndex], {competences: payload}, true).pipe(takeUntil(this.onDestroy$)).subscribe(
            () => {
                this.jobService.setItemToDisplayWithId(this.jobService.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id, tab: 'talents'});
                this.selectedTalents = [];
                this.close.emit(true);
            }
        );
    }

    saveNewCompetences(payload) {
        // call api to save the modification
        this.apiJobsService.jobTalents([this.jobService.id], {competences: payload}).pipe(takeUntil(this.onDestroy$)).subscribe(
            () => {
                this.jobService.setItemToDisplayWithId(this.jobService.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id, tab: 'talents'});
                this.selectedTalents = [];
                this.close.emit(true);
            }
        );
    }

    updateTalent(talent, value, subMenuNumber) {
        let setValue = value;
        if (setValue === 0) {
            setValue = null;
        }
        this.selectedTalents[talent] = setValue;
        if (subMenuNumber !== null) {
            this.subMenu[subMenuNumber] = false;
        }
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined  && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    closeForm() {
        this.close.emit();
    }

}
