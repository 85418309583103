import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiPeopleService } from 'src/app/people/services/api-people.service';
import { WindowRef } from 'src/app/app/services/window-ref.service';
import {EnvService} from '../../../../../../app/services/env.service';

@Component({
    selector: 'app-people-survey-request-send-method-paper',
    template: `
        <p>{{ 'commons.enterSurveysOnline' | translate }}
            <a (click)="openEvaluationForm()">{{'commons.here' | translate }}</a>
        </p>
        <p>{{ 'commons.completeOnlineFill' | translate }}</p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestSendMethodPaperComponent implements OnInit, OnDestroy {
    @Input() recordId: string;
    @Input() formSubmit$: Observable<any>;
    @Output() afterSubmission: EventEmitter<any> = new EventEmitter();

    private nativeWindow: any;
    public onDestroy$: Subject<any> = new Subject();

    constructor(
        private apiPeopleService: ApiPeopleService,
        protected windowRef: WindowRef,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        // tt
        this.formSubmit$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.afterSubmission.emit();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public openEvaluationForm() {
        // Open window to avoid popup blocking
        const questionnaireWindow = this.nativeWindow.open('', '_blank');
        this.apiPeopleService.requestToken([this.recordId]).pipe(takeUntil(this.onDestroy$)).subscribe(
            (res: any) => {
                // Modification of URL in window open after getting data
                questionnaireWindow.document.location.href = `${this.environment.evaluationFormUrl}/autoconnect/${res.token}`;
            }
        );
    }
}
