import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {ApiJobsService} from '../../../../jobs/services/api-jobs.service';
import {PricesDefinitions} from '../../../../shared/prices/pricesDefinitions';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {StateService} from '../../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../../../administration/services/api-administration.service';
import {PeopleReportActivationModalCommon} from '../../commonClasses/people-report-activation-modal-common';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';

@Component({
    selector: 'app-people-report-activation-modal',
    templateUrl: './people-report-activation-modal.component.html',
    styleUrls: ['./people-report-activation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleReportActivationModalComponent extends PeopleReportActivationModalCommon implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();
    @Output() activation = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public router: Router,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        private state: StateService,
        public translate: TranslateService,
        protected cd: ChangeDetectorRef,
        public person: PersonService,
        public stateService: StateService
    ) {
        super(apiJobsService, apiAdministrationService, cd, translate, testAndReportHandlingService, person, stateService);
        this.pricesDefinitions = PricesDefinitions.item;
    }

    ngOnInit() {
        this.getSatelliteJobs();
        this.creditSetup(0, this.state.session.sessionData.accountData.id);

        this.proceed = true;
        if (this.activationData) {
            let subAccount = (this.activationData.personToDisplay) ? this.activationData.personToDisplay.subAccount : this.activationData.subAccount;
            this.creditSetup(subAccount, this.state.session.sessionData.accountData.id, this.activationData);
        }
    }

    get activationType() {
        return (this.activationData ? this.activationData.type : null);
    }

    setActivationData(activationData) {
        if (activationData && activationData.types) {
            this.activationData.types = activationData.types;
        }
        if (activationData && activationData.cost) {
            this.activationData.cost = activationData.cost;
        }
    }

    activationTexts() {
        if (this.unlimitedCredits) {
            if (this.translate.currentLang === 'fr') {
                if (this.activationData.types.length === 1) {
                    return 'Activer le rapport ' + this.renameReport(this.activationData.types[0]) + ' ?';
                } else {
                    return 'Activer les rapports ' + this.listReports(this.activationData.types) + ' ?';
                }
            } else {
                if (this.activationData.types.length === 1) {
                    return 'Activate ' + this.renameReport(this.activationData.types[0]) + ' report?';
                } else {
                    return 'Activate ' + this.listReports(this.activationData.types) + ' reports?';
                }
            }
        } else {
            if (this.translate.currentLang === 'fr') {
                if (this.activationData.types.length === 1) {
                    return 'Activer le rapport ' + this.renameReport(this.activationData.types[0]) + ' pour ' + this.activationData.cost + ' crédits ?';
                } else {
                    return 'Activer les rapports ' + this.listReports(this.activationData.types) + ' pour ' +
                        this.activationData.cost + ' crédits ?';
                }
            } else {
                if (this.activationData.types.length === 1) {
                    return 'Activate ' + this.renameReport(this.activationData.types[0]) + ' report for ' + this.activationData.cost + ' credits?';
                } else {
                    return 'Activate ' + this.listReports(this.activationData.types) + ' reports for ' +
                        this.activationData.cost + ' credits?';
                }
            }
        }
    }

    lang() {
        return this.translate.currentLang;
    }

    navToJob() {
        this.closeModal();
        this.state.navigate(
            this.router,
            'jobs/list',
            'setSideBarStatus',
            {
                sideBarStatus: 'recentActivities',
                action: 'activateSatellite'
            },
            'jobs'
        );
    }

    reportActivation(type = this.activationData.type) {
        this.reportActivationCommon(type);
        this.state.session.sessionCreditUpdateWatch.next(true);
        this.closeModal(true);
    }

    closeModal(activated = false) {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.proceed = null;
        });
        this.close.emit({
            closed: true,
            id: this.activationData.personToDisplay ? this.activationData.personToDisplay.id : this.activationData.id,
            type: activated ? this.backToFrontCorrectedType(this.activationData.type) : null
        });
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
