// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  padding-top: 10px;
}

.mainContainer {
  margin-left: 110px;
  padding: 0 20px;
  height: 100vh;
}

.toolsNav {
  margin-top: 28px;
}

.top150 {
  position: relative;
  top: 150px;
  margin: 0 !important;
}

.top150 h1 {
  font-size: 22px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tools/components/main-tools/main-tools.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,oBAAA;AACJ;;AAEA;EACI,eAAA;EACA,UAAA;AACJ","sourcesContent":["h1 {\n    padding-top: 10px;\n}\n\n.mainContainer {\n    margin-left: 110px;\n    padding: 0 20px;\n    height: 100vh;\n}\n\n.toolsNav {\n    margin-top: 28px;\n}\n\n.top150 {\n    position: relative;\n    top: 150px;\n    margin: 0 !important;\n}\n\n.top150 h1 {\n    font-size: 22px;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
