import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector   : 'app-mini-idw-graph',
    templateUrl: './mini-idw-graph.component.html',
    styleUrls  : ['./mini-idw-graph.component.scss']
})
export class MiniIdwGraphComponent implements OnInit {

    @ViewChild('MiniIdwGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id: string;
    @Input() quad: string;
    @Input() type = 'paragraphHeader';
    @Input() card = false;
    @Input() lg = 'fr';
    gridDefinition: string;

    constructor() {
    }

    ngOnInit() {
        /** Todo: change the database so that we get V instead of uls... */

        if (this.type === 'paragraphHeader') {
            let corner = this.quad.split('.')[0];
            switch (corner) {
            case 'uls':
                this.gridDefinition = 'V';
                break;
            case 'urs':
                this.gridDefinition = 'R';
                break;
            case 'lls':
                this.gridDefinition = 'B';
                break;
            case 'lrs':
                this.gridDefinition = 'J';
                break;
            }
        } else if (this.quad && this.quad.length === 2) {
            this.gridDefinition = this.quad[0];
        } else {
            this.gridDefinition = this.quad;
        }

        // console.log(this.gridDefinition + ' ' + this.quad[0]);

        if (this.gridDefinition !== 'SEP') {
            /** Todo: Gérer ces cas-là! **/
            this.createMiniIdwGraph();
        } else {
            console.log('Fix SEPs');
        }
    }

    createMiniIdwGraph() {

        const colors = {
            green : 'rgb(119,198,175)',
            red   : 'rgb(240,123,126)',
            blue  : 'rgb(119,156,197)',
            yellow: 'rgb(238,207,123)',
            gray: '#E2E2E2'
        };

        const graphSize = {
            paragraphHeader: '30',
            cards          : '60',
            sidebar        : '60'
        };

        if (this.card) {
            d3.select('#mini_dit_' + this.id).remove();
        }

        let element = this.chartContainer.nativeElement;

        let id = (this.id) ? this.id : 'pastilleIdw';
        let svg = d3.select(element).append('svg').attr('id', 'mini_dit_' + id).attr('preserveAspectRatio', 'xMidYMid meet').
            attr('width', graphSize[this.type]).
            attr('height', graphSize[this.type]).attr('viewBox', '0 0 40 40');

        svg.append('circle').attr('cx', 20).attr('cy', 20).attr('r', 18).style('fill', 'white').style('stroke-width', 1).
            style('stroke', colors.gray);

        if (this.quad === 'INV') {
            let inv = (this.lg === 'fr') ? 'Invalide' : 'Invalid';
            svg.append('text')
                .attr('x', 20)
                .attr('y', 22)
                .text(inv)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'sans-serif')
                .attr('font-size', 7)
                .attr('fill', '#888');
        }

        /** Arc circle presentation */
        const gridElement = {
            R: {startAngle: 0, endAngle: 2 * Math.PI / 4, color: 'red'},
            J: {startAngle: 2 * Math.PI / 4, endAngle: 2 * (2 * Math.PI / 4), color: 'yellow'},
            B: {startAngle: 2 * (2 * Math.PI / 4), endAngle: 3 * (2 * Math.PI / 4), color: 'blue'},
            V: {startAngle: 3 * (2 * Math.PI / 4), endAngle: 2 * Math.PI, color: 'green'}

        };

        const startAngleConfig: number = gridElement[this.gridDefinition] ? gridElement[this.gridDefinition].startAngle : null;
        const endAngleConfig: number = gridElement[this.gridDefinition] ? gridElement[this.gridDefinition].endAngle : null;
        const colorConfig: string = gridElement[this.gridDefinition] ? gridElement[this.gridDefinition].color : 'gray';

        let arcBG = d3.arc().innerRadius(12).outerRadius(18)
            .startAngle(startAngleConfig)
            .endAngle(endAngleConfig);
        //
        svg.append('path').attr('transform', 'translate(20,20)').attr('class', 'arc').attr('d', arcBG).
            attr('fill', colors[colorConfig]);

    }

}
