// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuLogo {
  display: inline-block;
  padding: 15px 25px;
}

.menuLogo a {
  display: block;
}

.sidenav-menu-btn {
  padding-top: 9px;
}

.sidenav-menu-btn .mat-icon {
  font-size: 45px;
  width: 45px;
  height: 45px;
  color: #666;
}

.mainMenuItemContainer {
  display: flex;
  min-width: 65px;
  max-width: 220px;
  max-height: 57px;
  text-align: center;
  flex-direction: column;
}

.mainMenuItemContainer .mat-icon {
  margin-right: 10px;
  margin-left: 10px;
  color: white;
}

.mainMenuButtonTitle {
  font-size: 1.2rem;
}

.credits {
  color: #ffffff;
  padding: 10px 10px 10px 20px;
  border-top: 1px solid #595959;
  font-size: 1.25rem;
  font-style: italic;
  margin-top: 10px;
}

button.bottom-btn {
  background-color: #313131;
  border-top: 1px solid #595959;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 0 0 5px !important;
  height: unset !important;
  width: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app/components/navigation/mobile/mobile-navigation.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,kBAAA;AACJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,gBAAA;AAGJ;;AADA;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AAIJ;;AAFA;EACI,aAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;AAKJ;;AAHA;EACI,kBAAA;EACA,iBAAA;EACA,YAAA;AAMJ;;AAJA;EACI,iBAAA;AAOJ;;AALA;EACI,cAAA;EACA,4BAAA;EACA,6BAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAQJ;;AALA;EACI,yBAAA;EACA,6BAAA;AAQJ;;AALA;EACI,6BAAA;EACA,wBAAA;EACA,uBAAA;AAQJ","sourcesContent":[".menuLogo {\n    display: inline-block;\n    padding: 15px 25px;\n}\n.menuLogo a {\n    display: block;\n}\n.sidenav-menu-btn {\n    padding-top: 9px;\n}\n.sidenav-menu-btn .mat-icon {\n    font-size: 45px;\n    width: 45px;\n    height: 45px;\n    color: #666;\n}\n.mainMenuItemContainer {\n    display: flex;\n    min-width: 65px;\n    max-width: 220px;\n    max-height: 57px;\n    text-align: center;\n    flex-direction: column;\n}\n.mainMenuItemContainer .mat-icon {\n    margin-right: 10px;\n    margin-left: 10px;\n    color: white;\n}\n.mainMenuButtonTitle {\n    font-size: 1.2rem; \n}\n.credits {\n    color: #ffffff;\n    padding: 10px 10px 10px 20px;\n    border-top: 1px solid #595959;\n    font-size: 1.25rem;\n    font-style: italic;\n    margin-top: 10px;\n}\n\nbutton.bottom-btn {\n    background-color: #313131;\n    border-top: 1px solid #595959;\n}\n\n.mat-mdc-icon-button.mat-mdc-button-base {\n    padding: 0 0 0 5px !important;\n    height: unset !important;\n    width: unset !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
