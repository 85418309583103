// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ng-sidebar__content {
  position: static !important;
}

#myCarousel {
  display: block;
  position: relative;
  width: 800px;
  height: 600px;
  margin: auto;
  z-index: 1000000;
  background-color: white;
}

#carousel-container {
  position: absolute;
  background-color: white;
  padding: 50px 20px;
  z-index: 1000000;
  border: 1px solid #aaa;
  border-radius: 10px;
  top: calc(50% - 350px);
  left: calc(50% - 440px);
}

#carrousel-footer {
  width: 100%;
  height: 100%;
  position: relative;
  top: 20px;
}
#carrousel-footer label {
  margin-right: 10px;
}

.carousel-indicators .active {
  margin: 0 10px !important;
}

.carousel-indicators li {
  margin: 0 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-list/people-list.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AACJ;AAAI;EACI,kBAAA;AAER;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACG,yBAAA;AACH","sourcesContent":["::ng-deep .ng-sidebar__content {\n    position: static !important;\n}\n\n#myCarousel {\n    display: block;\n    position: relative;\n    width: 800px;\n    height: 600px;\n    margin: auto;\n    z-index: 1000000;\n    background-color: white\n}\n\n#carousel-container {\n    position: absolute;\n    background-color: white;\n    padding: 50px 20px;\n    z-index: 1000000;\n    border: 1px solid #aaa;\n    border-radius: 10px;\n    top: calc(50% - 350px);\n    left: calc(50% - 440px);\n}\n\n#carrousel-footer {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    top: 20px;\n    label {\n        margin-right: 10px;\n    }\n}\n\n.carousel-indicators .active {\n    margin: 0 10px!important;\n}\n\n.carousel-indicators li {\n   margin: 0 10px!important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
