import {ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-satellite-score-graph',
    templateUrl: './satellite-score-graph.component.html',
    styleUrls: ['./satellite-score-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SatelliteScoreGraphComponent implements OnChanges, OnInit, OnDestroy {

    @ViewChild('SatelliteScoreGraph', {static: true}) private chartContainer: ElementRef;
    @Input() score: any;
    @Input() satelliteInfo: any;
    @Input() request = false;
    @Input() requestArr: any;

    private subscriptions = new Subscription();

    constructor(
        public translate: TranslateService,
        public stateService: StateService
    ) {
    }

    ngOnInit() {
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            () => { this.createSatelliteScoreGraph(); }
        ));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.request) {
            this.request = false;
        }
        this.createSatelliteScoreGraph();
    }

    createSatelliteScoreGraph() {
        d3.select('#SatelliteScoreGraph').remove();
        // console.log(this.score);
        let inactive = (!this.score || this.score === 'noJobProfile');
        let textCategory = '';
        let inactiveTxt = [];
        if (this.score && inactive) {
            if (this.score === 'noJobProfile') {
                inactiveTxt[0] = this.translate.instant('commons.choose');
                inactiveTxt[1] = this.translate.instant('commons.aJob');
            }
        } else if (this.score) {
            textCategory = this.score;
        } else {
            inactiveTxt[0] = this.translate.instant('commons.not');
            inactiveTxt[1] = this.translate.instant('commons.completed');
            if ((this.requestArr && !this.requestArr.includes('satellite')) || !this.stateService.hasSatellite) {
                inactiveTxt[0] = this.translate.instant('commons.none');
                inactiveTxt[1] = null;
            }
            // in case of an active satellite report without associated job
            if (this.satelliteInfo && this.satelliteInfo.status) {
                inactiveTxt[0] = this.translate.instant('people.reportSatellite.chooseAJobPart1');
                inactiveTxt[1] = this.translate.instant('people.reportSatellite.chooseAJobPart2');
            }
        }
        let element = this.chartContainer.nativeElement;
        let svg = d3.select(element).append('svg').attr('id', 'SatelliteScoreGraph').attr('preserveAspectRatio', 'xMidYMid meet').
            attr('width', '100%').
            attr('height', '100%').
            attr('viewBox', '0 0 140 140');

        let endAngle = 2 * Math.PI * this.score / 5;
        if (this.score === 0) {
            endAngle = 0.1;
        }


        let arcBG = d3.arc().innerRadius(57).outerRadius(70).startAngle(0).endAngle(2 * Math.PI);
        svg.append('path').attr('transform', 'translate(70,70)').attr('class', 'arc').attr('d', arcBG).attr('fill', '#CCC');

        if (!inactive) {
            let arc = d3.arc().innerRadius(57).outerRadius(70).startAngle(0).endAngle(-endAngle);
            svg.append('path').attr('transform', 'translate(70,70)').attr('class', 'arc').attr('d', arc).attr('fill', '#888');
        }
        if (inactive) {
            svg.append('text').attr('x', 70).
            attr('y', inactiveTxt[1] ? 65 : 75).
            text(inactiveTxt[0]).
            attr('text-anchor', 'middle').
            attr('font-family', 'sans-serif').
            attr('font-size', 20).
            attr('fill', '#888');

            if (inactiveTxt[1]) {
                svg.append('text').attr('x', 70).
                attr('y', 85).
                text(inactiveTxt[1]).
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 20).
                attr('fill', '#888');
            }
        } else {
            svg.append('text').attr('x', 70).
            attr('y', 80).
            text(textCategory).
            attr('text-anchor', 'middle').
            attr('font-family', 'sans-serif').
            attr('font-size', 40).
            attr('fill', 'rgb(227, 94, 36)');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
