import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {Tools} from '../../../shared/misc/tools';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
    selector: 'app-administration-move-credit-modal',
    templateUrl: './administration-move-credit-modal.component.html',
    styleUrls: ['./administration-move-credit-modal.component.scss']
})
export class MoveCreditModalComponent implements OnInit, OnDestroy {
    // ToDo: account model
    @Input() account;

    public close: EventEmitter<any> = new EventEmitter();
    public editRefresh: EventEmitter<any> = new EventEmitter();

    @ViewChild('moveCreditNgForm') moveCreditNgForm;
    moveCreditForm: UntypedFormGroup;
    sessionStructure: any = {};
    errorForm: any;
    validateFormField = Tools.validateFormField;

    doDisplayMaxCredits = false;
    fromCredits: number;

    lowCredits = false;

    private subscriptions = new Subscription();

    submitted = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        public bsModalRef: BsModalRef,
        private stateService: StateService,
        private apiAdministration: ApiAdministrationService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.stateService.session.sessionDataWatch$.subscribe(
            (res) => {
                if (res) {
                    this.sessionStructure = this.stateService.session.sessionData.structure;
                    // console.log(this.sessionStructure);
                }
            }
        ));

        this.initForm();
    }

    closeModal() {
        this.resetForm();
        this.close.emit({closed: true});
        this.bsModalRef.hide();
    }

    displayMaxCredits() {
        let subAccountIndex = parseInt(this.moveCreditForm.get('subAccountFrom').value, 10);
        console.log(this.account.subAccounts, subAccountIndex);
        if (_.find(this.account.subAccounts, {id: subAccountIndex})['credits']) {
            this.doDisplayMaxCredits = true;
            this.fromCredits = _.find(this.account.subAccounts, {id: subAccountIndex})['credits'];
            this.changeDetectorRef.markForCheck();
        } else {
            return;
        }
    }

    formCheck(specificField = null) {
        if (this.moveCreditNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.moveCreditForm.controls[specificField], this.moveCreditNgForm)) {
                    return true;
                }
            } else {
                let fields = ['subAccountFrom', 'subAccountTo', 'credits'];
                for (let field of fields) {
                    if (this.validateFormField(this.moveCreditForm.controls[field], this.moveCreditNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.doDisplayMaxCredits = false;
        this.fromCredits = null;
        this.submitted = false;
        this.moveCreditForm = this.fb.group({
            subAccountFrom: ['', [Validators.required]],
            subAccountTo: ['', [Validators.required]],
            credits: ['', [Validators.required]],
            clientId: [this.account.id, [Validators.required]]
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (model.valid) {
            let data = model.value;

            // call api to save the modification
            this.subscriptions.add(this.apiAdministration.moveCredit(data).subscribe(
                () => {
                    this.stateService.admin.stateChanged.next({refreshComponent: true});
                    this.closeModal();
                },
                error => {
                    this.errorForm = error.error;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    computeRemainingCredits() {
        if (this.fromCredits - this.moveCreditForm.get('credits').value < 0) {
            this.lowCredits = true;
            return;
        }
        this.lowCredits = false;
        return;
    }

    // ToDo: get subAccounts -> map to remove unlimited subAccounts

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.doDisplayMaxCredits = false;
            this.fromCredits = null;
            this.submitted = false;
            // this.errorForm = null;
            this.moveCreditForm.reset();
            this.moveCreditNgForm.submitted = false;
            this.initForm();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}
