export class JobSatisfactionTeamGraphHelper {
    public static setData(records, months) {
        if (records.length === 0) {
            return;
        }
        // Compute expired prbs


        // Set data per se
        const extremalDates = this.getExtremalDates(records);
        let dataStructure = [];

        // loop through all months between min and max date

        // for every month between min and max date initialize object with 0
        for (let i = extremalDates.minDate; i <= extremalDates.maxDate; i.setMonth(i.getMonth() + 1)) {
            i = new Date(this.resetToTheFirstOfTheMonth(i));
            dataStructure.push({
                date: this.truncateToMonths(i.toISOString().split('T')[0]) + '-01',
                amount: 0,
                bruteScore: null,
                percent: null
            });
        }

        for (let i = 0; i < records.length; i++) {
            let record = records[i];
            let prbs = record.prb;
            let jobSatisfactionScores = [];
            for (let j = 0; j < prbs.length; j++) {
                jobSatisfactionScores.push({
                    date: this.truncateToMonths(prbs[j].date.split('T')[0]) + '-01',
                    score: this.teamBruteScore(prbs[j].jobSatisfactionScore)
                });
            }
            jobSatisfactionScores.reverse();
            let jobSatisfactionScoresCurrentIndex = 0;
            let currentScoreToAdd = 0;
            let personToAdd = 0;
            let firstPass = false;
            let lastScore = 0;
            for (let j = 0; j < dataStructure.length; j++) {
                if (
                    jobSatisfactionScores[jobSatisfactionScoresCurrentIndex] &&
                    dataStructure[j].date === jobSatisfactionScores[jobSatisfactionScoresCurrentIndex].date) {
                    firstPass = true;
                    currentScoreToAdd = jobSatisfactionScores[jobSatisfactionScoresCurrentIndex].score;
                    lastScore = 0;
                    if (jobSatisfactionScoresCurrentIndex > 0) {
                        lastScore = jobSatisfactionScores[jobSatisfactionScoresCurrentIndex - 1].score;
                    }
                    if (jobSatisfactionScoresCurrentIndex === 0) {
                        personToAdd = 1;
                    }
                    jobSatisfactionScoresCurrentIndex++;
                }
                dataStructure[j].amount += personToAdd;
                dataStructure[j].bruteScore += currentScoreToAdd;
                dataStructure[j].percent = 4 * (dataStructure[j].bruteScore / (5 * dataStructure[j].amount));
                firstPass = false;
            }
        }

        let toAdd = months - (dataStructure.length % months);

        // Set values for empty objects
        let tmpStructure = [];
        if (toAdd !== months) {
            let lastDateArray = dataStructure[0].date.split('-');
            let month = Number(lastDateArray[1]);
            let year = Number(lastDateArray[0]);
            for (let add = 0; add < toAdd; add++) {
                month = (month === 1) ? 12 : month - 1;
                year = (month === 12) ? year - 1 : year;
                tmpStructure.unshift({
                    date: year + '-' + ((month < 10) ? '0' + month : month) + '-01',
                    amount: null,
                    bruteScore: null,
                    percent: null
                });
            }
        }
        dataStructure = tmpStructure.concat(dataStructure);

        // split array dataStructure in an array of arrays of length 12
        let dataStructureSplitted = [];
        for (let i = 0; i < dataStructure.length; i += months) {
            dataStructureSplitted.push(dataStructure.slice(i, i + months));
        }
        // this.setDataBack(dataStructureSplitted[dataStructureSplitted.length - 1]);
        return dataStructureSplitted;
    }

    public static setDataBack(data = []) {
        let dataString = '';
        for (let i = 0; i < 12; i++) {
            dataString += data[i].date + '+' + (Math.round(data[i].percent * 100) / 100);
            if (i !== 11) {
                dataString += ',';
            }
        }
        return dataString;
    }

    private static resetToTheFirstOfTheMonth(date: any, type = 'date') {
        if (type === 'date') {
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        } else {
            return date.split('-')[0] + '-' + date.split('-')[1] + '-01';
        }
    }

    private static getExtremalDates(records) {
        let minDate: any = this.getFormatedDate(new Date());
        let maxDate: any = this.getFormatedDate(new Date());
        for (let i = 0; i < records.length; i++) {
            if (records[i].prb) {
                for (let j = 0; j < records[i].prb.length; j++) {
                    if (records[i].prb[j] && records[i].prb[j].date) {
                        let date = this.getFormatedDate(new Date(records[i].prb[j].date));
                        if (date < minDate) {
                            minDate = this.getFormatedDate(new Date(records[i].prb[j].date));
                        }
                    }
                }
            }
        }

        minDate = new Date(Date.UTC(Number(minDate.split('-')[0]),
            Number(minDate.split('-')[1]), // no Minus one because month starts at 0 with Date()
            1));
        maxDate = new Date(Date.UTC(Number(maxDate.split('-')[0]),
            Number(maxDate.split('-')[1]), // no Minus one because month starts at 0 with Date()
            1));

        return {
            minDate: minDate,
            maxDate: maxDate
        };
    }

    private static getFormatedDate(date: Date) {
        return date.getUTCFullYear() + '-' +
            this.addTrailingZeros(date.getUTCMonth() + 1) + '-' +
            this.addTrailingZeros(date.getUTCDate());
    }

    private static truncateToMonths(date: string) {
        return date.split('-')[0] + '-' + date.split('-')[1];
    }

    private static teamBruteScore(score) {
        switch (score) {
            case 2:
                return 5;
            case 1:
                return 2.5;
            case 0:
                return 0;
            default:
                return 0;
        }
    }

    private static addTrailingZeros(n) {
        if (n < 10) {
            return '0' + n;
        } else {
            return '' + n;
        }
    }
}
