import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";

@Injectable()
export class DynamicFormService {

    constructor(private fb: UntypedFormBuilder) {
    }

    /**
     * Intialise the form
     *
     * @param formDefinition
     * @returns {FormGroup}
     */

    initForm(formDefinition) {
        return this.fb.group(
            DynamicFormService.buildForm(formDefinition)
        );
    }

    /**
     * Iterate throught the JSON and produce the input for a FormGroup in
     * FormBuilder
     *
     * @param formDefinition
     * @returns {{}}
     */

    static buildForm(formDefinition) {
        let form: any;
        form = formDefinition;
        let fb = {};
        for (let column of form.structure) {
            for (let line of column) {
                for (let item of line) {
                    let name = item.name;
                    fb[name] = [item.initialValue];
                    if (item.validators !== undefined) {
                        for (let validator of item.validators) {
                            if (validator == 'required') {
                                fb[name][1] = Validators.required;
                            }
                        }
                    }
                }
            }
        }
        return fb;
    }

}
