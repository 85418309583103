import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({
    selector: '[appOptionInput]'
})
export class OptionInputDirective implements OnInit {

    @Input() definition: any;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.value = this.definition.value;
        if (this.definition.selected !== undefined && this.definition.selected === true) {
            this.el.nativeElement.selected = true;
        }
    }
}