// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-danger {
  margin-left: 15px;
  margin-top: 2px;
}

::ng-deep .formWrapper .mat-mdc-form-field {
  width: 100%;
}

::ng-deep .formWrapper .mat-mdc-grid-tile .mat-mdc-figure {
  justify-content: flex-end;
}

::ng-deep .formWrapper .mat-mdc-grid-tile .mat-mdc-figure button:first-child {
  margin-right: 10px;
}

#wrapper {
  height: 100vh;
  overflow-y: scroll;
}

#candidateList {
  height: 400px;
  width: 100%;
  overflow-y: scroll;
}

#candidateList table tr td.nameCell {
  max-width: calc(100% - 75px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mdc-checkbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  height: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/jobSatisfactions/components/mobile/mobile-job-satisfactions-edit/mobile-job-satisfactions-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,kBAAA;AACJ;;AAGA;EACI,aAAA;EACA,WAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,4BAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,4BAAA;AAAJ;AACI;EACG,wBAAA;AACP","sourcesContent":[".text-danger {\n    margin-left: 15px;\n    margin-top: 2px;\n}\n\n::ng-deep .formWrapper .mat-mdc-form-field {\n    width: 100%;\n}\n\n::ng-deep .formWrapper .mat-mdc-grid-tile .mat-mdc-figure {\n    justify-content: flex-end;\n}\n\n::ng-deep .formWrapper .mat-mdc-grid-tile .mat-mdc-figure button:first-child {\n    margin-right: 10px;\n}\n\n#wrapper {\n    height: 100vh;\n    overflow-y: scroll;\n}\n\n\n#candidateList {\n    height: 400px;\n    width: 100%;\n    overflow-y: scroll;\n}\n\n#candidateList table tr td.nameCell {\n    max-width: calc(100% - 75px);\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.mdc-checkbox {\n    padding-top: 0!important;\n    padding-bottom: 0!important;\n    .mdc-checkbox .mdc-checkbox__native-control {\n       height: unset!important;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
