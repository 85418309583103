import { AccountComponent } from './components/administration-account/administration-account.component';
import { AdminFilesModalComponent } from './components/administration-files-modal/administration-files-modal.component';
import { AdministrationAccountFormComponent } from './components/administration-account-form/administration-account-form.component';
import { AdministrationAccountFormFieldComponent } from './components/administration-account-form-field/administration-account-form-field.component';
import { AdministrationAccountFormFieldHierarchyComponent } from './components/administration-account-form-field-hierarchy/administration-account-form-field-hierarchy.component';
import { AdministrationAccountLeftSidebarComponent } from './components/administration-account-left-sidebar/administration-account-left-sidebar.component';
import { AdministrationCommonListComponent } from './components/administration-common-list/administration-common-list.component';
import { AdministrationDepartmentModalComponent } from './components/administration-department-modal/administration-department-modal.component';
import { AdministrationFilesListComponent } from './components/administration-files-list/administration-files-list.component';
import { AdministrationListComponent } from './components/administration-list/administration-list.component';
import { AdministrationListSearchFiltersComponent } from './components/administration-list-search-filters/administration-list-search-filters.component';
import { AdministrationListTransactionsComponent } from './components/administration-list-transactions/administration-list-transactions.component';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationSidebarComponent } from './components/administration-sidebar/administration-sidebar.component';
import { AdministrationSubaccountsListComponent } from './components/administration-subaccounts-list/administration-subaccounts-list.component';
import { AdministrationTransactionsEditModalComponent } from './components/administration-transactions-edit-modal/administration-transactions-edit-modal.component';
import { AdministrationTransactionsFilterModalComponent } from './components/administration-transactions-filter-modal/administration-transactions-filter-modal.component';
import { AdministrationUserFormFieldComponent } from './components/administration-user-form-field/administration-user-form-field.component';
import { AdministrationUserLeftSidebarComponent } from './components/administration-user-left-sidebar/administration-user-left-sidebar.component';
import { AdministrationUsersListComponent } from './components/administration-users-list/administration-users-list.component';
import { ApiAdministrationService } from './services/api-administration.service';
import { ApiUploadFiles } from '../core/services/api/api-upload-files';
import { CommonModule } from '@angular/common';
import { DeleteSubAccountModalComponent } from './components/administration-delete-subaccount-modal/administration-delete-subaccount-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphboardsModule } from '../analysis/graphboards/graphboards.module';
import { IdwMatricesModule } from '../analysis/idw-matrices/idw-matrices.module';
import { JobsModule } from '../jobs/jobs.module';
import { MainListAdministrationService } from './services/main-list-administration.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MobileAdministrationListComponent } from './components/mobile/mobile-administration-list/mobile-administration-list.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MomentDateLangPipe, MomentDatePipe, Nl2BrPipe } from '../shared/barrels/pipes';
import { MoveCreditModalComponent } from './components/administration-move-credit-modal/administration-move-credit-modal.component';
import { NgenioFormsModule } from '../shared/forms/ngenio-forms.module';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxLoadingModule } from 'ngx-loading';
import { PeopleModule } from '../people/people.module';
import { PurchaseAdjustementModalComponent } from './components/administration-purchase-adjustement-modal/administration-purchase-adjustement-modal.component';
import { PurchaseProductModalComponent } from './components/administration-purchase-product-modal/administration-purchase-product-modal.component';
import { RightMatchesModule } from '../analysis/right-matches/right-matches.module';
import { SharedModule } from '../shared/shared.module';
import { SidebarModule } from 'ng-sidebar';
import { ToolsModule } from '../tools/tools.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UserDetailComponent } from './components/administration-user/administration-user.component';
import { UserFormComponent } from './components/administration-user-form/administration-user-form.component';
import { UserFormDefinitionService } from './services/user-form-definition.service';
import { UserFormInitService } from './services/user-form-init.service';
import { UserPreferencesComponent } from './components/administration-user-preferences/administration-user-preferences.component';
import { UserPreferencesService } from './services/user-preferences.service';
import { AdministrationDepartmentsListComponent } from './components/administration-departments-list/administration-departments-list.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        AdministrationRoutingModule,
        CommonModule,
        FormsModule,
        MatGridListModule, MatCheckboxModule,
        ModalModule.forRoot(),
        NgenioFormsModule,
        NgMultiSelectDropDownModule,
        NgxLoadingModule,
        PeopleModule, JobsModule, RightMatchesModule, IdwMatricesModule, GraphboardsModule,
        ReactiveFormsModule,
        SharedModule,
        SidebarModule.forRoot(),
        ToolsModule,
        TooltipModule.forRoot(),
        TranslateModule,
        NgxLoadingModule,
        TooltipModule.forRoot(),
        SidebarModule.forRoot(),
        MatGridListModule, MatCheckboxModule,
        ToolsModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatIconModule
    ],
    declarations: [
        AccountComponent,
        AdminFilesModalComponent,
        AdministrationAccountFormComponent,
        AdministrationAccountFormComponent,
        AdministrationAccountFormFieldComponent,
        AdministrationAccountFormFieldHierarchyComponent,
        AdministrationAccountLeftSidebarComponent,
        AdministrationCommonListComponent,
        AdministrationDepartmentModalComponent,
        AdministrationDepartmentsListComponent,
        AdministrationFilesListComponent,
        AdministrationListComponent,
        AdministrationListSearchFiltersComponent,
        AdministrationListTransactionsComponent,
        AdministrationSidebarComponent,
        AdministrationSubaccountsListComponent,
        AdministrationTransactionsEditModalComponent,
        AdministrationTransactionsFilterModalComponent,
        AdministrationUserFormFieldComponent,
        AdministrationUserLeftSidebarComponent,
        AdministrationUsersListComponent,
        DeleteSubAccountModalComponent,
        MobileAdministrationListComponent,
        MomentDateLangPipe,
        MomentDatePipe,
        MoveCreditModalComponent,
        Nl2BrPipe,
        PurchaseAdjustementModalComponent,
        PurchaseProductModalComponent,
        UserDetailComponent,
        UserFormComponent,
        UserPreferencesComponent
    ],
    providers: [
        ApiAdministrationService,
        ApiUploadFiles,
        MainListAdministrationService,
        UserFormDefinitionService,
        UserFormInitService,
        UserPreferencesService
    ]
})
export class AdministrationModule {
}
