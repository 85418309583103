// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

file-drop ::ng-deep #dropZone {
  border: 2px dotted #666666;
  border-radius: 6px;
}

file-drop ::ng-deep #dropZone .content span {
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-files-modal/administration-files-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n\nfile-drop ::ng-deep #dropZone {\n    border: 2px dotted #666666;\n    border-radius: 6px;\n}\n\nfile-drop ::ng-deep #dropZone .content span {\n    color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
