import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {take} from 'rxjs/operators';
import {ApiJobsService} from '../../../jobs/services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {PersonService} from '../../../people/services/person.service';

@Component({
    selector: 'app-iac-sidebar-graph',
    templateUrl: './iac-sidebar-graph.component.html',
    styleUrls: ['./iac-sidebar-graph.component.scss']
})
export class IacSidebarGraphComponent implements OnChanges {

    @Input() jobId;
    @Input() bruteScore;
    @Input() answeredQuestions;
    @Input() time;
    @Input() normalizedScore;

    jobIacScore;
    jobTitle;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiJobsService: ApiJobsService,
        private translateService: TranslateService,
        public person: PersonService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.jobId) {
            this.jobInfo(changes.jobId.currentValue);
        }
    }

    jobInfo(jobId) {
        this.jobIacScore = undefined;
        this.jobTitle = undefined;
        if (jobId) {
            this.apiJobsService.job([jobId]).pipe(take(1)).subscribe(
                res => {
                    if (res) {
                        this.jobIacScore = res['RA'];
                        this.jobTitle = res.jobTitle;
                    }
                    this.changeDetectorRef.markForCheck();
                }
            );
        }
    }

    chooseAJob() {
        if (this.translateService.currentLang === 'fr') {
            return 'Choisir un poste';
        } else {
            return 'Choose a job';
        }

    }

    get noTest() {
        return (
            (typeof this.bruteScore === 'undefined' || this.bruteScore === null) &&
            (typeof this.normalizedScore === 'undefined' || this.normalizedScore === null)
        );
    }

    get noTestTitle() {
        if (this.person.hasIacRequest) {
            return this.translateService.instant('people.commons.notCompleted');
        }
        if (!this.person.iacReport) {
            return this.translateService.instant('commons.none');
        }
        if (!this.person.hasIacOnHold) {
            return this.translateService.instant('commons.onHold');
        }
    }

}
