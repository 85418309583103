import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-main-tools',
    templateUrl: './main-tools.component.html',
    styleUrls: ['./main-tools.component.scss']
})
export class MainToolsComponent implements OnInit {
    @Input() section;
    actualTool = 'profileSearch';

    constructor() { }

    ngOnInit() {
    }

    switchTools(newTool) {
        this.actualTool = newTool;
    }

}
