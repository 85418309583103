import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {RightMatchListService} from '../../../services/right-match-list.service';
import {ComponentFullView} from '../../../../../shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-mobile-right-matches-report',
  templateUrl: './mobile-right-matches-report.component.html',
  styleUrls: ['./mobile-right-matches-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileRightMatchesReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    pdfRequest = false;
    editRequest = false;
    graphWidth: number;
    graphHeight: number;
    isLandscape = false;
    showPortraitButtons = false;
    idleTime = 0;
    // idleInterval;

    public actualPage = 1;
    public gActualPage: any = {};
    public maxPage = 1;

    maxPerPage = 7;

    sortResult = 6;
    addingPanelOpenState = false;
    infoPanelOpenState = false;
    evaluationTypes: Array<string> = ['analysis', 'MPOTargetProfile', 'competenciesTarget', 'jobDescription'];
    titleSep: string;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        protected router: Router,
        private apiRightMatches: ApiRightMatchesService,
        private changeDetectorRef: ChangeDetectorRef,
        public rightMatch: RightMatchAnalysisService,
        public rightMatchService: RightMatchListService,
        public translate: TranslateService,
        public stateService: StateService,
        protected deviceService: DeviceDetectorService,
    ) {
            super(stateService, translate, router, deviceService);
    }

    @HostListener('window:mousemove') refreshUserState() {
        this.idleTime = 0;
    }

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
        // if (this.showPortraitButtons) {
        //     this.idleInterval = setInterval(() => {
        //         this.idleTime++;
        //         if (this.idleTime > 3) { // 3 sec
        //             this.showPortraitButtons = false;
        //             clearInterval(this.idleInterval);
        //             this.changeDetectorRef.markForCheck();
        //         }
        //     }, 1000);
        // }
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    goToPage(factor, type = null) {
        if (!type) {
            this.actualPage = this.actualPage + factor;
        } else {
            if (!this.gActualPage[type]) {
                this.gActualPage[type] = 0;
            }
            this.gActualPage[type] += factor;
        }
    }

    ngOnInit() {
        this.graphWidth = window.innerWidth;
        this.graphHeight = this.graphWidth / 2.4256;
        this.graphHeight = +this.graphHeight.toFixed(2);
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;

        if (this.rightMatchState.goTo) {
            // reload the Right Match context after coming back from jobs
            this.rightMatch.setItemToDisplayWithId(this.rightMatch.id);
            this.rightMatchState.goTo = null;
        }

        let thisObject = this;
        window.addEventListener('orientationchange', function() {
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            // set the graph width by the previous window height
            thisObject.graphWidth = window.innerHeight;
            thisObject.graphHeight = thisObject.graphWidth / 2.4256;
            thisObject.graphHeight = +thisObject.graphHeight.toFixed(2);
            thisObject.changeDetectorRef.markForCheck();
        }, false);

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);

        if (this.rightMatch.itemToDisplay) {
            this.rightMatchService.setAmount(this.rightMatch.records);
            // Set data tab to show by default
            this.switchEvaluationType(this.rightMatchState.selectedTab);
        }

        this.stateService.rightMatch.stateChanged$.subscribe(
            (res) => {
                if (res && res.itemToDisplayId) {
                    this.rightMatchService.setAmount(this.rightMatch.records);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );

        let divs = Array.from(document.querySelectorAll('body > div:not(#menu_wrapper)')) as unknown as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style.overflowY = 'hidden';
        }
        document.getElementById('scrollBody').style.overflowY = 'scroll';
    }

    openPanel() {
        this.addingPanelOpenState = true;
    }

    openInfoPanel() {
        this.infoPanelOpenState = true;
    }

    closePanel() {
        this.addingPanelOpenState = false;
    }

    closeInfoPanel() {
        this.infoPanelOpenState = false;
    }

    switchEvaluationType(index: number) {
        this.rightMatchState.report.reportType = this.evaluationTypes.find((e, i) => {
            if (i === index) {
                this.rightMatchState.selectedTab = index;
                this.focusTab(index);
                return e;
            } else {
                return;
            }
        });
    }

    focusTab(index) {
        // for a tab that's not the first one
        if (index > 0) {
            // we have to put the focus on a half-hidden tab to make it fully visible
            setTimeout(() => {
                let tabsGroup = document.getElementsByClassName('rightMatchesTabs')[0];
                if (tabsGroup) {
                    let activeTab = <HTMLElement>tabsGroup.getElementsByClassName('mat-tab-label-active')[0];
                    activeTab.click();
                }
            });
        }
    }

    sortResults(event) {
        this.sortResult = event;
        this.rightMatchState.report.reportType = 'analysis';
        this.changeDetectorRef.markForCheck();

    }

    itemEdit() {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.editRequest = false;
    }

    closeAllRequestsPanels() {
        this.pdfRequest = false;
        this.editRequest = false;
    }

    get rightMatchState(): any {
        return this.stateService.rightMatch;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
