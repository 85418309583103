import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavigationService {
    switchSubAccountName$: BehaviorSubject<string> = new BehaviorSubject('');

    public updateSwitchSubAccountName(subAccountName: string): void {
        this.switchSubAccountName$.next(subAccountName);
    }
}
