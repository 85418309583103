import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PeopleList } from '../../../services/people_list.service';
import { ComponentList } from 'src/app/shared/classes/components/component_list';
import { StateService } from 'src/app/core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { PeopleSearchFiltersService } from '../../../services/people-search-filters.service';
import { PersonService } from '../../../services/person.service';
import { ApiPeopleService } from '../../../services/api-people.service';
declare var $: any;

@Component({
	selector: 'app-mobile-people-list',
	templateUrl: './mobile-people-list.component.html',
	styleUrls: ['./mobile-people-list.component.scss'],
	animations: [
		trigger('selected', [
			state('none', style({
				display: 'none',
				transform: 'scale(0)'
			})),
			state('some', style({
				display: '',
				transform: 'scale(1)'
			})),
			transition('none => some', animate('120ms ease-in')),
			transition('some => none', animate('120ms ease-out'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeopleListComponent extends ComponentList implements OnInit, OnDestroy, AfterViewInit {
	showSurvey = false;
	logoImg = '/assets/menu_gauche/MPO_32px.png';
	totalItems = 0;
	public orderedBy;

	private subscriptions = new Subscription();

	// showCarousel = true;
	// basePath: string;
	// dontShowAgain: boolean = false;

	constructor(
		public person: PersonService,
		public peopleList: PeopleList,
		protected translate: TranslateService,
		protected stateService: StateService,
		protected router: Router,
		protected route: ActivatedRoute,
		private apiPeople: ApiPeopleService,
		private changeDetectorRef: ChangeDetectorRef,
		protected deviceService: DeviceDetectorService,
		public peopleSearchFilters: PeopleSearchFiltersService
	) {
		super(stateService, translate, router, deviceService);
		this.peopleList.checkReportType();
		this.orderedBy = this.peopleList.state.listColumns.filter(col => col.order !== undefined);
	}

	ngOnInit() {
		this.peopleList.checkArchiveSettings();
		this.peopleList.testAccessPermissions();

		// check items list without selection
		let initList = this.peopleList.getList({ listSearchFilters: {} }, 0,
			null, false, false, this.actualModule.archive);
		initList.listObservable.subscribe(list => {
			this.totalItems = list.count;
			this.changeDetectorRef.markForCheck();
		});

		this.peopleList.getRecordList(true);
		this.peopleList.state.stateChanged$.subscribe(
			() => {
				this.changeDetectorRef.markForCheck();
			}
		);

		// Translate list content when needed
		this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
			this.peopleList.getRecordList(true);
		}));

		// this.setBasePath();
		// this.translate.onLangChange.subscribe(() => {
		// 	this.setBasePath();
		// });
		// this.checkSawCarousel();

	}

	setOrder(event) {
		this.peopleList.setOrder(event);
		this.peopleList.getRecordList(true);
	}

	refreshList(): void {
		this.peopleList.getRecordList();
	}

	/**
	 * Set search parameter on event from shared/main-list/component/main-list-search-bar
	 * @param event
	 */
	setSearchFilter(event: string): void {
		this.peopleSearchFilters.setSearchFilter(event);
		this.peopleList.getRecordList(true);

		let activeElement = document.activeElement as HTMLElement;
		// remove focus from the search input
		activeElement.blur();

		// check the case when the button Search/Clear was clicked
		setTimeout(() => {
			if (document.activeElement.tagName === 'INPUT') {
				activeElement = document.activeElement as HTMLElement;
				activeElement.blur();
			}
		}, 0);
	}

	onSurveyRequest() {
		this.showSurvey = !this.showSurvey;
		if (!this.showSurvey) {
			this.refreshList();
		}
	}

	// setBasePath() {
	// 	const lang = this.translateService.currentLang || 'en'; // default to 'en' if not set
	// 	this.basePath = `assets/carousel/carousel-mobile/${lang}/`;
	// }

	// checkSawCarousel() {
	// 	const localStorageKey = 'sawCarousel';
	// 	const localAccepted = localStorage.getItem(localStorageKey);
	// 	// console.log('Check called:', localAccepted);

	// 	if (localAccepted === 'true') {
	// 		// console.log('inside localAccepted:', localAccepted);
	// 		// User has accepted terms previously
	// 		this.showCarousel = false;
	// 	} else {
	// 		// Check with the API
	// 		const apiAccepted = this.person.hasSawCarousel;
	// 		// console.log('inside else:', apiAccepted);
	// 		if (apiAccepted) {
	// 			// API confirms acceptance
	// 			this.showCarousel = false;
	// 			localStorage.setItem(localStorageKey, 'true');
	// 		}
	// 	}
	// }

	// closeCarousel() {
	// 	this.showCarousel = false;
	// 	// console.log('AI Carrousel closed.', this.showCarousel);
	// 	if (this.dontShowAgain) {
	// 		this.saveSawCarrousel();
	// 	}
	// }

	// saveSawCarrousel() {
	// 	const localStorageKey = 'sawCarousel';
	// 	localStorage.setItem(localStorageKey, 'true');

	// 	// Replace 'user-id' with the actual user identifier required by your API
	// 	const userId: string = this.stateService.session.sessionData.userData.id;
	// 	const urlParams: [string] = [userId];

	// 	this.subscriptions.add(
	// 		this.apiPeople.setSawAiCarousel(urlParams).subscribe(
	// 			(response) => {
	// 				console.log('AI Carrousel acceptance successfully saved to the backend.');
	// 			},
	// 			(error) => {
	// 				console.error('Error saving AI Carrousel acceptance to the backend:', error);
	// 			}
	// 		)
	// 	);
	// }

	// onCheckboxChange(event: Event) {
	// 	const checkbox = event.target as HTMLInputElement;
	// 	this.dontShowAgain = checkbox.checked;
	// 	// console.log('Checkbox state:', this.dontShowAgain);
	// }

	// enableSwipe() {
	// 	const myCarousel = document.getElementById('myCarousel');
	// 	if (myCarousel) {
	// 	  let touchStartX = 0;
	// 	  let touchEndX = 0;
	
	// 	  myCarousel.addEventListener('touchstart', (event) => {
	// 		touchStartX = event.changedTouches[0].screenX;
	// 	  });
	
	// 	  myCarousel.addEventListener('touchend', (event) => {
	// 		touchEndX = event.changedTouches[0].screenX;
	// 		this.handleSwipeGesture(touchStartX, touchEndX);
	// 	  });
	// 	}
	//   }
	
	//   handleSwipeGesture(touchStartX: number, touchEndX: number) {
	// 	const threshold = 50;
	// 	if (touchEndX < touchStartX - threshold) {
	// 	  $('#myCarousel').carousel('next');
	// 	}
	// 	if (touchEndX > touchStartX + threshold) {
	// 	  $('#myCarousel').carousel('prev');
	// 	}
	//   }

	ngAfterViewInit() {
		$('#myCarousel').carousel();
		// this.enableSwipe();
	}

	ngOnDestroy(): void {
		// this.peopleList.redirectTo = undefined;
		this.subscriptions.unsubscribe();
	}

}
