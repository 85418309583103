// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 25px 15px;
}

table#profilesSearchForm tr td {
  padding: 3px;
}

table#profilesSearchForm tr td input {
  width: 230px;
}

tr.record td {
  padding-right: 5px;
}

button[disabled] {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/tools/components/profile-search/profile-search.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".content {\n    padding: 25px 15px;\n}\n\ntable#profilesSearchForm tr td {\n    padding: 3px;\n}\n\ntable#profilesSearchForm tr td input {\n    width: 230px;\n}\n\ntr.record td {\n    padding-right: 5px\n}\n\nbutton[disabled] {\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
