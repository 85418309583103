import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

import {AbstractState} from './state';
import {RightMatchStateColumns} from '../../interfaces/state';
// import {isUndefined} from 'util';

export class StateArchivesRightMatch extends AbstractState {

    // Structural elements ///////////////////////////////////////////////////////////////////////
    //  TODO: Nombre de candidats

    readonly columns = ['name', 'date', 'subAccountName', 'profiler.jobTitle', 'profiler.location', 'consultant', 'departmentName'];
    readonly columnsName = {
        fr: {
            name: 'Nom',
            date: 'Date',
            subAccountName: 'Sous-compte',
            departmentName: 'Département',
            'profiler.jobTitle': 'Profil de poste',
            'profiler.location': 'Lieu',
            'consultant': 'Consultant'
        },
        en: {
            name: 'Name',
            date: 'Date',
            subAccountName: 'Sub-account',
            departmentName: 'Department',
            'profiler.jobTitle': 'Job profile',
            'profiler.location': 'Location',
            'consultant': 'Consultant'
        }
    };

    readonly filters = [
        'search',
        'rightMatchStartDate',
        'rightMatchEndDate',
        'subAccount',
        'department'
    ];

    public listColumnsInitialConfig: Array<RightMatchStateColumns> = [
        {key: 'name', display: true},
        {key: 'date', display: true, order: 'desc'}
    ];

    protected state: any = {
        sideBarStatus: 'recentActivities',
        report: {},
        listView: 'list',
        listSearchFilters: {},
        listColumns: this.listColumnsInitialConfig,
        informationVisibility: true
    };

    protected store: any = {
        multi: {
            action: '',
            idType: '',
            ids: [],
            data: []
        }
    };

    // TODO: added for refactoring

    get search(): string {
        return this.listSearchFilters.search;
    }

    // MULTISELECT ///////////////////////////////////////////////////////////////////////////////

    public multiselect = [];

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    multiselectWatch = new BehaviorSubject(null);
    multiselectWatch$ = this.multiselectWatch.asObservable();

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    constructor(
        private translate
    ) {
        super();
        // Initialization from sessionStorage
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('archivesRightMatch.' + String(item));
            }
        }
    }


    /**
     * Checks validity of the sent params and process data if needed.
     *
     * @param action
     * @param params
     * @param modState
     * @param url
     */
    protected actionPreProcessing(action, params, modState, url = null) {
        switch (action) {
            case 'refreshList':
                modState['listUpdate'] = '';
                return [url, modState];
            case 'resetSearchFilters':
                modState.listSearchFilters = {};
                modState.listColumns = this.listColumnsInitialConfig;
                if (!params['noRefresh']) {
                    modState['listUpdate'] = '';
                }
                return [url, modState];

            case 'setSearchFilters':
                for (let filter of this.filters) {
                    if (filter) {
                        // if (!isUndefined(params[filter])) {
                        //     modState.listSearchFilters[filter] = params[filter];
                        // }
                        modState.listSearchFilters[filter] = params[filter] ?? modState.listSearchFilters[filter];
                    }
                }
                modState['listUpdate'] = '';
                return [url, modState];

            case 'setListConfig':
                if (typeof(params.listView) !== 'undefined' &&
                    params.listView !== 'list' &&
                    params.listView !== 'cards'
                ) {
                    throw 'Illegal view for setter StateArchivesRightMatch._listView';
                }
                // View
                if (params.listView) {
                    modState.listView = params.listView;
                }
                // Display
                if (params['key'] && params['display'] != null) {
                    let index = _.findIndex(modState.listColumns, function(o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    if (index > -1) {
                        if (params['display']) {
                            modState.listColumns[index] = {
                                key: params['key'],
                                display: params['display']
                            };
                        } else {
                            modState.listColumns.splice(index, 1);
                        }
                    } else {
                        if (params['display']) {
                            modState.listColumns.push({
                                key: params['key'],
                                display: params['display']
                            });
                        }
                    }
                }
                if (params['key'] != null && params['order']) {
                    // tslint:disable-next-line:forin
                    let index = _.findIndex(modState.listColumns, function(o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    for (let indexRemoved in modState.listColumns) {
                        if (
                            modState.listColumns &&
                            modState.listColumns[indexRemoved] && (
                                modState.listColumns[indexRemoved]['order'] === 'asc' ||
                                modState.listColumns[indexRemoved]['order'] === 'desc'
                            )
                        ) {
                            delete modState.listColumns[indexRemoved]['order'];
                        }
                    }
                    let column = this.state.listColumns[index];
                    if (column) {
                        column['order'] = params['order'];
                        // console.log(column['order']);
                        modState.listColumns[index] = column;
                    }
                }
                // console.log(modState);
                modState['listUpdate'] = '';
                return [url, modState];

            case 'setSideBarStatus': // was sideBarStatus
                if (params.sideBarStatus !== 'recentActivities' && params.sideBarStatus !== 'searchFilters' &&
                    params.sideBarStatus !== 'clickMode') {
                    throw 'Illegal status for setter StateJobs.sideBarStatus';
                }
                modState['sideBarStatus'] = params.sideBarStatus;
                modState['itemToDisplayId'] = null;
                return [url, modState];

            case 'setReport':
                if (params.itemToDisplayId == null) {
                    throw 'itemToDisplayId must be non-null in setReport';
                }
                if (_.has(params, 'itemToDisplay')) {
                    _.unset(params, 'itemToDisplay');
                }
                modState.report = params;
                modState['sideBarStatus'] = 'reports';
                if (params.listUpdate) {
                    modState['listUpdate'] = '';
                    _.unset(params, 'listUpdate');
                }
                modState['itemToDisplayUpdate'] = '';
                return [url, modState];

            case 'getFullReport':
                modState.report = params;
                url = url + '/' + params.itemToDisplayId;
                return [url, modState];

            default:
                throw 'action "' + action + '" is inexistant on state-archives-rightMatch.preProcessing';
        }

    }


    /**
     * Partition state's parameter in function of the curent URL definitions i.e. if parameter x
     * is not required on URL y, then don't emit it, else do so.
     *
     * @param url
     * @param params
     */
    protected urlProcessing(url, params) {
        return params;
    }


    /**
     * Replace old state with the new state. Perform extra operations if needed.
     *
     * @param type
     * @param param
     * @param value
     */
    protected actionPostProcessing(type, param, value = null) {
        if (type === 'optionalParams') {
            switch (param) {
                case 'listUpdate':
                    this.stateChanged.next({listUpdate: true});
                    return false;
                case 'itemToDisplayUpdate':
                    this.stateChanged.next({itemToDisplayUpdate: true});
                    return false;
                case 'setSearchFilters':
                    this.stateChanged.next({setSearchFilters: true});
                    return false;
                default:
                    break;
            }
        }
        return true;
    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    get sideBarStatus(): string {
        return this.state.sideBarStatus;
    }

    set report(report) {
        this.state.report = report;
    }

    get report(): any {
        return this.state.report;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    set listSearchFilters(filters) {
        this.state.listSearchFilters = filters;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }

    set listColumns(cols) {
        this.state.listColumns = cols;
    }

    get multiAction(): any {
        return this.store.multi;
    }

    set multiAction(val) {
        this.store.multi = val;
    }

    resetMultiAction() {
        this.store.multi = {
            action: '',
            idType: '',
            ids: [],
            data: []
        };
    }

    get tableDefinition(): any {
        this.state.listColumns = _.uniqBy(this.state.listColumns, 'key');
        let listColumnsNonTrivial = _.compact(this.state.listColumns);
        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumnsInitialConfig;
        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            /** Todo use current language */
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }

}
