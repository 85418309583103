import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {ApiJobsService} from './api-jobs.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class JobService extends ContentItem {
    private job;
    private report;
    private lowScoreTalents;
    public loading = false;

    readonly persistentVariables = [
        'job',
        'report',
        'lowScoreTalents'
    ];

    constructor(
        private apiJobs: ApiJobsService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        private translateService: TranslateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('jobService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setItemToDisplayWithId(id, specificState = 'jobs', action = null) {
        let obsResult = {};
        this.loading = true;
        let jobObservable = this.apiJobs.job([id]).pipe(take(1)).subscribe(
            res => {
                if (res) {
                    this.job = res;
                    this.loading = false;
                    if (res.id) {
                        this.getReport(res.id, specificState);
                        this.getCompetencies(res.id, specificState);
                    }
                    this.storeServiceData('jobService', this.persistentVariables, this);
                    this.stateService[specificState].jobToDisplayId = res.id;
                    if (action === 'resetSidebarToReport' && this.isRightModule(specificState)) {
                        obsResult = this.resetSidebarToReport(specificState);
                    } else if (action === 'resetSidebarToFilters') {
                        this.stateService[specificState].sideBarStatus = 'searchFilters';
                    } // else {
                      //  this.stateService[specificState].sideBarStatus = 'recentActivities';
                    // }
                    obsResult['jobToDisplayId'] = res.id;
                    this.stateService[specificState].stateChanged.next(obsResult);
                    jobObservable.unsubscribe();
                    if (action === 'mobileView') {
                        this.router.navigate(['m/jobs/report/' + res.id + '/personality']).then(() => {});
                    }
                }
            }
        );
    }

    isRightModule(specificState) {
        if (specificState === 'jobs' && !this.archive) {
            return true;
        }
        return !!(specificState === 'archivesJobs' && this.archive);
    }

    getReport(jobId, specificState = 'jobs') {
        if (!jobId || jobId.trim() === '') {
            this.report = undefined;
        } else {
            let jobReportObservable = this.apiJobs.reportJob(
                [jobId],
                [
                    [1],
                    0, // Index of the evaluation object is (so far) always 0
                    this.translateService.currentLang
                ]).subscribe((res) => {
                    this.report = res;
                    this.stateService[specificState].stateChanged.next({report: res});
                    jobReportObservable.unsubscribe();
            });
        }
    }

    getCompetencies(jobId, specificState = 'jobs') {
        if (!jobId || jobId.trim() === '') {
            this.lowScoreTalents = undefined;
        } else {
            let jobCompetenciesObservable = this.apiJobs.competenciesLowScore({jobId: jobId}).subscribe((res) => {
                this.lowScoreTalents = res;
                this.stateService[specificState].stateChanged.next({competencies: res});
                jobCompetenciesObservable.unsubscribe();
            });
        }
    }

    isLowScoreTalent(number, lowerTierCompetencies = this.lowerTierCompetencies) {
        if (!lowerTierCompetencies) {
            lowerTierCompetencies = [];
        }
        return lowerTierCompetencies.includes(number);
    }

    resetItemToDisplay(specificState = 'jobs') {
        this.jobToDisplay = {};
        this.stateService[specificState].stateChanged.next({
            'itemDeleted': true
        });
        this.stateService[specificState].report.jobToDisplay = undefined;
        if (this.isMobile) {
            this.router.navigate(['m/analysis/jobs/list']).then(() => {});
        } else {
            this.stateService.jobs.sideBarStatus = 'recentActivities';
            this.stateService.archivesJobs.sideBarStatus = 'recentActivities';
        }
    }

    checkReportDisplay(reportType) {
        if (!this.state.report.display) {
            this.state.report.display = 'report';
        }
        if (!this.state.report.reportType) {
            if (this.score) {
                this.state.report.reportType = 'personality';
            } else if (this.competences) {
                this.state.report.display = 'report';
                this.state.report.reportType = 'talents';
            } else if (this.raScore) {
                this.state.report.display = 'report';
                this.state.report.reportType = 'ra';
            }
        }
        if (reportType && reportType !== this.state.report.reportType) {
            this.state.report.reportType = reportType;
        }
    }

    checkTask() {
        if (!this.jobDescription || !this.tasks || this.tasks.length === 0) {
            return false;
        } else {
            let jobToDisplay = this.jobToDisplay;
            jobToDisplay.jobDescription.tasks = _.orderBy(jobToDisplay.jobDescription.tasks, ['priority'], ['asc']);
            this.jobToDisplay = jobToDisplay;
            return true;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get jobToDisplay(): any {
        return _.get(this, 'job');
    }

    set jobToDisplay(item) {
        this.job = item;
        this.storeServiceData('jobService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'job.id');
    }

    get jobTitle(): string {
        return _.get(this, 'job.jobTitle');
    }

    get creationDate(): string {
        return _.get(this, 'job.creationDate');
    }

    get date(): string {
        return _.get(this, 'job.date');
    }

    get location(): string {
        return _.get(this, 'job.location');
    }

    get type(): string {
        return _.get(this, 'job.type');
    }

    get subAccount(): string {
        return _.get(this, 'job.subAccount');
    }

    get language(): string {
        return _.get(this, 'job.language');
    }

    get score(): any {
        return _.get(this, 'job.score');
    }

    get scoreCreationDate(): any {
        return _.get(this, 'job.scoreCreationDate');
    }

    get scoreModificationDate(): any {
        return _.get(this, 'job.scoreModificationDate');
    }

    get competences(): any {
        return _.get(this, 'job.competences');
    }

    get competencesCreationDate(): any {
        return (this.competences) ? _.get(this, 'job.competencesCreationDate') : null;
    }

    get competencesModificationDate(): any {
        return (this.competences) ? _.get(this, 'job.competencesModificationDate') : null;
    }

    get raScore(): any {
        return _.get(this, 'job.RA');
    }

    get raScoreCreationDate(): any {
        return (this.raScore) ? _.get(this, 'job.raCreationDate') : null;
    }

    get raScoreModificationDate(): any {
        return (this.raScore) ? _.get(this, 'job.raModificationDate') : null;
    }

    get jobDescription(): any {
        return _.get(this, 'job.jobDescription');
    }

    get tasks(): any {
        return _.get(this.jobDescription, 'tasks');
    }

    get purposeOfThePosition() {
        return _.get(this.jobDescription, 'purposeOfThePosition');
    }

    get department(): any {
        let department = _.get(this, 'job.department');
        if (department !== undefined && department.length > 0) {
            let currentLang = this.translateService.currentLang;
            if (currentLang === '' || currentLang === undefined) {
                currentLang = 'fr';
            }
            department = department[0][currentLang];
        }
        return department;
    }

    get jobReport(): any {
        return _.get(this, 'report');
    }

    get reportsTo(): string {
        return _.get(this, 'job.reportsTo');
    }

    get requiredBy(): string {
        return _.get(this, 'job.requiredBy');
    }

    get sharedToSubAccounts() {
        return _.get(this, 'job.sharedToSubaccounts');
    }

    get jobLowScoreTalents() {
        return _.get(this, 'lowScoreTalents');
    }

    get lowerTierCompetencies() {
        return _.get(this.jobLowScoreTalents, 'lowerTierCompetencies');
    }

    get archive() {
        return _.get(this, 'job.archive');
    }
}
