import {ComponentPrimitive} from './componentPrimitive';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

export abstract class ComponentFullView extends ComponentPrimitive {
    protected constructor(
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translateService,
            router,
            deviceService
        );
    }

    goToJob(id: string, reportBackId: string, moduleName: string, wasFor = null) {
        let reportBack;
        if (moduleName === 'people') {
            reportBack = {
                to: this.router.url,
                personToDisplayId: reportBackId,
                module: moduleName
            };
        } else {
            reportBack = {
                to: this.router.url,
                // id: reportBackId,
                module: moduleName,
                wasFor: wasFor
            };
        }
        this.stateService.jobs.reportBack = reportBack;
        this.stateService.jobs.report.display = 'report';
        this.stateService.jobs.report.reportType = 'mpo';
        this.stateService.jobs.report.jobToDisplayId = id;
        if (this.stateService[moduleName]) {
            this.stateService[moduleName].goTo = 'jobs';
        }
        if (!this.isMobile) {
            this.router.navigate(['jobs/report/' + id + '/mpo']).then();
        } else {
            this.router.navigate(['m/jobs/report/' + id + '/mpo']).then();
        }
    }
}
