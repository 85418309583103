import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../../core/services/state/state.service';
import * as _ from 'lodash';
import {EnvService} from '../../../../services/env.service';

@Component({
  selector: 'app-mobile-support',
  templateUrl: './mobile-support.component.html',
  styleUrls: ['./mobile-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSupportComponent implements OnInit {

    lang: string;
    phoneNumber: string;
    apiBaseUrl: string;
    logoImg = '/assets/menu_gauche/MPO_32px.png';

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        public stateService: StateService,
        private environment: EnvService
        ) {
        // Set current locale
        if (this.translate.getDefaultLang()) {
            this.lang = this.translate.getDefaultLang();
        } else {
            let lang = this.translate.getBrowserLang();
            if (lang !== 'fr' && lang !== 'en') {
                lang = 'en';
            }
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            this.lang = lang;
        }
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    ngOnInit() {
        this.phoneNumber = this.findPhoneNumber();
        if (this.apiBaseUrl && this.apiBaseUrl.length) {
            const lastChar = this.apiBaseUrl.length - 1;
            if (this.apiBaseUrl.substring(lastChar) === '/') {
                this.apiBaseUrl = this.apiBaseUrl.substring(0, lastChar);
            }
        }
    }

    navigateTo(link: [string]) {
        this.router.navigate(link).then(() => {});
    }

    findPhoneNumber() {
        let hierarchy = this.stateService.session.sessionData.accountHierarchy;

        let phoneNumber;
        // Subsidiary
        phoneNumber = _.result(_.find(hierarchy, function (o) {
            return o.accountType === 1;
        }), 'phone');
        // Distributor
        if (phoneNumber) {
            return phoneNumber;
        }
        phoneNumber = _.result(_.find(hierarchy, function (o) {
            return o.accountType === 2;
        }), 'phone');
        if (phoneNumber) {
            return phoneNumber;
        }
        // If all else fails: Ngenio
        return (this.translate.currentLang === 'fr') ? '514 990-4600 poste 125' : '514 990-4600 Ext. 125';
    }

    sendSupportMail() {
        let date: any = new Date();
        date = date.toDateString();
        let browser = window.navigator.userAgent;
        let suffix = `__________________________________%0A` +
            `Date: ${date}%0A` +
            `Browser: ${browser}`;


        let title = 'Support - Ngenioconnect 3';
        let body = `INSTRUCTIONS%0A%0A` +
            `- Précisez la section de l'application nécessitant du support ainsi qu'un résumé du problème dans la section titre.%0A` +
            `- Décrivez en détail la situation nécessitant du support ou la nature du problème dans la section description.%0A` +
            `- Si nécessaire, veuillez inclure des copies d'écran montrant la nature du problème.%0A%0A` +
            `TITRE%0A%0A%0A` + `DESCRIPTION%0A%0A%0A` + suffix;
        if (this.translate.currentLang === 'en') {
            title = 'Support - Ngenioconnect 3';
            body = `INSTRUCTIONS%0A%0A` +
                `- Specify the section of the application which requires support and a summary of the problem in the title ` +
                `section.%0A` +
                `- Describe in detail the situation requiring support or the nature of the problem in the description of the ` +
                `section.%0A` +
                `- If necessary, please include screenshots clarifying the nature of the problem.%0A%0A` +
                `TITRE%0A%0A%0A` + `DESCRIPTION%0A%0A%0A` + suffix;
        }

        window.location.href = 'mailto:info@ngenioworld.com?subject=' + title + '&body=' + body;

        this.changeDetectorRef.markForCheck();

    }

}
