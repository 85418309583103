import {AdminService} from '../../app/services/admin.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ComponentPrimitive} from '../../shared/classes/components/componentPrimitive';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';

export abstract class CommonsAdminComponent extends ComponentPrimitive {

    subscriptions = new Subscription();

    constructor(
        protected adminService: AdminService,
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    /**
     * Download file event
     */
    downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
     * Select file
     */
    onSelectFile(file, assets) {
        this.subscriptions.add(this.adminService.getImage({type: assets, filename: file.filename}).subscribe(
            data => {
                const urlData = 'data:' + file.mimeType + ';base64,' + data.image;
                this.downloadURI(urlData, file.originalName);
            }
        ));
    }

    mongoDateToDp(date, lang = 'fr') {

        if (date) {
            if (date.sec) {
                date = date.sec * 1000;
            }
            if (date.hasOwnProperty('sec') && !date.sec) {
                return;
            }
            return ('en' === lang) ?
                moment(new Date(date)).format('MM/DD/YYYY') :
                moment(new Date(date)).format('DD/MM/YYYY');
        } else {
            return;
        }
    }
}
