import {Component, EventEmitter, Input, OnDestroy} from '@angular/core';

import {ApiAdministrationService} from '../../services/api-administration.service';
import {ApiUploadFiles} from '../../../core/services/api/api-upload-files';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-administration-files-modal',
    templateUrl: './administration-files-modal.component.html',
    styleUrls: ['./administration-files-modal.component.scss']
})
export class AdminFilesModalComponent implements OnDestroy {
    @Input() accountId: string;
    @Input() userId: string;
    @Input() data: any;

    public close: EventEmitter<any> = new EventEmitter();
    public refresh: EventEmitter<any> = new EventEmitter();

    private onDestroy$: Subject<any> = new Subject();
    formData: FormData;
    fileToUpload: File = null;
    error = false;

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private apiAdministrationService: ApiAdministrationService,
        private apiUploadFiles: ApiUploadFiles
    ) {
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.close.emit({closed: true});
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    closeModal() {
        this.close.emit({closed: true});
        this.bsModalRef.hide();
        this.delay(1000).then(() => {
            this.fileToUpload = null;
        });
    }

    addFile(): void {
        let upload$: Observable<any>;
        // SubAccount file
        if (this.accountId) {
            upload$ = this.apiAdministrationService.addSubAccountFiles([this.accountId, this.data.subAccountId], this.formData);
        }

        // User file
        if (this.userId) {
            upload$ = this.apiAdministrationService.addUserFiles([this.userId], this.formData);
        }

        // API call
        const currentObject = this;
        upload$.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res && res.success) {
                    this.error = false;
                    currentObject.refresh.emit({success: res.success});
                    // close modal
                    currentObject.closeModal();
                } else {
                    this.error = true;
                }
            },
            () => {
                this.error = false;
            }
        );
    }

    fileInputHandling(files: FileList) {
        this.fileToUpload = files.item(0);
        this.formData = new FormData();
        this.formData.append('files[]', this.fileToUpload);
    }

    /**
     * Drag and drop methods:
     *
     public fileOver(event) {
         console.log(event);
     }

     public fileLeave(event) {
         console.log(event);
     }

     public dropped(event: UploadEvent) {
        this.files = event.files;
        this.formData = new FormData();
        for (let i = 0; i < event.files.length; i++) {
            event.files[i].fileEntry['file'](file => {
                this.formData.append('files[]', file, event.files[i].relativePath);
            });
        }
     }

     */
}
