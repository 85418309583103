import {Content} from './content';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';

export abstract class ContentItem extends Content {
    protected constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
    }

    resetSidebarToReport(specificState) {
        let obsResult = {};
        if (this.stateService[specificState].sideBarStatus !== 'report') {
            this.stateService[specificState].sideBarStatus = 'reports';
            obsResult['sideBarStatus'] = 'reports';
        }
        if (specificState.includes('people') || specificState.includes('People')) {
            if (this.stateService[specificState].prbIndex !== 0) {
                this.stateService[specificState].prbIndex = 0;
                obsResult['prbIndex'] = 0;
            }
        } else if (!specificState.includes('jobs') && !specificState.includes('Jobs')) {
            if (this.stateService[specificState].selectedInfoQuestionTab !== 0) {
                this.stateService[specificState].selectedInfoQuestionTab = 0;
            }
        }
        return (Object.keys(obsResult).length === 0) ? null : obsResult;
    }
}
