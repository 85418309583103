import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';

@Component({
    selector: 'app-mini-iac-graph',
    templateUrl: './mini-iac-graph.component.html',
    styleUrls: ['./mini-iac-graph.component.scss']
})
export class MiniIacGraphComponent implements OnChanges {

    @ViewChild('MiniIacGraph', {static: true}) private chartContainer: ElementRef;
    @Input() score;
    @Input() id;
    @Input() type = 'cards';
    @Input() card = false;
    data = [];

    constructor() {
    }

    ngOnChanges() {
        this.createMiniIacGraph();
    }

    createMiniIacGraph() {

        const gray = '#E2E2E2';

        const graphSize = {
            paragraphHeader: '30',
            cards          : '61'
        };

        let element = this.chartContainer.nativeElement;

        if (!this.card) {
            d3.select('#mini_iac_' + this.id).remove();
        }

        let svg = d3.select(element).append('svg').attr('preserveAspectRatio', 'xMidYMid meet').
        attr('id', 'mini_iac_' + this.id).
        attr('width', graphSize[this.type]).
        attr('height', graphSize[this.type]).attr('viewBox', '0 0 40 40');
        svg.append('circle').attr('cx', 20).attr('cy', 20).attr('r', 18).style('fill', 'white').style('stroke-width', 1).
        style('stroke', gray);
        svg.append('svg:image')
            .attr('x', 1.5)
            .attr('y', 1.5)
            .attr('width', 37)
            .attr('height', 37)
            .attr('xlink:href', 'assets/graphicalElements/bellCurveCircle.png');
        svg.append('circle').attr('cx', 20).attr('cy', 20).attr('r', 18).style('fill', 'none').style('stroke-width', 1).
        style('stroke', gray);

        svg.append('text').attr('x', 20).
        attr('y', 24).
        text(this.score).
        attr('text-anchor', 'middle').
        attr('font-family', 'sans-serif').
        attr('font-size', 16).
        attr('fill', '#888');


    }

}
