import { ActivatedRoute } from '@angular/router';
import { AdministrationCommonListComponent } from '@admin/components/administration-common-list/administration-common-list.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService } from '@core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-administration-files-list',
	templateUrl: './administration-files-list.component.html',
	styleUrl: './administration-files-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationFilesListComponent extends AdministrationCommonListComponent {
	constructor(
		protected translate: TranslateService,
		protected route: ActivatedRoute,
		protected stateService: StateService
	) {
		super(translate, route, stateService);
	}

	iconFile(fileType) {
        const validFileTypes = [
            'csv',
            'doc',
            'docx',
            'html',
            'jpg',
            'pdf',
            'png',
            'ppt',
            'pptx',
            'psd',
            'rtf',
            'svg',
            'txt',
            'xls',
            'xlsx'
        ];

        return validFileTypes.includes(fileType)
            ? `/assets/filesIcons/${fileType}.png`
            : '/assets/filesIcons/file.png';
    }
}
