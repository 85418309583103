import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomizableFormComponent} from './components/customizable-form/customizable-form.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CustomizableFormComponent]
})
export class CustomizableFormsModule {
}
