import {Location} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {RightMatchListService} from '../../../services/right-match-list.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {RightMatchModalHandlingService} from '../../../services/right-match-modal-handling.service';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import {Subject, Subscription} from 'rxjs';
import {AlertService} from '../../../../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {PermissionsService} from 'src/app/shared/permissions/permissions.service';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {take, takeUntil} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TalentsDefinitions} from '../../../../../shared/talents/talentsDefinitions';
import * as _ from 'lodash';
import {UserService} from '../../../../../core/services/user/user.service';

@Component({
    selector: 'app-right-matches-sidebar',
    templateUrl: './right-matches-sidebar.component.html',
    styleUrls: ['./right-matches-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesSidebarComponent extends ComponentSidebar implements OnInit, OnChanges, OnDestroy {
    @Input() width = '';
    @Input() config: any;
    @Input() isLeftAndNotTop = true;
    @Input() tabletMode = false;

    currentLang: string;



    @Output() sortResults = new EventEmitter;
    @Output() closeSidebar = new EventEmitter;

    canDelete = false;
    titleSep: string;

    onDestroy$: Subject<null> = new Subject();

    private subscriptions = new Subscription();

    maxPerPage = 7;
    dateFormat;

    constructor(public rightMatch: RightMatchAnalysisService,
                public rightMatchService: RightMatchListService,
                public rightMatchModalHandlingService: RightMatchModalHandlingService,
                protected router: Router,
                private location: Location,
                protected route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,
                private apiRightMatchesService: ApiRightMatchesService,
                private alertService: AlertService,
                public stateService: StateService,
                protected translateService: TranslateService,
                private permissionsService: PermissionsService,
                protected deviceService: DeviceDetectorService,
                public user: UserService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        this.canDelete = this.permissionsService.canDelete();
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);
        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.itemToDisplayId) {
                    this.rightMatchService.setAmount(this.rightMatch.records);
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    ngOnChanges(changes) {

        // prevent refresh upon change of width
        let keys = Object.keys(changes);
        let firstKey = keys[0];
        if (keys.length === 1 && firstKey === 'width') { return; }

        this.currentLang = this.translateService.currentLang;
        if (!this.isLeftAndNotTop) {
            this.width = null;
        }
        this.rightMatchService.checkModuleUrl();
        if (this.rightMatch.id) {
            this.rightMatchService.setAmount(this.rightMatch.records);
            if (this.rightMatch.profilerInfo && typeof this.rightMatch.profilerInfo === 'string') {
                // in case of job without profile we have to delete the analysis
                this.subscriptions.add(this.apiRightMatchesService.deleteRightMatch([this.rightMatch.id]).subscribe(
                    () => {
                        let alertTitle = this.translateService.instant('commons.deleted');
                        let alertP = this.translateService.instant('analysis.rightMatch.noAssociatedProfileMessage');

                        this.alertService.setConfig({
                            paragraph: alertP,
                            texth1: alertTitle,
                            position: 'top-right',
                            type: 'green'
                        });

                        if (this.config === 'list') {
                            this.rightMatch.setItemToDisplayWithId(this.rightMatch.id);
                            this.rightMatchService.getRightMatchList(true);
                        }
                        this.changeDetectorRef.markForCheck();
                    })
                );
            }
        } else {
            this.rightMatchService.amount = [0, 0, 0, 0, 0, 0];
        }
        this.maxPage = (this.rightMatch.orderedTalents && this.rightMatch.orderedTalents[this.translateService.currentLang]) ?
            Math.ceil(_.size(this.rightMatch.orderedTalents[this.translateService.currentLang]) / this.maxPerPage) :
            null;

    }



    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

    sortResultsSidebar(event) {
        this.sortResults.emit(event);
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        let pAction;
        let paragraph;
        if (action === 'archive') {
            if (act) {
                p[0] = this.translateService.instant('analysis.rightMatch.archived');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[0] = this.translateService.instant('commons.archiving');
                h1[1] = this.translateService.instant('commons.archivingError');
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });

                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            } else {
                p[0] = this.translateService.instant('analysis.rightMatch.reactivated');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[0] = this.translateService.instant('commons.reactivation');
                h1[1] = this.translateService.instant('commons.reactError');
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });

                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            }
        } else if (action === 'delete') {
            p[0] = this.translateService.instant('analysis.commons.rightMatchDeleted', {
                total: 1,
                plural: ''
            });
            h1[0] = this.translateService.instant('analysis.commons.rightMatchDeletion');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            pAction = this.translateService.instant('commons.deletionAction');
            paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                action: pAction,
                total: 1,
                plural: ''
            });
            const txt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txt);
        }
        this.alertService.nextAction$.subscribe(
            res => {
                if (res === 'confirm' && action) {
                    if (action === 'delete') {
                        this.apiRightMatchesService.deleteRightMatch([this.rightMatch.id]).pipe(take(1)).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (this.config === 'list') {
                                    this.rightMatch.resetItemToDisplay(this.specificState());
                                }
                                this.rightMatchService.getRightMatchList(true);
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                                this.changeDetectorRef.markForCheck();
                            }
                        );
                    } else if (action) {
                        this.apiRightMatchesService.putRightMatch([this.rightMatch.id], {archive: act}).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (this.config === 'list') {
                                    this.rightMatch.setItemToDisplayWithId(this.rightMatch.id, this.specificState());
                                    if (this.smallSize) {
                                       this.switchOffPanelAction();
                                    }
                                } else {
                                    this.goToList();
                                }
                                this.rightMatchService.getRightMatchList(true);
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                                this.changeDetectorRef.markForCheck();
                            }
                        );
                    }
                    action = undefined;
                }
            }
        );
        return;
    }

    switchOffPanelAction() {
        this.closeSidebar.emit(true);
    }

    openEditModal() {
        this.rightMatchModalHandlingService.openRightMatchModal({'status': 'edit'});
    }

    openPdfModal() {
        if (this.rightMatch.records && this.rightMatch.records.length > 0) {
            this.rightMatchModalHandlingService.openRightMatchPdfModal();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

