import {Component, NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Tools} from '../../../shared/misc/tools';
import {Subscription} from 'rxjs';
import {StateService} from '../../../core/services/state/state.service';
import {LoginService} from '../../services/login.service';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {passwordStrengthValidator} from '../../validators/password-strength';
import {JwtHelperService} from '../../services/jwt-helper.service';

@Component({
    selector: 'app-setpass',
    templateUrl: './setpass.component.html',
    styleUrls: ['./setpass.component.scss']
})
export class SetpassComponent implements OnInit, OnDestroy {

    @ViewChild('forgetPassNgForm',  {static: false}) forgetPassNgForm;
    @ViewChild('changePassNgForm', {static: false}) changePassNgForm;

    token: any;
    message: string;
    forgetPassForm: FormGroup;
    changePassForm: FormGroup;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    pre = true;

    discrepancies = false;

    badUserName = false;

    messageFormSubmit = false;
    messagePasswordChange = false;
    emptyUserName = false;

    language: string;

    private subscriptions = new Subscription();

    constructor(public state: StateService,
                private route: ActivatedRoute,
                private router: Router,
                private loginService: LoginService,
                private changeDetectorRef: ChangeDetectorRef, // Used in the service
                private fb: FormBuilder,
                private location: Location,
                public translate: TranslateService,
                private zone: NgZone,
                private jwtHelperService: JwtHelperService
    ) {
        this.language = this.translate.getBrowserLang();
        if (this.language !== 'fr' && this.language !== 'en') {
            this.language = 'en'
        }
        this.translate.setDefaultLang(this.language);
        this.translate.use(this.language);
    }

    ngOnInit() {
        if (localStorage.getItem('setPasswordOverride') === 'true') {
            this.router.navigateByUrl('/login');
            return;
        }
        this.initForgetPassForm();
        // Default redirect if authentified
        if (!this.jwtHelperService.isTokenExpired()) {
            // ToDo: find a way to have a redirect service
            // this.router.navigate(['people']);
            return;
        }

        // Token
        let urlParams = this.location.path().split('/');
        if ('undefined' !== typeof urlParams[2]) {
            this.token = urlParams[2];
            this.initChangePassForm(this.token);
        }
    }

    initForgetPassForm() {
        this.discrepancies = false;
        this.forgetPassForm = this.fb.group(
            {
                username: ['', [Validators.required]]
            }
        );
        return this.forgetPassForm;
    }

    onSubmitForgetPassForm(model: FormGroup) {
        this.emptyUserName = false;
        if (!model.value['username'] || model.value['username'] === '') {
            this.emptyUserName = true;
            return;
        }
        if (model.valid) {
            model.value['lang'] = this.translate.currentLang;
            this.subscriptions.add(this.loginService.resetPassword(model.value).subscribe(
                () => {
                    this.messageFormSubmit = true;
                    this.pre = false;
                    this.changeDetectorRef.markForCheck();
                },
                () => {
                    this.badUserName = true;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    langChange(lang) {
        this.translate.use(lang);
    }

    initChangePassForm(token) {
        this.messageFormSubmit = false;
        this.messagePasswordChange = false;
        this.discrepancies = false;
        this.changePassForm = this.fb.group(
            {
                password: ['', [Validators.required, Validators.minLength(8)]],
                pwdConfirmation: ['', [Validators.required]],
                token: [token, [Validators.required]]
            },
            {
                validator: passwordStrengthValidator('password')
            }
        );
    }

    get password() {
        return this.changePassForm.get('password').value;
    }

    get pwdConfirmation() {
        return this.changePassForm.get('pwdConfirmation').value;
    }

    onSubmitChangePassForm(model: FormGroup) {
        if (this.password !== this.pwdConfirmation) {
            this.discrepancies = true;
            return;
        }

        this.discrepancies = false;

        if (model.valid) {
            this.subscriptions.add(this.loginService.changePassword(model.value).subscribe(
                () => {
                    this.messagePasswordChange = true;
                    localStorage.setItem('setPasswordOverride', 'true');

                    this.changeDetectorRef.markForCheck();

                    setTimeout(() => {
                        this.zone.runOutsideAngular(() => {
                            window.location.href = '/';
                        });
                    }, 3000);
                },
                error => {
                    this.errorForm = error.error;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
