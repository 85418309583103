import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({
    selector: '[appSelectInput]'
})
export class SelectInputDirective implements OnInit {

    @Input() definition: any;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        // Seems like the formControlName attribute must be declared directly in the template
        this.el.nativeElement.name = this.definition.name;
    }
}
