import {TranslateService} from '@ngx-translate/core';
import {TalentsHelper} from '../../../shared/talents/talents.helper';
import {TalentsDefinitions} from '../../../shared/talents/talentsDefinitions';

export abstract class JobsCompetencesModalCommon {

    selectedTalents: Array<number> = [];
    selectedTalentsInit: Array<number> = [];
    talentsOrder: 'title' | 'number' = 'title';
    talentsData: any = [];

    protected constructor(
        protected translateService: TranslateService
    ) {
    }

    dropDownValues(talent, num) {
        let notSelected = this.translateService.instant('commons.notSelected');
        let functionnal = this.translateService.instant('jobs.modal.competenceFuncOption');
        let mastery = this.translateService.instant('jobs.modal.competenceMasteryOption');

        if (num === 0) {
            if (!this.selectedTalents[talent]) {
                return [notSelected, 0];
            }
            if (this.selectedTalents[talent] === 1) {
                return [functionnal, 1];
            }
            if (this.selectedTalents[talent] === 2) {
                return [mastery, 2];
            }
        }
        if (num === 1) {
            if (!this.selectedTalents[talent]) {
                return [functionnal, 1];
            }
            if (this.selectedTalents[talent] === 1) {
                return [mastery, 2];
            }
            if (this.selectedTalents[talent] === 2) {
                return [functionnal, 1];
            }
        }
        if (num === 2) {
            if (!this.selectedTalents[talent]) {
                return [mastery, 2];
            }
            if (this.selectedTalents[talent] === 1) {
                return [notSelected, 0];
            }
            if (this.selectedTalents[talent] === 2) {
                return [notSelected, 0];
            }
        }
        return;
    }

    profileDataLoad() {
        if (this.talentsOrder === 'number') {
            this.talentsData = TalentsHelper.getSortedTalentsByNumber(new TalentsDefinitions().getTalentsData(this.translateService.currentLang));
        } else {
            this.talentsData = TalentsHelper.getSortedTalentsByName(new TalentsDefinitions().getTalentsData(this.translateService.currentLang));
        }
    }

    updateSorting(sorting: 'number' | 'title'): void {
        this.talentsOrder = sorting;
        this.profileDataLoad();
    }

    showSortOption(lang = this.translateService.currentLang) {
        return (lang === 'en');
    }

    checkTalentsPayload() {
        let talentsPayload: any = {};
        for (let talent in this.selectedTalents) {
            if (talent && this.selectedTalents.hasOwnProperty(talent) &&
                (this.selectedTalents[talent] === 1 || this.selectedTalents[talent] === 2)) {
                talentsPayload['' + talent] = this.selectedTalents[talent];
            }
        }
        return talentsPayload;
    }

    trackByCompetencieId(index: number, item: any) {
        if (!item) {
            return null;
        }
        return item.id;
    }
}
