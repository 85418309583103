// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.isSmallMobile {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/graphics/job-satisfactions-person-history/job-satisfactions-person-history.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".isSmallMobile {\n    width: fit-content;\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
