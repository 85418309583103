// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-danger {
  margin-left: 15px;
  margin-top: 2px;
}

::ng-deep .formWrapper .mat-mdc-form-field {
  width: 100%;
}

.mat-grid-tile-content {
  justify-content: flex-end !important;
}

.mobile-form .mat-mdc-form-field-infix {
  padding-bottom: 25px !important;
}
.mobile-form .modalButton {
  background-color: #666;
  color: #fff;
  font-weight: bold;
}

.mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-bottom: 25px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/graphboards/components/mobile/mobile-graphboards-edit/mobile-graphboards-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAGI;EACI,+BAAA;AAAR;AAEI;EACI,sBAAA;EACA,WAAA;EACA,iBAAA;AAAR;;AAKI;EACI,+BAAA;AAFR","sourcesContent":[".text-danger {\n    margin-left: 15px;\n    margin-top: 2px;\n}\n\n::ng-deep .formWrapper .mat-mdc-form-field {\n    width: 100%;\n}\n\n.mat-grid-tile-content {\n    justify-content: flex-end!important;\n}\n\n.mobile-form {\n    .mat-mdc-form-field-infix {\n        padding-bottom: 25px!important;\n    }\n    .modalButton {\n        background-color: #666;\n        color: #fff;\n        font-weight: bold;\n    }\n}\n\n.mat-mdc-form-field-flex {\n    .mat-mdc-form-field-infix {\n        padding-bottom: 25px!important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
