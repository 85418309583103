import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy
} from '@angular/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-graphboards-person-modal',
    templateUrl: './graphboards-person-modal.component.html',
    styleUrls: ['./graphboards-person-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsPersonModalComponent implements OnInit, OnDestroy {

    @Input() multiAction: any = false;
    @Input() graphboardTab = 'individus';
    @Input() archivedRecords = [];

    @Output() editRefresh = new EventEmitter();

    public loading = false;
    public close: EventEmitter<any> = new EventEmitter();
    public graphboardTabChange: EventEmitter<any> = new EventEmitter();

    searchTerm: any;
    records: any = {};

    chooseGraphboard: boolean;
    headerText: string;
    gbList: Array<any> = null;

    gbId: string;

    selectedItems: any = {
        individus: [],
        profiler: []
    };

    addToSelectedItems: any = {
        individus: [],
        profiler: []
    };

    recordsLoaded: any = {
        individus: 250,
        profiler: 250
    };
    recordsLength = {};

    newGraphBoard = false;

    actual = '';

    private subscriptions = new Subscription();

    constructor(public bsModalRef: BsModalRef,
                private changeDetectorRef: ChangeDetectorRef,
                private stateService: StateService,
                private router: Router,
                public graphBoard: GraphBoardsAnalysisService,
                public graphBoardsList: GraphBoardsListService,
                private apiGraphboards: ApiGraphboardsService,
                private apiPeopleService: ApiPeopleService,
                private apiJobsService: ApiJobsService,
                private translate: TranslateService
    ) {
        this.selectedItems = {
            'individus': [],
            'profiler': []
        };
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchData(this.graphboardTab, searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchData(this.graphboardTab, searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchData(this.graphboardTab, null);
    }

    ngOnInit() {
        this.selectedItems = {
            individus: [],
            profiler: []
        };

        this.fetchData('individus');
        this.fetchData('profiler');

        if (this.multiAction && this.multiAction.idType !== '' && this.multiAction.ids.length > 0) {
            let type = (this.multiAction.idType === 'people') ? 'individus' : 'profiler'; // 'people' or 'profiler'
            for (let id of this.multiAction.ids) {
                let item = {};
                if (type === 'individus') {
                    item = {
                        record: id,
                        hasTrait: true,
                        hasPrb: false
                    };
                } else {
                    item = {
                        record: id,
                        position: null
                    };
                }
                this.addToSelectedItems[type].push(item);
                this.selectedItems[type].push(item);
            }

            this.chooseGraphboard = true;
            this.headerText = (this.translate.currentLang === 'fr') ? 'Les éléments choisis sont sélectionnés ci-dessous' :
                'Chosen elements are selected below';

            if (this.gbList === null) {
                let gbList = [];
                this.subscriptions.add(this.apiGraphboards.graphboards([{isArchived: 0, limit: 100}]).subscribe(
                    res => {
                        if (res) {
                            for (let gb of res.data) {
                                gbList.push({
                                    id: gb.id,
                                    name: gb.name
                                });
                            }
                            this.gbList = gbList;
                        }
                    }
                ));
            }
            this.multiAction = false;

        } else if (this.graphBoard.alignment) {
            let i = {
                individus: 0,
                profiler: 0
            };
            let colKeys = Object.keys(this.graphBoard.alignment);
            // tslint:disable-next-line:forin
            for (let col in colKeys) {
                let rowKeys = Object.keys(this.graphBoard.alignment[+col]);
                // tslint:disable-next-line:forin
                for (let row in rowKeys) {
                    // tslint:disable-next-line:max-line-length
                    let analysisType = (
                        this.graphBoard.alignment[+col][+row].evaluationType === 'mpo'
                    ) ? 'individus' : 'profiler';
                    let hasTrait = (this.graphBoard.alignment[+col][+row].analysisType === 'trait' ||
                        this.graphBoard.alignment[+col][+row].hasTrait);
                    let hasPrb = (this.graphBoard.alignment[+col][+row].analysisType === 'prb' ||
                        this.graphBoard.alignment[+col][+row].hasPrb);
                    // noinspection OverlyComplexBooleanExpressionJS
                    let currentId: any = (
                        this.graphBoard.alignment &&
                        this.graphBoard.alignment[+col] &&
                        this.graphBoard.alignment[+col][+row] &&
                        this.graphBoard.alignment[+col][+row].id) ?
                        this.graphBoard.alignment[+col][+row].id : false;

                    let insert = !(_.find(this.selectedItems[analysisType], function (o) {
                        return o.record === currentId;
                    }));

                    if (insert) {
                        let item = {};
                        if (analysisType === 'individus') {
                            item = {
                                record: this.graphBoard.alignment[+col][+row].id,
                                hasTrait: hasTrait,
                                hasPrb: hasPrb,
                                position: [+col, +row]
                            };
                        } else {
                            item = {
                                record: this.graphBoard.alignment[+col][+row].id,
                                position: [+col, +row]
                            };
                        }
                        this.selectedItems[analysisType][i[analysisType]] = item;
                        i[analysisType]++;
                    } else {
                        if (currentId) {
                            let index = this.selectedItems[analysisType].map(function (o) {
                                return o.record;
                            }).indexOf(currentId);
                            if (hasTrait) {
                                this.selectedItems[analysisType][index].hasTrait = hasTrait;
                            }
                            if (hasPrb) {
                                this.selectedItems[analysisType][index].hasPrb = hasPrb;
                            }
                        }
                    }
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    displayType(newDisplayedType) {
        this.graphboardTab = newDisplayedType;
        this.fetchData(this.graphboardTab);
        this.graphboardTabChange.emit({newDisplayedType: newDisplayedType});
    }

    onScroll() {
        if (this.recordsLoaded[this.graphboardTab] >= this.recordsLength[this.graphboardTab]) {
            return;
        }

        this.fetchData(
            this.graphboardTab, this.searchTerm, this.recordsLoaded[this.graphboardTab], true
        );
    }

    predicateCheckBox(type: string, record: any): any {
        if (type === 'profiler') {
            for (let item of this.selectedItems['profiler']) {
                if (item.record === record.id) {
                    return true;
                }
            }
            return false;
        }
    }

    check(record, type) {

        let item = _.find(this.selectedItems['individus'], function (o) {
            return o.record === record.id;
        });

        if (item) {
            if (!item.hasPrb && item.hasTrait && type === 'trait') {
                // if(record.id=='59a051294ff3c1a647004868')  console.log(record);
                // if(record.id=='59a051294ff3c1a647004868')  console.log(item);
                return true;
            }
            if (item.hasPrb && !item.hasTrait && type === 'prb') {
                return true;
            }
            if (item.hasPrb && item.hasTrait && type === 'both') {
                return true;
            }
        }
        this.changeDetectorRef.markForCheck();
        // console.log(record);
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems[this.graphboardTab].push({
                record: id,
                position: null
            });
        } else {
            _.remove(this.selectedItems[this.graphboardTab], function (i) {
                return i.record === id;
            });
        }
    }

    selectIndType(id, type) {
        // type := {'trait' 'prb' 'both', 'none'};

        let item = _.find(this.selectedItems[this.graphboardTab], function (o) {
            return o.record === id;
        });
        _.remove(this.selectedItems[this.graphboardTab], function (i) {
            return i.record === id;
        });

        let hasTrait = false;
        let hasPrb = false;

        if (type === 'trait') {
            if (item && item.hasPrb && item.hasTrait) {
                hasTrait = true;
            } else {
                hasTrait = !(item && item.hasTrait);
            }
            hasPrb = false;
        }
        if (type === 'prb') {
            if (item && item.hasPrb && item.hasTrait) {
                hasPrb = true;
            } else {
                hasPrb = !(item && item.hasPrb);
            }
            hasTrait = false;

        }
        if (type === 'both') {
            if (item && item.hasPrb && item.hasTrait) {
                hasTrait = false;
                hasPrb = false;
            } else {
                hasTrait = true;
                hasPrb = true;
            }

        }

        this.selectedItems[this.graphboardTab].push({
            record: id,
            hasTrait: hasTrait,
            hasPrb: hasPrb,
            position: null
        });
    }

    assignGbId(id) {
        this.graphBoard.setItemToDisplayWithId(id);
        this.router.navigate(['analysis/graphboard/list']).then(() => {
            this.graphBoardsList.changeGraphBoardTab(this.graphboardTab);
        });
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    onSubmit() {
        let modSelectedItems: any = JSON.parse(JSON.stringify(this.selectedItems));
        // console.log(modSelectedItems);
        let submitArray = [[], [], []];
        let newElmt = {
            'trait': [],
            'prb': [],
            'profiler': []
        };

        // Convert back to canonical format:
        modSelectedItems['trait'] = [];
        modSelectedItems['prb'] = [];
        for (let item of modSelectedItems['individus']) {
            let itemTmp = item;
            // console.log(item);
            if (item.position !== null || item.hasTrait || item.hasPrb) {
                if (itemTmp.hasTrait && itemTmp.hasPrb) {
                    delete itemTmp.hasTrait;
                    delete itemTmp.hasPrb;
                    modSelectedItems['trait'].push(itemTmp);
                    modSelectedItems['prb'].push(itemTmp);
                } else {
                    if (itemTmp.hasTrait) {
                        delete itemTmp.hasTrait;
                        delete itemTmp.hasPrb;
                        modSelectedItems['trait'].push(itemTmp);
                    }
                    if (itemTmp.hasPrb) {
                        delete itemTmp.hasTrait;
                        delete itemTmp.hasPrb;
                        modSelectedItems['prb'].push(itemTmp);
                    }
                }
            }
        }

        for (let itemType of ['trait', 'prb', 'profiler']) {
            for (let item of modSelectedItems[itemType]) {
                if (item.record && item.position !== null) {// } || item.hasTrait || item.hasPrb)) {
                    if (itemType === 'prb') {
                        if (submitArray[item.position[0]][item.position[1]]) {
                            let x = item.position[0];
                            let y = item.position[1];
                            while (submitArray[x][y]) {
                                x = (x + 1) % 3;
                                y += 1;
                            }
                            submitArray[x][y] = {
                                type: itemType,
                                record: item.record,
                                dash: 0
                            };
                        } else {
                            submitArray[item.position[0]][item.position[1]] = {
                                type: itemType,
                                record: item.record,
                                dash: 0
                            };
                        }
                    } else {
                        if (submitArray[item.position[0]][item.position[1]]) {
                            let x = item.position[0];
                            let y = item.position[1];
                            while (submitArray[x][y]) {
                                x = (x + 1) % 3;
                                y += 1;
                            }
                            submitArray[x][y] = {
                                type: itemType,
                                record: item.record
                            };
                        } else {
                            submitArray[item.position[0]][item.position[1]] = {
                                type: itemType,
                                record: item.record
                            };
                        }
                    }
                } else {
                    newElmt[itemType].push(item.record);
                }
            }
        }
        // console.log(submitArray, newElmt);

        let j = 0;
        for (let itemType of ['trait', 'prb', 'profiler']) {
            for (let i = 0; i < newElmt[itemType].length; i++) {
                if (newElmt[itemType][i] !== null) {
                    let payload: any = {
                        type: itemType,
                        record: newElmt[itemType][i]
                    };
                    if (itemType === 'prb') {
                        payload.dash = 0;
                    }
                    submitArray[j].push(
                        payload
                    );
                }
                j++;
                j %= submitArray.length;
            }
        }

        if (submitArray) {
            const data = {'alignment': submitArray};
            this.subscriptions.add(this.apiGraphboards.putGraphboard([this.graphBoard.id], data).subscribe(
                (res) => {
                    if (this.multiAction && this.multiAction.idType !== '' && this.multiAction.ids.length > 0) {
                        this.graphBoard.setItemToDisplayWithId(res.id);
                        this.router.navigate(['analysis/graphboard/report/' + res.id]).then(() => {});
                        this.reinitModal();
                    }
                    this.closeModal(res.id);
                    return;
                }
            ));
        }
    }

    hasMpoEvaluation(record) {
        if (record && record.reportStatuses.mpo) {
            return record.reportStatuses.mpo;
        }
        return;
    }

    hasPrbEvaluation(record) {
        if (record && record.reportStatuses.prb) {
            return record.reportStatuses.prb;
        }
        return;
    }

    fetchData(displayType, searchTerm = null, skip = null, concat = false) {
        // Get individus
        if (displayType === 'individus') {
            this.loading = true;
            let request = {
                'search': searchTerm,
                'skip': skip,
                'limit': this.recordsLoaded[displayType]
            };

            this.subscriptions.add(this.apiPeopleService.records(
                [request]).subscribe(
                (res) => {
                    res.data.forEach(el => {
                        if (displayType === 'individus' && this.archivedRecords.includes(el.id)) {
                            el.isArchived = true;
                        }
                    });

                    // Get records loaded (add the new records from API)
                    this.recordsLoaded[displayType] = (skip) ?
                        this.recordsLoaded[displayType] + res.data.length :
                        this.recordsLoaded[displayType];

                    // Records data (merge new records from API)
                    this.records[displayType] = (concat) ?
                        [...this.records[displayType], ...res.data] :
                        res.data;

                    // console.log(res);
                    // Total records
                    this.recordsLength[displayType] = res.count;

                    // Reload component
                    this.changeDetectorRef.markForCheck();

                    this.loading = false;
                    return;
                }
            ));
        }

        // Profiler
        if (displayType === 'profiler') {
            this.loading = true;
            let request = {
                'search': searchTerm,
                'fields': 'id,jobTitle',
                'skip': skip,
                'limit': this.recordsLoaded[displayType],
                'order': {date: 'desc'},
                'statuses[score]': 1,
                'isArchived': 0
            };
            this.subscriptions.add(this.apiJobsService.jobs(
                [request]).subscribe(
                (res) => {
                    // Get records loaded (add the new records from API)
                    this.recordsLoaded[displayType] = (skip) ?
                        this.recordsLoaded[displayType] + res.data.length :
                        this.recordsLoaded[displayType];

                    // Records data (merge new records from API)
                    this.records[displayType] = (concat) ?
                        [...this.records[displayType], ...res.data] :
                        res.data;

                    // Total records
                    this.recordsLength[displayType] = res.count;

                    // Reload component
                    this.changeDetectorRef.markForCheck();

                    this.loading = false;
                    return;
                }
            ));
        }

        // this.loading = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    reinitModal() {
        this.selectedItems = {
            individus: [],
            profiler: []
        };

        this.newGraphBoard = true;

    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    closeModal(id = null) {
        this.delay(1000).then(() => {
            this.reinitModal();
        });
        if (this.multiAction && this.multiAction.idType !== '' && this.multiAction.ids.length > 0) {
            this.router.navigate(['/people/list']).then(() => {});
            return;
        }

        this.close.emit({closed: true, id: id});
        this.bsModalRef.hide();
    }
}
