export class Tools {

    /**
     * Validate form field
     * @param control FormGroup control
     * @param ngform ngForm directive
     */
    public static validateFormField(control, ngform, completed= true): boolean {
        if (completed) {
            return !!(
                control.errors && ngform.submitted ||
                (
                    control.errors &&
                    (control.dirty || control.touched)
                )
            )
        } else {
            return;
        }
    }

    /**
     * Generate a random password
     * @param len length of the password
     */
    public static passwordGenerator(len: number): string {
        let length = (len) ? (len) : (10);
        let string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
        let numeric = '0123456789';
        let punctuation = '$-/:-?{-~!"^_`[]';
        let password = '';
        let character = '';

        while ( password.length < length ) {
            let entity1 = Math.ceil(string.length * Math.random() * Math.random());
            let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
            let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
            let hold = string.charAt( entity1 );
            hold = (password.length % 2 === 0) ? (hold.toUpperCase()) : (hold);
            character += hold;
            character += numeric.charAt( entity2 );
            character += punctuation.charAt( entity3 );
            password = character;
        }
        password = password.split('').sort(function() {return 0.5 - Math.random()}).join('');

        return password.substr(0, len);
    }

    /**
     * Slugify a string
     * @param str string to slugify
     */
    public static slugify(str: string): string {
        str = String(str).toString();
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        return str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-') // collapse dashes
            .replace(/^-+/, '') // trim - from start of text
            .replace(/-+$/, '');
    }
}
