import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {StateService} from '../../core/services/state/state.service';
import {ApiAdministrationService} from './api-administration.service';

@Injectable()
export class UserPreferencesService {

    constructor(
        private router: Router,
        private stateService: StateService,
        private apiAdministrationService: ApiAdministrationService
    ) {
    }

    initUserPreferences() {
        const userPreferences = this.stateService.session.sessionData.userData.preferences;
        this.setUserPreferences(userPreferences);
    }

    /**
     * @param moduleName
     *
     * This method will return a boolean telling whether or not there is a preference set for
     * this module.
     */
    isPreferenceSet(moduleName) {
        return !!(
            this.stateService.session.preferences &&
            this.stateService.session.preferences.hasOwnProperty(moduleName) &&
            this.stateService.session.preferences[moduleName] !== null &&
            this.stateService.session.preferences[moduleName] !== 'undefined'
        );
    }

    emptyUserPreferences(moduleName, userDataId) {
        if (
            userDataId &&
            this.stateService[moduleName] &&
            this.stateService.session.sessionData.userData.preferences.hasOwnProperty(moduleName)
        ) {
            this.stateService[moduleName].listColumns = this.getStoredKey(moduleName, 'listColumnsInitialConfig');

            let searchPreference = {};
            for (let filter in this.stateService[moduleName].filters) {
                if (this.stateService[moduleName].filters.hasOwnProperty(filter)) {
                    searchPreference[filter] = null;
                }
            }

            this.stateService[moduleName].state.listSearchFilters = searchPreference;
            this.stateService[moduleName].listView = 'list';

            // Store new preferences in state
            this.apiAdministrationService.putUser([userDataId], {
                preferences: {[moduleName]: null}
            }).subscribe(
                (res) => {
                    this.stateService.session.preferences = res.preferences;
                });
        }
    }

    setUserPreferences(preferences: any, url = null, specificModule = null) {
        // get data from user preferences
        // tslint:disable-next-line:forin
        if (specificModule === null) {
            for (let moduleName in preferences) {
                if (preferences.hasOwnProperty(moduleName)) {
                    this.setOneModulePreference(preferences, url, moduleName);
                }
            }
        } else {
            this.setOneModulePreference(preferences, url, specificModule);
        }

    }

    setOneModulePreference(preferences, url = null, moduleName) {
        if (this.stateService[moduleName]) {
            // display
            if (preferences[moduleName] && preferences[moduleName].display) {
                // Display
                let displayPreference = preferences[moduleName].display;
                // Get preference key and value, and transform the + in . (in keys)
                let preferencesDisplayToLoad = [];
                for (let key of Object.keys(displayPreference)) {
                    let value = displayPreference[key];
                    key = this.plusToDot(key);

                    // Update state
                    preferencesDisplayToLoad.push({key: key, display: value});
                    if (moduleName === 'people' && key === 'status') {
                        preferencesDisplayToLoad.push({key: 'statusPrb', display: value});
                    }

                }

                const newDisplayPreferences = [...this.getStoredKey(moduleName, 'listColumnsInitialConfig'), ...preferencesDisplayToLoad];

                this.stateService[moduleName].listColumns = newDisplayPreferences;
                this.saveSessionStorage(moduleName, 'listColumns', newDisplayPreferences);
            }

            // Sub-accounts
            // Search
            if (preferences[moduleName] && preferences[moduleName].search) {
                // Search
                let searchPreference = preferences[moduleName].search;
                // Update state
                this.stateService[moduleName].state.listSearchFilters = searchPreference;
                this.saveSessionStorage(moduleName, 'listSearchFilters', searchPreference);

            }

            if (preferences[moduleName] && preferences[moduleName].listView) {

                // Search
                let searchPreference = preferences[moduleName].listView;
                // Update state
                this.stateService[moduleName].listView = searchPreference;
                this.saveSessionStorage(moduleName, 'listView', searchPreference);
            }
        }
    }

    dotToPlus(str: string) {
        if (str) {
            return str.replace(/\./g, '+');
        }
        return;

    }

    plusToDot(str: string) {
        if (str) {
            str = str.replace(/\+/g, '.');
            return str;
        }
        return;
    }

    saveSessionStorage(moduleName, key, value) {
        sessionStorage[moduleName + '.' + key] = JSON.stringify(value);
    }

    getStoredKey(moduleName, key) {
        if (this.stateService[moduleName]) {
            return this.stateService[moduleName][key];
        }

        return null;
    }
}
