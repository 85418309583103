import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {
    AdministrationAccountFormFieldComponent
} from '../administration-account-form-field/administration-account-form-field.component';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountService} from '../../services/account.service';

@Component({
    selector: 'app-administration-account-form-field-hierarchy',
    templateUrl: './administration-account-form-field-hierarchy.component.html',
    styleUrls: ['./administration-account-form-field-hierarchy.component.scss']
})
export class AdministrationAccountFormFieldHierarchyComponent extends AdministrationAccountFormFieldComponent implements OnChanges {
    @Input() currentAccount = null;
    @Input() new: any = false;
    @Input() missedFields: any = [];
    @Input() closeAllFieldsExcept: string;
    @Input() formHelper: any;

    pristine = true;

    error: boolean;

    fieldsValue = {
        subsidiary: null,
        distributor: null,
        partner: null
    };

    fieldsList = {
        subsidiary: null,
        distributor: null,
        partner: null
    };

    initialHierarchy = {};

    @Output() closeAllAdminFields = new EventEmitter();
    @Output() editionsResult = new EventEmitter();

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        public translate: TranslateService,
        protected cd: ChangeDetectorRef,
        private accountService: AccountService,
        private apiAdministrationService: ApiAdministrationService
    ) {
        super(router, deviceService, stateService, translate, cd, stateService);
    }

    ngOnChanges(changes) {
        this.apiAdministrationService.hierarchy([this.accountService.id]).subscribe(
            res => {

                this.fieldsValue['subsidiary'] = res.subsidiary ?? null;
                this.fieldsList['subsidiary'] =  this.getDescendents('19999', 'subsidiary');
                if (this.fieldsValue['subsidiary']) {
                    this.fieldsValue['distributor'] = res.distributor ?? null;
                }

                this.fieldsValue['partner'] = res.partner ?? null;
            }
        );
    }

    getDescendents(accountNumber, level) {
        if (this.new && level === 'subsidiary') {
            accountNumber = '19999';
        }
        this.apiAdministrationService.descendents([accountNumber, level]).subscribe(
            res => {
                this.fieldsList[level] = res;
                if (accountNumber === '19999' && level === 'subsidiary') {
                    this.fieldsList[level]['19999'] = 'Ngenio';
                }
            }
        );
    }

    handleHierarchyChange(level) {

    }

}
