export class TextHelper {

    public static checkTitleSep(lang) {
        if (lang === 'fr') {
            return '&nbsp;:';
        } else {
            return ':';
        }
    }

    public static dateFormat(lang) {
        if (lang === 'fr') {
            return 'dd/MM/y';
        } else {
            return 'MM/dd/y';
        }
    }

    public static profiles() {
        return {
            titles: [
                {
                    key: 'gens',
                    name: {
                        fr: 'GÉNÉRALISTES (A/S)',
                        en: 'GENERALISTS (A/S)'
                    },
                },
                {
                    key: 'specs',
                    name: {
                        fr: 'SPÉCIALISTES (S/A)',
                        en: 'SPECIALISTS (S/A)'
                    },
                },
            ],
            gens: [
                {
                    key: 'explorateur',
                    name: {
                        fr: 'L\'Explorateur',
                        en: 'Explorator'
                    },
                    score: [7, 6, 4, 3]
                },
                {
                    key: 'pionnier',
                    name: {
                        fr: 'Pionnier',
                        en: 'The Pioneer'
                    },
                    score: [7, 6, 3, 4]
                },
                {
                    key: 'fonceur',
                    name: {
                        fr: 'Le Fonceur',
                        en: 'The Driver'
                    },
                    score: [7, 4, 3, 4]
                },
                {
                    key: 'architecte',
                    name: {
                        fr: 'L\'Architecte',
                        en: 'The Architect'
                    },
                    score: [7, 4, 3, 6]
                },
                {
                    key: 'chercheur',
                    name: {
                        fr: 'Le Chercheur',
                        en: 'The Researcher'
                    },
                    score: [7, 3, 6, 3]
                },
                {
                    key: 'expressif',
                    name: {
                        fr: 'L\'Expressif',
                        en: 'The Persuader'
                    },
                    score: [6, 7, 3, 4]
                },
                {
                    key: 'promoteur',
                    name: {
                        fr: 'Le Promoteur',
                        en: 'The Promoter'
                    },
                    score: [6, 7, 4, 3]
                },
                {
                    key: 'ambassadeur',
                    name: {
                        fr: 'L\'Ambassadeur',
                        en: 'The Ambassador'
                    },
                    score: [6, 7, 7, 3]
                },
            ],
            specs: [
                {
                    key: 'perfectionniste',
                    name: {
                        fr: 'Le Perfectionniste',
                        en: 'The Perfectionist'
                    },
                    score: [3, 4, 6, 7]
                },
                {
                    key: 'prevoyant',
                    name: {
                        fr: 'Le Prévoyant',
                        en: 'The Supporter'
                    },
                    score: [3, 4, 7, 6]
                },
                {
                    key: 'cooperatif',
                    name: {
                        fr: 'Le Coopératif',
                        en: 'The Collaborator '
                    },
                    score: [3, 6, 7, 6]
                },
                {
                    key: 'facilitateur',
                    name: {
                        fr: 'Le Facilitateur',
                        en: 'The Facilitator'
                    },
                    score: [3, 6, 7, 4]
                },
                {
                    key: 'coordonnateur',
                    name: {
                        fr: 'Le Coordonnateur',
                        en: 'The Coordinator'
                    },
                    score: [3, 7, 3, 7]
                },
                {
                    key: 'analyste',
                    name: {
                        fr: 'L\'Analyste',
                        en: 'The Analyst'
                    },
                    score: [4, 3, 7, 6]
                },
                {
                    key: 'specialiste',
                    name: {
                        fr: 'Le Spécialiste',
                        en: 'The Specialist'
                    },
                    score: [4, 3, 6, 7]
                },
                {
                    key: 'controleur',
                    name: {
                        fr: 'Le Contrôleur',
                        en: 'The Controller'
                    },
                    score: [5, 3, 3, 7]
                }
            ]
        };
    }

    public static checkReportName(name, lang = 'fr') {
        let rName = '';
        switch (name) {
            case 'mpo':
                if (lang === 'fr') {
                    rName = 'Personnalité';
                } else {
                    rName = 'Personality';
                }
                break;
            case 'dit':
                rName = 'Communication';
                break;
            case 'talents':
                rName = 'Talents';
                break;
            case 'satellite':
                rName = 'Satellite';
                break;
            case 'ra':
                if (lang === 'fr') {
                    rName = 'IAC';
                } else {
                    rName = 'CAI';
                }
                break;
            default:
                break;
        }
        return rName;
    }

    public static accountTransferConfirm(lang = 'fr') {
        let paragraph = (lang === 'fr') ?
            'Le transfert entre les comptes a été effectué' :
            'The transfer between accounts has been completed';
        let texth1 = (lang === 'fr') ? 'Succès' : 'Success';
        return {
            paragraph: paragraph,
            texth1: texth1,
            position: 'top-right',
            type: 'green'
        };
    }

    public static errorMessage(lang = 'fr') {
        let paragraph = (lang === 'fr') ? 'Veuillez réessayer' : 'Please try again';
        let texth1 = (lang === 'fr') ? 'Une erreur s\'est produite' : 'There was an error';
        return {
            paragraph: paragraph,
            texth1: texth1,
            position: 'center-center',
            type: 'red'
        };
    }
}
