import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-job-satisfactions-person-modal',
  templateUrl: './job-satisfactions-person-modal.component.html',
  styleUrls: ['./job-satisfactions-person-modal.component.scss']
})
export class JobSatisfactionsPersonModalComponent implements OnInit, OnDestroy {
    @Input() multiAction;

    @Output() multiActionDestroy = new EventEmitter();
    public close: EventEmitter<any> = new EventEmitter();

    searchTerm: string;
    records: Array<any>;
    selectedItems: Array<string>;
    jobSatisfactionRecords: Array<string>;

    toggleEmptyListWarning = false;

    actual = '';

    public loading = false;

    chooseJobSatisfaction: boolean;
    headerText: string;
    jsList: Array<any> = [];

    jsId: string;

    recordsLoaded = 100;
    recordsLength: number;

    private subscriptions = new Subscription();

    constructor(
        public jobSatisfactionsAnalysis: JobSatisfactionsAnalysisService,
        public bsModalRef: BsModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private stateService: StateService,
        private apiPeopleService: ApiPeopleService,
        private apiJobSatisfactionsService: ApiJobSatisfactionsService,
        public translateService: TranslateService
    ) {
    }

    formatDateWithLang(date, lang) {
        if (date) {
            if (lang === 'fr') {
                return date.split('-').reverse().join('/');
            } else {
                return date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0];
            }
        }
        return;
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(null);
    }

    ngOnInit() {
        this.fetchRecords();

        this.jobSatisfactionRecords = [];
        this.selectedItems = [];
        if (this.multiAction && this.multiAction.ids.length > 0) {
            this.jobSatisfactionRecords = this.multiAction.ids;

            this.chooseJobSatisfaction = true;
            this.headerText = 'Les éléments choisis sont sélectionnés ci-dessous';

            this.loading = true;
            this.subscriptions.add(this.apiJobSatisfactionsService.jobSatisfactions([{limit: 100}]).subscribe(
                res => {
                    if (res) {
                        for (let rm of res.data) {
                            this.jsList.push({
                                id: rm.id,
                                name: rm.name
                            });
                        }

                        this.loading = false;
                    }
                }
            ));

        }

        if (this.jobSatisfactionsAnalysis.records) {
            this.jobSatisfactionRecords = this.jobSatisfactionRecords.concat(this.jobSatisfactionsAnalysis.records.map(record => record.id));
            // in case of the string array instead of the objects array
            if (this.jobSatisfactionRecords.length && !this.jobSatisfactionRecords[0]) {
                this.jobSatisfactionRecords = this.jobSatisfactionsAnalysis.records;
            }
            this.selectedItems = this.jobSatisfactionRecords;
        } else {
            this.jobSatisfactionRecords = [];
            this.selectedItems = [];
        }
    }

    assignRmId(val) {
        this.jobSatisfactionsAnalysis.setItemToDisplayWithId(val);
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems.push(id);
        } else {
            this.selectedItems.splice(
                this.selectedItems.indexOf(id),
                1
            );
        }

        this.toggleEmptyListWarning = (this.selectedItems.length === 0);
    }

    onSubmit() {
        if (this.selectedItems.length > 0) {
            const data = {'records': this.selectedItems};
            this.subscriptions.add(this.apiJobSatisfactionsService.putJobSatisfaction([this.jobSatisfactionsAnalysis.id], data).subscribe(
                () => {
                    this.closeModal();
                }
            ));
        }
    }

    closeModal(jsId= this.jobSatisfactionsAnalysis.id) {
        if (this.multiAction === true) {
            this.router.navigate(['/people/list']).then(() => {});
            return;
        }
        this.close.emit({closed: true, id: jsId});
        this.bsModalRef.hide();
    }

    fetchRecords(searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        this.subscriptions.add(this.apiPeopleService.records(
            [{
                'search': searchTerm,
                'skip': skip,
                'isArchived': 0,
                'reportStatuses[mpo]': 1,
                'limit': this.recordsLoaded
            }]
        ).subscribe(
            (res) => {
                // Get records loaded (add the new records from API)
                this.recordsLoaded = (skip) ?
                    this.recordsLoaded + res.data.length :
                    this.recordsLoaded;

                // Records data (merge new records from API)
                this.records = (concat) ?
                    [...this.records, ...res.data] :
                    res.data;

                // Total records
                this.recordsLength = res.count;

                // Reload component
                this.changeDetectorRef.markForCheck();

                this.loading = false;
            }
        ));
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {

            return;
        }
        this.fetchRecords(this.searchTerm, this.recordsLoaded, true);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}
