// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollableList {
  max-height: calc(100vh - 109px);
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/idw-matrices/components/mobile/mobile-idw-matrix-list/mobile-idw-matrix-list.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ","sourcesContent":[".scrollableList {\n    max-height: calc(100vh - 109px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
