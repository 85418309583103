import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Content} from 'src/app/shared/classes/services/content';
import {TranslateService} from '@ngx-translate/core';
import {RightMatchListService} from './right-match-list.service';
import {RightMatchAnalysisService} from './right-match-analysis.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {RightMatchesModalComponent} from '../components/web/right-matches-modal/right-matches-modal.component';
import {RightMatchesPdfModalComponent} from '../components/web/right-matches-pdf-modal/right-matches-pdf-modal.component';
import {RightMatchesPersonModalComponent} from '../components/web/right-matches-person-modal/right-matches-person-modal.component';
import {RightMatchesReportsFiltersModalComponent} from '../components/web/right-matches-reports-filters-modal/right-matches-reports-filters-modal.component';

@Injectable({
    providedIn: 'root'
})
export class RightMatchModalHandlingService extends Content {
    rightMatchModalStatus = 'new';
    rmModalRef: BsModalRef;
    pdfModalRef: BsModalRef;
    personModalRef: BsModalRef;
    reportsFiltersModalRef: BsModalRef;

    constructor(
        protected router: Router,
        public rightMatch: RightMatchAnalysisService,
        public rightMatchService: RightMatchListService,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    openRightMatchModal(config: any) {
        this.rightMatchModalStatus = config.status;
        this.rightMatchService.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.rightMatchService.multiAction,
            status: this.rightMatchModalStatus
        };
        this.rmModalRef = this.modalService.show(RightMatchesModalComponent, {initialState, backdrop : 'static'});
        this.rmModalRef.content.multiActionDestroy.subscribe(res => {
            if (res.destroy) {
                this.rightMatchService.multiAction = null;
            }
        });

        this.rmModalRef.content.close.subscribe(res => {
            if (res.closed && res.id) {
                // if (res.profilerHasChanged) {
                this.rightMatch.setItemToDisplayWithId(res.id, 'rightMatch', 'newAnalysis');
                // }
                this.rightMatchService.getRightMatchList(true);
            }
        });
    }

    openRightMatchPdfModal() {
        this.rightMatchService.cleanMultiselectMode(true);
        this.pdfModalRef = this.modalService.show(RightMatchesPdfModalComponent, {backdrop : 'static'});
    }

    openRightMatchPersonModal() {
        this.rightMatchService.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.rightMatchService.multiAction
        };
        this.personModalRef = this.modalService.show(RightMatchesPersonModalComponent, {initialState, backdrop : 'static'});
        this.personModalRef.content.close.subscribe(res => {
            if (res.closed) {
                this.rightMatch.setItemToDisplayWithId(res.id);
            }
        });
    }

    openRightMatchReportsFiltersModal() {
        this.reportsFiltersModalRef = this.modalService.show(RightMatchesReportsFiltersModalComponent, {backdrop : 'static'});
        return this.reportsFiltersModalRef.content.close;
    }
}
