import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { reportSelectedValidator } from '../validators/reports.validator';
import { PeopleSurveyRequestHelper} from '../components/commonClasses/people-survey-request/people-survey-request.helper';
import { PeopleSurveyRequestStep} from '../components/commonClasses/people-survey-request/people-survey-request-step.enum';
import { ApiAdministrationService } from 'src/app/administration/services/api-administration.service';
import {StateService} from '../../core/services/state/state.service';

@Injectable({
    providedIn: 'root'
})
export class PeopleSurveyRequestFormService {

    public requestStep$: BehaviorSubject<number> = new BehaviorSubject(PeopleSurveyRequestStep.RequestForm);
    public isReportSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isOnlyMpoSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSatelliteSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSubAccountSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isOnlyIACSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public subAccountSelected$: BehaviorSubject<any> = new BehaviorSubject(null);
    public subAccountCreditsString$: BehaviorSubject<string> = new BehaviorSubject('-');
    public subAccountCredits$: BehaviorSubject<number | string> = new BehaviorSubject(0);
    public requestCreditsCost$: BehaviorSubject<number> = new BehaviorSubject(0);
    public hasEnoughCredits$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public displayWarningMessage$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isRequestFormSubmitted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public recordSelected$: BehaviorSubject<any> = new BehaviorSubject(null);
    public langList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public tempUser$: BehaviorSubject<any> = new BehaviorSubject(null);
    public apiMessageError$: BehaviorSubject<string> = new BehaviorSubject('');
    public variantOptions$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public sendingTypeList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public extraData$: BehaviorSubject<any> = new BehaviorSubject({
        requesterData: {
            name: '',
            company: ''
        },
        hasIac: false
    });
    private prbOnlyFormValues: any = null;
    private researchQuestionsForm = false;
    private preferredLanguage: string = null;

    constructor(private apiAdministration: ApiAdministrationService,
                private stateService: StateService
                ) {}

    /**
     * If the current account is a researchAccount, we freeze country, language and
     * research question and set their values to the one given by the researchAccount
     * object for the first two and to true for the third.
     *
     * @param surveyRequestFormGroup
     * @param researchAccount
     */
    public setResearchQuestionsForm(surveyRequestFormGroup, researchAccount) {
        this.researchQuestionsForm = true;

        surveyRequestFormGroup.get('country').setValue(researchAccount.cntry, {onlySelf: true, emitEvent: false});
        surveyRequestFormGroup.get('country').disable({onlySelf: true, emitEvent: false});

        this.langList$.next([
            {
                value: 'fr',
                name: 'Français'
            },
            {
                value: 'en',
                name: 'English'
            }
        ]);

        // Already disabled
        surveyRequestFormGroup.get('language').setValue(researchAccount.lang, {onlySelf: true, emitEvent: false});
        surveyRequestFormGroup.get('researchQuestions').setValue(true, {onlySelf: true, emitEvent: false});

        this.updateSendingTypeList(surveyRequestFormGroup.get('language').value, surveyRequestFormGroup);
    }

    /**
     * Sets researchQuestionForm to false is this is not a research account.
     *
     * NOTE: This function had to be defined because the service
     *       is not destroyed upon logout. So, login a researchAccount,
     *       then logging out, then login in a non-researchAccount
     *       led to erratic behavior.
     */
    notAResearchQuestionsForm() {
        this.researchQuestionsForm = false;
    }


    /**
     * Initialize survey request FormGroup
     * @param sessionData - session data from state
     * @param options - options to manage the form generation
     * @returns FormGroup
     */
    public initSurveyRequestFormGroup(sessionData: any, options: any): UntypedFormGroup {
        let reportGroup = {
            reportGroup: new UntypedFormGroup({
                mpo: new UntypedFormControl(false),
                satellite: new UntypedFormControl(false),
                dit: new UntypedFormControl({value: false, disabled: !options.canCreateDitWithoutMpo}),
                talents: new UntypedFormControl(false),
                iac: new UntypedFormControl(false),
            }, reportSelectedValidator)
        };

        // Satellite Only
        if (options.isSatelliteOnly) {
            reportGroup = {
                reportGroup: new UntypedFormGroup({
                    mpo: new UntypedFormControl({value: false, disabled: true}),
                    satellite: new UntypedFormControl(false),
                    dit: new UntypedFormControl({value: false, disabled: true}),
                    talents: new UntypedFormControl({value: false, disabled: true}),
                    iac: new UntypedFormControl({value: false, disabled: true}),
                }, reportSelectedValidator)
            };
        }
        return new UntypedFormGroup({
            // Report
            ...reportGroup,

            // Options
            satelliteJobId: new UntypedFormControl(null),
            recordId: new UntypedFormControl(),
            variant: new UntypedFormControl({value: null, disabled: true}),
            country: new UntypedFormControl(null),
            language: new UntypedFormControl(null),
            locale: new UntypedFormControl(null, Validators.required),

            // Participant
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            email: new UntypedFormControl(null, [Validators.required, Validators.email]),
            researchQuestions: new UntypedFormControl({value: false, disabled: true}),

            // Survey request
            jobTitle: new UntypedFormControl(),
            organisation: new UntypedFormControl(null, (this.stateService.session.sessionData.accountData.type === 'bizdev'
                || this.stateService.session.sessionData.accountData.type === 'discretionary'
            ) ? Validators.required : null),
            recordType: new UntypedFormControl(),
            subAccount: new UntypedFormControl(null, Validators.required),
            department: new UntypedFormControl(),
            jobId: new UntypedFormControl(),
            requestedByName: new UntypedFormControl(sessionData.userData.fullName),
            requestedBy: new UntypedFormControl(sessionData.userData.username),
            requester: new UntypedFormControl(),
            sendingType: new UntypedFormControl(null, Validators.required),
            autoGen: new UntypedFormControl({value: true, disabled: true}),
        });
    }

    /**
     * Process on form values change
     * @param surveyRequestFormGroup - Form group
     * @param notifier$ - Notifier to close this observable
     * @param options - Options to manage process (options.subAccounts for example)
     */
    public onValuesChange(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>, options: any): void {
        // Report value change
        this.updateReportGroupProcess(surveyRequestFormGroup, notifier$, options.canCreateDitWithoutMpo);

        if (!this.researchQuestionsForm) {
            // Country value change
            this.updateCountryProcess(surveyRequestFormGroup, notifier$, options.languages);

            // Language value change
            this.updateLanguageProcess(surveyRequestFormGroup, notifier$);
        }

        // IAC changed
        surveyRequestFormGroup.get('reportGroup').get('iac').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(iac => {
            let finalLangList = options.languages;
            if (iac) {
                finalLangList = finalLangList.filter((item: any) => item.value !== 'es');
                finalLangList = finalLangList.filter((item: any) => item.value !== 'ar');
            }
            if (surveyRequestFormGroup.get('country').value !== 'ma') {
                finalLangList = finalLangList.filter((item: any) => item.value !== 'ar');
            }
            this.langList$.next(finalLangList);
        });

        // Variant change
        surveyRequestFormGroup.get('variant').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(variant => {
            // Credits cost
            this.updateCreditsCost(surveyRequestFormGroup.get('reportGroup').value, variant, surveyRequestFormGroup.get('autoGen').value);

            // PRB only
            if (variant === '0,1') {
                this.updateRequestStep(PeopleSurveyRequestStep.RecordListPrbOnly);
            }
        });

        // SubAccount change
        this.updateSubAccountIdProcess(surveyRequestFormGroup, notifier$, options.accountId);

        // Autogen change
        surveyRequestFormGroup.get('autoGen').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(autoGen => {
            // Credits cost
            this.updateCreditsCost(surveyRequestFormGroup.get('reportGroup').value, surveyRequestFormGroup.get('variant').value, autoGen);
        });

        // Check if there are enough credits
        this.checkHasEnougCredits(notifier$);

        // Check if we display the warning message and enable auto-gen checkbox
        this.checkCreditsValidation(surveyRequestFormGroup, notifier$);


        // Do not check at the form initialization (pristine)
        if (!surveyRequestFormGroup.pristine) {
            // On record select
            this.recordSelectedProcess(surveyRequestFormGroup, notifier$);
        }
    }

    /**
     * Process on reportGroup changes
     * @param surveyRequestFormGroup
     * @param notifier$
     * @param canCreateDitWithoutMpo
     */
    private updateReportGroupProcess(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>, canCreateDitWithoutMpo: boolean): void {
        surveyRequestFormGroup.get('reportGroup').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(reports => {
            // Trigger Observables depending on report selection
            this.checkReportSelected(reports);

            this.updateDitCheckbox((surveyRequestFormGroup.get('reportGroup') as UntypedFormGroup), reports.mpo, canCreateDitWithoutMpo);
            this.updateSurveyType(surveyRequestFormGroup, reports);

            // Credits cost
            this.updateCreditsCost(reports, surveyRequestFormGroup.get('variant').value, surveyRequestFormGroup.get('autoGen').value);

            this.updateSendingTypeList(surveyRequestFormGroup.get('language').value, surveyRequestFormGroup);

            // Reload FormGroup with good values because we do not emit the values from updateDitCheckbox() and updateSurveyType()
            surveyRequestFormGroup.get('reportGroup').updateValueAndValidity();
        });
    }

    /**
     * Process on country changes
     * @param surveyRequestFormGroup
     * @param notifier$
     * @param languages
     */
    private updateCountryProcess(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>, languages: any[]): void {
        surveyRequestFormGroup.get('country').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(country => {
            this.updateLangField(surveyRequestFormGroup, !!country);

            let finalLangList: any[] = languages;

            if (country) {
                // Maghreb
                if (country === 'ma') {
                    finalLangList = finalLangList.filter((item: any) => item.value !== 'es');
                }

                if (country !== 'ma') {
                    finalLangList = finalLangList.filter((item: any) => item.value !== 'ar');
                }
            }

            if (surveyRequestFormGroup.get('reportGroup').get('iac').value) {
                finalLangList = finalLangList.filter((item: any) => item.value !== 'es');
                finalLangList = finalLangList.filter((item: any) => item.value !== 'ar');
            }

            this.langList$.next(finalLangList);

            // Choisi la langue de preference dansle formulaire
            if (finalLangList.some(item => item.value === this.preferredLanguage)) {
                surveyRequestFormGroup.get('language').setValue(this.preferredLanguage);
            }
        });
    }

    /**
     * Process on language changes
     * @param surveyRequestFormGroup
     * @param notifier$
     */
    private updateLanguageProcess(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>): void {
        surveyRequestFormGroup.get('language').valueChanges.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(language => {
            this.updateSendingTypeList(language, surveyRequestFormGroup);

            // Reset sending type
            // surveyRequestFormGroup.get('sendingType').setValue('');

            // Reset research questions
            this.updateResearchQuestions(surveyRequestFormGroup, language);
        });
    }

    /**
     * Process on subAccount changes
     * Trigger Observables on subAccountId changes
     * @param surveyRequestFormGroup
     * @param notifier$
     * @param sessionAccountId
     */
    private updateSubAccountIdProcess(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>, sessionAccountId: string): void {
        surveyRequestFormGroup.get('subAccount').valueChanges.pipe(takeUntil(notifier$)).subscribe((subAccountId: string) => {
            if (subAccountId !== null && subAccountId !== undefined) {
                subAccountId = subAccountId.toString();
            }
            if (subAccountId && subAccountId !== '') {
                this.apiAdministration.clientSubaccountCredits([sessionAccountId, subAccountId]).subscribe(
                    (accountInfo: any) => {
                        this.subAccountSelected$.next(subAccountId);
                        const subAccountCredits: number | string = PeopleSurveyRequestHelper.getSubAccountCredits(accountInfo.credits, accountInfo.unlimited);
                        const subAccountCreditsString: string = (subAccountCredits === 'unlimited' ) ? 'commons.creditsUnlimitedList' : subAccountCredits.toString();

                        this.updateSubAccountCreditsString(subAccountCreditsString);
                        this.subAccountCredits$.next(subAccountCredits);
                        this.isSubAccountSelected$.next(true);
                    }
                );
            } else {
                this.updateSubAccountCreditsString('-');
                this.subAccountCredits$.next(0);
                this.isSubAccountSelected$.next(false);
                this.subAccountSelected$.next(null);
            }
        });
    }

    /**
     * Process on record selected
     * @param surveyRequestFormGroup
     * @param notifier$
     */
    private recordSelectedProcess(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>): void {
        this.recordSelected$.pipe(takeUntil(notifier$), distinctUntilChanged(isEqual)).subscribe(record => {
            if (record !== null) {
                // fill form with good values
                const {country, language} = PeopleSurveyRequestHelper.getLangAndCountryFromNorm(record.evaluations.mpo.info.norm);
                surveyRequestFormGroup.get('firstName').setValue(record.firstName, {onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('lastName').setValue(record.lastName, {onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('country').setValue(country);
                surveyRequestFormGroup.get('language').setValue(language, {onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('recordId').setValue(record.id, {onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('jobTitle').setValue(record.jobTitle, {onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('organisation').setValue(record.organisation, {onlySelf: true, emitEvent: false});

                // Force subAccount change detection
                surveyRequestFormGroup.get('subAccount').setValue(record.subAccount);

                // Set PRB only form values before disabling data
                this.prbOnlyFormValues = surveyRequestFormGroup.value;

                // Disable fields
                surveyRequestFormGroup.get('reportGroup').disable({onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('variant').disable({onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('country').disable({onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('language').disable({onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('firstName').disable({onlySelf: true, emitEvent: false});
                surveyRequestFormGroup.get('lastName').disable({onlySelf: true, emitEvent: false});
            }

            surveyRequestFormGroup.updateValueAndValidity();
        });
    }

    /**
     * Update Survey type depending on report choice
     * @param surveyRequestFormGroup
     * @param reports
     */
    private updateSurveyType(surveyRequestFormGroup: UntypedFormGroup, reports: any): void {
        if (PeopleSurveyRequestHelper.isOnlyIACSelected(reports) || !PeopleSurveyRequestHelper.isReportSelected(reports)) {
            surveyRequestFormGroup.get('variant').setValue(null, {onlySelf: true, emitEvent: false});
            surveyRequestFormGroup.get('variant').disable({onlySelf: true, emitEvent: false});
            if (PeopleSurveyRequestHelper.isOnlyIACSelected(reports)) {
                surveyRequestFormGroup.get('variant').setValue('0,0', {onlySelf: true, emitEvent: false});
            }
        } else {
            if (PeopleSurveyRequestHelper.isReportSelected(reports) && surveyRequestFormGroup.get('variant').disabled) {
                surveyRequestFormGroup.get('variant').enable({onlySelf: true, emitEvent: false});
            }

            if (!PeopleSurveyRequestHelper.isSatelliteSelected(reports) && surveyRequestFormGroup.get('satelliteJobId').value !== null) {
                surveyRequestFormGroup.get('satelliteJobId').setValue(null, {onlySelf: true, emitEvent: false});
            }

            // Select variant, on report change set to 1,1 (except for talents ?)
            if (
                PeopleSurveyRequestHelper.canSelectOnlyTraitsOnly(reports)
            ) {
                surveyRequestFormGroup.get('variant').setValue('1,0', {onlySelf: true, emitEvent: false});
            } else {
                surveyRequestFormGroup.get('variant').setValue('1,1', {onlySelf: true, emitEvent: false});
            }
        }
    }

    /**
     * Check if a report is selected and trigger Observables
     * @param {object} reports - Form field object with values
     */
    private checkReportSelected(reports: any): void {
        // Set variant option
        this.updateVariantOptions(reports);

        this.isReportSelected$.next(PeopleSurveyRequestHelper.isReportSelected(reports));
        this.isOnlyMpoSelected$.next(PeopleSurveyRequestHelper.isOnlyMpoSelected(reports));
        this.isSatelliteSelected$.next(PeopleSurveyRequestHelper.isSatelliteSelected(reports));
        this.isOnlyIACSelected$.next(PeopleSurveyRequestHelper.isOnlyIACSelected(reports));
    }

    /**
     * Check if there is enough credits and trigger an Observable
     * @param notifier$
     */
    private checkHasEnougCredits(notifier$: Subject<null>): void {
        combineLatest(this.requestCreditsCost$, this.subAccountCredits$).pipe(
            takeUntil(notifier$), distinctUntilChanged(isEqual)
        ).subscribe(([creditsCost, creditsAvailable]) => {
            this.hasEnoughCredits$.next(PeopleSurveyRequestHelper.hasEnoughCredits(creditsCost, creditsAvailable));
        });
    }

    /**
     * Check the credits validation, all the conditions are met to use the credits ?
     * @param surveyRequestFormGroup
     * @param notifier$
     */
    private checkCreditsValidation(surveyRequestFormGroup: UntypedFormGroup, notifier$: Subject<null>): void {
        combineLatest([this.isReportSelected$, this.isSubAccountSelected$, this.hasEnoughCredits$]).pipe(
            takeUntil(notifier$), distinctUntilChanged(isEqual)
        ).subscribe(([isReportSelected, isSubAccountSelected, hasEnoughCredits]) => {
            // Display warning message
            const displayWarningMessage: boolean = isReportSelected && isSubAccountSelected && !hasEnoughCredits;
            this.displayWarningMessage$.next(displayWarningMessage);

            // Enable auto-generation
            const enableAutoGen: boolean = isReportSelected && isSubAccountSelected && hasEnoughCredits;
            this.updateAutoGenCheckbox(surveyRequestFormGroup, enableAutoGen);
        });
    }

    /**
     * Manage DIT checkbox depending on MPO status
     * @param reportFormGroup
     * @param isMpoChecked
     * @param canCreateDitWithoutMpo
     */
    private updateDitCheckbox(reportFormGroup: UntypedFormGroup, isMpoChecked: boolean, canCreateDitWithoutMpo: boolean): void {
        if (!canCreateDitWithoutMpo) {
            if (isMpoChecked && reportFormGroup.get('dit').disabled) {
                reportFormGroup.get('dit').enable({onlySelf: true, emitEvent: false});
            }

            if (!isMpoChecked && reportFormGroup.get('dit').enabled) {
                reportFormGroup.get('dit').disable({onlySelf: true, emitEvent: false});
                reportFormGroup.get('dit').setValue(false, {onlySelf: true, emitEvent: false});
            }
        }
    }

    public setPreferredLanguage(lang: string) {
        this.preferredLanguage = lang;
    }

    /**
     * Manage language field
     * @param surveyRequestFormGroup
     * @param isCountrySelected
     */
    private updateLangField(surveyRequestFormGroup: UntypedFormGroup, isCountrySelected: boolean): void {
        if (isCountrySelected && surveyRequestFormGroup.get('language').disabled) {
            surveyRequestFormGroup.get('language').enable();
        }

        if (!isCountrySelected && surveyRequestFormGroup.get('language').enabled) {
            surveyRequestFormGroup.get('language').disable();
        }

        surveyRequestFormGroup.get('language').setValue(null);
    }

    /**
     * Manage the auto generation checkbox
     * @param surveyRequestFormGroup
     * @param enableAutoGen
     */
    private updateAutoGenCheckbox(surveyRequestFormGroup: UntypedFormGroup, enableAutoGen: boolean): void {
        if (enableAutoGen && surveyRequestFormGroup.get('autoGen').disabled) {
            surveyRequestFormGroup.get('autoGen').enable({onlySelf: true, emitEvent: false});
            surveyRequestFormGroup.get('autoGen').setValue(true, {onlySelf: true, emitEvent: false});
        }

        if (!enableAutoGen && surveyRequestFormGroup.get('autoGen').enabled) {
            surveyRequestFormGroup.get('autoGen').disable({onlySelf: true, emitEvent: false});
            surveyRequestFormGroup.get('autoGen').setValue(false, {onlySelf: true, emitEvent: false});
        }
    }

    /**
     * Update the credits cost Observable
     * @param reports - Reports object
     * @param variant
     * @param autoGen
     * @param firstPrb - Is is a first PRB
     */
    public updateCreditsCost(reports: any, variant: string, autoGen: boolean, firstPrb = true): void {
        this.requestCreditsCost$.next(PeopleSurveyRequestHelper.getCreditCost(reports, variant, firstPrb));
    }

    /**
     * Update the Observable that manages the sub account credits string
     * @param creditsString
     */
    public updateSubAccountCreditsString(creditsString: string): void {
        this.subAccountCreditsString$.next(creditsString);
    }

    /**
     * Update request step Observable
     * @param step
     */
    public updateRequestStep(step: number): void {
        this.requestStep$.next(step);
    }

    /**
     * Update the Observable that checks if the form is submitted
     * @param isSubmitted
     */
    public updateRequestFormSubmission(isSubmitted: boolean): void {
        this.isRequestFormSubmitted$.next(isSubmitted);
    }

    /**
     * Update the Observable with the selected record (for PRB)
     * @param record
     */
    public updateRecordSelected(record: any): void {
        this.recordSelected$.next(record);
    }

    /**
     * Update temp user Observable
     * The temp user is the user created to fill the online survey
     * @param tempUser
     */
    public updateTempUser(tempUser: any): void {
        this.tempUser$.next(tempUser);
    }

    /**
     * Update the Observable with the API error for the main request form
     * @param apiMEssageError
     */
    public updateApiErrorMessage(apiMEssageError: string): void {
        this.apiMessageError$.next(apiMEssageError);
    }

    /**
     * Update the Observable with the requester data
     * @param extraData
     */
    public updateExtraData(extraData: any): void {
        this.extraData$.next({...extraData});
    }

    /**
     * Update the Variant options Observable depending on the report selected
     * @param reports
     */
    private updateVariantOptions(reports: any): void {
        if (PeopleSurveyRequestHelper.isOnlyMpoSelected(reports)) {
            this.variantOptions$.next([{value: '1,1', name: 'people.commons.traitsAndPrb'}, {value: '1,0', name: 'people.commons.traitsOnly'}]);
        } else if (
            PeopleSurveyRequestHelper.canSelectOnlyTraitsOnly(reports)
        ) {
            this.variantOptions$.next([{value: '1,0', name: 'people.commons.traitsOnly'}]);
        } else {
            this.variantOptions$.next([{value: '1,1', name: 'people.commons.traitsAndPrb'}, {value: '1,0', name: 'people.commons.traitsOnly'}]);
        }
    }

    /**
     * Update the sending type list Observable depending on the lang selected
     * @param lang
     * @param surveyRequestFormGroup
     */
    private updateSendingTypeList(lang: string, surveyRequestFormGroup = null): void {
        let sendingOptions = [
            {value: 'cutpaste', name: 'commons.cutnpaste'},
            {value: 'email', name: 'commons.sendByMyEmail'},
            {value: 'server', name: 'commons.sendByServer'},
            {value: 'paper', name: 'commons.fillOnline'}
        ];
        if (lang === 'ar') {
            sendingOptions = [
                {value: 'cutpaste', name: 'commons.cutnpaste'},
                {value: 'server', name: 'commons.sendByServer'},
                {value: 'paper', name: 'commons.fillOnline'}
            ];
        }
        if (surveyRequestFormGroup &&
            surveyRequestFormGroup.get('reportGroup') &&
            surveyRequestFormGroup.get('reportGroup').value &&
            surveyRequestFormGroup.get('reportGroup').value.iac) {
            // remove paper option if iac is selected
            sendingOptions.splice((sendingOptions.length - 1), 1);
        }

        if (lang === '') {
            this.sendingTypeList$.next([]);
        } else {
            this.sendingTypeList$.next(sendingOptions);
        }
    }

    /**
     * Update research questions checkbox
     * @param surveyRequestFormGroup
     * @param lang
     */
    private updateResearchQuestions(surveyRequestFormGroup: UntypedFormGroup, lang: string): void {
        const locale = (lang && surveyRequestFormGroup.get('country') && surveyRequestFormGroup.get('country').value)
            ? `${surveyRequestFormGroup.get('country').value.toUpperCase()}_${lang.toLowerCase()}`
            : '';
        const displayResearchQuestions = ['CA_en', 'CA_fr', 'EU_fr', 'FR_fr', 'US_en'].includes(locale);

        // Enable field condition met and disabled (to not enable it each times and trigger form events)
        if (displayResearchQuestions && surveyRequestFormGroup.get('researchQuestions').disabled) {
            surveyRequestFormGroup.get('researchQuestions').enable();
        }

        // Disable field
        if (!displayResearchQuestions && surveyRequestFormGroup.get('researchQuestions').enabled) {
            surveyRequestFormGroup.get('researchQuestions').setValue(false, {onlySelf: true, emitEvent: false});
            surveyRequestFormGroup.get('researchQuestions').disable();
        }
    }

    /**
     * Get the data if we select PRB only (variant 0,1)
     * The fields are disabled, so ReactiveForm does not send it in the form object
     */
    public getPrbOnlyFormValues(): any {
        return this.prbOnlyFormValues;
    }

    /**
     * Reset form values to a good state and Observables
     * @param surveyRequestFormGroup
     */
    public resetFormProcess(surveyRequestFormGroup: UntypedFormGroup): void {
        this.resetObservables();
        this.resetFormValues(surveyRequestFormGroup);
    }

    private resetObservables(): void {
        this.updateRequestStep(PeopleSurveyRequestStep.RequestForm);
        this.updateRequestFormSubmission(false);
        this.updateTempUser(null);
        this.updateRecordSelected(null);
        this.updateCreditsCost(null, null, false);
        this.updateSubAccountCreditsString('-');
        this.updateApiErrorMessage('');
    }

    private resetFormValues(surveyRequestFormGroup: UntypedFormGroup): void {
        if (surveyRequestFormGroup.get('reportGroup').disabled) {
            surveyRequestFormGroup.get('reportGroup').enable({onlySelf: true, emitEvent: false});
        }
        if (surveyRequestFormGroup.get('variant').disabled) {
            surveyRequestFormGroup.get('variant').enable({onlySelf: true, emitEvent: false});
        }
        if (surveyRequestFormGroup.get('country').disabled) {
            surveyRequestFormGroup.get('country').enable({onlySelf: true, emitEvent: false});
        }
        if (surveyRequestFormGroup.get('language').disabled) {
            surveyRequestFormGroup.get('language').enable({onlySelf: true, emitEvent: false});
        }
        if (surveyRequestFormGroup.get('firstName').disabled) {
            surveyRequestFormGroup.get('firstName').enable({onlySelf: true, emitEvent: false});
        }
        if (surveyRequestFormGroup.get('lastName').disabled) {
            surveyRequestFormGroup.get('lastName').enable({onlySelf: true, emitEvent: false});
        }

        surveyRequestFormGroup.reset();
        surveyRequestFormGroup.get('autoGen').setValue(true);
    }

    /**
     * Send another request process, clear only unwanted fields
     * @param surveyRequestFormGroup
     * @param partialReset
     */
    public sendAnotherProcess(surveyRequestFormGroup: UntypedFormGroup, partialReset = false): void {
        this.updateRequestStep(PeopleSurveyRequestStep.RequestForm);
        this.updateRequestFormSubmission(false);
        this.updateApiErrorMessage('');
        this.isSatelliteSelected$.next(false);

        surveyRequestFormGroup.get('firstName').reset();
        surveyRequestFormGroup.get('lastName').reset();
        surveyRequestFormGroup.get('email').reset();
        const reportGroup = surveyRequestFormGroup.get('reportGroup');
        if (!partialReset) {
            if (reportGroup) {
                if (reportGroup.get('mpo')) {
                    reportGroup.get('mpo').reset();
                }
                if (reportGroup.get('dit')) {
                    reportGroup.get('dit').reset();
                }
                if (reportGroup.get('satellite')) {
                    reportGroup.get('satellite').reset();
                }
                if (reportGroup.get('talents')) {
                    reportGroup.get('talents').reset();
                }
                if (reportGroup.get('iac')) {
                    reportGroup.get('iac').reset();
                }
            }
            if (surveyRequestFormGroup.get('satelliteJobId')) {
                surveyRequestFormGroup.get('satelliteJobId').reset();
            }
            surveyRequestFormGroup.get('variant').reset();
            surveyRequestFormGroup.get('country').reset();
            surveyRequestFormGroup.get('language').reset();
            surveyRequestFormGroup.get('locale').reset();
            surveyRequestFormGroup.get('jobTitle').reset();
            surveyRequestFormGroup.get('organisation').reset();
            surveyRequestFormGroup.get('recordType').reset();
            surveyRequestFormGroup.get('subAccount').reset();
            surveyRequestFormGroup.get('department').reset();
            surveyRequestFormGroup.get('jobId').reset();
            surveyRequestFormGroup.get('requestedByName').reset();
            surveyRequestFormGroup.get('requestedBy').reset();
            if (surveyRequestFormGroup.get('requester')) {
                surveyRequestFormGroup.get('requester').reset();
            }
            surveyRequestFormGroup.get('sendingType').reset();
            surveyRequestFormGroup.get('autoGen').reset();
        }
    }
}
