// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#innerTextServer {
  width: 100%;
  border: 1px solid #BBB;
  padding: 10px;
  height: 200px;
  overflow-y: scroll;
}

.ar {
  direction: rtl;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/send-methods/ngenio-server/ngenio-server.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EAAgB,YAAA;AAEpB","sourcesContent":["#innerTextServer {\n    width: 100%;\n    border: 1px solid #BBB;\n    padding: 10px;\n    height: 200px;\n    overflow-y: scroll;\n}\n\n.ar {\n    direction: rtl; float: right\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
