/**********************************************************************************************
 * ############################################################################################
 *
 *      WARNING ::
 *
 *      The following scripts uses httpClientModule to directly use PHP scripts. it DOES NOT
 *      use the API directly.
 *
 * ############################################################################################
 **********************************************************************************************/

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {EnvService} from '../../../app/services/env.service';

@Component({
    selector: 'app-profile-search',
    templateUrl: './profile-search.component.html',
    styleUrls: ['./profile-search.component.scss']
})
export class ProfileSearchComponent implements OnInit, OnDestroy {

    firstName: string;
    lastName: string;

    warning = {
        firstName: false,
        lastName: false
    };
    loading = false;
    loadingGif = '/assets/spiner.gif';
    results: any;

    subscriptions = new Subscription();

    constructor(
        private httpClient: HttpClient,
        private cd: ChangeDetectorRef,
        private environment: EnvService
    ) {
    }

    ngOnInit() {
    }

    inputOnChange(event) {
        if (event && event.keyCode === 13) {
            this.searchForRecords();
        }
    }

    searchForRecords() {
        if (this.firstName || this.lastName) {
            this.loading = true;
            this.results = undefined;
            let suffix = '';
            if (this.firstName) {
                suffix += 'firstName=' + this.firstName + '&';
            }
            if (this.lastName) {
                suffix += 'lastName=' + this.lastName + '&';
            }
            suffix = suffix.substring(0, suffix.length - 1);
            this.subscriptions.add(this.httpClient.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/fetchRecords?' + suffix).subscribe(
                res => {
                    this.loading = false;
                    this.results = Object.values(res);
                    this.cd.markForCheck();
                }
            ));
            this.warning.firstName = false;
            this.warning.firstName = false;


        } else {
            this.warning.firstName = true;
            this.warning.firstName = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
