import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import {PeopleSurveyRequestSendMethodsHelper} from '../send-methods.helper';
import { PeopleSurveyRequestFormService } from '../../../../../services/people-survey-request-form.service';

@Component({
    selector: 'app-mobile-send-methods',
    templateUrl: './mobile-send-methods.component.html',
    styleUrls: ['./mobile-send-methods.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSendMethodsComponent implements OnInit, OnDestroy {
    @Input() sendMethod: string;
    @Input() tempUser: any;
    @Output() closeSendMethods: EventEmitter<any> = new EventEmitter();
    @Output() sendAnotherRequest: EventEmitter<any> = new EventEmitter();

    public onDestroy$: Subject<any> = new Subject();
    public instructionsMessages: any;
    public surveyLink: string;
    public decodedSurveyLink: string;
    public buttonText: string;
    public formSubmit$: Subject<any> = new Subject();
    @Input() public sendAnotherSurvey = false;
    public extraData$: Observable<any>;

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService
    ) {}

    ngOnInit() {
        this.getInstructionsMessages();

        this.buttonText = PeopleSurveyRequestSendMethodsHelper.getButtonTranslation(this.sendMethod, this.sendAnotherSurvey);

        this.surveyLink = PeopleSurveyRequestSendMethodsHelper.getSurveyLink(
            this.tempUser.country, this.tempUser.language, this.tempUser.username, this.tempUser.tmpPwd, this.tempUser.isSatelliteOnly
        );
        this.decodedSurveyLink = decodeURIComponent(this.surveyLink);
    }

    ngOnDestroy() {
        this.formSubmit$.complete();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public toggleSendAnotherSurvey(): void {
        this.sendAnotherSurvey = !this.sendAnotherSurvey;
        this.buttonText = PeopleSurveyRequestSendMethodsHelper.getButtonTranslation(this.sendMethod, this.sendAnotherSurvey);
    }

    public submit(): void {
        this.formSubmit$.next();
    }

    public afterSubmission(event = null): void {
        if (this.sendAnotherSurvey) {
            this.sendAnotherRequest.emit();
        } else {
            this.closeSendMethods.emit(event);
        }
    }

    public cancelRequest() {
        this.closeSendMethods.emit({submitted: true});
    }

    private getInstructionsMessages(): void {
        this.peopleRequestFormService.extraData$.pipe(
            takeUntil(this.onDestroy$), distinctUntilChanged(isEqual)
        ).subscribe((requesterData: any) => {
            let hasIac = false;
            if (this.tempUser && this.tempUser.additionalData &&
                this.tempUser.additionalData.evaluationRequestType &&
                this.tempUser.additionalData.evaluationRequestType.includes('ra')) {
                hasIac = true;
            }
            this.instructionsMessages = PeopleSurveyRequestSendMethodsHelper.getInstructionsMessages(this.tempUser.language, this.tempUser.variant, {extraData: {requesterData: requesterData, hasIac: hasIac}});
        });
    }
}
