import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-mini-satellite-graph',
    templateUrl: './mini-satellite-graph.component.html',
    styleUrls: ['./mini-satellite-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniSatelliteGraphComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('MiniSatelliteGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id = '';
    @Input() score: any;
    @Input() from;

    private subscriptions = new Subscription();

    constructor(
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        // this.createMiniSatelliteGraph();
    }

    ngOnChanges(changes) {
        this.createMiniSatelliteGraph();
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    createMiniSatelliteGraph() {
        if (this.from === 'report') {
            d3.select('.MiniSatelliteGraph').remove();
        } else {
            d3.select('#MiniSatelliteGraph_' + this.id).remove();
        }

        let inactive = (!this.score);
        let notCompleted = (this.translate.currentLang === 'fr') ? 'Non complété' : 'Not completed';
        let textCategory = (inactive) ? notCompleted : this.score;
        let element = this.chartContainer.nativeElement;
        let svg = d3.select(element).append('svg').attr('id', '#MiniSatelliteGraph_' + this.id).
            attr('preserveAspectRatio', 'xMidYMid meet').
            attr('width', 60).
            attr('height', 60).
            attr('viewBox', '0 0 60 60').
            attr('class', 'MiniSatelliteGraph');


        let endAngle = 0.1;

        if (this.score && this.score !== 0 && this.score !== 'noJobProfile') {
            endAngle = 2 * Math.PI * this.score / 5;
        } else if (this.score === 'noJobProfile') {
            endAngle = 0;
        }

        let arcBG = d3.arc().innerRadius(19).outerRadius(23).startAngle(0).endAngle(2 * Math.PI);
        svg.append('path').attr('transform', 'translate(30,30)').attr('class', 'arc').attr('d', arcBG).attr('fill', '#CCC');

        svg.append('circle').attr('cx', 30).attr('cy', 30).attr('r', 28).style('fill', 'white').
            style('stroke-width', 1.5).
            style('stroke', '#E2E2E2');

        if (!inactive) {
            let arc = d3.arc().innerRadius(21).outerRadius(27).startAngle(0).endAngle(-endAngle);
            svg.append('path').attr('transform', 'translate(30,30)').attr('class', 'arc').attr('d', arc).attr('fill', '#AAA');
        }
        // console.log(this.translate.currentLang);
        if (this.score === 'noJobProfile') {

            if (this.translate.currentLang === 'fr') {
                svg.append('text').attr('x', 30).
                attr('y', 30).
                text('Choisir un').
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 10).
                attr('fill', 'rgb(85, 85, 85)');
                svg.append('text').attr('x', 30).
                attr('y', 42).
                text('poste').
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 10).
                attr('fill', 'rgb(85, 85, 85)');
            } else {
                svg.append('text').attr('x', 30).
                attr('y', 30).
                text('Choose a ').
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 10).
                attr('fill', 'rgb(85, 85, 85)');
                svg.append('text').attr('x', 30).
                attr('y', 42).
                text('job').
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 10).
                attr('fill', 'rgb(85, 85, 85)');
            }

        } else if (!inactive) {
            svg.append('text').attr('x', 30).
                attr('y', 36).
                text(textCategory).
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 16).
                attr('fill', 'rgb(227, 94, 36)');
        } else {
            svg.append('text').attr('x', 30).
                attr('y', 31).
                text(textCategory).
                attr('text-anchor', 'middle').
                attr('font-family', 'sans-serif').
                attr('font-size', 8).
                attr('fill', '#888');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
