import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-indicator-graph',
    templateUrl: './indicator-graph.component.html',
    styleUrls: ['./indicator-graph.component.scss']
})
export class IndicatorGraphComponent implements OnChanges {

    @ViewChild('SixLevelSmallGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id;
    @Input() display: any;
    @Input() score: any = 3;
    @Input() over: any = 6;
    @Input() color = 'green';

    constructor(
        public translate: TranslateService,
        public stateService: StateService
    ) {
    }

    ngOnChanges(changes) {
        if (changes && changes.id && changes.id.previousValue) {
            d3.select('#SixLevelSmallGraph' + changes.id.previousValue).remove();
        }

        this.sixLevelSmallGraph();
    }

    sixLevelSmallGraph() {

        this.score = (typeof this.score === 'undefined') ? null : this.score;

        const colors = {
            green : 'rgb(119,198,175)',
            red   : 'rgb(240,123,126)',
            blue  : 'rgb(119,156,197)',
            yellow: 'rgb(238,207,123)',
            gray: '#E2E2E2',
            greyBlack: '#aaa'
        };
        // console.log(this.score);
        let textCategory = '';
        let inactiveTxt = [];
        textCategory = (this.display) ? this.display : this.score;
        let element = this.chartContainer.nativeElement;
        let svg = d3.select(element).append('svg')
            .attr('id', 'SixLevelSmallGraph' + this.id)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 140 140');
        let endAngle = 0;

        if (this.score === null) {
            textCategory = '—';
        } else if (this.score === 0) {
            endAngle = 0.1;
        } else {
            endAngle = 2 * Math.PI * this.score / this.over;
        }

        if (
            this.color !== 'green' &&
            this.color !== 'red' &&
            this.color !== 'blue' &&
            this.color !== 'yellow' &&
            this.color !== 'greyBlack' &&
            this.color !== null
        ) {
            throw 'Invalid color!';
        }

        let mainColor = colors[this.color];

        svg.append('circle')
            .attr('cx', '70')
            .attr('cy', '70')
            .attr('r', '70').attr('stroke', 'white')
            .attr('stroke-width', 0)
            .style('fill', 'white');

        let arcBG = d3.arc().innerRadius(45).outerRadius(70).startAngle(0).endAngle(2 * Math.PI);
        svg.append('path')
            .attr('transform', 'translate(70,70)')
            .attr('class', 'arc')
            .attr('d', arcBG)
            .attr('fill', '#e3e3e3');

        if (this.score !== null) {
            let arc = d3.arc().innerRadius(45).outerRadius(70).startAngle(0).endAngle(-endAngle);
            svg.append('path')
                .attr('transform', 'translate(70,70)')
                .attr('class', 'arc')
                .attr('d', arc)
                .attr('fill', mainColor);
        }

        svg.append('text')
            .attr('x', 70)
            .attr('y', 95)
            .text(textCategory)
            .attr('text-anchor', 'middle')
            .attr('font-family', 'sans-serif')
            .attr('font-size', 65)
            .attr('fill', '#6a6a6a');

    }
}
