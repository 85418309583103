import {
    Component, OnChanges, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange, DoCheck, OnDestroy
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AlertService} from '../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {main} from '@angular/compiler-cli/src/main';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            transition(':enter', [
                style({
                    opacity: 0
                }),
                animate('1s ease-in-out', style({
                    opacity: 1
                }))
            ]),
            transition(':leave', [
                style({
                    opacity: 1
                }),
                animate('1s ease-in-out', style({
                    opacity: 0
                }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnChanges  {

    @Input() configGlobal: any;
    config: any;

    lastTicker: number;

    display: boolean;
    backdrop: boolean;

    x_left_pos: string;

    margin_top_correction: number = null; // Display realignment for top
    margin_right_correction: number = null; // Display realngment for right
    margin_left_correction: number = null; // Display realngment for left
    margin_bottom_correction: number = null; // Display realngment for bottom

    button_confirm: boolean;
    x_confirm: boolean;

    button_cancel: boolean;
    button_ok_freezeAction: boolean;

    width: number; // width of the alert pannel
    height: number; // height of the alert pannel

    padding: number; // padding of the alert pannel

    padding_bottom: number; // padding bottom of the alert pannel

    font_size: number; // font size of the alert pannel

    positions: Array<string>; // breakup of position in a tuple.

    separation = true; // hr between the title and the text

    border_left: string;

    // Fade out
    visible = false;

    constructor(
        private alertService: AlertService,
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        private router: Router
    ) {
        this.lastTicker = -10;
    }

    flashAlert() {
        document.body.classList.remove('fixedScroll');
        this.backdrop = false;
        this.visible = false;
        this.alertService.nextAction.next(null);
        this.alertService.configWatch.next(this.alertService.defaultConfig);
        this.changeDetectorRef.markForCheck();
    }

    checks(config) {
        if (config.texth1 !== null && config.texth2 !== null) {
            throw 'Can\'t have both texth1 and texth2';
        }
        if (config.type !== 'red' && config.type !== 'yellow' && config.type !== 'green' && !config.template) {
            throw 'Type\'s value must be either red, yellow or green.';
        }
        if (config.position === null) {
            throw 'Position required';
        }

    }

    ngOnChanges() {
        if (this.configGlobal === undefined) {
            return;
        }
        let config = JSON.parse(JSON.stringify(this.configGlobal));
        if (config.ticker === -10) {
            return;
        }
        if (config.ticker !== this.lastTicker) {
            this.flashAlert();
            // Reset to defaults
            this.display = false;
            this.backdrop = false;

            this.x_confirm = false;

            this.button_confirm = false;
            this.button_cancel = false;
            this.button_ok_freezeAction = false;

            this.margin_top_correction = null;
            this.margin_bottom_correction = null;
            this.margin_left_correction = null;
            this.margin_right_correction = null;

            this.width = 250;

            this.x_left_pos = this.width - 20 + 'px';

            // Increment ticker

            this.lastTicker = config.ticker;

            // set template specifics
            switch (config.template) {
                case 'usageWarningBeforeDelete':
                    this.backdrop = true;
                    this.button_confirm = true;
                    this.button_cancel = true;
                    config.position = 'center-center';
                    this.width = 550;
                    // this.height = 0;
                    config.type = 'yellow';
                    break;
                case 'deleteUser': // Ce cas prend en charge la suppresion d'individu et permet à l'avertissement d'être plus grand
                    this.backdrop = true;
                    this.button_confirm = true;
                    this.button_cancel = true;
                    this.separation = false;
                    config.position = 'center-center';
                    this.width = 550;
                    this.height = 200;
                    this.font_size = 14;
                    this.padding_bottom = 30;
                    config.type = 'yellow';
                    
                    break;    
                case 'warningConfirm':
                    this.backdrop = true;
                    this.button_confirm = true;
                    this.button_cancel = true;
                    config.position = 'center-center';
                    config.type = 'yellow';
                    break;
                case 'warning':
                    this.backdrop = true;
                    this.button_confirm = true;
                    config.position = 'center-center';
                    config.type = 'yellow';
                    break;
                case 'prohibitedAction':
                    this.backdrop = true;
                    this.button_confirm = true;
                    config.position = 'center-center';
                    config.type = 'red';
                    break;
                case 'notice':
                    config.position = 'top-right';
                    config.type = 'green';
                    break;
                case 'freezeAction':
                    this.backdrop = true;
                    this.button_ok_freezeAction = true;
                    config.position = 'center-center';
                    config.type = 'yellow';
                    break;
                case 'creditWarning':
                    this.backdrop = true;
                    this.width = 350;
                    this.button_confirm = true;
                    config.position = 'center-center';
                    config.type = 'red';
                    break;
            }

            this.checks(config); // exceptions

            this.config = config;

            // Positionning

            this.positions = this.config.position.split('-');

            if (this.positions[0] === 'top') {
                this.margin_top_correction = 20;
            }
            if (this.positions[0] === 'center') {
                this.margin_top_correction = -150;
            }
            if (this.positions[0] === 'bottom') {
                this.margin_bottom_correction = 20;
            }

            if (this.positions[1] === 'left') {
                this.margin_left_correction = 20;
            }
            if (this.positions[1] === 'center') {
                this.margin_left_correction = -this.width / 2;
            }
            if (this.positions[1] === 'right') {
                this.margin_right_correction = 20;
            }

            // text layout
            if (this.config.texth1 !== null || this.config.texth2 !== null && this.config.paragraph !== null) {
                this.separation = true;
            }

            // Left border color
            if (this.config.type === 'red') {
                this.border_left = '7px solid rgb(244,110,110)';
            } else if (this.config.type === 'yellow') {
                this.border_left = '7px solid rgb(238,200,99)';
            } else if (this.config.type === 'green') {
                this.border_left = '7px solid rgb(104,194,165)';
            }

            this.display = true;
            // this.backdrop = !!(this.config.backdrop);
            document.body.classList.add('fixedScroll');
            this.visible = true;
            this.changeDetectorRef.markForCheck();

            if (!this.button_confirm && !this.button_ok_freezeAction) {
                let mainTimeOut = setTimeout(() => {
                    this.flashAlert();
                    clearTimeout(mainTimeOut);
                }, 2500);
            }
        }
    }

    doContinue(act) {
        this.alertService.nextAction.next(act);
        this.flashAlert();
    }
}
