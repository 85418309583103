import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {StateService} from 'src/app/core/services/state/state.service';
import {ApiAdministrationService} from './api-administration.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ContentItem {
    public loading = false;
    private clientUser;

    readonly persistentVariables = [
        'clientUser'
    ];

    constructor(
        private apiAdministration: ApiAdministrationService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        protected translate: TranslateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('userService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setUserToDisplayWithId(id, accountId = null, specificState = 'admin') {
        let obsResult = {};
        this.loading = true;

        let userObservable = this.apiAdministration.user([id]).pipe(take(1)).subscribe(
            (res) => {
                this.clientUser = res;
                this.loading = false;
                this.storeServiceData('userService', this.persistentVariables, this);
                obsResult['setUser'] = res.id;
                this.stateService[specificState].stateChanged.next(obsResult);
                if (accountId) {
                    this.router.navigate(['admin/account/' + accountId + '/user/' + id]).then(() => {
                    });
                }

                userObservable.unsubscribe();
            });
    }

    setClientSince() {
        // console.log('tata');
        const format = 'YYYYMMDD';
        const fDate = moment(this.creationDate).format(format);
        const daysNumber = moment({hours: 0}).diff(fDate, 'days');
        const word = (daysNumber > 0) ? 'commons.days' : 'commons.day';
        this.translate.get(word).subscribe((tWord: string) => {
            this.clientUser.clientSince = daysNumber + ' ' + tWord;
            this.storeServiceData('userService', this.persistentVariables, this);
        });
    }

    checkFiles() {
        if (!this.assets) {
            this.assets = [];
        }
        if (!(Array.isArray(this.assets))) {
            let userAssets = [];
            for (let asset in this.assets) {
                if (asset && this.assets.hasOwnProperty(asset)) {
                    userAssets.push(this.assets[asset]);
                }
            }
            if (userAssets.length) {
                this.assets = userAssets;
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get userToDisplay(): any {
        return _.get(this, 'clientUser');
    }

    set userToDisplay(user) {
        this.clientUser = user;
        this.storeServiceData('userService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'clientUser.id');
    }

    get username(): string {
        return _.get(this, 'clientUser.username');
    }

    get firstName(): string {
        return _.get(this, 'clientUser.firstName');
    }

    get lastName(): string {
        return _.get(this, 'clientUser.lastName');
    }

    get creationDate(): string {
        return _.get(this, 'clientUser.creationDate');
    }

    get clientSince(): string {
        return _.get(this, 'clientUser.clientSince');
    }

    get role() {
        return _.get(this, 'clientUser.role');
    }

    get phone() {
        return _.get(this, 'clientUser.phone');
    }

    get addressInfo() {
        return _.get(this, 'clientUser.addressInfo');
    }

    get assets() {
        return _.get(this, 'clientUser.assets');
    }

    set assets(assets) {
        this.clientUser.assets = assets;
        this.storeServiceData('userService', this.persistentVariables, this);
    }

    get status() {
        return _.get(this, 'clientUser.status');
    }

    get subAccountAccess() {
        return _.get(this, 'clientUser.subAccountAccess');
    }

    get principalSubAccount() {
        return _.get(this, 'clientUser.principalSubAccount');
    }

    get jobTitle() {
        return _.get(this, 'clientUser.jobTitle');
    }

    get formation() {
        return _.get(this, 'clientUser.formation');
    }

    get permissions() {
        return _.get(this, 'clientUser.permissions');
    }

    get notes() {
        return _.get(this, 'clientUser.notes');
    }
}
