// import {Inject} from "@angular/core";
import {Observable} from 'rxjs';
import {Api} from './api';

export abstract class ApiGet extends Api {

    /**
     *
     * Sends GET requests to the API.
     *
     * @param {string} route - See urlParams below
     * @param {Array<any>} urlParams - If urlParams[index] has to be inserted elsewhere than
     *                                 as as suffix, then insert 'UrlParameter' + index at
     *                                 that place in route. Otherwise, it is appended as suffix.
     * @param {Array<any>} dataParams
     * @param {Array<string>} paramsName - Names in the query suffix. If the paramsName are keys
     *                                     of an object passed as dataParams, the array ['@fromObject']
     *                                     must be passed as paramsName.
     * @returns {Observable<any>}
     *
     */

    apiGet(route: string,
           urlParams: Array<any>,
           dataParams: Array<any>,
           paramsName: Array<string>): Observable<any> {
        if ((paramsName !== null && dataParams === null && (paramsName[0] !== '@fromObject')) ||
            (paramsName === null && dataParams !== null)
        ) {
            console.log('For apiGet with');
            console.log('route: ' + route);
            console.log('urlParams:');
            console.log(urlParams);
            console.log('dataParams:');
            console.log(dataParams);
            console.log('paramsName:');
            console.log(paramsName);
            throw 'The following set of conditions should be satisfied satisfied' +
            '(paramsName !== null && dataParams === null && (paramsName[0] !== \'@fromObject\')) || ' +
            '(paramsName === null && dataParams !== null)';
        }

        let url = '';
        if (dataParams !== null) {
            url = this.toUrlData(dataParams, paramsName, route);
        }
        return this.toApi(
            'get',
            route,
            urlParams,
            url
        )
    }

    /**
     * For GET request, this function produces the query string of the URL
     * and appends it to the vanilla API.
     *
     * When used, both dataParams and paramsNames need to be non-null.
     *
     * @param {*} dataParams
     * @param {*} paramsNames
     * @param {*} route
     * @returns {string}
     *
     *
     */

    toUrlData(dataParams: any, paramsNames: any, route = null): string {
        // If 'fromObject' is passed we map the object passed along to url
        // directly, thus 'objectToUrl'

        /**
         * Problem is here  :: Looks like the dataParams doesn't fit with the params names
         */

        let objectToUrl = (paramsNames[0] === '@fromObject');

        let data: any = {};
        let urlData = '';
        let it = 0;

        paramsNames.forEach(name => {
            name = (name === '@fromObject') ? 'name' : name;
            data[name] = dataParams[it];
            it++;
        });

        if (Object.keys(data).length !== 0) {
            // Handling definition WRT different types ////////////////////////////
            const urlHandlings = {
                'ObjectToUrl': (obj) => {
                    let urlArray: Array<string> = [];
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            if (typeof (obj[key]) === 'string' ||
                                typeof (obj[key]) === 'boolean' ||
                                typeof (obj[key]) === 'number') {
                                urlArray.push(key + '=' + encodeURIComponent(obj[key]));
                            } else if (Array.isArray(obj[key])) {

                                for (let elmt of obj[key]) {
                                    urlArray.push(key + '[]' + '=' + elmt);
                                }
                            } else {
                                for (let field in obj[key]) {
                                    if (obj[key] && obj[key].hasOwnProperty(field)) {
                                        urlArray.push(key + '[' + field + ']' + '=' + encodeURIComponent(obj[key][field]));
                                    }
                                }
                            }
                        }
                    }
                    return urlArray.join('&');
                },
                'ObjectType': (obj, name) => {
                    let urlArray: Array<string> = [];
                    for (let field in obj) {
                        if (obj[field] && obj.hasOwnProperty(field)) {
                            urlArray.push(name + '[' + field + ']' + '=' + encodeURIComponent(obj[field]));
                        }
                    }
                    return urlArray.join('&');
                },
                'ArrayType': (arr, name) => {
                    let urlArray: Array<string> = [];
                    for (let elmt of arr) {
                        urlArray.push(name + '[]' + '=' + elmt);
                    }
                    return urlArray.join('&');
                },
                'StringType': (str, name) => {
                    str = String(str);
                    return name + '=' + encodeURIComponent(str);
                }
            };

            // Handle data WRT type and prepare URL ///////////////////////////////
            Object.keys(data).forEach(key => {
                let datum = data[key];
                // Specific cases... Maybe generalized
                if (objectToUrl) {
                    urlData += urlHandlings['ObjectToUrl'](datum) + '&';
                    // General cases
                } else if (typeof (datum) === 'string' || typeof (datum) === 'boolean' || typeof (datum) === 'number') {
                    urlData += urlHandlings['StringType'](datum, key) + '&';
                } else if (Array.isArray(datum)) {
                    urlData += urlHandlings['ArrayType'](datum, key) + '&';
                } else if (typeof (datum) === 'object') {
                    urlData += urlHandlings['ObjectType'](datum, key) + '&';
                } else {
                    throw 'Type ' + typeof (datum) + ' for ' + key + ' not handled | in core/api/api-get.ts';

                }
            });

            // Prepend "?" if needed //////////////////////////////////////////////
            if (urlData.length > 0) {
                urlData = '?' + urlData.substr(0, urlData.length - 1);
            }
        }
        return urlData;
    }

}
