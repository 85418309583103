import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {JobSatisfactionsGraphs} from '../job-satisfactions-graphs';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-job-satisfactions-person-history',
    templateUrl: './job-satisfactions-person-history.component.html',
    styleUrls: ['./job-satisfactions-person-history.component.scss']
})
export class JobSatisfactionsPersonHistoryComponent extends JobSatisfactionsGraphs implements OnChanges {
    @ViewChild('jobSatisfactionGraph', {static: true}) private chartContainer: ElementRef;

    @Input() record;
    @Input() mobile;
    prbs: any = [];
    isSmallMobile;

    spacing = 45;

    constructor(
        private translate: TranslateService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(): void {
        this.isSmallMobile = (document.documentElement.clientWidth < 450 && this.mobile);
        this.graph();
        this.cd.markForCheck();

        this.translate.onLangChange.subscribe(res => {
            this.graph();
            this.cd.markForCheck();
        });
    }

    graph() {
        this.prbs = this.cleanPrbs(this.record.prb);
        // set the dimensions and margins of the graph
        let margin = {top: 10, right: 30, bottom: 30, left: 60},
            width = 728.5 - margin.left - margin.right,
            height = 130 - margin.top - margin.bottom;

        let element = this.chartContainer.nativeElement;

        // Remove duplicates
        d3.select('#' + 'jobSatisfactionGraph_' + this.record.id).remove();
        // append the svg object to the body of the page
        let viewBoxWidth = '220';
        let gWidth = '350px';
        if (this.isSmallMobile) {
            viewBoxWidth = '132';
            gWidth = '220px';
        }
        let svg = d3.select(element)
            .append('svg')
            .attr('id', 'jobSatisfactionGraph_' + this.record.id)
            .attr('width', gWidth)
            .attr('viewBox', '-15 0 ' + viewBoxWidth + ' 26')
            .attr('preserveAspectRatio', 'none');

        // Read the data

        // sort dates ascending
        this.prbs.sort(function (a, b) {
            return b.date - a.date;
        });
        let tempArr = [null, null, null, null, null];
        for (let i = 0; i < this.prbs.length; i++) {
            tempArr[i] = this.prbs[i];
        }
        tempArr.reverse();
        this.prbs = tempArr;
        if (this.isSmallMobile) {
            this.prbs.shift();
            this.prbs.shift();
        }
        for (let i = 0; i < ((this.isSmallMobile) ? 3 : 5); i++) {
            if (this.prbs[i] !== null) {
                if (i !== ((this.isSmallMobile) ? 2 : 4)) {
                    this.path(svg, i, (this.prbs[i + 1] && this.prbs[i + 1].jobSatisfactionScore !== null));
                }
                this.node(svg, this.prbs[i], i);
            } else {
                if (i !== ((this.isSmallMobile) ? 2 : 4)) {
                    this.path(svg, i, false);
                }
                this.node(svg, -1, i);
            }
        }
    }

    path(svg, n, gray = false) {
        let startX = (n * this.spacing) + 14;
        let stopX = ((1 + n) * this.spacing) + 1;

        // Add the curve

        let line = d3.line();
        let path = line([
            [startX, 7.5],
            [stopX, 7.5]
        ]);

        let lineColor = gray ? '#666' : '#C7C6C7';

        svg
            .append('path')
            .datum(this.prbs)
            .attr('fill', 'none')
            .attr('stroke', lineColor)
            .attr('stroke-width', 2)
            .attr('d', path);

        return svg;
    }

    node(svg, prb, n) {
        let color;
        let postDate = true;
        switch (prb.jobSatisfactionScore) {
            case 2:
                color = 'green';
                break;
            case 1:
                color = 'yellow';
                break;
            case 0:
                color = 'red';
                break;
            default:
                color = 'unknown_inactive';
                postDate = false;
        }

        svg
            .append('image')
            .attr('xlink:href', '/assets/report/smiley_' + color + '.svg')
            .attr('x', n * this.spacing)
            .attr('y', 0)
            .attr('width', 15)
            .attr('height', 15);

        let format = 'DD/MM/YYYY';
        if (this.translate.currentLang === 'en') {
            format = 'MM/DD/YYYY';
        }

        if (postDate) {
            svg.append('text')
                .attr('x', n * this.spacing - 8)
                .attr('y', 22)
                .attr('text-anchor', 'middle')
                .text(moment(prb.date).format(format))
                .attr('text-anchor', 'start')
                .attr('font-size', 6.5);
        }

        return svg;
    }

}
