import {Component, ElementRef, ViewChild, OnChanges, Input, SimpleChanges} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-trait-graph',
    templateUrl: './trait-graph.component.html',
    styleUrls: ['./trait-graph.component.scss']
})
export class TraitGraphComponent implements OnChanges {

    @ViewChild('TraitGraph', {static: true}) private chartContainer: ElementRef;
    // @Input() private ratio: number;
    @Input() width: any;
    @Input() lang: string;
    @Input() mobile = false;
    @Input() private part: string;
    @Input() private trait: string;
    @Input() private trait1Score: any;
    @Input() private trait2Score: any;
    @Input() jsColor: any = false;
    @Input() autoWidth: any = false;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        this.createTraitGraph();
    }


    createTraitGraph() {

        let id = 'traitGraph_' + this.part + '_' + this.trait;

        let paperHeight = 65;
        if (this.part === 'prb') {
            paperHeight = 105;
        }

        let ratio = .75;

        d3.select('#' + id).remove();

        // Define "element" to work on
        let element = this.chartContainer.nativeElement;
        let width = 675;
        let rectWidth = 650;
        let horz = [25, 85, 145, 205, 265, 325, 385, 445, 505, 565, 625];
        let circleRadius = 18.5;
        let middleFontSize = 15;
        let textX = 650;
        if (this.mobile) {
            ratio = .92;
            width = this.width;
            rectWidth = width;
            let firstPoint = width * 0.037;
            firstPoint = +firstPoint.toFixed();
            let nextPoint = firstPoint;
            horz = [firstPoint];
            for (let i = 1; i < 11; i++) {
                nextPoint = nextPoint + (width / 11);
                nextPoint = +nextPoint.toFixed();
                horz.push(nextPoint);
            }
            circleRadius = 13.5;
            middleFontSize = 12;
            textX = this.width;
        }

        let widthCorrected  = (this.autoWidth) ? 375 : width * ratio;
        let svg = d3.select(element).append('svg')
            .attr('id', id)
            .attr('width', widthCorrected)
            .attr('height', paperHeight * ratio)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('viewBox', '0 0 ' + width + ' ' + paperHeight);

        // tslint:disable-next-line:radix
        let intPart1 = parseInt(this.trait1Score);
        // tslint:disable-next-line:radix
        let intPart2 = parseInt(this.trait2Score);

        let corr1 = 0;
        let corr2 = 0;

        if (this.trait === 'AD') {
            let remPart1 = this.trait1Score - intPart1;
            corr1 = remPart1 * 60;

            let remPart2 = this.trait2Score - intPart2;
            corr2 = remPart2 * 60;
        }

        let leftText = 'none';
        let rightText = 'none';


        if (this.lang === 'fr') {
            switch (this.trait) {
                case 'OR':
                    leftText = 'Pragmatisme';
                    rightText = 'Originalité';
                    break;
                case 'SE':
                    leftText = 'Stabilité';
                    rightText = 'Spontanéité';
                    break;
                case 'A':
                    leftText = 'Amabilité';
                    rightText = 'Assertivité';
                    break;
                case 'E':
                    leftText = 'Introversion';
                    rightText = 'Extraversion';
                    break;
                case 'P':
                    leftText = 'Pression';
                    rightText = 'Pondération';
                    break;
                case 'S':
                    leftText = 'Liberté';
                    rightText = 'Structure';
                    break;
                case 'AD':
                    leftText = 'Constance';
                    rightText = 'Adaptabilité';
                    break;
            }
        } else if (this.lang === 'en') {
            switch (this.trait) {
                case 'OR':
                    leftText = 'Pragmatism';
                    rightText = 'Originality';
                    break;
                case 'SE':
                    leftText = 'Stability';
                    rightText = 'Spontaneity';
                    break;
                case 'A':
                    leftText = 'Agreeableness';
                    rightText = 'Assertiveness';
                    break;
                case 'E':
                    leftText = 'Introversion';
                    rightText = 'Extroversion';
                    break;
                case 'P':
                    leftText = 'Variable pace';
                    rightText = 'Steady pace';
                    break;
                case 'S':
                    leftText = 'Freedom';
                    rightText = 'Structure';
                    break;
                case 'AD':
                    leftText = 'Consistency';
                    rightText = 'Adaptability';
                    break;
            }
        }

        // First Background Line

        let fillCircle = 'white';
        let strokeCircle1 = 'rgb(233,118,6)';
        let strokeCircle2 = 'rgb(30%, 30%, 30%)';
        let traitLineColor1 = 'rgb(85.0%,85.0%,85.0%)';
        let traitLineColor2 = 'rgb(85.0%,85.0%,85.0%)';

        if (this.jsColor) {
            traitLineColor1 = 'rgb(30%, 30%, 30%)';
            traitLineColor2 = 'rgb(85.0%,85.0%,85.0%)';
            strokeCircle1 = 'rgb(30%, 30%, 30%)';
            strokeCircle2 = 'rgb(85.0%,85.0%,85.0%)';
            if (this.jsColor === 'r') {
                fillCircle = '#F07B7E';
            } else if (this.jsColor === 'j') {
                fillCircle = '#EECF7B';
            } else if (this.jsColor === 'v') {
                fillCircle = '#77C6AF';
            }
        }

        // console.log(fillCircle);


        svg.append('rect')
            .attr('x', 0)
            .attr('y', 40)
            .attr('width', rectWidth)
            .attr('height', 10)
            .attr('stroke', 'none')
            .style('fill', traitLineColor1);

        svg.append('circle')
            .attr('cx', horz[intPart1] + corr1)
            .attr('cy', 45)
            .attr('r', circleRadius).attr('stroke', strokeCircle1)
            .attr('stroke-width', 3)
            .style('fill', fillCircle);


        svg.append('text')
            .attr('x', horz[intPart1] + corr1)
            .attr('y', 50)
            .attr('text-anchor', 'middle')
            .text(this.trait1Score)
            .attr('font-size', middleFontSize);

        svg.append('text')
            .attr('x', 0)
            .attr('y', 16)
            .text(leftText)
            .attr('text-anchor', 'start')
            .attr('font-size', 18);

        svg.append('text')
            .attr('x', textX)
            .attr('y', 16)
            .text(rightText)
            .attr('text-anchor', 'end')
            .attr('font-size', 18);

        // Second Background Line

        if (this.part === 'prb') {
            svg.append('rect')
                .attr('x', 0)
                .attr('y', 80)
                .attr('width', rectWidth)
                .attr('height', 10)
                .attr('stroke', 'none')
                .style('fill', traitLineColor2);

            svg.append('circle')
                .attr('cx', horz[intPart2] + corr2)
                .attr('cy', 85)
                .attr('r', circleRadius)
                .attr('stroke', strokeCircle2)
                .attr('stroke-width', 3)
                .style('fill', fillCircle);

            svg.append('text')
                .attr('x', horz[intPart2] + corr2)
                .attr('y', 90)
                .attr('text-anchor', 'middle')
                .text(this.trait2Score)
                .attr('font-size', middleFontSize);
        }
    }
}

