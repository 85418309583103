import {ApplicationRef, Injectable} from '@angular/core';
import {List} from '../../../people/interfaces';
import {JobSatisfactionStateColumns} from '../../../core/interfaces/state';
import {UserService} from '../../../core/services/user/user.service';
import {UserPreferencesService} from '../../../administration/services/user-preferences.service';
import {StateService} from '../../../core/services/state/state.service';
import {AlertService} from '../../../shared/alert/services/alert.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CanonicalizeHelper} from '../../../shared/helpers/canonicalize.helper';
import {ContentList} from '../../../shared/classes/services/content_list';
import * as _ from 'lodash';
import {JobSatisfactionsAnalysisService} from './job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from './api-job-satisfactions.service';

@Injectable({
    providedIn: 'root'
})
export class JobSatisfactionsListService extends ContentList {
    public _opened;
    public switchOff: boolean;
    public clickMode = false;

    public loading = false;
    public url = 'analysis/jobsatisfaction/list';
    public toList: List = null;
    public totalItems = 0;
    public recordsLoaded = 0;
    public multiAction: any;
    public amount: Array<number> = [];

    public listColumns;
    public listColumnsInitialConfig: Array<JobSatisfactionStateColumns> = [
        {key: 'name', display: true},
        {key: 'date', display: true}
    ];
    readonly columns = [
        'name',
        'date',
        'subAccountName',
        'consultant',
        'departmentName'
    ];
    readonly columnsName = {
        fr: {
            name: 'Nom',
            date: 'Date',
            subAccountName: 'Sous-compte',
            consultant: 'Demandeur',
            departmentName: 'Département'
        },
        en: {
            name: 'Name',
            date: 'Date',
            subAccountName: 'Sub-account',
            consultant: 'Requester',
            departmentName: 'Department'
        }
    };

    constructor(
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        public user: UserService,
        private userPreferencesService: UserPreferencesService,
        public stateService: StateService,
        private alertService: AlertService,
        private apiJobSatisfactionsService: ApiJobSatisfactionsService,
        protected applicationRef: ApplicationRef,
        protected router: Router,
        protected translate: TranslateService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, router, applicationRef, deviceService);
    }

    checkModuleUrl() {
        let prefix = (this.isMobile && !this.url.includes('m/')) ? 'm/' : '';
        this.url = prefix + this.url;
        if (this.archive) {
            this.url = prefix + 'archives/list/jobsatisfaction';
        } else {
            this.url = prefix + 'analysis/jobsatisfaction/list';
        }
    }

    setInitialColumnsList() {
        this.listColumnsInitialConfig = [
            {key: 'name', display: true},
            {key: 'date', display: true}
        ];
    }

        getList(params, recordsLoaded, total, append = false, archive = false) {
        let step = 100;

        let amountLoaded = (recordsLoaded + step > total && append) ? total - recordsLoaded : step;
        recordsLoaded = append ? recordsLoaded : 0;

        let request: any = {
            limit: amountLoaded,
            skip: recordsLoaded
        };
        let parameters = [
            'search',
            'jobSatisfactionStartDate',
            'jobSatisfactionEndDate',
            'subAccount',
            'department'
        ];

        let arrayParameters = ['subAccount'];

        for (let parameter of parameters) {
            if (params.listSearchFilters[parameter]) {
                request[parameter] = params.listSearchFilters[parameter];
            }
        }

        for (let parameter of arrayParameters) { // subAccount handling
            if (request[parameter] && request[parameter].length > 0) {
                request[parameter] = request[parameter].toString();
            } else {
                delete request[parameter];
            }
        }

        if (request.subAccount) {
            request.subAccount = request.subAccount.split(',');
        }

        if (archive) {
            request['isArchived'] = 1;
        } else {
            request['isArchived'] = 0;
        }

        // Build order parameter
        for (let index in params.listColumns) {
            if (params.listColumns.hasOwnProperty(index)) {
                if (params.listColumns[index] && params.listColumns[index]['order']) {
                    let orderObject = {};
                    let colKey: string;
                    switch (params.listColumns[index]['key']) {
                        case 'subAccountName':
                            colKey = 'subAccount';
                            break;
                        case 'departmentName':
                            colKey = 'department';
                            break;
                        default:
                            colKey = params.listColumns[index]['key'];
                    }
                    orderObject[colKey] = params.listColumns[index]['order'];
                    request['order'] = orderObject;
                }
            }
        }
        if (typeof request.department === 'string') {
            request.department = request.department.split(',');
        }

        request = CanonicalizeHelper.addSuffix('jobSatisfactions', request);
        return {
            listObservable: this.apiJobSatisfactionsService.jobSatisfactions([request]),
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }

    checkTotalItems() {
        // check items list without selection
        let initList = this.getList({listSearchFilters: {}}, 0,
            null, false, this.archive);
        initList.listObservable.subscribe(list => {
            this.totalItems = list.count;
            this.state.stateChanged.next({
                totalItems: this.totalItems
            });
        });
    }

    getJobSatisfactionList(unsubscribe = false, callback = null, obj = null) {
        let list = this.getList(this.stateService[this.specificState], 0,
            null, false, this.archive);
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList = this.setSubAccountName(
                    this.truncateDate(list)
                );
                if (this.jobSatisfactionState.report && this.jobSatisfactionState.report.itemToDisplayId) {
                    if (obj && obj.setItemToDisplay) {
                        obj.setItemToDisplay(this.toList, this.jobSatisfactionState.report.itemToDisplayId);
                    }
                }
                if (unsubscribe) {
                    listObservableSubscription.unsubscribe();
                }
                if (callback) {
                    this.alertService.setConfig(callback);
                }
                this.loading = false;
                this.jobSatisfactionState.stateChanged.next({
                    list: list
                });
                this.applicationRef.tick();
            }
        );
    }

    /**
     * Update list on scroll
     */
    listScrolled(): void {
        if (this.toList['count'] && this.recordsLoaded >= this.toList['count']) {
            return;
        }

        let list = this.getList(
            this.state, this.recordsLoaded, this.toList['count'], true, this.archive
        );
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                let truncatedList = this.truncateDate(list);
                truncatedList = this.setSubAccountName(truncatedList);
                this.toList['data'] =
                    [...this.toList['data'], ...truncatedList['data']];

                this.loading = false;
                listObservableSubscription.unsubscribe();
                this.state.stateChanged.next({
                    list: list
                });
                this.applicationRef.tick();
            }
        );
    }

    /**
     * Set search parameter on event from shared/main-list/component/main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.state.listSearchFilters.search = event;
        this.state.stateStore(this.specificState, 'listSearchFilters', {search: event});
        this.state.stateChanged.next({
            listSearchFilters: {search: event}
        });
    }

    /**
     * Set selected item
     * @param item
     */
    itemSelected(item): void {
        if (!this.isMobile) {
            this.jobSatisfaction.setItemToDisplayWithId(item[0].id, this.specificState, 'forceSidebarToReport');
            // open sidebar
            this._opened = true;
        } else {

            this.jobSatisfaction.setItemToDisplayWithId(item[0].id, this.specificState, 'mobileView');
        }
    }

    goToPrec() {
        this.switchOffFilterPanelIndicator(true);
        if (this.state.lastSideBarStatus === 'recentActivities') {
            this.revertToNews();
        }
    }

    checkUserJobSatisfactionDisplayPreferences() {
        let listColumn = this.listColumnsInitialConfig;
        let display = _.get(this.userData, 'preferences.jobSatisfaction.display');
        if (display) {
            for (let k in display) {
                if (display.hasOwnProperty(k)) {
                    let colIndex = listColumn.findIndex(col => col.key === k);
                    if (colIndex > -1) {
                        listColumn[colIndex].display = display[k];
                    } else {
                        listColumn.push({key: this.userPreferencesService.plusToDot(k), display: display[k]});
                    }
                }
            }
        }
        this.listColumns = JSON.parse(JSON.stringify(listColumn));
    }

    get archive() {
        return this.actualModule.archive;
    }

    get specificState() {
        return (!this.archive) ? 'jobSatisfaction' : 'archivesJobSatisfaction';
    }

    get jobSatisfactionStartDate() {
        return (this.jobSatisfactionState && this.jobSatisfactionState.listSearchFilters) ?
            this.jobSatisfactionState.listSearchFilters.jobSatisfactionStartDate :
            null;
    }

    get jobSatisfactionEndDate() {
        return (this.jobSatisfactionState && this.jobSatisfactionState.listSearchFilters) ?
            this.jobSatisfactionState.listSearchFilters.jobSatisfactionEndDate :
            null;
    }

    get jobSatisfactionState(): any {
        return this.stateService[this.specificState];
    }

    get userPreferences() {
        return (this.sessionData && this.sessionData.userData) ? this.sessionData.userData.preferences : null;
    }

    get totalListItems() {
        return (this.toList && this.toList.count !== this.totalItems) ? this.toList.count : null;
    }

    get resMention() {
        return (this.totalListItems > 1) ? 'commons.results' : 'commons.result';
    }

    get tableDefinition() {
        const statusIacIndex = this.jobSatisfactionState.listColumns.findIndex(col => col.key === 'statusIac');
        if (statusIacIndex > -1) {
            // remove statusIac from the list if it was found
            this.jobSatisfactionState.listColumns.splice(statusIacIndex, 1);
        }
        let listColumnsNonTrivial = _.compact(_.uniqBy(this.jobSatisfactionState.listColumns, 'key'));

        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.jobSatisfactionState.listColumns;

        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            let colKey = col.key;
            colKey = colKey.replace('.', '_');
            col['title'] = this.columnsName[this.translate.currentLang][colKey];
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }
}
