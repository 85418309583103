import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiJobsService} from '../../services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {JobService} from '../../services/job.service';

@Component({
    selector: 'app-jobs-iac-modal',
    templateUrl: './jobs-iac-modal.component.html',
    styleUrls: ['./jobs-iac-modal.component.scss']
})
export class JobsIacModalComponent implements OnInit {
    @Input() status: any;

    public close: EventEmitter<any> = new EventEmitter();
    public saveIacScore: EventEmitter<any> = new EventEmitter();

    RAScore: any;
    pressedKey: number;
    raTexts: any;
    action: string;

    constructor(
        public jobService: JobService,
        public bsModalRef: BsModalRef,
        private cd: ChangeDetectorRef,
        private apiJobsService: ApiJobsService,
        public translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.action = (this.status === 'edit') ? 'update' : 'save';
        this.RAScore = undefined;
        if (this.status === 'edit') {
            if (this.jobService.raScore) {
                this.RAScore = this.jobService.raScore;
                this.cd.markForCheck();
            }
        }
        this.apiJobsService.jobRaTexts().subscribe(
            (res) => {
                this.raTexts = res['RAtexts'];
                this.cd.markForCheck();
            }
        );
    }

    checkPressedKey(event) {
        this.pressedKey = event.keyCode;
    }

    refreshGraph() {
        if (this.RAScore < 1 && this.RAScore !== '') {
            this.RAScore = 1;
        }
        this.cd.markForCheck();
    }

    closeModal(action = 'close', jobId = this.jobService.id) {
        if (action !== 'save' && action !== 'close' && action !== 'update') {
            throw 'Invalid action to close modal in jobs-iac-modal.component.ts::closeModale(action)';
        }
        let report;
        if (action === 'save' || action === 'update') {
            report = 'ra';
            // Save and refresh
            this.saveIacScore.emit({
                action: action,
                score: this.RAScore,
                id: jobId,
                report: report
            });
        } else {
            jobId = undefined;
        }
        this.RAScore = undefined;
        this.close.emit({closed: true, id: jobId, report: report});
        this.bsModalRef.hide();
    }
}
