import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
    OnChanges,
    SimpleChanges,
    EventEmitter, ViewChildren, ElementRef, AfterViewInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ApiGetNewsService} from '../../news/services/api-get-news.service';
import {ApiPostNewsService} from '../../news/services/api-post-news.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true',  style({ opacity: '1' })),
            state('false', style({ opacity: '0' })),
            transition('0 <=> 1', animate('350ms ease'))
        ]),
        trigger('displayChanged', [
            state('true',  style({ display: 'block' })),
            state('false', style({ display: 'none'  })),
            transition('0 => 1', animate('0ms ease')),
            transition('1 => 0', animate('0ms 350ms ease'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarouselComponent implements OnInit, OnChanges {

    @Output() hideCarouselEvent = new EventEmitter();
    @ViewChildren('carousel') myErrorText: ElementRef;

    news: any;
    visible = false;
    visibleFirst = true;

    lastNewsId: string;

    imgFolder = 'assets/carousel-mobile/';
    carouselSlides = [
        {
            img:  this.imgFolder + 'Ngenio_Slider_IBE_1.' + this.translateService.currentLang  + '.svg'
        },
        {
            img: this.imgFolder + 'Ngenio_Slider_IBE_2.' + this.translateService.currentLang  + '.svg'
        },
        {
            img: this.imgFolder + 'Ngenio_Slider_IBE_3.' + this.translateService.currentLang  + '.svg'
        },
        {
            img: this.imgFolder + 'Ngenio_Slider_IBE_4.' + this.translateService.currentLang  + '.svg'
        }
        // {
        //     img: this.imgFolder + 'carouselImg5.' + this.translateService.currentLang  + '.png'
        // },
        // {
        //     img: this.imgFolder + 'carouselImg6.' + this.translateService.currentLang  + '.png'
        // },
        // {
        //     img: this.imgFolder + 'carouselImg7.' + this.translateService.currentLang  + '.png'
        // },
        // {
        //     img: this.imgFolder + 'carouselImg8.' + this.translateService.currentLang  + '.png'
        // },
    ];

    message = {
        'en': '',
        'fr': ''
    };

    @Input() showNewsPanel;

    constructor(
        public translateService: TranslateService,
        private apiGetNewsService: ApiGetNewsService,
        private apiPostNewsService: ApiPostNewsService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.visibleFirst) {
            this.visible = !this.visible;
        } else {
            this.visibleFirst = false;
        }
    }

    slideCarousel(side) {

    }

    ngOnInit() {
        const today = new Date();
        const yearAgo = moment(today).subtract(1, 'year').format('YYYY-MM-DD').toString();
        this.apiGetNewsService.news_messages([this.translateService.currentLang, null, null, yearAgo]).subscribe(
            res => {
                this.visible = res['carousel-mobile'];
                let news = res.data.sort((a, b) => (a.version <= b.version) ? 1 : ((b.version <= a.version) ? -1 : 0));
                if (news.length > 0) {
                    this.lastNewsId = news[news.length - 1].id;
                }
                this.cd.markForCheck();
            }
        );
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.carouselSlides = [
                    {
                        img: this.imgFolder + 'Ngenio_Slider_IBE_1.' + this.translateService.currentLang  + '.svg'
                    },
                    {
                        img: this.imgFolder + 'Ngenio_Slider_IBE_2.' + this.translateService.currentLang  + '.svg'
                    },
                    {
                        img: this.imgFolder + 'Ngenio_Slider_IBE_3.' + this.translateService.currentLang  + '.svg'
                    },
                    {
                        img: this.imgFolder + 'Ngenio_Slider_IBE_4.' + this.translateService.currentLang  + '.svg'
                    }
                    // {
                    //     img: this.imgFolder + 'carouselImg5.' + this.translateService.currentLang  + '.png'
                    // },
                    // {
                    //     img: this.imgFolder + 'carouselImg6.' + this.translateService.currentLang  + '.png'
                    // },
                    // {
                    //     img: this.imgFolder + 'carouselImg7.' + this.translateService.currentLang  + '.png'
                    // },
                    // {
                    //     img: this.imgFolder + 'carouselImg8.' + this.translateService.currentLang  + '.png'
                    // },
                ];
            }
        );
    }

    closeNewsWindow(doNoDisplayAgain = false) {
        if (doNoDisplayAgain) {
            if (this.lastNewsId !== null && typeof this.lastNewsId !== 'undefined') {
                let userDateMessageSubscription: Subscription = this.apiPostNewsService.news_userdateMessage([this.lastNewsId])
                    .subscribe(
                        () => {
                            this.hideCarouselEvent.emit();
                            userDateMessageSubscription.unsubscribe();
                        }
                    );
            }
        }
        this.visible = false;
    }
}

