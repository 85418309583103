// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
}

table * {
  vertical-align: top;
}

.btn[type=submit]:disabled {
  cursor: auto;
}

input[type=text], input[type=password] {
  border: 1px solid #222;
}

input[type=text].borderWarning, input[type=password].borderWarning {
  border: 2px solid #f27b66;
}

div.formInnerDiv {
  float: left;
}

.btn {
  float: right;
}

.formWarning {
  font-size: 10pt; /** not vanilla, remove */
  color: #f27b66;
}

div.formClear {
  clear: both;
}

.btn {
  margin-bottom: 5px;
  height: 32px;
  width: auto;
  padding: 5px 15px 5px 15px;
  background-color: black;
  border-radius: 0;
  border: 1px solid #666;
}

.btn:hover {
  background-color: #444;
  border: 1px solid #666;
}

.btn:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/forms/components/form-table/form-table.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA,EAAA,yBAAA;EACA,cAAA;AACJ;;AAEA;EACG,WAAA;AACH;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,0BAAA;EACA,uBAAA;EACA,gBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":["div {\n    margin: 0;\n    padding: 0;\n}\n\ntable {\n    border-collapse: collapse;\n}\n\ntable * {\n    vertical-align: top;\n}\n\n.btn[type=\"submit\"]:disabled {\n    cursor: auto;\n}\n\ninput[type='text'], input[type='password'] {\n    border: 1px solid #222;\n}\n\ninput[type='text'].borderWarning, input[type='password'].borderWarning {\n    border: 2px solid #f27b66;\n}\n\ndiv.formInnerDiv {\n    float: left;\n}\n\n.btn {\n    float: right;\n}\n\n.formWarning {\n    font-size: 10pt; /** not vanilla, remove */\n    color: #f27b66;\n}\n\ndiv.formClear {\n   clear: both;\n}\n\n.btn {\n    margin-bottom: 5px;\n    height: 32px;\n    width: auto;\n    padding: 5px 15px 5px 15px;\n    background-color: black;\n    border-radius: 0;\n    border: 1px solid #666;\n}\n\n.btn:hover {\n    background-color: #444;\n    border: 1px solid #666;\n}\n\n.btn:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
