import { ActivatedRoute } from '@angular/router';
import { AdministrationCommonListComponent } from '@admin/components/administration-common-list/administration-common-list.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService } from '@core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-administration-departments-list',
	standalone: false,
	templateUrl: './administration-departments-list.component.html',
	styleUrl: './administration-departments-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationDepartmentsListComponent extends AdministrationCommonListComponent {
	constructor(
		protected translate: TranslateService,
		protected route: ActivatedRoute,
		protected stateService: StateService
	) {
		super(translate, route, stateService);
	}
	
	departmentsListCellProperties(columnIndex) {
		let styleObject = {
			width: '',
			vertical_align: '',
			text_align: ''
		};
		switch (columnIndex) {
			case 2:
			case 4:
				styleObject.width = '100px';
				return styleObject;
			case 3:
				styleObject.width = '100px';
				styleObject.text_align = 'center';
				return styleObject;
			default:
				styleObject.width = 'auto';
				return styleObject;
		}
	}
}
