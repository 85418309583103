import {
    Component,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {JobService} from '../../../services/job.service';

@Component({
  selector: 'app-mobile-jobs-iac',
  templateUrl: './mobile-jobs-iac.component.html',
  styleUrls: ['./mobile-jobs-iac.component.scss']
})
export class MobileJobsIacComponent implements OnChanges {

    @Input() status: any;

    @Output() close = new EventEmitter();
    @Output() saveIacScore = new EventEmitter();

    RAScore: any;
    pressedKey: number;
    assetsFolder = 'assets/graphicalElements/';

    constructor(
        public jobService: JobService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.RAScore = undefined;
        if (this.status === 'edit') {
            if (this.jobService.raScore) {
                this.RAScore = this.jobService.raScore;
                this.cd.markForCheck();
            }
        }
    }

    checkPressedKey(event) {
        this.pressedKey = event.keyCode;
    }

    refreshGraph() {
        if (this.RAScore < 1 && this.RAScore !== '') {
            this.RAScore = 1;
        } else if (this.RAScore > 9) {
            // if (this.pressedKey === 8) { // backspace key was pressed
            //     this.RAScore = this.RAScore % 10;
            // } else {
            //     let numberString = '' + this.RAScore;
            //     this.RAScore = parseInt(numberString.charAt(0), 10);
            // }
        }

        this.cd.markForCheck();
    }

    finishIac(action = 'close') {
        if (action !== 'save' && action !== 'close' && action !== 'update') {
            throw 'Invalid action';
        }
        if (action === 'save' || action === 'update') {
            // Save and refresh
            this.saveIacScore.emit({
                action: action,
                score: this.RAScore
            });
        }
        this.RAScore = undefined;
        this.close.emit(true);
    }

}
