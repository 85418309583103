import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
    Input, OnInit, ViewChild, OnDestroy
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {StateService} from '../../../../../core/services/state/state.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {IdwMatrix} from '../../../models/idw-matrix.model';
import {Tools} from '../../../../../shared/misc/tools';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-idw-matrices-modal',
    templateUrl: './idw-matrices-modal.component.html',
    styleUrls: ['./idw-matrices-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatricesModalComponent implements OnInit, OnDestroy {

    @Input() status;
    @Input() multiAction;

    @ViewChild('idwMatrixNgForm') idwMatrixNgForm;

    public close: EventEmitter<any> = new EventEmitter();
    public multiActionDestroy: EventEmitter<any> = new EventEmitter();

    idwMatrix: IdwMatrix;
    idwMatrixForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    submitted = false;
    headerText: string;

    private subscriptions = new Subscription();

    constructor(public bsModalRef: BsModalRef,
                public idwAssMatrix: IdwMatrixAnalysisService,
                private changeDetectorRef: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private stateService: StateService,
                private apiIdwMatricesService: ApiIdwMatricesService
    ) {
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    ngOnInit() {

        this.requestedBy = this.sessionData.userData.username;
        this.sessionStructure = this.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (!this.idwAssMatrix.itemToDisplay) {
            this.idwAssMatrix.itemToDisplay = {
                subAccount: 0
            };
        }

        if (this.status === 'new') {
            this.idwMatrix = Object.assign({}, new IdwMatrix());
            this.idwMatrix.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.idwMatrix = Object.assign({}, this.idwAssMatrix.itemToDisplay);
        }

        if (this.multiAction && this.multiAction.ids.length !== 0) {
            this.headerText = (this.multiAction.ids.length === 1) ?
                'analysisCommons.addCandidateToAnalysis' :
                'analysisCommons.addCandidatesToAnalysis';
        } else {
            this.headerText = null;
        }

        this.initForm();
    }

    closeModal(mId = this.idwAssMatrix.id) {
        this.resetForm();
        this.close.emit({closed: true, id: mId});
        this.bsModalRef.hide();
    }

    initForm() {
        this.submitted = false;
        const departmentId = (this.idwMatrix.department && this.idwMatrix.department.length > 0)
            ? this.idwMatrix.department[0].id
            : null;

        let subAccount = (this.sessionData.accountData) ?
            this.sessionData.accountData.masterSubAccountId : '';
        if (this.status === 'edit') {
            subAccount = this.idwMatrix.subAccount;
        }

        this.idwMatrixForm = this.fb.group({
            name: [this.idwMatrix.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.idwMatrix.consultant, [Validators.required, Validators.minLength(2)]],
            department: [departmentId]
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (model.valid) {
            let data = model.value;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    formCheck(specificField = null) {
        if (this.idwMatrixNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.idwMatrixForm.controls[specificField], this.idwMatrixNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount', 'consultant'];
                for (let field of fields) {
                    if (this.validateFormField(this.idwMatrixForm.controls[field], this.idwMatrixNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    saveNew(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiIdwMatricesService.postDitGram(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // Get Trait and PRB from id
                    const recordsTrait = this.multiAction.ids.map((id: string) => ({type: 'trait', record: id}));
                    const recordsPrb = this.multiAction.ids.map((id: string) => ({type: 'prb', dash: 0, record: id}));
                    const recordsToPut = {'records': [...recordsTrait, ...recordsPrb]};
                    // Update created Matrix
                    this.subscriptions.add(this.apiIdwMatricesService.putDitGram([res.id], recordsToPut).subscribe(
                        () => {
                            this.multiActionDestroy.emit({destroy: true});
                            this.closeModal(res.id);
                        }
                    ));
                } else {
                    this.closeModal(res.id);
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiIdwMatricesService.putDitGram([this.idwAssMatrix.id], data).subscribe(
            () => {
                this.closeModal(this.idwMatrix.id);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.idwMatrixForm.reset();
            this.idwMatrixNgForm.submitted = false;
            this.initForm();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
