// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  border: 1px solid #ddd;
  border-radius: 25px;
  background-color: indianred; /** IDW red: rgb(240,123,126) */
  width: 13px;
  height: 13px;
  font-size: 8pt;
  top: -21px;
  position: relative;
  padding: 0;
}

.alertPositioningFrench {
  left: 47px;
}

.alertPositioningEnglish {
  left: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/news/components/news-icon/news-icon.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;EACA,2BAAA,EAAA,+BAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".alert {\n    border: 1px solid #ddd;\n    border-radius: 25px;\n    background-color: indianred; /** IDW red: rgb(240,123,126) */\n    width: 13px;\n    height: 13px;\n    font-size: 8pt;\n    top: -21px;\n    position: relative;\n    padding: 0;\n}\n\n.alertPositioningFrench {\n    left: 47px;\n}\n\n.alertPositioningEnglish {\n    left: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
