import {ApplicationRef, Injectable} from '@angular/core';
import {RightMatchListService} from './right-match-list.service';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
// import {IMyOptions} from 'mydatepicker';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ContentSearchFilters} from '../../../shared/classes/services/content_search_filters';

@Injectable({
    providedIn: 'root'
})
export class RightMatchSearchFiltersService extends ContentSearchFilters {

    public preferences: boolean;
    public filter: boolean;

    public datePickerOptions: any = {dateFormat: 'dd/mm/yyyy'};
    public dateStart: any;
    public dateEnd: any;
    public dateStartModel = {};
    public dateEndModel = {};
    public hiringDateModel = {};

    public testsOptions: any;
    public optionsTestsModel: any = [];

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    public subAccountsOptions = [];
    public subAccountsOptionsModel: any = [];

    public departmentOptions = [];
    public departmentOptionsModel: any = [];

    public testStatuses: any = {};
    public optionsTestsStatusModel = [];
    public optionsTestsStatusRaModel = [];

    public testsStatusOptions: any;

    public checkedColumn: any = [];

    public sortCardsBy = '';
    public sortCardsOrder = 'asc';

    constructor(
        public stateService: StateService,
        protected router: Router,
        protected applicationRef: ApplicationRef,
        protected deviceService: DeviceDetectorService,
        protected rightMatchListService: RightMatchListService
    ) {
        super(stateService, router, applicationRef, deviceService);
    }

    initInputState() {
        if (this.stateService.rightMatch.listSearchFilters) {
            // Sub-accounts
            if (this.stateService.rightMatch.listSearchFilters.subAccount) {
                let subAccArr = this.stateService.rightMatch.listSearchFilters.subAccount;
                if (typeof this.stateService.rightMatch.listSearchFilters.subAccount === 'string') {
                    subAccArr = subAccArr.split(',');
                }
                this.subAccountsOptionsModel = subAccArr
                    .map(function (item) {
                        return parseInt(item, 10);
                    });
            }
            // Departments
            this.setDepartmentsModel(this.stateService[this.rightMatchListService.specificState]);
        }

        let colSelected = 0;
        for (let col of this.columns) {
            if (this.filtering(this.listColumns, col)) {
                colSelected++;
            }
        }
        // this.columnsToSelect = this.maxNumberOfColumns - colSelected;
    }

    initInputStatePreferences(obj) {
        // override these with user preferences
        if (obj.userPreferences && obj.userPreferences.rightMatch) {
            for (let col in obj.userPreferences.rightMatch.display) {
                if (obj.userPreferences.rightMatch.display.hasOwnProperty(col)) {
                    let index = 0;
                    for (let o of this.listColumns) {
                        if (o.key === obj.userPreferencesService.plusToDot(col)) {
                            if (this.listColumns[index]) {
                                this.listColumns[index].display = obj.userPreferences.rightMatch.display[col];
                            }
                        } else {
                        }
                        index++;
                    }
                }
            }
        }
        if (this.stateService.rightMatch.listSearchFilters) {
            // Sub-accounts
            this.subAccountsOptionsModel = [];
            if (this.stateService.rightMatch.listSearchFilters.subAccount) {
                let subAccArr = this.stateService.rightMatch.listSearchFilters.subAccount;
                if (typeof this.stateService.rightMatch.listSearchFilters.subAccount === 'string') {
                    subAccArr = subAccArr.split(',');
                }

                this.subAccountsOptionsModel = subAccArr
                    .map(function (item) {
                        return parseInt(item, 10);
                    });
            }
            // Departments
            this.setDepartmentsModel(this.stateService[this.rightMatchListService.specificState]);
            if (obj && obj.changeDetectorRef) {
                obj.changeDetectorRef.markForCheck();
            }
        }
    }

    get columns() {
        return this.rightMatchListService.columns;
    }

    get listColumns() {
        return this.rightMatchListService.listColumns;
    }
}
