import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-unaccessible-tests-modal',
    templateUrl: './unaccessible-tests-modal.component.html',
    styleUrls: ['./unaccessible-tests-modal.component.scss']
})
export class UnaccessibleTestsModalComponent implements OnChanges, OnDestroy {

    @Input() initialState;

    test = 'personality';
    lang = 'fr';
    public close: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public translateService: TranslateService) {
    }

    closeModal() {
        this.close.emit({closed: true});
        this.bsModalRef.hide();
    }

    ngOnChanges() {
        // console.log(this.initialState);
        this.test = (this.initialState && this.initialState.test) ? this.initialState.test : this.test;
        this.lang = (this.initialState && this.initialState.lang) ? this.initialState.lang : this.lang;
    }

    ngOnDestroy() {
        this.close.emit({closed: true});
    }

}
