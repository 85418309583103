import {ChangeDetectorRef, Component, EventEmitter, Input, IterableDiffers, OnInit, Output} from '@angular/core';
import {ComponentSidebar} from '../../../../../shared/classes/components/component_sidebar';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';

@Component({
  selector: 'app-job-satisfactions-selected-items',
  templateUrl: './job-satisfactions-selected-items.component.html',
  styleUrls: ['./job-satisfactions-selected-items.component.scss']
})
export class JobSatisfactionsSelectedItemsComponent extends ComponentSidebar implements OnInit {

    @Input() width = '';

    @Output() removeElmt = new EventEmitter();
    @Output() actionOnRecords = new EventEmitter();

    action: any;
    iterableDiffer: any;
    maxPerPage = 30; /** mod this */

    constructor(private _iterableDiffers: IterableDiffers,
                private changeDetectorRef: ChangeDetectorRef,
                public translateService: TranslateService,
                protected stateService: StateService,
                protected deviceService: DeviceDetectorService,
                protected router: Router) {
        super(stateService, translateService, router, deviceService);
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.stateService[this.specificState()].multiselectWatch$.subscribe(
            res => {
                if (res) {
                    this.selectedItems = this.stateService[this.specificState()].multiselect;
                    this.updateList(this.selectedItems, this.maxPerPage);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    act() {
        if (this.action) {
            let event = {
                actionType: this.action,
                ids: []
            };
            for (let item of this.selectedItems) {
                event.ids.push(item.id);
            }

            this.actionOnRecords.emit(event);
        }
    }

    removeItem(item) {
        this.state.multiselect.splice(
            this.selectedItems.indexOf(item),
            1
        );
        this.selectedItems = this.state.multiselect;
        this.state.multiselectWatch.next(true);

        this.changeDetectorRef.markForCheck();
    }

    onResize(event) {
        // this.informationVisibility = (event.target.innerHeight < 725);
    }

}
