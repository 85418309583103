import {
    Component,
    EventEmitter,
    OnInit
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StateService} from '../../../../core/services/state/state.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {ApiPeopleService} from '../../../services/api-people.service';
import {ApiJobsService} from '../../../../jobs/services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {PeopleEditModalComponentCommon} from '../../commonClasses/people-edit-modal-common';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PersonService} from 'src/app/people/services/person.service';

@Component({
    selector: 'app-people-edit-modal',
    templateUrl: './people-edit-modal.component.html',
    styleUrls: ['./people-edit-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleEditModalComponent extends PeopleEditModalComponentCommon implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    peopleEditForm: UntypedFormGroup;
    blurred = false;
    emptyName: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private apiPeopleService: ApiPeopleService,
        protected stateService: StateService,
        public translate: TranslateService,
        protected apiJobs: ApiJobsService,
        public person: PersonService,
        protected cd: ChangeDetectorRef,
        protected fb: UntypedFormBuilder) {
        super(stateService, apiJobs, cd, person, fb);
    }

    ngOnInit() {
        this.initSessionVars();
        this.emptyName = (this.person.firstName === '' || this.person.lastName === '');
        this.peopleEditForm = this.initPeopleEditForm();

        // people edit form
        this.fetchJobs();
    }

    onFormChange(editForm: any) {
        if (editForm.form && editForm.form.value) {
            this.emptyName = (editForm.form.value.firstName === '' || editForm.form.value.lastName === '');
        }
    }

    onSubmit(model: any) {
        // department
        model.value.department = (model.value.department) ? [model.value.department] : [];
        // add subAccount
        model.value.subAccount = (model.value.subAccount) ? model.value.subAccount : this.person.subAccount;
        // call api to save the modification
        this.subscriptions.add(this.apiPeopleService.editPerson([this.person.id], model.value).subscribe(
            (res) => {
                if (res) {
                    res.updateData = true;
                    this.person.personToDisplay = res;
                }
                this.closeModal(this.person.id, model.value.jobId);
            }
        ));
    }

    closeModal(personId = this.person.id, jobId = this.person.jobId) {
        this.stateService.people.stateChanged.next({
            personDefChanged: true
        });
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.initPeopleEditForm();
        });
        this.close.emit({closed: true, id: personId, jobId: jobId});
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
