import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-archives-jobs-list',
    templateUrl: './archives-jobs-list.component.html',
    styleUrls: ['./archives-jobs-list.component.scss']
})
export class ArchivesJobsListComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
