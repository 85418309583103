import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {JobSatisfactionsGraphs} from '../job-satisfactions-graphs';

@Component({
    selector: 'app-job-satisfactions-person-history-graph',
    templateUrl: './job-satisfactions-person-history-graph.component.html',
    styleUrls: []
})
export class JobSatisfactionsPersonHistoryGraphComponent extends JobSatisfactionsGraphs implements OnChanges {

    @ViewChild('jobSatisfactionGraph', {static: true}) private chartContainer: ElementRef;

    @Input() record: any = [];
    prbs: any = [];

    constructor() {
        super();
    }


    ngOnChanges(): void {
        this.graph();
    }

    graph() {
        this.prbs = this.cleanPrbs(this.record.prb);
        // set the dimensions and margins of the graph
        let margin = {top: 10, right: 30, bottom: 30, left: 60},
            width = 728.5 - margin.left - margin.right,
            height = 130 - margin.top - margin.bottom;

        let element = this.chartContainer.nativeElement;

        // Remove duplicates
        d3.select('#' + 'jobSatisfactionGraph_' + this.record.id).remove();
        // append the svg object to the body of the page
        let svg = d3.select(element)
            .append('svg')
            .attr('id', 'jobSatisfactionGraph_' + this.record.id)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '-20 -20 660 150')
            .attr('preserveAspectRatio', 'none');

        // Read the data

        // sort dates ascending
        this.prbs.sort(function (a, b) {
            return a.date - b.date;
        });

        this.prbs.unshift({
            date: this.getFirstDate(this.prbs[0].date.toISOString().slice(0, 10)),
            jobSatisfactionScore: null
        });
        this.prbs.push({
            date: this.getLastDate(this.prbs[this.prbs.length - 1].date.toISOString().slice(0, 10)),
            jobSatisfactionScore: null
        });

        // Add X axis --> it is a date format
        let x = d3.scaleTime().domain(d3.extent(this.prbs, function (d: any) {
            return new Date(d.date);
        })).range([0, width]);

        // Add Y axis
        let y = d3.scaleLinear()
            .domain([0, 3])
            .range([height, 0]);

        this.writeGridEs(svg, width, height, x, y);

        // End of axis, remove first and last ticks
        this.prbs.shift();
        this.prbs.pop();

        // Gradient definition

        svg.append('linearGradient')
            .attr('id', 'svgGradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', 0)
            .attr('y1', 130 - margin.top - margin.bottom) // 110
            .attr('x2', 0)
            .attr('y2', 0)// 0
            .selectAll('stop')
            .data(d3.ticks(0, 3, 4))
            .join('stop')
            .attr('offset', d => d / 3)
            .attr('stop-color', d => this.color[d]);

        // Add the curve
        svg
            .append('path')
            .datum(this.prbs)
            .attr('fill', 'none')
            .attr('stroke', 'url(#svgGradient)')
            .attr('stroke-width', 1.25)
            .attr('d', d3.line()
                .x(function (d: any) {
                    return x(d.date);
                })
                .y(function (d: any) {
                    return y(d.jobSatisfactionScore);
                })
                .curve(d3.curveMonotoneX));

        // Complete the curve
        let lastPoint = this.prbs[this.prbs.length - 1];
        let lastColor = this.color[lastPoint.jobSatisfactionScore];

        let endLine = [
            lastPoint,
            {
                date: this.getLastDate(this.prbs[this.prbs.length - 1].date.toISOString().slice(0, 10)),
                jobSatisfactionScore: lastPoint.jobSatisfactionScore
            }
        ];

        svg
            .append('path')
            .datum(endLine)
            .attr('fill', 'none')
            .attr('stroke', lastColor)
            .attr('stroke-width', 1.25)
            .attr('d', d3.line()
                .x(function (d: any) {
                    return x(d.date);
                })
                .y(function (d: any) {
                    return y(d.jobSatisfactionScore);
                })
            );

        // Add the points
        for (let datum of this.prbs) {
            svg
                .append('circle')
                .datum(datum)
                .attr('fill', function (d: any) {
                    if (d.jobSatisfactionScore === 0) {
                        return 'rgb(189, 188, 184)';
                    } else if (d.jobSatisfactionScore === 1) {
                        return 'rgb(240,123,126)';
                    } else if (d.jobSatisfactionScore === 2) {
                        return 'rgb(238,207,123)';
                    } else if (d.jobSatisfactionScore === 3) {
                        return 'rgb(119,198,175)';
                    }
                })
                .attr('cx', function (d: any) {

                    return x(d.date);
                })
                .attr('cy', function (d: any) {
                    return y(d.jobSatisfactionScore);
                })
                .attr('r', 3.5);
        }

        svg
            .append('image')
            .attr('xlink:href', '/assets/report/jobSatisfaction/smiley_green.svg')
            .attr('x', -20)
            .attr('y', -8.5)
            .attr('width', 15)
            .attr('height', 15);

        svg
            .append('image')
            .attr('xlink:href', '/assets/report/jobSatisfaction/smiley_yellow.svg')
            .attr('x', -20)
            .attr('y', 21)
            .attr('width', 15)
            .attr('height', 15);

        svg
            .append('image')
            .attr('xlink:href', '/assets/report/jobSatisfaction/smiley_red.svg')
            .attr('x', -20)
            .attr('y', 50.5)
            .attr('width', 15)
            .attr('height', 15);

        svg
            .append('image')
            .attr('xlink:href', '/assets/report/jobSatisfaction/smiley_unknown.svg')
            .attr('x', -20)
            .attr('y', 80)
            .attr('width', 15)
            .attr('height', 15);

        /**
         *
         // This allows to find the closest X index of the mouse:
         let bisect = d3.bisector(function (d: any) {
            return d.date;
        }).left;
         // Create the circle that travels along the curve of chart
         let focus = svg
         .append('g')
         .append('circle')
         .style('fill', 'none')
         .attr('stroke', '#bbb')
         .attr('stroke-width', 3)
         .attr('r', 8.5)
         .style('opacity', 0);

         // Create the text that travels along the curve of chart
         let focusText = svg
         .append('g')
         .append('text')
         .style('opacity', 0)
         .attr('text-anchor', 'left')
         .attr('alignment-baseline', 'middle');

         // Create a rect on top of the svg area: this rectangle recovers mouse position
         svg
         .append('rect')
         .style('fill', 'none')
         .style('pointer-events', 'all')
         .attr('width', width)
         .attr('height', height)
         .on('mouseover', mouseover)
         .on('mousemove', mousemove)
         .on('mouseout', mouseout);

         // What happens when the mouse move -> show the annotations at the right positions.
         function mouseover() {
            focus.style('opacity', 1);
            focusText.style('opacity', 1);
        }

         function mousemove() {
            // recover coordinate we need
            let x0 = x.invert(d3.mouse(this)[0]);
            let i = bisect(this.prbs, x0, 1);
            let selectedData: any = this.prbs[i];
            let interpretation = 'NA';
            if (selectedData.y === 1) {
                interpretation = 'Low';
            } else if (selectedData.y === 2) {
                interpretation = 'Medium';
            } else if (selectedData.y === 3) {
                interpretation = 'High';
            }
            if (interpretation !== 'NA') {
                interpretation = interpretation + ' potential well-being';
            }
            focus
                .attr('color', '#bbb')
                .attr('cx', x(selectedData.date))
                .attr('cy', y(selectedData.jobSatisfactionScore));
            focusText
                .html(
                    '<div id="aaaa" style="position:relative">' +
                    selectedData.date.getMonth() + 1 + '/' + selectedData.date.getDate() + '/' + selectedData.date.getFullYear()
                    + ' - ' + interpretation + '</div>')
                .attr('x', x(selectedData.date) + 15)
                .attr('y', y(selectedData.jobSatisfactionScore));
        }

         function mouseout() {
            focus.style('opacity', 0);
            focusText.style('opacity', 0);
        }
         **/
    }

}
