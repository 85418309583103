import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-administration-delete-subaccount-modal',
    templateUrl: './administration-delete-subaccount-modal.component.html',
    styleUrls: ['./administration-delete-subaccount-modal.component.scss']
})
export class DeleteSubAccountModalComponent implements OnInit, OnDestroy {
    @Input() account: any;
    @Input() subAccountId: number;

    public close: EventEmitter<any> = new EventEmitter();

    private onDestroy$: Subject<any> = new Subject();
    public deleteSubAccountForm: UntypedFormGroup;
    public subAccounts: any;

    constructor(
        public bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private apiAdministrationService: ApiAdministrationService
    ) {
    }

    ngOnInit() {
        this.initForm();
        this.subAccounts = this.account.subAccounts.filter((subAccount: any) => subAccount.id !== this.subAccountId);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.close.emit({closed: true});
    }

    public closeModal(isDeleted = false): void {
        this.onDestroy$.next();
        this.resetForm();
        this.close.emit({closed: true, isDeleted: isDeleted});
        this.bsModalRef.hide();
    }

    private initForm(): void {
        this.deleteSubAccountForm = this.fb.group({
            delete: null,
            moveToSubAccountId: new UntypedFormControl({value: null, disabled: true}),
            deleteFiles: null
        });
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    private resetForm(): void {
        this.delay(1000).then(() => {
            this.deleteSubAccountForm.reset();
            this.initForm();
        });
    }

    public deleteChange(): void {
        if (this.deleteSubAccountForm.get('delete').value === '1') {
            this.deleteSubAccountForm.get('moveToSubAccountId').disable();
        } else {
            this.deleteSubAccountForm.get('moveToSubAccountId').enable();
        }
    }

    public canSubmit(): boolean {
        if (this.deleteSubAccountForm.get('delete').value === '1') {
            return true;
        }

        return (
            this.deleteSubAccountForm.get('delete').value === '0' &&
            (this.deleteSubAccountForm.get('moveToSubAccountId').value !== '' && this.deleteSubAccountForm.get('moveToSubAccountId').value !== null)
        );
    }

    public onSubmit(): void {
        const data: any = {};

        if (this.deleteSubAccountForm.value.moveToSubAccountId) {
            data.moveToSubAccount = this.deleteSubAccountForm.value.moveToSubAccountId;
        }

        if (this.deleteSubAccountForm.value.deleteFiles) {
            data.deleteFile = this.deleteSubAccountForm.value.deleteFiles;
        }

        this.apiAdministrationService.deleteSubAccount([this.account.id, this.subAccountId], data).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.closeModal(true);
        });
    }
}
