// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  width: 100%;
}

.modal-body {
  background-color: #EEE;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/shared/add-people-analysis-modal/add-people-analysis-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AACA;EACI,sBAAA;AAEJ","sourcesContent":["label {\n    width: 100%;\n}\n.modal-body {\n    background-color: #EEE;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
