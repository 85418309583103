// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paleGrey {
  color: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/right-matches/components/commonClasses/right-matches-person-card/sub-card/app-right-matches-person-sub-card-personality/right-matches-person-sub-card-personality.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".paleGrey {\n    color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
