export abstract class AccountTypesHelper {
    static fullArray = ['subsidiary', 'distributor', 'partner', 'bizdev', 'consultant', 'other', 'discretionary', 'client', 'demo'];

    static getTranslationStringForType(type, isDiscretionary = false) {
        switch (type) {
            case 'ngenio':
                return 'Ngenio';
            case 'subsidiary':
                return 'admin.commons.subsidiary';
            case 'distributor':
                return 'admin.commons.distributor';
            case 'partner':
                return 'admin.commons.partner';
            case 'consultant':
                return 'admin.commons.consultant';
            case 'client':
                if (isDiscretionary) {
                    return 'admin.commons.discretionary';
                } else {
                    return 'admin.commons.client';
                }
            case 'demo':
                return 'admin.commons.demo';
        }
    }

    static accountTypeNameByIdAndLanguage(id, language, format = 'name') {
        switch (id) {
            case 'subsidiary':
                if (format === 'name') {
                    return (language === 'fr') ? 'Subsidiaires' : 'Subsidiaries';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'subsidiary', name: 'Subsidiaires'} :
                        {id: 'subsidiary', name: 'Subsidiaries'};
                }
                break;
            case 'distributor':
                if (format === 'name') {
                    return (language === 'fr') ? 'Distributeurs' : 'Distributors';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'distributor', name: 'Distributeurs'} :
                        {id: 'distributor', name: 'Distributors'};
                }
                break;
            case 'partner':
                if (format === 'name') {
                    return (language === 'fr') ? 'Partenaires' : 'Partners';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'partner', name: 'Partenaires'} :
                        {id: 'partner   ', name: 'Partners'};
                }
                break;
            case 'bizdev':
                if (format === 'name') {
                    return (language === 'fr') ? 'Développement des affaires' : 'Business development';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'bizdev', name: 'Développement des affaires'} :
                        {id: 'bizdev', name: 'Business development'};
                }
                break;
            case 'consultant':
                if (format === 'name') {
                    return (language === 'fr') ? 'Consultants' : 'Consultants';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'consultant', name: 'Consultants'} :
                        {id: 'consultant', name: 'Consultants'};
                }
                break;
            case 'other':
                if (format === 'name') {
                    return (language === 'fr') ? 'Autres' : 'Others';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'other', name: 'Autres'} :
                        {id: 'other', name: 'Others'};
                }
                break;
            case 'discretionary':
                if (format === 'name') {
                    return (language === 'fr') ? 'Compte discrétionnaire' : 'Discretionary account';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'discretionary', name: 'Compte discrétionnaire'} :
                        {id: 'discretionary', name: 'Discretionary account'};
                }
                break;
            case 'client':
                if (format === 'name') {
                    return (language === 'fr') ? 'Clients' : 'Clients';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'client', name: 'Clients'} :
                        {id: 'client', name: 'Clients'};
                }
                break;
            case 'demo':
                if (format === 'name') {
                    return (language === 'fr') ? 'Démos' : 'Demos';
                }
                if (format === 'object') {
                    return (language === 'fr') ?
                        {id: 'demo', name: 'Démos'} :
                        {id: 'demo', name: 'Demo'};
                }
                break;

        }
    }

    static get accountPermissionLevelByName() {
        return {
            'ngenio': 0,
            'subsidiary': 1,
            'distributor': 2,
            'partner': 3,
            'consultant': 4,
            'client': 5,
            'demo': 7,
            'report': 15,
            'other': 5,
            'bizdev': 5,
            'discretionary': 5,
            'unknown': 99
        };
    }

    static accountNameByPermissionLevel(level) {
        if (isNaN(level)) {
            return level;
        }
        return {
            0: 'ngenio',
            1: 'subsidiary',
            2: 'distributor',
            3: 'partner',
            4: 'consultant',
            5: 'client',
            7: 'demo',
        };
    }

    static setCurrentAccountTypesArray(accountType) {
        switch (accountType) {
            case 'ngenio':
                return ['subsidiary', 'distributor', 'partner', 'consultant', 'client', 'demo'];
            case 'subsidiary':
                return ['distributor', 'partner', 'consultant', 'client', 'demo'];
            case 'distributor':
                return ['partner', 'consultant', 'client', 'demo'];
            case 'partner':
                return ['consultant', 'client', 'demo'];
            case 'consultant':
                return ['client', 'demo'];
            default:
                return ['client', 'demo'];
        }
    }
}

