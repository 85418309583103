import {UntypedFormGroup} from '@angular/forms';

export function passwordStrengthValidator(formKey: string) {

    return (group: UntypedFormGroup): {[key: string]: any} => {
        const password: any = group.controls[formKey].value;

        const symbolsRegex = /[$-/:-?{-~!"^_`\[\]]/g;

        const lowerLettersTest = /[a-z]+/.test(password);
        const upperLettersTest = /[A-Z]+/.test(password);
        const numbersTest = /[0-9]+/.test(password);
        const symbolsTest = symbolsRegex.test(password);

        let passTest = 0;
        for (let currentTest of [lowerLettersTest, upperLettersTest, numbersTest, symbolsTest]) {
            if (currentTest) {
                passTest++;
            }
        }

        if (passTest < 3) {
            return {
                weakPassword: true
            };
        }
    }

}
