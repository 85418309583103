import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ModalModule} from 'ngx-bootstrap/modal';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

import {SharedModule} from '../shared/shared.module';
import {RightMatchesModule} from './right-matches/right-matches.module';

import {AnalysisRoutingModule} from './analysis-routing.module';
import {GraphboardsModule} from './graphboards/graphboards.module';
import {IdwMatricesModule} from './idw-matrices/idw-matrices.module';

import {TranslateModule} from '@ngx-translate/core';
import { AnalysisSharedModule } from './shared/analysis-shared.module';
import {JobSatisfactionsModule} from './jobSatisfactions/job-satisfactions.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgMultiSelectDropDownModule,
        SharedModule,
        RightMatchesModule,
        GraphboardsModule,
        IdwMatricesModule,
        AnalysisRoutingModule,
        TranslateModule,
        AnalysisSharedModule,
        JobSatisfactionsModule
    ],
    declarations: []
})
export class AnalysisModule {
}
