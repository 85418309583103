import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AccountService} from '../../services/account.service';
import {Router} from '@angular/router';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-administration-department-modal',
    templateUrl: './administration-department-modal.component.html',
    styleUrls: ['./administration-department-modal.component.scss']
})
export class AdministrationDepartmentModalComponent implements OnInit, OnDestroy {

    @Input() departmentModalAction;
    @Input() departmentModalData;
    @Input() lang;

    public close: EventEmitter<any> = new EventEmitter();
    public departmentModResult: EventEmitter<any> = new EventEmitter();

    deptName: string;

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private accountService: AccountService,
        private stateService: StateService,
        private router: Router
    ) {
    }

    ngOnInit() {
        if (this.departmentModalData) {
            if (!this.departmentModalData || Object.keys(this.departmentModalData).length === 0) {
                this.deptName = '';
            } else if (this.departmentModalData.en || this.departmentModalData.fr) {
                this.deptName = this.departmentModalData[this.translate.currentLang];
            }
        }
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetData() {
        this.delay(1000).then(() => {
            this.deptName = '';
            this.departmentModalAction = null;
            this.departmentModalData = null;
            this.lang = null;
        });
    }

    deleteDepartment() {
        this.closeModal();

        this.departmentModResult.emit({
            id: this.departmentModalData.id,
            action: 'delete'
        });

    }

    saveDepartment() {
        this.closeModal();

        let payload: any = {};
        payload.action = this.departmentModalAction;
        if (this.departmentModalData.id) {
            payload.id = this.departmentModalData.id;
            payload.action = 'edit';
        } else {
            payload.action = 'add';
        }
        payload['fr'] = this.deptName;
        payload['en'] = this.deptName;

        this.departmentModResult.emit(payload);
        this.resetData();
    }

    closeModal() {
        this.stateService.admin.stateChanged.next({refreshComponent: true});
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.close.emit({closed: true});
    }

}
