import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'app-mini-competences-graph',
    templateUrl: './mini-competences-graph.component.html',
    styleUrls: ['./mini-competences-graph.component.scss']
})
export class MiniCompetencesGraphComponent implements OnInit {

    @ViewChild('MiniCompetencesGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id: string;
    @Input() scores: any = [[], []]; // assume format '3,5...'
    @Input() type: string;

    mainScores: any;

    constructor() {
    }

    ngOnInit() {
        if (this.type === 'job') {
            this.scores[1] = [9, 9, 9, 9, 9, 9];
        }
        this.createMiniCompetenceGraph();
    }

    createMiniCompetenceGraph() {
        this.mainScores = this.scores[1];

        if(this.type !== 'cards') { d3.select('#mini_competences_' + this.id).remove(); }

        let element = this.chartContainer.nativeElement;

        let sizes = {
            rad: 60,
            translation: 30,
            barHeight: 9
        };
        if (this.type === 'cards') {
            sizes = {
                rad: 60,
                translation: 30,
                barHeight: 9
            };
        }

        let svg = d3.select(element)
            .append('svg')
            .attr('width', sizes.rad)
            .attr('height', sizes.rad)
            .attr('class', 'radial')
            .append('g')
            .attr('transform', 'translate(' + sizes.translation + ', ' + sizes.translation + ')')
            .attr('id', 'mini_competences_' + this.id)
            .attr('preserveAspectRatio', 'xMidYMid meet');


        svg.style('background-color', '1px solid blue');

        // Background circle
        svg.append('circle')
            // .attr('id', 'outerPath' + '_mini_competences_' + this.id)
            .attr('r', sizes.barHeight + 19)
            .classed('outer', true)
            .style('fill', 'white')
            .style('stroke', '#E2E2E2')
            .style('stroke-width', '1.5px');

        let extent = [0, 10];
        let barScale = d3.scaleLinear()
            .domain(extent)
            .range([0, sizes.barHeight]);




        // Outer circles

        let a = 0;
        let b = (2 * Math.PI) / 6;
        let dist = (b - a); // (angle opening)

        // Create arcs
        let arc = d3.arc()
            .startAngle(function (d, i) {
                return i * dist;
            })
            .endAngle(function (d, i) {
                return (i + 1) * dist;
            })
            .innerRadius(6);


        // Extract this scores

        let scores = [];

            for (let datum of this.mainScores) {
                scores.push({
                    value: datum,
                    outerRadius: null
                });
            }



        // Render colored arcs
        svg.selectAll('path')
            .data(scores)
            .enter().append('path')
            .each(function(d) {
                d.outerRadius = barScale(+d.value + 3) * 2.3;
            })
            .style('fill', (d) => {
                if (this.type === 'job') {
                    return 'rgb(227, 94, 36)';
                } else {
                    let val = d.value;
                    if (val <= 3) {
                        return '#779cc5'; // '#e2bb95';//
                    } else if (val <= 6) {
                        return '#efce7c'; // '#e59950';//
                    } else {
                        return '#78c7af'; // '#ea7300'; //rgb(227, 94, 36)//
                    }
                }
            })
            .style('stroke', 'white')
            .style('stroke-width', '1px')
            .attr('d', arc);

    }

}
