// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

#insufficientCreditLevelWarning {
  padding-top: 2px;
  padding-left: 10px;
  color: indianred;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-purchase-adjustement-modal/administration-purchase-adjustement-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n#insufficientCreditLevelWarning {\n    padding-top: 2px;\n    padding-left: 10px;\n    color: indianred;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
