import { Injectable } from '@angular/core';
import { StateService } from 'src/app/core/services/state/state.service';

@Injectable()
export class PermissionsService {

    constructor(
        private stateService: StateService
    ) {}

    public canDelete(): boolean {
        return ['s_admin', 'm_admin', 'admin'].includes(this.stateService.session.sessionData.permissions.userType);
    }
}
