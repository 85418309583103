import {Injectable} from '@angular/core';
import {Rest} from '../../../core/services/api/rest';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {EnvService} from '../../../app/services/env.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiJobSatisfactionsService extends Rest {

    constructor(
        protected http: HttpClient,
        protected translate: TranslateService,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    jobSatisfactions(dataParams: Array<any>): Observable<any> {
        dataParams[0]['lang'] = this.translate.currentLang;
        return this.apiGet(
            'jobsatisfactions',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    reportJobSatisfactions<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/jobsatisfaction',
            urlParams,
            dataParams,
            ['parts', 'language', 'data']
        );
    }

    jobSatisfaction(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'jobsatisfaction',
            urlParams,
            null,
            null
        );
    }

    reportEmail(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'email/report',
            null,
            dataParams,
            ['emailTo', 'subject', 'message', 'pdfLink', 'name', 'origin', 'lang']
        );
    }

    putJobSatisfaction(urlParams: [string], item: any): Observable<any> {
        return this.apiPut(
            'jobsatisfaction',
            urlParams,
            item
        );
    }

    postJobSatisfaction(data): Observable<any> {
        return this.apiPost(
            'jobsatisfaction',
            null,
            data
        );
    }

    deleteJobSatisfactions(idList: string[]): Observable<any> {
        return this.apiPost(
            'jobsatisfactions/delete',
            null,
            {idList: idList}
        );
    }

    deleteJobSatisfaction(urlParams: [string]): Observable<any> {
        return this.apiDelete(
            'jobsatisfaction',
            urlParams
        );
    }

}
