export class Graphboard {
    id: string;
    name: string;
    subAccount: number;
    date: Date;
    creationDate: Date;
    description: string;
    records: Array<any>;
    department: any;

    constructor(options: {
        id?: string,
        name?: string,
        subAccount?: number,
        date?: Date,
        creationDate?: Date,
        description?: string,
        records?: Array<any>,
        department?: any
    } = {}) {
        this.id = options.id;
        this.name = options.name;
        this.subAccount = options.subAccount;
        this.date = options.date;
        this.creationDate = options.creationDate;
        this.description = options.description;
        this.records = options.records;
        this.department = options.department;
    }
}
