import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import * as _ from 'lodash';
import {StateService} from 'src/app/core/services/state/state.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-idw-matrix-person-modal',
    templateUrl: './idw-matrix-person-modal.component.html',
    styleUrls: ['./idw-matrix-person-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatrixPersonModalComponent implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    searchTerm: any;
    records: Array<any>;
    selectedItems: Array<any> = [];

    recordsLoaded = 100;
    recordsLength: number;
    public loading = false;

    displayedType = 'dit';

    actual = '';

    private subscriptions = new Subscription();

    constructor(
        public idwMatrix: IdwMatrixAnalysisService,
        public bsModalRef: BsModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private stateService: StateService,
        private apiIdwMatricesService: ApiIdwMatricesService,
        private apiPeopleService: ApiPeopleService) {
    }

    ngOnInit() {

        if (this.idwMatrix.itemToDisplay) {

            this.fetchRecords('dit');
            // remove prbs
            let newRecords = [];
            if (this.idwMatrix.records) {
                for (let item of this.idwMatrix.records) {
                    if (item && item.analysisType !== 'prb') {
                        newRecords.push(item);
                    }
                }
            }
            this.idwMatrix.records = newRecords;
            this.selectedItems = [];
            if (this.idwMatrix.records) {
                for (let record of this.idwMatrix.records) {
                    this.selectedItems.push({
                        type: 'trait',
                        record: record.id
                    });
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(this.displayedType, searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(this.displayedType, searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(this.displayedType, null);
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {
            return;
        }

        this.fetchRecords(this.displayedType, this.searchTerm, this.recordsLoaded, true);
    }

    fetchRecords(type, searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        let request = {
            'skip': skip,
            'search': searchTerm,
            'limit': this.recordsLoaded,
            'reportStatuses': {dit: 1},
            'isArchived': 0
        };

        this.subscriptions.add(this.apiPeopleService.records(
            [request]
        ).subscribe(
                (res) => {
                    // Get records loaded (add the new records from API)
                    this.recordsLoaded = (skip && res.data) ?
                        this.recordsLoaded + res.data.length :
                        this.recordsLoaded;

                    // Records data (merge new records from API)
                    this.records = (concat) ?
                        [...this.records, ...res.data] :
                        res.data;

                    // Total records
                    this.recordsLength = res.count;

                    // Reload component
                    this.changeDetectorRef.markForCheck();

                    this.loading = false;
                }
        ));
    }

    predicateCheckBox(record) {
        return ((_.findIndex(this.selectedItems, function (i) {
                return (i.record === record.id);
            }
        )) !== -1);
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems.push({
                type: 'trait',
                record: id
            });
        } else {
            _.remove(this.selectedItems, {record: id});
        }
    }

    onSubmit() {
        let selectedItemsTemp = [];
        if (this.selectedItems) {
            for (let item of this.selectedItems) {
                selectedItemsTemp.push({
                    type: 'prb',
                    record: item.record,
                    dash: 0
                });
            }
            this.selectedItems = this.selectedItems.concat(selectedItemsTemp);
            if (!this.selectedItems.length) {
                // no record has been selected
                this.selectedItems.push({
                    type: '',
                    record: '',
                    dash: 0
                });
            }
            // console.log(this.selectedItems);
            const data = {'records': this.selectedItems};
            // console.log(data);
            this.subscriptions.add(this.apiIdwMatricesService.putDitGram([this.idwMatrix.id], data).subscribe(
                () => {
                    this.closeModal();
                }
            ));
        }
    }

    closeModal() {
        this.close.emit({closed: true, id: this.idwMatrix.id});
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}
