import * as form_definition from '../../../app/assets/form-definitions/login';

import {LoginService} from '../../../app/services/login.service';
import {StateService} from '../../../core/services/state/state.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {AfterViewInit, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Location} from '@angular/common';
import { JwtHelperService } from '../../services/jwt-helper.service';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {EnvService} from '../../services/env.service';

@Component({
    selector       : 'app-login',
    templateUrl    : 'login.component.html',
    styleUrls      : ['login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, AfterViewInit {
    errorLogin: any;
    loginForm: any;

    public loading = false;

    language: string;

    passwordStaging = null;

    pw: string;
    lang: string;
    small: boolean;
    versionNumber;

    baseUrl = '';

    passwordChangeSuccess = false;

    @Input() redirect = false;
    @Output() credentials = new EventEmitter();

    constructor(public state: StateService,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        public translate: TranslateService,
        private el: ElementRef,
        private renderer: Renderer2,
        private location: Location,
        private changeDetectorRef: ChangeDetectorRef, // Used in the service
        private jwtHelperService: JwtHelperService,
        private apiAdministrationService: ApiAdministrationService,
        public environment: EnvService
    ) {
        this.loginForm = form_definition.login_form;
    }

    changeLang(lang) {
        localStorage.setItem('sessionLang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    checkToken(pageToRedirect: string = null) {
        if (!this.jwtHelperService.isTokenExpired()) {
            if (pageToRedirect) {
                let urlParamsString = pageToRedirect.split(';');
                this.router.navigate([urlParamsString[0]]).then(() => {});
            }
        }
    }

    setPageLanguage(language) {
        if (language) {
            this.language = language;
        } else if (localStorage.getItem('sessionLang')) {
            this.language = localStorage.getItem('sessionLang');
        } else {
            if (!this.language) {
                this.language = this.translate.getBrowserLang();
            }
        }
        this.language = (this.language !== 'en' &&  this.language !== 'fr' ) ? 'en' : this.language;
        this.translate.setDefaultLang(this.language);
        this.translate.use(this.language);
        localStorage.setItem('logOutSessionLang', this.language);
    }

    ngOnInit() {
        this.baseUrl = (this.environment && this.environment.apiBaseUrl) ? this.environment.apiBaseUrl : '';
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                let setLangTo = (localStorage.getItem('sessionLang')) ? localStorage.getItem('sessionLang') : this.translate.getBrowserLang();
                let urlArr = event.url.split(';');
                urlArr.forEach((q) => {
                    if (q.includes('lang=')) {
                        setLangTo = q.replace('lang=', '');
                    }
                });
                if (urlArr[0] === 'login') {
                    this.setPageLanguage(setLangTo);
                }
                this.changeDetectorRef.markForCheck();
            });
        if (!this.language) {
            if (localStorage.getItem('logOutSessionLang')) {
                this.language = localStorage.getItem('logOutSessionLang');
            } else {
                this.language = 'fr';
            }
            this.translate.setDefaultLang(this.language);
            this.translate.use(this.language);
        }

        this.apiAdministrationService.frontVersion().subscribe(
            res => {
                // console.log(res.version);
                this.versionNumber = 'version ' + res.version;
                // console.log(this.versionNumber);
                this.changeDetectorRef.markForCheck();
            }
        );
        this.small = !!(window.innerWidth < 955);
        let urlParamsString = this.location.path().split('/');
        let urlParams = (typeof urlParamsString[1] !== 'undefined'
            && typeof (urlParamsString[1].split('?'))[1] !== 'undefined'
        ) ?
            (urlParamsString[1].split('?'))[1].split(';') :
            '';
        for (let urlParam of urlParams) {
            if ('undefined' !== typeof urlParam) {
                let urlParamArr = urlParam.split('=');
                if (urlParamArr[0] === 'pw') { this.pw = urlParamArr[1]; }
                if (urlParamArr[0] === 'lang') {
                    this.lang = urlParamArr[1];
                    this.language = this.lang;
                    this.translate.setDefaultLang(this.lang);
                    this.translate.use(this.lang);
                    // console.log(this.lang);
                }
            }
        }

        this.passwordChangeSuccess = !!this.pw;
        // localStorage.setItem('logOutSessionLang', this.language);
        // Default redirect if authenticated ( @ilya 2020-09-28 - default redirect has been canceled)
        // this.checkToken();

        // when a mobile page is loaded in the regular login
        // we have to redirect it to it's regular version
        if (urlParamsString[1] === 'm') {
            let pageToRedirect = this.location.path().replace('/m/', '/');
            this.checkToken(pageToRedirect);
        }
    }

    onResize(event) {
        this.small = !!(event.target.innerWidth < 955);
    }

    ngAfterViewInit() {
        // const img = this.renderer.createElement('img');
        // this.renderer.setAttribute(img, 'src', 'assets/buttons/login.png');
        // this.renderer.setAttribute(img, 'class', 'pull-left');

        // const button = this.el.nativeElement.querySelector(
        //    'app-form-table form table#table_loginForm tr td#loginForm-0-5-1-middle_field div button.btn'
        // );
        // this.renderer.appendChild(button, img);
        // this.renderer.setStyle(button, 'width', "160px");


        const link = this.el.nativeElement.querySelector(
            'app-form-table form table#table_loginForm tr td#loginForm-0-4-0-blank-html-a a'
        );
        this.renderer.listen(link, 'click', () => {
                // this.page = 'reset-pass';
                this.router.navigate(['reset-pass']).then(() => {});
                this.changeDetectorRef.markForCheck();
            });
        const checkbox = this.el.nativeElement.querySelector(
            'div#loginForm-0-5-0-cbox_container input'
        );
        this.renderer.setAttribute(checkbox, 'value', 'checked');
            this.renderer.listen(checkbox, 'change', (val) => {
            if (val.target.checked) {
                localStorage.setItem('remember_me', 'true');
            } else {
                localStorage.setItem('remember_me', 'false');
            }
        });
    }

    handleForm(event) {
        if (
            this.baseUrl === 'https://staging.ngenioconnect.com/' &&
            this.passwordStaging !== 'QAMaster!'
        ) {
            return;
        }
        this.loading = true;
        this.loginService.login(this, event, this.language);
    }
}
