// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  font-size: 1.1rem;
  min-height: 92vh;
}

@media screen and (max-device-height: 670px) {
  form {
    min-height: 105vh;
  }
}
@media screen and (max-device-height: 570px) {
  form {
    min-height: 120vh;
  }
}
@media screen and (max-device-height: 420px) {
  form {
    min-height: 163vh;
  }
}
@media screen and (max-device-height: 375px) {
  form {
    min-height: 187vh;
  }
}
@media screen and (max-device-height: 320px) {
  form {
    min-height: 210vh;
  }
}
@media screen and (min-device-height: 820px) {
  form {
    min-height: 82vh;
  }
}
.mat-mdc-form-field {
  width: 100%;
}

.peopleEditFooter button:first-child {
  margin-right: 10px;
}

::ng-deep .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
::ng-deep .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label,
::ng-deep .mat-mdc-select-value-text {
  font-size: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/mobile/people-edit/mobile-people-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,iBAAA;EACN;AACF;AAEA;EACI;IACI,iBAAA;EAAN;AACF;AAGA;EACI;IACI,iBAAA;EADN;AACF;AAIA;EACI;IACI,iBAAA;EAFN;AACF;AAKA;EACI;IACI,iBAAA;EAHN;AACF;AAMA;EACI;IACI,gBAAA;EAJN;AACF;AAOA;EACI,WAAA;AALJ;;AAQA;EACI,kBAAA;AALJ;;AAQA;;;EAGI,kBAAA;AALJ","sourcesContent":["form {\n    font-size: 1.1rem;\n    min-height: 92vh;\n}\n\n@media screen and (max-device-height: 670px){\n    form {\n        min-height: 105vh;\n    }\n}\n\n@media screen and (max-device-height: 570px){\n    form {\n        min-height: 120vh;\n    }\n}\n\n@media screen and (max-device-height: 420px){\n    form {\n        min-height: 163vh;\n    }\n}\n\n@media screen and (max-device-height: 375px){\n    form {\n        min-height: 187vh;\n    }\n}\n\n@media screen and (max-device-height: 320px){\n    form {\n        min-height: 210vh;\n    }\n}\n\n@media screen and (min-device-height: 820px){\n    form {\n        min-height: 82vh;\n    }\n}\n\n.mat-mdc-form-field {\n    width: 100%;\n}\n\n.peopleEditFooter button:first-child {\n    margin-right: 10px;\n}\n\n::ng-deep .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,\n::ng-deep .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label,\n::ng-deep .mat-mdc-select-value-text {\n    font-size: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
