import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-people-survey-request-participant-info',
    templateUrl: './participant-info.component.html',
    styleUrls: ['./participant-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestParticipantInfoComponent {
    @Input() surveyRequestFormGroup: UntypedFormGroup;
    @Input() isFormSubmitted: boolean;
    @Input() researchAccount: any;
    @Input() mobile = false;
}
