// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  font-size: 115%;
}

.modLink {
  display: inline-block;
  width: 70%;
  float: right;
  text-align: right;
}

.clear {
  clear: both;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/web/jobs-report-talents/jobs-report-talents.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":["h4 {\n    font-size: 115%;\n}\n\n.modLink {\n    display: inline-block;\n    width: 70%;\n    float: right;\n    text-align: right;\n}\n\n.clear {\n    clear: both;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
