import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../core/services/state/state.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiPeopleService} from '../../../services/api-people.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-people-multiselect-action',
    templateUrl: './people-multiselect-action.component.html',
    styleUrls: ['./people-multiselect-action.component.scss']
})
export class PeopleMultiselectActionComponent implements OnInit, OnDestroy {

    @Input() multiselectData: any;

    public transfer: EventEmitter<any> = new EventEmitter();
    public close: EventEmitter<any> = new EventEmitter();

    container: any = null;
    location: string;
    subAccounts: any;
    departments: any;

    isAccountsLoaded = false;
    searchTerm: any = null;
    selectedAccountNumber: any = null;
    iconsFolder = 'assets/header/';
    buttonsFolder = 'assets/buttons/';
    items: any = [];

    typeItem;

    constructor(
        public bsModalRef: BsModalRef,
        private stateService: StateService,
        public translateService: TranslateService,
        public apiPeopleService: ApiPeopleService
    ) {
    }

    ngOnInit() {
        if (this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.structure
        ) {
            this.subAccounts = this.stateService.session.sessionData.structure.subAccounts;
            this.departments = this.stateService.session.sessionData.structure.departments;
        }
        this.container = null;

        if (this.multiselectData.actionType === 'transferOtherAcc') {
            this.location = this.translateService.instant('commons.accounts');
        }
        if (this.multiselectData.actionType === 'subaccount') {
            this.selectedAccountNumber = true;
            this.location = this.translateService.instant('commons.subAccounts');
            this.location = this.location.toLowerCase();
        }
        if (this.multiselectData.actionType === 'department') {
            this.selectedAccountNumber = true;
            this.location = this.translateService.instant('commons.departments');
        }
        this.getAccountsList();
        let intervalId = setInterval(() => {
            if (this.isAccountsLoaded) {
                clearInterval(intervalId);
            }
        }, 1000);
    }
    getAccountsList() {
        let request: any = {
            namesOnly: 1,
            limit: 10000,
            skip: 0
        };
        this.apiPeopleService.clientForTransfer([request]).pipe(take(1)).subscribe(
            res => {
                if (res && res.data && res.data.length) {
                    this.isAccountsLoaded = true;
                    res.data.forEach(acc => {
                        this.items.push({
                            id: acc.id,
                            accountName: acc.name,
                            accountNumber: acc.number
                        });
                    });
                } else {
                    this.isAccountsLoaded = true;
                }
            });
    }

    transferReports() {
        if (this.container != null && this.multiselectData.actionType !== 'transferOtherAcc') {
            this.container = (this.multiselectData.actionType === 'department') ? [this.container] : this.container;

            this.multiselectData['container'] = this.container;
            this.transfer.emit({multiselectData: this.multiselectData});
        }
        if (this.multiselectData.actionType === 'transferOtherAcc' && this.selectedAccountNumber) {
            this.multiselectData['container'] = this.selectedAccountNumber;
            this.transfer.emit({multiselectData: this.multiselectData});
        }
        this.closeModal();
    }

    closeModal() {
        this.close.emit();
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        this.clearSearch();
        this.close.emit();
    }

    selectedItem(selItem) {
        if (this.searchTerm) {
            /**
             *  uncomment the lines below to cancel the automatic value selection by Enter button
             */
            // this.container = this.searchTerm;
            // this.searchTerm = null;
        }
        // console.log(selItem, this.container);
        if (selItem) { // removec  && this.container === selItem.value
            this.selectedAccountNumber = selItem.item.accountNumber;
        }
    }

    onSearch(event) {
        this.selectedAccountNumber = null;
        if (this.container && event.keyCode === 13) {
            this.searchTerm = this.container;
        }
    }

    clearSearch() {
        this.searchTerm = null;
        this.container = null;
        this.selectedAccountNumber = null;
    }

}
