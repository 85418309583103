import {Injectable} from '@angular/core';
import {FormsHelper} from '../helpers/FormsHelper';
import {AccountService} from './account.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class SubAccountService {

    private subAccountData;

    constructor(
        private accountService: AccountService
    ) {
    }

    clearSubAccountData() {
        this.subAccountData = null;
    }

    setSubAccountDataById(id) {
        this.subAccount = _.find(this.accountService.subAccounts, {id: id});
    }

    private set subAccount(subAccount) {
        this.subAccountData = subAccount;
    }

    get id() {
        return this.subAccountData?.id;
    }

    get credits() {
        return this.subAccountData.credits;
    }

    get subAccountName() {
        return this.subAccountData.name;
    }

    get createdBy() {
        return (this.subAccountData?.createdBy?.fullName) ? this.subAccountData.createdBy.fullName : 'NA';
    }

    get modifiedBy() {
        return (this.subAccountData?.modifiedBy?.fullName) ? this.subAccountData.modifiedBy.fullName : 'NA';
    }

    get status() {
        return this.subAccountData.status;
    }

    get employeesNumber() {
        return this.subAccountData?.employeesNumber;
    }

    get addressInfo() {
        let addressInfo = {};
        for (let field of FormsHelper.fieldsName(FormsHelper.addressInfo)) {
            if (this.subAccountData.addressInfo && this.subAccountData.addressInfo[field]) {
                addressInfo[field] = this.subAccountData.addressInfo[field];
            } else {
                if (this.subAccountData.hasOwnProperty(field)) {
                    addressInfo[field] = this.subAccountData[field];
                } else {
                    addressInfo[field] = '';
                }
            }
        }
        return addressInfo;
    }

    get phoneNumber() {
        return this.subAccountData.phoneNumber;
    }

    get webSite() {
        return this.subAccountData.webSite;
    }
}
