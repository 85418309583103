export const login_form = {
    name: 'loginForm',
    properties: {
        rows: 3,
        columns: 2
    },
    structure: [
        [
            [
                {
                    name: {
                        'en': 'Username',
                        'fr': 'Nom d\'utilisateur'
                    },
                    display: {
                        type: 'pureText'
                    },
                    options: {
                        colspan: 2
                    }
                }
            ],
            [
                {
                    name: 'username',
                    initialValue: '',
                    validators: ['required'],
                    states: {
                        touched: false
                    },
                    display: {
                        text: '',
                        merge: true,
                        type: 'text',
                        visualWarnings: {
                            border: true
                        }
                    },
                    options: {
                        reverse: true,
                        merge: true,
                        colspan: 2
                    }
                }
            ],
            [
                {
                    name: {
                        'en': 'Password',
                        'fr': 'Mot de passe'
                    },
                    display: {
                        type: 'pureText'
                    },
                    options: {
                        colspan: 2
                    }
                }
            ],
            [
                {
                    name: 'password',
                    initialValue: '',
                    validators: ['required'],
                    states: {
                        touched: false
                    },
                    display: {
                        text: '',
                        type: 'password',
                        visualWarnings: {
                            border: true
                        }
                    },
                    options: {
                        reverse: true,
                        merge: true,
                        colspan: 2
                    }
                }
            ],
            [
                {
                    // name: '<a (click)="linkToRecoverPassword()">Nom d\'utilisateur ou mot de passe oublié ?</a>',
                    name: {
                        'fr': 'Mot de passe oublié ?',
                        'en': 'Forgotten password ?'
                    },
                    display: {
                        type: 'pureHtml_a'
                    },
                    options: {
                        colspan: 2
                    }
                }
            ],
            [
                {
                    name: 'rememberMe',
                    initialValue: '',
                    display: {
                        text: {
                            en: 'Remember me',
                            fr: 'Se souvenir de moi',
                        },
                        type: 'checkbox'
                    },
                    options: {
                        reverse: true,
                        merge: true,
                        colspan: 1
                    }
                },
                {
                    name: 'submit',
                    display: {
                        type: 'submit_login',
                        text: null,
                        textButton:
                            {
                                en: 'Connect',
                                fr: 'Se connecter'
                            }
                    },
                    options: {
                        colspan: 1,
                        merge: true
                    }
                }
            ]
        ]
    ],
    nonValidMessage: {
        en: 'Enter a username and password',
        fr: 'Entrez un nom d\'utilisateur et un mot de passe'
    }
};
