import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    OnInit
} from '@angular/core';
import {Router} from '@angular/router';
//// TESTING /////
import {StateService} from '../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
//////////////////
import {TextHelper} from '../../../../shared/misc/text.helper';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import {take} from 'rxjs/operators';
import {PeopleList} from '../../../services/people_list.service';

@Component({
    selector: 'app-people-report-communication',
    templateUrl: './people-report-communication.component.html',
    styleUrls: ['./people-report-communication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleReportCommunicationComponent implements OnInit, OnDestroy { // OnDestroy
    @Input() originReportType: string;
    @Input() hideGraph = false;

    report: any;
    titleSep: string;
    dateFormat: string;
    showFakeReport = false;
    subscriptions = new Subscription();

    constructor(//// TESTING /////
        private stateService: StateService,
        //////////////////
        private router: Router,
        private testAndReportHandlingService: TestAndReportHandlingService,
        private changeDetectorRef: ChangeDetectorRef, // used in peopleReportService even if flagged otherwise
        public translate: TranslateService,
        public person: PersonService
    ) {

    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.getCommunicationReport();

        this.subscriptions.add(this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
                this.getCommunicationReport();
            }
        ));

        this.stateService.people.stateChanged$.subscribe(
            (res) => {
                if (res && (res.personId || res.commTraits)) {
                    this.stateService.people.report['originReportType'] = undefined;
                    this.getCommunicationReport();
                } else if (res && res.commPrb ) {
                    this.getCommunicationReport();
                    this.stateService.people.report['originReportType'] = 'ditprb';
                }
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    getCommunicationReport() {
        this.testAndReportHandlingService.getReport('communication', this.translate.currentLang).pipe(take(1))
            .subscribe(
                report => {
                    this.report = report;
                    this.changeDetectorRef.markForCheck();
                },
                err => console.log(err)
            );
    }

    trackByProfile(index: number, item: any) {
        if (!item) {
            return null;
        }
        return item.id;
    }

    moveTo(fragment) {
        const element = document.querySelector('#' + fragment);
        if (element) {
            element.scrollIntoView(true);
        }
        return;
    }

    gotToCommunication(): void {
        // TODO: remove - refactoring
        /**
         this.stateService.navigate(
         this.router,
         'people/report',
         'getFullReport',
         {
                personToDisplayId: this.stateService.people.report.personToDisplayId,
                reportType: 'dit',
                originReportType: 'dit'
            }
         );*/
    }

    get profile(): any {
        if (this.report) {
            this.showFakeReport = true;
            if (this.report.profile && this.report.profile.length > 0) {
                this.showFakeReport = false;
                return this.report.profile;
            }
        } else {
            this.showFakeReport = false;
        }

        return false;
    }

    get prb(): any {
        if (this.person.prbReport) {
            return this.testAndReportHandlingService.getPrbData(
                this.person.prbReport,
                this.stateService.people.prbIndex
            );
        }
        return false;
    }

    get warnings(): any {
        if (
            this.report.info.evaluationWarnings.extremeScores ||
            this.report.info.evaluationWarnings.socialDesirability
        ) {
            return this.report.info.evaluationWarnings;
        }
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
