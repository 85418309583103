import { Injectable } from '@angular/core';

@Injectable()
export class JwtHelperService {
  tokenGetter: () => string;

  constructor() {
    this.tokenGetter = () => localStorage.getItem('id_token');
  }

  decodeToken(token?: string): any {
    if (!token) { token = this.tokenGetter(); }
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    return null;

  }

  getTokenExpirationDate(token?: string): Date | null {
    if (!token) { token = this.tokenGetter(); }
    if (!token) { return null; }
    const decoded = this.decodeToken(token);
    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string, offsetSeconds?: number): boolean {
    if (!token) token = this.tokenGetter();
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;
    if (date === null) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }
}
