import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter, OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiRightMatchesService } from '../../right-matches/services/api-right-matches.service';
import { ApiIdwMatricesService } from '../../idw-matrices/services/api-idw-matrices.service';
import { RightMatchAnalysisService } from '../../right-matches/services/right-match-analysis.service';
import { IdwMatrixAnalysisService } from '../../idw-matrices/services/idw-matrix-analysis.service';
import {ApiJobSatisfactionsService} from '../../jobSatisfactions/services/api-job-satisfactions.service';
import {JobSatisfaction} from '../../jobSatisfactions/models/job-satisfaction.model';
import {JobSatisfactionsAnalysisService} from '../../jobSatisfactions/services/job-satisfactions-analysis.service';

@Component({
    selector: 'app-add-people-analysis-modal',
    templateUrl: './add-people-analysis-modal.component.html',
    styleUrls: ['./add-people-analysis-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPeopleAnalysisModalComponent implements OnInit {
    @Input() multiAction;

    public close: EventEmitter<any> = new EventEmitter();
    public peopleList: any[];
    public analysisList: any[];
    public selectedAnalysis = '';
    public isSubmitted = false;
    public modalTitle = '';
    public analysisType: string;
    headerText: string;

    constructor (
        private bsModalRef: BsModalRef,
        private apiRightMatches: ApiRightMatchesService,
        public rightMatch: RightMatchAnalysisService,
        private apiIdwMatrices: ApiIdwMatricesService,
        private apiJobSatisfactions: ApiJobSatisfactionsService,
        public idwMatrix: IdwMatrixAnalysisService,
        public jobSatisfaction: JobSatisfactionsAnalysisService
    ) {}

    ngOnInit() {
        if (this.multiAction && this.multiAction.ids && this.multiAction.ids.length) {
            this.headerText = (this.multiAction.ids.length === 1) ?
                'analysisCommons.addCandidateToAnalysis' :
                'analysisCommons.addCandidatesToAnalysis';
        }
    }

    /**
     * Close the modal
     */
    public closeModal(id = null): void {
        this.close.emit({id: id});
        this.bsModalRef.hide();
    }

    /**
     * Save data
     */
    public save(): void {
        this.isSubmitted = true;
        if (this.selectedAnalysis !== '') {
            let getObservable: Observable<any>;
            if (this.analysisType === 'idw') {
                getObservable = this.apiIdwMatrices.ditGram([this.selectedAnalysis]);
            }
            if (this.analysisType === 'jobSatisfaction') {
                getObservable = this.apiJobSatisfactions.jobSatisfaction([this.selectedAnalysis]);
            }
            if (this.analysisType === 'rightmatch') {
                getObservable = this.apiRightMatches.rightMatch([this.selectedAnalysis]);
            }
            getObservable.pipe(take(1)).subscribe(
                res => {

                    // Get current analysis to concat them to the new array
                    // const currentAnalysisRecords = this.analysisList.filter(
                    //     (analysis: any) => analysis.id === this.selectedAnalysis
                    // ).map(
                    //     (analysis: any) => analysis.records
                    // ).reduce(
                    //     (accumulator, currentValue) => [...accumulator, ...currentValue], []
                    // );
                    const currentAnalysisRecords = res.records;

                    // Get the distinct id list to not duplicate entries
                    const currentIdList = currentAnalysisRecords.filter(
                        (item: any) => item && item.id
                    ).map(
                        (item: any) => item.id
                    ).reduce(
                        (unique: any, id: any) => unique.includes(id) ? unique : [...unique, id], []
                    );

                    // Get new analysis record to insert
                    const analysisRecords = this.peopleList.filter(
                        (people: any) => !currentIdList.includes(people.id)
                    ).map(
                        (people: any) => people.analysisData
                    ).reduce(
                        (accumulator, currentValue) => [...accumulator, ...currentValue], []
                    );

                    // Create PUT Observable
                    let putObervable: Observable<any>;
                    if (this.analysisType === 'idw') {
                        putObervable = this.apiIdwMatrices.putDitGram([this.selectedAnalysis], {records: [...analysisRecords], appendRecords: true});
                        if (this.selectedAnalysis) {
                            this.idwMatrix.setItemToDisplayWithId(this.selectedAnalysis);
                        }
                    }
                    if (this.analysisType === 'rightmatch') {
                        putObervable = this.apiRightMatches.putRightMatch([this.selectedAnalysis], {records: [...analysisRecords], appendRecords: true});
                        if (this.selectedAnalysis) {
                            this.rightMatch.setItemToDisplayWithId(this.selectedAnalysis);
                        }
                    }
                    if (this.analysisType === 'jobSatisfaction') {
                        putObervable = this.apiJobSatisfactions.putJobSatisfaction([this.selectedAnalysis], {records: [...analysisRecords], appendRecords: true});
                        if (this.selectedAnalysis) {
                            this.jobSatisfaction.setItemToDisplayWithId(this.selectedAnalysis);
                        }
                    }
                    // take(1): make one call and complete
                    putObervable.pipe(take(1)).subscribe(
                        () => {
                            this.closeModal(this.selectedAnalysis);
                        }
                    );
                });
        }
    }
}
