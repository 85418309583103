import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {StateService} from 'src/app/core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {ApiIdwMatricesService} from './api-idw-matrices.service';

@Injectable({
    providedIn: 'root'
})
export class IdwMatrixAnalysisService extends ContentItem {
    public loading = false;
    private idwMatrix;

    readonly persistentVariables = [
        'idwMatrix'
    ];

    constructor(
        private apiIdwMatrices: ApiIdwMatricesService,
        private translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('idwMatrixService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setItemToDisplayWithId(id, specificState = 'idwMatrix', action = null) {
        let obsResult = {};
        this.loading = true;
        this.apiIdwMatrices.ditGram([id]).pipe(take(1)).subscribe(
            res => {
                if (res) {
                    this.idwMatrix = res;
                    this.loading = false;
                    this.storeServiceData('idwMatrixService', this.persistentVariables, this);
                    this.stateService[specificState].itemToDisplayId = res.id;
                    if (this.isRightModule(specificState)) {
                        obsResult = this.resetSidebarToReport(specificState);
                    } else {
                        this.stateService[specificState].sideBarStatus = 'recentActivities';
                    }
                    obsResult['itemToDisplayId'] = res.id;
                    this.stateService[specificState].stateChanged.next(obsResult);
                    if (action === 'mobileView') {
                        this.router.navigate(['m/analysis/idwmatrix/report/' + res.id]).then(() => {});
                    }
                }
            }
        );
    }

    isRightModule(specificState) {
        if (specificState === 'idwMatrix' && !this.archive) {
            return true;
        }
        return !!(specificState === 'archivesIdwMatrix' && this.archive);
    }

    resetItemToDisplay(specificState = 'idwMatrix') {
        this.itemToDisplay = {};
        this.stateService[specificState].itemToDisplayId = undefined;
        this.stateService[specificState].stateChanged.next({
            'itemDeleted': true
        });
        this.stateService[specificState].sideBarStatus = 'recentActivities';
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get itemToDisplay(): any {
        return _.get(this, 'idwMatrix');
    }

    set itemToDisplay(item) {
        this.idwMatrix = item;
        this.storeServiceData('idwMatrixService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'idwMatrix.id');
    }

    get date(): string {
        return _.get(this, 'idwMatrix.date');
    }

    get creationDate(): string {
        return _.get(this, 'idwMatrix.creationDate');
    }

    get name(): string {
        return _.get(this, 'idwMatrix.name');
    }

    get consultant(): any {
        return _.get(this, 'idwMatrix.consultant');
    }

    get subAccount(): any {
        return _.get(this, 'idwMatrix.subAccount');
    }

    set records(records) {
        this.idwMatrix.records = records;
        this.storeServiceData('idwMatrixService', this.persistentVariables, this);
    }

    get records(): any {
        return _.get(this, 'idwMatrix.records');
    }

    get department(): any {
        let department = _.get(this, 'idwMatrix.department');
        if (department !== undefined && department.length > 0) {
            let currentLang = this.translateService.currentLang;
            if (currentLang === '' || currentLang === undefined) {
                currentLang = 'fr';
            }
            department = department[0][currentLang];
        } else {
            department = undefined;
        }
        return department;
    }

    get departmentId() {
        let department = null;
        let assDepartment = _.get(this, 'idwMatrix.department');
        if (assDepartment !== undefined && assDepartment.length > 0) {
            department = assDepartment[0].id;
        }
        return department;
    }

    get archive() {
        return _.get(this, 'idwMatrix.archive');
    }

}
