import {animate, state, style, transition, trigger} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ComponentList} from 'src/app/shared/classes/components/component_list';
import {StateService} from '../../../../core/services/state/state.service';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {JobsListService} from '../../../services/jobs-list.service';
import {JobService} from '../../../services/job.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-mobile-jobs-list',
    templateUrl: './mobile-jobs-list.component.html',
    styleUrls: ['./mobile-jobs-list.component.scss'],
    animations: [
        trigger('selected', [
            state('none', style({
                display: 'none',
                transform: 'scale(0)'
            })),
            state('some', style({
                display: '',
                transform: 'scale(1)'
            })),
            transition('none => some', animate('120ms ease-in')),
            transition('some => none', animate('120ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileJobsListComponent extends ComponentList implements OnInit, OnDestroy {

    logoImg = '/assets/menu_gauche/MPO_32px.png';
    addJob = false;
    lastSearch: string;

    private subscriptions = new Subscription();
    public orderedBy;

    constructor(
        protected router: Router,
        private route: ActivatedRoute,
        public stateService: StateService,
        public translate: TranslateService,
        private apiJobs: ApiJobsService,
        private alertService: AlertService,
        public jobsList: JobsListService,
        public jobService: JobService,
        protected deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(stateService, translate, router, deviceService);
        this.orderedBy = this.jobsList.jobsState.listColumns.filter(col => col.order !== undefined);
    }

    /**
     * Set search parameter on event from shared/main-list/component/mobile/mobile-main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.lastSearch = event;
        this.jobsList.setSearchFilter(event);
        this.refreshList();
        this.blurSearchElement();
    }

    /**
     * Remove focus from the search input
     */
    blurSearchElement() {
        let activeElement = document.activeElement as HTMLElement;
        activeElement.blur();

        // check the case when the button Search/Clear was clicked
        setTimeout(() => {
            if (document.activeElement.tagName === 'INPUT') {
                activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
            }
        }, 0 );
    }

    /**
     * Set selected person on event from shared/main-list/component/main-list
     * @param item
     */
    jobSelected(item): void {
        this.closed = false;
        this.jobsList.jobsState.lastSideBarStatus = 'reports';
        this.jobsList.jobSelected(item);
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.jobsList.setOrder(event);
        this.refreshList();
    }

    refreshList() {
        this.jobsList.getJobList(true);
    }

    goToPeople() {
        this.router.navigate(['m/people/']).then(() => {});
    }

    createNewJob() {
        this.addJob = true;
    }

    backToItemsList() {
        this.router.navigate(['m/jobs/list', {cancel: true, moduleName: 'jobs'}]).then(() => {
            this.addJob = false;
        });
    }

    ngOnInit() {
        this.lastSearch = this.jobsList.jobsState.listSearchFilters.search;
        // Reset the subAccount and department properties in the search
        this.jobsList.jobsState.listSearchFilters.subAccount = undefined;
        this.jobsList.jobsState.listSearchFilters.department = undefined;
        this.jobsList.checkModuleUrl();
        this.jobsList.checkUserJobsDisplayPreferences();

        // check items list without selection
        this.jobsList.checkTotalItems();

        if (this.jobService.id) {
            this.jobService.setItemToDisplayWithId(this.jobService.id, this.jobsList.specificState);
        }

        // WATCHES ////////////////////////////////////////////////////////////////////////

        this.jobsList.jobsState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.jobsList.getJobList(true);
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            () => {
                this.jobsList.getJobList(true);
            }
        ));

        // Change Route event
        this.stateService.session.actualModule = this.jobsList.specificState;

        // Init list with the last search to call the watcher
        this.jobsList.setSearchFilter(this.lastSearch);
        this.blurSearchElement();

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
