import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mobile-ng-nav-icon',
  templateUrl: './mobile-ng-nav-icon.component.html',
  styleUrls: ['./mobile-ng-nav-icon.component.scss']
})
export class MobileNgNavIconComponent implements OnChanges {

    @Input() iconSize = ""; // possible values = { '', '20', '24', '32'}
    @Output() clicked = new EventEmitter();

    constructor() {
    }

    ngOnChanges(){

    }



    get currentSize(){
      let underscore = '';
      if(this.iconSize !== '') underscore = '_';
      return underscore + this.iconSize;
    }
}
