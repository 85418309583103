import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {ApiGraphboardsService} from './api-graphboards.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class GraphBoardsAnalysisService extends ContentItem {
    private graphBoard;

    readonly persistentVariables = [
        'graphBoard'
    ];

    constructor(
        private apiGraphBoardsService: ApiGraphboardsService,
        private translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('graphBoardService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setItemToDisplayWithId(id, specificState = 'graphboard', action = null) {
        let obsResult = {};
        let graphBoardObservable = this.apiGraphBoardsService.graphboard([id]).pipe(take(1)).subscribe(
            res => {
                if (res) {
                    this.graphBoard = res;
                    this.storeServiceData('graphBoardService', this.persistentVariables, this);
                    if (this.isRightModule(specificState)) {
                        obsResult = this.resetSidebarToReport(specificState);
                    } else {
                        this.stateService[specificState].sideBarStatus = 'recentActivities';
                    }
                    obsResult['itemToDisplayId'] = res.id;
                    obsResult['action'] = action;
                    this.stateService[specificState].itemToDisplayId = res.id;
                    this.stateService[specificState].stateChanged.next(obsResult);
                    graphBoardObservable.unsubscribe();
                    if (action === 'mobileView') {
                        this.router.navigate(['m/analysis/graphboard/report/' + res.id]).then(() => {});
                    }
                }
            });
    }

    isRightModule(specificState) {
        if (specificState === 'graphboard' && !this.archive) {
            return true;
        }
        return !!(specificState === 'archivesGraphboard' && this.archive);
    }

    resetItemToDisplay(specificState = 'graphboard') {
        this.itemToDisplay = {};
        this.stateService[specificState].itemToDisplayId = undefined;
        this.stateService[specificState].stateChanged.next({
            'itemDeleted': true
        });
        if (this.isMobile) {
            this.router.navigate(['m/graphboard/list']).then(() => {});
        } else {
            this.stateService[specificState].sideBarStatus = 'recentActivities';
        }
    }

    isEmptyAlignment() {
        let isNotEmpty = false;
        if (this.alignment) {
            this.alignment.forEach((col) => {
                if (col.length > 0) {
                    isNotEmpty = true;
                }
            });
        }
        return !isNotEmpty;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get itemToDisplay(): any {
        return _.get(this, 'graphBoard');
    }

    set itemToDisplay(item) {
        this.graphBoard = item;
        this.storeServiceData('graphBoardService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'graphBoard.id');
    }

    get name(): string {
        return _.get(this, 'graphBoard.name');
    }

    get date() {
        return _.get(this, 'graphBoard.date');
    }

    get creationDate() {
        return _.get(this, 'graphBoard.creationDate');
    }

    get description() {
        return _.get(this, 'graphBoard.description');
    }

    get subAccount() {
        return _.get(this, 'graphBoard.subAccount');
    }

    get subAccountName() {
        return _.get(this, 'graphBoard.subAccountName');
    }

    get alignment() {
        return _.get(this, 'graphBoard.alignment');
    }

    get records() {
        return _.get(this, 'graphBoard.records');
    }

    get department(): any {
        let department = _.get(this, 'graphBoard.department');
        if (department !== undefined && department.length > 0) {
            let currentLang = this.translateService.currentLang;
            if (currentLang === '' || currentLang === undefined) {
                currentLang = 'fr';
            }
            department = department[0][currentLang];
            if (!department && this.departmentName && this.departmentName.hasOwnProperty(currentLang)) {
                return this.departmentName[currentLang];
            }
        }
        return department;
    }

    get departmentName() {
        return _.get(this, 'graphBoard.departmentName');
    }

    get archive() {
        return _.get(this, 'graphBoard.archive');
    }
}
