// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainTitleRmSb {
  margin-top: 15px;
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 22px; /** h1 usual is 28px */
}

.noScroll {
  overflow: hidden;
}

.buttonTitle {
  padding-right: 10px;
  margin-top: 2px;
}

.titleRight {
  padding-left: 10px;
  margin-top: 2px;
  color: #444;
}

.smallTxt {
  font-size: 93%;
}

.first, .second, .third {
  width: 33.3333333333%;
  float: left;
}

.first {
  padding: 0px 5px 5px 0px;
}

.second {
  padding: 0px 5px 5px 5px;
}

.third {
  padding: 0px 0px 5px 5px;
}

.cardSpacer {
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/right-matches/components/web/right-matches-report/right-matches-report.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,6CAAA;EACA,eAAA,EAAA,sBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,eAAA;AADJ;;AAIA;EACI,kBAAA;EACA,eAAA;EACA,WAAA;AADJ;;AAIA;EACI,cAAA;AADJ;;AAGA;EACI,qBAAA;EACA,WAAA;AAAJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":["\n\n.mainTitleRmSb {\n    margin-top: 15px;\n    font-family: 'MontserratSemiBold',  sans-serif;\n    font-size: 22px; /** h1 usual is 28px */\n}\n\n.noScroll {\n    overflow: hidden;\n}\n\n.buttonTitle {\n    padding-right: 10px;\n    margin-top: 2px;\n}\n\n.titleRight {\n    padding-left: 10px;\n    margin-top: 2px;\n    color: #444;\n}\n\n.smallTxt {\n    font-size: 93%;\n}\n.first, .second, .third {\n    width: 33.3333333333%;\n    float: left;\n}\n.first {\n    padding: 0px 5px 5px 0px;\n}\n\n.second {\n    padding: 0px 5px 5px 5px;\n}\n\n.third {\n    padding: 0px 0px 5px 5px;\n}\n\n.cardSpacer {\n    height: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
