import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {StateService} from '../../../../../core/services/state/state.service';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-right-matches-person-adding',
  templateUrl: './mobile-right-matches-person-adding.component.html',
  styleUrls: ['./mobile-right-matches-person-adding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileRightMatchesPersonAddingComponent implements OnInit, OnDestroy {
    @Input() multiAction;
    @Input() sectionTitle;

    @Output() closePanel = new EventEmitter();

    searchTerm: string;
    records: Array<any>;
    selectedItems: Array<string>;
    rightMatchRecords: Array<string>;

    actual = '';

    public loading = false;

    chooseRightMatch: boolean;
    headerText: string;
    rmList: Array<any> = [];

    recordsLoaded = 100;
    recordsLength: number;

    private subscriptions = new Subscription();

    constructor(
        public rightMatch: RightMatchAnalysisService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private stateService: StateService,
        private apiPeopleService: ApiPeopleService,
        private apiRightMatches: ApiRightMatchesService
    ) {
    }

    ngOnInit() {
        this.fetchRecords();
        this.checkRecords();
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(null);
    }

    checkRecords() {
        this.rightMatchRecords = [];
        this.selectedItems = [];
        if (this.multiAction && this.multiAction.ids.length > 0) { // on assume que s'il y a une multiAction, il n'y a pas
            // d'itemToDisplay...
            this.rightMatch.itemToDisplay = {
                id: null,
                records: []
            };
            this.rightMatchRecords = this.multiAction.ids;

            this.chooseRightMatch = true;
            this.headerText = 'Les éléments choisis sont sélectionnés ci-dessous';

            this.loading = true;
            this.subscriptions.add(this.apiRightMatches.rightMatches([{limit: 100}]).subscribe(
                res => {
                    if (res) {
                        for (let rm of res.data) {
                            this.rmList.push({
                                id: rm.id,
                                name: rm.name
                            });
                        }

                        this.loading = false;
                    }
                }
            ));

        }

        if (this.rightMatch.records) {
            this.rightMatchRecords = this.rightMatchRecords.concat(this.rightMatch.records.map(record => record.id));
            // in case of the string array instead of the objects array
            if (this.rightMatchRecords.length && !this.rightMatchRecords[0]) {
                this.rightMatchRecords = this.rightMatch.records;
            }
            this.selectedItems = this.rightMatchRecords;
        } else {
            this.rightMatchRecords = [];
            this.selectedItems = [];
        }
    }

    closeP() {
        this.closePanel.emit(true);
    }

    onChecked(event, id: string) {
        if (event.checked) {
            this.selectedItems.push(id);
        } else {
            this.selectedItems.splice(
                this.selectedItems.indexOf(id),
                1
            );
        }
    }

    onSubmit() {
        if (this.selectedItems.length > 0) {
            const data = {'records': this.selectedItems};
            this.subscriptions.add(this.apiRightMatches.putRightMatch([this.rightMatch.id], data).subscribe(
                () => {
                    this.rightMatch.setItemToDisplayWithId(this.rightMatch.id);
                    this.stateService.rightMatch.stateChanged.next({
                        refreshComponent: true,
                        itemToDisplayId: this.rightMatch.id
                    });
                    this.closeP();
                }
            ));
        }
    }

    fetchRecords(searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        this.subscriptions.add(this.apiPeopleService.records(
            [{
                'search': searchTerm,
                'skip': skip,
                'limit': this.recordsLoaded,
                'isArchived': 0,
                'reportStatuses': {mpo: 1}
            }]
        ).subscribe(
            (res) => {
                // Get records loaded (add the new records from API)
                this.recordsLoaded = (skip) ?
                    this.recordsLoaded + res.data.length :
                    this.recordsLoaded;

                // Records data (merge new records from API)
                this.records = (concat) ?
                    [...this.records, ...res.data] :
                    res.data;

                // Total records
                this.recordsLength = res.count;

                // Reload component
                this.changeDetectorRef.markForCheck();

                this.loading = false;
            }
        ));
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {

            return;
        }
        this.fetchRecords(this.searchTerm, this.recordsLoaded, true);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
