import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PeopleSurveyRequestFormService} from '../../../../services/people-survey-request-form.service';
import { PeopleSurveyRequestStep } from '../people-survey-request-step.enum';
import { PeopleSurveyRequestHelper } from '../people-survey-request.helper';
import { ApiPeopleService} from '../../../../services/api-people.service';
import { SharedErrorHelper } from 'src/app/shared/helpers/error.helper';

@Component({
    selector: 'app-people-survey-request-form',
    templateUrl: './request-form.component.html',
    styleUrls: ['./request-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestFormComponent implements OnInit, OnDestroy {
    @Input() surveyRequestFormGroup: UntypedFormGroup;
    @Input() sessionData: any;
    @Input() isFormSubmitted: boolean;
    @Input() researchAccount: any;

    @Output() cancelRequestForm = new EventEmitter();
    @Output() resetRequestForm = new EventEmitter();
    @Output() submitRequestForm = new EventEmitter();
    currentLanguage: string;

    private onDestroy$: Subject<null> = new Subject();

    constructor (
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private translateService: TranslateService,
        private apiPeopleService: ApiPeopleService
    ) {}

    ngOnInit() {
        if (this.sessionData.userData.language) {
            this.peopleRequestFormService.setPreferredLanguage(this.sessionData.userData.language);
        }
        this.currentLanguage = this.translateService.currentLang;

        const options = {
            accountId: this.sessionData.accountData.id,
            subAccounts: this.sessionData.structure.subAccounts,
            languages: this.sessionData.structure.languages[this.translateService.currentLang],
            canCreateDitWithoutMpo: PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.sessionData.accountType)
        };

        // On form values change
        this.peopleRequestFormService.onValuesChange(this.surveyRequestFormGroup, this.onDestroy$, options);

        // Update default sub account
        if (this.sessionData.userData.hasOwnProperty('principalSubAccount')) {
            this.surveyRequestFormGroup.get('subAccount').setValue(`${this.sessionData.userData.principalSubAccount}`);
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * On cancel button click
     */
    public handleCancelRequestForm(): void {
        this.cancelRequestForm.emit();
    }

    /**
     * On reset button click
     */
    public handleResetRequestForm(): void {
        this.resetRequestForm.emit();
    }

    /**
     * Get requester data
     * Used to identify the requester
     *
     * @param formattedValues - formatted form values
     * @param sessionData - session data
     */
    private getExtraData(formattedValues: any, sessionData: any): any {
        const requesterName = formattedValues.requestedByName || sessionData.userData.fullName;
        const requesterCompany = sessionData.structure.subAccounts[formattedValues.subAccount] ? sessionData.structure.subAccounts[formattedValues.subAccount].name : '';
        const hasIac = PeopleSurveyRequestHelper.hasIac(this.surveyRequestFormGroup.get('reportGroup').value);
        const requester = this.surveyRequestFormGroup.get('requester').value;

        return {
            requesterData: {
                name: requesterName,
                company: requesterCompany
            },
            selectedRequester: requester,
            hasIac: hasIac
        };
    }

    /**
     * On submit button click
     */
    public sumbitSurveyRequestFormValues(): void {
        this.peopleRequestFormService.updateRequestFormSubmission(true);

        // Process if form is valid
        if (this.surveyRequestFormGroup.valid) {
            const isSatelliteOnly = PeopleSurveyRequestHelper.isOnlySatelliteSelected(this.surveyRequestFormGroup.get('reportGroup').value);
            const mergedValues = {...this.peopleRequestFormService.getPrbOnlyFormValues(), ...this.surveyRequestFormGroup.value};
            const formattedValues = PeopleSurveyRequestHelper.formatRequestValues(mergedValues, this.researchAccount);

            const { language, country } = this.splitLocale(formattedValues.locale);
            formattedValues.language = language;
            formattedValues.country = country;

            this.peopleRequestFormService.updateApiErrorMessage('');
            // Send the form data to the API
            this.apiPeopleService.evaluationRequest(formattedValues).pipe(takeUntil(this.onDestroy$)).subscribe(
                (tempUser: any) => {
                    // Add info for the temp user
                    const additionalUserData = {
                        variant: formattedValues.variant,
                        country: formattedValues.country,
                        language: formattedValues.language,
                        locale: formattedValues.locale,
                        isSatelliteOnly: isSatelliteOnly,
                        fullName: `${formattedValues.firstName} ${formattedValues.lastName}`
                    };
                    this.peopleRequestFormService.updateTempUser({...tempUser, ...additionalUserData});

                    // Add requester data
                    this.peopleRequestFormService.updateExtraData(this.getExtraData(formattedValues, this.sessionData));

                    // Request step
                    this.peopleRequestFormService.updateRequestStep(PeopleSurveyRequestStep.SendMethods);
                    this.submitRequestForm.emit();
                },
                (error: any) => {
                    const apiMessageError = `errors.${SharedErrorHelper.getErrorId(error.error.message)}`;
                    this.peopleRequestFormService.updateApiErrorMessage(apiMessageError);
                }
            );
        }
    }

    private splitLocale(locale: string): { language: string, country: string } {
        const [language, country] = locale.split('_');
        return { language, country };
    }
}
