import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {StateService} from '../../../../../core/services/state/state.service';
import {MainListIdwMatricesService} from '../../../services/main-list-idw-matrices.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {TranslateService} from '@ngx-translate/core';
import {ComponentList} from '../../../../../shared/classes/components/component_list';
import {DeviceDetectorService} from 'ngx-device-detector';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {IdwMatrixListService} from '../../../services/idw-matrix-list.service';

@Component({
  selector: 'app-mobile-idw-matrix-list',
  templateUrl: './mobile-idw-matrix-list.component.html',
  styleUrls: ['./mobile-idw-matrix-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileIdwMatrixListComponent extends ComponentList implements OnInit, OnDestroy {

    analysisTabs = [];
    selectedTab = 1;
    addAnalysis = false;

    public orderedBy;

    isSelected = 'none';
    lastSearch: string;

    private subscriptions = new Subscription();

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public idwMatrix: IdwMatrixAnalysisService,
        public idwMatricesList: IdwMatrixListService,
        private mainListIdwMatrices: MainListIdwMatricesService,
        public stateService: StateService,
        private apiIdwMatrices: ApiIdwMatricesService,
        private changeDetectorRef: ChangeDetectorRef,
        protected translateService: TranslateService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('rightmatch');
        }
        if (this.stateService.hasCommunication) {
            this.analysisTabs.push('idwmatrix');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('graphboard');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('jobsatisfaction');
        }
        this.orderedBy = this.idwMatricesList.idwMatrixState.listColumns.filter(col => col.order !== undefined);

        if (!this.stateService.hasCommunication) {
            if (this.stateService.hasPersonality) {
                this.router.navigate(['/m/analysis/rightmatch/list']);
            } else {
                this.router.navigate(['/m/people/list']);
            }
        }
    }

    ngOnInit() {
        if (!this.idwMatricesList.archive) {
            this.setLastAddress();
        }
        this.lastSearch = this.idwMatricesList.idwMatrixState.listSearchFilters.search;
        this.idwMatricesList.multiAction = null;
        this.idwMatricesList.checkModuleUrl();
        this.idwMatricesList.checkUserIdwMatrixDisplayPreferences();
        this.idwMatricesList.resetFilters();

        // check items list without selection
        this.idwMatricesList.checkTotalItems();

        if (this.idwMatrix.id) {
            this.idwMatrix.setItemToDisplayWithId(this.idwMatrix.id, this.idwMatricesList.specificState);
        }

        this.idwMatricesList.idwMatrixState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.idwMatricesList.getIdwMatricesList( true, null, this);

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.idwMatricesList.getIdwMatricesList( true, null, this);
            }
        ));

        this.blurSearchElement();
    }

    createNewAnalysis() {
        this.addAnalysis = true;
    }

    backToItemsList() {
        this.router.navigate(['m/analysis/idwmatrix/list', {cancel: true, moduleName: 'analysis'}]).then(() => {
            this.addAnalysis = false;
        });
    }

    /**
     * Set search parameter on event from shared/main-list/component/mobile/mobile-main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.lastSearch = event;
        this.idwMatricesList.setSearchFilter(event);
        this.refreshList();
        this.blurSearchElement();
    }

    /**
     * Remove focus from the search input
     */
    blurSearchElement() {
        let activeElement = document.activeElement as HTMLElement;
        activeElement.blur();

        // check the case when the button Search/Clear was clicked
        setTimeout(() => {
            if (document.activeElement.tagName === 'INPUT') {
                activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
            }
        }, 0 );
    }

    switchTab(index) {
        const tabName = this.analysisTabs.find((e, i) => {
            if (i === index) {
                return e;
            } else {
                return;
            }
        });
        this.router.navigate(['/m/analysis/' + tabName + '/list']).then(() => {
            this.selectedTab = index;
        });
    }

    refreshList() {
        this.idwMatricesList.getIdwMatricesList( true, null, this);
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    setOrder(event) {
        this.idwMatricesList.setOrder(event);
        this.refreshList();
    }

    /**
     * Set selected item on event from shared/main-list/component/main-list
     * @param item
     */
    itemSelected(item): void {
        this.closed = false;
        this.idwMatricesList.idwMatrixState.lastSideBarStatus = 'reports';
        this.idwMatricesList.itemSelected(item);

        setTimeout(() => {
            this.router.navigate(['/m/analysis/idwmatrix/report/' + item[0].id]).then(() => {});
        }, 200);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
