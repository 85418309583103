import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ProductsListNames } from '@admin/assets/productsListNames';
import { StateService } from '@core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-administration-common-list',
    templateUrl: './administration-common-list.component.html',
    styleUrls: ['./administration-common-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationCommonListComponent implements OnInit, OnChanges {

    @Input() createItemLabel;
    @Input() displaySearch = false;
    @Input() list;
    @Input() listTitle;
    @Input() listTitles;
    @Input() listMetaData;
    @Input() listType: string;

    sessionPermissions;

    productTitles;
    originalList: any;

    actualPage = 1;
    maxPerPage = 10;
    /** mod this */
    maxPage: number;
    numberOfLastPageReached = 0;

    value;

    @Output() browse = new EventEmitter();
    @Output() createItem = new EventEmitter();
    @Output() selectItem = new EventEmitter();
    @Output() deleteFile = new EventEmitter();
    @Output() onLastPage = new EventEmitter();

    constructor(
        protected translate: TranslateService,
        protected route: ActivatedRoute,
        protected stateService: StateService
    ) {
        this.productTitles = new ProductsListNames().titles;
    }

    ngOnInit() {
        this.sessionPermissions = this.stateService.session.sessionData.permissions;
    }

    ngOnChanges() {
        if (!this.list) return;

        this.maxPage = Math.ceil(this.list.length / this.maxPerPage);

        if (!this.originalList) {
            this.originalList = this.list;
        }
    }

    /**
     * Removes falsy items from the 'items' property of the provided list.
     *
     * @param {*} list The list to be checked
     * @return {*} The list with falsy items removed from its 'items' property
     * @memberof AdministrationCommonListComponent
     */
    checkedList(list: any) {
        if (list?.items) {
            list.items = list.items.filter(item => item);
        }
        return list;
    }

    onCreateItem() {
        this.createItem.emit();
    }

    onSelectItem(item: any) {
        if (!item) return;
        this.selectItem.emit(item);
    }

    deteleteFile(item: any) {
        this.deleteFile.emit(item);
    }

    listTitleMapping = {
        'Purchases': 'purchases',
        'Achats': 'purchases',
        'Utilisateurs': 'users',
        'Users': 'users',
        'Départements': 'department',
        'Departments': 'department',
        'Files': 'files',
        'Fichiers': 'files',
        'Sous-comptes': 'subAccounts',
        'Sub-accounts': 'subAccounts'
    }

    thisList() {
        return this.listTitleMapping[this.listTitle];
    }

    hasAnyRole(roles: string[]): boolean {
        return roles.some(role => this.sessionPermissions.roles.includes(role));
    }

    /**
     * Determines if the current user has the necessary role to create a new item based on the current list type.
     *
     * @return {*} A boolean indicating whether the user has the necessary role
     * @memberof AdministrationCommonListComponent
     */
    doCreate() {
        const list = this.thisList();
        const isAdmin = this.hasAnyRole(['ROLE_SUPER_ADMIN', 'ROLE_MAIN_ADMIN', 'ROLE_ADMIN']);

        switch (list) {
            case 'purchases':
                const urlPath = this.route.snapshot.url[2]?.path;
                if (urlPath && parseInt(urlPath) !== 0) {
                    return this.hasAnyRole(['ROLE_SUPER_ADMIN']);
                }
                return false;
            case 'subAccounts':
            case 'department':
                return isAdmin;
            default:
                return this.hasAnyRole(['ROLE_SUPER_ADMIN']);
        }
    }

    get isSuperAdmin() {
        return this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN');
    }

    /**
     * Creates an array representing a range of indices for the given page number.
     *
     * @param {*} actualPage The current page number
     * @return {*} An array of indices for the items on the current page
     * @memberof AdministrationCommonListComponent
     */
    createRange(actualPage) {
        let maxPerPage = this.maxPerPage;
        let start = (actualPage - 1) * maxPerPage;

        return Array.from({ length: maxPerPage }, (_, i) => start + i);
    }

    /**
     * Converts a quantity value to a localized string representation.
     *
     * @param {*} value The quantity value to convert
     * @return {*} A localized string representing 'unlimited' if the value is 9999 or 'unlimited', otherwise the original value
     * @memberof AdministrationCommonListComponent
     */
    quantity(value) {
        return (value === 9999 || value === 'unlimited')
            ? (this.translate.currentLang === 'fr' ? 'Illimité' : 'Unlimited')
            : value;
    }

    /**
     * Formats the product title based on the current language and SKU.
     *
     * @param {*} sku The SKU of the product
     * @return {*} A string representing the product title in the current language followed by the SKU in parentheses, or the SKU if the title is not available
     * @memberof AdministrationCommonListComponent
     */
    formatProductTitles(sku) {
        return this.productTitles[sku] && this.productTitles[sku][this.translate.currentLang]
            ? `${this.productTitles[sku][this.translate.currentLang]} (${sku})`
            : sku;
    }

    /**
     * Determines the style properties for a cell based on the list type and column index.
     *
     * @param {*} listType The type of the list ('history' or 'purchases')
     * @param {*} columnIndex The index of the column
     * @return {*} An object containing the style properties for the cell
     * @memberof AdministrationCommonListComponent
     */
    cellProperties(listType, columnIndex) {
        let styleObject = {
            width: '',
            vertical_align: '',
            text_align: ''
        };
        switch (listType) {
            case 'history':
                switch (columnIndex) {
                    case 1:
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            case 'purchases':
                switch (columnIndex) {
                    case 1:
                    case 2:
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            default:
                return styleObject;
        }
    }

    /**
     * Navigates to a new page based on the provided factor and emits an event if the last page is reached.
     *
     * @param {*} factor The number to be added to the current page to determine the new page
     * @memberof AdministrationCommonListComponent
     */
    goToPage(factor) {
        this.actualPage += factor;

        if (this.actualPage === this.maxPage) {
            this.numberOfLastPageReached++;
            this.onLastPage.emit({ type: this.listType, numberOfLastPageReached: this.numberOfLastPageReached });
        }
    }
}
