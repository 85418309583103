import {Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {takeUntil} from 'rxjs/operators';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';

@Component({
    selector: 'app-people-report-personality',
    templateUrl: './people-report-personality.component.html',
    styleUrls: ['./people-report-personality.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleReportPersonalityComponent implements OnInit, OnDestroy {
    @Input() mobile = false;
    @Input() width = 675;

    traits: Array<string> = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];

    textFields: any = {
        fr: {
            OR: {summary: 'Originalité de pensée'},
            SE: {summary: 'Spontanéité émotionnelle'},
            A: {summary: 'Assertivité', predictableBehavior: 'assertivité'},
            E: {summary: 'Extraversion', predictableBehavior: 'extraversion'},
            P: {summary: 'Pondération', predictableBehavior: 'pondération'},
            S: {summary: 'Structure', predictableBehavior: 'structure'},
            AD: {summary: 'Adaptabilité'}
        },
        en: {
            OR: {summary: 'Originality of thought'},
            SE: {summary: 'Emotional spontaneity'},
            A: {summary: 'Assertiveness', predictableBehavior: 'assertiveness'},
            E: {summary: 'Extroversion', predictableBehavior: 'extroversion'},
            P: {summary: 'Pace', predictableBehavior: 'pace'},
            S: {summary: 'Structure', predictableBehavior: 'structure'},
            AD: {summary: 'Adaptability'}
        }
    };
    report: any;
    titleSep: string;
    dateFormat: string;

    onDestroy$: Subject<null> = new Subject();

    private subscriptions = new Subscription();

    constructor(
        private changeDetectorRef: ChangeDetectorRef, // used in peopleReportService even if flagged otherwise
        private stateService: StateService,
        public translate: TranslateService,
        public testAndReportHandlingService: TestAndReportHandlingService,
        public person: PersonService
    ) {
        // PRB Index
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            () => {
                this.getPersonalityReport();
            }
        ));
    }

    ngOnInit() {
        this.getPersonalityReport();
        this.stateService.people.stateChanged$.subscribe(
            (res) => {

                if (res && (res.newPrbIndex || res.personId)) {
                    this.getPersonalityReport();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

    getPersonalityReport() {
        this.subscriptions.add(this.testAndReportHandlingService.getReport(
            'personality',
            this.translate.currentLang,
            this.state.prbIndex
        ).subscribe(
            report => {
                this.report = report;
                this.changeDetectorRef.markForCheck();
            },
            err => console.log(err)
        ));
    }

    moveTo(fragment) {
        const element = document.querySelector('#' + fragment);
        if (element) {
            element.scrollIntoView(true);
        }
        return;
    }

    get warnings(): any {
        if (
            this.report.info.evaluationWarnings.extremeScores ||
            this.report.info.evaluationWarnings.socialDesirability
        ) {
            return this.report.info.evaluationWarnings;
        }
        return false;
    }

    get state(): any {
        return this.stateService.people;
    }

    get traitsSummary(): any {
        if (this.report) {
            return this.report.traitsSummary;
        }

        return false;
    }

    get extraPoints(): any {
        if (this.report) {
            return this.report.extraPoints;
        }
    }

    get predictableBehavior(): any {
        if (this.report) {
            return this.report.predictableBehavior;
        }

        return false;
    }

    get profile(): any {
        if (this.report) {
            return this.report.profile;
        }
        return false;
    }

    get currentPrbReport(): any {
        let report: any = false;
        if (this.report && this.testAndReportHandlingService.currentPrb.exists) {
            report = this.report.prb;
        }
        return report;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
