import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {JobService} from '../../../jobs/services/job.service';
import * as d3 from 'd3';

@Component({
    selector: 'app-iac-graph',
    templateUrl: './iac-graph.component.html',
    styleUrls: ['./iac-graph.component.scss']
})
export class IacGraphComponent implements OnChanges {

    @ViewChild('IacGraph', {static: true}) private chartContainer: ElementRef;
    @Input() mobile = false;
    @Input() private normalizedScore: any;
    @Input() private jobScore: any = null;
    @Input() id;
    @Input() newJob = false;

    sizeFactor = 1;

    constructor(public jobService: JobService) {
    }

    ngOnChanges(changes) {
        if (this.jobScore === null && !this.newJob) {
            this.jobScore = undefined;
        } else if (!this.jobScore && !this.newJob) {
            this.jobScore = this.jobService.raScore;
        }
        if (changes && changes.id && changes.id.previousValue) {
            d3.select('#iacMainGraph' + changes.id.previousValue).remove();
        }
        this.createIacGraph();
    }

    createIacGraph() {

        d3.select('#iacMainGraph' + this.id).remove();

        let element = this.chartContainer.nativeElement;

        let width = 980 * this.sizeFactor;
        let height = 40 * this.sizeFactor;
        let space = 10;
        let cy = 10 + (height - 10) / 2;
        let cRadius = 15.5;
        let fontSize = 19;
        let textY = height - 8;
        let jobRectY = 47;
        let jobRectHeight = 9;

        if (this.mobile) {
            width = window.innerWidth - 34;
            space = 7;
            cy = cy + 1;
            cRadius = 10.5;
            fontSize = 12;
            textY = height - 10;
            jobRectHeight = 5;
            jobRectY = 43;
        }

        let svg = d3.select(element).append('svg')
            .attr('id', 'iacMainGraph' + this.id)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('viewBox', '0 0 ' + width + ' ' + (height + 15));


        let rectWidth = (width - 8 * space) / 9;
        let halfWidth = rectWidth / 2;

        for (let i = 1; i < 10; i++) {
            let fillingColor = '30.0%,30.0%,30.0%';
            if (parseInt(this.normalizedScore, 10) === i) {
                fillingColor = '233,118,6';
            }
            let xPos = {
                rect: (i - 1) * (rectWidth + space),
                circ: (i - 1) * (rectWidth + space) + halfWidth
            };
            svg.append('rect')
                .attr('x', xPos['rect'])
                .attr('y', 12)
                .attr('width', rectWidth)
                .attr('height', height - 12)
                .attr('stroke', 'none')
                .style('fill', 'rgb(' + fillingColor + ')');


            svg.append('circle')
                .attr('cx', xPos['circ'])
                .attr('cy', cy)
                .attr('r', cRadius).attr('stroke', 'rgb(' + fillingColor + ')')
                .attr('stroke-width', 7)
                .style('fill', 'white');


            svg.append('text')
                .attr('x', xPos['rect'] + halfWidth)
                .attr('y', textY)
                .attr('text-anchor', 'middle')
                .text(i)
                .attr('r', cRadius).attr('stroke', 'rgb(45.0%,45.0%,45.0%)')
                .attr('font-size', fontSize);

            if (this.jobScore !== null && parseInt(this.jobScore, 10) === i) {
                svg.append('rect')
                    .attr('x', xPos['rect'])
                    .attr('y', jobRectY)
                    .attr('width', rectWidth)
                    .attr('height', jobRectHeight)
                    .attr('stroke', 'none')
                    .style('fill', 'rgb(119,156,197)');
            }
        }
    }

}
