import * as d3 from 'd3';

export abstract class JobSatisfactionsGraphs {
    color = ['rgb(189, 188, 184)', 'rgb(240,123,126)', 'rgb(238,207,123)', 'rgb(119,198,175)'];
    colorObject = {
        red: this.color[1],
        yellow: this.color[2],
        green: this.color[3],
        grey: this.color[0]
    };

    cleanPrbs(prb) {
        let prbClean = [];
        for (let i = 0; i < prb.length; i++) {
            if (prb[i] !== null) {
                prbClean.push({
                    date: new Date(prb[i].date),
                    jobSatisfactionScore: prb[i].jobSatisfactionScore
                });
            }
        }
        return prbClean;
    }

    getFirstDate(firstDate: string, as = 'date'): any {
        let firstMonth = (Number(firstDate.split('-')[1]) - 1);
        let firstDay = 1;
        let firstYear = Number(firstDate.split('-')[0]);
        if (as === 'date') {
            return new Date(firstYear, firstMonth - 1, firstDay);
        } else if (as === 'string') {
            return firstYear + '-' + this.addTrailingZero(firstMonth) + '-' + this.addTrailingZero(firstDay);
        }
    }

    getLastDate(lastDate: string, as = 'date'): any {
        let lastMonth = (Number(lastDate.split('-')[1]) + 1);
        let lastDay = 1;
        let lastYear = Number(lastDate.split('-')[0]);
        if (as === 'date') {
            return new Date(lastYear, lastMonth - 1, lastDay);
        } else if (as === 'string') {
            return lastYear + '-' + this.addTrailingZero(lastMonth) + '-' + this.addTrailingZero(lastDay);
        }
        return new Date(lastYear, lastMonth - 1, lastDay);
    }

    addTrailingZero(number) {
        if (number < 10) {
            return '0' + number;
        }
    }

    writeGridEs(svg, width, height, x, y, isMobile = false) {

        // @ts-ignore
        svg.selectAll('line.verticalGrid').data(x.ticks(d3.timeMonth)).enter()
            .append('line')
            .attr('id', function (d, index) {
                return 'horizontalGrid_x_' + index;
            })
            .attr('class', 'horizontalGrid')
            .attr('class', 'verticalGrid')
            .attr('x1', function (d) {
                return x(d);
            })
            .attr('x2', function (d) {
                return x(d);
            })
            .attr('y1', 0)
            .attr('y2', height)
            .attr('fill', 'none')
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', '#ddd')
            .attr('stroke-width', '1px');


        // svg.select('#horizontalGrid_x_0').remove();

        svg.append('g')
            .attr('class', 'axis xAxis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x)
                    // .scale(xLabels)
                    .ticks(d3.timeMonth, 1)
                    .tickSize(1)
                //    .tickFormat(quarter)
                 .tickFormat(
                     function(date) {
                         // console.log(date);
                         // @ts-ignore
                         if (d3.timeYear(date) < date) {
                             // @ts-ignore
                             let localName = (this.translate.currentLang === 'fr') ? 'fr-CA' : 'en-US';
                             d3.json('https://cdn.jsdelivr.net/npm/d3-time-format@3/locale/' + localName + '.json').then((locale) => {
                                 // @ts-ignore
                                 d3.timeFormatDefaultLocale(locale);
                             });
                             // @ts-ignore
                             return d3.timeFormat('%B')(date);
                         } else {
                             // @ts-ignore
                             return d3.timeFormat('%B %Y')(date);
                         }
                     }
                     // d3.timeFormat('%b'))
            ));
        /**
         * x1 = 0
         * x2 = 638.5
         * y1 et y2 = 90
         */
        svg.append('rect')
            .attr('x', 0)
            .attr('y', 89)
            .attr('width', 641)
            .attr('height', 5)
            .attr('stroke', 'none')
            .style('fill', 'white');

        svg.selectAll('line.horizontalGrid').data(y.ticks(4)).enter()
            .append('line')
            .attr('id', function (d) {
                return 'horizontalGrid_y_' + d;
            })
            .attr('class', 'horizontalGrid')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', function (d) {
                return y(d);
            })
            .attr('y2', function (d) {
                return y(d);
            })
            .attr('fill', 'none')
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', function(d, index) {
                return (index === 0) ? '#000' : '#ddd';
            })
            .attr('stroke-width', '1px');



        d3.selectAll('.xAxis>.tick>text')
            .each(function(d, i) {
                d3.select(this).style('font-size', (isMobile) ? '15pt' : '8pt');
                d3.select(this).style('text-transform', 'uppercase');
            });

        return svg;
    }
}
