import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnChanges,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {StateService} from '../../../../core/services/state/state.service';
import {Job, JobDescriptionData, JobTask, Score} from '../../../models/job.model';
import {JobsCompetencesModalCommon} from '../../commonClasses/jobs-competences-modal-common';
import {JobsListService} from '../../../services/jobs-list.service';
import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {Tools} from '../../../../shared/misc/tools';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {ProfilerHelper} from '../../../helpers/profiler.helper';
import {environment} from 'src/environments/environment';
import * as _ from 'lodash';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-jobs-edit',
    templateUrl: './mobile-jobs-edit.component.html',
    styleUrls: ['./mobile-jobs-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileJobsEditComponent extends JobsCompetencesModalCommon implements OnInit, OnChanges, OnDestroy {

    @Input() status: string;
    @Input() reportType: string;
    @Output() close = new EventEmitter();

    @ViewChild('jobNgForm') jobNgForm;
    @ViewChild('jobFormContent') jobFormContent;

    apiBaseUrl;

    jobForm: UntypedFormGroup;
    jobFormInitValue: any;
    nextAction = 'none';

    jobObject: Job = new Job();
    requiredBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    afterNewItem: boolean;

    subAccountsOptions: any = [];

    selectedProfile = '';
    profileList;
    profileListGroups;
    scoreToDisplay: Score;
    scoreIndex = 0;
    lowScoreTalents = [];
    pProfileTouched = false;
    showProfileWarning = false;
    override = false;
    isSatellite = false;

    subMenu = [];

    RAScore: any;
    pressedKey: number;
    raTexts: any;

    submitted = false;

    editTabs: Array<string> = ['jobDescriptionPanel', 'personalityPanel', 'talentsPanel', 'iacPanel'];

    private subscriptions = new Subscription();

    constructor(
        public jobsList: JobsListService,
        public jobService: JobService,
        private apiJobsService: ApiJobsService,
        public stateService: StateService,
        private fb: UntypedFormBuilder,
        protected alertService: AlertService,
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
        private env: EnvService
    ) {
        super(translate);
        this.profileList = this.generateProfiles(this.translate.currentLang);
        this.profileListGroups = this.generateProfiles(this.translate.currentLang, true);
        this.apiBaseUrl = this.env.apiBaseUrl;
    }

    globalInit() {
        this.submitted = false;
        this.jobsList.jobsState['selectedTab'] = 0;
        this.jobObject = Object.assign({}, new Job());
        this.jobObject.reportsTo = this.requiredBy;
        this.jobObject.requiredBy = this.requiredBy;
    }

    initJobForm() {
        this.submitted = false;

        const departmentId = (this.jobObject.department && this.jobObject.department.length > 0)
            ? this.jobObject.department[0].id
            : '';

        const industryObj = (this.jobObject.industry)
            ? this.sessionStructure.profilerIndustry.find(item => item.name === this.jobObject.industry)
            : null;
        const industry = (industryObj) ? industryObj['value'] : null;

        const jobTypeObj = (this.jobObject.jobType)
            ? this.sessionStructure.profilerJobType.find(item => item.name === this.jobObject.jobType)
            : null;
        const jobType = (jobTypeObj) ? jobTypeObj['value'] : null;

        let subAccountValue: any = '';
        if (this.jobService.id && this.status !== 'new') {
            subAccountValue = this.jobService.subAccount;
        } else if (this.subAccounts && this.subAccounts[0]) {
            subAccountValue = this.subAccounts[0].id;
        } else if (this.sessionData && this.sessionData.userData &&
            this.sessionData.userData.preferences &&
            this.sessionData.userData.preferences.jobs &&
            this.sessionData.userData.preferences.jobs.search &&
            this.sessionData.userData.preferences.jobs.search.subAccount) {
            subAccountValue = this.sessionData.userData.preferences.jobs.search.subAccount;
        }
        subAccountValue = subAccountValue.toString();

        let currentScore = (this.mpoScores && this.status !== 'new') ?
            [this.mpoScores.A, this.mpoScores.E, this.mpoScores.P, this.mpoScores.S] :
            [];
        const profiles = TextHelper.profiles();
        for (let title of profiles.titles) {
            if (title) {
                for (let profile of profiles[title.key]) {
                    if (JSON.stringify(currentScore) === JSON.stringify(profile.score)) {
                        this.selectedProfile = profile.key;
                    }
                }
            }
        }

        // let sharedToSubAccounts = [];
        // if (this.jobService.sharedToSubAccounts && this.status !== 'new') {
        //     sharedToSubAccounts = this.jobService.sharedToSubAccounts;
        // }

        this.jobForm = this.fb.group({
            jobTitle: [this.jobObject.jobTitle, [Validators.required, Validators.minLength(2)]],
            subAccount: [+subAccountValue, [Validators.required]],
            // type: [this.jobObject.type, [Validators.required]],
            // language: [this.jobObject.language, [Validators.required]],
            location: [this.jobObject.location],
            requiredBy: [this.jobObject.requiredBy],
            reportsTo: [this.jobObject.reportsTo],
            // sharedToSubaccounts: [sharedToSubAccounts],
            purposeOfThePosition: [this.jobObject.jobDescription.purposeOfThePosition],
            tasks: this.fb.array(this.getTasks()),
            department: [departmentId],
            industry: [industry],
            jobType: [jobType],
            selectedProfile: [this.selectedProfile],
            OR: [this.scoreToDisplay.OR || null],
            SE: [this.scoreToDisplay.SE || null],
            A: [this.scoreToDisplay.A || null],
            E: [this.scoreToDisplay.E || null],
            P: [this.scoreToDisplay.P || null],
            S: [this.scoreToDisplay.S || null],
            isSatellite: this.isSatellite,
            RAScore: [this.RAScore]
        });

        this.jobFormInitValue = Object.assign({}, this.jobForm.value);
        this.selectedTalentsInit = [...this.selectedTalents];
    }

    // task related functions
    getTasksControls(jobForm) {
        if (jobForm) {
            return jobForm.get('tasks').controls;
        }
        return;

    }

    // Create array of FormGroup
    getTasks(): Array<UntypedFormGroup> {
        let tasks = [];

        // Init with api data
        if (this.jobObject.jobDescription.tasks && this.jobObject.jobDescription.tasks.length > 0) {
            for (let task of this.jobObject.jobDescription.tasks) {
                tasks.push(this.setTaskFormGroup(task));
            }
        } else {
            // Init default data
            for (let i = 0; i < 1; i++) {
                tasks.push(this.setTaskFormGroup(new JobTask()));
            }
        }

        return tasks;
    }

    setTaskFormGroup(task: JobTask): UntypedFormGroup {
        return this.fb.group({
            responsibility: [(task.responsibility) ? task.responsibility.toString() : ''],
            priority: [(task.priority) ? task.priority.toString() : ''],
            percentage: [(task.percentage) ? task.percentage.toString() : '']
        });
    }

    addTask() {
        const control = <UntypedFormArray>this.jobForm.controls['tasks'];
        control.push(this.setTaskFormGroup(new JobTask()));
    }

    removeTask(i: number) {
        const control = <UntypedFormArray>this.jobForm.controls['tasks'];
        control.removeAt(i);
    }

    resetSection(event, section) {
        event.stopPropagation();
        let elemToReset;
        let formControlName;
        switch (section) {
            case 'jobDescription':
                elemToReset = document.getElementsByClassName('jobDescriptionField');
                let taskIndex = 0;
                for (let i = 0; i < elemToReset.length; i++) {
                    formControlName = elemToReset[i].getAttribute('formcontrolname');
                    if (formControlName === 'responsibility' || formControlName === 'priority' || formControlName === 'percentage') {
                        if (this.jobFormInitValue.tasks[taskIndex]) {
                            elemToReset[i].value = this.jobFormInitValue.tasks[taskIndex][formControlName];
                            if (this.jobForm && this.jobForm.value && this.jobForm.value.tasks &&
                                this.jobForm.value.tasks[taskIndex] && this.jobForm.value.tasks[taskIndex][formControlName]) {
                                this.jobForm.value.tasks[taskIndex][formControlName] = this.jobFormInitValue.tasks[taskIndex][formControlName];
                            }
                        } else {
                            if (this.jobForm.value && this.jobForm.value.tasks &&
                                this.jobForm.value.tasks[taskIndex] && this.jobForm.value.tasks[taskIndex][formControlName]) {
                                this.jobForm.value.tasks[taskIndex][formControlName] = '';
                            }
                            elemToReset[i].value = '';
                        }
                        if (formControlName === 'percentage') {
                            taskIndex++;
                        }
                    } else {
                        elemToReset[i].value = this.jobFormInitValue[formControlName];
                        if (this.jobForm.value && this.jobForm.value[formControlName]) {
                            this.jobForm.value[formControlName] = this.jobFormInitValue[formControlName];
                            this.jobForm.get(formControlName).setValue(this.jobFormInitValue[formControlName]);
                        }
                    }
                }
                break;
            case 'personality':
                elemToReset = document.getElementsByClassName('personalityField');
                for (let i = 0; i < elemToReset.length; i++) {
                    formControlName = elemToReset[i].getAttribute('formcontrolname');
                    if (formControlName === 'isSatellite') {
                        let isSatChk = elemToReset[i].getElementsByClassName('mat-checkbox-input')[0];
                        isSatChk.checked = this.jobFormInitValue[formControlName];
                        this.jobForm.get(formControlName).setValue(null);
                        if (!isSatChk.checked) {
                            if (elemToReset[i].classList && elemToReset[i].classList.contains('mat-checkbox-checked')) {
                                elemToReset[i].classList.remove('mat-checkbox-checked');
                            }
                        }
                    } else {
                        this.jobForm.get(formControlName).setValue(null);
                        elemToReset[i].value = null;
                    }
                }
                this.checkCompetencies(null, true);
                break;
            case 'talents':
                this.selectedTalents = [];
                this.checkCompetencies(null, true);
                break;
            case 'iac':
                elemToReset = document.getElementById('RAScore');
                // formControlName = elemToReset.getAttribute('formcontrolname');
                elemToReset.value = null;
                this.RAScore = null;
                this.cd.markForCheck();
                break;
        }
    }

    resetEvaluationForm() {
        this.submitted = false;
        this.RAScore = '';
        this.selectedProfile = '';
        this.scoreToDisplay = undefined;
        this.jobForm.reset();
        this.jobNgForm.submitted = false;
        this.globalInit();
    }

    /**
     goToQuestionnaireDef(event) {
        event.stopPropagation();
        window.open('http://profiler-dev.ngenioconnect.com/');
    }*/

    onCancel() {
        let isJobFormChanged = !(_.isEqual(this.jobForm.value, this.jobFormInitValue));
        let isTalentListChanged = !(JSON.stringify(this.selectedTalents) === JSON.stringify(this.selectedTalentsInit));
        if (isJobFormChanged || isTalentListChanged) {
            const cancelWarning = {
                paragraph: this.translate.instant('jobs.modal.modWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(cancelWarning);
            this.subscriptions.add(this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.resetEvaluationForm();
                        this.close.emit(true);
                    }
                })
            );
        } else {
            this.resetEvaluationForm();
            this.close.emit(true);
        }
    }

    ngOnInit() {
        if (this.jobsList.jobsState.selectedTab) {
            this.focusTab(this.jobsList.jobsState.selectedTab);
        }
    }

    ngOnChanges() {
        this.requiredBy = (this.sessionData && this.sessionData.userData) ? this.sessionData.userData.fullName : null;
        this.sessionStructure = (this.sessionData) ? this.sessionData.structure : null;
        this.departments = (this.sessionStructure) ? this.sessionStructure.departments : null;

        if (this.status === 'new') {
            this.globalInit();
        }

        if (this.status === 'copy') {
            this.jobObject = Object.assign({}, this.jobService.jobToDisplay);
            this.jobObject.jobTitle = '[Copie] ' + this.jobObject.jobTitle;
        }

        if (this.status === 'edit') {
            this.afterNewItem = false;
            this.jobObject = Object.assign({}, this.jobService.jobToDisplay);
        }

        // subAccounts multiselect activation
        if (this.subAccounts) {
            for (let subAcct of this.subAccounts) {
                this.subAccountsOptions.push({id: subAcct.id, name: subAcct.name});
            }
        }

        this.selectedProfile = '';

        // Init purposeOfThePosition
        if (!this.jobObject.jobDescription) {
            this.jobObject.jobDescription = new JobDescriptionData();
        }

        // Score
        if (this.status !== 'new' && this.jobService.score && this.jobService.score.length > 0) {
            this.scoreToDisplay = this.numerizeScore(this.jobService.score[this.scoreIndex]);
        } else {
            this.scoreToDisplay = new Score();
        }
        this.override = false;

        // Competences
        for (let i = 0; i < 46; i++) {
            this.selectedTalents[i] = null;
            this.subMenu[i] = false;
        }
        this.profileDataLoad();

        if (this.status !== 'new') {
            if (this.mpoScores && this.mpoScores.satellite) {
                this.isSatellite = true;
            }
            if (this.talentsScores) {
                for (let competence in this.talentsScores) {
                    if (competence && this.talentsScores.hasOwnProperty(competence)) {
                        this.updateTalent(Number(competence), this.talentsScores[competence], null);
                    }
                }
            }
        }

        // CAI
        if (this.status !== 'new') {
            if (this.jobService.raScore) {
                this.RAScore = this.jobService.raScore.toString();
                this.cd.markForCheck();
            }
        } else {
            this.RAScore = '';
        }
        this.apiJobsService.jobRaTexts().subscribe(
            (res) => {
                if (res) {
                    this.raTexts = res['RAtexts'];
                }
                this.cd.markForCheck();
            }
        );

        this.initJobForm();

        this.subscriptions.add(this.translate.onLangChange.subscribe(
            (event) => {
                this.profileListGroups = this.generateProfiles(event.lang, true);
                this.profileList = this.generateProfiles(event.lang);
                this.profileDataLoad();
            }
        ));
    }

    useWithSatellite(event) {
        this.pProfileTouched = true;
        this.isSatellite = event.checked;
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (!this.jobNgForm.submitted) {
            this.jobNgForm.submitted = true;
        }

        // If form valid
        if (model.valid) {
            let jobData: Job = this.cleanFormValue(model.value);
            jobData.competences = this.checkTalentsPayload();


            jobData.raScore = (this.RAScore) ? this.RAScore : null;


            // Save a new item after edit
            if (this.status === 'edit' && !this.afterNewItem) {
                this.saveEditJob(jobData);
            } else {
                this.saveNewJob(jobData);
            }
        }
    }

    numerizeScore(scores): Score {
        return {
            'OR': +scores.OR,
            'SE': +scores.SE,
            'A': +scores.A,
            'E': +scores.E,
            'P': +scores.P,
            'S': +scores.S
        };
    }

    toggleMastering(i) {
        let currentVal = this.subMenu[i];
        for (let j = 0; j < 46; j++) {
            this.subMenu[j] = false;
        }
        this.subMenu[i] = !currentVal;
    }

    checkCompetencies(score = null, modScore = false) {
        this.lowScoreTalents = [];
        if (!score) {
            score = {
                A: this.jobForm.get('A').value,
                E: this.jobForm.get('E').value,
                P: this.jobForm.get('P').value,
                S: this.jobForm.get('S').value,
                OR: this.jobForm.get('OR').value,
                SE: this.jobForm.get('SE').value
            };
        }
        if (score && this.jobForm.get('A').value !== null) {
            this.subscriptions.add(this.apiJobsService.competenciesLowScore({score: score}).subscribe((res) => {
                if (res && res.lowerTierCompetencies) {
                    this.lowScoreTalents = res.lowerTierCompetencies;

                    if (modScore) {
                        this.showProfileWarning = false;

                        // check selected talents id
                        let talentIds = [];
                        if (this.selectedTalents) {
                            this.selectedTalents.forEach((tal, index) => {
                                if (tal === 1 || tal === 2) {
                                    talentIds.push(index);
                                }
                            });
                        }

                        // get selected talents numbers
                        let talentNumbers = [];
                        talentIds.forEach((talentId) => {
                            if (talentId && this.talentsData && this.talentsData[talentId]) {
                                if (this.talentsData.find(talent => talent.id === talentId)) {
                                    talentNumbers.push(this.talentsData.find(talent => talent.id === talentId)['number']);
                                }
                            }
                        });

                        if (this.lowScoreTalents) {
                            this.lowScoreTalents.forEach((lsTalent) => {
                                if (talentNumbers && talentNumbers.includes(lsTalent)) {
                                    this.showProfileWarning = true;
                                }
                            });
                        }
                    }
                }
                this.cd.markForCheck();
            }));
        }
    }

    isLowScoreTalent(number) {
        if (this.lowScoreTalents.length || this.status === 'new') {
            return this.lowScoreTalents.includes(number);
        } else {
            return this.jobService.isLowScoreTalent(number);
        }
    }

    handlePercentage(jobData: Job): Job {
        if (jobData && jobData.jobDescription && jobData.jobDescription.tasks) {
            for (let taskIndex in jobData.jobDescription.tasks) {
                if (taskIndex && jobData.jobDescription.tasks.hasOwnProperty(taskIndex) && jobData.jobDescription.tasks[taskIndex].percentage) {
                    jobData.jobDescription.tasks[taskIndex].percentage =
                        parseInt(jobData.jobDescription.tasks[taskIndex].percentage.toString().replace('%', ''), 10);
                }
            }
        }

        return jobData;
    }

    saveNewJob(jobData: Job) {
        jobData = this.handlePercentage(jobData);

        // call api to save the modification
        this.subscriptions.add(this.apiJobsService.postJob(jobData).subscribe(
            res => {
                this.close.emit({
                    id: res.id,
                    nextAction: this.nextAction
                });
                this.jobsList.getJobList(true);
                this.jobService.setItemToDisplayWithId(res.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: res.id});
            },
            error => {
                this.errorForm = error.error;
                this.cd.detectChanges();
            }
        ));
    }

    saveEditJob(jobData: Job) {
        jobData = this.handlePercentage(jobData);

        // call api to save the modification
        this.subscriptions.add(this.apiJobsService.putJob([this.jobService.id], jobData).subscribe(
            () => {
                this.jobService.setItemToDisplayWithId(this.jobService.id);
                this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id});
                this.close.emit(this.stateService.jobs.report.reportType);
            },
            error => {
                this.errorForm = error.error;
                this.cd.detectChanges();
            }
        ));
    }

    formCheck(specificField = null) {
        if (this.jobNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.jobForm.controls[specificField], this.jobNgForm)) {
                    this.topFunction();
                    return true;
                }
            } else {
                let fields = ['jobTitle', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.jobForm.controls[field], this.jobNgForm)) {
                        this.topFunction();
                        return true;
                    }
                }
            }
        }
        return false;
    }

    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For others
    }

    cleanScore(local = false) {
        let scores = {
            OR: this.jobForm.value.OR,
            SE: this.jobForm.value.SE,
            A: this.jobForm.value.A,
            E: this.jobForm.value.E,
            P: this.jobForm.value.P,
            S: this.jobForm.value.S
        };
        return ProfilerHelper.cleanScore(scores, local);
    }

    cleanFormValue(formValue: any): Job {
        let jobData: Job = new Job(formValue);
        // Init jobDescription (find better way?)
        jobData.jobDescription = new JobDescriptionData();

        if (formValue.purposeOfThePosition) {
            jobData.jobDescription.purposeOfThePosition = formValue.purposeOfThePosition;
            // delete formValue.purposeOfThePosition;
        }

        if (formValue && formValue.tasks) {
            let cleanTasks: Array<JobTask> = [];
            formValue.tasks.forEach(function (task, index) {
                if (task && task.responsibility) {
                    cleanTasks.push(formValue.tasks[index]);
                }
            });

            jobData.jobDescription.tasks = cleanTasks;
        }

        // Department
        jobData.department = (formValue.department) ? [formValue.department] : [];

        // Score
        if (formValue.hasOwnProperty('A') &&
            formValue.hasOwnProperty('E') &&
            formValue.hasOwnProperty('P') &&
            formValue.hasOwnProperty('S')) {
            if (!this.pProfileTouched) {
                this.jobForm.get('A').setValue(null);
                this.jobForm.get('E').setValue(null);
                this.jobForm.get('P').setValue(null);
                this.jobForm.get('S').setValue(null);
                this.jobForm.get('OR').setValue(null);
                this.jobForm.get('SE').setValue(null);
            }
            let scores = this.cleanScore(true);
            if (scores) {
                // Clear AD if exists
                if (scores.AD) {
                    delete scores.AD;
                }
                jobData.score = {
                    OR: scores['OR'],
                    SE: scores['SE'],
                    A: scores['A'],
                    E: scores['E'],
                    P: scores['P'],
                    S: scores['S'],
                    satellite: this.isSatellite
                };
            }
            delete jobData['A'];
            delete jobData['E'];
            delete jobData['P'];
            delete jobData['S'];
            delete jobData['OR'];
            delete jobData['SE'];
        }

        return jobData;
    }

    generateProfiles(lang, group = false) {
        const profiles = TextHelper.profiles();
        let dropdownGroups = [];
        let dropdownContent = [];
        for (let title of profiles.titles) {
            if (group) {
                dropdownContent = [];
            }
            for (let profile of profiles[title.key]) {
                if (profile) {
                    dropdownContent.push({
                        value: profile.key,
                        text: profile.name[lang],
                        score: profile.score
                    });
                }
            }
            dropdownGroups.push({
                value: title.key,
                text: title.name[lang],
                opt: dropdownContent
            });
        }
        if (group) {
            return dropdownGroups;
        }
        return dropdownContent;
    }

    onChangePersonalityProfile() {
        this.pProfileTouched = true;
        this.selectedProfile = '';
        this.jobForm.get('selectedProfile').setValue('');
        this.checkCompetencies(null, true);
    }

    loadValues() {
        this.pProfileTouched = true;
        let selectedProfile = this.jobForm.get('selectedProfile').value;
        if (selectedProfile !== 'none') {
            const profiles = TextHelper.profiles();
            let score = _.result(_.find(profiles.gens.concat(profiles.specs), function (obj) {
                return obj.key === selectedProfile;
            }), 'score');

            let scoreSE = (this.scoreToDisplay.SE) ? this.scoreToDisplay.SE : 0;
            let scoreOR = (this.scoreToDisplay.OR) ? this.scoreToDisplay.OR : 0;

            if (score) {
                this.jobForm.get('S').setValue(`${score[3]}`);
                this.jobForm.get('P').setValue(`${score[2]}`);
                this.jobForm.get('E').setValue(`${score[1]}`);
                this.jobForm.get('A').setValue(`${score[0]}`);
                this.jobForm.get('OR').setValue(`${scoreOR}`);
                this.jobForm.get('SE').setValue(`${scoreSE}`);
            } else {
                this.jobForm.get('A').setValue(null);
                this.jobForm.get('E').setValue(null);
                this.jobForm.get('P').setValue(null);
                this.jobForm.get('OR').setValue(null);
                this.jobForm.get('S').setValue(null);
                this.jobForm.get('SE').setValue(null);
            }
        }
        this.checkCompetencies(null, true);
    }

    updateTalent(talent, value, subMenuNumber) {
        let setValue = value;
        if (setValue === 0) {
            setValue = null;
        }
        this.selectedTalents[talent] = setValue;
        if (subMenuNumber !== null) {
            this.subMenu[subMenuNumber] = false;
        }
    }

    checkPressedKey(event) {
        this.pressedKey = event.keyCode;
    }

    refreshGraph() {
        this.RAScore = this.jobForm.get('RAScore').value;
        if (this.RAScore < 1 && this.RAScore !== '') {
            this.RAScore = 1;
            this.jobForm.get('RAScore').setValue(`${this.RAScore}`);
        }
        this.cd.markForCheck();
    }

    switchEditTabs(index: number) {
        this.editTabs.find((e, i) => {
            if (i === index) {
                this.jobsList.jobsState.selectedTab = index;
                if (index === 1 || index === 2) {
                    this.checkCompetencies(null, true);
                }
                this.focusTab(index);
                return e;
            } else {
                return;
            }
        });
    }

    focusTab(index) {
        // for a tab that's not the first one
        if (index > 0) {
            // we have to put the focus on a half-hidden tab to make it fully visible
            setTimeout(() => {
                let tabsGroup = document.getElementsByClassName('jobTabs')[0];
                if (tabsGroup) {
                    let activeTab = <HTMLElement>tabsGroup.getElementsByClassName('mat-tab-label-active')[0];
                    activeTab.click();
                }
            });
        }
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    get subAccounts() {
        if (this.sessionData.structure && this.sessionData.structure.subAccounts) {
            return this.sessionData.structure.subAccounts;
        }
        return;
    }

    get mpoScores() {
        if (this.jobService.score) {
            return this.jobService.score[0];
        }
        return;
    }

    get talentsScores() {
        if (this.jobService.competences) {
            return this.jobService.competences[0];
        }
        return;
    }

    get displayGraph() {
        return !!(
            this.cleanScore() !== undefined &&
            this.cleanScore() !== null &&
            Object.keys(this.cleanScore()).length === 6
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
