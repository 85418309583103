import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    Input,
    OnInit,
    OnDestroy
} from '@angular/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-mobile-graphboards-person-adding',
  templateUrl: './mobile-graphboards-person-adding.component.html',
  styleUrls: ['./mobile-graphboards-person-adding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileGraphboardsPersonAddingComponent implements OnInit, OnDestroy {

    @Input() multiAction: any = false;
    @Input() selectedTab = 0;
    graphboardTab = 'individus';

    @Output() closePanel = new EventEmitter();
    @Output() graphboardTabChange = new EventEmitter();

    public loading = false;

    evaluationTypes = ['individus', 'profiler'];
    searchTerm: any;
    records: any = {};

    chooseGraphboard: boolean;
    headerText: string;
    gbList: Array<any> = null;

    gbId: string;

    selectedItems: any = {
        individus: [],
        profiler: []
    };

    recordsLoaded: any = {
        individus: 250,
        profiler: 250
    };
    recordsLength = {};

    newGraphBoard = false;
    loadingGif = '/assets/spiner.gif';
    actual = '';
    currentLang: string;

    private subscriptions = new Subscription();

    constructor(
        public graphBoard: GraphBoardsAnalysisService,
        private changeDetectorRef: ChangeDetectorRef,
        private stateService: StateService,
        private router: Router,
        private apiGraphboards: ApiGraphboardsService,
        private apiPeopleService: ApiPeopleService,
        private apiJobsService: ApiJobsService,
        private translate: TranslateService
    ) {
        this.selectedItems = {
            'individus': [],
            'profiler': []
        };
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchData(this.graphboardTab, searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchData(this.graphboardTab, searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchData(this.graphboardTab, null);
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.selectedItems = {
            individus: [],
            profiler: []
        };

        this.loading = true;
        this.fetchData('individus');
        this.fetchData('profiler');

        if (this.graphBoard.alignment) {
            let i = {
                individus: 0,
                profiler: 0
            };
            let colKeys = Object.keys(this.graphBoard.alignment);
            // tslint:disable-next-line:forin
            for (let col in colKeys) {
                let rowKeys = Object.keys(this.graphBoard.alignment[+col]);
                // tslint:disable-next-line:forin
                for (let row in rowKeys) {
                    // tslint:disable-next-line:max-line-length
                    let analysisType = (
                        this.graphBoard.alignment[+col][+row].evaluationType === 'mpo'
                    ) ? 'individus' : 'profiler';
                    let hasTrait = (this.graphBoard.alignment[+col][+row].analysisType === 'trait' ||
                        this.graphBoard.alignment[+col][+row].hasTrait);
                    let hasPrb = (this.graphBoard.alignment[+col][+row].analysisType === 'prb' ||
                        this.graphBoard.alignment[+col][+row].hasPrb);
                    // noinspection OverlyComplexBooleanExpressionJS
                    let currentId: any = (
                        this.graphBoard.alignment &&
                        this.graphBoard.alignment[+col] &&
                        this.graphBoard.alignment[+col][+row] &&
                        this.graphBoard.alignment[+col][+row].id) ?
                        this.graphBoard.alignment[+col][+row].id : false;


                    let insert = !(_.find(this.selectedItems[analysisType], function (o) {
                        return o.record === currentId;
                    }));

                    if (insert) {
                        let item = {};
                        if (analysisType === 'individus') {
                            item = {
                                record: this.graphBoard.alignment[+col][+row].id,
                                hasTrait: hasTrait,
                                hasPrb: hasPrb,
                                position: [+col, +row]
                            };
                        } else {
                            item = {
                                record: this.graphBoard.alignment[+col][+row].id,
                                position: [+col, +row]
                            };
                        }
                        this.selectedItems[analysisType][i[analysisType]] = item;
                        i[analysisType]++;
                    } else {
                        if (currentId) {
                            let index = this.selectedItems[analysisType].map(function (o) {
                                return o.record;
                            }).indexOf(currentId);
                            if (hasTrait) {
                                this.selectedItems[analysisType][index].hasTrait = hasTrait;
                            }
                            if (hasPrb) {
                                this.selectedItems[analysisType][index].hasPrb = hasPrb;
                            }
                        }
                    }
                }
            }
        }
        if (this.selectedTab > 0) {
            this.displayType(this.selectedTab);
        }
        this.changeDetectorRef.markForCheck();
    }

    displayType(index: number) {
        this.selectedTab = index;
        this.graphboardTab = this.evaluationTypes.find((e, i) => {
            if (i === index) {
                return e;
            } else {
                return;
            }
        });
        this.fetchData(this.graphboardTab, this.actual);
        this.graphboardTabChange.emit(index);
    }

    onScroll() {
        if (this.recordsLoaded[this.graphboardTab] >= this.recordsLength[this.graphboardTab]) {
            return;
        }

        this.fetchData(
            this.graphboardTab, this.searchTerm, this.recordsLoaded[this.graphboardTab], true
        );
    }

    predicateCheckBox(type: string, record: any): any {
        if (type === 'profiler') {
            for (let item of this.selectedItems['profiler']) {
                if (item.record === record.id) {
                    return true;
                }
            }
            return false;
        }
    }

    check(record, type) {

        let item = _.find(this.selectedItems['individus'], function (o) {
            return o.record === record.id;
        });

        if (item) {
            if (!item.hasPrb && item.hasTrait && type === 'trait') {
                return true;
            }
            if (item.hasPrb && !item.hasTrait && type === 'prb') {
                return true;
            }
            if (item.hasPrb && item.hasTrait && type === 'both') {
                return true;
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    isDisabledTraitCheck(ctx) {
        return (ctx &&
        ctx.reportStatuses &&
        ctx.reportStatuses.mpo ? null : true);
    }

    isDisabledPrbCheck(ctx) {
        return (ctx &&
        ctx.reportStatuses &&
        ctx.reportStatuses.prb ? null : true);
    }

    showProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    onChecked(event, id: string) {
        if (event.checked) {
            this.selectedItems[this.graphboardTab].push({
                record: id,
                position: null
            });
        } else {
            _.remove(this.selectedItems[this.graphboardTab], function (i) {
                return i.record === id;
            });
        }
    }

    selectIndType(id, type) {
        // type := {'trait' 'prb' 'both', 'none'};

        let item = _.find(this.selectedItems[this.graphboardTab], function (o) {
            return o.record === id;
        });
        _.remove(this.selectedItems[this.graphboardTab], function (i) {
            return i.record === id;
        });

        let hasTrait = false;
        let hasPrb = false;

        if (type === 'trait') {
            if (item && item.hasPrb && item.hasTrait) {
                hasTrait = true;
            } else {
                hasTrait = !(item && item.hasTrait);
            }
            hasPrb = false;
        }
        if (type === 'prb') {
            if (item && item.hasPrb && item.hasTrait) {
                hasPrb = true;
            } else {
                hasPrb = !(item && item.hasPrb);
            }
            hasTrait = false;

        }
        if (type === 'both') {
            if (item && item.hasPrb && item.hasTrait) {
                hasTrait = false;
                hasPrb = false;
            } else {
                hasTrait = true;
                hasPrb = true;
            }

        }

        this.selectedItems[this.graphboardTab].push({
            record: id,
            hasTrait: hasTrait,
            hasPrb: hasPrb,
            position: null
        });
    }

    assignGbId(id) {
        this.graphBoard.setItemToDisplayWithId(id, 'graphboard', 'mobileView');
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    onSubmit() {
        let modSelectedItems: any = JSON.parse(JSON.stringify(this.selectedItems));
        // console.log(modSelectedItems);
        let submitArray = [[], [], []];
        let newElmt = {
            'trait': [],
            'prb': [],
            'profiler': []
        };

        // Convert back to canonical format:
        modSelectedItems['trait'] = [];
        modSelectedItems['prb'] = [];
        for (let item of modSelectedItems['individus']) {
            let itemTmp = item;
            // console.log(item);
            if (item.position !== null || item.hasTrait || item.hasPrb) {
                if (itemTmp.hasTrait && itemTmp.hasPrb) {
                    delete itemTmp.hasTrait;
                    delete itemTmp.hasPrb;
                    modSelectedItems['trait'].push(itemTmp);
                    modSelectedItems['prb'].push(itemTmp);
                } else {
                    if (itemTmp.hasTrait) {
                        delete itemTmp.hasTrait;
                        delete itemTmp.hasPrb;
                        modSelectedItems['trait'].push(itemTmp);
                    }
                    if (itemTmp.hasPrb) {
                        delete itemTmp.hasTrait;
                        delete itemTmp.hasPrb;
                        modSelectedItems['prb'].push(itemTmp);
                    }
                }
            }
        }

        for (let itemType of ['trait', 'prb', 'profiler']) {
            for (let item of modSelectedItems[itemType]) {
                // console.log/(item, itemType);
                if (item.record && item.position !== null) {// } || item.hasTrait || item.hasPrb)) {
                    if (itemType === 'prb') {
                        if (submitArray[item.position[0]][item.position[1]]) {
                            let x = item.position[0];
                            let y = item.position[1];
                            while (submitArray[x][y]) {
                                x = (x + 1) % 3;
                                y += 1;
                            }
                            submitArray[x][y] = {
                                type: itemType,
                                record: item.record,
                                dash: 0
                            };
                        } else {
                            submitArray[item.position[0]][item.position[1]] = {
                                type: itemType,
                                record: item.record,
                                dash: 0
                            };
                        }
                    } else {
                        if (submitArray[item.position[0]][item.position[1]]) {
                            let x = item.position[0];
                            let y = item.position[1];
                            while (submitArray[x][y]) {
                                x = (x + 1) % 3;
                                y += 1;
                            }
                            submitArray[x][y] = {
                                type: itemType,
                                record: item.record
                            };
                        } else {
                            submitArray[item.position[0]][item.position[1]] = {
                                type: itemType,
                                record: item.record
                            };
                        }
                    }
                } else {
                    newElmt[itemType].push(item.record);
                }
            }
        }

        let j = 0;
        for (let itemType of ['trait', 'prb', 'profiler']) {
            for (let i = 0; i < newElmt[itemType].length; i++) {
                if (newElmt[itemType][i] !== null) {
                    let payload: any = {
                        type: itemType,
                        record: newElmt[itemType][i]
                    };
                    if (itemType === 'prb') {
                        payload.dash = 0;
                    }
                    submitArray[j].push(
                        payload
                    );
                }
                j++;
                j %= submitArray.length;
            }
        }

        if (submitArray) {
            const data = {'alignment': submitArray};
            this.subscriptions.add(this.apiGraphboards.putGraphboard([this.graphBoard.id], data).subscribe(
                (res) => {
                    this.graphBoard.setItemToDisplayWithId(res.id, 'graphboard', 'itemSelected');
                    this.initSelection();
                    if (this.multiAction && this.multiAction.idType !== '' && this.multiAction.ids.length > 0) {
                        this.router.navigate(['m/analysis/graphboard/report/' + res.id]).then(() => {});
                    }
                    this.closeP();
                    return;
                }
            ));
        }
    }

    initSelection() {
        this.selectedItems = {
            individus: [],
            profiler: []
        };

        this.newGraphBoard = true;
    }

    closeP() {
        this.closePanel.emit(true);
    }

    loadRecords(records, displayType, skip, concat) {
        // Get records loaded (add the new records from API)
        this.recordsLoaded[displayType] = (skip) ?
            this.recordsLoaded[displayType] + records.data.length :
            this.recordsLoaded[displayType];

        // Records data (merge new records from API)
        this.records[displayType] = (concat) ?
            [...this.records[displayType], ...records.data] :
            records.data;

        // Total records
        this.recordsLength[displayType] = records.count;
    }

    fetchData(displayType, searchTerm = null, skip = null, concat = false) {
        // Get individus
        if (displayType === 'individus') {
            let request = {
                'search': searchTerm,
                'skip': skip,
                'limit': this.recordsLoaded[displayType],
                // 'order': {firstName: 'asc'}
            };

            this.subscriptions.add(this.apiPeopleService.records(
                [request]).subscribe(
                (res) => {
                    this.loadRecords(res, displayType, skip, concat);

                    // Reload component
                    this.changeDetectorRef.markForCheck();

                    this.loading = false;
                    return;
                }
            ));
        }

        // Profiler
        if (displayType === 'profiler') {
            let request = {
                'search': searchTerm,
                'fields': 'id,jobTitle',
                'skip': skip,
                'statuses[score]': 1,
                'limit': this.recordsLoaded[displayType],
                'order': {date: 'desc'},
                'isArchived': 0
            };
            this.subscriptions.add(this.apiJobsService.jobs(
                [request]).subscribe(
                (res) => {
                    this.loadRecords(res, displayType, skip, concat);

                    // Reload component
                    this.changeDetectorRef.markForCheck();

                    this.loading = false;
                    return;
                }
            ));
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
