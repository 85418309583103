export class PricesDefinitions {
    // TODO: clean mpo and dit from here and upstream
    static item: any = {
        mpo: 5,
        personality: 5,
        talents: 4,
        dit: 3,
        communication: 3,
        satellite: 2,
        perception_initial: 0,
        perception_subsequent: 2,
        iac: 2 // TODO change this
    };
}
