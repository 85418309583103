import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit
} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {emailValidator} from '../../../validators/email';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {PricesDefinitions} from '../../../../shared/prices/pricesDefinitions';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {StateService} from '../../../../core/services/state/state.service';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {ApiPeopleService} from '../../../services/api-people.service';
import {PeopleReportActivationModalCommon} from '../../commonClasses/people-report-activation-modal-common';
import {PeopleSurveyRequestHelper} from '../../commonClasses/people-survey-request/people-survey-request.helper';
import {PeopleSurveyRequestSendMethodsHelper} from '../../commonClasses/people-survey-request/send-methods/send-methods.helper';
import {EvaluationRequestNext} from '../../../models/evaluation-request-next.model';
import {EvaluationRequest} from '../../../models/evaluation-request.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-people-report-activation',
    templateUrl: './mobile-people-report-activation.component.html',
    styleUrls: ['./mobile-people-report-activation.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ]),
        trigger('fadeInOut2', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ])

    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeopleReportActivationComponent extends PeopleReportActivationModalCommon
    implements OnInit, OnChanges, OnDestroy {

    @Input() activationData: any;
    @Output() close = new EventEmitter();

    creditCost = 0;
    creditAvailable: any = '';
    variantOptionsValue = {
        traitsAndPrb: '1,1',
        traitsOnly: '1,0'
    };
    variant;
    variantFlag = false;
    reports = {
        personality: false,
        talents: false,
        communication: false,
        satellite: false
    };
    displayWarningMessage = false;
    country: string;
    countries: any;
    countryFlag = false;
    language: string;
    languageFlag = false;
    languages = {
        fr: [
            {
                value: 'fr',
                name: 'Français'
            },
            {
                value: 'en',
                name: 'Anglais'
            }
        ],
        en: [
            {
                value: 'fr',
                name: 'French'
            },
            {
                value: 'en',
                name: 'English'
            }
        ]
    };
    toggle = 0;
    flipValue: number;
    person: any;
    tempUser: any;
    mailto: any;
    innerHtml;
    textToSend = '';
    evaluationRequestResend: EvaluationRequestNext = new EvaluationRequestNext();
    evaluationResendForm: UntypedFormGroup;
    nativeWindow: any;

    evaluationFormUrl: string;

    constructor(
        private fb: UntypedFormBuilder,
        private apiPeopleService: ApiPeopleService,
        private windowRef: WindowRef,
        public router: Router,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        private state: StateService,
        public translate: TranslateService,
        protected cd: ChangeDetectorRef,
        public personService: PersonService,
        private environment: EnvService
    ) {
        super(apiJobsService, apiAdministrationService, cd, translate, testAndReportHandlingService, personService, state);
        this.pricesDefinitions = PricesDefinitions.item;
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    ngOnInit() {
        this.getSatelliteJobs();
        this.creditSetup(0, this.state.session.sessionData.accountData.id);
        // check the case if OnInit is called after OnChanges
        setTimeout( () => {
            this.setupData();
        }, 200);
    }

    ngOnChanges() {
        this.proceed = true;
        this.setupData();
    }

    setupData() {
        if (this.activationData) {
            let subAccount = (this.activationData.personToDisplay) ? this.activationData.personToDisplay.subAccount : this.activationData.subAccount;
            this.creditSetup(subAccount, this.state.session.sessionData.accountData.id, this.activationData);
            this.toggle = 0;
            let activationDataType = this.activationData.type;
            if (activationDataType === 'personality') {
                activationDataType = 'mpo';
            }
            if (activationDataType === 'communication') {
                activationDataType = 'dit';
            }
            this.reports[activationDataType] = true;
            this.computeCredits();
            this.creditAvailable = '';
            this.displayWarningMessage = false;
            // get credits available for this subAccount
            this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
                [this.state.session.sessionData.account.id, subAccount]
            ).subscribe(
                (res) => {
                    if (res.unlimited) {
                        this.creditAvailable = 'unlimited';
                    } else {
                        this.creditAvailable = res.credits;
                    }
                    this.cd.markForCheck();
                }
            ));
            if (this.state.session.sessionData.structure && this.state.session.sessionData.structure.countries) {
                this.countries = this.state.session.sessionData.structure.countries[this.translate.currentLang];
            }
        }
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    setActivationData(activationData) {
        if (activationData && activationData.types) {
            this.activationData.types = activationData.types;
        }
        if (activationData && activationData.cost) {
            this.activationData.cost = activationData.cost;
        }
    }

    activationTexts() {
        let types = (this.activationData && this.activationData.types) ? this.activationData.types : null;
        if (!types && this.activationData && this.activationData.type) {
            types = [this.activationData.type];
        }
        if (types) {
            if (this.unlimitedCredits) {
                if (types.length === 1) {
                    return this.translate.instant('people.reportActivationModal.reportActivationUnlimited', {reportType: this.renameReport(types[0])});
                } else {
                    return this.translate.instant('people.reportActivationModal.reportsActivationUnlimited', {reportType: this.listReports(types)});
                }
            } else {
                let cost = this.activationData.cost;
                if (cost === undefined) {
                    cost = this.pricesDefinitions[this.activationData.type];
                }
                if (types.length === 1) {
                    return this.translate.instant('people.reportActivationModal.reportActivationFor', {reportType: this.renameReport(types[0]), price: cost});
                } else {
                    return this.translate.instant('people.reportActivationModal.reportsActivationFor', {reportType: this.listReports(types), price: cost});
                }
            }
        }

    }

    get activationType() {
        return (this.activationData ? this.activationData.type : null);
    }

    get activationCost() {
        return (this.activationData ? this.activationData.cost : null);
    }

    get targetPerson() {
        return (this.activationData && this.activationData.hasOwnProperty('personToDisplay') ? this.activationData.personToDisplay : null);
    }

    get personEmail() {
        return (this.targetPerson ? this.activationData.personToDisplay.email : null);
    }

    computeCredits() {
        this.creditCost = 0;
        this.creditCost = PeopleSurveyRequestHelper.getCreditCost(this.reports, '1,1');
        this.displayWarningMessage = (this.creditCost > this.creditAvailable);
    }

    toggleFlip(n) {
        if (n !== '0' && n !== '1' && !this.displayWarningMessage) {
            this.produceMpoRequest(n);
        }
        return;
    }

    initEvaluationResendForm() {
        this.evaluationResendForm = this.fb.group({
                emailTo: [this.evaluationRequestResend.emailTo],
                // subject : [this.evaluationRequestResend.subject],
                message: [(this.evaluationRequestResend.message) ? this.evaluationRequestResend.message : this.textToSend]
            },
            {
                validator: emailValidator('emailTo')
            });
    }

    openEvaluationForm() {
        // Open window to avoid popup blocking

        let questionnaireWindow = this.nativeWindow.open('', '_blank');

        this.subscriptions.add(this.apiPeopleService.requestToken([this.person.id]).subscribe(
            res => {
                // Modification of URL in window open after getting data
                questionnaireWindow.document.location.href = this.evaluationFormUrl + '/autoconnect/' + res.token;
            }
        ));
    }

    initMessage(type, person, tempUser) {
        return PeopleSurveyRequestSendMethodsHelper.initMessage(
            type,
            person,
            tempUser,
            this.language,
            this.variant,
            {
                name: this.state.session.sessionData.userData.fullName,
                company: this.state.session.sessionData.structure.subAccounts[0].name
            },
            false
        );
    }

    produceMpoRequest(toggleNumber) {
        let type = [];
        for (const [key, value] of Object.entries(this.reports)) {
            if (value === true) {
                type.push(key);
            }
        }

        if (this.targetPerson) {
            let data = new EvaluationRequest({
                recordId: this.targetPerson.id,
                variant: this.variant,
                country: this.country,
                language: this.language,
                firstName: this.targetPerson.firstName,
                lastName: this.targetPerson.lastName,
                email: this.targetPerson.email,
                jobTitle: this.targetPerson.jobTitle,
                organisation: this.targetPerson.organisation,
                recordType: this.targetPerson.recordType,
                subAccount: this.targetPerson.subAccount,
                jobId: this.targetPerson.jobId,
                requestedByName: this.state.session.sessionData.userData.fullName,
                requestedBy: this.state.session.sessionData.userData.username,
                type: type,
                researchQuestions: false,
                createTempUser: true
            });

            // Generate request
            this.apiPeopleService.evaluationRequest(data).subscribe(
                (res) => {
                    this.evaluationRequestResend = new EvaluationRequestNext();

                    this.tempUser = res;

                    this.person = this.targetPerson;

                    if (this.tempUser && this.tempUser.email) {
                        this.evaluationRequestResend.emailTo = this.targetPerson.email;
                    }

                    // Init
                    this.initEvaluationResendForm();
                    if (this.person && this.tempUser) {
                        this.mailto = this.initMessage('mail', this.person, this.tempUser);
                        this.innerHtml = this.initMessage('cutnpaste', this.person, this.tempUser);
                        this.cd.markForCheck();
                    }

                    // proceed to sending

                    this.toggle = toggleNumber;
                    this.cd.markForCheck();
                }
            );
        }
    }

    onSubmit(model: any) {
        this.subscriptions.add(this.apiPeopleService.emailEvaluationSurvey(
            [this.tempUser.id],
            [model.value.emailTo, model.value.message, false]
        ).subscribe(
            () => {
                this.closeActivationSection(true);
            },
            error => {
                console.log(error._body);
            }
        ));
    }

    lang() {
        return this.translate.currentLang;
    }

    navToJob() {
        this.state.navigate(
            this.router,
            'jobs/list',
            'setSideBarStatus',
            {
                sideBarStatus: 'recentActivities'
            },
            'jobs'
        );
    }

    reportActivation(type = this.activationData.type) {
        this.reportActivationCommon(type);
        this.state.session.sessionCreditUpdateWatch.next(true);
        if (!this.warningNothingSelected) {
            this.closeActivationSection(true);
        }
    }

    finishSending() {
        this.closeActivationSection(true);
    }

    closeActivationSection(refresh = false) {
        // Reset data
        this.toggle = 0;
        this.language = undefined;
        this.country = undefined;
        this.flipValue = undefined;
        this.languageFlag = undefined;
        this.countryFlag = undefined;
        this.evaluationRequestResend = new EvaluationRequestNext();
        this.proceed = null;
        this.close.emit(refresh);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
