import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TestAndReportHandlingService} from '../../../../../../../people/services/test-and-report-handling.service';
import {StateService} from '../../../../../../../core/services/state/state.service';
import {DomSanitizer} from '@angular/platform-browser';
import {PeopleModalHandlingService} from '../../../../../../../people/services/people-modal-handling.service';
import {JobSatisfactionPcrReportService} from '../../../../../services/job-satisfaction-pcr-report.service';
import * as moment from 'moment';
import {ApiPeopleService} from '../../../../../../../people/services/api-people.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-job-satisfactions-person-sub-card-personality',
    templateUrl: './job-satisfactions-person-sub-card-personality.component.html',
    styleUrls: ['./job-satisfactions-person-sub-card-personality.component.scss']
})
export class JobSatisfactionsPersonSubCardPersonalityComponent implements OnChanges {

    @Input() record;
    @Input() job;
    @Input() informationVisibility = false;
    @Input() listView = true;
    @Input() pos;
    @Input() truncate = false;
    @Output() openPcrReport = new EventEmitter;

    personFetched: any;
    isSmallMobile;

    constructor(
        public translateService: TranslateService,
        public cd: ChangeDetectorRef,
        public testAndReportHandlingService: TestAndReportHandlingService,
        public stateService: StateService,
        protected peopleModalHandlingService: PeopleModalHandlingService,
        protected jobSatisfactionPcrReport: JobSatisfactionPcrReportService,
        public apiPeopleService: ApiPeopleService,
        public device: DeviceDetectorService
    ) {
    }

    openJobSatisfactionPcrReport(data) {
        if (data.prbScores !== null) {
            data.jobSatisfactionScore = this.record.jobSatisfactionScore;
            this.jobSatisfactionPcrReport.personData = data;
            this.openPcrReport.emit(true);
        }
    }

    ngOnChanges() {

        this.isSmallMobile = (document.documentElement.clientWidth < 400 && this.device.isMobile());
        this.cd.markForCheck();
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    openModal(type, evaluationRequest = null) {
        this.apiPeopleService.record([this.record.id]).subscribe(
            (person) => {
                this.personFetched = person;
                this.testAndReportHandlingService.prepareGetRequestSendData(type, evaluationRequest).then(
                    (data: any) => {
                        return this.peopleModalHandlingService.openSendExtraPrbModal({person: this.personFetched});
                    }
                );
            }
        );
    }

    redoPcr(record) {
        let date = (record['prb'][0] && record['prb'][0]['date']) ? record['prb'][0]['date'] : null;
        // console.log(record['lastName'], date);
        if (date !== null) {
            let today = new Date();
            let pcrDate = new Date(date);
            let diffDate = (today.getTime() - pcrDate.getTime()) / 86400000;
            if (diffDate >= 120) {
                return 2;
            }
        }
    }

    flagPrbToSend(record) {
        return (
            !(record && record['scores']) ||
            !(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ||
            (this.redoPcr(record) === 2)
        );
    }

    get state() {
        return this.stateService.jobSatisfaction;
    }

}
