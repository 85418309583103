import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiGet} from '../../../core/services/api/api-get';
import { HttpClient } from '@angular/common/http';
import {EnvService} from '../../../app/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class ApiGetNewsService  extends ApiGet {

    constructor(
        protected http: HttpClient,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    /**
     * @param dataParams
     */
    news_messages(dataParams: [string, string, string, any]): Observable<any> {
        let dataParamsNames = [];
        let dataParamsToSend = [];
        dataParams.forEach((p, index) => {
            if (p != null) {
                dataParamsToSend.push(p);
                if (index === 0) {
                    dataParamsNames.push('lang');
                }
                if (index === 1) {
                    dataParamsNames.push('version');
                }
                if (index === 2) {
                    dataParamsNames.push('type');
                }
                if (index === 3) {
                    dataParamsNames.push('startDate');
                }
            }
        });

        return this.apiGet(
            'news-messages',
            null,
            dataParamsToSend,
            dataParamsNames
        );
    }
}
