// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  display: none;
}

#backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  overflow: hidden;
}

#alertFrame {
  position: fixed;
  border: 1px solid #9A9A9A;
  background-color: white;
  padding: 0;
  z-index: 99999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  border-radius: 3px;
}

#innerFrame {
  padding: 10px;
}

.icon {
  width: 40px;
}

div.main-container {
  display: table;
}

div.main-container div.title-container {
  display: table-cell;
  vertical-align: middle;
}

h1 {
  font-size: 11pt;
  font-weight: bold;
  margin: 0 0 0 20px;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/app/components/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EAEA,gBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,yBAAA;EACA,uBAAA;EACA,UAAA;EACA,cAAA;EAEA,2BAAA;EACA,yBAAA;EAGA,iBAAA;EAEA,eAAA;EAEA,kBAAA;AAHJ;;AAMA;EACI,aAAA;AAHJ;;AAMA;EACI,WAAA;AAHJ;;AAMA;EACI,cAAA;AAHJ;;AAMA;EACI,mBAAA;EACA,sBAAA;AAHJ;;AAMA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;AAHJ;;AAMA;EACI,SAAA;EACA,UAAA;AAHJ","sourcesContent":[".block {\n    display: none;\n}\n\n#backdrop {\n    background-color:rgba(0, 0, 0, 0.3);\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 100000;\n\n    overflow: hidden;\n}\n\n#alertFrame {\n    position: fixed;\n    border: 1px solid #9A9A9A;\n    background-color: white;\n    padding: 0;\n    z-index: 99999;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n\n    cursor: default;\n\n    border-radius: 3px;\n}\n\n#innerFrame {\n    padding: 10px;\n}\n\n.icon {\n    width: 40px;\n}\n\ndiv.main-container {\n    display: table;\n}\n\ndiv.main-container div.title-container {\n    display: table-cell;\n    vertical-align: middle;\n}\n\nh1 {\n    font-size: 11pt;\n    font-weight: bold;\n    margin: 0 0 0 20px;\n    padding: 0;\n}\n\nh2 {\n    margin: 0;\n    padding: 0;\n\n}\n\np {\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
