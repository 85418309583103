// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  top: 15px;
  text-align: center;
}

h2 {
  font-size: 1.7rem;
  border-bottom: 1px #aaaaaa solid;
  padding: 10px 10px 5px 10px;
}

h3 {
  font-size: 1.5rem;
  padding: 10px;
}

.panelHr {
  border-color: #aaaaaa;
  margin: 0;
}

a:hover, a:focus {
  color: #6a6a6a;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/app/components/support/mobile/mobile-support/mobile-support.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gCAAA;EACA,2BAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;AACJ;;AAEA;EACI,qBAAA;EACA,SAAA;AACJ;;AAEA;EACI,cAAA;EACA,0BAAA;AACJ","sourcesContent":["h1 {\n    top: 15px;\n    text-align: center;\n}\n\nh2 {\n    font-size: 1.7rem;\n    border-bottom: 1px #aaaaaa solid;\n    padding: 10px 10px 5px 10px;\n}\n\nh3 {\n    font-size: 1.5rem;\n    padding: 10px;\n}\n\n.panelHr {\n    border-color: #aaaaaa;\n    margin: 0;\n}\n\na:hover, a:focus {\n    color: #6a6a6a;\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
