import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ChangeDetectorRef,
    OnChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import {StateService} from 'src/app/core/services/state/state.service';
import {UserService} from 'src/app/core/services/user/user.service';
import {PersonService} from '../../../services/person.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiPeopleService} from '../../../services/api-people.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {PeoplePdfModalComponentCommon} from '../../commonClasses/people-pdf-modal-common';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-people-pdf-sharing',
    templateUrl: './mobile-people-pdf-sharing.component.html',
    styleUrls: ['./mobile-people-pdf-sharing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeoplePdfSharingComponent extends PeoplePdfModalComponentCommon implements OnInit, OnChanges {
    @Output() cancelPdf = new EventEmitter;

    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    selectMethod = false;

    serverText: string;

    method = 'pdf';
    share = 'none';

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public user: UserService,
        public person: PersonService,
        public stateService: StateService,
        private windowRef: WindowRef,
        protected apiPeopleService: ApiPeopleService,
        protected apiJobService: ApiJobsService,
        protected translate: TranslateService,
        private environment: EnvService
    ) {
        super(
            translate,
            stateService,
            apiJobService,
            changeDetectorRef,
            apiPeopleService,
            person
        );
        this.nativeWindow = windowRef.getNativeWindow();
    }

    getReportData(data: any) {
        let obs = this.getReportDataCommon();
        this.subcriptions.add(obs.subscribe(
            res => {
                this.pdfToken = res.pdfToken;

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + encodeURI(this.pdfToken);

                if (data.type === 'mailto') {
                    this.mailto = '?subject=' + this.translate.instant('commons.report') + ' '
                        + this.reportName + '&body=' +
                        this.translate.instant('people.pdfModal.linkInstruction', {
                            reportName: this.reportName,
                            firstName: this.person.firstName,
                            lastName: this.person.lastName
                        }) + '%0A%0A' + this.pdfReportUrl;
                    this.mailToJson = {
                        mailto: this.mailto
                    };
                } else if ('share' === data.type) {
                    this.serverText = this.translate.instant('people.pdfModal.linkInstruction', {
                        reportName: this.reportName,
                        firstName: this.person.firstName,
                        lastName: this.person.lastName
                    }) + this.pdfReportUrl;
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    handleReport() {
        if (this.method !== 'none') {
            if (this.pdfStructure.report === 'personality') {
                this.reportName = this.translate.instant('commons.mpoPersonality');
            }
            if (this.pdfStructure.report === 'communication') {
                this.reportName = 'MPO Communication';
            }
            if (this.pdfStructure.report === 'satellite') {
                this.reportName = 'MPO Satellite';
            }
            if (this.pdfStructure.report === 'talents') {
                this.reportName = 'MPO Talents';
            }
            if (this.pdfStructure.report === 'iac') {
                this.reportName = this.translate.instant('commons.cai');
            }
            switch (this.method) {
                case 'pdf':
                    this.downloadReport();
                    return;
                case 'cutpaste':
                    this.share = 'cutpaste';
                    this.getReportData({type: 'token'});
                    return;
                case 'mailto':
                    this.share = 'mailto';
                    this.getReportData({type: 'mailto'});
                    return;
                case 'server':
                    this.share = 'server';
                    this.getReportData({type: 'share'});
                    return;
            }
        }
        this.selectMethod = true;
        return;
    }

    ngOnInit() {
        this.initPdfStructure();
    }

    cancel() {
        this.cancelPdf.emit();
    }

    toggleGraphOnlyChk() {
        this.graphOnly = !this.graphOnly;
        this.toggleGraphOnly();
    }

    showProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    get testAccessPermissions() {
        return this.stateService.session.sessionData.structure.testAccessPermissions;
    }

    ngOnChanges(changes) {
        this.initPdfStructure(changes);
    }

}
