import {Subscription} from 'rxjs';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {PersonService} from 'src/app/people/services/person.service';
import {StateService} from '../../../core/services/state/state.service';

export abstract class PeopleEditModalComponentCommon {

    protected subscriptions = new Subscription();
    jobProfiles: any;
    sessionStructure: any = {};
    departments: any;
    recordStatus: any;
    subAccounts: any;

    protected constructor(
        protected stateService: StateService,
        protected apiJobs: ApiJobsService,
        protected cd: ChangeDetectorRef,
        public person: PersonService,
        protected fb: UntypedFormBuilder
    ) {
    }

    fetchJobs() {
        this.subscriptions.add(this.apiJobs.jobs([{
            fields: 'id,jobTitle',
            limit: 500,
            'order[date]': 'desc',
            isArchived: 0
        }]).subscribe(
            (res) => {
                this.jobProfiles = res.data;
                this.cd.markForCheck();
            }
        ));
    }

    initSessionVars() {
        this.sessionStructure = this.stateService.session.sessionData.structure;
        this.departments = this.sessionStructure.departments;
        this.recordStatus = this.sessionStructure.recordStatus;
        this.subAccounts = this.sessionStructure.subAccounts;
    }

    initPeopleEditForm() {
        let departmentId;
        if (Array.isArray(this.person.department)) {
            if (this.person.department[0]) {
                departmentId = (this.person.department[0].id) ?
                    this.person.department[0].id :
                    this.person.department[0];
            }
        }
        if (!departmentId) {
            departmentId = (this.person.department && this.person.department.length > 0) ?
                this.person.department.id :
                null;
        }
        return this.fb.group({
            firstName: [this.person.firstName, [Validators.required, Validators.minLength(2)]],
            lastName: [this.person.lastName, [Validators.required, Validators.minLength(2)]],
            email: [this.person.email],
            organisation: [this.person.organisation],
            subAccount: [this.person.subAccount],
            division: [this.person.division],
            recordType: [this.person.recordType],
            jobTitle: [this.person.jobTitle],
            jobType: [this.person.jobType],
            jobId: [this.person.jobId],
            department: [departmentId]
        });
    }
}
