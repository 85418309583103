import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-job-fake-report',
  templateUrl: './job-fake-report.component.html',
  styleUrls: ['./job-fake-report.component.scss']
})
export class JobFakeReportComponent implements OnInit {

    @Input() for = 'mpo';
    pLines = [
        [0, 1, 2, 3, 4, 5],
        [0, 1, 2, 3, 4],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2, 3],
        [0, 1, 2]
    ];

    constructor(public translate: TranslateService) { }

    ngOnInit() {

    }

}
