import {Subscription} from 'rxjs';
import {ApiJobsService} from '../../../jobs/services/api-jobs.service';
import {ApiAdministrationService} from '../../../administration/services/api-administration.service';
import {ChangeDetectorRef, Input, Directive} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EvaluationHelper} from '../../../shared/misc/evaluation.helper';
import {TestAndReportHandlingService} from '../../services/test-and-report-handling.service';
import {PersonService} from '../../services/person.service';
import {StateService} from '../../../core/services/state/state.service';

@Directive()
export abstract class PeopleReportActivationModalCommon {

    @Input() activationData: any;

    protected subscriptions = new Subscription();

    satelliteList: any = null;
    pricesDefinitions: any;
    proceed: boolean = null;
    unlimitedCredits = false;
    warningNothingSelected = false;
    subAccountName: string;
    bindedProfiler: string;

    protected constructor(
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        protected cd: ChangeDetectorRef,
        protected translate: TranslateService,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        public person: PersonService,
        public stateService: StateService
        ) {
        this.unlimitedCredits = this.stateService.session.accountHasUnlimitedCredits;
    }

    getSatelliteJobs() {
        this.subscriptions.add(this.apiJobsService.jobs([{isSatellite: 1, order: {jobTitle: 'asc'}}]).subscribe(
            res => {
                if (res.count === 0) {
                    this.satelliteList = false;
                } else {
                    this.satelliteList = res.data;
                }
                this.cd.markForCheck();
            }
        ));
    }

    creditSetup(subAccount, accountId, activationData = null) {
        this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
            [accountId, subAccount]
        ).subscribe(
            (res) => {
                if (activationData) {
                    let cost = (activationData.cost) ? activationData.cost : this.pricesDefinitions[activationData.type];
                    this.subAccountName = res.name;
                    this.proceed = !!(cost <= res.credits || res.unlimited);
                    this.cd.markForCheck();
                }
            }
        ));
    }

    listReports(types) {
        let listTypes = '';
        let i = 1;
        for (let type of types) {
            type = this.renameReport(type);
            if (i === types.length - 1) {
                let conj = (this.translate.currentLang === 'fr') ? ' et ' : ', and ';
                listTypes += type + conj;
            } else {
                listTypes += type + ', ';
            }

            i++;
        }
        return listTypes.slice(0, -2);
    }

    renameReport(name) {
        let lang = (this.translate.currentLang === 'fr') ? 'fr' : 'en';
        switch (name) {
            case 'mpo':
            case 'MPO':
            case 'personality':
                return this.translate.instant('commons.mpoPersonality');
            case 'Personality':
                return this.translate.instant('commons.mpoPersonality');
            case 'communication':
            case 'Communication':
            case 'dit':
            case 'DIT':
                return 'MPO Communication';
            case 'idw':
            case 'IDW':
                return 'MPO Communication';
            case 'satellite':
            case 'Satellite':
                return 'MPO Satellite';
            case 'talents':
            case 'Talents':
                return 'MPO Talent';
            case 'iac':
            case 'IAC':
            case 'ra':
            case 'RA':
                return (lang === 'fr') ? 'Indicateur d\'aptitudes cognitives MPO' : 'MPO Cognitive Aptitude Indicator';
        }
    }

    reportActivationCommon(typeOrigin = null) {
        let type = typeOrigin;
        if (typeOrigin === 'personality') {
            type = 'mpo';
        }
        if (typeOrigin === 'communication') {
            type = 'dit';
        }

        this.warningNothingSelected = (type === 'satellite' && (!this.bindedProfiler || this.bindedProfiler === 'none'));
        if (this.warningNothingSelected) {
            return;
        }
        let params = {};
        if (this.bindedProfiler) {
            params['satelliteJobId'] = this.bindedProfiler;
        }

        // PRB related index
        const prbRelatedIndex: number | null = (this.activationData.hasOwnProperty('prbRelated')) ? this.activationData.prbRelated : null;

        params['recordId'] = (this.activationData.id) ? this.activationData.id : this.person.id;
        params['subAccount'] = (this.activationData.subAccount) ? this.activationData.subAccount : this.person.subAccount;
        if (this.activationData.type) {
            params['type'] = this.isRa(this.activationData.type) ? 'ra' : this.activationData.type;

            params = {...params, ...EvaluationHelper.getPrbEnableParams(params['type'], prbRelatedIndex)};

            this.testAndReportHandlingService.activateReportByParams(this, params);
        } else if (this.activationData.types) {

            for (let typeOfAd of this.activationData.types) {
                if (typeOfAd === 'satellite') {
                    params['satelliteJobId'] = this.activationData.satelliteJobId;
                }

                params['type'] = this.isRa(typeOfAd) ? 'ra' : typeOfAd;

                params = {...params, ...EvaluationHelper.getPrbEnableParams(params['type'], prbRelatedIndex)};

                this.testAndReportHandlingService.activateReportByParams(this, params);
            }
        }

        this.stateService.people.sideBarStatus = 'reports';

        this.stateService.people.report = {
            type: this.backToFrontCorrectedType(this.activationData.type),
            subType: null
        };

        this.stateService.people.stateChanged.next({
            reportChange: true
        });
    }

    backToFrontCorrectedType(type) {
        let activationTypeCorrected = (type === 'dit') ? 'communication' : type;
        activationTypeCorrected = (type === 'mpo') ? 'personality' : activationTypeCorrected;
        return activationTypeCorrected;
    }

    isRa(expr) {
        return (
            expr === 'ra' ||
            expr === 'RA' ||
            expr === 'iac' ||
            expr === 'IAC'
        );
    }
}
