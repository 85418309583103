import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, NgZone } from '@angular/core';
import { StateService } from '../../../../../core/services/state/state.service';
import { ComponentPrimitive } from '../../../../../shared/classes/components/componentPrimitive';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PersonService } from '../../../../../people/services/person.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { RightMatchAnalysisService } from '../../../services/right-match-analysis.service';
import { find } from 'lodash';
import { MarkdownToHtmlHelper } from '../../../../../shared/helpers/markdown-to-html.helper';
import { ApiRightMatchesService } from '../../../services/api-right-matches.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-right-matches-person-card',
    templateUrl: './right-matches-person-card.component.html',
    styleUrls: ['./right-matches-person-card.component.scss']
})

export class RightMatchesPersonCardComponent extends ComponentPrimitive implements OnChanges {

    @Input() record: any;
    @Input() job: any;
    @Input() orderedTalents: any;
    @Input() minimizeAll;
    @Input() listView = true;
    @Input() pos;

    // toggles
    informationVisibility = false;

    overlayOpen = false;

    isLoading = false;

    actualContext = '';

    messages = { response: '' };

    individualScore: { [key: string]: number };
    jobScore: { [key: string]: number };

    loadingState = 0;
    loadingIntervalId: any;

    otherIconsFolder = 'assets/other_icons/';

    responseReceived: boolean = false;
    
    userFeedback: 'thumbs_up' | 'thumbs_down' | null = null;

    copySuccess = false;

    constructor(
        protected stateService: StateService,
        public translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        public cd: ChangeDetectorRef,
        public user: UserService,
        private person: PersonService,
        public rightMatch: RightMatchAnalysisService,
        public apiRightMatchService: ApiRightMatchesService,
        private ngZone: NgZone
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minimizeAll']) {
            this.informationVisibility = !changes['minimizeAll'].currentValue;
        }
        this.cd.markForCheck();

        this.retrieveScores();
    }

    retrieveScores() {
        if (this.record && this.record.id) {
            let ob = find(this.rightMatch.records, { id: this.record.id });
            this.individualScore = ob ? ob.scores : null;
        }

        this.jobScore = this.rightMatch.profilerInfo['scores'];
    }

    startLoadingAnimation() {
        // console.log('startLoadingAnimation called');
        this.loadingIntervalId = setInterval(() => {
            this.loadingState = this.loadingState % 3 + 1;
            // console.log('loadingState updated', this.loadingState);
            this.cd.detectChanges();
        }, 500);
    }

    stopLoadingAnimation() {
        if (this.loadingIntervalId) {
            clearInterval(this.loadingIntervalId);
            this.loadingIntervalId = null;
        }
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    toggleOverlay() {
        this.overlayOpen = !this.overlayOpen;

        if (this.overlayOpen) {
            console.log(this.translateService.currentLang);
            let data = {
                currentLang: this.translateService.currentLang,
                experimental: 'false',
                personalityScores: this.individualScore,
                jobScores: this.jobScore,
                iacScores: this.record.RA.scoreNormalized,
                jobIacScores: this.job.RA,
                talents: this.record.talents,
                jobComptencencies: this.job.competences[0],
            };
            this.isLoading = true;
            this.startLoadingAnimation();
            this.apiRightMatchService
                .questionAi(data)
                .subscribe(
                    (response: any) => {
                        this.isLoading = false;
                        this.stopLoadingAnimation();
                        this.actualContext = response;
                        // console.log(response);
                        this.messages = {
                            response: MarkdownToHtmlHelper.markdownToHtml(
                                response.response
                            ),
                        };
                        this.responseReceived = true;
                        this.cd.detectChanges();
                    },
                    (error) => {
                        this.isLoading = false;
                        this.stopLoadingAnimation();
                        console.log('error', error);
                    }
                );
        } else {
            this.messages = { response: '' };
        }
    }

    copyToClipboard() {
        let responseText = this.HTMLToText(this.messages?.response);
        this.translateService.get(['people.commons.firstNameLastName', 'people.commons.response']).subscribe((translations) => {
            const clipboardText = `${translations['people.commons.firstNameLastName']}: ${this.record.firstName} ${this.record.lastName}\n\n${translations['people.commons.response']}: ${responseText}`;

            navigator.clipboard.writeText(clipboardText).then(() => {
                this.copySuccess = true;
                this.cd.detectChanges();

                setTimeout(() => {
                    this.copySuccess = false;
                    this.cd.detectChanges();
                }, 2000);
            });
        });
    }

    HTMLToText(html: string): string {
        let text = html.replace(/<\/p>/g, '\n\n');
        text = text.replace(/<[^>]*>/g, '');
        text = text.replace(/\n\s*\n/g, '\n\n');
        return text;
    }

    // Define the saveAiContext method if not already present
    saveAiContext(data: any): Observable<any> {
        return this.apiRightMatchService.saveAiContext(data);
    }

    testingClick() {
        console.log('testingClick');
    }

    submitThumbsUp(): void {
        this.userFeedback = 'thumbs_up';
        console.log('User clicked Thumbs Up');
        // Optionally, provide immediate visual feedback
        // this.cd.detectChanges();
    }

    giveFeedback(): void {
        this.userFeedback = 'thumbs_down';
        console.log('User clicked Thumbs Down');
        // Optionally, provide immediate visual feedback
        // this.cd.detectChanges();
    }

    // 3. Implement sendFeedback Method

    private sendFeedback(): void {
        if (this.userFeedback) {
            const feedbackData = {
                feedback: this.userFeedback,
                context: this.actualContext, // Include any relevant context
                // Add other necessary data here
            };

            this.saveAiContext(feedbackData).subscribe(
                (response) => {
                    console.log('Feedback successfully sent:', response);
                },
                (error) => {
                    console.error('Error sending feedback:', error);
                }
            );

            // Reset feedback after sending
            this.userFeedback = null;
        }
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'navigate');
    }

    ngOnDestroy(): void {
        // Send feedback if the component is destroyed while feedback is pending
        this.sendFeedback();
    }

    get stateRightMatch() {
        return this.stateService.rightMatch;
    }

    get hasThreeReports() {
        return (
            this.user.hasIacAccess && this.state.displayPreferences.display.cai &&
            this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
            this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality
        );
    }

    get hasTwoReports() {
        return (
            !this.hasThreeReports &&
            (
                (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents
                ) || (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                ) ||
                (
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                )
            )
        );
    }

    get hasOneReport() {
        // console.log(this.hasThreeReports, this.hasTwoReports);
        return !(this.hasThreeReports || this.hasTwoReports);
    }

    get state() {
        return this.stateService.rightMatch;
    }

    get hasAiAccess() {
        return this.user.hasJilAccess;
    }
}
