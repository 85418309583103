import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {Subscription} from 'rxjs';
import {StateService} from 'src/app/core/services/state/state.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ComponentPrimitive} from '../../../../classes/components/componentPrimitive';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-main-list-search-bar',
    templateUrl: './main-list-search-bar.component.html',
    styleUrls: ['./main-list-search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainListSearchBarComponent extends ComponentPrimitive implements OnInit, OnDestroy, OnChanges {

    @Output() private searchEvent = new EventEmitter<string>();
    @Output() private filterPanel = new EventEmitter<boolean>();

    @Input() switchOff: any;
    @Input() filters = true;
    @Input() selectionByFilters = false;
    @Input() clear: boolean;

    actual = '';

    searchTerm: string;
    moduleService: string;
    filterPanelState = false;
    recordsLoaded = 0;
    enterButtonPressed = false;
    iconsFolder = 'assets/header/';
    buttonsFolder = 'assets/buttons/';
    items: any = [];

    private subscriptions = new Subscription();

    constructor(
        public stateService: StateService,
        public translateService: TranslateService,
        public router: Router,
        public deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiPeopleService: ApiPeopleService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnInit() {
        if (this.stateService[this.specificState()]) {
            this.searchTerm = this.stateService[this.specificState()].search;
            this.actual = this.stateService[this.specificState()].search;
        }
        if (this.actualModule.name === 'people' && this.apiPeopleService) {
            if (!this.recordsLoaded) {
                // get the people list
                this.subscriptions.add(this.apiPeopleService.records(
                    [{
                        limit: 100,
                        fields: 'firstName,lastName',
                        isArchived: this.actualModule.archive ? 1 : 0,
                        order: {lastName: 'asc'}
                    }]).subscribe(
                    (res) => {
                        this.recordsLoaded = res.count;
                        res.data.forEach(element => {
                            const name = element.lastName + ' ' + element.firstName;
                            // fill the people array excluding the duplicated names
                            const alreadyExistsIdx = this.items.findIndex(el => el.name === name);
                            if (alreadyExistsIdx === -1) {
                                this.items.push({
                                    name: name,
                                    id: [element.id]
                                });
                            } else {
                                this.items[alreadyExistsIdx].id.push(element.id);
                            }
                        });
                        this.changeDetectorRef.markForCheck();
                    }
                ));
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['clear']) {
            this.clearSearch();
        }
        if (this[this.moduleService]) {
            if (this[this.moduleService].sideBarStatus !== 'searchFilters' || this.switchOff) {
                this.filterPanelState = false;
            }
            if (this[this.moduleService].sideBarStatus === 'searchFilters') {
                this.filterPanelState = true;
            }
        }

    }

    selectedItem(item) {
        let searchString = 'idList=' + item.item.id.join(',');
        this.searchTerm = this.actual !== '' && this.enterButtonPressed ? this.actual : searchString;
        this.searchEvent.emit(this.searchTerm);

        if (this.searchTerm === searchString) {
            this.searchTerm = item.value;
        }

        this.onBlurSearch();
        this.actual = this.searchTerm;
        this.enterButtonPressed = false;
    }

    filterPanelToggle() {
        this.filterPanelState = !this.filterPanelState;
        this.filterPanel.emit(this.filterPanelState);
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.searchEvent.emit('');
    }

    onBlurSearch() {
        let tableHead = document.getElementsByClassName('mat-header-row');
        if (tableHead && tableHead[0]) {
            let headClass = tableHead[0].className;
            headClass = headClass.replace(' search', '');
            tableHead[0].className = headClass;
        }
    }

    onSearch(event, searchTerm, which) {
        let tableHead = document.getElementsByClassName('mat-header-row');
        if (tableHead && tableHead[0]) {
            let headClass = tableHead[0].className;
            if (!headClass.includes('search')) {
                tableHead[0].className = headClass + ' search';
            }
        }

        if (event.keyCode === 13) {
            this.enterButtonPressed = true;
        }
        if (which === 'return' && event.keyCode === 13) {
            this.searchEvent.emit(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.searchEvent.emit(searchTerm);
            this.actual = searchTerm;
        }
    }

    ngOnDestroy() {
        this.actual = '';
        this.searchTerm = '';
        // this.searchEvent.emit('');
        this.subscriptions.unsubscribe();
    }

}


