import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from '../../../shared/misc/text.helper';

@Component({
    selector: 'app-administration-sidebar',
    templateUrl: './administration-sidebar.component.html',
    styleUrls: ['./administration-sidebar.component.scss']
})
export class AdministrationSidebarComponent implements OnInit, OnChanges {

    @Input() clientToDisplay: any;
    @Input() width = '';
    @Input() config: any = 'list';

    @Output() adjustmentCreditsModal = new EventEmitter();

    action: any;

    clientTypeName: any = {};

    // users: any = {};
    userNum = 0;
    principalAdmins: any = {};
    titleSep: string;
    informationVisibility = true;

    constructor(private router: Router,
                private apiAdministration: ApiAdministrationService,
                private changeDetectorRef: ChangeDetectorRef,
                private stateService: StateService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.informationVisibility = (window.innerHeight < 725);
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);

        this.translateService.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    ngOnChanges() {
        if (this.clientToDisplay) {
            this.setClientType(this.clientToDisplay.accountType);
            this.usersLoad();
        }

    }

    usersLoad() {
        let params: any = {accountNumber: [this.clientToDisplay.accountNumber]};
        this.apiAdministration.users([params]).subscribe(res => {
            // this.users = res;
            let num = 0;
            for (let user of res) {
                if (user.roles.indexOf('ROLE_MAIN_ADMIN') > -1) {
                    this.principalAdmins.push(user);
                }
                num++;
            }
            this.userNum = num;
            this.changeDetectorRef.markForCheck();
        });
        this.changeDetectorRef.markForCheck();
    }

    canExtendDemo() {
        // Only super admin can create client
        const permissions = this.stateService.session.sessionData.permissions;
        return !!(permissions && permissions.roles.includes('ROLE_SUPER_ADMIN'));

    }

    canAdjust() {
        // Only super admin can create client
        const permissions = this.stateService.session.sessionData.permissions;
        return !!(permissions && permissions.roles.includes('ROLE_SUPER_ADMIN'));

    }

    notSpecified() {
        if (this.translateService.currentLang === 'fr') {
            return 'Non spécifié';
        } else {
            return 'Not specified';
        }
    }

    subAccountNavigation(id) {
        this.router.navigateByUrl('admin/account/' + this.clientToDisplay.id + '/subaccount/' + id);
    }

    principalAdminsNavigation(id) {
        this.router.navigateByUrl('admin/account/' + this.clientToDisplay.id + '/user/' + id);
    }

    navToUsers() {
        this.router.navigateByUrl('admin/account/' + this.clientToDisplay.id + '#users');
    }

    toggleInformationVisibility(newVisibility) {
        this.informationVisibility = !(newVisibility === 'show');
    }

    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

    act() {
        switch (this.action) {
            case 'ajustCredits':
                this.activateAdjustmentCreditsModal();
                break;
            case 'extendDemo':
                // TODO: no method here.
                break;
        }
    }

    activateAdjustmentCreditsModal() {
        this.adjustmentCreditsModal.emit();
    }

    setClientType(accountType) {
        switch (accountType) {
            case 7:
                this.clientTypeName.lowCap = 'démo';
                this.clientTypeName.cap = 'Démo';
                break;
            case 5:
                this.clientTypeName.lowCap = 'client';
                this.clientTypeName.cap = 'Client';
                break;
            case 4:
                this.clientTypeName.lowCap = 'consultant';
                this.clientTypeName.cap = 'Consultant';
                break;
            case 3:
                this.clientTypeName.lowCap = 'partenaire';
                this.clientTypeName.cap = 'Partenaire';
                break;
            case 2:
                this.clientTypeName.lowCap = 'distributeur';
                this.clientTypeName.cap = 'Distributeur';
                break;
        }
    }

    goToNews() {
        this.stateService.navigate(
            this.router,
            'admin/list',
            'setSideBarStatus',
            {
                sideBarStatus: 'recentActivities'
            }
        );
    }

    goTofullClient() {
        this.stateService.navigate(
            this.router,
            'admin/account',
            'fullClient',
            {
                clientToDisplayId: this.clientToDisplay.id
            }
        );
    }

    get state() {
        return this.stateService.admin;
    }

}
