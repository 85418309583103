import * as _ from 'lodash';

export abstract class FormsHelper {
    // Commons
    static permissions = [
        {
            fieldsName: 'mpoPersonality',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'mpo'
        },
        {
            fieldsName: 'mpoCommunication',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'dit'
        },
        {
            fieldsName: 'mpoTalents',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'talents'
        },
        {
            fieldsName: 'mpoSatellite',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'satellite'
        },
        {
            fieldsName: 'cai',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'ra'
        },
        {
            fieldsName: 'jil',
            fieldsType: 'checkbox',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'jil'
        }
    ];

    static setCheckboxText(fieldsName, value) {
        if (['mpoPersonality', 'mpoCommunication', 'mpoTalents', 'mpoSatellite', 'cai', 'jil'].includes(fieldsName)) {
            return {
                fr: (value === true) ? 'Oui' : 'Non',
                en: (value === true) ? 'Yes' : 'No'
            };
        }
    }

    static addressInfo = [
        {
            fieldsName: 'address',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'city',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'zipcode',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: 'CA H0H 0H0 US 12345, FR 00001'
        },
        {
            fieldsName: 'region',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'country',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        }
    ];

    static fieldsName(arrayOfObjects) {
        return arrayOfObjects.map(
            item => item?.fieldsName
        );
    }

    static dateFormatFromArray(date, lang, locale: any = false) {
        let datePre;

        if (locale !== false) {
            datePre = new Date(date.sec * 1000)
                .toLocaleString(locale).split(', ');
            if (datePre.length === 1) {
                datePre = new Date(date.sec * 1000)
                    .toLocaleString(locale).split(' ');
            }
        } else {
            datePre = date.split('-');
        }
        if (lang === 'fr') {
            return datePre[2] + '/' + datePre[1] + '/' + datePre[0];
        } else {
            return datePre[1] + '/' + datePre[2] + '/' + datePre[0];
        }
    }

    static twoColumnSplit(items, howMuchItemsCol1, howMuchItemsCol2) {
        let splittedItems = [
            [],
            []
        ];
        for (let i = 0; i < howMuchItemsCol1; i++) {
            splittedItems[0].push(items[i]);
        }
        let itemIndex;
        for (let j = 0; j < howMuchItemsCol2; j++) {
            itemIndex = j + howMuchItemsCol1;
            splittedItems[1].push(items[itemIndex]);
        }
        return splittedItems;
    }

    static getDbFieldsName(group, currentFieldsName) {
        if (currentFieldsName) {
            let fieldsName = currentFieldsName;
            let o = _.find(this[group], {fieldsName: fieldsName});
            if (o.dbFieldsName !== undefined && o.dbFieldsName !== fieldsName) {
                fieldsName = o.dbFieldsName;
            }
            return fieldsName;
        }
    }

    static getDropdownTextByValue(group, fieldsName, value) {
        let fieldsTexts = _.find(this[group], {fieldsName: fieldsName}).fieldsOptions.selectOptions;
        let dropDownOption = _.find(fieldsTexts, {value: value});
        return (dropDownOption) ? dropDownOption.text : null;
    }

    static convertValueToDataValue(fieldsName, value) {
        if (!isNaN(Number.parseInt(value, 10))) {
            value = Number.parseInt(value, 10);
        }

        if (this.isStringBool(value)) {
            value = (value === 'true');
        }

        if (fieldsName === 'accountType') {
            value = value.split('_')[0];
        }

        return value;
    }

    static isStringBool(value): boolean {
        return !!(value === 'true' || value === 'false');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Administration

    static headsPristine: any = {
        users: {
            fr: {
                createItemLabel: '+ Ajouter un utilisateur',
                listTitle: 'Utilisateurs',
                listTitles: ['Nom de l\'utilisateur', 'Nom du sous-compte principal', 'Type d\'utilisateur', 'Titre', 'Statut']
            },
            en: {
                createItemLabel: '+ Add a user',
                listTitle: 'Users',
                listTitles: ['Name of the user', 'Name of principal subaccount', 'User type', 'Title', 'Status']
            }
        },
        subAccounts: {
            fr: {
                createItemLabel: '+ Ajouter un sous-compte',
                listTitle: 'Sous-comptes',
                listTitles: ['Nom du sous-compte', 'Adresse', 'Téléphone', 'Crédits', 'Crée le']
            },
            en: {
                createItemLabel: '+ Add a subaccount',
                listTitle: 'Sub-accounts',
                listTitles: ['Name of the subaccount', 'Address', 'Phone number', 'Credits', 'Created on']
            }
        },
        files: {
            fr: {
                createItemLabel: '+ Ajouter un fichier',
                listTitle: 'Fichiers',
                listTitles: ['Type', 'Nom du fichier', 'Ajouté par', 'Date', 'Taille']
            },
            en: {
                createItemLabel: '+ Add a file',
                listTitle: 'Files',
                listTitles: ['Type', 'Name of file', 'Added by', 'Date', 'Size']
            }
        },
        purchases: {
            fr: {
                createItemLabel: '+ Ajouter un achat',
                listTitle: 'Achats',
                listTitles: ['Produits', 'Quantité', 'Date']
            },
            en: {
                createItemLabel: '+ Add a purchase',
                listTitle: 'Purchases',
                listTitles: ['Products', 'Quantity', 'Date']
            }
        },
        history: {
            fr: {
                listTitle: 'Historique',
                listTitles: ['Activités', 'Date']
            },
            en: {
                listTitle: 'History',
                listTitles: ['Activities', 'Date']
            }
        },
        department: {
            fr: {
                createItemLabel: '+ Ajouter un département',
                listTitle: 'Départements',
                listTitles: ['Nom']
            },
            en: {
                createItemLabel: '+ Add a department',
                listTitle: 'Departments',
                listTitles: ['Name']
            }
        }
    };

    // 'fieldsName', # Field's name used for exchange AND translation string as admin.fields. + fieldsName
    // 'fieldsType', # text, select, checkbox, multiselect-dropdown, date-picker etc...
    // 'fieldsExternalValue', # current value
    // 'editable', # can we edit or not
    // 'fieldsOptions', # options for select, multiselect etc.
    // 'validation', # validation message to display e.g., for user name
    // 'format', # format of field e.g., Zip: CA H0H 0H0 US 12345, FR 00001 | Phone: CA/US 555 555-5555, FR 5 55 55 55 55

    // Other fields are subAccounts fields by default
    static accountFields = ['parentAccountNumber', 'permissions', 'status', 'mpo', 'dit', 'satellite', 'ra', 'jil',
        'talents', 'accountType', 'expiresAt', 'discretionary', 'archive', 'adoptionDate', 'dropDateNote', 'dropDate', 'renewalDate', 'productCode', 'administrator'];

    // For special handling not necessary it seems..
    // static dateFields = ['expiresAt', 'adoptionDate', 'renewalDate', 'creationDate', 'clientSince', 'creationDemoDate'];

    static accountsMendatoryFields = ['name', 'accountType', 'parentAccountNumber'];

    static subAccountsMendatoryFields = ['name'];

    // New ///////////////////////////////////////
    // Account is this
    static newAccountInfo = ['subAccountName', 'phoneNumber', 'webSite', 'employeesNumber', 'accountType', 'parentAccountNumber'];
    // + address +
    static newProductInfo = ['adoptionDate', 'renewalDate'];
    // + permissions

    // Subaccount is this
    static newSubAccountInfo = ['subAccountName', 'phoneNumber', 'webSite', 'employeesNumber'];
    // + address

    //////////////////////////////////////////////

    static accountInfo = [
        {
            fieldsName: 'subAccountName',
            dbFieldsName: 'name',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: true
        },
        {
            fieldsName: 'accountName',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'creationDate',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'clientSince',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'phoneNumber',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: 'CA/US 555 555-5555, FR 5 55 55 55 55',
            subAccount: true
        },
        {
            fieldsName: 'webSite',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: true
        },
        {
            fieldsName: 'employeesNumber',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: true
        },
        {
            fieldsName: 'accountNumber',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'accountType',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: {
                default: 'blank',
                selectOptions: [
                    {
                        text: {
                            en: 'Subsidiary',
                            fr: 'Subsidiaire'
                        },
                        value: 'subsidiary',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Distributor',
                            fr: 'Distributeur'
                        },
                        value: 'distributor',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Partner',
                            fr: 'Partenaire'
                        },
                        value: 'partner',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Consultant',
                            fr: 'Consultant'
                        },
                        value: 'consultant',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Client',
                            fr: 'Client'
                        },
                        value: 'client',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Discretionary account',
                            fr: 'Compte discrétionnaire'
                        },
                        value: 'discretionary',
                        discretionary: true
                    },
                    {
                        text: {
                            en: 'Demo',
                            fr: 'Démo'
                        },
                        value: 'demo',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Business Development',
                            fr: 'Développement des affaires'
                        },
                        value: 'bizdev',
                        discretionary: false
                    },
                    {
                        text: {
                            en: 'Other',
                            fr: 'Autre'
                        },
                        value: 'other',
                        discretionary: false
                    }
                ]
            },
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'status',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: {
                default: 'blank',
                selectOptions: [
                    {
                        text: {
                            en: 'Active',
                            fr: 'Actif'
                        },
                        value: true
                    },
                    {
                        text: {
                            en: 'Inactive',
                            fr: 'Inactif'
                        },
                        value: false
                    }
                ]
            },
            validation: null,
            format: null,
            subAccount: true
        },
        {
            fieldsName: 'subsidiaryName',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'distributorName',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'partnerName',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false
        },
        {
            fieldsName: 'parentAccountNumber',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            subAccount: false,
        }
    ];

    static mpoProducts = [
        {
            fieldsName: 'creationDemoDate',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'adoptionDate',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'productCodes',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null,
            dbFieldsName: 'productCode'
        },
        {
            fieldsName: 'description',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'renewalDate',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'administrator',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'dropDate',
            fieldsType: 'date-picker',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'dropDateNote',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'mpoClient',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        }
    ];

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Users

    static userInfo = [
        {
            fieldsName: 'accountName',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'firstName',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'lastName',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'creationDate',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: false,
            validation: null,
            format: null
        },
        {
            fieldsName: 'userSince',
            fieldsType: null,
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: false,
            fieldsOptions: false,
            validation: null,
            format: null
        },
        {
            fieldsName: 'email',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'personalEmail',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'phoneNumber',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: 'CA/US 555 555-5555, FR 5 55 55 55 55'
        },
        {
            fieldsName: 'subAccountAccess',
            fieldsType: 'multiselect-dropdown',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'principalSubAccount',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'title',
            fieldsType: 'text',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        },
        {
            fieldsName: 'status',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            validation: null,
            format: null
        },
        {
            fieldsName: 'language',
            fieldsType: 'select',
            fieldsExternalValue: null,
            fieldsValueText: null,
            editable: true,
            fieldsOptions: null,
            validation: null,
            format: null
        }
    ];
}
