import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    IterableDiffers,
    OnInit
} from '@angular/core';
import {UserService} from 'src/app/core/services/user/user.service';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import {TranslateService} from '@ngx-translate/core';
import {ApiPeopleService} from '../../../services/api-people.service';
import {StateService} from '../../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PersonService} from '../../../services/person.service';
import {PeopleMultiselectService} from '../../../services/people-multiselect.service';

@Component({
    selector: 'app-people-selected-items',
    templateUrl: './people-selected-items.component.html',
    styleUrls: ['./people-selected-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSelectedItemsComponent extends ComponentSidebar implements OnInit {

    @Input() width = '';

    iterableDiffer: any;

    constructor(private apiPeople: ApiPeopleService,
                private _iterableDiffers: IterableDiffers,
                private changeDetectorRef: ChangeDetectorRef,
                public user: UserService,
                public stateService: StateService,
                public translateService: TranslateService,
                protected deviceService: DeviceDetectorService,
                protected person: PersonService,
                protected router: Router,
                public peopleMultiselect: PeopleMultiselectService
                ) {
        super(stateService, translateService, router, deviceService);
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.stateService[this.specificState()].multiselectWatch$.subscribe(
            res => {
                if (res) {
                    this.updateList(this.peopleMultiselect.selectedItems, 30);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    act() {
        if (this.peopleMultiselect.act() === 'goToNews') {
            this.goToNews();
        }
        this.peopleMultiselect.action = '';
    }

}
