import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MpoGraphComponent} from './mpo-graph/mpo-graph.component';
import {IdwGraphComponent} from './idw-graph/idw-graph.component';
import {TraitGraphComponent} from './trait-graph/trait-graph.component';
import {MiniMpoGraphComponent} from './mini-mpo-graph/mini-mpo-graph.component';
import {MiniIdwGraphComponent} from './mini-idw-graph/mini-idw-graph.component';
import {PeopleCardComponent} from './people-card/people-card.component';
import {PeopleCardNoReportComponent} from './people-card-no-report/people-card-no-report.component';
import {MiniCompetencesGraphComponent} from './mini-competences-graph/mini-competences-graph.component';
import {IdwMatrixComponent} from './idw-matrix/idw-matrix.component';
import {RightMatchScoreGraphComponent} from './right-match-score-graph/right-match-score-graph.component';
import {MiniPlaneComponent} from './mini-plane/mini-plane.component';
import { MiniRightMatchScaleComponent } from './mini-right-match-scale/mini-right-match-scale.component';
import { SatelliteScoreGraphComponent } from './satellite-score-graph/satellite-score-graph.component';
import { MiniSatelliteGraphComponent } from './mini-satellite-graph/mini-satellite-graph.component';
import { CompetencesGraphComponent } from './competences-graph/competences-graph.component';
import { TalentGraphComponent } from './talent-graph/talent-graph.component';
import { MiniIacGraphComponent } from './mini-iac-graph/mini-iac-graph.component';
import { IacGraphComponent } from './iac-graph/iac-graph.component';
import { IacIndividualTypeGraphComponent } from './iac-individual-type-graph/iac-individual-type-graph.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import { IacSidebarGraphComponent } from './iac-sidebar-graph/iac-sidebar-graph.component';
import { IndicatorGraphComponent } from './six-level-small-graph/indicator-graph.component';
import { JobSatisfactionsPersonHistoryGraphComponent} from './job-satisfactions-person-history-graph/job-satisfactions-person-history-graph.component';
import { JobSatisfactionsTeamGraphsComponent } from './job-satisfactions-team-graphs/job-satisfactions-team-graphs.component';
import { JobSatisfactionsPersonHistoryComponent } from './job-satisfactions-person-history/job-satisfactions-person-history.component';
import { JobSatisfactionsTeamGraphComponent } from './job-satisfactions-team-graph/job-satisfactions-team-graph.component';

@NgModule({
    imports: [
        CommonModule,
        TooltipModule.forRoot(),
        TranslateModule
    ],
    exports: [
        MpoGraphComponent, IdwGraphComponent, TraitGraphComponent, MiniMpoGraphComponent,
        MiniIdwGraphComponent, PeopleCardComponent, IdwMatrixComponent,
        PeopleCardNoReportComponent, RightMatchScoreGraphComponent, MiniRightMatchScaleComponent,
        SatelliteScoreGraphComponent, MiniSatelliteGraphComponent, CompetencesGraphComponent, MiniCompetencesGraphComponent, TalentGraphComponent,
        MiniIacGraphComponent, IacGraphComponent, IacIndividualTypeGraphComponent, IacSidebarGraphComponent, IndicatorGraphComponent, JobSatisfactionsPersonHistoryGraphComponent, JobSatisfactionsTeamGraphsComponent, JobSatisfactionsPersonHistoryComponent, JobSatisfactionsTeamGraphComponent
    ],
    declarations: [
        MpoGraphComponent, IdwGraphComponent, TraitGraphComponent, MiniMpoGraphComponent,
        MiniIdwGraphComponent, PeopleCardComponent, PeopleCardNoReportComponent,
        MiniCompetencesGraphComponent, IdwMatrixComponent, RightMatchScoreGraphComponent, MiniPlaneComponent, MiniRightMatchScaleComponent, SatelliteScoreGraphComponent, MiniSatelliteGraphComponent, CompetencesGraphComponent, TalentGraphComponent, MiniIacGraphComponent, IacGraphComponent, IacIndividualTypeGraphComponent, IacSidebarGraphComponent, IndicatorGraphComponent, JobSatisfactionsPersonHistoryGraphComponent, JobSatisfactionsTeamGraphsComponent, JobSatisfactionsPersonHistoryComponent, JobSatisfactionsTeamGraphComponent
    ]
})
export class GraphicsModule {
}
