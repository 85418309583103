export class ProfilerHelper {
    /**
     * Clean the profiler score
     */
    public static cleanScore(scores, local = false) {
        let numericScore: any = {};
        let anyScore = false;
        // tslint:disable-next-line:forin
        for (let score in scores) {
            if (scores.hasOwnProperty(score) && scores[score] !== undefined && scores[score] !== null) {
                anyScore = true;
                let alphaScore = '' + scores[score];
                let number = +alphaScore.replace(/\D/g, '');
                if (number > 10) {
                    number = 10;
                }
                numericScore[score] = number;
                if (!local) {
                    if (score === 'ES') {
                        score = 'SE';
                    }
                    if (score && document.getElementById('profiler' + score)) {
                        (<HTMLInputElement>document.getElementById('profiler' + score)).value = numericScore[score];
                    }
                }
            }
        }
        if (anyScore) {
            return numericScore;
        }
        return;
    }
}
