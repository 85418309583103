import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'momentDate'})
export class MomentDatePipe implements PipeTransform {
    defaultFormat = 'DD/MM/YYYY';

    transform(value: any, format?: string): any {
        // empty value
        if (!value) {
            return '';
        }

        format = (format) ? format : this.defaultFormat;
        return moment(value).format(format);
    }
}

@Pipe({name: 'momentDateLang'})
export class MomentDateLangPipe implements PipeTransform {
    defaultLang = 'fr';

    transform(value: any, lang?: string): any {
        // empty value
        if (!value) {
            return '';
        }

        lang = (lang) ? lang : this.defaultLang;

        // English
        if ('en' === lang) {
            return moment(value).format('MM/DD/YYYY');
        }

        return moment(value).format('DD/MM/YYYY');
    }
}
