import {Injectable} from '@angular/core';
import {ApiAdministrationService} from './api-administration.service';
import {StateService} from '../../core/services/state/state.service';
import {CanonicalizeHelper} from 'src/app/shared/helpers/canonicalize.helper';
import {AccountTypesHelper} from '../commons/AccountTypesHelper';

@Injectable()
export class MainListAdministrationService {

    constructor(
        private apiAdministrationService: ApiAdministrationService,
        private stateService: StateService
    ) {
    }

    getList(params, recordsLoaded, total, append = false) {
        let step = 100;

        let amountLoaded = (recordsLoaded + step > total && append) ? total - recordsLoaded : step;
        recordsLoaded = append ? recordsLoaded : 0;

        let request: any = {
            limit: amountLoaded,
            skip: recordsLoaded
        };
        let parameters = [
            'search',
            'accountTypes',
            'isArchived',
            'isEnabled',
            'accountNumberIsUpInHierarchy',
            'clientStartDate',
            'clientEndDate'
        ];


        let arrayParameters = [];
        for (let parameter of parameters) {
            if (
                params.listSearchFilters[parameter] !== null &&
                typeof params.listSearchFilters[parameter] !== 'undefined'
            ) {
                if (params.listSearchFilters[parameter] === true) {
                    request[parameter] = 1;
                } else if (params.listSearchFilters[parameter] === false) {
                    request[parameter] = 0;
                } else {
                    request[parameter] = params.listSearchFilters[parameter];
                }
            }
        }

        for (let parameter of arrayParameters) { // subAccount handling
            if (request[parameter] && request[parameter].length > 0) {
                request[parameter] = request[parameter].toString();
            } else {
                delete request[parameter];
            }
        }

        if (request.subAccount) {
            request.subAccount = request.subAccount.split(',');
        }

        // Build order parameter
        for (let index in params.listColumns) {
            if (params.listColumns.hasOwnProperty(index)) {
                if (params.listColumns[index] && params.listColumns[index]['order']) {
                    let orderObject = {};
                    orderObject[params.listColumns[index]['key']] = params.listColumns[index]['order'];
                    request['order'] = orderObject;
                }
            }
        }
        if (typeof request.department === 'string') {
            request.department = request.department.split(',');
        }

        request['types'] = request['accountTypes'];
        delete request['accountTypes'];

        request = CanonicalizeHelper.addSuffix('administration', request);

        request['selfExclude'] = 1;
        return {
            listObservable: this.apiAdministrationService.clients([request]),
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }

    convertPreferencesToAccountType(preferences) {
        let convertedPreferences = {
            en: [],
            fr: []
        };

        for (let preference of preferences) {
            convertedPreferences['en'].push(AccountTypesHelper.accountTypeNameByIdAndLanguage(AccountTypesHelper.accountNameByPermissionLevel(preference), 'en', 'object'));
            convertedPreferences['fr'].push(AccountTypesHelper.accountTypeNameByIdAndLanguage(AccountTypesHelper.accountNameByPermissionLevel(preference), 'fr', 'object'));
        }

        return convertedPreferences;
    }

    initLevels() {
        let accountTypeIntLevel = AccountTypesHelper.accountPermissionLevelByName[this.stateService.session.sessionData.permissions.accountType];

        let levels = {
            en: [],
            fr: []
        };

        if (accountTypeIntLevel === 0) {
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('subsidiary', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('subsidiary', 'fr', 'object'));
        }
        if (accountTypeIntLevel <= 1) {
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('distributor', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('distributor', 'fr', 'object'));
        }
        if (accountTypeIntLevel <= 2) {
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('partner', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('partner', 'fr', 'object'));
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('bizdev', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('bizdev', 'fr', 'object'));
        }
        if (accountTypeIntLevel <= 3) {
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('consultant', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('consultant', 'fr', 'object'));
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('other', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('other', 'fr', 'object'));
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('discretionary', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('discretionary', 'fr', 'object'));
        }
        if (accountTypeIntLevel <= 4) {
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('client', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('client', 'fr', 'object'));
            levels.en.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('demo', 'en', 'object'));
            levels.fr.push(AccountTypesHelper.accountTypeNameByIdAndLanguage('demo', 'fr', 'object'));
        }
        return levels;
    }

}
