import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy, OnChanges
} from '@angular/core';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {StateService} from 'src/app/core/services/state/state.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {RightMatchModalHandlingService} from '../../../services/right-match-modal-handling.service';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {ResponsiveHelper} from 'src/app/shared/helpers/responsive.helper';
import {ComponentFullView} from 'src/app/shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TalentsDefinitions} from '../../../../../shared/talents/talentsDefinitions';
import * as _ from 'lodash';
import {UserService} from '../../../../../core/services/user/user.service';

@Component({
    selector: 'app-right-matches-report',
    templateUrl: './right-matches-report.component.html',
    styleUrls: ['./right-matches-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    rightMatchModalStatus = 'edit';

    titleSep: string;
    reportWidth: string;
    sideWidth: string;
    graphWidth: string;
    scoreWidth: string;
    listViewDisplay = true;

    sortResult = 6;

    small = true;

    records = [];

    tabName = 'concordanceAnalysis';

    minimizeState = false;
    minimizeAll = false;

    private subscriptions = new Subscription();

    constructor(
        public rightMatch: RightMatchAnalysisService,
        private route: ActivatedRoute,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private apiRightMatches: ApiRightMatchesService,
        private changeDetectorRef: ChangeDetectorRef,
        public rightMatchModalHandlingService: RightMatchModalHandlingService,
        public translate: TranslateService,
        public stateService: StateService,
        public user: UserService
    ) {
        super(stateService, translate, router, deviceService);
    }

    get job() {
        return this.rightMatch.itemToDisplay.profiler;
    }

    ngOnInit() {
        console.log(this.job['RA']);
        // Preset display w.r.t available target.s
        this.stateService.rightMatch.displayPreferences.display.personality =
            (this.job['scores']); // ? false : this.stateService.rightMatch.displayPreferences.display.personality;
        this.stateService.rightMatch.displayPreferences.display.talents =
            ((_.size(this.job['competences'][0]) !== 0)); // ?
                // false : this.stateService.rightMatch.displayPreferences.display.talents;
        this.stateService.rightMatch.displayPreferences.display.cai =
            (this.job['RA'] !== false); // ? false : this.stateService.rightMatch.displayPreferences.display.cai;

        this.tabName = this.stateService.rightMatch.reportsTabName;
        this.small = ResponsiveHelper.isSmallSize(window.innerWidth);
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);

        // reload the Right Match context after coming back from jobs
        this.rightMatch.setItemToDisplayWithId(this.rightMatch.id);
        this.rightMatchState.goTo = null;


        if (this.rightMatch.itemToDisplay && !this.rightMatchState.report.reportType) {
            // Set 'analysis' tab to show by default
            this.switchEvaluationType('analysis');
        }
        // Set width
        this.computeWidths(window);

        this.computeSortAndDisplayValues();

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );

        let divs = Array.from(document.querySelectorAll('body > div:not(#menu_wrapper)')) as unknown as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style.overflowY = 'hidden';
        }
        if (document.getElementById('scrollBody') !== null) {
            document.getElementById('scrollBody').style.overflowY = 'scroll';
        }

        this.records = this.sortRecordsWithPrefs();

        this.stateService.rightMatch.stateChanged$.subscribe(
            (res) => {
                this.computeSortAndDisplayValues();
                this.records = this.sortRecordsWithPrefs();

                this.changeDetectorRef.markForCheck();
            }
        );

        this.minimizeState = this.stateService.rightMatch.minimizeCards;
        this.minimizeAll = this.stateService.rightMatch.minimizeCards;

        this.changeDetectorRef.markForCheck();
    }

    sortRecordsWithPrefs() {
        let sortBy = this.stateService.rightMatch.displayPreferences.sortBy;
        let removed = [];
        let records = [];
        // sort
        if (sortBy === 'cai') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].displayValues.cai.iacDeltaLevel)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }
            records = _.orderBy(
                records,
                ['displayValues.cai.iacDeltaLevel', 'lastName', 'firstName', 'id'],
                ['desc', 'asc', 'asc', 'asc']
            );
            records = records.concat(removed);
        } else if (sortBy === 'talents') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].displayValues.competencies.competenciesScore)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }

            records = _.orderBy(
                records,
                ['displayValues.competencies.competenciesScore', 'lastName', 'firstName', 'id'],
                ['desc', 'asc', 'asc', 'asc']
            );
            records = records.concat(removed);
        } else if (sortBy === 'personality') {
            for (let i in this.rightMatch.records) {
                if (this.checkIfValid(this.rightMatch.records[i].rightMatchScore)) {
                    removed.push(this.rightMatch.records[i]);
                } else {
                    records.push(this.rightMatch.records[i]);
                }
            }

            records = _.orderBy(
                records,
                ['rightMatchScore', 'lastName', 'firstName', 'id'],
                ['desc', 'asc', 'asc', 'asc']
            );

            records = records.concat(removed);
        }

        return records;
    }
    /**
    checkIfValidTalent(competencies) {
        return (
            (
                competencies.competenciesScore === null ||
                competencies.competenciesScore === undefined ||
                competencies.competenciesScore === false
            ) && (
                competencies.competenciesRedTotal === 0 ||
                competencies.competenciesRedTotal === null ||
                competencies.competenciesRedTotal === undefined ||
                competencies.competenciesRedTotal === false
            )
        );
    }**/

    checkIfValid(number) {
        return (
            number === null ||
            number === undefined ||
            number === false
        );
    }

    get displayValuesLoaded() {
        return (
            this.rightMatch.records === undefined ||
            (
            this.rightMatch.records &&
                (
                    this.rightMatch.records.length === 0 || (
                        this.rightMatch.records[0] &&
                        this.rightMatch.records[0].displayValues &&
                        this.rightMatch.records[0].displayValues.competencies
                    )
                )
            )
        );
    }

    computeSortAndDisplayValues() {
        if (this.rightMatch && this.rightMatch.records && this.rightMatch.records.length !== 0) {
            let records = JSON.parse(JSON.stringify(this.rightMatch.records));
            for (let i in records) {
                if (records[i]) {
                    let record = records[i];
                    let values = this.computeSortAndDisplayValuesForARecord(record, this.rightMatch.profilerInfo);
                    record['displayValues'] = {
                        competencies: values['competencies'],
                        cai: values['cai']
                    };
                    records[i] = record;
                }
            }
            this.rightMatch.records = records;
        }
        this.changeDetectorRef.markForCheck();
    }

    computeSortAndDisplayValuesForARecord(record, job) {
        let competenciesGreenTotal = null;
        let competenciesYellowTotal = null;
        let competenciesRedTotal = null;
        let competenciesScore = null;
        // Competencies
        for (let item of this.rightMatch.orderedTalents[this.translateService.currentLang]) {
            let score = (record['talents'][item[2]] !== null || record['talents'][item[2]] !== undefined) ?
                record['talents'][item[2]] :
                false;
            if (score) {
                if (item[1] === 1) {
                    if (score > 6) {
                        competenciesYellowTotal++;
                        competenciesScore++;
                    } else if (score >= 4 && score <= 6) {
                        competenciesGreenTotal++;
                        competenciesScore += 3;
                    } else if (score < 4) {
                        competenciesRedTotal++;
                        if (competenciesScore === null) {
                            competenciesScore = 0;
                        }
                    }
                } else if (item[1] === 2) {
                    if (score < 7) {
                        competenciesRedTotal++;
                        if (competenciesScore === null) {
                            competenciesScore = 0;
                        }
                    } else if (score >= 7) {
                        competenciesGreenTotal++;
                        competenciesScore += 3;
                    }
                }

            }
        }
        // CAI
        let iacColor = 'red';
        let iacDeltaLevel;
        if (job.RA && record.RA.scoreNormalized) {
            let delta = Math.abs(job.RA - record.RA.scoreNormalized);
            if (delta <= 1) {
                iacColor = 'green';
                iacDeltaLevel = 3;
            } else if (delta <= 2) {
                iacColor = 'yellow';
                iacDeltaLevel = 2;
            } else {
                iacColor = 'red';
                iacDeltaLevel = 1;
            }
        }
        return {
            competencies: {
                competenciesGreenTotal: competenciesGreenTotal,
                competenciesYellowTotal: competenciesYellowTotal,
                competenciesRedTotal: competenciesRedTotal,
                competenciesScore: competenciesScore
            },
            cai: {
                iacDeltaLevel: iacDeltaLevel,
                iacColor: iacColor
            }
        };
    }

    onResize(event) {
        // re-Set list width
        this.computeWidths(event.target);
        this.small = ResponsiveHelper.isSmallSize(event.target.innerWidth);
    }

    toggleListView() {
        this.listViewDisplay = !this.listViewDisplay;
        this.changeDetectorRef.markForCheck();
    }

    get displayPreferences() {
        return this.stateService.rightMatch.displayPreferences.display;
    }

    get listView() {
        let countActiveReport = 0;
        if (this.displayPreferences.cai === true) {
            countActiveReport++;
        }
        if (this.displayPreferences.personality === true) {
            countActiveReport++;
        }
        if (this.displayPreferences.talents === true) {
            countActiveReport++;
        }
        let moreThanOneReport = (countActiveReport > 1);
        if (moreThanOneReport) {
            return this.listViewDisplay;
        } else if (countActiveReport === 1) {
            return false;
        }
    }

    computeWidths(startWidth) {
        let adjustment = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.graphWidth = ((innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right) - 75 + 'px';
        this.scoreWidth = ((innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right) / 4 + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
        // @ilya 2020-10-16 - fix the side width to 400px
        this.sideWidth = '400px';
        this.reportWidth = (innerWidth - 505) + 'px';
        return;
    }

    switchEvaluationType(newEvaluation: string) {
        this.rightMatchState.report.reportType = newEvaluation;
    }

    sortResults(event) {
        this.sortResult = event;
        this.switchEvaluationType('analysis');
        this.changeDetectorRef.markForCheck();

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    switchTab(tabName) {
        this.tabName = tabName;
        this.stateService.rightMatch.reportsTabName = tabName;
        return null;
    }

    togglePersonsCards() {
        this.minimizeState = !this.minimizeAll;
        this.minimizeAll = !this.minimizeAll;
        this.stateService.rightMatch.minimizeCards = this.minimizeAll;
    }

    openRightMatchReportsFiltersModal() {
        this.rightMatchModalHandlingService.openRightMatchReportsFiltersModal().subscribe(
            res => {
                if (res.closed) {
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    get targetToDisplay() {
        let display = 0;

        if (this.stateService.rightMatch.displayPreferences.display.personality === true) {
            display++;
        }
        if (this.stateService.rightMatch.displayPreferences.display.talents === true) {
            display++;
        }
        if (this.stateService.rightMatch.displayPreferences.display.cai === true) {
            display++;
        }

        return display;
    }

    get numberOfLines() {
        let nol = 0;
        if (this.rightMatch &&
            this.rightMatch.records &&
            this.rightMatch.records.length
        ) {
            nol = Math.ceil(this.rightMatch.records.length / 3);
        }
        return nol;
    }

    get rightMatchState(): any {
        return this.stateService.rightMatch;
    }

}
