// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top {
  background-color: rgb(241, 241, 241);
  top: 0;
}

.listingCell {
  border-top: none;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/jobSatisfactions/components/web/job-satisfactions-selected-items/job-satisfactions-selected-items.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,MAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;AACJ","sourcesContent":[".top {\n    background-color: rgb(241, 241, 241);\n    top: 0;\n}\n\n.listingCell {\n    border-top: none;\n    padding:0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
