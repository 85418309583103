import * as _ from 'lodash';

export abstract class AbstractState {

    protected state = {};

    constructor() {
    }

    /**
     * Given a state key and a value val, if storage is available,
     * JSON.stringigy val and store it.
     *
     * @param module
     * @param key - the key, must be of the EXACT form "substate.key" e.g. "people.personToDisplay"
     * @param val
     */

    protected stateStore<T, U>(module: string, key: string, val: U): void {
        // if (typeof(Storage) !== "undefined" && val)
        if (typeof(Storage) !== 'undefined'/* && val*/) {
            let storeLabel = module + '.' + key;
            this['state'][key] = val;
            sessionStorage[storeLabel] = JSON.stringify(val);
        }
    }

    /**
     * Given a key, if there is a value in store, set the BehaviorSubect's value with JSON.parse.
     *
     * @param key
     */
    protected retrieveStore(key: string): void {
        if (typeof(Storage) !== 'undefined') {
            let keyArr = key.split('.');
            if (sessionStorage.getItem(key)) {
                if (!(keyArr.length === 2)) {
                    throw 'In /core/services/state/state.retrieveStore :: ' + key + ' is not of the form module.key.';
                }
                _.set(this, 'state.' + keyArr[1], (JSON.parse(sessionStorage.getItem(key))));
            }
        }
    }

    /**
     * Checks validity of the sent params and process data if needed.
     *
     * @param action
     * @param params
     * @param modState
     * @param url
     */
    protected actionPreProcessing(action, params, modState, url = null) {
    }

    /**
     * Partition state's parameter in function of the curent URL definitions i.e. if parameter x
     * is not required on URL y, then don't emit it, else do so.
     *
     * @param url
     * @param params
     */
    protected urlProcessing(url, params) {
    }

    /**
     * Replace old state with the new state. Perform extra operations if needed.
     *
     * @param type
     * @param param
     * @param value
     */
    protected actionPostProcessing(type, param, value = null) {
    }
}
