import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {ApiGetNewsService} from '../../services/api-get-news.service';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ApiPostNewsService} from '../../services/api-post-news.service';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {Subscription} from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true',  style({ opacity: '1' })),
            state('false', style({ opacity: '0' })),
            transition('0 <=> 1', animate('350ms ease'))
        ]),
        trigger('displayChanged', [
            state('true',  style({ display: 'block' })),
            state('false', style({ display: 'none'  })),
            transition('0 => 1', animate('0ms ease')),
            transition('1 => 0', animate('0ms 350ms ease'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NewsComponent implements OnInit, OnChanges, OnDestroy {

    news: any;
    dateFormat: string;
    // versions: Array<string> = [];

    visible = false;
    visibleFirst = true;

    lastNewsId: string;

    @Input() showNewsPanel;
    @Output() closeNews = new EventEmitter();

    private subscriptions = new Subscription();

    constructor(
        private apiGetNewsService: ApiGetNewsService,
        private apiPostNewsService: ApiPostNewsService,
        public translateService: TranslateService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.visibleFirst) {
            this.visible = !this.visible;
        } else {
            this.visibleFirst = false;
        }
    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.fetchNews();
        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            (e) => {
                this.dateFormat = TextHelper.dateFormat(e.lang);
                this.fetchNews(e.lang);
            })
        );
    }

    fetchNews(lang = this.translateService.currentLang) {
        const today = new Date();
        const yearAgo = moment(today).subtract(1, 'year').format('YYYY-MM-DD').toString();
        this.apiGetNewsService.news_messages([lang, null, null, yearAgo]).subscribe(
            res => {
                this.visible = res.display;
                let news = res.data.sort((a, b) => (a.version <= b.version) ? 1 : ((b.version <= a.version) ? -1 : 0));
                if (news.length > 0) {
                    let organizedNews = {
                        versions: [{version: news[0].version, creationDate: news[0].creationDate}],
                        newsItems: [[]]
                    };
                    // console.log(news);
                    this.lastNewsId = news[news.length - 1].id;
                    for (let newsItem in news) {
                        if (news.hasOwnProperty(newsItem)) {
                            if (organizedNews.versions[organizedNews.versions.length - 1] &&
                                organizedNews.versions[organizedNews.versions.length - 1].version !== news[newsItem].version) {
                                    organizedNews.versions.push({version: news[newsItem].version, creationDate: news[newsItem].creationDate});
                                    organizedNews.newsItems[organizedNews.newsItems.length] = [];
                            }
                            if (news[newsItem].message) {
                                organizedNews.newsItems[organizedNews.newsItems.length - 1].push(news[newsItem].message);
                            }
                        }
                    }
                    this.news = organizedNews;
                }
                this.cd.markForCheck();
            }
        );
    }

    closeNewsWindow() {
        if (this.lastNewsId !== null && typeof this.lastNewsId !== 'undefined') {
            let userdateMessageSubscription: Subscription = this.apiPostNewsService.news_userdateMessage([this.lastNewsId])
                .subscribe(
                    () => {
                        userdateMessageSubscription.unsubscribe();
                    }
                );
        }
        // this.api
        this.visible = false;
        this.closeNews.emit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
