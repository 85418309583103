// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftContent, .rightContent {
  width: 50%;
}

.leftContent {
  padding-right: 7px;
}

.rightContent {
  padding: 0 7px;
}

.creditBox {
  padding-left: 40px;
  height: 140px;
  float: right !important;
}
@media screen and (max-width: 1242px) {
  .creditBox {
    width: 100%;
    padding: 0 40px 0 0;
    margin: 0 0 15px 0;
  }
}

.creditBoxIn {
  width: 90px;
}

.wantedReport {
  float: left !important;
}

::ng-deep .noRequester {
  color: rgb(240, 123, 126);
  font-size: 1rem;
}

:host ::ng-deep label {
  margin: 10px auto 0 auto;
}

:host ::ng-deep label.label-first {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/request-form/request-form.component.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;AADJ;;AAGA;EACI,kBAAA;AAAJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EAJJ;IAKQ,WAAA;IACA,mBAAA;IACA,kBAAA;EAGN;AACF;;AAAA;EACI,WAAA;AAGJ;;AAAA;EACI,sBAAA;AAGJ;;AAAA;EACI,yBAAA;EACA,eAAA;AAGJ;;AAAA;EACI,wBAAA;AAGJ;;AAAA;EACI,aAAA;AAGJ","sourcesContent":["@use \"src/responsive\" as responsive;\n\n.leftContent, .rightContent {\n    width: 50%;\n}\n.leftContent {\n    padding-right: 7px;\n}\n.rightContent {\n    padding: 0 7px;\n}\n\n.creditBox {\n    padding-left: 40px;\n    height: 140px;\n    float: right !important;\n    @media screen and ( max-width: 1242px ) {\n        width: 100%;\n        padding: 0 40px 0 0;\n        margin: 0 0 15px 0;\n    }\n}\n\n.creditBoxIn {\n    width: 90px;\n}\n\n.wantedReport {\n    float: left !important;\n}\n\n::ng-deep .noRequester {\n    color: rgb(240, 123, 126);\n    font-size: 1rem;\n}\n\n:host ::ng-deep label {\n    margin: 10px auto 0 auto;\n}\n\n:host ::ng-deep label.label-first {\n    margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
