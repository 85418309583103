import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {CdkDragDrop, CdkDragStart, moveItemInArray} from '@angular/cdk/drag-drop';
import {StateService} from 'src/app/core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {UserService} from '../../../../../core/services/user/user.service';

@Component({
    selector: 'app-mobile-main-list',
    templateUrl: './mobile-main-list.component.html',
    styleUrls: ['./mobile-main-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMainListComponent implements AfterViewInit, OnChanges {

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() listsData; // The results got from GET /api/records
    @Input() orderedBy;
    @Input() selectedItemId: string;
    @Input() currentLang = 'fr';
    @Input() tableDefinition: any; // provided by the state
    @Input() sectionName = 'people'; // possible values: 'analysis', 'jobs', 'archive' etc.
    @Input() subSection: string; // possible values: 'rightMatch', 'jobSatisfaction', 'idwMatrix', 'graphboard'

    @Output() listScrolled = new EventEmitter(); // signal the list has been scrolled for the infinite scroll
    @Output() reordering = new EventEmitter();
    @Output() selectedItem = new EventEmitter();
    @Output() reportsActivation = new EventEmitter;

    columns: any[] = [
        { field: 'status' },
        { field: 'fullName' },
        { field: 'creationDate' }
    ];
    displayedColumns: string[] = [];
    dataTable;
    firstNameTitle;
    lastNameTitle;
    nameTitle;
    dateTitle;
    jobTitle;
    locationTitle;
    accountTypeTitle;
    previousIndex: number;
    // order the table by the creationDate column from recent to old by default
    // it will be rewritten if orderedBy state object contains the last ordered column
    matSortActive = 'creationDate';
    matSortDirection: 'desc' | 'asc' | '' = 'desc';

    selectedItems = [];

    constructor(
        private translate: TranslateService,
        private stateService: StateService,
        private userService: UserService,
        public cd: ChangeDetectorRef
    ) {
        let statusIndex = this.columns.findIndex(col => col.field === 'status');
        if (statusIndex === 0) {
            let statusElem = this.columns.shift();
            this.columns.push(statusElem);
        }

        statusIndex = this.columns.findIndex(col => col.field === 'status');
        let statusPrbIndex = -1;
        if (statusIndex > -1) {
            let prbStatusIndex = statusIndex + 1;
            this.columns.splice(prbStatusIndex, 0, {field: 'statusPrb'});
            statusPrbIndex = this.columns.findIndex(col => col.field === 'statusPrb');
        }

        const statusIacInList = this.state.listColumns.filter(col => (col.key === 'statusIac' && col.display === true));
        if (statusPrbIndex > -1 && statusIacInList) {
            let iacStatusIndex = statusPrbIndex + 1;
            this.columns.splice(iacStatusIndex, 0, { field: 'statusIac' });
        }
    }

    accountType(item) {
        switch (item) {
            case 7:
                return this.translate.instant('admin.commons.demo');
            case 5:
                return this.translate.instant('admin.commons.client');
            case 4:
                return this.translate.instant('admin.commons.consultant');
            case 3:
                return this.translate.instant('admin.commons.partner');
            case 2:
                return this.translate.instant('admin.commons.distributor');
            case 1:
                return this.translate.instant('admin.commons.subsidiaryAccountType');
        }
    }

    statusIcon(obj, key) {
        let statusTxt;
        if (obj.testStatuses && obj.testStatuses[key]) {
            statusTxt = obj.testStatuses[key];
        }
        if (key === 'prb' && obj.testStatuses && obj.testStatuses.mpo === 'H') {
            statusTxt = 'H';
        }
        switch (statusTxt) {
            case 'NC':
                return 'darkGrayIcon';
            case 'C':
                return 'orangeIcon';
            default:
                return 'grayIcon';
        }
    }

    statusAbr(obj, key) {
        let statusTxt;
        if (obj.testStatuses && obj.testStatuses[key]) {
            statusTxt = obj.testStatuses[key];
        }
        if (key === 'prb' && obj.testStatuses && obj.testStatuses.mpo === 'H') {
            statusTxt = 'H';
        }
        switch (statusTxt) {
            case 'NC':
                return 'NC';
            case 'C':
                return 'C';
            case 'N':
                return this.translate.instant('commons.noneAbr');
            default:
                return this.translate.instant('commons.onHoldAbr');
        }
    }

    onSelectItem(item) {
        this.selectedItemId = item[0].id;
        this.selectedItem.emit(item);
    }

    isSelected(item) {
        return (this.selectedItems.indexOf(item) > -1);
    }

    ngAfterViewInit() {

        if (this.sectionName === 'analysis') {
            this.columns = [
                { field: 'name' },
                { field: 'date' }
            ];
            this.displayedColumns = ['name', 'date'];
        }
        if (this.sectionName === 'jobs') {
            this.columns = [
                { field: 'jobTitle' },
                { field: 'location' },
                { field: 'date' }
            ];
            this.displayedColumns = ['jobTitle', 'location', 'date'];
        }
        if (this.sectionName === 'admin') {
            this.columns = [
                { field: 'subAccounts[0].name' },
                { field: 'accountType' },
                { field: 'creationDate' }
            ];
            this.displayedColumns = ['subAccounts[0].name', 'accountType', 'creationDate'];
        }
        this.dataTable = new MatTableDataSource(this.listsData.data);
        this.dataTable.sort = this.sort;
    }

    getFullName() {
        let peopleData = this.listsData.data;
        peopleData.forEach(person => {
            person.fullName = person.lastName + ' ' + person.firstName;
        });
    }

    setDisplayedColumns() {
        this.columns.forEach(( col, index) => {
            col.index = index;
            this.displayedColumns[index] = col.field;
        });
    }

    // dragStarted(event: CdkDragStart, index: number ) {
    //     this.previousIndex = index;
    // }

    // dropListDropped(event: CdkDragDrop<any>, index: number) {
    //     if (event) {
    //         moveItemInArray(this.columns, this.previousIndex, index);
    //         this.setDisplayedColumns();
    //         this.state.columnsOrder = this.columns;
    //     }
    // }

    getDefinitionTitle(keyValue) {
        const obj = this.tableDefinition.find(el => el.key === keyValue);
        if (obj) {
            if (obj.title === undefined && obj.key === 'statusIac') {
                obj.title = this.translate.instant('commons.cai');
            }
        }
        return obj ? obj.title : null;
    }

    ngOnChanges(changes) {

        if (this.state && this.state.columnsOrder) {
            this.columns = this.state.columnsOrder;
        }
        if (this.sectionName === 'people') {
            this.getFullName();
        }

        this.setDisplayedColumns();

        this.dataTable = new MatTableDataSource(this.listsData.data);
        this.dataTable.sort = this.sort;
        if (this.tableDefinition) {
            if (this.tableDefinition.filter(col => col.key === 'firstName').length > 0) {
                this.firstNameTitle = this.tableDefinition.filter(col => col.key === 'firstName')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'lastName').length > 0) {
                this.lastNameTitle = this.tableDefinition.filter(col => col.key === 'lastName')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'name').length > 0) {
                this.nameTitle = this.tableDefinition.filter(col => col.key === 'name')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'creationDate').length > 0) {
                this.dateTitle = this.tableDefinition.filter(col => col.key === 'creationDate')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'date').length > 0) {
                this.dateTitle = this.tableDefinition.filter(col => col.key === 'date')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'jobTitle').length > 0) {
                this.jobTitle = this.tableDefinition.filter(col => col.key === 'jobTitle')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'location').length > 0) {
                this.locationTitle = this.tableDefinition.filter(col => col.key === 'location')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'subAccounts[0].name').length > 0) {
                this.nameTitle = this.tableDefinition.filter(col => col.key === 'subAccounts[0].name')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'accountType').length > 0) {
                this.accountTypeTitle = this.tableDefinition.filter(col => col.key === 'accountType')[0].title;
            }
            if (this.tableDefinition.filter(col => col.key === 'subAccounts[0].creationDate').length > 0) {
                this.dateTitle = this.tableDefinition.filter(col => col.key === 'subAccounts[0].creationDate')[0].title;
            }
        }

        // show the ordered column (key and order) from state if it exists
        if (this.orderedBy[0] && this.orderedBy[0].key) {
            let activeKey = this.orderedBy[0].key;
            if (activeKey === 'lastName') {
                activeKey = 'fullName';
            }
            this.matSortActive = activeKey;
        }
        if (this.orderedBy[0] && this.orderedBy[0].order) {
            this.matSortDirection = this.orderedBy[0].order;
        }

        if (!this.selectedItemId && changes && changes.selectedItemId && changes.selectedItemId.currentValue) {
            this.selectedItemId = changes.selectedItemId.currentValue;
        }
    }

    loGet(obj, key) {
        if (key === 'department') {
            return (obj[key] && obj[key].length > 0) ? obj[key][0][this.currentLang] : '';
        } else if (key === 'accountType') {
            return this.accountType(_.get(obj, key));
        } else {
            return _.get(obj, key);
        }
    }

    clear(rowKey) {
        if (rowKey === 'recordType') {
            return 'recordTypeTxt';
        } else if (rowKey === 'status') {
            return 'statusTxt';
        } else {
            return rowKey;
        }
    }

    admData(item) {
        let dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])$/;
        let dateRegex1 = /^([0-3][0-9])\/([0-1][0-9])\/([0-9]{4})$/;
        if (dateRegex.test(item.creationDate) || dateRegex1.test(item.creationDate)) {
            return item.creationDate;
        }
        return;
    }

    sortColumn(sort: Sort) {
        if (sort.direction === '') {
            // default sorting by date if we cancel any other sorting
            if (this.sectionName === 'people' || this.sectionName === 'admin') {
                this.order('creationDate', 'desc');
            } else {
                this.order('date', 'desc');
            }
        } else {
            if (sort.active === 'fullName') {
                this.order('lastName', sort.direction);
            } else {
                this.order(sort.active, sort.direction);
            }
        }
    }

    canSort(item) {
        if (item !== 'subAccountName' &&
            item !== 'evaluations.mpo.info.status' &&
            item !== 'evaluations.dit.info.status' &&
            item !== 'evaluations.talents.info.status' &&
            item !== 'evaluations.competences.info.status' &&
            item !== 'evaluations.satellite.info.status' &&
            item !== 'evaluations.mpo.info.type' &&
            item !== 'totalCredits' &&
            item !== 'hierarchy[\'partner\'].accountName' &&
            item !== 'hierarchy[\'distributor\'].accountName' &&
            item !== 'status' &&
            item !== 'statusIac' &&
            item !== 'department' &&
            item !== 'accountType'
        ) {
            return true;
        }
        return;
    }

    order(key, order) {
        let event = {key: key, order: order};
        this.reordering.emit(event);
    }

    get state() {
        if (this.sectionName === 'analysis' && this.subSection) {
            return this.stateService[this.subSection];
        }
        return this.stateService[this.sectionName];
    }
}
