import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Rest} from 'src/app/core/services/api/rest';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {EnvService} from '../../../app/services/env.service';

@Injectable()
export class ApiIdwMatricesService extends Rest {
    constructor(
        protected http: HttpClient,
        private translate: TranslateService,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    ditGrams(dataParams: Array<any>): Observable<any> {
        dataParams[0]['lang'] = this.translate.currentLang;
        return this.apiGet(
            'ditgrams',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    ditGram(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'ditgram',
            urlParams,
            null,
            null
        );
    }

    reportDitgram<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/ditgram',
            urlParams,
            dataParams,
            ['parts', 'language']
        );
    }

    reportEmail(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'email/report',
            null,
            dataParams,
            ['emailTo', 'subject', 'message', 'pdfLink', 'name', 'origin', 'lang']
        );
    }

    putDitGram(urlParams: [string], item: any): Observable<any> {
        return this.apiPut(
            'ditgram',
            urlParams,
            item
        );
    }

    postDitGram(data): Observable<any> {
        return this.apiPost(
            'ditgram',
            null,
            data
        );
    }

    deleteDitGrams(idList: string[]): Observable<any> {
        return this.apiPost(
            'ditgrams/delete',
            null,
            {idList: idList}
        );
    }

    deleteDitGram(urlParams: Array<string>): Observable<any> {
        return this.apiDelete(
            'ditgram',
            urlParams
        );
    }
}
