import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JobService} from '../../../../../jobs/services/job.service';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {ApiJobsService} from '../../../../../jobs/services/api-jobs.service';

@Component({
    selector: 'app-right-matches-report-job-iac',
    templateUrl: './right-matches-report-job-iac.component.html',
    styleUrls: ['./right-matches-report-job-iac.component.scss']
})
export class RightMatchesReportJobIacComponent implements OnInit {
    @Input() currentLang = 'fr';
    @Output() openModRa = new EventEmitter();
    titleSep: string;
    dateFormat: string;
    raTexts;

    raTextsFetched;

    constructor(
        public jobService: JobService,
        public rightMatchService: RightMatchAnalysisService,
        private translate: TranslateService,
        private apiJobService: ApiJobsService,
        public cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.apiJobService.jobRaTexts().subscribe(
            (res) => {
                this.raTextsFetched = res['RAtexts'];
                let score = this.rightMatchService.profilerInfo.RA;
                this.raTexts = {
                    'env': this.raTextsFetched['env'][score],
                    'job': this.raTextsFetched['job'][score]
                };
                this.cd.markForCheck();
            }
        );
        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

}
