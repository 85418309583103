import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({
    selector: '[appCheckboxInput]'
})
export class CheckboxInputDirective implements OnInit {

    @Input() definition: any;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        // Seems like the formControlName attribute must be declared directly in the template
        this.el.nativeElement.type = this.definition.display.type;
        this.el.nativeElement.name = this.definition.name;
        let properties = ['checked', 'disabled', 'value'];
        properties.forEach(elt => {
            return (this.definition.display.properties &&
            this.definition.display.properties[elt] !== undefined) ?
                this.el.nativeElement[elt] = this.definition.display.properties[elt] :
                null;
        });
    }
}