// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftContent, .rightContent {
  width: 50%;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  background-color: #EEE;
}

.modal-body {
  padding-top: 20px;
  background-color: #EEE;
}

.search-wrap {
  width: 100%;
  position: relative;
}

.search-wrap img {
  position: absolute;
  top: 6px;
  right: 9px;
}

.search-wrap .closeX {
  top: 8px;
  opacity: 0.8;
  cursor: pointer;
}

.search-wrap .closeX:hover {
  opacity: 1;
}

.clickSaveToConfirm {
  padding-top: 10px;
}

.modalButton:disabled {
  background-color: #aaaaaa;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-multiselect-action/people-multiselect-action.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AACJ;;AAEA;EACI,QAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".leftContent, .rightContent {\n    width: 50%;\n}\n\n.pointer {\n    cursor: pointer;\n}\n\n.pointer:hover {\n    background-color: #EEE;\n}\n\n.modal-body {\n    padding-top: 20px;\n    background-color: #EEE;\n}\n\n.search-wrap {\n    width: 100%;\n    position: relative;\n}\n\n.search-wrap img {\n    position: absolute;\n    top: 6px;\n    right: 9px;\n}\n\n.search-wrap .closeX {\n    top: 8px;\n    opacity: 0.8;\n    cursor: pointer;\n}\n\n.search-wrap .closeX:hover {\n    opacity: 1;\n}\n\n.clickSaveToConfirm {\n    padding-top: 10px;\n}\n\n.modalButton:disabled {\n    background-color: #aaaaaa;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
