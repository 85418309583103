export class ResponsiveHelper {
    public static computeListWidth(innerWidth: number, displayConstant: any, adminSection = false): any {
        let listWidth;
        let sideWidth;
        let graphWidth;
        let subComponentsWidth;
        let reportAdjustment = 0;
        if (!displayConstant || !displayConstant.widthRatio || !displayConstant.adjustment) {
            return;
        }
        if (displayConstant.adjustment.report) {
            reportAdjustment = displayConstant.adjustment.report;
        }
        if (!adminSection) {
            if (innerWidth < 1235) {
                listWidth = innerWidth - displayConstant.leftColumnWidth + displayConstant.adjustment.left;
                graphWidth = ((innerWidth - displayConstant.leftColumnWidth) + displayConstant.adjustment.left) / 2 + 'px';
                sideWidth = 400;
            } else {
                sideWidth = (innerWidth - displayConstant.leftColumnWidth) *
                    (1 - displayConstant.widthRatio) + displayConstant.adjustment.right;
                if (sideWidth < 400) {
                    sideWidth = 400;
                }
                // @ilya 2020-10-16 - fix the side width to 400px
                sideWidth = 400;
                listWidth = (innerWidth - (sideWidth * 1.25)) + reportAdjustment;
            }
            listWidth = listWidth + 'px';
            sideWidth = sideWidth + 'px';
        } else {
            listWidth = (innerWidth - displayConstant.leftColumnWidth) * displayConstant.widthRatio + displayConstant.adjustment.left;
            sideWidth = ((innerWidth - listWidth) * displayConstant.widthRatio + displayConstant.adjustment.right);
            subComponentsWidth = (innerWidth - displayConstant.leftColumnWidth) * displayConstant.widthRatio - 40;

            if (innerWidth < 1235) {
                sideWidth = (innerWidth - displayConstant.leftColumnWidth) * (1 - displayConstant.widthRatio) + displayConstant.adjustment.right;
            }

            listWidth = listWidth + 'px';
            sideWidth = sideWidth + 'px';
            subComponentsWidth = subComponentsWidth + 'px';
        }

        return {
            listWidth: listWidth,
            sideWidth: sideWidth,
            graphWidth: graphWidth,
            subComponentsWidth: subComponentsWidth
        };
    }

    public static isSmallSize(innerWidth: number) {
        return (innerWidth < 1235);
    }
}
