import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    IterableDiffers,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ApiPeopleService} from '../../../people/services/api-people.service';
import {Subscription} from 'rxjs';
import {TestAndReportHandlingService} from '../../../people/services/test-and-report-handling.service';
import {StateService} from '../../../core/services/state/state.service';
import {PersonService} from '../../../people/services/person.service';

@Component({
    selector: 'app-people-card',
    templateUrl: './people-card.component.html',
    styleUrls: ['./people-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleCardComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('personTooltip') personTooltip;
    @ViewChild('satTooltip') satTooltip;
    @ViewChild('ditTooltip') ditTooltip;
    @ViewChild('talentTooltip') talentTooltip;
    @ViewChild('caiTooltip') caiTooltip;

    @Input() personOnCard: any;
    @Input() selectionArray: any = [];
    @Input() selectedItemId;
    @Input() decalageLeft;
    @Output() onSelectedItem = new EventEmitter();
    @Output() requestSendMpoEvent = new EventEmitter();
    @Output() requestResendMpoEvent = new EventEmitter();
    @Output() requestSendIacEvent = new EventEmitter();
    @Output() requestResendIacEvent = new EventEmitter();
    @Output() activateReport = new EventEmitter();
    @Output() checkableItem = new EventEmitter();

    status: string;
    subTitle: string;
    firstName: string;
    lastName: string;

    iterableDiffer: any;

    selection = false;
    currentLang: string;

    displayDataObject = {
        mpo: null,
        ra: null,
        count: null
    };

    private subscriptions = new Subscription();

    constructor(
        private _iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef,
        public stateService: StateService,
        private apiPeople: ApiPeopleService,
        public testAndReportHandling: TestAndReportHandlingService,
        public person: PersonService,
        public translateService: TranslateService
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    activate(event, reportType) {
        let isReportExists = (this.personOnCard.reportStatuses &&
            this.personOnCard.reportStatuses[reportType]) ?
            this.personOnCard.reportStatuses[reportType] : false;
        if (!isReportExists) {
            event.stopPropagation();
            let payload = {
                id : this.personOnCard.id,
                type: reportType,
                subAccount: this.personOnCard.subAccount,
                invalid: false
            };

            if (this.borderlineCase) {
                payload.invalid = true;
            }

            this.activateReport.emit(payload);
        } else {
            this.display(event, reportType);
        }
    }

    /**
     *
     * @param event
     * @param questionnaireType
     * @param send if false, then resend
     */
    send(event, questionnaireType, send) {
        event.stopPropagation();
        this.apiPeople.record([this.personOnCard.id]).subscribe(
            (res) => {
                if (questionnaireType === 'mpo') {
                    (send) ?
                        this.requestSendMpoEvent.emit({'person': res}) :
                        this.requestResendMpoEvent.emit({'person': res});
                } else {
                    (send) ?
                        this.requestSendIacEvent.emit({'person': res}) :
                        this.requestResendIacEvent.emit({'person': res});
                }

            }
        );
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;

        this.firstName = this.personOnCard.firstName;
        this.lastName = this.personOnCard.lastName;

        let portraitInstance = this.testAndReportHandling.renderPortrait(this.personOnCard.portrait);
        let recordType = (this.personOnCard.recordType) ? this.personOnCard.recordType : '';
        let pipe = (this.personOnCard.recordType && portraitInstance) ? ' | ' : '';
        let portrait = (portraitInstance) ? this.translateService.instant(portraitInstance) : '';
        this.subTitle = recordType + pipe + portrait;

        if (this.personOnCard) {
            this.displayDataObject.mpo = {
                'mpo': false,
                'dit': false,
                'talents': false,
                'satellite': false
            };
            this.displayDataObject.ra = false;
            // MPO
            if (
                (this.testStatuses &&
                    this.testStatuses.mpo === 'C')
                || (
                    this.personOnCard.cardDisplay &&
                    this.testStatuses &&
                    this.testStatuses.mpo === 'NC' && (
                        this.personOnCard.cardDisplay.mpo ||
                        this.personOnCard.cardDisplay.dit ||
                        this.personOnCard.cardDisplay.talents ||
                        this.personOnCard.cardDisplay.satellite
                    )
                )
            ) {
                if (this.personOnCard.hasOwnProperty('cardDisplay')) {
                    for (let test of ['mpo', 'dit', 'talents', 'satellite']) {
                        if (this.personOnCard.cardDisplay.hasOwnProperty(test)) {
                            switch (test) {
                                case 'mpo':
                                    this.displayDataObject.mpo.mpo = this.personOnCard.cardDisplay.mpo[0];
                                    break;
                                case 'dit':
                                    this.displayDataObject.mpo.dit = this.personOnCard.cardDisplay.dit[0];
                                    break;
                                case 'talents':
                                    this.displayDataObject.mpo.talents = this.personOnCard.cardDisplay.talents[0];
                                    break;
                                case 'satellite':
                                    this.displayDataObject.mpo.satellite = this.personOnCard.cardDisplay.satellite[0];
                                    break;
                            }

                        }
                    }
                }
            } else if (this.testStatuses && this.testStatuses.mpo === 'NC') {
                this.displayDataObject.mpo = 'NC';
            } else if (this.testStatuses && this.testStatuses.mpo === 'N') {
                this.displayDataObject.mpo = 'N';
            }

            // RA
            if (this.testStatuses && this.testStatuses.ra === 'C') {
                if (
                    this.personOnCard.cardDisplay &&
                    this.personOnCard.cardDisplay.hasOwnProperty('ra')
                ) {
                    this.displayDataObject.ra = this.personOnCard.cardDisplay.ra[0];
                }
            } else if (this.testStatuses && this.testStatuses.ra === 'NC') {
                this.displayDataObject.ra = 'NC';
            } else if (this.testStatuses && this.testStatuses.ra === 'N') {
                this.displayDataObject.ra = 'N';
            }
        }
        // count
        if (this.displayDataObject.mpo === 'NC' || this.displayDataObject.mpo === 'N') {
            this.displayDataObject.count = 2;
        } else {
            this.displayDataObject.count = 4;
        }
        this.changeDetectorRef.markForCheck();
    }

    get raPermission() {
        return this.stateService.hasIac;
    }

    get testStatuses() {
        if (this.personOnCard.testStatuses) {
            return this.personOnCard.testStatuses;
        }
        return;
    }

    get mpoIconTitle() {
        if (this.testStatuses && this.testStatuses['mpo'] === 'NC') {
            if (this.mpoScores) {
                return {en: 'Activate', fr: 'Activer', y : 33};
            } else {
                return {en: 'Resend', fr: 'Renvoyer', y : 33};
            }
        }
        return;
    }

    get ditIconTitle() {
        if (this.borderlineCase) {
            return {en: 'Invalid', fr: 'Invalide', y : 33};
        } else {
            return this.mpoIconTitle;
        }
        return;
    }

    get satelliteIconTitle() {
        if (this.testStatuses && this.testStatuses['mpo'] === 'NC') {
            if (this.mpoScores) {
                return {en: 'Activate', fr: 'Activer', y : 33};
            } else {
                return {en: 'Resend', fr: 'Renvoyer', y : 33};
            }
        }
        if (this.satelliteScore === 'noJobProfile') {
            return {en: 'Choose a ', fr: 'Choisir un ', secen: 'job', secfr: 'poste', y: 29};
        }
        return;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.selection = (_.indexOf(this.selectionArray.map(item => {return item.id; }), this.personOnCard.id) > -1);
        this.changeDetectorRef.markForCheck();
    }

    isChecked(itemId, state) {
        this.checkableItem.emit({
            id: itemId,
            state: state
        });
    }

    hideTooltips(exception = null) {
        ['person', 'sat', 'dit', 'talent', 'cai'].forEach((t) => {
            if (this[t + 'Tooltip'] && t !== exception) {
                this[t + 'Tooltip'].hide();
            }
        });
    }

    checkPersonTooltips(personId) {
        let elem = document.getElementById('personCard_' + personId);
        if (elem && elem.classList) {
            // check if this mouseenter is the first for this person block
            if (!elem.classList.contains('tooltipH')) {
                // hide tooltips for the person block in question
                this.hideTooltips();
                // check if we have other displayed tooltips and remove if it exists
                let checkTooltips = document.querySelectorAll<HTMLElement>('.tooltip');
                if (checkTooltips) {
                    for (let i = 0; i < checkTooltips.length; i++) {
                        checkTooltips[i].style.display = 'none';
                    }
                }
                elem.classList.add('tooltipH');
            }
        }
    }

    leavePersonBlock(personId) {
        let elem = document.getElementById('personCard_' + personId);
        if (elem && elem.classList) {
            if (elem.classList.contains('tooltipH')) {
                this.hideTooltips();
                elem.classList.remove('tooltipH');
            }
        }
    }

    display(event, reportType) {
        event.stopPropagation();
        this.onSelectedItem.emit([this.personOnCard, reportType]);

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get borderlineCase() {
        let borderlineCase = false;
        if (this.mpoScores &&
            this.mpoScores['A'] === 0 &&
            this.mpoScores['E'] === 0 &&
            this.mpoScores['P'] === 0 &&
            this.mpoScores['S'] === 0) {
            borderlineCase = true;
        }
        if (this.mpoScores &&
            this.mpoScores['A'] === 10 &&
            this.mpoScores['E'] === 10 &&
            this.mpoScores['P'] === 10 &&
            this.mpoScores['S'] === 10) {
            borderlineCase = true;
        }
        if (this.ditIsInvalid) {
            borderlineCase = true;
        }
        return borderlineCase;
    }

    get hasCommunication() {
        return !!(this.personEvaluations &&
            this.personEvaluations.dit
        );
    }

    get ditIsInvalid() {
        return (this.hasCommunication && (_.get(this.personEvaluations, 'dit.info.type') === 'INV'));
    }

    get hasMpoReport() {
        for (let report of ['mpo', 'dit', 'talents', 'satellite']) {
            if (
                !!(
                    report !== 'sataellite' &&
                    this.hasEvaluations &&
                    this.personOnCard.cardDisplay.hasOwnProperty(report) &&
                    this.personOnCard.cardDisplay[report][0] !== null &&
                    this.personOnCard.cardDisplay[report][0] !== undefined
                )
            ) {
                return true;
            } else if (
                report === 'satellite' &&
                this.hasEvaluations &&
                this.personOnCard.cardDisplay.hasOwnProperty('satellite') &&
                (
                    (
                        this.personOnCard.cardDisplay['satellite'][0] !== null &&
                        this.personOnCard.cardDisplay['satellite'][0] !== undefined
                    ) || (
                        this.personOnCard.cardDisplay['satellite'].length === 0
                    )
                )
            ) {
                return true;
            }
        }
        return false;
    }

    get hasEvaluations() {
        return !!(this.personOnCard.cardDisplay);
    }

    get personEvaluations() {
        if (this.hasEvaluations) {
            return this.personOnCard.cardDisplay;
        }
        return null;
    }

    get mpoScores() {
        if (this.personEvaluations && this.personEvaluations.mpo && this.personEvaluations.mpo[0]) {
            return this.personEvaluations.mpo[0];
        }
        return undefined;
    }

    get satelliteScore() {
        if (this.personEvaluations && this.personEvaluations.satellite !== undefined) {
            if (!this.personEvaluations.satellite[0]) {
                return 'noJobProfile';
            } else {
                return this.personEvaluations.satellite[0];
            }
        }
        return undefined;
    }

    get ditQuad() {
        if (this.personEvaluations && this.personEvaluations.dit && this.personEvaluations.dit[0]) {
            return this.personEvaluations.dit[0];
        }
        return undefined;
    }

    get talentsScore() {
        if (this.personEvaluations && this.personEvaluations.talents && this.personEvaluations.talents[0]) {
            return this.personEvaluations.talents[0];
        }
        return undefined;
    }

    get iacScores() {
        if (this.personEvaluations && this.personEvaluations.ra && this.personEvaluations.ra[0]) {
            return this.personEvaluations.ra[0];
        }
        return undefined;
    }
}
