import {Component, OnInit, Output, EventEmitter, OnChanges, ChangeDetectorRef} from '@angular/core';
import {JobSatisfactionPcrReportService} from '../../../services/job-satisfaction-pcr-report.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-job-satisfaction-analysis-popup',
    templateUrl: './job-satisfaction-analysis-popup.component.html',
    styleUrls: ['./job-satisfaction-analysis-popup.component.scss']
})
export class JobSatisfactionAnalysisPopupComponent implements OnInit {

    @Output() closeJobSatisfactionPopup = new EventEmitter();

    info;
    prbReport;
    personalityScores;
    prbScores;
    jobSatisfactionScore;
    jobSatisfactionText;

    jsColor;

    traits: Array<string> = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];
    /** TODO: factor this, it is also in People's report */
    textFields: any = {
        fr: {
            OR: {summary: 'Originalité de pensée'},
            SE: {summary: 'Spontanéité émotionnelle'},
            A: {summary: 'Assertivité', predictableBehavior: 'assertivité'},
            E: {summary: 'Extraversion', predictableBehavior: 'extraversion'},
            P: {summary: 'Pondération', predictableBehavior: 'pondération'},
            S: {summary: 'Structure', predictableBehavior: 'structure'},
            AD: {summary: 'Adaptabilité'}
        },
        en: {
            OR: {summary: 'Originality of thought'},
            SE: {summary: 'Emotional spontaneity'},
            A: {summary: 'Assertiveness', predictableBehavior: 'assertiveness'},
            E: {summary: 'Extroversion', predictableBehavior: 'extroversion'},
            P: {summary: 'Pace', predictableBehavior: 'pace'},
            S: {summary: 'Structure', predictableBehavior: 'structure'},
            AD: {summary: 'Adaptability'}
        }
    };

    constructor(
        public jobSatisfactionPcrReport: JobSatisfactionPcrReportService,
        public translate: TranslateService,
        public deviceDetector: DeviceDetectorService,
        public cd: ChangeDetectorRef
    ) {
        this.jobSatisfactionPcrReport.reportChanged$.subscribe(
            (res) => {
                if (res !== null) {
                    this.info = res.info;
                    this.prbReport = res.prbReport;
                    this.personalityScores = res.personalityScores;
                    this.prbScores = res.prbScores;
                    this.jobSatisfactionScore = res.jobSatisfactionScore;
                    this.jobSatisfactionText = this.jobSatisfactionPcrReport.produceTexts(res.jobSatisfactionScore);
                    this.setJsColor();
                    this.cd.markForCheck();
                }
            }
        );

        this.translate.onLangChange.subscribe(
            (res) => {
                this.jobSatisfactionText = this.jobSatisfactionPcrReport.produceTexts(this.jobSatisfactionScore);
            }
        );
    }

    setJsColor() {
        this.jsColor = {
            OR: null,
            SE: null,
            A: null,
            E: null,
            P: null,
            S: null,
            AD: null
        };
        for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD']) {
            let ints;
            switch (trait) {
                case 'OR':
                    ints = [
                        [[-10, -5], -10, 'r'],
                        [[-4, -4], -9, 'r'],
                        [[-3, -3], -7, 'j'],
                        [[-2, -2], -5, 'j'],
                        [[-1, -1], -3, 'v'],
                        [[0, 0], 0, 'v'],
                        [[1, 1], 2, 'v'],
                        [[2, 2], 4, 'j'],
                        [[3, 3], 5, 'j'],
                        [[4, 4], 6, 'j'],
                        [[5, 5], 7, 'j'],
                        [[6, 6], 8, 'r'],
                        [[7, 7], 9, 'r'],
                        [[8, 10], 10, 'r']
                    ];
                    break;
                case 'SE':
                    ints = [
                        [[-10, -5], -9, 'r'],
                        [[-4, -4], -7, 'j'],
                        [[-3, -3], -4, 'j'],
                        [[-2, -2], -2, 'v'],
                        [[-1, -1], 0, 'v'],
                        [[0, 0], 2, 'v'],
                        [[1, 1], 4, 'v'],
                        [[2, 2], 5, 'j'],
                        [[3, 3], 6, 'j'],
                        [[4, 4], 7, 'j'],
                        [[5, 5], 8, 'r'],
                        [[6, 6], 9, 'r'],
                        [[7, 10], 10, 'r']
                    ];
                    break;
                case 'A':
                    ints = [
                        [[-10, -5], -10, 'r'],
                        [[-4, -4], -9, 'r'],
                        [[-3, -3], -7, 'j'],
                        [[-2, -2], -5, 'j'],
                        [[-1, -1], -2, 'v'],
                        [[0, 0], 1, 'v'],
                        [[1, 1], 3, 'v'],
                        [[2, 2], 5, 'j'],
                        [[3, 3], 6, 'j'],
                        [[4, 4], 8, 'r'],
                        [[5, 5], 9, 'r'],
                        [[6, 10], 10, 'r']
                    ];
                    break;
                case 'E':
                    ints = [
                        [[-10, -4], -9, 'r'],
                        [[-3, -3], -8, 'r'],
                        [[-2, -2], -6, 'j'],
                        [[-1, -1], -3, 'v'],
                        [[0, 0], -1, 'v'],
                        [[1, 1], 1, 'v'],
                        [[2, 2], 3, 'v'],
                        [[3, 3], 5, 'j'],
                        [[4, 4], 6, 'j'],
                        [[5, 5], 8, 'r'],
                        [[6, 6], 9, 'r'],
                        [[7, 10], 10, 'r']
                    ];
                    break;
                case 'P':
                    ints = [
                        [[-10, -5], -9, 'r'],
                        [[-4, -4], -8, 'r'],
                        [[-3, -3], -7, 'j'],
                        [[-2, -2], -5, 'j'],
                        [[-1, -1], -3, 'v'],
                        [[0, 0], -1, 'v'],
                        [[1, 1], 1, 'v'],
                        [[2, 2], 3, 'v'],
                        [[3, 3], 5, 'j'],
                        [[4, 4], 6, 'j'],
                        [[5, 5], 8, 'r'],
                        [[6, 6], 9, 'r'],
                        [[7, 10], 10, 'r']
                    ];
                    break;
                case 'S':
                    ints = [
                        [[-10, -5], -10, 'r'],
                        [[-4, -4], -9, 'r'],
                        [[-3, -3], -7, 'j'],
                        [[-2, -2], -5, 'j'],
                        [[-1, -1], -3, 'v'],
                        [[0, 0], -1, 'v'],
                        [[1, 1], 2, 'v'],
                        [[2, 2], 4, 'v'],
                        [[3, 3], 7, 'j'],
                        [[4, 4], 8, 'r'],
                        [[5, 5], 9, 'r'],
                        [[6, 10], 10, 'r']
                    ];
                    break;
                case 'AD':
                    ints = [
                        [[-10, -3], -10, 'r'],
                        [[-2.75, -2], -8, 'r'],
                        [[-1.75, -1], -4, 'j'],
                        [[-0.75, 0], 0, 'v'],
                        [[0.25, 1], 3, 'v'],
                        [[1.25, 2], 6, 'j'],
                        [[2.25, 3], 8, 'r'],
                        [[3.25, 4], 9, 'r'],
                        [[4.25, 10], 10, 'r']
                    ];
                    break;
                default:
                    break;
            }
            let delta = this.personalityScores[trait] - this.prbScores[trait];

            for (let int of ints) {
                if (delta >= int[0][0] && delta <= int[0][1]) {
                    this.jsColor[trait] = int[2];
                }
            }
        }
        // console.log(this.jsColor);
        this.cd.markForCheck();
    }

    ngOnInit(): void {

    }

}
