import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {TranslateService} from '@ngx-translate/core';
// import {IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';
import {FormsHelper} from '../../helpers/FormsHelper';
import * as _ from 'lodash';
import {ApiAdministrationService} from '../../services/api-administration.service';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../../../shared/helpers/custom-date-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';


@Component({
    selector: 'app-administration-account-form-field',
    templateUrl: './administration-account-form-field.component.html',
    styleUrls: ['./administration-account-form-field.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ],
})
export class AdministrationAccountFormFieldComponent implements OnChanges {

    @Input() info: any;
    @Input() new: any = false;
    @Input() missedFields: any = [];
    @Input() closeAllFieldsExcept: string;
    @Input() formHelper: any;
    @Input() client: any;
    @Input() mpoProducts: any;

    pristine = true;

    error: boolean;

    @Output() closeAllAdminFields = new EventEmitter();
    @Output() editionsResult = new EventEmitter();

    editEnable = false;
    fieldsValue: any;
    fieldsValueText: any;

    fieldsName: string;
    fieldsType: string;
    fieldsExternalValue: any;
    editable = true;
    fieldsOptions: any = null; // Used in multiselect
    validation: any = null; // Used for validating user name.
    format: string = null; // Zip: CA H0H 0H0 US 12345, FR 00001 ||| Phone: CA/US 555 555-5555, FR 5 55 55 55 55

    // myDatePickerOptions: {
    //    fr: IMyDpOptions,
    //    en: IMyDpOptions
    // } = {
    //    fr: {dateFormat: 'dd/mm/yyyy'},
    //    en: {dateFormat: 'mm/dd/yyyy'}
    // };
    currentDatePickerOptions;
    selDate;

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        public translate: TranslateService,
        protected cd: ChangeDetectorRef,
        protected state: StateService
    ) {
    }

    ngOnChanges(changes): void {
        if (changes) {
            if (changes.fieldsExternalValue && changes.fieldsExternalValue.currentValue !== changes.fieldsExternalValue.previousValue) {
                this.fieldsValue = changes.fieldsExternalValue.currentValue;
            } else if (
                changes.closeAllFieldsExcept && this.info &&
                changes.closeAllFieldsExcept.currentValue !== changes.closeAllFieldsExcept.previousValue &&
                changes.closeAllFieldsExcept.currentValue !== this.info.fieldsName
            ) {
                this.closeEdition();
                return;
            }
        }
        for (let input of ['fieldsName', 'fieldsValueText', 'fieldsType', 'fieldsExternalValue', 'editable', 'fieldsOptions', 'validation', 'format']) {
            this[input] = (this.info && (this.info[input] !== null || this.info[input] !== undefined)) ? this.info[input] : null;
        }

        if (this.fieldsName === 'parentAccountNumber') {
            let parentAccount = _.find(this.info.fieldsOptions.selectOptions, {value: this.fieldsValueText});
            this.fieldsValueText = (parentAccount) ? parentAccount.text[this.translate.currentLang] : null;
        }

        // Preserve initial value
        if (!this.new) {
            this.fieldsValue = this.fieldsExternalValue;
        } else if (this.pristine && ['mpoPersonality', 'mpoCommunication', 'mpoTalents', 'mpoSatellite', 'cai', 'jil'].includes(this.fieldsName)) {
            this.fieldsValue = true;
            this.pristine = false;
        }

        if (this.fieldsName === 'productCodes') {
            const productCodes = this.setProductCodes();
            if (productCodes) {
                this.fieldsOptions.selectOptions = Object.entries(productCodes);
            } else {
                this.fieldsOptions.selectOptions = [];
            }
            this.fieldsValue = this.fieldsExternalValue;
            this.fieldsValueText = this.getProductBySku(this.fieldsValue);
        }

        if (this.fieldsName === 'description') {
            if (this.client[1][1].fieldsExternalValue !== 'client' && this.client[1][1].fieldsExternalValue !== 'demo') {
                this.fieldsValueText = this.getProductBySku(this.mpoProducts[0][2].fieldsExternalValue, 'description');
            }
        }

        // Specific input fields
        if (this.fieldsType === 'date-picker') {
            this.setDatePickerFields(this.translate.currentLang);
            // Make dates bilingual
            this.translate.onLangChange.subscribe(
                (e) => {
                    this.setDatePickerFields(e.lang);
                }
            );
        }
        if (this.new) {
            this.error = this.missedFields.includes(this.fieldsName);
        }
        this.cd.markForCheck();
    }

    getProductBySku(sku: string, whatToGet = 'name'): string {
        // Iterate over the productCodes object
        for (let accountType in this.productCodes) {
            if (accountType === 'client') {
                // tslint:disable-next-line:forin
                for (let country in this.productCodes[accountType]) {
                    for (let product in this.productCodes[accountType][country]) {
                        if (this.productCodes[accountType][country][product].hasOwnProperty('sku') && this.productCodes[accountType][country][product]['sku'] === sku) {
                            return this.productCodes[accountType][country][product][whatToGet];
                        }
                    }
                }
            } else {
                for (let product in this.productCodes[accountType]) {
                    if (this.productCodes[accountType][product].hasOwnProperty('sku') && this.productCodes[accountType][product]['sku'] === sku) {
                        return this.productCodes[accountType][product][whatToGet];
                    }
                }
            }
        }
    }

    setDatePickerFields(lang) {
        if (this.fieldsExternalValue && this.fieldsExternalValue[lang] && !this.new) {
            this.fieldsValue = {jsdate: new Date(this.fieldsExternalValue[lang])};
        } else {
            this.fieldsExternalValue = null;
            this.fieldsValue = null;
        }

        // this.currentDatePickerOptions = this.myDatePickerOptions[lang];

    }

    setDatePickerFieldsToDisplay(lang, fieldsValue) {
        this.fieldsValueText = {
            fr: null,
            en: null
        };
        if (this.fieldsExternalValue !== null) {
            let prefix = (fieldsValue && fieldsValue.jsdate) ? fieldsValue.jsdate : fieldsValue;
            let dateArray =  prefix.toISOString().substring(0, 10).split('-');
            this.fieldsValueText = {
                fr: dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0],
                en: dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
            };
        }
    }

    handleValueChange() {
        if (this.new) {
            this.editionsResult.emit(this.fieldsValue);
        }
    }

    updateField() {
        this.editionsResult.emit({
            fieldsName: this.fieldsName,
            fieldsValue: this.fieldsValue,
            fieldsType: this.fieldsType
        });
        // Set text value
        this.fieldsExternalValue = this.fieldsValue;

        switch (this.fieldsType) {
            case 'text':
                this.fieldsValueText = this.fieldsValue;
                break;
            case 'multiselect-dropdown':
                break;
            case 'date-picker':
                this.setDatePickerFieldsToDisplay(this.translate.currentLang, this.fieldsValue);
                break;
            case 'select':
                let value = FormsHelper.convertValueToDataValue(this.fieldsName, this.fieldsValue);
                if (this.fieldsName === 'productCodes') {
                    // console.log(this.fieldsValue);
                    let accountType = this.client[1][1].fieldsExternalValue;
                    let country = this.client[1][3].fieldsValueText.includes('USA') ? 'USA' : 'Canada';
                    let text = '';
                    // console.log(this.fieldsValue);
                    // console.log(this.productCodes['client'][country][this.fieldsValue]['description']);
                    if (accountType === 'client') {
                        text = this.productCodes['client'][country][this.fieldsValue]['description'][this.translate.currentLang];
                    } else {
                        text = this.productCodes[accountType][this.fieldsValue]['description'][this.translate.currentLang];
                    }
                    this.fieldsValueText = text;
                } else if (this.fieldsName === 'administrator') {
                    this.fieldsValueText = value;
                } else {
                    this.fieldsValueText = _.find(this.fieldsOptions.selectOptions, {value: value}).text;
                }
                break;
            case 'checkbox':
                // Permission checkboxes
                this.fieldsValueText = FormsHelper.setCheckboxText(this.fieldsName, this.fieldsValue);
                break;
            default:
                break;
        }
        this.cd.markForCheck();
        this.closeEdition();
    }

    setProductCodes(): any {
        let accountType = this.client[1][1].fieldsExternalValue;
        let country = this.client[1][3].fieldsValueText.includes('USA') ? 'USA' : 'Canada';
        let productCodes = {};
        if (accountType === 'client') {
            productCodes = this.productCodes['client'][country];
        } else {
            // console.log(accountType);
            productCodes = this.productCodes[accountType];
        }
        // console.log(productCodes);
        return productCodes;

    }

    startEdition() {
        this.editEnable = true;
        this.closeAllAdminFields.emit(this.fieldsName);
    }

    cancelEditField() {
        this.closeEdition();
    }

    handleOptionTexts(text, title: any = false) {
        if (title) {
            if (text === 'subsidiary') {
                return (this.translate.currentLang === 'fr') ? 'SUBSIDIAIRE' : 'SUBSIDIARY';
            }
            if (text === 'distributor') {
                return (this.translate.currentLang === 'fr') ? 'DISTRIBUTEUR' : 'DISTRIBUTOR';
            }
            if (text === 'partner') {
                return (this.translate.currentLang === 'fr') ? 'PARTENAIRE' : 'PARTNER';
            }
        } else {
            return text.toUpperCase();
        }
    }

    closeEdition() {
        this.editEnable = false;
    }

    get isPermission() {
        return (this.fieldsName.split('_')[0] === 'permission');
    }

    get canEdit() {
        return this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN') ?? false;
    }

    get productCodes() {
        return {
            'client': {
                'Canada': {
                    'MPOENTPR75-2019': {
                        'sku': 'MPOENTPR75-2019',
                        'name': 'PREMIUM 75',
                        'description': {
                            'fr': 'jusqu\'à 75 employés',
                            'en': 'up to 75 employees'
                        }
                    },
                    'MPOENTPR150-2019': {
                        'sku': 'MPOENTPR150-2019',
                        'name': 'PREMIUM 150',
                        'description': {
                            'fr': 'de 75 à 150 employés',
                            'en': 'from 75 to 150 employees'
                        }
                    },
                    'MPOENTPR250-2019': {
                        'sku': 'MPOENTPR250-2019',
                        'name': 'PREMIUM 250',
                        'description': {
                            'fr': 'de 151 à 250 employés',
                            'en': 'from 151 to 250 employees'
                        }
                    },
                    'MPOENTPR350-2019': {
                        'sku': 'MPOENTPR350-2019',
                        'name': 'PREMIUM 350',
                        'description': {
                            'fr': 'de 251 à 350 employés',
                            'en': 'from 251 to 350 employees'
                        }
                    },
                    'MPOENTPR500-2019': {
                        'sku': 'MPOENTPR500-2019',
                        'name': 'PREMIUM 500',
                        'description': {
                            'fr': 'de 351 à 500 employés',
                            'en': 'from 351 to 500 employees'
                        }
                    },
                    'MPOENTPR1000-2019': {
                        'sku': 'MPOENTPR1000-2019',
                        'name': 'PREMIUM 1000',
                        'description': {
                            'fr': 'de  501 à 1000 employés',
                            'en': 'from 501 to 1000 employees'
                        }
                    },
                    'MPOENTPR1500-2019': {
                        'sku': 'MPOENTPR1500-2019',
                        'name': 'PREMIUM 1500',
                        'description': {
                            'fr': 'de 1001 à 1500 employés',
                            'en': 'from 1001 to 1500 employees'
                        }
                    },
                    'MPOENTPR2000-2019': {
                        'sku': 'MPOENTPR2000-2019',
                        'name': 'PREMIUM 2000',
                        'description': {
                            'fr': 'de 1501 à 2000 employés',
                            'en': 'from 1501 to 2000 employees'
                        }
                    },
                    'MPOENTPR2500-2019': {
                        'sku': 'MPOENTPR2500-2019',
                        'name': 'PREMIUM 2500',
                        'description': {
                            'fr': 'de 2001 à 2500 employés',
                            'en': 'from 2001 to 2500 employees'
                        }
                    },
                    'MPOENTPR3000-2019': {
                        'sku': 'MPOENTPR3000-2019',
                        'name': 'PREMIUM 3000',
                        'description': {
                            'fr': 'de 2501 à 3000 employés',
                            'en': 'from 2501 to 3000 employees'
                        }
                    },
                    'MPOENTPR3500-2019': {
                        'sku': 'MPOENTPR3500-2019',
                        'name': 'PREMIUM 3500',
                        'description': {
                            'fr': 'de 3001 à 3500 employés',
                            'en': 'from 3001 to 3500 employees'
                        }
                    },
                    'MPOENTPR4000-2019': {
                        'sku': 'MPOENTPR4000-2019',
                        'name': 'PREMIUM 4000',
                        'description': {
                            'fr': 'de  3501 à 4000 employés',
                            'en': 'from 3501 to 4000 employees'
                        }
                    },
                    'MPOENTPR4500-2019': {
                        'sku': 'MPOENTPR4500-2019',
                        'name': 'PREMIUM 4500',
                        'description': {
                            'fr': 'de 4001 à 4500 employés',
                            'en': 'from 4001 to 4500 employees'
                        }
                    },
                    'MPOENTPR5000-2019': {
                        'sku': 'MPOENTPR5000-2019',
                        'name': 'PREMIUM 5000',
                        'description': {
                            'fr': 'de 4501 à 5000 employés(370$ distributeur extra/500 employés-1070$ client)',
                            'en': 'from 4501 to 5000 employees(370$ distributor extra/500 employees-1070$ client)'
                        }
                    },
                    'MPOFSA50': {
                        'sku': 'MPOFSA50',
                        'name': 'PROFESSIONNELLE 50',
                        'description': {
                            'fr': 'Entente annuelle Jusqu\'à 50 employés',
                            'en': 'Annual agreement Up to 50 employees'
                        }
                    },
                    'MPOFSA150': {
                        'sku': 'MPOFSA150',
                        'name': 'PROFESSIONNELLE 150',
                        'description': {
                            'fr': 'Entente annuelle 51 à 150 employés',
                            'en': 'Annual agreement 51 to 150 employees'
                        }
                    },
                    'MPOFSA250': {
                        'sku': 'MPOFSA250',
                        'name': 'PROFESSIONNELLE 250',
                        'description': {
                            'fr': 'Entente annuelle 151 à 250 employés',
                            'en': 'Annual agreement 151 to 250 employees'
                        }
                    },
                    'MPOFSA500': {
                        'sku': 'MPOFSA500',
                        'name': 'PROFESSIONNELLE 500',
                        'description': {
                            'fr': 'Entente annuelle 251 à 500 employés',
                            'en': 'Annual agreement 251 to 500 employees'
                        }
                    },
                    'MPOFSA1000': {
                        'sku': 'MPOFSA1000',
                        'name': 'PROFESSIONNELLE 1000',
                        'description': {
                            'fr': 'Entente annuelle 501 à 2000 employés',
                            'en': 'Annual agreement 501 to 2000 employees'
                        }
                    },
                    'MPOFSA2000': {
                        'sku': 'MPOFSA2000',
                        'name': 'PROFESSIONNELLE 2000',
                        'description': {
                            'fr': 'Entente annuelle 2001 et plus employés',
                            'en': 'Annual agreement 2001 and more employees'
                        }
                    }
                },
                'USA': {
                    'MPOENTPR25 - US': {
                        'sku': 'MPOENTPR25 - US',
                        'name': 'PREMIUM 25',
                        'description': {
                            'en': 'up to 25 employees',
                            'fr': 'jusqu\'à 25 employés'
                        }
                    },
                    'MPOENTPR50 - US': {
                        'sku': 'MPOENTPR50 - US',
                        'name': 'PREMIUM 50',
                        'description': {
                            'en': '26 to 50 employees',
                            'fr': 'de 26 à 50 employés'
                        }
                    },
                    'MPOENTPR100 - US': {
                        'sku': 'MPOENTPR100 - US',
                        'name': 'PREMIUM 100',
                        'description': {
                            'en': '51 to 100 employees',
                            'fr': 'de 51 à 100 employés'
                        }
                    },
                    'MPOENTPR200 - US': {
                        'sku': 'MPOENTPR200 - US',
                        'name': 'PREMIUM 200',
                        'description': {
                            'en': '101 to 200 employees',
                            'fr': 'de 101 à 200 employés'
                        }
                    },
                    'MPOENTPR300 - US': {
                        'sku': 'MPOENTPR300 - US',
                        'name': 'PREMIUM 300',
                        'description': {
                            'en': '201 to 300 employees',
                            'fr': 'de 201 à 300 employés'
                        }
                    },
                    'MPOENTPR400 - US': {
                        'sku': 'MPOENTPR400 - US',
                        'name': 'PREMIUM 400',
                        'description': {
                            'en': '301 to 400 employees',
                            'fr': 'de 301 à 400 employés'
                        }
                    },
                    'MPOENTPR500 - US': {
                        'sku': 'MPOENTPR500 - US',
                        'name': 'PREMIUM 500',
                        'description': {
                            'en': '401 to 500 employees',
                            'fr': 'de 401 à 500 employés'
                        }
                    },
                    'MPOENTPR600 - US': {
                        'sku': 'MPOENTPR600 - US',
                        'name': 'PREMIUM 600',
                        'description': {
                            'en': '501 to 600 employees',
                            'fr': 'de 501 à 600 employés'
                        }
                    },
                    'MPOENTPR700 - US': {
                        'sku': 'MPOENTPR700 - US',
                        'name': 'PREMIUM 700',
                        'description': {
                            'en': '601 to 700 employees',
                            'fr': 'de 601 à 700 employés'
                        }
                    },
                    'MPOENTPR800 - US': {
                        'sku': 'MPOENTPR800 - US',
                        'name': 'PREMIUM 800',
                        'description': {
                            'en': '701 to 800 employees',
                            'fr': 'de 701 à 800 employés'
                        }
                    },
                    'MPOENTPR900 - US': {
                        'sku': 'MPOENTPR900 - US',
                        'name': 'PREMIUM 900',
                        'description': {
                            'en': '801 to 900 employees',
                            'fr': 'de 801 à 900 employés'
                        }
                    },
                    'MPOENTPR1000 - US': {
                        'sku': 'MPOENTPR1000 - US',
                        'name': 'PREMIUM 1000',
                        'description': {
                            'en': '901 to 1000 employees',
                            'fr': 'de 901 à 1000 employés'
                        }
                    },
                    'MPOENTPR1500 - US': {
                        'sku': 'MPOENTPR1500 - US',
                        'name': 'PREMIUM 1500',
                        'description': {
                            'en': '1001 to 1500 employees',
                            'fr': 'de 1001 à 1500 employés'
                        }
                    },
                    'MPOENTPR2000 - US': {
                        'sku': 'MPOENTPR2000 - US',
                        'name': 'PREMIUM 2000',
                        'description': {
                            'en': '1501 to 2000 employees',
                            'fr': 'de 1501 à 2000 employés'
                        }
                    },
                    'MPOENTPR2500 - US': {
                        'sku': 'MPOENTPR2500 - US',
                        'name': 'PREMIUM 2500',
                        'description': {
                            'en': '2001 to 2500 employees',
                            'fr': 'de 2001 à 2500 employés'
                        }
                    },
                    'MPOENTPR3000 - US': {
                        'sku': 'MPOENTPR3000 - US',
                        'name': 'PREMIUM 3000',
                        'description': {
                            'en': '2501 to 3000 employees',
                            'fr': 'de 2501 à 3000 employés'
                        }
                    },
                    'MPOENTPR4000 - US': {
                        'sku': 'MPOENTPR4000 - US',
                        'name': 'PREMIUM 4000',
                        'description': {
                            'en': '3001 to 4000 employees',
                            'fr': 'de 3001 à 4000 employés'
                        }
                    },
                    'MPOENTPR5000 - US': {
                        'sku': 'MPOENTPR5000 - US',
                        'name': 'PREMIUM 5000',
                        'description': {
                            'en': '4001 to 5000 employees ($1000 extra /500 employees, client)',
                            'fr': 'de 4001 à 5000 employés (1000$ extra /500 employés, client)'
                        }
                    },
                    'MPOFSA50': {
                        'sku': 'MPOFSA50',
                        'name': 'PROFESSIONNELLE 50',
                        'description': {
                            'fr': 'Entente annuelle Jusqu\'à 50 employés',
                            'en': 'Annual agreement Up to 50 employees'
                        }
                    },
                    'MPOFSA150': {
                        'sku': 'MPOFSA150',
                        'name': 'PROFESSIONNELLE 150',
                        'description': {
                            'fr': 'Entente annuelle 51 à 150 employés',
                            'en': 'Annual agreement 51 to 150 employees'
                        }
                    },
                    'MPOFSA250': {
                        'sku': 'MPOFSA250',
                        'name': 'PROFESSIONNELLE 250',
                        'description': {
                            'fr': 'Entente annuelle 151 à 250 employés',
                            'en': 'Annual agreement 151 to 250 employees'
                        }
                    },
                    'MPOFSA500': {
                        'sku': 'MPOFSA500',
                        'name': 'PROFESSIONNELLE 500',
                        'description': {
                            'fr': 'Entente annuelle 251 à 500 employés',
                            'en': 'Annual agreement 251 to 500 employees'
                        }
                    },
                    'MPOFSA1000': {
                        'sku': 'MPOFSA1000',
                        'name': 'PROFESSIONNELLE 1000',
                        'description': {
                            'fr': 'Entente annuelle 501 à 2000 employés',
                            'en': 'Annual agreement 501 to 2000 employees'
                        }
                    },
                    'MPOFSA2000': {
                        'sku': 'MPOFSA2000',
                        'name': 'PROFESSIONNELLE 2000',
                        'description': {
                            'fr': 'Entente annuelle 2001 et plus employés',
                            'en': 'Annual agreement 2001 and more employees'
                        }
                    }
                }
            },
            'partner': {
                'CERTP1': {
                    'sku': 'CERTP1',
                    'name': 'CERTP1',
                    'description': {
                        'fr': 'Certification - 1 partenaire ',
                        'en': 'Certification - 1 partner'
                    }
                },
                'CERTP3': {
                    'sku': 'CERTP3',
                    'name': 'CERTP3',
                    'description': {
                        'fr': 'Certification - Groupe de 3 partenaires',
                        'en': 'Certification - Group of 3 partners'
                    }
                },
                'CERTP4PLUS': {
                    'sku': 'CERTP4PLUS',
                    'name': 'CERTP4PLUS',
                    'description': {
                        'fr': 'Certification - Groupe de 3 partenaires - 4e et plus',
                        'en': 'Certification - Group of 3 partners - 4th and more'
                    }
                },
                'CERTPMPOC': {
                    'sku': 'CERTPMPOC',
                    'name': 'CERTPMPOC',
                    'description': {
                        'fr': 'Certification - Consultant MPO pour partenaire',
                        'en': 'Certification - MPO Consultant for partner'
                    }
                },
                'CERTPA': {
                    'sku': 'CERTPA',
                    'name': 'CERTPA',
                    'description': {
                        'fr': 'Certification - Analyste MPO pour partenaire',
                        'en': 'Certification - MPO Analyst for partner'
                    }
                }
            },
            'consultant': {
                'CERTC1': {
                    'sku': 'CERTC1',
                    'name': 'CERTC1',
                    'description': {
                        'fr': 'Certification - 1 consultant',
                        'en': 'Certification - 1 consultant'
                    }
                },
                'CERTC3': {
                    'sku': 'CERTC3',
                    'name': 'CERTC3',
                    'description': {
                        'fr': 'Certification - Groupe de 3 consultants',
                        'en': 'Certification - Group of 3 consultants'
                    }
                },
                'CERTC4PLUS': {
                    'sku': 'CERTC4PLUS',
                    'name': 'CERTC4PLUS',
                    'description': {
                        'fr': 'Certification - Groupe de 3 consultants - 4e et plus',
                        'en': 'Certification - Group of 3 consultants - 4th and more'
                    }
                },
                'CERTCA': {
                    'sku': 'CERTCA',
                    'name': 'CERTCA',
                    'description': {
                        'fr': 'Certification - Analyste MPO pour Consultant',
                        'en': 'Certification - MPO Analyst for Consultant'
                    }
                }
            },
            'distributor': {
                'DistCa': {
                    'sku': 'DistCa',
                    'name': 'DistCa',
                    'description': {
                        'fr': 'Distributeur - Canada',
                        'en': 'Distributor - Canada'
                    }
                },
                'DistUs': {
                    'sku': 'DistUs',
                    'name': 'DistUs',
                    'description': {
                        'fr': 'Distributeur - États-Unis',
                        'en': 'Distributor - USA'
                    }
                },
                'DistEu': {
                    'sku': 'DistEu',
                    'name': 'DistEu',
                    'description': {
                        'fr': 'Distributeur - Europe',
                        'en': 'Distributor - Europe'
                    }
                }
            },
            'subsidiary': {
                'subsCa': {
                    'sku': 'subsCa',
                    'name': 'subsCa',
                    'description': {
                        'fr': 'Subsidiaire - Canada',
                        'en': 'Subsidiary - Canada'
                    }
                },
                'subsUs': {
                    'sku': 'subsUs',
                    'name': 'subsUs',
                    'description': {
                        'fr': 'Subsidiaire - États-Unis',
                        'en': 'Subsidiary - USA'
                    }
                },
                'subsEu': {
                    'sku': 'subsEu',
                    'name': 'subsEu',
                    'description': {
                        'fr': 'Subsidiaire - Europe',
                        'en': 'Subsidiary - Europe'
                    }
                }
            }
        };
    }

    protected readonly Object = Object;
}
