
import {first} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Subscription} from 'rxjs';

@Injectable()
export class VersionCheckService implements OnDestroy {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    stop: boolean = false;

    private subscriptions = new Subscription();

    constructor(
        private http: HttpClient
        ) {}

    /**
     *
     * @param url
     * @param _that
     * @param frequency
     */
    public initVersionCheck(url, _that, frequency = 1000*60*120) {
        setInterval(() => {
            if(!this.stop){
                this.checkVersion(_that, url);
            }
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     * @param _that placeholder for this
     */
    private checkVersion(_that, url) {
        // timestamp these requests to invalidate caches
        this.subscriptions.add(this.http.get(url).pipe(// + '?t=' + new Date().getTime())
            first())
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    // If new version, do something
                    if (hashChanged) {
                        _that.update = 'expired';
                        this.stop = true;
                    } else {
                        _that.update = 'fixed';
                        this.stop = false;
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                    _that.changeDetectorRef.markForCheck();
                }
            ));
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
