import {Content} from './content';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {ApplicationRef} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';


export abstract class ContentSearchFilters extends Content {

    abstract sortCardsBy: string;
    sortCardsOrder = 'asc';

    abstract columns: any;
    abstract listColumns: any;

    abstract preferences: boolean;
    abstract filter: boolean;

    datePickerOptions: any = {dateFormat: 'dd/mm/yyyy'};
    abstract dateStart: any;
    abstract dateEnd: any;
    abstract dateStartModel: any;
    abstract dateEndModel: any;
    abstract hiringDateModel: any;

    abstract testsOptions: any;
    abstract optionsTestsModel: any;

    abstract subAccountsOptions: any;
    abstract subAccountsOptionsModel: any;

    abstract departmentOptions: any;
    abstract departmentOptionsModel: any;

    abstract testStatuses: any;
    abstract optionsTestsStatusModel: any;
    abstract optionsTestsStatusRaModel: any;

    abstract testsStatusOptions: any;

    abstract checkedColumn: any;

    protected constructor(
        public stateService: StateService,
        protected router: Router,
        protected applicationRef: ApplicationRef,
        protected deviceService: DeviceDetectorService
    ) {
        super(router, deviceService, stateService);
    }

    /**
     * Set search parameter on event
     * @param event
     * @param key
     */
    setSearchFilter(event: Date | string, key = null): void {
        // search input case
        if (!key) {
            this.state.search = event as string;
        } else {
            // filters case
            if (key.includes('StartDate') || key.includes('EndDate')) {
                if (typeof event === 'object' && event.getTime() === 0) {
                    event = null;
                } else if (typeof event === 'object') {
                    event = this.dToMongoDate(event);
                }
            }
            this.setFilter(key, event);
        }
    }

    refreshPage(state = this.state) {
        state.stateChanged.next({
            'refreshComponent': true
        });
    }

    setFilter(key, value) {
        this.state.listSearchFilters[key] = value;
    }

    resetSearchFilters(noRefresh = false) {
        for (let report in this.state.listSearchFilters) {
            if (report && this.state.listSearchFilters.hasOwnProperty(report)) {
                if (report !== 'search') {
                    this.state.listSearchFilters[report] = null;
                }
            }
        }
        this.state.listColumns = this.state.listColumnsInitialConfig;
        if (this.actualModule.name === 'people') {
            this.state.listView = 'list';
        }
        if (!noRefresh) {
            this.refreshPage();
        }
    }

    resetSearchFiltersAction() {
        this.optionsTestsModel = [];
        this.subAccountsOptionsModel = [];
        this.departmentOptionsModel = [];
        this.optionsTestsStatusModel = [];
        this.optionsTestsStatusRaModel = [];
        this.dateStart = '';
        this.dateEnd = '';
        this.dateStartModel = null;
        this.dateEndModel = null;
        if (this.actualModule.name === 'people') {
            if (this.sortCardsOrder && this.sortCardsBy) {
                this.sortCardsOrder = 'asc';
                this.sortCardsBy = '';
            }
        }
    }

    checkPreferences(whereAmI) {
        this.preferences = (whereAmI === 'preferences');
        this.filter = (whereAmI === 'filter');
    }

    filtering(obj, col) {
        const storedItem = _.find(obj, function (o) {
            if (o && o.display === true) {
                return o.key === col;
            }
        });
        return !!((typeof storedItem !== 'undefined') && storedItem.display === true);
    }

    initColumnsFilter() {
        for (let col of this.columns) {
            this.checkedColumn[col] = this.filtering(this.listColumns, col);
        }
    }

    initDateFormat(format) {
        this.datePickerOptions.dateFormat = format;
    }

    initSubAccountFilter() {
        if (this.subAccountFilter) {
            // tslint:disable-next-line:forin
            for (let item in this.listSearchFilters) {
                if (this.listSearchFilters.hasOwnProperty(item)) {
                    this.listSearchFilters[item] =
                        String(this.listSearchFilters[item]);
                }
            }
        } else {
            this.subAccountsOptionsModel = [];
        }
    }

    setSubAccountsOptions() {
        /** Todo: how to check those already selected
         *  Todo: Fork for user's pref
         * */
        this.subAccountsOptions = [];
        // tslint:disable-next-line:forin
        for (let i in this.stateService.session.sessionData.structure.subAccounts) {
            if (this.stateService.session.sessionData.structure.subAccounts.hasOwnProperty(i)) {
                this.subAccountsOptions[i] = {
                    id: this.stateService.session.sessionData.structure.subAccounts[i].id,
                    name: this.stateService.session.sessionData.structure.subAccounts[i].name,
                    checked: true
                };
            }
        }
    }

    subAccount(event, obj, module = this.actualModule.name) {
        if (this.preferences) {
            event = event.map(function (x) {
                return x.toString();
            });
            event = _.uniq(event);
            obj.selectPreference.emit({
                module: module,
                key: 'subAccount',
                value: event.join(','),
                type: 'search'
            });
        } else {
            this.setFilter('subAccount', event);
        }
    }

    onDepartmentOptionChange(event, obj, module = this.actualModule.name) {
        if (this.preferences) {
            obj.selectPreference.emit({
                module: module,
                key: 'department',
                value: event.join(','),
                type: 'search'
            });
        } else {
            this.setFilter('department', event);
        }
    }

    setDepartmentsOptions() {
        // Department
        this.departmentOptions = [];
        for (let department of this.stateService.session.sessionData.structure.departments) {
            this.departmentOptions.push({
                id: department.id,
                name: department.fr
                // checked: true
            });
        }
    }

    setDepartmentsModel(state = this.state) {
        this.departmentOptionsModel = [];
        if (state.listSearchFilters.department) {
            if (typeof state.listSearchFilters.department === 'string') {
                let departs = state.listSearchFilters.department.split(',');
                for (let departmentId of departs) {
                    this.departmentOptionsModel.push(departmentId);
                }
            } else if (Array.isArray(state.listSearchFilters.department)) {
                this.departmentOptionsModel = state.listSearchFilters.department;
            }
        }
    }

    columnDisplay(colConfig, obj, specificState = this.actualModule.name) {
        if (this.preferences) {
            obj.selectPreference.emit({
                module: specificState,
                key: colConfig.key,
                value: colConfig.display,
                type: 'display'
            });
        } else {
            this.setColumn(colConfig);
        }
    }

    findIndex(param) {
        return _.findIndex(this.listColumns, function (o) {
            if (o) {
                return o.key === param;
            }
        });
    }

    setColumn(params) {
        if (params['key'] && params['display'] !== null && (typeof params['display'] !== 'undefined')) {
            let index = this.findIndex(params['key']);



            if (index > -1) {
                if (params['display']) {
                    this.listColumns[index] = {
                        key: params['key'],
                        display: params['display']
                    };
                } else {
                    this.listColumns.splice(index, 1);
                }
            } else {
                if (params['display']) {
                    this.listColumns.push({
                        key: params['key'],
                        display: params['display']
                    });
                }
            }
            this.state.listColumns = this.listColumns;
        }
    }

    get listSearchFilters() {
        return this.stateService[this.actualModule.name].listSearchFilters;
    }

    set listSearchFilters(listSearchFilters) {
        this.state.listSearchFilters = listSearchFilters;
    }

    get state() {
        return this.stateService[this.actualModule.name];
    }
}
