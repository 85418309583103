// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

.largeButton {
  height: 36px;
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.largeButton:last-child {
  margin-bottom: auto;
}

.largeButton:hover {
  background-color: #CCC;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/web/jobs-profiler-email-modal/jobs-profiler-email-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n\n.largeButton {\n    height: 36px;\n    width: 300px;\n    background-color: white;\n    border: 1px solid #ccc;\n    padding: 5px;\n    margin-bottom: 5px;\n    cursor: pointer;\n}\n\n.largeButton:last-child {\n    margin-bottom: auto;\n}\n\n.largeButton:hover {\n    background-color: #CCC;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
