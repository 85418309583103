import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import { take } from 'rxjs/operators';

import { StateService } from 'src/app/core/services/state/state.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { RightMatchModalHandlingService } from '../../../services/right-match-modal-handling.service';
import { RightMatchAnalysisService } from '../../../services/right-match-analysis.service';
import { ApiRightMatchesService } from '../../../services/api-right-matches.service';
import { RightMatchListService } from '../../../services/right-match-list.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddPeopleAnalysisModalComponent } from 'src/app/analysis/shared/add-people-analysis-modal/add-people-analysis-modal.component';
import { ComponentList } from 'src/app/shared/classes/components/component_list';
import { ResponsiveHelper } from 'src/app/shared/helpers/responsive.helper';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-right-matches-list',
    templateUrl: './right-matches-list.component.html',
    styleUrls: ['./right-matches-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesListComponent extends ComponentList implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('gSidebar') gSidebar;
    displayConstant = {
        adjustment: {left: -5, right: -14},
        widthRatio: 2 / 3,
        leftColumnWidth: 400
    };
    action;
    listWidth: any;
    sideWidth: any;
    itemToDisplay: any;
    isSelected = 'none';
    rightMatchModalStatus = 'new';
    peopleAnalysisModalRef: BsModalRef;

    private subscriptions = new Subscription();

    constructor(public router: Router,
                public rightMatch: RightMatchAnalysisService,
                public rightMatchModalHandlingService: RightMatchModalHandlingService,
                private route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,  // Even if flagged as unused, it is used in getList(this, search) below
                public stateService: StateService,
                public user: UserService,
                public rightMatchListService: RightMatchListService,
                private apiRightMatchesService: ApiRightMatchesService,
                private alertService: AlertService,
                protected translateService: TranslateService,
                protected deviceService: DeviceDetectorService,
                private modalService: BsModalService
    ) {
        super(stateService, translateService, router, deviceService);
        if (this.user.user && !this.stateService.hasPersonality) {
            if (this.stateService.hasCommunication) {
                this.router.navigate(['/analysis/idwmatrix/list']).then(() => {});
            } else {
                this.router.navigate(['/people/list']).then(() => {});
            }
        }
        // set the sidebar status
        this.rightMatchListService._opened = !this.smallSize;
    }

    ngOnInit() {
        this.rightMatchListService.resetList();
        if (!this.rightMatchListService.archive) {
            this.setLastAddress();
        }
        // Close add to analysis modal
        this.subscriptions.add(this.modalService.onHidden.subscribe(() => {
            // Reset Multi-action
            this.stateService.rightMatch.resetMultiAction();
            this.rightMatchListService.multiAction = null;
            // Reset itemToDisplay to reload the selected item after list update
            this.itemToDisplay = null;
            this.rightMatchListService.state.stateChanged.next(true);
        }));

        this.rightMatchListService.multiAction = null;
        this.rightMatchListService.checkModuleUrl();
        this.rightMatchListService.checkUserRightMatchDisplayPreferences();

        // check items list without selection
        this.rightMatchListService.checkTotalItems();

        if (this.rightMatch.id) {
            let action = 'forceSidebarToReport';
            if (this.rightMatchListService.rightMatchState.sideBarStatus === 'searchFilters') {
                action = 'resetSidebarToFilters';
            }
            this.rightMatch.setItemToDisplayWithId(this.rightMatch.id, this.rightMatchListService.specificState, action);
        }

        this.rightMatchListService.rightMatchState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.rightMatchListService.getRightMatchList(true, null, this);

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.rightMatchListService.getRightMatchList(true, null, this);
            }
        ));

        // Set list width
        this.computeListWidth(window);
    }

    setSearchFilter(event) {
        this.rightMatchListService.setSearchFilter(event);
        this.refreshList();
    }

    setOrder(event) {
        this.rightMatchListService.setOrder(event);
        this.refreshList();
    }

    ngAfterViewInit() {
        if (this.stateService.rightMatch.multiAction.ids.length !== 0) {
            this.rightMatchListService.multiAction = this.stateService.rightMatch.multiAction;
            this.stateService.rightMatch.resetMultiAction();

            if (this.rightMatchListService.multiAction['action'] === 'add') {
                // Open modal to add selected users to an existing analysis
                if (this.rightMatchListService.multiAction['data']) {
                    this.openRightMatchPeopleToAnalysisModal();
                }
            } else if (this.rightMatchListService.multiAction['action'] === 'create') {
                this.rightMatchModalHandlingService.openRightMatchModal({status: 'new'});
            }
        }
    }

    multiActionDestroy() {
        this.rightMatchListService.multiAction = null;
    }

    refreshList() {
        this.rightMatchListService.getRightMatchList(true, null, this);
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.rightMatchListService._opened = true;
            this.gSidebar.open();
        }
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);

        this.rightMatchListService._opened = !this.smallSize;
        this.closed = this.smallSize;
    }

    /**
     * Close Right Match sidebar tablet device
     */
    closeRightMatchSidebar(closeRightMatchSidebar: boolean): void {
        this.stateService.rightMatch.lastSideBarStatus = 'recentActivities';
        if (closeRightMatchSidebar && this.rightMatchListService._opened && this.smallSize) {
            this.rightMatchListService._opened = false;
            this.closed = true;
        }
    }

    itemSelected(event) {
        this.closed = false;
        this.stateService.rightMatch.lastSideBarStatus = 'reports';
        this.rightMatchListService.itemSelected(event);
    }

    computeListWidth(window: Window): void {
        let checkWidth = ResponsiveHelper.computeListWidth(window.innerWidth, this.displayConstant);
        this.sideWidth = checkWidth.sideWidth;
        this.listWidth = checkWidth.listWidth;
        this.changeDetectorRef.markForCheck();
    }

    actOnRecords(event) {
        let total = event.ids.length;
        let handled = 0;
        this.action = event.actionType;
        if (this.action === 'delete') {
            let pAction = this.translateService.instant('commons.deletionAction');
            let plural = (event.ids.length > 1) ? 's' : '';
            let paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                action: pAction,
                total: total,
                plural: plural
            });
            let alertText: string;
            const deleteTxt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(deleteTxt);
            this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm' && this.action === 'delete') {
                        // Delete all selected ids
                        this.apiRightMatchesService.deleteRightMatches(event.ids).pipe(take(1)).subscribe(
                            () => {
                                this.rightMatchListService.loading = true;
                                paragraph = this.translateService.instant('analysis.commons.rightMatchDeleted', {
                                    total: total,
                                    plural: plural
                                });
                                alertText = this.translateService.instant('analysis.commons.rightMatchDeletion');
                                const txtDelete = {
                                    paragraph: paragraph,
                                    texth1: alertText,
                                    position: 'top-right',
                                    type: 'green'
                                };
                                this.rightMatchListService.getRightMatchList(true, txtDelete, this);
                                this.rightMatch.resetItemToDisplay(this.specificState());
                                this.rightMatchListService.revertToNews();
                                this.rightMatchListService.cleanMultiselectMode(true);
                            },
                            () => {
                                this.rightMatchListService.loading = true;
                                const txtDeleteError = {
                                    paragraph: this.translateService.instant('commons.tryAgain'),
                                    texth1: this.translateService.instant('commons.wasError'),
                                    position: 'center-center',
                                    type: 'red'
                                };
                                this.rightMatchListService.getRightMatchList(true, txtDeleteError, this);
                            }
                        );
                        this.action = undefined;
                    }
                }
            );
        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            let plural = (event.ids.length > 1) ? 's' : '';
            let pAction;
            let paragraph;
            switch (this.action) {
                case 'archive':
                    putObject = {archive: true};
                    p[0] = this.translateService.instant('analysis.rightMatch.multipleArchived', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleArchiving');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.archivingError');
                    break;
                case 'reactivate':
                    putObject = {archive: false};
                    p[0] = this.translateService.instant('analysis.rightMatch.multipleReactivated', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleReactivation');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.reactError');
                    break;
            }
            if (this.action === 'archive') {
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtWarningArchive = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtWarningArchive);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'archive') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.rightMatchListService.revertToNews();
                            this.rightMatchListService.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action === 'reactivate') {
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.rightMatch.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtArchivingConfirmation = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }
        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.action) {
            let handledCounter = handled;
            for (let id of event.ids) {
                this.apiRightMatchesService.putRightMatch([id], putObject).pipe(take(1)).subscribe(
                    () => {
                        handledCounter++;
                        if (handledCounter === total) {
                            this.rightMatchListService.loading = true;
                            this.rightMatchListService.getRightMatchList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            }, this);
                            this.rightMatchListService.revertToNews();
                            this.rightMatchListService.cleanMultiselectMode(true);
                        }
                        this.refreshList();
                    },
                    () => {
                        this.rightMatchListService.loading = true;
                        this.rightMatchListService.getRightMatchList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        }, this);
                    }
                );
            }
            this.action = undefined;
        }
    }

    openRightMatchPeopleToAnalysisModal() {
        this.rightMatchListService.revertToNews();
        this.rightMatchListService.cleanMultiselectMode(true);
        this.apiRightMatchesService.rightMatches([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = this.rightMatchListService.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [record.id]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                modalTitle: 'commons.addToRm',
                analysisType: 'rightmatch'
            };

            // Open modal
            this.peopleAnalysisModalRef = this.modalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected readonly parseInt = parseInt;
}
