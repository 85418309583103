import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {RightMatchAnalysisService} from '../../../../right-matches/services/right-match-analysis.service';
import {ApiRightMatchesService} from '../../../../right-matches/services/api-right-matches.service';
import {WindowRef} from '../../../../../app/services/window-ref.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../../core/services/user/user.service';
import {EnvService} from '../../../../../app/services/env.service';
import * as _ from 'lodash';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {JobSatisfactionTeamGraphHelper} from '../../commonClasses/jobSatisfactionTeamGraphHelper';

@Component({
  selector: 'app-mobile-job-satisfactions-pdf',
  templateUrl: './mobile-job-satisfactions-pdf.component.html',
  styleUrls: ['./mobile-job-satisfactions-pdf.component.scss']
})
export class MobileJobSatisfactionsPdfComponent  implements OnInit , OnDestroy {

    @Output() close = new EventEmitter();

    private subscriptions = new Subscription();

    pdfStructure = {
        parts: {
            'analyst': '1',
            'frontPage': true,
            'team': true,
            'person': true,
            'personSummary': true,
            'personText': true
        },
        selectedPersons: []
    };

    selectMethod = false;
    method = 'pdf';
    share = 'none';

    pdfEmail: any;

    showPerson = true;

    nativeWindow: any;


    mailto = null;
    mailToJson = null;
    pdfToken = null;
    pdfReportUrl = null;

    serverText: string;

    public subcriptions = new Subscription();

    constructor(
        public translate: TranslateService,
        public jobSatisfaction: JobSatisfactionsAnalysisService,
        public apiJobSatisfaction: ApiJobSatisfactionsService,
        private windowRef: WindowRef,
        public changeDetectorRef: ChangeDetectorRef,
        public user: UserService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.initPdfStructure();
    }



    initPdfStructure() {
        // PDF structure
        this.pdfStructure = Object.assign({}, {
            parts: {
                'analyst': '1',
                'frontPage': true,
                'team': true,
                'person': true,
                'personSummary': true,
                'personText': true
            },
            selectedPersons: []
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: this.translate.instant('commons.jobSatisfactionReportTitle'),
            message: '',
        });


        // PDF link
        this.mailto = null;
        this.mailToJson = null;
        this.pdfToken = null;
        this.pdfReportUrl = null;

        this.showPerson = true;
        this.changeDetectorRef.markForCheck();
    }

    changeSelectMethodState() {
        if (this.method !== 'pdf') {
            this.selectMethod = false;
        }
    }

    handleReport() {
        if (this.method !== 'none') {
            switch (this.method) {
                case 'pdf':
                    this.downloadReport();
                    return;
                case 'cutpaste':
                    this.share = 'cutpaste';
                    this.getReportData({type: 'token'});
                    return;
                case 'mailto':
                    this.share = 'mailto';
                    this.getReportData({type: 'mailto'});
                    return;
                case 'server':
                    this.share = 'server';
                    this.getReportData({type: 'share'});
                    return;
            }
        }
        this.selectMethod = true;
        return;
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    getReportData(data) {
        // JobSatisfactionsAnalysisService
        let dataBack = '';
        let dataSet = JobSatisfactionTeamGraphHelper.setData(this.jobSatisfaction.records, 12);
        if (this.jobSatisfaction.records && this.jobSatisfaction.records.length !== 0) {
            dataBack = JobSatisfactionTeamGraphHelper.setDataBack(dataSet[dataSet.length - 1]);
        }
        // @ts-ignore
        this.pdfStructure.parts.analyst = (this.pdfStructure.parts.analyst === '1');
        let obs = this.apiJobSatisfaction.reportJobSatisfactions([this.jobSatisfaction.id], [
            this.pdfStructure.parts,
            this.translate.currentLang,
            dataBack
        ]);
        this.subcriptions.add(obs.subscribe(
            res => {
                const reportName = this.translate.instant('commons.jobSatisfactionReportTitle');
                this.pdfToken = res.pdfToken;

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + encodeURI(this.pdfToken);

                if (data.type === 'mailto') {
                    this.mailto = '?subject=' + this.translate.instant('commons.report') + ' '
                        + reportName + '&body=' +
                        this.translate.instant('jobSatisfactions.pdfModal.linkInstruction', {
                            reportName: reportName,
                        }) + '%0A%0A' + this.pdfReportUrl;
                    this.mailToJson = {
                        mailto: this.mailto
                    };
                } else if ('share' === data.type) {
                    this.serverText = this.translate.instant('jobSatisfactions.pdfModal.linkInstruction', {
                        reportName: reportName,
                    }) + this.pdfReportUrl;
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.cancel();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport d\' analyse de Bien-être (IBT)' : 'Wellbeing Potential Analysis Report';
        }

        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.jobSatisfaction.name,
            'jobSatisfaction',
            this.translate.currentLang
        ];

        this.subcriptions.add(this.apiJobSatisfaction.reportEmail(dataEmail).subscribe());
        this.cancel();
    }

    //////

    /**
    onChange(event) {
        if (event && event.source && event.source.id) {
            this.optionsCheckboxes[event.source.id] = event.checked;
            let totalChecked = 0;
            for (let opt in this.optionsCheckboxes) {
                if (opt && this.optionsCheckboxes.hasOwnProperty(opt) && this.optionsCheckboxes[opt]) {
                    totalChecked++;
                }
            }
            if (totalChecked < 1) {
                this.pdfStructure[event.source.id] = true;
                let currentCheckbox = document.getElementById(event.source.id);
                if (currentCheckbox) {
                    setTimeout(() => {
                        currentCheckbox.classList.add('mat-checkbox-checked');
                    });
                }
            }
        }
    }*/

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
        this.close.emit(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }


    onComplete() {
        this.cancel();
        this.initPdfStructure();
        this.close.emit();
    }

}
