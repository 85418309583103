import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApiJobsService} from './api-jobs.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class JobReportService {

    getReportSubscription: Subscription;

    constructor(
        private apiJobService: ApiJobsService,
        private translate: TranslateService
        ) {
    }

    getReport(o, id, requiredGlobalNames = {report: 'report', changeDetectorRef: 'changeDetectorRef'}) {
        this.getReportSubscription =
            this.apiJobService.reportJob(
                [id],
                [
                    [1], // was [1,3] but part 3 is not required and cause bugs from the back thrown when no MPO target.
                    0, // Index of the evaluation object is (so far) always 0
                    this.translate.currentLang
                ]
            ).subscribe(
                res => {
                    o[requiredGlobalNames.report] = res;
                    o[requiredGlobalNames.changeDetectorRef].markForCheck();
                    this.getReportSubscription.unsubscribe();
                    // console.log(res);
                },
                err => console.log(err)
            );
    }

}
