import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-people-assistant-modal-questions',
  templateUrl: './people-assistant-modal-questions.component.html',
  styleUrls: ['./people-assistant-modal-questions.component.scss']
})
export class PeopleAssistantModalQuestionsComponent implements OnInit {

  otherIconsFolder = './assets/other_icons/';

  @Input()
  openCategoryEvent: EventEmitter<number>;

  types: any[] = [];
  clickedTypeIndex: number = -1;
  clickedCategoryIndex: number = -1;
  expandedTypeIndex: number = -1;
  expandedCategoryIndex: number = -1;
  selectedType: string = null;

  @Output()
  questionSelected = new EventEmitter<string>();

  buttonNameToTypeName = {
    'personaliteSelected': 'Personalité et IAC',
    'pcrSelected': 'PCR',
    'talentsSelected': 'Talents',
    'analyseSelected': "Aide à l'analyse"
  };

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<any[]>('assets/assistant-questions/questions.json').subscribe(data => {
      this.types = data;
      // console.log('Types:', this.types);
      this.selectedType = 'Personalité et IAC';

    });
    this.openCategoryEvent.subscribe((buttonName: string) => {
      let typeName = this.buttonNameToTypeName[buttonName];
      if (typeName) {
        this.openCategory(typeName);
        // console.log('openCategoryEvent called, typeName:', typeName);
      } else {
        // console.log('No match found for buttonName:', buttonName);
      }
    });
  }

openCategory(typeName: string) {
    let tIndex = this.types.findIndex(type => type.typeName === typeName);
    if (tIndex !== -1) {
      if (this.selectedType === this.types[tIndex].typeName) {
        this.selectedType = null;
        // console.log('openCategory called, type index:', tIndex, 'selectedType: none (category closed)');
      } else {
        this.selectedType = this.types[tIndex].typeName;
        // console.log('openCategory called, type index:', tIndex, 'selectedType:', this.selectedType);
      }
    } else {
      console.log('No match found for typeName:', typeName);
    }
  }


  toggleAccordion(tIndex: number, cIndex: number): void {
    if (this.clickedTypeIndex === tIndex && this.clickedCategoryIndex === cIndex) {
        this.clickedTypeIndex = -1;
        this.expandedTypeIndex = -1;
        this.clickedCategoryIndex = -1;
        this.expandedCategoryIndex = -1;
    } else {
        if (this.expandedTypeIndex !== -1) {
            this.clickedTypeIndex = -1;
            this.expandedTypeIndex = -1;
            this.clickedCategoryIndex = -1;
            this.expandedCategoryIndex = -1;

            // Add a delay here to allow the CSS transition to complete
            setTimeout(() => {
                this.clickedTypeIndex = tIndex;
                this.expandedTypeIndex = tIndex;
                this.clickedCategoryIndex = cIndex;
                this.expandedCategoryIndex = cIndex;
            }, 500); // Adjust this value to match your CSS transition duration
        } else {
            this.clickedTypeIndex = tIndex;
            this.expandedTypeIndex = tIndex;
            this.clickedCategoryIndex = cIndex;
            this.expandedCategoryIndex = cIndex;
        }
    }
}


  onQuestionClick(question: string) {
    this.questionSelected.emit(question);
  }
}
