// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creditBoxInner div:first-child p {
  margin-bottom: 6px;
  padding-bottom: 0;
}

.creditBoxInner div:nth-child(2),
.creditBoxInner div:nth-child(3) {
  border: 1px solid #ccc;
}

.creditBoxInner div:nth-child(2) {
  margin-bottom: 5px;
}

.creditBoxInner div:nth-child(3) {
  background-color: #bebebe;
}

.creditBoxInner div:nth-child(2) p,
.creditBoxInner div:nth-child(3) p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.creditsAvailable, .creditCost {
  padding: 6.5px;
}

.redWarning {
  color: rgb(240, 123, 126);
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/request-form/credits/credits.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;;AACA;;EAEI,sBAAA;AAEJ;;AAAA;EACI,kBAAA;AAGJ;;AADA;EACI,yBAAA;AAIJ;;AAFA;;EAEI,SAAA;EACA,UAAA;EACA,kBAAA;AAKJ;;AAFA;EACI,cAAA;AAKJ;;AAFA;EACI,yBAAA;AAKJ","sourcesContent":[".creditBoxInner div:first-child p {\n    margin-bottom: 6px;\n    padding-bottom: 0;\n}\n.creditBoxInner div:nth-child(2),\n.creditBoxInner div:nth-child(3) {\n    border: 1px solid #ccc;\n}\n.creditBoxInner div:nth-child(2) {\n    margin-bottom: 5px;\n}\n.creditBoxInner div:nth-child(3) {\n    background-color: #bebebe;\n}\n.creditBoxInner div:nth-child(2) p,\n.creditBoxInner div:nth-child(3) p {\n    margin: 0;\n    padding: 0;\n    text-align: center;\n}\n\n.creditsAvailable, .creditCost {\n    padding: 6.5px;\n}\n\n.redWarning {\n    color: rgb(240, 123, 126)\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
