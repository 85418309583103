import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {StateService} from '../../../core/services/state/state.service';
import {AccountService} from '../../services/account.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {AdminModalHandlingService} from '../../services/admin-modal-handling.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../shared/alert/services/alert.service';

@Component({
    selector: 'app-administration-account',
    templateUrl: './administration-account.component.html',
    styleUrls: ['./administration-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountComponent implements OnInit, OnDestroy {
    fragment: string;
    displayWidth: string;
    sideWidth: string;
    principalWidth: string;

    subAccountId: number;

    newSubAccount = false;

    private subscriptions = new Subscription();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                public stateService: StateService,
                public clientAccount: AccountService,
                private apiAdministrationService: ApiAdministrationService,
                public adminModalHandlingService: AdminModalHandlingService,
                public alertService: AlertService,
                public translate: TranslateService
    ) {
    }
    ngOnInit() {
        let newItem = (this.router.url.split('/account/')[1] === 'new' || this.router.url.split('/subaccount/')[1] === 'new');
        if (newItem) {
            this.newSubAccount = true;
        } else {
            const urlArray = this.router.url.split('/');
            let accountId = urlArray[urlArray.indexOf('account') + 1];
            let subaccountId = (urlArray.indexOf('subaccount') > -1) ? urlArray[urlArray.indexOf('subaccount') + 1] : null;
            if (subaccountId && subaccountId.includes('#')) {
                this.subAccountId = parseInt(subaccountId.split('#')[0], 10);
            } else {
                this.subAccountId = 0;
            }

            this.clientAccount.setAccountToDisplayWithId(accountId);
        }
        if (!this.newSubAccount) {
            this.subscriptions.add(this.router.events.subscribe(s => {
                if (s instanceof NavigationEnd) {
                    const tree = this.router.parseUrl(this.router.url);
                    if (tree.fragment) {

                        const element = document.querySelector('#' + tree.fragment);
                        // console.log(element);
                        if (element) {
                            element.scrollIntoView();
                        }
                    }
                }
            }));

            this.computeListWidth(window);

            this.checkSubAccountId();
            this.adminState.stateChanged$.subscribe(
                (res) => {
                    if (res) {
                        if (!res.reset) {
                            this.checkSubAccountId();
                        }
                        this.changeDetectorRef.markForCheck();
                    }
                }
            );
        }
    }

    onResize(event) {
        // re-Set list width
        this.computeListWidth(event.target);
    }

    checkSubAccountId() {
        this.subAccountId = this.clientAccount.masterSubAccountId;
        if (this.clientAccount.subAccountFromUrl) {
            this.subAccountId = Number.parseInt(this.clientAccount.subAccountFromUrl, 10);
        }
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 3 / 4;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (!this.clientAccount.smallSize && !this.newSubAccount) {
            widthRatio = 5 / 6;
            this.displayWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
            this.sideWidth = '250px';
            this.principalWidth = (window.innerWidth - 367) + 'px';
        } else {
            this.displayWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
            let displayWidthNumber = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left;
            this.sideWidth = (displayWidthNumber - 35) + 'px';
            this.principalWidth = (window.innerWidth - 117) + 'px';
        }
        return;
    }

    /**
     * Destroy component: free Observables
     */
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    topFunction() {
        this.router.navigateByUrl('admin/account/' + this.clientAccount.id + '#accountInfo')
            .then(
                () =>
                    this.router.navigateByUrl('admin/account/' + this.clientAccount.id)
            );
    }

    activateExtendDemoWarning() {
        const extendTxt = {
            paragraph: this.translate.instant('admin.commons.demoActivityConfirm'),
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };
        this.alertService.setConfig(extendTxt);
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm') {
                    let newDate = new Date();
                    newDate.setDate(newDate.getDate() + 60);
                    let newMonth = newDate.getMonth() + 1;
                    let monthDate = (newMonth < 10) ? '0' + newMonth : newMonth;
                    let dayDate = (newDate.getDate() < 10) ? '0' + newDate.getDate() : newDate.getDate();
                    let formattedDate = newDate.getFullYear() + '-' + monthDate + '-' + dayDate;
                    let payload = {
                        accountType: 'demo',
                        expiresAt: formattedDate
                    };
                    this.subscriptions.add(this.apiAdministrationService.putAccount([this.clientAccount.id], payload).subscribe(
                        // tslint:disable-next-line:no-shadowed-variable
                        () => {
                            const txtAdded = {
                                paragraph: this.translate.instant('admin.commons.demoActivityParagraph'),
                                texth1: this.translate.instant('admin.commons.demoActivityMessage'),
                                position: 'top-right',
                                type: 'green'
                            };
                            this.alertService.setConfig(txtAdded);
                            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
                        },
                        () => {
                            const txtError = {
                                paragraph: this.translate.instant('commons.tryAgain'),
                                texth1: this.translate.instant('commons.wasError'),
                                position: 'center-center',
                                type: 'red'
                            };
                            this.alertService.setConfig(txtError);
                        }
                    ));
                }
            }
        ));
    }

    get adminState() {
        return this.stateService.admin;
    }
}
