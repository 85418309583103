// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  margin-bottom: 0;
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.57);
}

.name {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

.modLink {
  display: inline-block;
  width: 50%;
  float: right;
  text-align: right;
}

.clear {
  clear: both;
}

.sticky .panelHr {
  margin: 10px;
}

.informationPanel table, #talentsDisplay {
  width: 100%;
}

#talentsDisplay tr {
  height: 20px;
}

#talentsDisplay td:not(.alignBottom) {
  padding: 2px 0;
}

#talentsDisplay td:first-child {
  width: 95%;
}

.graph-container {
  margin-bottom: 10px;
  max-width: 100%;
}

.reportDescription {
  margin-bottom: 110px;
}

.talentsPagination .panelHr {
  margin-bottom: 8px;
}

.raWrap {
  height: 330px;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 15px;
}

.raWrap .bold {
  font-weight: bold;
}

.raScore {
  margin-top: 10px;
  font-size: 14pt;
}

.footer {
  bottom: 0;
}

.cardEvaluationTitle {
  font-size: 85%;
  text-align: center;
  margin: 0;
  padding: 0;
}

::ng-deep .informationPanel .mat-expansion-panel-body {
  padding: 0 16px 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/mobile/mobile-jobs-report/mobile-jobs-report.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,iBAAA;EACA,SAAA;EACA,UAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gCAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":["h2 {\n    margin-bottom: 0;\n    padding-bottom: 0;\n    color: rgba(0, 0, 0, 0.57);\n}\n\n.name {\n    font-size: 1.4rem;\n    margin: 0;\n    padding: 0;\n}\n\n.modLink {\n    display: inline-block;\n    width: 50%;\n    float: right;\n    text-align: right;\n}\n\n.clear {\n    clear: both;\n}\n\n.sticky .panelHr {\n    margin: 10px;\n}\n\n.informationPanel table, #talentsDisplay {\n    width: 100%;\n}\n\n#talentsDisplay tr {\n    height: 20px;\n}\n\n#talentsDisplay td:not(.alignBottom) {\n    padding: 2px 0;\n}\n\n#talentsDisplay td:first-child {\n    width: 95%;\n}\n\n.graph-container {\n    margin-bottom: 10px;\n    max-width: 100%;\n}\n\n.reportDescription {\n    margin-bottom: 110px;\n}\n\n.talentsPagination .panelHr {\n    margin-bottom: 8px;\n}\n\n.raWrap {\n    height: 330px;\n    border-bottom: 1px solid #aaaaaa;\n    margin-bottom: 15px;\n}\n\n.raWrap .bold {\n    font-weight: bold;\n}\n\n.raScore {\n    margin-top: 10px;\n    font-size: 14pt;\n}\n\n.footer {\n    bottom: 0;\n}\n\n.cardEvaluationTitle {\n    font-size: 85%;\n    text-align: center;\n    margin: 0;\n    padding: 0;\n}\n\n::ng-deep .informationPanel .mat-expansion-panel-body {\n    padding: 0 16px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
