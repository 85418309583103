// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/unaccessible-tests-modal/unaccessible-tests-modal.component.scss"],"names":[],"mappings":"AACA;EACI,sBAAA;AAAJ","sourcesContent":["\n.modal-body {\n    background-color: #EEE;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
