import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuardService} from '../app/services/auth-guard.service';

import {RightMatchesListComponent} from './right-matches/components/web/right-matches-list/right-matches-list.component';
import {RightMatchesReportComponent} from './right-matches/components/web/right-matches-report/right-matches-report.component';
import {GraphboardsListComponent} from './graphboards/components/web/graphboards-list/graphboards-list.component';
import {GraphboardsReportComponent} from './graphboards/components/web/graphboards-report/graphboards-report.component';
import {IdwMatrixListComponent} from './idw-matrices/components/web/idw-matrix-list/idw-matrix-list.component';
import {IdwMatrixReportComponent} from './idw-matrices/components/web/idw-matrix-report/idw-matrix-report.component';
import {MobileRightMatchesListComponent} from './right-matches/components/mobile/mobile-right-matches-list/mobile-right-matches-list.component';
import {MobileRightMatchesReportComponent} from './right-matches/components/mobile/mobile-right-matches-report/mobile-right-matches-report.component';
import {MobileIdwMatrixListComponent} from './idw-matrices/components/mobile/mobile-idw-matrix-list/mobile-idw-matrix-list.component';
import {MobileGraphboardsListComponent} from './graphboards/components/mobile/mobile-graphboards-list/mobile-graphboards-list.component';
import {MobileIdwMatrixReportComponent} from './idw-matrices/components/mobile/mobile-idw-matrix-report/mobile-idw-matrix-report.component';
import {MobileGraphboardsReportComponent} from './graphboards/components/mobile/mobile-graphboards-report/mobile-graphboards-report.component';
import {
    JobSatisfactionsListComponent
} from './jobSatisfactions/components/web/job-satisfactions-list/job-satisfactions-list.component';
import {
    JobSatisfactionsReportComponent
} from './jobSatisfactions/components/web/job-satisfactions-report/job-satisfactions-report.component';
import {
    MobileJobSatisfactionsListComponent
} from './jobSatisfactions/components/mobile/mobile-job-satisfactions-list/mobile-job-satisfactions-list.component';
import {
    MobileJobSatisfactionsReportComponent
} from './jobSatisfactions/components/mobile/mobile-job-satisfactions-report/mobile-job-satisfactions-report.component';

@NgModule({
    imports: [RouterModule.forChild([
        {
            path: 'analysis',
            redirectTo: '/analysis/list',
            pathMatch: 'full'
        },
        {
            path: 'analysis/list',
            redirectTo: '/analysis/rightmatch/list',
            pathMatch: 'full'
        },

        {
            path: 'analysis/rightmatch/list',
            component: RightMatchesListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/rightmatch/report/:itemToDisplayId',
            component: RightMatchesReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/jobsatisfaction/list',
            component: JobSatisfactionsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/jobsatisfaction/report/:itemToDisplayId',
            component: JobSatisfactionsReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/graphboard/list',
            component: GraphboardsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/graphboard/report/:itemToDisplayId',
            component: GraphboardsReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/idwmatrix/list',
            component: IdwMatrixListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'analysis/idwmatrix/report/:itemToDisplayId',
            component: IdwMatrixReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis',
            redirectTo: '/m/analysis/list',
            pathMatch: 'full'
        },
        {
            path: 'm/analysis/list',
            redirectTo: '/m/analysis/rightmatch/list',
            pathMatch: 'full'
        },
        {
            path: 'm/analysis/rightmatch/list',
            component: MobileRightMatchesListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/rightmatch/report/:itemToDisplayId',
            component: MobileRightMatchesReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/jobsatisfaction/list',
            component: MobileJobSatisfactionsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/jobsatisfaction/report/:itemToDisplayId',
            component: MobileJobSatisfactionsReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/idwmatrix/list',
            component: MobileIdwMatrixListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/idwmatrix/report/:itemToDisplayId',
            component: MobileIdwMatrixReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/graphboard/list',
            component: MobileGraphboardsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/analysis/graphboard/report/:itemToDisplayId',
            component: MobileGraphboardsReportComponent,
            canActivate: [AuthGuardService]
        }
        /*
        {
            path: 'analysis/report/:jobToDisplayId/:reportType',
            component: MobileJobsReportComponent,
            canActivate: [AuthGuardService]
        }*/
    ])],
    exports: [RouterModule]
})
export class AnalysisRoutingModule {
}
