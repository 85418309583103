// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.surveyRequestTable {
  width: 100%;
  margin-bottom: 150px;
}
.surveyRequestTable td {
  vertical-align: top;
  padding-top: 5px;
}
.surveyRequestTable.twoCols {
  margin-bottom: 50px;
}
.surveyRequestTable.twoCols td:first-child {
  width: 60%;
}
.surveyRequestTable .alignRight {
  text-align: right;
}
.surveyRequestTable button {
  margin-right: 10px;
}
.surveyRequestTable .mdc-button {
  background-color: #666 !important;
  color: #fff !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/request-form/mobile/mobile-people-survey-request-form/mobile-people-survey-request-form.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,oBAAA;AACJ;AAAI;EACI,mBAAA;EACA,gBAAA;AAER;AAAI;EACI,mBAAA;AAER;AADQ;EACI,UAAA;AAGZ;AAAI;EACI,iBAAA;AAER;AAAI;EACI,kBAAA;AAER;AAAI;EACI,iCAAA;EACA,sBAAA;EACA,4DAAA;EACA,0BAAA;EACA,2BAAA;AAER","sourcesContent":[".surveyRequestTable {\n    width: 100%;\n    margin-bottom: 150px;\n    td {\n        vertical-align: top;\n        padding-top: 5px;\n    }\n    &.twoCols {\n        margin-bottom: 50px;\n        td:first-child {\n            width: 60%;\n        }\n    }\n    .alignRight {\n        text-align: right;\n    }\n    button {\n        margin-right: 10px;\n    }\n    .mdc-button {\n        background-color: #666!important;\n        color: #fff!important;\n        font-family: Roboto, \"Helvetica Neue\", sans-serif!important;\n        font-size: 14px!important;\n        font-weight: 500!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
