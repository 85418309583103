import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-job-satisfactions-person-sub-card-talents',
  templateUrl: './job-satisfactions-person-sub-card-talents.component.html',
  styleUrls: ['./job-satisfactions-person-sub-card-talents.component.scss']
})
export class JobSatisfactionsPersonSubCardTalentsComponent implements OnInit {

    @Input() record;
    @Input() pos;
    @Input() listView;
    @Input() informationVisibility;

  constructor() { }

  ngOnInit(): void {
  }

}
