// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .analysisListDiv .mat-icon-button {
  height: 25px;
  width: 25px;
  line-height: 24px;
}

::ng-deep .analysisListDiv .mat-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  line-height: 20px;
}

#analysisListing {
  border-collapse: collapse;
  width: 100%;
}

#analysisListing tr:first-child {
  border-top: 2px solid #CCCCCC;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/right-matches/components/web/right-matches-report-analysis/right-matches-report-analysis.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AACA;EACI,YAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,yBAAA;EACA,WAAA;AAGJ;;AAAA;EACI,6BAAA;AAGJ","sourcesContent":["::ng-deep .analysisListDiv .mat-icon-button {\n    height: 25px;\n    width: 25px;\n    line-height: 24px;\n}\n::ng-deep .analysisListDiv .mat-icon {\n    height: 20px;\n    width: 20px;\n    font-size: 20px;\n    line-height: 20px;\n}\n#analysisListing {\n    border-collapse: collapse;\n    width: 100%;\n}\n\n#analysisListing tr:first-child {\n    border-top: 2px solid #CCCCCC;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
