import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-archives-people-list',
    templateUrl: './archives-people-list.component.html',
    styleUrls: ['./archives-people-list.component.scss']
})
export class ArchivesPeopleListComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
