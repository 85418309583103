import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Content} from 'src/app/shared/classes/services/content';
import {TranslateService} from '@ngx-translate/core';
import {ApiJobsService} from './api-jobs.service';
import {JobsListService} from './jobs-list.service';
import {JobService} from './job.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JobsEditModalComponent} from '../components/web/jobs-edit-modal/jobs-edit-modal.component';
import {JobProfilerRequestResendModalComponent} from '../components/web/job-profiler-request-resend-modal/job-profiler-request-resend-modal.component';
import {NoJobModalComponent} from '../components/web/no-job-modal/no-job-modal.component';
import {JobsPdfModalComponent} from '../components/web/jobs-pdf-modal/jobs-pdf-modal.component';
import {JobsProfilerEmailModalComponent} from '../components/web/jobs-profiler-email-modal/jobs-profiler-email-modal.component';
import {JobsDuplicateModalComponent} from '../components/web/jobs-duplicate-modal/jobs-duplicate-modal.component';
import {UnaccessibleTestsModalComponent} from '../../shared/components/unaccessible-tests-modal/unaccessible-tests-modal.component';

@Injectable({
    providedIn: 'root'
})
export class JobsModalHandlingService extends Content {

    noJobModal: BsModalRef;
    jModalRef: BsModalRef;
    profilerResendModal: BsModalRef;
    jProfilerEmailModal: BsModalRef;
    jPdfModal: BsModalRef;
    jDuplicateModal: BsModalRef;
    noAccessTestsModal: BsModalRef;

    jobModalStatus = 'new';
    jobIacModalStatus = 'new';

    constructor(
        protected router: Router,
        private apiJobs: ApiJobsService,
        public jobsList: JobsListService,
        public jobService: JobService,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    /**
     * Job edit modal
     */
    jobEditModal(event, config = 'list') {
        this.jobModalStatus = event.status;
        this.jobsList.cleanMultiselectMode(true);
        const initialState = {
            status: this.jobModalStatus,
            section: event.section
        };
        this.jModalRef = this.modalService.show(JobsEditModalComponent, {initialState, backdrop : 'static'});
        this.setModalClass('app-jobs-edit-modal');
        this.jModalRef.content.close.subscribe(res => {
            if (res.closed && res.id) {
                if (config === 'list') {
                    this.refreshJob(res);
                } else {
                    if (event && event.display) {
                        this.state.report.display = event.display;
                    }
                    this.jobService.setItemToDisplayWithId(res.id);
                }
            }
        });
        this.jModalRef.content.scrollTop.subscribe(res => {
            if (res) {
                this.scrollJobsModalTop(res);
            }
        });
    }

    /**
     *  No job modal
     */
    openNoJobModal() {
        this.jobsList.revertToNews();
        this.jobsList.cleanMultiselectMode(true);
        this.noJobModal = this.modalService.show(NoJobModalComponent, {backdrop : 'static'});
        this.noJobModal.content.close.subscribe(res => {
            if (res.newJob) {
                this.jobEditModal({status: 'new', section: 'jobDescription'});
            }
        });
    }

    /**
     * Job profiler resend modal
     */
    jobProfilerResendModal() {
        this.jobsList.cleanMultiselectMode(true);

        this.profilerResendModal = this.modalService.show(JobProfilerRequestResendModalComponent, {
            backdrop : 'static'
        });
        this.setModalClass('app-job-profiler-request-resend-modal');

        this.profilerResendModal.content.close.subscribe(res => {
            if (res.closed && res.id) {
                this.jobService.setItemToDisplayWithId(res.id);
            }
        });
    }

    jobProfilerEmailModal(profilerEmailData, config = 'list') {
        this.jobsList.cleanMultiselectMode(true);
        const initialState = {
            profilerEmailData: profilerEmailData,
            jobToDisplay: profilerEmailData.job
        };
        let modalOpen = document.getElementsByTagName('app-jobs-profiler-email-modal');
        if (modalOpen.length === 0) {
            this.jProfilerEmailModal = this.modalService.show(JobsProfilerEmailModalComponent, {initialState, backdrop : 'static'});
            this.jProfilerEmailModal.content.close.subscribe(res => {
                if (res.closed && res.id) {
                    if (config === 'list') {
                        this.jobsList.getJobList(true);
                    } else {
                        if (profilerEmailData && profilerEmailData.display) {
                            this.state.report.display = profilerEmailData.display;
                        }
                    }
                    this.jobService.setItemToDisplayWithId(res.id);
                }
            });
        }
    }

    jobDuplicateModalOpen() {
        this.jobsList.revertToNews();
        this.jobsList.cleanMultiselectMode(true);
        const initialState = {
            jobToDisplay: this.jobService.jobToDisplay
        };
        this.jDuplicateModal = this.modalService.show(JobsDuplicateModalComponent, {initialState, backdrop : 'static'});
        this.jDuplicateModal.content.close.subscribe(res => {
            if (res.closed && res.id) {
                this.refreshJob();
            }
        });
    }

    openPdfModal(event): void {
        this.jobsList.cleanMultiselectMode(true);
        const jobToDisplay = event.jobToDisplay ? event.jobToDisplay : this.jobService.jobToDisplay;
        const initialState = {
            jobToDisplay: jobToDisplay
        };
        this.jPdfModal = this.modalService.show(JobsPdfModalComponent, {initialState, backdrop : 'static'});
    }

    showModalUnaccessibleTest(event, config = 'list') {
        const initialState = {
            lang: this.translateService.currentLang,
            test: event
        };
        this.noAccessTestsModal = this.modalService.show(UnaccessibleTestsModalComponent, {initialState, backdrop : 'static'});
        this.noAccessTestsModal.content.close.subscribe(res => {
            if (res.closed && res.id) {
                this.jobService.setItemToDisplayWithId(res.id, this.jobsList.specificState);
                if (config === 'list') {
                    this.jobsList.getJobList(true);
                } else if (event && event.display) {
                    this.state.report.display = event.display;
                }
            }
        });
    }

    refreshJob(res = null) {
        let id = (res && res.id) ? res.id : this.jobService.id;
        this.jobService.setItemToDisplayWithId(id, this.jobsList.specificState);
        this.jobsList.getJobList(true);
    }

    scrollJobsModalTop(elem) {
        elem.nativeElement.scrollIntoView({behavior: 'smooth'});
    }

    setModalClass(componentName: string) {
        let componentDom = document.getElementsByTagName(componentName);
        if (componentDom && componentDom[0]) {
            let modalElement = componentDom[0].closest('.modal-dialog');
            if (componentName === 'app-jobs-competences-modal' || componentName === 'app-jobs-edit-modal') {
                modalElement.className = 'modal-dialog competences-modal';
            } else if (componentName === 'app-jobs-profiler-modal') {
                modalElement.className = 'modal-dialog profiler-modal';
            } else {
                modalElement.className = 'modal-dialog send-modal';
            }
        }
    }
}
