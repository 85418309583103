import {animate, style, transition, trigger} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Job} from '../../../models/job.model';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService} from '../../../../core/services/state/state.service';
import {EvaluationRequestNext} from '../../../../people/models/evaluation-request-next.model';
import {emailValidator} from '../../../../people/validators/email';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../environments/environment';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-jobs-profiler-email-modal',
    templateUrl: './jobs-profiler-email-modal.component.html',
    styleUrls: ['./jobs-profiler-email-modal.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition (after transition it removes)
                animate(500, style({opacity: 1})) // the new state of the transition (after transition it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition (after transition it removes)
            ])
        ]),
        trigger('fadeInOut2', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition (after transition it removes)
                animate(500, style({opacity: 1})) // the new state of the transition (after transition it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition (after transition it removes)
            ])
        ])

    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsProfilerEmailModalComponent implements OnInit, OnDestroy {
    @Input() jobToDisplay: Job = null;
    @Input() profilerEmailData: any;

    public close: EventEmitter<any> = new EventEmitter();

    flipValue: any;

    toggle = 1;

    jobProfilerEmailNextForm: UntypedFormGroup;
    requiredBy: string;
    sessionStructure: any = {};
    userData: any = null;
    mailto: string;
    blurred: boolean;
    evaluationRequestNext: EvaluationRequestNext = new EvaluationRequestNext();
    tempUser: any;
    job: Job;

    private subscriptions = new Subscription();

    readonly profilerFormUrl = ''; // environment.profilerFormUrl;

    constructor(private apiJobsService: ApiJobsService,
                private stateService: StateService,
                private fb: UntypedFormBuilder,
                public bsModalRef: BsModalRef,
                public translate: TranslateService,
                public alertService: AlertService) {
    }

    ngOnInit() {
        this.evaluationRequestNext = new EvaluationRequestNext();

        this.tempUser = (this.profilerEmailData && this.profilerEmailData.tempUser) ? this.profilerEmailData.tempUser : null;
        this.job = (this.jobToDisplay) ? this.jobToDisplay : null;

        if (this.tempUser && this.tempUser.email) {
            this.evaluationRequestNext.emailTo = this.tempUser.email;
        }

        // Init
        this.initJobProfilerEmailNextForm();
        // if (this.job && this.tempUser) {
        //    this.mailToGeneration(this.job, this.tempUser);
        // }
    }

    initJobProfilerEmailNextForm() {
        this.jobProfilerEmailNextForm = this.fb.group({
                emailTo: [this.evaluationRequestNext.emailTo, [Validators.required]],
                // subject : [this.evaluationRequestNext.subject],
                message: [this.evaluationRequestNext.message]
            },
            {
                validator: emailValidator('emailTo')
            });
    }

    toggleFlip(n) {
        this.toggle = n;
    }

    finishSending() {
        this.closeModal(this.jobToDisplay.id);
    }

    closeModal(jobId = null) {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.toggle = 1;
            this.flipValue = '';
        });
        this.close.emit({closed: true, id: jobId});
        this.bsModalRef.hide();
    }

    onSubmitEmailNext(model: UntypedFormGroup) {
        // console.log(model.value);
        this.subscriptions.add(this.apiJobsService.profilerRequestNext([this.jobToDisplay.id], [
            this.tempUser.id, model.value.emailTo, model.value.message
        ]).subscribe(
            () => {
                let message = {
                    paragraph: this.translate.instant('people.alert.successRequest.paragraph'),
                    texth1: this.translate.instant('people.alert.successRequest.title'),
                    position: 'top-right',
                    type: 'green'
                };
                this.alertService.setConfig(message);
                this.closeModal(this.jobToDisplay.id);
            }
        ));
    }

    cutNPasteGeneration() {
        let cutPasteTitle = this.translate.instant('people.requestModal.cutnpasteTitle');
        let firstParagraph = this.translate.instant('people.requestModal.fillQuestionnaireToDefinePosition', {
            jobTitle: this.job.jobTitle
        });
        let usernameText = this.translate.instant('people.requestModal.yourUsername', {
            username: this.tempUser.username
        });
        let pwdText = this.translate.instant('people.requestModal.yourPassword', {
            pwd: this.tempUser.tmpPwd
        });
        let questionnaireLink = `${this.profilerFormUrl}/login?lang=${this.translate.currentLang}&username=${this.tempUser.username}&password=${this.tempUser.tmpPwd}`;

        return `<h3><b>${cutPasteTitle}</b></h3>
                <br>
                <p>${firstParagraph}</p>

                <p>${usernameText}<br>${pwdText}</p>

                <p><a href="${questionnaireLink}" target="_blank">${this.translate.instant('people.requestModal.clickToStart')}</a>
                    <br>${questionnaireLink}
                </p>

                <p>${this.translate.instant('people.requestModal.cutPasteNote')}</p>`;
    }

    mailToGeneration() {
        let firstText = this.translate.instant('people.requestModal.fillQuestionnaireToDefinePosition', {
            jobTitle: this.job.jobTitle
        });
        let usernameText = this.translate.instant('people.requestModal.yourUsername', {
            username: this.tempUser.username
        });
        let pwdText = this.translate.instant('people.requestModal.yourPassword', {
            pwd: this.tempUser.tmpPwd
        });
        let questionnaireLink = `${this.profilerFormUrl}/login?lang=${this.translate.currentLang}&username=${this.tempUser.username}&password=${this.tempUser.tmpPwd}`;
        questionnaireLink = encodeURIComponent(questionnaireLink);

        return `${this.translate.instant('people.requestModal.hello')}%0A%0A
            ${firstText}%0A%0A
            ${usernameText}%0A
            ${pwdText}%0A
            ${this.translate.instant('people.requestModal.clickToStart')}
            ${this.translate.instant('people.requestModal.colon')}
            ${questionnaireLink}%0A%0A
            ${this.translate.instant('people.requestModal.cutPasteNote')}`;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
