// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .errorMessage .mat-toolbar-row {
  white-space: normal;
  line-height: 1.8rem;
  color: #800b09;
}

.staging-mobile-login {
  position: absolute;
  right: 0;
  top: 300px;
}

#alert-login * {
  color: #75241c !important;
}

#alert-login-container {
  font-family: "Montserrat-Bold";
  position: absolute;
  z-index: 100002;
  margin-right: 10px;
  top: 390px;
}

#alert-login-box {
  color: #75241c;
  background-color: #fadad7;
  border: 1px solid #f5aca6;
  border-radius: 5px;
  padding: 5px;
}

@media (max-width: 480px) {
  .staging-mobile-login {
    position: absolute;
    right: 0;
    top: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app/components/login/mobile/mobile-login.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AAAJ;;AAGA;EACI,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;;AAIA;EACI;IACI,kBAAA;IACA,QAAA;IACA,UAAA;EADN;AACF","sourcesContent":["::ng-deep .errorMessage .mat-toolbar-row {\n    white-space: normal;\n    line-height: 1.8rem;\n    color: #800b09;\n}\n\n// Utilisé pour positionner le staging login\n.staging-mobile-login {\n    position: absolute;\n    right: 0;\n    top: 300px;\n}\n\n#alert-login * {\n    color: #75241c!important;\n}\n\n#alert-login-container {\n    font-family:'Montserrat-Bold';\n    position: absolute;\n    z-index: 100002;\n    margin-right: 10px;\n    top: 390px;\n}\n\n#alert-login-box {\n    color: #75241c;\n    background-color: #fadad7;\n    border: 1px solid #f5aca6;\n    border-radius: 5px;\n    padding: 5px;\n}\n\n\n@media (max-width: 480px) {\n    .staging-mobile-login {\n        position: absolute;\n        right: 0;\n        top: 250px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
