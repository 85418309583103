import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fake-report',
  templateUrl: './fake-report.component.html',
  styleUrls: ['./fake-report.component.scss']
})
export class FakeReportComponent implements OnInit {

    @Input() for = 'personality';
    traitSummary = [
        {score: 5, cx: 66.25 * 5},
        {score: 5, cx: 66.25 * 5},
        {score: 5, cx: 66.25 * 5}
    ];

    traitPrb = [
        {score: [6, 6], cx: [(66.25 * 6), (66.25 * 6)]},
        {score: [3, 5], cx: [(66.25 * 3), (66.25 * 5)]},
        {score: [5, 5], cx: [(66.25 * 5), (66.25 * 5)]}
    ];

    competencesScores = [
        [4, 4, 6, 6, 5, 5, 7, 5, 3, 8, 6, 5, 4, 5, 8, 8, 3, 3, 4, 2, 3, 5, 2, 5, 5, 8, 7, 7, 8, 6, 8, 7, 8, 5, 4, 4, 4, 5, 7, 5, 5, 4, 3, 4, 7, 5],
        [3, 5, 5, 7, 8, 4]
    ];

    portraitId = 'portrait';

    constructor(public translate: TranslateService) { }

    ngOnInit() {

    }

}
