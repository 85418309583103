import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {StateService} from '../../../../../core/services/state/state.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GraphBoardsModalHandlingService} from '../../../services/graphboards-modal-handling.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {ComponentFullView} from '../../../../../shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-graphboards-report',
    templateUrl: './graphboards-report.component.html',
    styleUrls: ['./graphboards-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    graphboardSize: number;

    reportWidth: string;
    sideWidth: string;
    reportWidthNumber;
    graphWidth;
    archivedRecords = [];
    currentLang: string;
    newBoard: any;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        protected router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private apiPeopleService: ApiPeopleService,
        private apiGraphboardsService: ApiGraphboardsService,
        public graphBoard: GraphBoardsAnalysisService,
        public graphBoardsModalHandlingService: GraphBoardsModalHandlingService,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        public dragulaService: DragulaService,
        protected translate: TranslateService
    ) {
        super(stateService, translate, router, deviceService);
        this.computeListWidth(window);
        const bag: any = this.dragulaService.find('first-bag');
        if (bag !== undefined) {
            this.dragulaService.destroy('first-bag');
        }
        this.dragulaService.createGroup('first-bag', {revertOnSpill: true});

        this.newBoard = [[], [], []];

    }

    ngOnInit() {
        this.subscriptions.add(this.dragulaService.drop('first-bag')
            .subscribe(() => {
                this.act();
            })
        );
        this.currentLang = this.translate.currentLang;

        this.graphBoardState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        // Check the archived records
        this.subscriptions.add(this.apiPeopleService.records([{isArchived: 1}]).subscribe(
            (res) => {
                res.data.forEach(r => {
                    this.archivedRecords.push(r.id);
                });
            })
        );

        if (
            this.dragulaService &&
            this.dragulaService.find('first-bag') &&
            this.dragulaService.find('first-bag').drake
        ) {
            this.dragulaService.find('first-bag').drake.containers = [];
        }

        if (this.graphBoard.alignment && this.graphBoard.alignment.length) {
            this.graphboardSize = this.graphBoard.alignment.length;
        } else {
            this.graphboardSize = 3;
        }

        this.computeListWidth(window);
    }

    analysisTypeForId(item) {
        if (item.analysisType === 'profiler') {
            return 'profiler';
        } else {

            if (item.analysisType === 'prb') {
                return 'individus_prb';
            } else {
                return 'individus_trait';
            }
        }
    }

    fontSize(gbSize) {
        switch (gbSize) {
            case 3:
                return '';
            case 6:
                return '7pt';
            // case 9:
            //    return '7pt';
        }
    }

    /**
     switchSize(newSize) {
        this.graphboardSize = newSize;
        if (newSize > this.graphboardSize) {
            // add columns
            const addup = (newSize - this.graphboardSize) - 1;
            for (let i = addup; i < newSize; i++) {
                this.itemToDisplay.alignment[i] = [];
            }
        } else if (newSize < this.graphboardSize) {
            // destroy column and redistribute evenly.

        }
        this.changeDetectorRef.markForCheck();
    }
     */

    onResize(event) {
        let graphsPadEl = document.getElementsByClassName('graphsPad');
        // hide the graphs before to refresh it
        for (let i = 0; i < graphsPadEl.length; i++) {
            graphsPadEl[i]['style'].display = 'none';
        }
        this.graphBoard.setItemToDisplayWithId(this.graphBoard.id);
        this.computeListWidth(event.target);
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        // if (startWidth.innerWidth < 1280) {
        //    innerWidth = 1280;
        // }
        if (!this.smallSize) {
            this.reportWidthNumber = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left;
            this.reportWidth = this.reportWidthNumber + 'px';
            this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
            // @ilya 2020-10-16 - fix the side width to 400px
            this.sideWidth = '400px';
            this.reportWidth = (innerWidth - 550) + 'px';
        } else {
            this.graphWidth = ((innerWidth - leftColumnWidth) + adjustment.left) / 2 + 'px';
            this.reportWidthNumber = ((innerWidth - leftColumnWidth) + adjustment.left);
            this.reportWidth = this.reportWidthNumber + 'px';
            this.sideWidth = (this.reportWidthNumber  - 35) + 'px';
        }
        return;
    }

    idBuilding(item, root = false) {
        // console.log(item);
        let analysisType = 'profiler';

        if (item.analysisType === 'trait') {
            analysisType = 'trait';
        }
        if (item.analysisType === 'prb') {
            analysisType = 'prb';
        }

        if (root) {
            return analysisType;
        } else {
            return analysisType + '_' + item.id;
        }

    }

    act() {
        // const [name, el, target, source, sibling] = values;
        // console.log(name, el, target, source, sibling);
        // console.log(this.itemToDisplay.alignment);

        let newAlignment: Array<Array<any>> = [];
        for (let i = 0; i < 3; i++) {
            newAlignment[i] = [];
            let j = 0;
            let type = '';
            for (let item of this.graphBoard.alignment[i]) {

                if (item.evaluationType && item.evaluationType === 'mpo') {
                    type = item.analysisType;
                } else {
                    type = 'profiler';
                }

                let payload = {
                    type: type,
                    record: item.id
                };
                if (type === 'prb') {
                    payload['dash'] = 0;
                }

                newAlignment[i][j] = payload;
                j++;
            }
        }

        let data = {
            'alignment': newAlignment
        };
        this.subscriptions.add(this.apiGraphboardsService.putGraphboard([this.graphBoard.id], data).subscribe(
            (res) => {
                this.graphBoard.setItemToDisplayWithId(res.id);
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get graphBoardState(): any {
        return this.stateService[this.specificState()];
    }
}
