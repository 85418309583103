import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-talent-status',
    template: `
    <div style="overflow: hidden; width: 42px">
        <div style="float: left; width: 15px">{{ talentNumber }}</div>
        <div style="
            float: left;
            margin-top: -1px;
            margin-left: 5px;"
        [class]="talentClass">
            ⬤
        </div>
        <div class="clearfix"></div>
    </div>
    `,
    styles: ['.talentDisable {color: #ccc}', '.talentLow {color: #efce7c}', '.talentHigh {color: #78c7af}']
})
export class TalentStatutComponent {
    @Input() talentNumber: number;
    @Input('selectedValue')
    set selectedValue(value: number | null) {
        this.talentClass = 'talentDisable';

        if (value === 1) {
            this.talentClass = 'talentLow';
        }

        if (value === 2) {
            this.talentClass = 'talentHigh';
        }
    }

    talentClass: string;
}
