import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-mini-plane',
    templateUrl: './mini-plane.component.html',
    styleUrls: ['./mini-plane.component.scss']
})
export class MiniPlaneComponent implements OnInit {

    @ViewChild('MiniPlaneGraph', {static: true}) private chartContainer: ElementRef;
    @Input() type: string;

    textHead: string;
    subText: string;

    constructor(
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        const colors = {
            gray: '#E2E2E2',
            darkGray: 'rgb(85, 85, 85)'
        };

        let element = this.chartContainer.nativeElement;

        let svg = d3.select(element).append('svg').attr('preserveAspectRatio', 'xMidYMid meet').
            attr('width', 60).
            attr('height', 60).
            attr('viewBox', '0 0 60 60');

        svg.append('circle').attr('cx', 30).attr('cy', 30).attr('r', 27).style('fill', 'white').style('stroke-width', 1.5).
            style('stroke', colors.gray);

        let height = (this.type === 'iac' || this.type === 'mpo') ? 7 : 15;

        svg.append('svg:image').attr('xlink:href', 'assets/header/send/ico_send.png').attr('x', 12).attr('y', height).
            attr('width', 35).attr('height', 35);

        if (this.type === 'iac' || this.type === 'mpo') {
            let text = '';
            if (this.type === 'iac') {
                text = (this.translate.currentLang === 'fr') ? 'IAC' : 'CAI';
            } else {
                text = 'MPO';
            }
            svg.append('text')
                .attr('x', 30)
                .attr('y', 47)
                .attr('text-anchor', 'middle')
                .text(text)
                .attr('stroke', 'rgb(70.0%,70.0%,70.0%)')
                .attr('font-size', 11);
        }

    }

}
