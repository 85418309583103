import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    EventEmitter, Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-no-job-modal',
    templateUrl: './no-job-modal.component.html',
    styleUrls: ['./no-job-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoJobModalComponent implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService
    ) { }

    ngOnInit() {

    }

    closeModal() {
        this.close.emit({closed: true});
        this.bsModalRef.hide();
    }

    openNewJobModal() {
        this.bsModalRef.hide();
        this.close.emit({newJob: true});
    }

    ngOnDestroy(): void {
        this.close.emit({closed: true});
    }

}
