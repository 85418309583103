import {
    ChangeDetectionStrategy,
    Component,
    Output,
    Input,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-mobile-main-list-search-bar',
  templateUrl: './mobile-main-list-search-bar.component.html',
  styleUrls: ['./mobile-main-list-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMainListSearchBarComponent implements OnInit, OnDestroy, OnChanges {
  @Output() private searchEvent = new EventEmitter<string>();
  @Input() initialValue = '';
  @Input() clear: boolean;
  actual = '';
  searchTerm: string;

  constructor() { }

  ngOnInit() {
      this.searchTerm = this.initialValue;
      this.actual = this.initialValue;
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes['clear']) {
          this.clearSearch();
      }
  }

  clearSearch() {
      this.actual = '';
      this.searchTerm = '';
      this.searchEvent.emit('');
  }

  onSearch(event, searchTerm, which) {
      if (which === 'return' && event.keyCode === 13) {
          this.searchEvent.emit(searchTerm);
          this.actual = searchTerm;
      } else if (which === 'click') {
          this.searchEvent.emit(searchTerm);
          this.actual = searchTerm;
      }
  }

  ngOnDestroy() {
      // this.clearSearch();
  }

}
