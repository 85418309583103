import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiPost} from '../../../core/services/api/api-post';
import { HttpClient } from '@angular/common/http';
import {newsMessagePostData} from '../models/newsMessagePostData.model';
import {EnvService} from '../../../app/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPostNewsService extends ApiPost {

    constructor(
        protected http: HttpClient,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    news_message(data: newsMessagePostData): Observable<any> {
        return this.apiPost(
            'news-message',
            null,
             data
        );
    }

    news_userdateMessage(urlParams: [string]): Observable<any> {
        return this.apiPost(
            'news-userdate/message/UrlParameter0',
            urlParams,
            null
        );
    }
}
