// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning {
  padding: 10px;
  margin: 25px;
  border: 2px solid indianred;
}

.warning p {
  padding: 0;
}

.modLink {
  display: inline-block;
  float: right;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/web/jobs-report-mpo/jobs-report-mpo.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,2BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,qBAAA;EACA,YAAA;EACA,iBAAA;AACJ","sourcesContent":[".warning {\n    padding: 10px;\n    margin: 25px;\n    border: 2px solid indianred;\n}\n\n.warning p {\n    padding: 0;\n}\n\n.modLink {\n    display: inline-block;\n    float: right;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
