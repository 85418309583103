import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {StateService} from 'src/app/core/services/state/state.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';

export abstract class AdminSidebar extends ComponentPrimitive {

    protected constructor(
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translateService,
            router,
            deviceService
        );
    }

    moveTo(fragment) {
        this.router.navigateByUrl(this.router.url.split('#')[0] + '#' + fragment).then(() => {
        });
    }

    get sessionData() {
        return _.get(this.stateService, 'session.sessionData');
    }

    get sessionAccountData() {
        return _.get(this.sessionData, 'accountData');
    }

    get sessionPermissions() {
        return _.get(this.sessionData, 'permissions');
    }

    get permissionsRoles() {
        return _.get(this.sessionPermissions, 'roles');
    }

    get permissionsAccountType() {
        return _.get(this.sessionPermissions, 'accountType');
    }
}
