// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5 {
  font-size: 1.2rem;
  margin: 0 0 10px 0;
}

label {
  font-size: 1rem;
}

.alignLeftTop {
  position: absolute;
  left: 1px;
  top: 0;
}

.sharePdfTitle {
  font-size: 1.8rem;
}

::ng-deep .alignLeftTop label {
  max-width: 100%;
}

label.report_iac {
  max-width: 80%;
}

::ng-deep .alignLeftTop input[type=radio] {
  position: relative;
  top: 3px;
}

::ng-deep .alignLeftTop .topM7 {
  top: -7px !important;
}

.modalButton {
  background-color: #666;
  padding: 5px 10px;
  border: 1px solid #AAA;
  color: #FFF;
  border-radius: 4px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/mobile/people-pdf-sharing/mobile-people-pdf-sharing.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,SAAA;EACA,MAAA;AAGJ;;AAAA;EACI,iBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,cAAA;AAGJ;;AAAA;EACI,kBAAA;EACA,QAAA;AAGJ;;AAAA;EACI,oBAAA;AAGJ;;AAAA;EACI,sBAAA;EACA,iBAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,iDAAA;EACA,eAAA;EACA,gBAAA;AAGJ","sourcesContent":["h5 {\n    font-size: 1.2rem;\n    margin: 0 0 10px 0;\n}\nlabel {\n    font-size: 1rem;\n}\n.alignLeftTop {\n    position: absolute;\n    left: 1px;\n    top: 0;\n}\n\n.sharePdfTitle {\n    font-size: 1.8rem;\n}\n\n::ng-deep .alignLeftTop label {\n    max-width: 100%;\n}\n\nlabel.report_iac {\n    max-width: 80%;\n}\n\n::ng-deep .alignLeftTop input[type='radio'] {\n    position: relative;\n    top: 3px;\n}\n\n::ng-deep .alignLeftTop .topM7 {\n    top: -7px !important;\n}\n\n.modalButton {\n    background-color: #666;\n    padding: 5px 10px;\n    border: 1px solid #AAA;\n    color: #FFF;\n    border-radius: 4px;\n    font-family: Roboto, \"Helvetica Neue\", sans-serif;\n    font-size: 14px;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
