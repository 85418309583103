import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {StateService} from 'src/app/core/services/state/state.service';
import {ComponentFullView} from 'src/app/shared/classes/components/component_full_view';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {PersonService} from 'src/app/people/services/person.service';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {DeviceDetectorService} from 'ngx-device-detector';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';

@Component({
    selector: 'app-mobile-idw-matrix-report',
    templateUrl: './mobile-idw-matrix-report.component.html',
    styleUrls: ['./mobile-idw-matrix-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileIdwMatrixReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    pdfRequest = false;
    editRequest = false;
    graphWidth: number;
    graphHeight: number;
    viewTabs = ['trait', 'prb'];
    sideWidth: string;
    participants: any;
    viewType = 'trait';
    actualPage = 1;
    maxPerPage = 6;
    maxPage: number;
    addingPanelOpenState = false;
    currentLang: string;
    isLandscape = false;
    showPortraitButtons = false;
    idleTime = 0;
    // idleInterval;
    titleSep: string;

    private subscriptions = new Subscription();

    constructor(private route: ActivatedRoute,
                protected router: Router,
                public idwMatrix: IdwMatrixAnalysisService,
                private changeDetectorRef: ChangeDetectorRef,
                public person: PersonService,
                private apiPeopleService: ApiPeopleService,
                private apiIdwMatrices: ApiIdwMatricesService,
                protected translate: TranslateService,
                protected stateService: StateService,
                protected deviceService: DeviceDetectorService,
    ) {
        super(stateService, translate, router, deviceService);
    }

    @HostListener('window:mousemove') refreshUserState() {
        this.idleTime = 0;
    }

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
        // if (this.showPortraitButtons) {
        //     this.idleInterval = setInterval(() => {
        //         this.idleTime++;
        //         if (this.idleTime > 3) { // 3 sec
        //             this.showPortraitButtons = false;
        //             clearInterval(this.idleInterval);
        //             this.changeDetectorRef.markForCheck();
        //         }
        //     }, 1000);
        // }
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.graphWidth = window.innerWidth;
        this.graphHeight = this.graphWidth / 2.4256;
        this.graphHeight = +this.graphHeight.toFixed(2);
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
        let thisObject = this;
        window.addEventListener('orientationchange', function() {
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            // set the graph width by the previous window height
            thisObject.graphWidth = window.innerHeight;
            thisObject.graphHeight = thisObject.graphWidth / 2.4256;
            thisObject.graphHeight = +thisObject.graphHeight.toFixed(2);
            thisObject.changeDetectorRef.markForCheck();
        }, false);

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);

        this.stateService.idwMatrix.stateChanged$.subscribe(
            (res) => {
                if (res && res.itemToDisplayId) {
                    this.fetchParticipants();
                    if (this.recordsUnique) {
                        this.maxPage = Math.ceil(this.recordsUnique.length / this.maxPerPage);
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.fetchParticipants();

        if (this.idwMatrix.id) {
            if (this.recordsUnique) {
                this.maxPage = Math.ceil(this.recordsUnique.length / this.maxPerPage);
            }
        }

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    fetchParticipants() {
        if (this.idwMatrix.records && this.idwMatrix.records.length) {
            this.participants = {
                'trait': [],
                'prb': []
            };
            let records = this.idwMatrix.records.sort(
                function(a, b) {
                    let textA = a.firstName.toUpperCase();
                    let textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );
            for (let participant of records) {
                if (participant.analysisType === 'trait') {
                    this.participants.trait.push(participant);
                } else {
                    this.participants.prb.push(participant);
                }
            }
        } else {
            this.participants = null;
        }
    }

    itemEdit() {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.editRequest = false;
    }

    closeAllRequestsPanels() {
        this.pdfRequest = false;
        this.editRequest = false;
    }

    openPanel() {
        this.addingPanelOpenState = true;
    }

    closePanel() {
        this.addingPanelOpenState = false;
    }

    goToPage(factor) {
        this.actualPage += factor;
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'mobileView', 'people', 'communication');
    }

    removeItem(id) {
        let recordsUnique = JSON.parse(JSON.stringify(this.recordsUnique));
        let records = JSON.parse(JSON.stringify(this.idwMatrix.records));

        let newRecords = _.remove(records, function (i) {
            return i.id !== id;
        });
        let newRecordsUnique = _.remove(recordsUnique, function (i) {
            return i.id !== id;
        });
        let items = [];
        for (let record of newRecordsUnique) {
            items.push({
                type: 'trait',
                record: record.id
            });
            items.push({
                type: 'prb',
                record: record.id,
                dash: 0
            });
        }
        if (!items.length) {
            // for the case when we try to remove the last associated record
            items.push('0');
        }
        this.subscriptions.add(this.apiIdwMatrices.putDitGram([this.idwMatrix.id], {'records': items}).subscribe(
            () => {
                this.idwMatrix.records = newRecords;
                this.stateService.idwMatrix.stateChanged.next({
                    refreshComponent: true,
                    itemToDisplayId: this.idwMatrix.id
                });
            }
        ));
    }

    switchView(index) {
        this.viewType = this.viewTabs.find((e, i) => {
            if (i === index) {
                return e;
            } else {
                return;
            }
        });
    }

    get hasPrb() {
        let hasPrb = false;
        if (this.participants && this.participants.prb && this.participants.prb[0] && this.participants.prb[0].quad) {
            hasPrb = true;
        }
        return hasPrb;
    }

    get state(): any {
        return this.stateService.idwMatrix;
    }

    get recordsUnique(): any {
        if (this.idwMatrix && this.idwMatrix.records) {
            let records = this.idwMatrix.records.sort(
                function(a, b) {
                    let textA = a.firstName.toUpperCase();
                    let textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );
            return _.uniqBy(records, 'id');
        }
        return null;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
