import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '../../../core/services/state/state.service';
import {AccountService} from '../../services/account.service';
import {UserService} from '../../services/user.service';
import {AdminService} from '../../../app/services/admin.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {AdminModalHandlingService} from '../../services/admin-modal-handling.service';
import {AlertService} from '../../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {CommonsAdminComponent} from '../../commons/CommonsAdminComponent';
import {FormsHelper} from '../../helpers/FormsHelper';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SubAccountService} from '../../services/sub-account.service';
import {take, takeLast} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, from} from 'rxjs';

@Component({
    selector: 'app-administration-account-form',
    templateUrl: './administration-account-form.component.html',
    styleUrls: ['./administration-account-form.component.scss']
})

export class AdministrationAccountFormComponent extends CommonsAdminComponent implements OnInit, OnDestroy {

    subAccountData: any = {};
    missedFields = [];
    submitDisable = false;

    formHelper = FormsHelper;
    products: any;
    isMasterAccount = false;
    users: any;
    focused = '';
    currentEditedField;

    distributor = null;
    subsidiary = null;

    productsData;

    newFormData;

    new: any = false;
    parentAccounts;

    accountInfo: any = false;
    addressInfo: any = false;
    mpoProductsDemo;
    mpoProducts;
    permissions;

    closeAllFieldsExcept;

    formPermissions: any = {
        mpo: true,
        dit: true,
        talents: true,
        satellite: true,
        ra: true,
        jil: true
    };

    displayLoader = false;

    public loaderDisplay = {
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
    };

    heads: any = {
        users: {},
        subAccounts: {},
        files: {},
        purchases: {},
        history: {},
        department: {}
    };

    principalAdmins = [];

    assets: any = [];
    actionLogs: any;
    actionLogsLimit = 100;

    constructor(
        protected router: Router,
        private apiAdministrationService: ApiAdministrationService,
        public adminModalHandlingService: AdminModalHandlingService,
        protected adminService: AdminService,
        private changeDetectorRef: ChangeDetectorRef,
        public alertService: AlertService,
        public stateService: StateService,
        public accountService: AccountService,
        public subAccountService: SubAccountService,
        public clientUser: UserService,
        public translateService: TranslateService,
        public deviceService: DeviceDetectorService
    ) {
        super(adminService, stateService, translateService, router, deviceService);
        let urlArray = this.router.url.split('/');
        if (urlArray[urlArray.length - 1] === 'new') {
            if (urlArray[urlArray.length - 2] === 'account') {
                this.new = 'account';
            } else if (urlArray[urlArray.length - 2] === 'subaccount') {
                this.new = 'subAccount';
            }
        } else {
            this.checkHeads();
        }
    }

    ngOnInit() {
        this.initializeForm();

        if (!this.new) {
            this.subscriptions.add(this.translateService.onLangChange.subscribe(
                (e) => {
                    if (this.subAccountService.id) {
                        this.checkHeads(e.lang);
                        this.getSubaccountsActionLogs();
                        if (this.accountService.purchases && this.accountService.purchases.data) {
                            this.accountService.checkUnlimited(this.accountService.purchases.data);
                        }
                        this.changeDetectorRef.markForCheck();
                    }
                }
            ));
        }

        if (this.accountService.isClientOrDemo && !this.new) {
            this.heads.files.createItemLabel = null;
        }

        this.subscriptions.add(this.stateService.admin.stateChanged$.pipe(takeLast(1)).subscribe(
            (res) => {
                if (res && !res.reset && res.setClient !== this.accountService.id) {
                    this.initializeForm();
                    this.changeDetectorRef.markForCheck();
                }
            }
        ));
        // this.initializeForm();
    }

    initializeForm() {
        this.parentAccounts = null;
        this.productsData = null;
        this.users = null;


        if (!this.new) {
            // console.log('a');
            // this.accountService.setAccountToDisplayWithId(this.accountService.id);

            this.accountService.parentAccountsChanged = undefined;
            this.accountService.parentAccountsChanged$ = undefined;
            this.accountService.parentAccountsChanged = new BehaviorSubject(null);
            this.accountService.parentAccountsChanged$ = this.accountService.parentAccountsChanged.asObservable();


            this.accountService.productDescriptionChanged = undefined;
            this.accountService.productDescriptionChanged$ = undefined;
            this.accountService.productDescriptionChanged = new BehaviorSubject(null);
            this.accountService.productDescriptionChanged$ = this.accountService.productDescriptionChanged.asObservable();

            this.accountService.usersChanged = undefined;
            this.accountService.usersChanged$ = undefined;
            this.accountService.usersChanged = new BehaviorSubject(null);
            this.accountService.usersChanged$ = this.accountService.usersChanged.asObservable();


            forkJoin([
                this.apiAdministrationService.accountParentsForType([this.accountService.accountType || 'demo']),
                this.apiAdministrationService.productsClient([this.accountService.id]),
                this.apiAdministrationService.users([{
                    limit: 10000,
                    order: {firstName: 'asc'},
                    accountNumber: [this.accountService.accountNumber],
                    asList: true
                }])
            ]).pipe(takeLast(1)).subscribe(
                ([parentAccounts, productsData, users]: [any, any, any]) => {
                    this.accountService.parentAccountsChanged.next(parentAccounts);
                    this.parentAccounts = parentAccounts.data;

                    this.productsData = this.accountService.handleProductsData(productsData);
                    this.accountService.productDescriptionChanged.next(this.productsData);

                    this.users = users;
                    if (users && users.data) {
                        let usersData = users.data;
                        if (usersData !== null) {
                            for (let user of usersData) {
                                if (user.roles.indexOf('ROLE_MAIN_ADMIN') > -1) {
                                    this.principalAdmins.push(user.firstName + ' ' + user.lastName);
                                }
                            }
                        }
                    }
                    this.getSubAccountData();
                    this.changeDetectorRef.markForCheck();
                });
        } else {
            this.accountService.resetAccountToDisplay();
            this.apiAdministrationService.accountParentsForType([this.accountService.accountType || 'demo']).subscribe(
                (parentAccounts) => {
                    this.accountService.parentAccountsChanged.next(parentAccounts);
                    this.parentAccounts = parentAccounts.data;
                    this.initializeEmptyForm();
                    this.changeDetectorRef.markForCheck();

                });
        }
    }

    initializeNewFormData() {
        this.newFormData = {
            account: {
                accountType: '',
                name: '',
                parent: null,
                permissions: {mpo: true, dit: true, talents: true, satellite: true, ra: true, jil: true},
                status: null
            },
            subAccount: {
                addressInfo: {address: '', city: '', zipcode: '', region: '', country: ''},
                employeesNumber: null,
                name: '',
                status: null,
                telephone: '',
                website: ''
            }
        };
    }

    initializeEmptyForm() {
        if (this.parentAccounts) {
            this.initializeNewFormData();

            this.setAccountInfo();
            this.setAddressInfo();
            if (this.new !== 'subAccount') {
                this.setMpoProducts();
                this.setPermissions();
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    getSubAccountData() {
        // Check if all variables properly initialized
        if (this.productsData && this.parentAccounts && this.users) {
            // Set data proper
            let subAccFromUrl = this.router.url.split('subaccount/')[1];
            let subAccId = 0;
            if (subAccFromUrl) {
                let subAccFromUrlArr = subAccFromUrl.split('#');
                subAccId = Number.parseInt(subAccFromUrlArr[0], 10);
            }
            this.subAccountService.clearSubAccountData();
            this.subAccountService.setSubAccountDataById(subAccId);
            // Set data to display
            this.setAccountInfo();
            this.setAddressInfo();
            this.setMpoProducts();
            this.setPermissions();

            // Lists data
            this.getSubacountsFiles();
            this.getSubaccountsActionLogs();
            this.getSubaccountsPurchases();

            this.changeDetectorRef.markForCheck();
        }
    }

    getSubacountsFiles() {
        // Master account: all files
        this.assets = (this.accountService.assets) ? this.accountService.assets : [];
        if (this.subAccountService.id) {
            if (this.accountService.assets) {
                this.assets = this.accountService.assets.filter(
                    asset => asset.subAccountId === this.subAccountService.id
                );
            }
        }
    }

    getSubaccountsPurchases() {
        let purchasesAccount = (this.isMasterAccount) ? [] : [this.subAccountService.id];
        this.accountService.fetchPurchases(purchasesAccount);
    }

    setAccountInfo() {
        let accountInfo = this.formHelper.accountInfo;
        // Set items value etc. here
        let ref = this.formHelper.fieldsName(this.formHelper.accountInfo);
        let fieldsToAddValue = ['status', 'accountType'];
        let leftColNum = 7;
        let rightColNum = (this.new) ? 6 : 7;
        if (this.new === 'account') {
            ref = this.formHelper.newAccountInfo;
            leftColNum = (this.new) ? 3 : 4;
            rightColNum = 3;
        } else if (this.new === 'subAccount') {
            fieldsToAddValue = ['status'];
            ref = this.formHelper.newSubAccountInfo;
            leftColNum = (this.new) ? 2 : 3;
            rightColNum = 2;
        }

        let accountInfoToDisplay = [];

        for (let fieldName of ref) {
            let item = _.find(accountInfo, {fieldsName: fieldName});
            if (!this.new) {
                let serviceName = (item?.subAccount) ? 'subAccountService' : 'accountService';
                // Set parentAccount select options
                item.fieldsExternalValue = this[serviceName][fieldName];
            }
            item = this.setSelectParentAccountsOptions(item);
            item = this.setFieldsValues(item, fieldsToAddValue, 'accountInfo');
            accountInfoToDisplay.push(item);
        }
        // Split for display
        this.accountInfo = this.formHelper.twoColumnSplit(accountInfoToDisplay, leftColNum, rightColNum);
        this.changeDetectorRef.markForCheck();
    }

    setAddressInfo() {
        let addressInfo = this.formHelper.addressInfo;
        if (!this.new) {
            // Set items value etc. here
            for (let [i, fieldName] of this.formHelper.fieldsName(this.formHelper.addressInfo).entries()) {
                addressInfo[i].fieldsExternalValue = this.subAccountService.addressInfo[fieldName];
                addressInfo[i] = this.setFieldsValues(addressInfo[i], [], 'addressInfo');
            }
        }
        // Split for display
        this.addressInfo = this.formHelper.twoColumnSplit(addressInfo, 3, 2);
        this.changeDetectorRef.markForCheck();
    }

    setMpoProducts() {
        let mpoProducts = this.formHelper.mpoProducts;
        let mpoProductsToDisplay = [];
        let ref = this.formHelper.fieldsName(this.formHelper.mpoProducts);
        let leftColNum = 5;
        let rightColNum = 5;
        if (this.new === 'account') {
            ref = this.formHelper.newProductInfo;
            leftColNum = 1;
            rightColNum = 1;
        }
        for (let fieldName of ref) {
            let item = _.find(mpoProducts, {fieldsName: fieldName});
            if (!this.new) {
                item.fieldsExternalValue = this.accountService[fieldName];
                item = this.setFieldsValues(item, ['productCodes', 'description'], 'mpoProduct');
                item = this.setSelectProductCodesOptions(item);
                item = this.setSelectMainAdminOptions(item);
            }
            mpoProductsToDisplay.push(item);
        }
        if (this.accountService.accountType === 'demo') {

            mpoProductsToDisplay.push({
                fieldsName: 'expiresAt',
                fieldsType: 'text',
                fieldsExternalValue: this.accountService.expiresAt,
                fieldsValueText: this.getformatedDatesForText(this.accountService.expiresAt),
                editable: false,
                fieldsOptions: null,
                validation: null,
                format: null
            });
            rightColNum = 6;
        }
        // Split for display
        this.mpoProducts = this.formHelper.twoColumnSplit(mpoProductsToDisplay, leftColNum, rightColNum);
        this.changeDetectorRef.markForCheck();
    }

    getformatedDatesForText(date) {
        let fieldsValueText;
        if ((typeof date === 'string' || date instanceof String) && (date.match(/-/g) || []).length === 2) {
            let dateArr = date.substring(0, 10).split('-');
            fieldsValueText = {
                fr: dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0],
                en: dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0]
            };
        }
        return fieldsValueText;
    }

    setPermissions() {
        let permissions = this.formHelper.permissions;
        // Set items value etc. here
        for (let [i, fieldName] of this.formHelper.fieldsName(this.formHelper.permissions).entries()) {
            permissions[i].fieldsExternalValue = (!this.new) ? this.accountService[fieldName] : true;
            permissions[i] = this.setFieldsValues(permissions[i], null, 'permissions');
        }
        this.permissions = this.formHelper.twoColumnSplit(permissions, 3, 3);
    }

    setFieldsValues(info, fields, group) {
        if (info.fieldsName === 'creationDemoDate') {
            info.fieldsValueText = this.getformatedDatesForText(info.fieldsExternalValue);
        } else if (
            info.fieldsType === 'select' &&
            fields.includes(info.fieldsName)
        ) {
            switch (info.fieldsName) {
                case 'productCodes':
                    info.fieldsValueText = this.productsData.descriptive;
                    break;
                default:
                    // Get textual value for all selects
                    info.fieldsValueText = (info.fieldsExternalValue !== undefined && info.fieldsExternalValue !== null) ?
                        this.formHelper.getDropdownTextByValue(
                            'accountInfo',
                            info.fieldsName,
                            info.fieldsExternalValue
                        ) :
                        null;
                    break;
            }
        } else {
            if (group === 'permissions') {
                info.fieldsValueText = this.formHelper.setCheckboxText(info.fieldsName, info.fieldsExternalValue);
            } else {
                switch (info.fieldsName) {
                    case 'description':
                        if (group === 'mpoProduct') {
                            info.fieldsValueText = {
                                en: this.productsData.descriptiveVerbatim['en'],
                                fr: this.productsData.descriptiveVerbatim['fr'],
                            };
                        }
                        break;
                    default:
                        info.fieldsValueText = info.fieldsExternalValue;
                        break;
                }
            }
        }
        return info;
    }

    setSelectProductCodesOptions(mpoProducts) {
        if (mpoProducts.fieldsName === 'productCodes') {
            mpoProducts.fieldsOptions = {
                default: 'blank',
                selectOptions: this.productsData.productCodes
            };
        }
        return mpoProducts;
    }

    setSelectMainAdminOptions(mpoProducts) {
        if (mpoProducts.fieldsName === 'administrator') {
            mpoProducts.fieldsOptions = {
                default: 'blank',
                selectOptions: this.principalAdmins
            };
        }
        return mpoProducts;
    }

    setSelectParentAccountsOptions(accountInfo) {
        if (accountInfo.fieldsName === 'parentAccountNumber') {
            let fieldsOptions = {
                default: 'blank',
                selectOptions: []
            };
            for (let account of this.parentAccounts) {
                fieldsOptions.selectOptions.push({
                    text: {
                        en: account.name,
                        fr: account.name
                    },
                    value: account.number
                });
            }
            accountInfo.fieldsOptions = fieldsOptions;
        }
        return accountInfo;
    }

    checkHeads(lang = this.translateService.currentLang) {
        for (let element of ['users', 'subAccounts', 'files', 'purchases', 'history', 'department']) {
            this.heads[element] = this.formHelper.headsPristine[element][lang];
        }
    }


    getMoreData(pageInfo): void {
        if (pageInfo?.type && pageInfo.type === 'history') {
            const dataToSkip = this.actionLogsLimit * pageInfo.numberOfLastPageReached;
            // add data only if enough in database
            if (dataToSkip <= this.actionLogs.count) {
                this.getSubaccountsActionLogs({skip: dataToSkip, isConcat: true});
            }
        }
    }

    checkFormPermission(event, report) {
        if (report) {
            this.formPermissions[report] = event.checked;
        }
    }

    goToSubAccount0() {
        this.accountService.setAccountToDisplayWithId(this.accountService.id, 'admin', 'fullClient');
    }

    loader(event) {
        this.displayLoader = event;
    }

    closeAllAdminFields(item) {
        this.closeAllFieldsExcept = item;
        this.changeDetectorRef.markForCheck();
    }

    onCreateUser() {
        this.router.navigate(['admin/account/' + this.accountService.id + '/user/new']).then(() => {
        });
    }

    onSelectUser(user) {
        if (user && user.id && this.accountService && this.accountService.id) {
            this.clientUser.setUserToDisplayWithId(user.id, this.accountService.id);
        }
    }

    onCreateSubAccount() {
        this.router.navigate(['admin/account/' + this.accountService.id + '/subaccount/new']).then(() => {
        });
    }

    onSelectSubAccount(subAccount = {id: this.subAccountService.id}) {
        this.router.navigate(['admin/account/' + this.accountService.id + '/subaccount/' + subAccount.id], {fragment: 'accountInfo'})
            .then(() => {
                this.getSubacountsFiles();
                let purchasesAccount = (!subAccount.id) ? [] : [subAccount.id];
                this.accountService.fetchPurchases(purchasesAccount, true);
            });
    }

    closeThis(event) {
        this.currentEditedField = event;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // API CALLS ////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Form submission
    onSubmit() {
        let missedFields = [];
        let mendatoryFields = (this.new === 'account') ? this.formHelper.accountsMendatoryFields : this.formHelper.subAccountsMendatoryFields;
        for (let field of mendatoryFields) {
            if (field &&
                !(this.newFormData.account.hasOwnProperty(field) && this.newFormData.account[field]) &&
                !(this.newFormData.subAccount.hasOwnProperty(field) && this.newFormData.subAccount[field])
            ) {
                if (field !== 'accountType') {
                    missedFields.push(field);
                } else {
                    missedFields.push('accountType');
                }
            }
        }
        this.missedFields = missedFields;
        if (!this.missedFields.length) {
            this.newFormData = this.convertDateToProperFormat(this.newFormData);
            this.newFormData.account.accountType = this.newFormData.account.accountType.split('_')[0];
            this.newFormData.account.status = true;
            this.newFormData.subAccount.status = true;
            if (this.new === 'account') {
                // save the new sub-account data for an existing account
                let subscriptionAccount = this.apiAdministrationService.postAccount(this.newFormData.account).subscribe(
                    (account) => {
                        let id = account.id;
                        let subscriptionSubAccount =
                            this.apiAdministrationService.subAccount([id, 0], this.newFormData.subAccount, true).subscribe(
                                () => {
                                    this.accountService.setAccountToDisplayWithId(id);
                                    subscriptionAccount.unsubscribe();
                                    subscriptionSubAccount.unsubscribe();
                                    this.router.navigate(['admin/account/' + id]).then(() => {
                                    });
                                    this.changeDetectorRef.markForCheck();
                                }
                            );
                    }
                );
            } else if (this.new === 'subAccount') {
                let accountId = this.router.url.split('/')[3];
                let subscriptionSubAccount = this.apiAdministrationService.subAccount(
                    [accountId],
                    this.newFormData.subAccount,
                    false).subscribe(
                    (res) => {
                        subscriptionSubAccount.unsubscribe();
                        this.router.navigate(
                            ['admin/account/' + accountId + '/subaccount/' + res.id], {fragment: 'accountInfo'})
                            .then(() => {
                                this.getSubacountsFiles();
                                let purchasesAccount = (!res.id) ? [] : [res.id];
                                this.accountService.fetchPurchases(purchasesAccount, true);
                            });
                        this.changeDetectorRef.markForCheck();
                    }
                );
            }
        }
    }

    convertDateToProperFormat(form) {
        return form;
    }

    onDeleteFile(file) {
        let deleteMessage = this.translateService.instant('admin.accountDetail.fileDeletionConfirm', {
            fileName: file.name,
        });

        // Open alert
        this.alertService.setConfig({
            paragraph: deleteMessage,
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        });

        // Alert actions
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            (res: string) => {
                if (res && res.toLowerCase() === 'confirm') {
                    // Delete file
                    let subAccountId = (file.subAccountId) ? file.subAccountId : this.subAccountService.id;
                    this.subscriptions.add(this.apiAdministrationService.deleteFile(
                        [this.accountService.id, subAccountId, file.filename]
                    ).subscribe(
                        () => {
                            // Reload account
                            this.accountService.setAccountToDisplayWithId(this.accountService.id);
                        }
                    ));
                }
            }
        ));
    }

    // ACCOUNTs MANAGEMENT /////////////////////////////////////////////////////////////////////////////////////////////

    // Handling change to a single field
    handleEdition(group, result, item, newAccount = false) {
        let dbFieldsName = this.formHelper.getDbFieldsName(group, item);
        // console.log(result, item, newAccount);
        if (newAccount) {
            let call = (this.formHelper.accountFields.includes(dbFieldsName)) ? 'account' : 'subAccount';
            if (this.new === 'subAccount') {
                call = 'subAccount';
            }
            if (group === 'addressInfo' || group === 'permissions') {
                this.newFormData[call][group][dbFieldsName] = result;
            } else {
                if (item === 'adoptionDate' || item === 'renewalDate' || item === 'dropDate') {
                    result = (result) ?
                        result.jsdate.toISOString().substring(0, 10) :
                        null;
                }
                this.newFormData[call][dbFieldsName] = result;
            }
            // console.log(this.newFormData);
            return;
        } else {
            let data = {};

            if (item === 'adoptionDate' || item === 'renewalDate' || item === 'dropDate') {
                result.fieldsValue = (result.fieldsValue) ? result.fieldsValue.toISOString().substring(0, 10) : null;
            }

            // Is this field part of a subObject ?
            if (group === 'addressInfo' || group === 'permissions') {
                data[group] = this.accountService[group];
                data[group][dbFieldsName] = result.fieldsValue;
            } else {
                data[dbFieldsName] = result.fieldsValue;
            }

            // Format for API

            data = this.convertOutputForForm(data, result);

            let apiCall = this.apiAdministrationService['subAccount']([this.accountService.id, this.subAccountService.id], data, true);
            //  Is this field a account OR a subAccount field
            console.log(dbFieldsName);
            if (this.formHelper.accountFields.includes(dbFieldsName)) {
                console.log('Im in');
                apiCall = this.apiAdministrationService['putAccount']([this.accountService.id], data);
            }

            if (dbFieldsName === 'parentAccountNumber') {
                this.displayLoader = true;
            }

            this.subscriptions.add(apiCall.subscribe(
                () => {
                    if (dbFieldsName === 'parentAccountNumber' || dbFieldsName === 'productCode' || dbFieldsName === 'status') {
                        // Force reload by 'faking' different adress
                        let suffix = (this.router.url.includes('subaccount')) ? '' : '/subaccount/0';
                        this.router.navigate(['admin/account/' + this.accountService.id + suffix]);
                    }
                    this.initializeForm();
                    this.displayLoader = false;
                    this.changeDetectorRef.markForCheck();
                }
            ));
        }

    }

    convertOutputForForm(data, result) {
        if (result.fieldsType === 'date-picker' && result.fieldsName !== 'renewalDate' && result.fieldsName !== 'adoptionDate' && result.fieldsName !== 'dropDate') {
            data[result.fieldsName] = this.dToMongoDate(data, result.fieldsName);
        } else if (result.fieldsName === 'accountType') {
            data = (data && data['accountType'] && data['accountType'].includes('_')) ?
                data['accountType'].split('_') : data;
            data = {
                accountType: data[0],
                discretionary: !!(data[0] === 'discretionary' && data[1] === 'disc')
            };
        } else if (result.fieldsName === 'status') {
            data[result.fieldsName] = (data[result.fieldsName] === true || data[result.fieldsName] === 'true') ?
                1 : 0;
        }
        return data;
    }

    getSubaccountsActionLogs(options: any = {skip: 0, isConcat: false}) {
        let params: any = {
            limit: this.actionLogsLimit,
            skip: options.skip,
            order: {creationDate: 'desc'},
            accountNumber: [this.accountService.accountNumber],
            subAccount: (this.isMasterAccount) ? [] : [this.subAccountService.id],
            lang: this.translateService.currentLang
        };
        this.subscriptions.add(this.apiAdministrationService.actionLogs([params]).subscribe(res => {
            // Front's sku and filtering
            for (let key in res['data']) {
                if (res['data'].hasOwnProperty(key) && res['data'][key].message) {
                    if (this.translateService.currentLang === 'fr') {
                        if (res['data'][key].message.includes('TRAITS&PRB')) {
                            res['data'][key].message = res['data'][key].message.replace('TRAITS&PRB', 'TRAITS&PCR');
                        }
                        if (res['data'][key].message.includes('MOVE_CREDITS_SUBACCOUNT')) {
                            res['data'][key].message = res['data'][key].message.replace('MOVE_CREDITS_SUBACCOUNT', 'DEPLACER_CREDITS_SOUSCOMPTE');
                        }
                    } else {
                        if (res['data'][key].message.includes('DIT')) {
                            res['data'][key].message = res['data'][key].message.replace('DIT', 'IDW');
                        }
                    }
                    if (res['data'][key].message.includes('ADJUSTMENT_MANUALLY')) {
                        res['data'][key].message = this.translateService.instant('admin.accountDetail.manualAdjustment', {
                            cost: res['data'][key].info.productData.cost,
                            fullName: res['data'][key].info.userFrom.fullName
                        });
                    }
                    // No move credits log and sub account creation already has a specific log
                    if (res['data'][key].message.includes('(SKU: MOVE_CREDITS_SUBACCOUNT)') || res['data'][key].message.includes('SUBACCOUNT_CREATION')) {
                        delete res['data'][key];
                    }
                }
            }
            // Add data after existing one
            if (options.isConcat && this.actionLogs.data) {
                res.data = this.actionLogs.data.concat(res.data);
            }
            this.actionLogs = res;
            this.changeDetectorRef.markForCheck();
        }));
    }

    deleteAccount() {
        const txtDeletion = {
            paragraph: this.translateService.instant('admin.accountDetail.warningAlert'),
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };

        this.alertService.setConfig(txtDeletion);
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm') {
                    this.subscriptions.add(this.apiAdministrationService.deleteAccount([this.accountService.id]).subscribe(
                        () => {
                            this.accountService.resetAccountToDisplay();
                            this.router.navigate(['admin/list']).then(() => {
                            });
                        }
                    ));
                }
            }
        ));
    }

    goBack() {
        if (this.new === 'account') {
            this.router.navigate(['admin/list']).then(() => {
            });
        }
        if (this.new === 'subAccount') {
            this.goToSubAccount0();
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ACCESSORs

    get isPrincipalSubAccount() {
        return (!this.new) ? (this.subAccountService.id === 0) : false;
    }

    get canEdit() {
        console.log(this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN'));
        return this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN') ?? false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
