export class TalentsHelper {
    /**
     * Order talents data by name
     * @param talentsData
     * @returns Ordered talents data
     */
    public static getSortedTalentsByName(talentsData: any[]): any[] {
        const sortedTalentsData: any[] = talentsData.sort(
            (a: any, b: any) => a.fullTitle.localeCompare(b.fullTitle, 'fr', {sensitivity: 'base', ignorePunctuation: true})
        );

        return sortedTalentsData;
    }

    /**
     * Order talents data by number
     * @param talentsData
     * @returns Ordered talents data
     */
    public static getSortedTalentsByNumber(talentsData: any[]): any[] {
        const sortedTalentsData: any[] = talentsData.sort((a: any, b: any) => a.number - b.number);

        return sortedTalentsData;
    }
}
