import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {RightMatch} from '../../../../right-matches/models/right-match.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Tools} from '../../../../../shared/misc/tools';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';
import {JobSatisfaction} from '../../../models/job-satisfaction.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-job-satisfactions-edit',
  templateUrl: './mobile-job-satisfactions-edit.component.html',
  styleUrls: ['./mobile-job-satisfactions-edit.component.scss']
})
export class MobileJobSatisfactionsEditComponent implements OnInit, OnChanges, OnDestroy {

    @Input() status;
    @Input() multiAction;

    @Output() close = new EventEmitter();

    @ViewChild('jobSatisfactionNgForm') jobSatisfactionNgForm;

    jobSatisfaction;
    jobSatisfactionForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    headerText: string;

    submitted = false;

    // Selecting people part
    public loading = false;
    searchTerm: string;
    actual = '';
    recordsLoaded = 100;
    recordsLength: number;
    records = [];
    selectedItems: Array<string> = [];

    public multiActionDestroy: EventEmitter<any> = new EventEmitter();

    private subscriptions = new Subscription();

    constructor(
        public jobSatisfactionService: JobSatisfactionsAnalysisService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private router: Router,
        private stateService: StateService,
        private apiJobSatisfactionService: ApiJobSatisfactionsService,
        private apiPeopleService: ApiPeopleService,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.fetchRecords();
    }

    ngOnChanges() {
        this.requestedBy = this.stateService.session.sessionData.userData.username;
        this.sessionStructure = this.stateService.session.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.jobSatisfaction = Object.assign({}, new JobSatisfaction());
            this.jobSatisfaction.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.jobSatisfaction = Object.assign({}, this.jobSatisfactionService.itemToDisplay);
            for (let record of this.jobSatisfaction.records) {
                this.selectedItems.push(record.id);
            }
        }
        if (this.multiAction) {
            if (this.multiAction.ids.length !== 0) {
                this.headerText = (this.multiAction.ids.length === 1) ?
                    'Le candidat sélectionné précédemment sera ajouté à l\'analyse automatiquement' :
                    'Les candidats sélectionnés précédemment seront ajoutés à l\'analyse automatiquement';
            }
        }

        this.initForm();
    }

    fetchRecords(searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        this.subscriptions.add(this.apiPeopleService.records(
            [{
                'search': searchTerm,
                'skip': skip,
                'isArchived': 0,
                'reportStatuses[mpo]': 1,
                'limit': this.recordsLoaded
            }]
        ).subscribe(
            (res) => {
                // Get records loaded (add the new records from API)
                this.recordsLoaded = (skip) ?
                    this.recordsLoaded + res.data.length :
                    this.recordsLoaded;

                // Records data (merge new records from API)
                this.records = (concat) ?
                    [...this.records, ...res.data] :
                    res.data;

                // Total records
                this.recordsLength = res.count;

                // Reload component
                this.changeDetectorRef.markForCheck();

                this.loading = false;
            }
        ));
    }

    onListScroll() {
        if (this.recordsLoaded >= this.recordsLength) {

            return;
        }
        this.fetchRecords(this.searchTerm, this.recordsLoaded, true);
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        }
        this.changeDetectorRef.markForCheck();
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems.push(id);
        } else {
            this.selectedItems.splice(
                this.selectedItems.indexOf(id),
                1
            );
        }
    }

    formatDateWithLang(date, lang) {
        if (date) {
            if (lang === 'fr') {
                return date.split('-').reverse().join('/');
            } else {
                return date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0];
            }
        }
        return;
    }

    formCheck(specificField = null) {
        if (this.jobSatisfactionNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.jobSatisfactionForm.controls[specificField], this.jobSatisfactionNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.jobSatisfactionForm.controls[field], this.jobSatisfactionNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.submitted = false;

        const departmentId = (this.jobSatisfaction.department && this.jobSatisfaction.department.length > 0)
            ? this.jobSatisfaction.department[0].id
            : null;

        let subAccount = (this.sessionData.structure.principalSubAccount) ?
            this.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.jobSatisfaction.subAccount;
        }

        this.jobSatisfactionForm = this.fb.group({
            name: [this.jobSatisfaction.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.jobSatisfaction.consultant],
            department: [departmentId],
            searchTerm: ['']
        });
    }

    onSubmit(model: UntypedFormGroup) {

        this.submitted = true;
        if (model.valid) {
            // console.log(model.value);
            let data = model.value;
            data.records = this.selectedItems;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    saveNew(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiJobSatisfactionService.postJobSatisfaction(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'records': this.multiAction.ids};
                    this.subscriptions.add(this.apiJobSatisfactionService.putJobSatisfaction([res.id], data).subscribe(
                        () => {
                            this.multiActionDestroy.emit({destroy: true});
                            this.onCancel();
                        }
                    ));
                } else {
                    this.onCancel();
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiJobSatisfactionService.putJobSatisfaction([this.jobSatisfaction.id], data).subscribe(
            (res) => {
                this.jobSatisfactionService.setItemToDisplayWithId(this.jobSatisfactionService.id);
                this.onCancel();
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    resetForm() {
        this.submitted = false;
        this.jobSatisfactionForm.reset();
        this.jobSatisfactionNgForm.submitted = false;
        this.initForm();
    }

    onCancel() {
        this.resetForm();
        this.close.emit(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }
}
