import {Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DynamicFormService} from '../../services/dynamic-form.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-form-table',
    templateUrl: './form-table.component.html',
    styleUrls: ['./form-table.component.scss']
})

export class FormTableComponent implements OnInit, OnDestroy {

    @Input() formDefinition: any;
    @Output() formOutput = new EventEmitter();
    @ViewChild('registrationForm') registrationForm;

    language: any;

    form: UntypedFormGroup;
    showNonValidMessage = false;

    private subscriptions = new Subscription();

    constructor(
        private dynamicFormService: DynamicFormService,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.form = this.dynamicFormService.initForm(this.formDefinition);
        this.language = this.translate.currentLang;
        this.subscriptions.add(this.translate.onLangChange.subscribe((event) => {
            this.language = event.lang;
        }));
    }

    onSubmit(data: any) {
        if (this.form.valid) {
            this.showNonValidMessage = false;
            this.formOutput.emit(data);
        } else {
            this.showNonValidMessage = true;
        }
    }

    /**
     *
     * @param item - an input in the formDefinition
     * @returns {boolean}
     */

    columnDisplay(item: any) {
        return item.options === undefined || (!item.options.reverse && !item.options.merge);
    }

    /**
     *
     * @param form - the form
     * @param item - is either of 'below', 'text' or 'border'
     * @param type - item - an input in the formDefinition
     * @returns {boolean}
     */

    warning(form: any, item: any, type: string) {
        if ((item.display.visualWarnings !== undefined) && item.display.visualWarnings[type]) {
            if (item.states && item.states.touched) {
                if (!form.controls[item.name].valid) {
                    return true;
                }
            } else {
                if (form.controls[item.name].untouched) {
                    return false;
                } else {
                    return !form.controls[item.name].valid;
                }
            }
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

