export class CanonicalizeHelper {
    /**
     * Scan a request object and add the Canonical suffix to ordering terms that
     * need it.
     */
    public static addSuffix(moduleName, request) {
        if (['people', 'jobs', 'rightMatches', 'graphboards', 'idwMatrices', 'administration', 'jobSatisfactions']
            .indexOf(moduleName) === -1) { throw 'Invalid module name in canonicalize.helper.ts'; }
        let keyToCanonicalize;
        switch (moduleName) {
            case 'people':
                keyToCanonicalize = ['firstName', 'lastName', 'organisation', 'jobTitle'];
                break;
            case 'jobs':
                keyToCanonicalize = ['jobTitle', 'location', 'reportsTo'];
                break;
            case'rightMatches':
            case 'graphboards':
                keyToCanonicalize = ['name'];
                break;
            case 'idwMatrices':
            case 'jobSatisfactions':
                keyToCanonicalize = ['name', 'consultant'];
                break;
            case 'administration':
                keyToCanonicalize = ['name', 'subAccountIName'];
                break;
            // users' Data?
        }
        // tslint:disable-next-line:forin
        for (let item in request.order) {
            let itemArr = item.split('.');
            if (
                // If ordering is on the it-h subAccount's name
                keyToCanonicalize.indexOf('subAccountIName') !== -1 && itemArr[0] === 'subAccounts' && itemArr[2] === 'name'
                // Regular ordering
                || keyToCanonicalize.indexOf(item) !== -1
            ) {
                let itemCanonicalized = item + 'Canonical';

                request.order[itemCanonicalized] = request.order[item];
                delete request.order[item];
            }
        }
        return request;
    }
}
