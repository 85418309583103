import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'nl2br'})
export class Nl2BrPipe implements PipeTransform {
    transform(value: any): any {
        if (typeof value === 'undefined' || value === null) {
            return value;
        }

        return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
}
