import {throwError as observableThrowError, Observable, of} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../../app/services/env.service';

export abstract class Api {

    constructor(
        protected http: HttpClient,
        protected environment: EnvService
    ) {
    }

    /**
     *
     * Sends data to the API the response is returned as an observable.
     *
     * @param {string} verb
     * @param {string} route
     * @param {Array<string>} urlParams
     * @param {*} data
     * @returns
     */

    protected toApi(verb: string, route: string, urlParams: any, data: any): Observable<any> {
        // Vanilla route
        let apiBaseUrl: string = this.environment.apiBaseUrl + 'api/';
        route = apiBaseUrl + route;

        // Insert url parameters if any
        if (urlParams !== null) {
            urlParams.forEach((elt, i) => {
                if (route.match('UrlParameter' + i) !== null) {
                    route = route.replace('UrlParameter' + i, urlParams[i]);
                } else {
                    route = route + '/' + urlParams[i];
                }
            });
        }

        // Query the API according to the verb passed
        if (verb === 'get') {
            route = data ? route + data : route;
            return this.http[verb](route).pipe(
                catchError((error: any) => {
                    // let urlRhs = (window.location.href.split(';')[1]!='refresh=1');
                    let errorMessage = error.error;
                    if (errorMessage && errorMessage.code === 401 && errorMessage.message === 'Invalid JWT Token') {
                        window.location.href = '/;refresh=1';
                    } else {
                        return observableThrowError(error);
                    }
                    return of(null);
                }));
            // } else if (verb === 'post' || verb === 'put' || verb === 'delete') {
        } else if (verb === 'delete') {
            route = data ? route + data : route;
            return this.http[verb](route).pipe(
                catchError((error: any) => {
                    // let urlRhs = (window.location.href.split(';')[1]!='refresh=1');
                    let errorMessage = error.error;
                    if (errorMessage && errorMessage.code === 401 && errorMessage.message === 'Invalid JWT Token') {
                        window.location.href = '/;refresh=1';
                    } else {
                        return observableThrowError(error);
                    }
                    return of(null);
                }));
            // } else if (verb === 'post' || verb === 'put' || verb === 'delete') {
        } else {
            return this.http[verb](route, data).pipe(
                catchError((error: any) => {
                    // let urlRhs = (window.location.href.split(';')[1]!='refresh=1');
                    let errorMessage = error.error;
                    console.log(error);
                    if (errorMessage.code === 401 && errorMessage.message === 'Invalid JWT Token') {
                        window.location.href = '/;refresh=1';
                    } else {
                        return observableThrowError(error);
                    }
                    return of(null);
                }));
        }
    }

}
