import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({
    selector: '[appRadioInput]'
})
export class RadioInputDirective implements OnInit {

    @Input() definition: any;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.type = this.definition.type;
        this.el.nativeElement.name = this.definition.name;
        this.el.nativeElement.value = this.definition.value;
        if (this.definition.checked !== undefined && this.definition.checked === true) {
            this.el.nativeElement.checked = true;
        }
    }

}
