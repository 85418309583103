import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ComponentList} from 'src/app/shared/classes/components/component_list';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {UserService} from 'src/app/core/services/user/user.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {GraphBoardsModalHandlingService} from '../../../services/graphboards-modal-handling.service';
import {AlertService} from '../../../../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-graphboards-list',
    templateUrl: './graphboards-list.component.html',
    styleUrls: ['./graphboards-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsListComponent extends ComponentList implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('gSidebar') gSidebar;

    archivedRecords = [];

    displayConstant = {
        adjustment: {left: -5, right: -14},
        widthRatio: 2 / 3,
        leftColumnWidth: 440
    };
    action;
    // The list width (corrected)
    listWidth: any;
    sideWidth: any;

    private subscriptions = new Subscription();

    constructor(public router: Router,
                private route: ActivatedRoute,
                private apiPeopleService: ApiPeopleService,
                public userPreferencesService: UserPreferencesService,
                public stateService: StateService,
                public user: UserService,
                protected deviceService: DeviceDetectorService,
                public graphBoard: GraphBoardsAnalysisService,
                public graphBoardsList: GraphBoardsListService,
                public graphBoardsModalHandlingService: GraphBoardsModalHandlingService,
                private apiGraphboardsService: ApiGraphboardsService,
                private changeDetectorRef: ChangeDetectorRef,
                protected translateService: TranslateService,
                private alertService: AlertService
    ) {
        super(stateService, translateService, router, deviceService);
        if (!this.stateService.hasPersonality) {
            if (this.stateService.hasCommunication) {
                this.router.navigate(['/analysis/idwmatrix/list']).then(() => {});
            } else {
                this.router.navigate(['/people/list']).then(() => {});
            }
        }
        // set the sidebar status
        this.graphBoardsList._opened = !this.smallSize;
    }

    ngOnInit() {
        this.graphBoardsList.resetList();
        if (!this.graphBoardsList.archive) {
            this.setLastAddress();
        }
        this.graphBoardsList.multiAction = null;

        // @ilya 2021-02-16 - The code below was canceled so as not to force the sub-accounts filter
        // let curSubAccounts = (this.state.listSearchFilters.subAccount) ? this.state.listSearchFilters.subAccount : [];
        //
        // if (typeof curSubAccounts === 'string') {
        //     curSubAccounts = this.state.listSearchFilters.subAccount.split(',');
        // }
        // if (curSubAccounts.length < this.stateService.session.sessionData.structure.subAccounts.length) {
        //     curSubAccounts = [];
        //     for (let i = 0; i < this.stateService.session.sessionData.structure.subAccounts.length; i++) {
        //         curSubAccounts.push(this.stateService.session.sessionData.structure.subAccounts[i].id);
        //     }
        //     this.state.listSearchFilters.subAccount = curSubAccounts.join();
        // }

        this.graphBoardsList.checkModuleUrl();
        this.graphBoardsList.checkUserGraphBoardDisplayPreferences();

        // check items list without selection
        this.graphBoardsList.checkTotalItems();

        if (this.graphBoard.id) {
            this.graphBoard.setItemToDisplayWithId(this.graphBoard.id, this.specificState());
        }

        this.graphBoardsList.graphBoardState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.graphBoardsList.getGraphBoardsList(true, null, this);

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.graphBoardsList.getGraphBoardsList(true, null, this);
            }
        ));

        // check the redirection case from another module with the passed graph id as query parameter
        // for example: new graph board creation with an associated person from the People module
        this.route.queryParams
            // .filter(params => params.id)
            .subscribe(params => {
                    this.itemSelected(params.id, true);
                }
            );

        // Check the archived records
        this.subscriptions.add(this.apiPeopleService.records([{isArchived: 1}]).subscribe(
            (res) => {
                res.data.forEach(r => {
                    this.archivedRecords.push(r.id);
                });
            })
        );

        this.computeListWidth(window);
    }

    ngAfterViewInit() {
        if (this.graphBoardsList.graphBoardState.multiAction.ids && this.graphBoardsList.graphBoardState.multiAction.ids.length) {
            this.graphBoardsList.multiAction = this.graphBoardsList.graphBoardState.multiAction;
            this.graphBoardsList.graphBoardState.resetMultiAction();

            if (this.graphBoardsList.multiAction['action'] === 'add') {
                this.graphBoardsModalHandlingService.openGraphBoardPersonModal(this.archivedRecords);
            } else if (this.graphBoardsList.multiAction['action'] === 'create') {
                this.graphBoardsModalHandlingService.openGraphBoardModal({status: 'new'});
            }
        }
    }

    multiActionDestroy() {
        this.graphBoardsList.multiAction = null;
    }

    refreshList() {
        this.graphBoardsList.getGraphBoardsList(true, null, this);
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);

        this.graphBoardsList._opened = !this.smallSize;
        this.closed = this.smallSize;
    }

    computeListWidth(window: Window): void {
        let innerWidth = window.innerWidth;
        if (window.innerWidth < 1235) {
            this.listWidth = innerWidth - this.displayConstant.leftColumnWidth + this.displayConstant.adjustment.left + 'px';
            this.sideWidth = '400px';
        } else {
            this.sideWidth = (innerWidth - this.displayConstant.leftColumnWidth) *
                (1 - this.displayConstant.widthRatio) + this.displayConstant.adjustment.right;
            if (this.sideWidth < 400) {
                this.sideWidth = 400;
            }
            // @ilya 2020-10-16 - fix the side width to 400px
            this.sideWidth = 400;
            this.listWidth = innerWidth - (this.sideWidth * 1.25) + 'px';
            this.sideWidth = this.sideWidth + 'px';
        }
    }

    setItemToDisplay(itemToDisplayId) {
        if (itemToDisplayId) {
            this.graphBoard.setItemToDisplayWithId(itemToDisplayId, this.specificState());
        }
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.graphBoardsList.setOrder(event);
        this.refreshList();
    }

    /**
     * Set selected item on event from shared/main-list/component/main-list
     * @param item
     * @param flat
     */
    itemSelected(item, flat = false): void {
        this.closed = false;
        this.graphBoardsList.graphBoardState.lastSideBarStatus = 'reports';
        if (flat) {
            item = [{id: item}];
        }
        this.graphBoardsList.itemSelected(item);
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.graphBoardsList._opened = true;
            this.gSidebar.open();
        }
    }

    /**
     * Set search parameter on event from shared/main-list/component/main-list-search-bar
     * @param event
     */
    setSearchFilter(event): void {
        this.graphBoardsList.setSearchFilter(event);
        this.refreshList();
    }

    actOnRecords(event) {
        let total = event.ids.length;
        let handled = 0;
        let pAction;
        let plural = (event.ids.length > 1) ? 's' : '';
        let paragraph;
        let alertText;
        this.action = event.actionType;
        if (this.action === 'delete') {
            pAction = this.translateService.instant('commons.deletionAction');
            paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                action: pAction,
                total: total,
                plural: plural
            });
            const deleteTxt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(deleteTxt);
            this.alertService.nextAction.subscribe(
                res => {
                    if (res === 'confirm' && this.action === 'delete') {
                        // Delete all selected ids
                        this.apiGraphboardsService.deleteGraphBoards(event.ids).subscribe(
                            () => {
                                paragraph = this.translateService.instant('analysis.commons.graphBoardDeleted', {
                                    total: total,
                                    plural: plural
                                });
                                alertText = this.translateService.instant('analysis.commons.graphBoardDeletion');
                                const txtDelete = {
                                    paragraph: paragraph,
                                    texth1: alertText,
                                    position: 'top-right',
                                    type: 'green'
                                };
                                this.graphBoard.resetItemToDisplay(this.specificState());
                                this.graphBoardsList.getGraphBoardsList(true, txtDelete, this);
                                this.graphBoardsList.revertToNews();
                                this.graphBoardsList.cleanMultiselectMode(true);
                            },
                            () => {
                                const txtDeleteError = {
                                    paragraph: this.translateService.instant('commons.tryAgain'),
                                    texth1: this.translateService.instant('commons.wasError'),
                                    position: 'center-center',
                                    type: 'red'
                                };
                                this.graphBoardsList.getGraphBoardsList(true, txtDeleteError, this);
                            }
                        );
                        this.action = undefined;
                    }
                }
            );

        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            switch (this.action) {
                case 'archive':
                    putObject = {archive: true};
                    p[0] = this.translateService.instant('analysis.graphBoard.multipleArchived', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleArchiving');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.archivingError');
                    break;
                case 'reactivate':
                    putObject = {archive: false};
                    p[0] = this.translateService.instant('analysis.graphBoard.multipleReactivated', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleReactivation');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.reactError');
                    break;
            }

            if (this.action === 'archive') {
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtWarningArchive = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtWarningArchive);
                this.alertService.nextAction.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'archive') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.graphBoardsList.revertToNews();
                            this.graphBoardsList.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action === 'reactivate') {
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtArchivingConfirmation = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.alertService.nextAction.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.graphBoardsList.revertToNews();
                            this.graphBoardsList.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }
        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.action) {
            let counter = handled;
            for (let id of event.ids) {
                this.apiGraphboardsService.putGraphboard([id], putObject).pipe(take(1)).subscribe(
                    () => {
                        counter++;
                        if (counter === total) {
                            this.graphBoardsList.getGraphBoardsList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            }, this);
                            this.graphBoardsList.cleanMultiselectMode(true);
                            this.graphBoardsList.revertToNews();
                        }
                    },
                    () => {
                        this.graphBoardsList.getGraphBoardsList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        }, this);
                    }
                );
            }
            this.action = undefined;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    checkIsPreferenceSet() {
        return this.userPreferencesService.isPreferenceSet(this.stateService.session.actualModule);
    }

    /**
     * Close jobs sidebar tablet device
     */
    closeGraphBoardsSidebar(closeGraphBoardsSidebar: boolean): void {
        this.stateService[this.specificState()].lastSideBarStatus = 'recentActivities';
        if (closeGraphBoardsSidebar && this.graphBoardsList._opened && this.smallSize) {
            this.graphBoardsList._opened = false;
            this.closed = true;
        }
    }

    protected readonly parseInt = parseInt;
}
