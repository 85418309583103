import {ComponentPrimitive} from './componentPrimitive';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

export abstract class ComponentSidebar extends ComponentPrimitive {
    public names: Array<Array<string>> = [];
    public selectedItems: any = [];
    public actualPage = 1;
    public gActualPage: any = {};
    public maxPage = 1;
    public gMaxPage: any = {};

    protected constructor(
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translateService,
            router,
            deviceService
        );
    }

    goToNews() {
        this.closed = true;
        this.switchOff.emit(true);
        let specificState = this.specificState();
        this.stateService[specificState].multiselect = [];
        this.stateService[specificState].sideBarStatus = 'recentActivities';
        // Close people sidebar tablet device
        this.closeSidebar.emit(true);
    }

    setInformationVisibility(value: boolean) {
        this.informationVisibility = value;
    }

    toggleInformationVisibility() {
        if (this.informationVisibility === null || this.informationVisibility === undefined
        ) {
            throw 'Module ' + this.module.name + ' has no visibility variable';
        }
        this.informationVisibility = !this.informationVisibility;
    }

    goToFullReport(id) {
        let reportUrl = this.router.url.replace('list', 'report/');
        if (this.actualModule && id) {
            let reportType = '';
            if (this.actualModule.name === 'jobs') {
                if (this.stateService.jobs.report && this.stateService.jobs.report.reportType) {
                    reportType = '/' + this.stateService.jobs.report.reportType;
                } else {
                    reportType = '/personality';
                }
            }
            this.stateService.jobs.reportBack = null;
            this.router.navigate([
                reportUrl + id + reportType
            ]).then();
        }
    }

    updateList(seletectedItems, maxPerPage) {
        this.names = [];
        for (let record of seletectedItems) {
            let name;
            if (record.firstName && record.lastName) {
                name = record.firstName + ' ' + record.lastName;
            }
            if (record.name) {
                name = record.name;
            }
            if (this.names.map((el) => el[0]).indexOf(record.id) === -1) {
                this.names.push([record, name]);
            } else {
                this.names.splice(this.names.indexOf([record.id, name]), 1);
            }
        }
        this.maxPage = Math.ceil(this.names.length / maxPerPage);
    }

    goToPage(factor, type = null) {
        if (!type) {
            this.actualPage = this.actualPage + factor;
        } else {
            if (!this.gActualPage[type]) {
                this.gActualPage[type] = 0;
            }
            this.gActualPage[type] += factor;
        }
    }

    goToJob(id: string, reportBackId: string, module: string) {
        let reportBack;
        if (module === 'people') {
            reportBack = {
                to: this.router.url,
                personToDisplayId: reportBackId,
                module: module
            };
        } else {
            reportBack = {
                to: this.router.url,
                // id: reportBackId,
                module: module
            };
        }
        this.stateService.jobs.reportBack = reportBack;
        if (this.stateService[module]) {
            this.stateService[module].goTo = 'jobs';
        }
        this.router.navigate(['jobs/report/' + id + '/personality']).then();
    }

    // Mutators

    get state() {
        return this.stateService[this.specificState()];
    }

    set informationVisibility(visibility) {
        this.stateService[this.specificState()].state.informationVisibility = visibility;
    }

    get informationVisibility(): boolean {
        return this.stateService[this.specificState()].state.informationVisibility;
    }

    set selectedInfoQuestionTab(selectedInfoQuestionTab) {
        this.stateService[this.specificState()].state.selectedInfoQuestionTab = selectedInfoQuestionTab;
    }

    get selectedInfoQuestionTab(): number {
        return this.stateService[this.specificState()].state.selectedInfoQuestionTab;
    }
}
