
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvService} from './env.service';

@Injectable()
export class AdminService {

    private apiBaseUrl: string;

    constructor(
        private http: HttpClient,
        private environment: EnvService
    ) {
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    getSession(lang = null): Observable<any> {
        let url = this.apiBaseUrl + 'api/session';
        if (lang) {
            url = url + '?lang=' + lang;
        }
        return this.http.get(url).pipe(
            catchError(error => observableThrowError(error)));
    }
    getImage(dataParams: any): Observable<any> {
        const route = this.apiBaseUrl + 'api/file?type=' + dataParams.type + '&filename=' + dataParams.filename;
        return this.http.get(route).pipe(catchError(error => observableThrowError(error)),);
    }
}
