import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Rest} from 'src/app/core/services/api/rest';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {EnvService} from '../../app/services/env.service';

@Injectable()
export class ApiJobsService extends Rest {
    constructor(
        protected http: HttpClient,
        protected translate: TranslateService,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    jobs(dataParams: Array<any>): Observable<any> {
        dataParams[0]['lang'] = this.translate.currentLang;
        return this.apiGet(
            'jobs',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    job(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'job',
            urlParams,
            null,
            null
        );
    }

    jobRaTexts(): Observable<any> {
        let dataParams = [
            {
                lang: this.translate.currentLang
            }
        ];
        return this.apiGet(
            'job/ra/texts',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    reportJob<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/job',
            urlParams, // jobDescriptionId
            dataParams,
            ['parts', 'indexProfiler', 'language']
        );
    }

    competenciesLowScore(params: any) {
        let dataParams;
        if (params.jobId) {
            dataParams = [
                {
                    jobId: params.jobId,
                    lang: this.translate.currentLang
                }
            ];
        } else {
            dataParams = [
                {
                    score: params.score,
                    lang: this.translate.currentLang
                }
            ];
        }

        return this.apiGet(
            'job/lower-tier-competencies',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    profilers(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'job/UrlParameter0/profilers',
            urlParams,
            [],
            []
        );
    }

    profilerRequestNext(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'profiler/UrlParameter0/request/next',
            urlParams,
            dataParams,
            ['userId', 'emailTo', 'message']
        );
    }

    profilerRequestToken(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'profiler/UrlParameter0/request/token',
            urlParams,
            dataParams,
            []
        );
    }

    profilerRequestTempUser(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'profiler/UrlParameter0/request/temp-user',
            urlParams,
            dataParams,
            []
        );
    }

    copyJob(urlParams: Array<string>, dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'job/UrlParameter0/copy',
            urlParams,
            dataParams,
            ['@fromObject']
        );
    }

    reportEmail(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'email/report',
            null,
            dataParams,
            ['emailTo', 'subject', 'message', 'pdfLink', 'name', 'origin', 'lang']
        );
    }

    postJob(data): Observable<any> {
        return this.apiPost(
            'job',
            null,
            data
        );
    }

    jobProfiler(urlParams: any, profiler: any, put = false): Observable<any> {
        if (put) {
            return this.apiPut(
                'job/UrlParameter0/profiler/UrlParameter1',
                urlParams,
                profiler
            );
        } else {
            return this.apiPost(
                'job/UrlParameter0/profiler',
                urlParams,
                profiler
            );
        }
    }

    jobTalents(urlParams: any, profiler: any, put = false): Observable<any> {
        if (put) {
            return this.apiPut(
                'job/UrlParameter0/talent/UrlParameter1',
                urlParams,
                profiler
            );
        } else {
            return this.apiPost(
                'job/UrlParameter0/talent',
                urlParams,
                profiler
            );
        }
    }

    jobIac(urlParams: [string], profiler: any, put = false): Observable<any> {
        if (put) {
            return this.apiPut(
                'job/UrlParameter0/ra',
                urlParams,
                profiler
            );
        } else {
            return this.apiPost(
                'job/UrlParameter0/ra',
                urlParams,
                profiler
            );
        }
    }

    deleteJobs(idList: string[]): Observable<any> {
        return this.apiPost(
            'jobs/delete',
            null,
            {idList: idList}
        );
    }

    checkDelete(idList: Array<any>): Observable<any> {
        return this.apiPost(
            'jobs/check-delete',
            null,
            {idList: idList}
        );
    }

    putJob(urlParams: [string], job: any): Observable<any> {
        return this.apiPut(
            'job',
            urlParams,
            job
        );
    }

    deleteJob(urlParams: [string]): Observable<any> {
        return this.apiDelete(
            'job',
            urlParams
        );
    }

    deleteProfiler(urlParams: Array<string | number>): Observable<any> {
        return this.apiDelete('job/UrlParameter0/profiler/UrlParameter1', urlParams);
    }

    deleteCompetence(urlParams: Array<string | number>): Observable<any> {
        return this.apiDelete('job/UrlParameter0/talent/UrlParameter1', urlParams);
    }

    deleteIac(urlParams: Array<string | number>): Observable<any> {
        return this.apiDelete('job/UrlParameter0/ra', urlParams);
    }
}
