import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Content} from 'src/app/shared/classes/services/content';
import {TranslateService} from '@ngx-translate/core';
import {GraphBoardsListService} from './graphboards-list.service';
import {GraphBoardsAnalysisService} from './graphboards-analysis.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {GraphboardsModalComponent} from '../components/web/graphboards-modal/graphboards-modal.component';
import {GraphboardsPdfModalComponent} from '../components/web/graphboards-pdf-modal/graphboards-pdf-modal.component';
import {GraphboardsPersonModalComponent} from '../components/web/graphboards-person-modal/graphboards-person-modal.component';

@Injectable({
    providedIn: 'root'
})
export class GraphBoardsModalHandlingService extends Content {
    personModalRef: BsModalRef;
    pdfModalRef: BsModalRef;
    gModalRef: BsModalRef;

    constructor(
        public graphBoard: GraphBoardsAnalysisService,
        public graphBoardsList: GraphBoardsListService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    openGraphBoardModal(config) {
        this.graphBoardsList.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.graphBoardsList.multiAction,
            status: config.status
        };
        this.gModalRef = this.modalService.show(GraphboardsModalComponent, {initialState, backdrop : 'static'});
        this.gModalRef.content.close.subscribe(res => {
            if (res.id && res.closed) {
                this.graphBoard.setItemToDisplayWithId(res.id, this.graphBoardsList.specificState, 'itemSelected');
                this.graphBoardsList.getGraphBoardsList(true);
            }
        });
    }

    openGraphBoardPersonModal(archivedRecords) {
        this.graphBoardsList.cleanMultiselectMode(true);
        const initialState = {
            multiAction: this.graphBoardsList.multiAction,
            graphboardTab: (this.graphBoardsList.multiAction) ? this.graphBoardsList.multiAction.idType : this.graphBoardsList.graphboardTab,
            archivedRecords: archivedRecords
        };
        this.personModalRef = this.modalService.show(GraphboardsPersonModalComponent, {initialState, backdrop : 'static'});
        this.personModalRef.content.close.subscribe(res => {
            if (res.closed) {
                this.graphBoardsList.multiAction = null;
                if (res.id) {
                    this.graphBoard.setItemToDisplayWithId(res.id, this.graphBoardsList.specificState, 'itemSelected');
                }
            }
        });
        this.personModalRef.content.graphboardTabChange.subscribe(res => {
            if (res.newDisplayedType) {
                this.graphBoardsList.changeGraphBoardTab(res.newDisplayedType);
            }
        });
    }

    openGraphBoardPdfModal(itemToDisplay) {
        this.graphBoardsList.cleanMultiselectMode(true);
        const initialState = {
            itemToDisplay: itemToDisplay
        };
        this.pdfModalRef = this.modalService.show(GraphboardsPdfModalComponent, {initialState, backdrop : 'static'});
    }
}
