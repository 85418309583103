import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AddPeopleAnalysisModalComponent } from './add-people-analysis-modal/add-people-analysis-modal.component';
import { MainListModule } from '../../shared/main-list/main-list.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MobileHeaderComponent } from './mobile/mobile-header/mobile-header.component';
import { PeopleModule } from '../../people/people.module';

@NgModule({
    imports: [CommonModule, TranslateModule, FormsModule, MatGridListModule, PeopleModule, MainListModule],
    declarations: [ AddPeopleAnalysisModalComponent, MobileHeaderComponent ],
    exports: [AddPeopleAnalysisModalComponent, MobileHeaderComponent]
})
export class AnalysisSharedModule {
}
