// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scrollBody {
  height: 100vh;
  padding-bottom: 75px;
}

#princResp {
  width: 100%;
}

#princResp thead tr th, #princResp tbody tr td {
  padding-bottom: 10px;
}

#princResp tbody tr td {
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/web/jobs-report/jobs-report.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":["#scrollBody {\n    height: 100vh;\n    padding-bottom: 75px;\n}\n\n#princResp {\n    width: 100%;\n}\n\n#princResp thead tr th, #princResp tbody tr td {\n    padding-bottom: 10px;\n}\n\n#princResp tbody tr td {\n    vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
