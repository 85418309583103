import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {ComponentFullView} from 'src/app/shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';

@Component({
    selector: 'app-idw-matrix-report',
    templateUrl: './idw-matrix-report.component.html',
    styleUrls: ['./idw-matrix-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatrixReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    reportWidth: string;
    sideWidth: string;
    participants: any;
    viewType = 'trait';
    currentLang: string;

    private subscriptions = new Subscription();

    constructor(
        public idwMatrix: IdwMatrixAnalysisService,
        private route: ActivatedRoute,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        protected translate: TranslateService,
        protected stateService: StateService) {
            super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.itemToDisplayId) {
                    this.fetchParticipants();
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.fetchParticipants();

        // Set list width
        this.computeListWidth(window);
    }

    fetchParticipants() {
        if (this.idwMatrix.records && this.idwMatrix.records.length) {
            this.participants = {
                'trait': [],
                'prb': []
            };
            let records = this.idwMatrix.records.sort(
                function(a, b) {
                    let textA = a.firstName.toUpperCase();
                    let textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );
            for (let participant of records) {
                if (participant.analysisType === 'trait') {
                    this.participants.trait.push(participant);
                } else {
                    this.participants.prb.push(participant);
                }
            }
        } else {
            this.participants = null;
        }
    }

    onResize(event) {
        // re-Set list width
        this.computeListWidth(event.target);
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
        // @ilya 2020-10-16 - fix the side width to 400px
        this.sideWidth = '400px';
        this.reportWidth = (innerWidth - 550) + 'px';
        return;
    }

    switchView(view) {
        this.viewType = view;
    }

    get hasPrb() {
        let hasPrb = false;
        if (this.participants && this.participants.prb && this.participants.prb && _.find(this.participants.prb, 'quad')) {
            hasPrb = true;
        }
        return hasPrb;
    }

    get state(): any {
        return this.stateService.idwMatrix;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
