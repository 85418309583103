// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

.scoreContainer {
  width: 50px;
  margin-right: 25px;
  text-align: center;
}

.scoreContainer.error {
  border: 1px #dc3545 solid;
  color: #dc3545;
}

.modalButton:disabled {
  opacity: 0.65 !important;
  background-color: #999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/jobs-iac-modal/jobs-iac-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,cAAA;AACJ;;AAEA;EACI,wBAAA;EACA,oCAAA;AACJ","sourcesContent":[".modal-body {\n  background-color: #EEE;\n}\n\n.scoreContainer {\n    width: 50px;\n    margin-right: 25px;\n    text-align: center;\n}\n\n.scoreContainer.error {\n    border: 1px #dc3545 solid;\n    color: #dc3545;\n}\n\n.modalButton:disabled {\n    opacity: 0.65 !important;\n    background-color: #999999 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
