// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreContainerField {
  width: 50px;
  text-align: center;
}

.modalButton:disabled {
  opacity: 0.65 !important;
  background-color: #999999 !important;
}

::ng-deep .error .scoreContainerField {
  color: #dc3545;
}

::ng-deep .error .mat-form-field-underline .mat-form-field-ripple {
  background-color: #dc3545 !important;
}

::ng-deep .buttonsTile .mat-figure {
  justify-content: flex-end;
}

::ng-deep .buttonsTile button:first-child {
  margin-right: 10px;
}

::ng-deep .mat-form-field-flex > .mat-form-field-infix {
  padding: 0;
  border-top: 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -15px;
}

::ng-deep label.ng-star-inserted {
  transform: translateY(-1.59375em) scale(0.25) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/mobile/mobile-jobs-iac/mobile-jobs-iac.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,oCAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EAAyD,UAAA;EAAY,aAAA;AAGrE;;AAFA;EAAqE,iBAAA;AAMrE;;AALA;EAAmC,wDAAA;AASnC","sourcesContent":[".scoreContainerField {\n    width: 50px;\n    text-align: center;\n}\n\n.modalButton:disabled {\n    opacity: 0.65 !important;\n    background-color: #999999 !important;\n}\n\n::ng-deep .error .scoreContainerField {\n    color: #dc3545;\n}\n\n::ng-deep .error .mat-form-field-underline .mat-form-field-ripple {\n    background-color: #dc3545 !important;\n}\n\n::ng-deep .buttonsTile .mat-figure {\n    justify-content: flex-end;\n}\n\n::ng-deep .buttonsTile button:first-child {\n    margin-right: 10px;\n}\n\n::ng-deep .mat-form-field-flex > .mat-form-field-infix { padding: 0; border-top: 0}\n::ng-deep .mat-form-field-appearance-outline .mat-form-field-label { margin-top:-15px; }\n::ng-deep label.ng-star-inserted { transform: translateY(-1.59375em) scale(.25) !important; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
