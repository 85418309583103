import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from '../../../../../app/services/window-ref.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../../core/services/user/user.service';
import * as _ from 'lodash';
import {EnvService} from '../../../../../app/services/env.service';

@Component({
  selector: 'app-mobile-right-matches-pdf',
  templateUrl: './mobile-right-matches-pdf.component.html',
  styleUrls: ['./mobile-right-matches-pdf.component.scss']
})
export class MobileRightMatchesPdfComponent implements OnInit , OnDestroy {

    @Output() close = new EventEmitter();

    pdfStructure: any = {
        jobDesign: 1,
        personalityProfile: (this.user.hasPersonalityAccess) ? 1 : 0,
        targetCompetencies: (this.user.hasTalentsAccess) ? 1 : 0,
        caiProfile: (this.user.hasIacAccess) ? 1 : 0,
        personality: (this.user.hasPersonalityAccess) ? 1 : 0,
        talents: (this.user.hasTalentsAccess) ? 1 : 0,
        cai: (this.user.hasIacAccess) ? 1 : 0,
        sortBy: (this.user.hasPersonalityAccess) ? 'personality' : ((this.user.hasTalentsAccess) ? 'talents' : 'cai'),
        displayTargetPersonalityProfile: (this.user.hasPersonalityAccess) ? 1 : null
    };
    optionsCheckboxes = {
        jobDesign: true,
        personalityProfile: true,
        targetCompetencies: true,
        caiProfile: true,
        personality: true,
        talents: true,
        cai: true,
        displayTargetPersonalityProfile: true
    };
    pdfEmail: any;
    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    mailto: string;
    serverText: string;

    selectMethod = false;

    method = 'pdf';
    share = 'none';

    private subscriptions = new Subscription();

    constructor(
        public rightMatchAnalysis: RightMatchAnalysisService,
        private apiRightMatchService: ApiRightMatchesService,
        private changeDetectorRef: ChangeDetectorRef,
        private windowRef: WindowRef,
        public translate: TranslateService,
        public user: UserService,
        private environment: EnvService
    ) {
            this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.initPdfStructure();
    }

    onChange(event) {
        if (event && event.source && event.source.id) {
            this.optionsCheckboxes[event.source.id] = event.checked;
            let totalChecked = 0;
            for (let opt in this.optionsCheckboxes) {
                if (opt && this.optionsCheckboxes.hasOwnProperty(opt) && this.optionsCheckboxes[opt]) {
                    totalChecked++;
                }
            }
            if (totalChecked < 1) {
                this.pdfStructure[event.source.id] = true;
                let currentCheckbox = document.getElementById(event.source.id);
                if (currentCheckbox) {
                    setTimeout(() => {
                        currentCheckbox.classList.add('mat-checkbox-checked');
                    });
                }
            }
        }
    }

    initPdfStructure() {
        // PDF structure
        this.pdfStructure = {
            jobDesign: 1,
            personalityProfile: (!this.rightMatchAnalysis.itemToDisplay.profiler['scores']) ? 0 : 1,
            targetCompetencies: (this.totalCompetencies === 0) ? 0 : 1,
            caiProfile: (this.rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? 0 : 1,
            personality: (!this.rightMatchAnalysis.itemToDisplay.profiler['scores']) ? 0 : 1,
            talents: (this.totalCompetencies === 0) ? 0 : 1,
            cai: (this.rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? 0 : 1,
            sortBy: null,
            displayTargetPersonalityProfile: (!this.rightMatchAnalysis.itemToDisplay.profiler['scores']) ? 0 : 1
        };
        // Sort by
        if (!!this.rightMatchAnalysis.itemToDisplay.profiler['scores'] && this.pdfStructure.sortBy === null) {
            this.pdfStructure.sortBy = 'personality';
        }
        if (this.totalCompetencies !== 0 && this.pdfStructure.sortBy === null) {
            this.pdfStructure.sortBy = 'talents';
        }
        if (this.pdfStructure.sortBy === null) {
            this.pdfStructure.sortBy = 'cai';
        }

        this.optionsCheckboxes = {
            jobDesign: true,
            personalityProfile: (this.rightMatchAnalysis.itemToDisplay.profiler['scores']),
            targetCompetencies: (this.totalCompetencies !== 0),
            caiProfile: (this.rightMatchAnalysis.itemToDisplay.profiler['RA'] !== false),
            personality: (this.rightMatchAnalysis.itemToDisplay.profiler['scores']),
            talents: (this.totalCompetencies !== 0),
            cai: (this.rightMatchAnalysis.itemToDisplay.profiler['RA'] !== false),
            displayTargetPersonalityProfile: (this.rightMatchAnalysis.itemToDisplay.profiler['scores'])
        };

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: (this.translate.currentLang === 'fr') ? 'Rapport Right Match' : 'Right Match Report',
            message: '',
        });

        // PDF link
        this.pdfToken = null;
        this.pdfReportUrl = this.environment.pdfReportUrl;
        this.changeDetectorRef.markForCheck();
    }

    getReportData(data: any) {
        let obs = this.apiRightMatchService.reportRightMatch([this.rightMatchAnalysis.id], [
            [
                3,
                (this.pdfStructure.jobDesign === 0 || this.pdfStructure.jobDesign === false) ? null : 'jobDesign',
                (this.pdfStructure.personalityProfile === 0 || this.pdfStructure.personalityProfile === false) ? null : 'personalityProfile',
                (this.pdfStructure.targetCompetencies === 0 || this.pdfStructure.targetCompetencies === false) ? null : 'targetCompetencies',
                (this.pdfStructure.caiProfile === 0 || this.pdfStructure.caiProfile === false) ? null : 'caiProfile',
                (this.pdfStructure.personality === 0 || this.pdfStructure.personality === false) ? null : 'personality',
                (this.pdfStructure.talents === 0 || this.pdfStructure.talents === false) ? null : 'talents',
                (this.pdfStructure.cai === 0 || this.pdfStructure.cai === false) ? null : 'cai',
            ],
            this.translate.currentLang,
            this.pdfStructure.sortBy,
            this.pdfStructure.displayTargetPersonalityProfile
        ]);

        this.subscriptions.add(obs.subscribe(
            res => {
                this.pdfToken = encodeURI(res.pdfToken);

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + this.pdfToken;

                if (this.translate.currentLang === 'fr') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=Rapport pour l\'analyse Right Match ' + this.rightMatchAnalysis.name + '&body=Vous pouvez voir le rapport pour' +
                            ' l\'analyse Right Match ' + this.rightMatchAnalysis.name + ' en cliquant sur le lien suivant :%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `Vous pouvez voir le rapport pour l'analyse Right Match ` +
                            this.rightMatchAnalysis.name +
                            ` en cliquant sur le lien suivant :

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                } else if (this.translate.currentLang === 'en') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=Right Match Analysis Report for ' + this.rightMatchAnalysis.name + '&body=You can see the report for' +
                            ' the Right Match Analysis ' + this.rightMatchAnalysis.name + ' y clicking here::%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `You can see the report for the Right Match Analysis ` +
                            this.rightMatchAnalysis.name +
                            ` y clicking here:

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.onComplete();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport Right Match' : 'Right Match Report';
        }
        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.rightMatchAnalysis.name,
            'rightMatches',
            this.translate.currentLang
        ];

        this.subscriptions.add(this.apiRightMatchService.reportEmail(dataEmail).subscribe());

    }

    handleReport() {
        switch (this.method) {
            case 'pdf':
                this.downloadReport();
                return;
            case 'cutpaste':
                this.share = 'cutpaste';
                this.getReportData({type: 'token'});
                return;
            case 'mailto':
                this.share = 'mailto';
                this.getReportData({type: 'mailto'});
                return;
            case 'server':
                this.share = 'server';
                this.getReportData({type: 'share'});
                return;
        }
        this.selectMethod = true;
        return;

    }

    changeSelectMethodState() {
        if (this.method !== 'none') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }

    get totalCompetencies() {
        return _.size(this.rightMatchAnalysis.itemToDisplay.profiler['competences'][0]);
    }



    onComplete() {
        this.cancel();
        this.initPdfStructure();
        this.close.emit();
    }

}
