// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=text] {
  border: 0;
  padding: inherit;
  width: 100%;
}

select {
  background-color: white;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 0;
}

option:disabled {
  font-weight: 700;
  font-style: italic;
}

table tr td label:not(.likeSpan) {
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: auto;
}

table tr td {
  padding-top: 12px;
}

table tr td:nth-child(2) {
  padding-left: 5px;
}

tr.actionModify td.pencilCell {
  width: 4%;
}

tr.actionModify td.width50 {
  width: 50%;
}

tr.actionModify td.rPadding5 {
  padding-right: 5px;
}

tr.modifyButtons td {
  padding-top: 0 !important;
}

.modifyButtons td div.edit {
  text-align: right;
}

.modifyButtons div.edit div.saveText,
.modifyButtons div.edit div.cancelText {
  cursor: pointer;
}

.modifyButtons div.edit div.saveText {
  color: rgb(227, 94, 36);
}

tr.actionModify td.modifyEnabled,
tr.modifyButtons td.modifyEnabled {
  border-bottom: 2px solid rgb(227, 94, 36);
}

.permCell {
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-user-form-field/administration-user-form-field.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,uBAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;;EAEI,eAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;;EAEI,yCAAA;AACJ;;AAEA;EACI,sBAAA;EACA,6BAAA;EACA,mBAAA;AACJ","sourcesContent":["input[type=text] {\n    border: 0;\n    padding: inherit;\n    width: 100%;\n}\n\nselect {\n    background-color: white;\n    width: 100%;\n    border: 0;\n    border-radius: 0;\n    padding: 0;\n}\n\noption:disabled {\n    font-weight: 700;\n    font-style: italic;\n}\n\ntable tr td label:not(.likeSpan) {\n    font-size: 14px;\n    white-space: nowrap;\n    margin-bottom: auto;\n}\n\ntable tr td {\n    padding-top: 12px;\n}\n\ntable tr td:nth-child(2) {\n    padding-left: 5px;\n}\n\ntr.actionModify td.pencilCell {\n    width: 4%;\n}\n\ntr.actionModify td.width50 {\n    width: 50%;\n}\n\ntr.actionModify td.rPadding5 {\n    padding-right: 5px;\n}\n\ntr.modifyButtons td {\n    padding-top: 0 !important;\n}\n\n.modifyButtons td div.edit {\n    text-align: right;\n}\n\n.modifyButtons div.edit div.saveText,\n.modifyButtons div.edit div.cancelText {\n    cursor: pointer;\n}\n\n.modifyButtons div.edit div.saveText {\n    color: rgb(227, 94, 36);\n}\n\ntr.actionModify td.modifyEnabled,\ntr.modifyButtons td.modifyEnabled {\n    border-bottom: 2px solid rgb(227, 94, 36);\n}\n\n.permCell {\n    box-sizing: border-box;\n    border-bottom: 1px solid #eee;\n    padding-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
