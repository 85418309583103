import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TestAndReportHandlingService} from '../../../../../../../people/services/test-and-report-handling.service';
import {StateService} from '../../../../../../../core/services/state/state.service';

@Component({
    selector: 'app-right-matches-person-sub-card-personality',
    templateUrl: './right-matches-person-sub-card-personality.component.html',
    styleUrls: [
        './right-matches-person-sub-card-personality.component.scss',
        '../../right-matches-person-card.component.scss'
    ]
})
export class RightMatchesPersonSubCardPersonalityComponent implements OnChanges {

    @Input() record;
    @Input() job;
    @Input() informationVisibility = false;
    @Input() listView = true;
    @Input() pos;

    traitColor = 'red';

    constructor(
        public translateService: TranslateService,
        public cd: ChangeDetectorRef,
        public testAndReportHandlingService: TestAndReportHandlingService,
        public stateService: StateService,
    ) {
    }

    ngOnChanges() {
        this.computeTraitColor();
        this.cd.markForCheck();
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    computeTraitColor() {
        this.traitColor = 'red';
        if (this.record.rightMatchScore === 3) {
            this.traitColor = 'yellow';
        } else if (this.record.rightMatchScore === 4 || this.record.rightMatchScore === 5) {
            this.traitColor = 'green';
        }
        return this.traitColor;
    }

    get state() {
        return this.stateService.rightMatch;
    }

}
