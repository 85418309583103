import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../../core/services/user/user.service';
import {StateService} from '../../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JobSatisfactionsListService} from '../../../services/job-satisfactions-list.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ComponentList} from '../../../../../shared/classes/components/component_list';

@Component({
  selector: 'app-mobile-job-satisfactions-list',
  templateUrl: './mobile-job-satisfactions-list.component.html',
  styleUrls: ['./mobile-job-satisfactions-list.component.scss']
})
export class MobileJobSatisfactionsListComponent extends ComponentList  implements OnInit, OnDestroy {

    analysisTabs = [];

    public loaderDisplay = {
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
    };

    public orderedBy;
    addAnalysis = false;
    itemToDisplay: any;
    isSelected = 'none';
    selectedTab = 3;
    lastSearch: string;

    private subscriptions = new Subscription();

    constructor(protected router: Router,
                private route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,  // Even if flagged as unused, it is used in getList(this, search) below
                public user: UserService,
                public stateService: StateService,
                public jobSatisfactionListService: JobSatisfactionsListService,
                public jobSatisfaction: JobSatisfactionsAnalysisService,
                protected translateService: TranslateService,
                protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('rightmatch');
        }
        if (this.stateService.hasCommunication) {
            this.analysisTabs.push('idwmatrix');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('graphboard');
        }
        if (this.stateService.hasPersonality) {
            this.analysisTabs.push('jobsatisfaction');
        }
        this.orderedBy = this.jobSatisfactionListService.state.listColumns.filter(col => col.order !== undefined);
        if (!this.orderedBy.length) {
            this.jobSatisfactionListService.state.listColumns.find((col) => {
                if (col.key === 'date') {
                    col.order = 'desc';
                }
            });
        }

        if (!this.stateService.hasPersonality) {
            if (this.stateService.hasCommunication) {
                this.router.navigate(['/m/analysis/idwmatrix/list']).then(() => {});
            } else {
                this.router.navigate(['/m/people/list']).then(() => {});
            }
        }
    }

    ngOnInit() {
        if (!this.jobSatisfactionListService.archive) {
            this.setLastAddress();
        }
        this.lastSearch = this.jobSatisfactionListService.state.listSearchFilters.search;
        this.jobSatisfactionListService.checkModuleUrl();

        // check items list without selection
        this.jobSatisfactionListService.checkTotalItems();

        this.jobSatisfactionListService.getJobSatisfactionList(true, null, this);

        this.jobSatisfactionListService.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.refreshComponent) {
                    this.refreshList();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.jobSatisfactionListService.getJobSatisfactionList(true, null, this);
            }
        ));

        this.blurSearchElement();
    }

    refreshList() {
        this.jobSatisfactionListService.getJobSatisfactionList(true, null, this);
    }

    /**
     * Set search parameter on event from shared/main-list/component/mobile/mobile-main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.lastSearch = event;
        this.jobSatisfactionListService.setSearchFilter(event);
        this.refreshList();
        this.blurSearchElement();
    }

    /**
     * Remove focus from the search input
     */
    blurSearchElement() {
        let activeElement = document.activeElement as HTMLElement;
        activeElement.blur();

        // check the case when the button Search/Clear was clicked
        setTimeout(() => {
            if (document.activeElement.tagName === 'INPUT') {
                activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
            }
        }, 0 );
    }

    createNewJobSatisfaction() {
        this.addAnalysis = true;
    }

    backToItemsList() {
        this.refreshList();
        this.addAnalysis = false;
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    setOrder(event) {
        this.jobSatisfactionListService.setOrder(event);
        this.refreshList();
    }

    /**
     * Set selected item on event from shared/main-list/component/mobile/mobile-main-list
     * @param item
     */
    itemSelected(item): void {
        this.closed = false;
        this.jobSatisfactionListService.state.lastSideBarStatus = 'reports';
        this.jobSatisfactionListService.itemSelected(item);
    }

    switchTab(index) {
        const tabName = this.analysisTabs.find((e, i) => {
            if (i === index) {
                return e;
            } else {
                return;
            }
        });
        this.router.navigate(['/m/analysis/' + tabName + '/list']).then(() => {
            this.selectedTab = index;
            console.log('pre-passed');
            this.jobSatisfactionListService.state.stateChanged.next({refreshComponent: true});
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
