import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
// import * as tDef from '../../talents/talentsDefinitions';
import {TalentsDefinitions} from '../../talents/talentsDefinitions';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-competences-graph',
    templateUrl: './competences-graph.component.html',
    styleUrls: ['./competences-graph.component.scss']
})
export class CompetencesGraphComponent implements OnChanges, OnInit {

    @ViewChild('CompetencesGraph', {static: true}) private chartContainer: ElementRef;
    @Input() id: string;
    @Input() scores: any; // assume format '3,5...'
    @Input() type: any = ''; // either of [social, service, management,operationnal,task,sales or global]
    @Input() lang = 'fr';
    @Input() width: any;
    @Input() mobile = false;
    @Input() request = false;
    @Input() fake = false;
    @Input() requestArr: any;

    definitions: any = [];

    barHeight: number = 360 / 2 - 40;

    constructor(
        private translateService: TranslateService,
        public stateService: StateService
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        // resetFunctions
        if (!changes.fake) {
            this.fake = false;
        }
        if (!changes.request) {
            this.request = false;
        }

        this.definitions['aptitudes'] = new TalentsDefinitions().aptitudes;
        this.definitions['convert'] = new TalentsDefinitions().convert;
        this.definitions['talents'] = new TalentsDefinitions().talents;

        this.createCompetenceGraph();
    }

    createCompetenceGraph() {
        let inactive = (!this.scores);
        let fake = this.fake;

        let data = (this.type !== 'global') ? this.definitions['aptitudes']['' + this.definitions['convert'][this.type]].content : null;

        d3.select('#talents_' + this.type).remove();

        let element = this.chartContainer.nativeElement;

        let tWidth = 400;
        let limitOuter = 440;
        let limitInner = 400;
        let buffer = 40;
        if (this.mobile) {
            tWidth = 385;
            limitOuter = 425;
            limitInner = 385;
        }

        if (this.width) {
            tWidth = this.width.substring(0, this.width.length - 2);
        }

        let outerWidth = (tWidth < limitOuter) ? tWidth - buffer : limitInner;
        let svgWidth = outerWidth + 'px';
        if (this.mobile) {
            svgWidth = '100%';
        }

        let svg = d3.select(element)
            .append('svg')
            .attr('height', svgWidth)
            .attr('width', svgWidth)
            .attr('xmlns', 'http://www.w3.org/2000/svg')
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .attr('id', 'talents_' + this.type);

        svg.attr('viewBox', '-250 -250 500 500');

        let append = 82.5;
        if (data) {
            append = 110;
        }

        // Background circle
        svg.append('circle')
            .attr('r', this.barHeight + append)
            .classed('outer', true)
            .style('fill', 'white')
            .style('stroke', 'none')
            .style('stroke-width', '20px');
        svg.append('circle')
            .attr('r', this.barHeight + 32)
            .classed('outer', true)
            .style('fill', '#EEE')
            .style('stroke', 'none')
            .style('stroke-width', '20px');

        let extent = [0, 10];
        let barScale = d3.scaleLinear()
            .domain(extent)
            .range([0, this.barHeight]);

        let numBars = 0;
        let keys = [];

        if (this.type !== 'global') {
            // console.log(data);
            keys = data.map(function (d, i) {
                return this.definitions['talents'][this.lang][d - 1];
            }, this);
            // console.log(keys);
            numBars = keys.length;
        } else {
            for (let aptitude in this.definitions['aptitudes']) {
                if (aptitude && this.definitions['aptitudes'].hasOwnProperty(aptitude)) {
                    let key = this.definitions['aptitudes'][aptitude].ttitle[this.lang];
                    keys.push(key);
                }
            }
            numBars = keys.length;
        }

        // Outer circles

        let a = 0;
        let b = (2 * Math.PI) / numBars;
        let dist = (b - a); // (angle opening)

        // Create arcs
        let arc = d3.arc()
            .startAngle(function (d, i) {
                return i * dist;
            })
            .endAngle(function (d, i) {
                return (i + 1) * dist;
            })
            .innerRadius(43);


        // Extract this scores
        if (!inactive) {
            let scores = [];

            if (data) {
                for (let datum of data) {
                    scores.push({
                        value: (this.scores[0]) ? this.scores[0][datum - 1] : [],
                        outerRadius: null
                    });
                }
            } else {
                for (let datum of this.scores[1]) {
                    scores.push({
                        value: datum,
                        outerRadius: null
                    });
                }
            }


            // Render colored arcs

            svg.selectAll('path')
                .data(scores)
                .enter().append('path')
                .each(function (d) {
                    d.outerRadius = barScale(+d.value + 3);
                })
                .style('fill', function (d) {
                    let val = d.value;
                    if (val <= 3) {
                        return '#779cc5'; // '#e2bb95';
                    } else if (val <= 6) {
                        return '#efce7c'; // '#e59950';
                    } else {
                        return '#78c7af'; // '#ea7300'; //rgb(227, 94, 36)
                    }
                })
                .style('stroke', 'white')
                .style('stroke-width', '2px')
                .attr('d', arc);
        }

        // Outer ring #1
        svg.append('circle')
            .attr('r', this.barHeight + 40)
            .classed('outer', true)
            .style('fill', 'none')
            .style('stroke-dasharray', '5, 5')
            .style('stroke', '#666')
            .style('stroke-width', '1px');

        // Outer ring #2
        svg.append('circle')
            .attr('r', this.barHeight + 75)
            .classed('outer', true)
            .style('fill', 'none')
            .style('stroke-dasharray', '5, 5')
            .style('stroke', '#666')
            .style('stroke-width', '1px');

        // Inner white circles
        for (let c = 2; c < 12; c++) {
            svg.append('circle')
                .attr('id', 'circle' + c + '_' + this.id)

                .attr('r', barScale(c) + 15)
                .style('fill', 'none')
                .style('stroke', 'white')
                .style('stroke-width', '2px');
        }

        if (data) {
            // Outer circle
            svg.append('circle')
                .attr('r', this.barHeight + 92)
                .classed('outer', true)
                .style('fill', 'none')
                .style('stroke', '#DDD') // paler
                .style('stroke-width', '20px');
        }

        let aptitudeColor = '#779cc5'; // rgb(119,156,197)

        if (data) {
            if (this.scores[1] && this.scores[1][this.type] > 3) {
                if (this.scores[1] && this.scores[1][this.type] <= 6) {
                    aptitudeColor = '#efce7c'; // '#e59950';
                } else {
                    aptitudeColor = '#78c7af'; // '#ea7300'; //rgb(227, 94, 36)
                }
            }
        } else {
            aptitudeColor = 'none';
        }

        if (data && this.scores[1]) {
            let angle = this.scores[1][this.type] * (360 / 9);

            let arc2 = d3.arc()
                .innerRadius(this.barHeight + 82)
                .outerRadius(this.barHeight + 102)
                .startAngle(0) // convert from degs to radians
                .endAngle(-angle * (Math.PI / 180)); // just radians

            svg.append('path')
                .attr('d', arc2)
                .style('fill', aptitudeColor)
                .attr('transform', 'translate(0,0)');
        }

        // Create an arc function
        let height = 400;
        let arcText = d3.arc()
            .innerRadius(height * 0.75 / 2)
            .outerRadius(height * 0.75 / 2 + 30);
        // .startAngle(0)
        // .endAngle(2*Math.PI);
        let pie = d3.pie()
            .value(1)
            .padAngle(.01)
            .sort(null);

        let that = this;
        svg.selectAll('.donutArcs')
            .data(pie(keys))
            .enter()
            .append('path')
            .attr('class', 'donutArcs')
            .attr('d', <any>arcText)
            .style('fill', 'none')
            .each(function (d, i) {
                // Search pattern for everything between the start and the first capital L
                let firstArcSection = /(^.+?)L/;
                // Grab everything up to the first Line statement
                let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1];
                // Replace all the comma's so that IE can handle it
                newArc = newArc.replace(/,/g, ' ');

                // If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                // flip the end and start position
                if (d.endAngle > 90 * Math.PI / 180 && d.endAngle < 270 * Math.PI / 180) {
                    let startLoc = /M(.*?)A/,		// Everything between the first capital M and first capital A
                        middleLoc = /A(.*?)0 0 1/,	// Everything between the first capital A and 0 0 1
                        endLoc = /0 0 1 (.*?)$/;	// Everything between the first 0 0 1 and the end of the string (denoted by $)
                    // Flip the direction of the arc by switching the start en end point (and sweep flag)
                    // of those elements that are below the horizontal line
                    let newStart = endLoc.exec(newArc)[1];
                    let newEnd = startLoc.exec(newArc)[1];
                    let middleSec = middleLoc.exec(newArc)[1];

                    // Build up the new arc notation, set the sweep-flag to 0
                    newArc = 'M' + newStart + 'A' + middleSec + '0 0 0 ' + newEnd;
                }
                // Create a new invisible arc that the text can flow along
                let group = svg.append('g');


                group.append('defs')
                    .append('path')
                    .attr('id', 'donutArc' + i + '_' + that.id)
                    .attr('d', newArc); // .replace(/ /g, ","))

                if (!fake) {
                    if (d.data[1]) {
                        group.append('text')
                        // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                            .attr('dy',
                                (d.endAngle > 90 * Math.PI / 180 && d.endAngle < 270 * Math.PI / 180)
                                    ? 13 : -22)
                            .append('textPath')
                            .attr('xlink:xlink:href', window.location.href + '#donutArc' + i + '_' + that.id)
                            .attr('text-anchor', 'middle')
                            .attr('startOffset', '50%')
                            .text(d.data[0]);
                        group.append('text')
                        // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                            .attr('dy',
                                (d.endAngle > 90 * Math.PI / 180 && d.endAngle < 270 * Math.PI / 180)
                                    ? 28 : -7)
                            .append('textPath')
                            .attr('xlink:xlink:href', window.location.href + '#donutArc' + i + '_' + that.id)
                            .attr('text-anchor', 'middle')
                            .attr('startOffset', '50%')
                            .text(d.data[1]);
                    } else {
                        let circleTxt = group.append('text')
                        // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                            .attr('dy',
                                (d.endAngle > 90 * Math.PI / 180 && d.endAngle < 270 * Math.PI / 180)
                                    ? 20 : -14)
                            .append('textPath')
                            .attr('xlink:xlink:href', window.location.href + '#donutArc' + i + '_' + that.id)
                            .attr('text-anchor', 'middle')
                            .attr('startOffset', '50%')
                            .text(d.data[0]);
                        if (inactive) {
                            circleTxt.attr('fill', '#aaa');
                        }
                    }
                }
            });

        if (inactive) {
            let notCompleted = this.translateService.instant('people.commons.notCompleted');
            if ((this.requestArr && !this.requestArr.includes('talents')) || !this.stateService.hasTalents) {
                notCompleted = this.translateService.instant('commons.none');
            }
            svg.append('text').attr('x', 0).attr('y', 15).text(notCompleted).
            attr('text-anchor', 'middle').
            attr('font-family', 'sans-serif').
            attr('font-size', 50).attr('fill', 'rgb(86, 86, 86)');
        }

        if (data && this.scores[1] && !fake) {
            svg.append('text')
                .attr('x', 0).attr('y', 17.5)
                .text(this.scores[1][this.type])
                .attr('text-anchor', 'middle').attr('font-family', 'sans-serif')
                .attr('font-size', 50)
                .attr('fill', aptitudeColor);
        }

        svg.selectAll('path.donutArcs').remove();
    }
}
