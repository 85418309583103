import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';
import {PeopleState} from '../../interfaces/state';
import {TranslateService} from '@ngx-translate/core';

export class StateArchivesPeople extends AbstractState {

    readonly listColumnsInitialConfig = [
        {key: 'firstName', display: true},
        {key: 'lastName', display: true},
        {key: 'creationDate', display: true},
        {key: 'status', display: true},
        {key: 'statusPrb', display: true}
    ];

    public state: PeopleState = {
        personToDisplayId: null,
        sideBarStatus: 'recentActivities',
        report: {
            type: 'personality',
            subType: null
        },
        goTo: null,
        listView: 'list',
        listSearchFilters: {},
        listColumns: [
            {key: 'firstName', display: true},
            {key: 'lastName', display: true},
            {key: 'creationDate', display: true},
            {key: 'status', display: true},
            {key: 'statusPrb', display: true}
        ],
        hiddenColumns: [],
        prbIndex: 0,
        selectedInfoQuestionTab: 0,
        reportTab: 0,
        informationVisibility: true
    };

    ////// MULTISELECT ///////////////////////////////////////////////////////////////////////////

    public multiselect = [];

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    clear = new BehaviorSubject(null);
    clear$ = this.clear.asObservable();

    multiselectWatch = new BehaviorSubject(null);
    multiselectWatch$ = this.multiselectWatch.asObservable();

    // CONSTRUCTOR ///////////////////////////////////////////////////////////////////////////////

    constructor(
        private translate: TranslateService
    ) {
        super();
        // Initialization from sessionStorage
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('archivesPeople.' + String(item));
            }
        }
    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    get reportTab() {
        return this.state.reportTab;
    }

    set reportTab(tabIndex) {
        this.state.reportTab = tabIndex;
    }

    set selectedInfoQuestionTab(tabNumber) {
        this.state.selectedInfoQuestionTab = tabNumber;
    }

    get listAction(): string {
        return this.state.listAction;
    }

    get selectedInfoQuestionTab(): number {
        return this.state.selectedInfoQuestionTab;
    }

    set report(report) {
        this.state.report = report;
    }

    get report(): any {
        return this.state.report;
    }

    set listAction(listAction) {
        this.state.listAction = listAction;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    set listColumns(listColumns) {
        this.state.listColumns = listColumns;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }

    set goTo(moduleName) {
        this.state.goTo = moduleName;
    }

    get goTo() {
        return this.state.goTo;
    }

    get reportBack(): any {
        return this.state.reportBack;
    }

    set reportBack(coord) {
        this.state.reportBack = coord;
    }

    set listSearchFilters(listSearchFilters) {
        this.state.listSearchFilters = listSearchFilters;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    set search(search) {
        this.listSearchFilters.search = search;
    }

    get search(): string {
        return this.listSearchFilters.search;
    }

    set idList(idList) {
        this.state.idList = idList;
    }

    get idList(): string {
        return this.state.idList;
    }

    set recordType(recordType) {
        this.state.listSearchFilters['recordType'] = recordType;
    }

    set personToDisplayId(personToDisplayId: string) {
        // no observable. This is handled via the person service
        this.state.personToDisplayId = personToDisplayId;
    }

    get personToDisplayId(): string {
        return this.state.personToDisplayId;
    }

    set prbIndex(prbIndex) {
        this.stateChanged.next({
            'newPrbIndex': prbIndex
        });
        this.state.prbIndex = prbIndex;
    }

    get prbIndex(): number {
        return this.state.prbIndex;
    }

    set informationVisibility(visibility) {
        this.state.informationVisibility = visibility;
    }

    get informationVisibility(): boolean {
        return this.state.informationVisibility;
    }

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    get sideBarStatus(): string {
        return (this.state.sideBarStatus && this.state.sideBarStatus !== '') ?
            this.state.sideBarStatus : 'recentActivities';
    }

    set reportType(reportType) {
        this.state.report.type = (reportType) ? reportType : 'personality';
    }

    get reportType(): string {
        return this.state.report.type;
    }

    set reportSubType(reportType) {
        this.state.report.subType = (reportType) ? reportType : null;
    }

    get reportSubType(): string {
        return this.state.report.subType;
    }
}
