// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  margin-bottom: 8px;
}

h5 {
  margin-top: 12px;
  margin-bottom: 10px;
}

#modalTitle {
  padding: 0;
  margin: 0;
}

.panel {
  width: 50%;
  box-shadow: none;
}

.modal-body, .panel {
  background-color: #EEE;
}

#innerTextServer {
  width: 100%;
  border: 1px solid #BBB;
  padding: 10px;
  height: 200px;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/right-matches/components/web/right-matches-pdf-modal/right-matches-pdf-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,WAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACJ","sourcesContent":["h4 {\n    margin-bottom: 8px\n}\n\nh5 {\n    margin-top: 12px;\n    margin-bottom: 10px\n}\n\n#modalTitle {\n    padding:0;\n    margin:0;\n}\n\n.panel {\n    width: 50%;\n    box-shadow: none;\n}\n\n.modal-body, .panel {\n    background-color: #EEE;\n}\n\n#innerTextServer {\n    width: 100%;\n    border: 1px solid #BBB;\n    padding: 10px;\n    height: 200px;\n    overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
