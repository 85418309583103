import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class UserFormInitService {
    accountDataId: string;
    userId: string;
    type: string; // user
    status: string; // new or edit

    getFormInitDataFromRoute(route: ActivatedRoute) {
        this.initVars(route.snapshot.url);

        return {
            accountDataId: this.accountDataId,
            userId: this.userId,
            type: this.type,
            status: this.status
        };
    }

    initVars(url: Array<any>): void {
        this.type = this.getType(url);
        this.status = this.getStatus(url);
        this.accountDataId = this.getAccountDataId(url);
        this.userId = this.getUserId(url);
    }

    getType(url: Array<any>): string {
        return 'user';
    }

    getStatus(url: Array<any>): string {
        return url[4].path;
    }

    getAccountDataId(url: Array<any>): string {
        return url[2].path;
    }

    getUserId(url: Array<any>): string {
        if ('edit' === this.status) {
            return url[5].path;
        }

        return null;
    }
}
