export class ProductsListNames {

    titles: any;

    constructor() {


        this.titles = {
            'MPOFAC': {
                'en': 'MPO Certified Professional Training Program',
                'fr': 'MPO Programme de formation certifiée'
            },
            'MPOFACCONV': {
                'en': 'MPO Certified Professional Training Program (conversion)',
                'fr': 'MPO Programme de formation certifiée (conversion)'
            },
            'DITRAP': {
                'en': 'MPO Communication Report',
                'fr': 'Rapport MPO Communication'
            },
            'DITFOR': {
                'en': 'MPO Personality Training Manual for Participant',
                'fr': 'Manuel de formation MPO Personnalité'
            },
            'DITMPO': {
                'en': 'MPO Personality report Generated from an MPO Communication report',
                'fr': 'Rapport MPO Personnalité à partir d\'un rapport MPO Communication'
            },
            'MPO1': {
                'en': '1 Report (report & feedback from a consultant)',
                'fr': '1 Rapport (rapport et rétroaction d\'un consultant)'
            },
            'MPO10': {
                'en': '10 Credits Packs',
                'fr': '10 crédits'
            },
            'MPO25': {
                'en': '25 Credits Packs',
                'fr': '25 crédits'
            },
            'MPOADD50': {
                'en': 'Business - 50 Additional Credits',
                'fr': 'Business - 50 Crédits additionnels'
            },
            'MPOADD125': {
                'en': 'Business - 125 Additional Credits',
                'fr': 'Business - 125 Crédits additionnels'
            },
            'MPOBEX': {
                'en': 'Business - 1 Year Extension',
                'fr': 'Business - Extension 1 an'
            },
            'MPOFSAINT': {
                'en': 'Yearly Agreement - Introduction Package (includes 10 credits)',
                'fr': 'Entente annuelle d\'introduction (incluant 10 crédits)'
            },
            'MPOFSA50': {
                'en': 'Yearly Agreement - 0 to 50 Employees',
                'fr': 'Entente annuelle - 0 à 50 Employés'
            },
            'MPOFSA150': {
                'en': 'Yearly Agreement – 51 to 150 Employees',
                'fr': 'Entente annuelle - 51 à 150 Employés'
            },
            'MPOFSA250': {
                'en': 'Yearly Agreement – 151 to 250 Employees',
                'fr': 'Entente annuelle - 151 à 250 Employés'
            },
            'MPOFSA500': {
                'en': 'Yearly Agreement – 251 to 500 Employees',
                'fr': 'Entente annuelle - 251 à 500 Employés'
            },
            'MPOFSA1000': {
                'en': 'Yearly Agreement -  500 and + Employees',
                'fr': 'Entente annuelle -  500 et + Employés'
            },
            'MPOPRO25': {
                'en': 'Pack of 25 MPO Credits',
                'fr': 'Banque de 25 crédits MPO'
            },
            'MPOPRO50': {
                'en': 'Pack of 50 MPO Credits',
                'fr': 'Banque de 50 crédits MPO'
            },
            'MPOPRO125': {
                'en': 'Pack of 125 MPO Credits',
                'fr': 'Banque de 125 crédits MPO'
            },
            'MPOPRO250': {
                'en': 'Pack of 250 MPO Credits',
                'fr': 'Banque de 250 crédits MPO'
            },
            'MPOSAT100': {
                'en': 'Pack of 100 MPO Satellite Credits',
                'fr': 'Banque de 100 crédits MPO Satellite'
            },
            'MPOSAT250': {
                'en': 'Pack of 250 MPO Satellite Credits',
                'fr': 'Banque de 250 crédits MPO Satellite'
            },
            'MPOSAT500': {
                'en': 'Pack of 500 MPO Satellite Credits',
                'fr': 'Banque de 500 crédits MPO Satellite'
            },
            'MPOENT150': {
                'en': 'Enterprise - Up to 150 Employees',
                'fr': 'Entreprise - Jusqu\'à 150 employés'
            },
            'MPOE150': {
                'en': 'Enterprise - Up to 150 Employees',
                'fr': 'Entreprise - Jusqu\'à 150 employés'
            },
            'MPOENT250': {
                'en': 'Enterprise - 151 to 250 Employees',
                'fr': 'Entreprise - 151 à 250 employés'
            },
            'MPOE250': {
                'en': 'Enterprise - 151 to 250 Employees',
                'fr': 'Entreprise - 151 à 250 employés'
            },
            'MPOENT500': {
                'en': 'Enterprise - 251 to 500 Employees',
                'fr': 'Entreprise - 251 à 500 employés'
            },
            'MPOE500': {
                'en': 'Enterprise - 251 to 500 Employees',
                'fr': 'Entreprise - 251 à 500 employés'
            },
            'MPOENT1000': {
                'en': 'Enterprise - 501 to 2000 Employees',
                'fr': 'Entreprise - 501 à 2000 employés'
            },
            'MPOE1000': {
                'en': 'Enterprise - 501 to 2000 Employees',
                'fr': 'Entreprise - 501 à 2000 employés'
            },
            'CERTACO1': {
                'en': 'Consultant - Frais de certification initial (15 crédits MPO)',
                'fr': 'Consultant Initial Certification Fee (15 MPO credits)'
            },
            'CERTACO2': {
                'en': 'Certified Analyst MPO for Consultant  Certification (5 MPO credits)',
                'fr': 'Consultant pour Analystes MPO - Frais de certification initial (5 crédits MPO)'
            },
            'CERTPA01': {
                'en': 'Partner Initial Certification Fee (15 MPO credits)',
                'fr': 'Partenaire - Frais de certification initial'
            },
            'CERTAP02': {
                'en': 'Certified Analyst MPO for Partner  Certification (5 MPO credits)',
                'fr': 'Partenaire pour Analyste MPO - Frais de certification initial'
            },
            'CERTAP03': {
                'en': 'Certified MPO Consultant for Partner  Certification (5 MPO credits)',
                'fr': 'Partenaire pour Consultant MPO- Frais de certification initial'
            },
            'FANC01': {
                'en': 'Associates & Consultant Annual Fee',
                'fr': 'Consultant - Frais annuel'
            },
            'MPOC10': {
                'en': 'Consultant 10 Credits Pack',
                'fr': '10 Crédits pour consultant'
            },
            'MPOC25': {
                'en': 'Consultant 25 Credits Pack',
                'fr': '25 Crédits pour consultant'
            },
            'MPOC50': {
                'en': 'Consultant 50 Credits Pack',
                'fr': '50 Crédits pour consultant'
            },
            'MPOC100': {
                'en': 'Consultant 100 Credits Pack',
                'fr': '100 Crédits pour consultant'
            },
            'MPOP10': {
                'en': 'Partner 10 Credits Pack',
                'fr': '10 Crédits pour partenaire'
            },
            'MPOP25': {
                'en': 'Partner 25 Credits Pack',
                'fr': '25 Crédits pour partenaire'
            },
            'MPOP50': {
                'en': 'Partner 50 Credits Pack',
                'fr': '50 Crédits pour partenaire'
            },
            'MPOP100': {
                'en': 'Partner 100 Credits Pack',
                'fr': '100 Crédits pour partenaire'
            },
            'CERTAGCO1': {
                'en': null,
                'fr': 'Consultant - Frais de certification initial par consultant (min. de 3 consultants)'
            },
            'CERTAGCO2': {
                'en': null,
                'fr': 'Consultant - Frais de certification initial à partir du 4ème consultant participant à la même session'
            },
            'FANGC02': {
                'en': null,
                'fr': 'Consultant - Frais annuel pour tout consultant supplémentaire'
            },
            'MPOTRCA': {
                'en': null,
                'fr': 'MPO Programme de formation certifiée'
            },
            'MPOFSA2000': {
                'en': null,
                'fr': 'Entente annuelle - 2001 et plus Employés'
            },
            'MPOPRO625': {
                'en': null,
                'fr': 'Banque de 625 crédits MPO'
            },
            'MPOPRO1250': {
                'en': null,
                'fr': 'Banque de 1250 crédits MPO'
            },
            'MPOPRO2000': {
                'en': null,
                'fr': 'Banque de 2000 crédits MPO'
            },
            'FANGC01': {
                'en': null,
                'fr': 'Consultant - Frais annuel pour un groupe de 3 consultants'
            },
            'MPOC125': {
                'en': null,
                'fr': '125 Crédits pour consultant'
            },
            'MPOC250': {
                'en': null,
                'fr': '250 Crédits pour consultant'
            },
            'MPOC500': {
                'en': null,
                'fr': '500 Crédits pour consultant'
            },
            'CERTPAO1': {
                'en': null,
                'fr': 'Partenaire - Frais de certification initial (75 crédits)'
            },
            'CERTPAO2': {
                'en': null,
                'fr': 'Partenaire pour Analyste MPO - Frais de certification initial (25 crédits)'
            },
            'CERTPAO3': {
                'en': null,
                'fr': 'Partenaire pour Consultant MPO - Frais de certification initial (25 crédits)'
            },
            'FANPA01': {
                'en': null,
                'fr': 'Partenaire - Frais annuel'
            },
            'MPOP125': {
                'en': null,
                'fr': '125 Crédits pour partenaire'
            },
            'MPOP250': {
                'en': null,
                'fr': '250 Crédits pour partenaire'
            },
            'MPOP500': {
                'en': null,
                'fr': '500 Crédits pour partenaire'
            },
            'MPOD50': {
                'en': null,
                'fr': '50 Crédits pour distributeur'
            },
            'MPOD125': {
                'en': null,
                'fr': '125 Crédits pour distributeur'
            },
            'MPOD250': {
                'en': null,
                'fr': '250 Crédits pour distributeur'
            },
            'MPOD500': {
                'en': null,
                'fr': '500 Crédits pour distributeur'
            }
        };
    }
}
