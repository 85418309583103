// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

.searchBar {
  padding-left: 5px;
}

#candidateList {
  height: 408px;
  background-color: white;
  overflow-y: scroll;
  border-top: 1px solid #BBB;
  border-bottom: 1px solid #BBB;
}

.candidateRow {
  border-bottom: 1px solid #DDD;
}

.closeSearch img.closeIdle {
  display: block;
}

.closeSearch img.closeHover {
  display: none;
}

.closeSearch:hover img.closeIdle {
  display: none;
}

.closeSearch:hover img.closeHover {
  display: block;
}

.closeIdle, .closeHover {
  margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/graphboards/components/web/graphboards-person-modal/graphboards-person-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,0BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EAA4B,cAAA;AAE5B;;AADA;EAA4B,aAAA;AAK5B;;AAHA;EAAkC,aAAA;AAOlC;;AANA;EAAmC,cAAA;AAUnC;;AARA;EACI,eAAA;AAWJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n\n.searchBar {\n    padding-left: 5px;\n}\n\n#candidateList {\n    height: 408px;\n    background-color: white;\n    overflow-y: scroll;\n    border-top: 1px solid #BBB;\n    border-bottom: 1px solid #BBB;\n}\n\n.candidateRow {\n    border-bottom: 1px solid #DDD;\n}\n\n.closeSearch img.closeIdle {display:block}\n.closeSearch img.closeHover{display:none}\n\n.closeSearch:hover img.closeIdle {display:none}\n.closeSearch:hover img.closeHover {display:block}\n\n.closeIdle, .closeHover {\n    margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
