// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrows {
  margin-top: 1px;
  cursor: pointer;
  padding-left: 7px;
}

.top {
  background-color: rgb(241, 241, 241);
  top: 0;
}

.tRight {
  padding: 35px 20px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/infopanel/infopanel.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oCAAA;EACA,MAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[".arrows {\n    margin-top: 1px;\n    cursor: pointer;\n    padding-left: 7px;\n}\n\n.top {\n    background-color: rgb(241, 241, 241);\n    top: 0;\n}\n\n.tRight {\n    padding: 35px 20px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
