// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green {
  color: rgb(104, 194, 165);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/local-loader/local-loader.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ","sourcesContent":[".green {\n    color: rgb(104,194,165);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
