import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent implements OnInit {
    @Input() isBlank = false;
    @Input() backgroundColor;
    @Input() textColor;
    @Input() border;
    @Input() isSidebar = true;
    @Input() isRightMatch = false;
    @Input() isJobSatisfaction = false;
    @Input() swapContent = false;
    @Output() buttonActon = new EventEmitter;

    constructor() { }

    ngOnInit() {

    }

    onButtonClick() {
        this.buttonActon.emit();
    }

}
