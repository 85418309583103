// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#candidateList {
  max-height: 250px;
  overflow-y: scroll;
}

.candidateRow {
  overflow: hidden;
}

.recordCheck {
  float: left;
}

.recordLabel {
  float: left;
  line-height: 1.5rem;
  padding-left: 5px;
}

.addingFooter {
  text-align: right;
  border-top: 1px solid #999999;
  padding-top: 10px;
}

.addingFooter button:first-child {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/idw-matrices/components/mobile/mobile-idw-matrix-person-adding/mobile-idw-matrix-person-adding.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,6BAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["#candidateList {\n    max-height: 250px;\n    overflow-y: scroll;\n}\n\n.candidateRow {\n    overflow: hidden;\n}\n\n.recordCheck {\n    float: left;\n}\n\n.recordLabel {\n    float: left;\n    line-height: 1.5rem;\n    padding-left: 5px;\n}\n\n.addingFooter {\n    text-align: right;\n    border-top: 1px solid #999999;\n    padding-top: 10px;\n}\n\n.addingFooter button:first-child {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
