import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PeopleSurveyRequestSendMethodsHelper} from '../send-methods.helper';
import {EnvService} from '../../../../../../app/services/env.service';

@Component({
    selector: 'app-people-survey-request-send-method-copypaste',
    template: `
        <div [class.ar]="instructionsMessages.language == 'ar'" style="background-color: transparent">
        <h3><strong>{{ 'people.requestModal.cutnpasteTitle' | translate }}</strong></h3>
        <br />
        <div [innerHTML]="cutNPasteText" ></div>
        </div>
        <div [class.clearfix]="instructionsMessages.language=='ar'"></div>
    `,
    styles: ['.ar { direction: rtl; float: right}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestSendMethodCopyPasteComponent implements OnInit, OnDestroy {
    @Input() instructionsMessages: any;
    @Input() surveyLink: string;
    @Input() username: string;
    @Input() password: string;
    @Input() formSubmit$: Observable<any>;
    @Output() afterSubmission: EventEmitter<any> = new EventEmitter();

    cutNPasteText: string;

    public onDestroy$: Subject<any> = new Subject();

    constructor(
        private environment: EnvService
    ) {
    }

    ngOnInit() {
        let lang = this.instructionsMessages.language;
        let qLink = this.environment.evaluationFormUrl + '/login?lang=' + lang + '&orgLang=' + lang
            + '&username=' + this.username + '&password=' + this.password;
        this.cutNPasteText = PeopleSurveyRequestSendMethodsHelper.copyPasteText(
            this.instructionsMessages, this.username, this.password, qLink, this.instructionsMessages.language
        );
        this.formSubmit$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.afterSubmission.emit();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
