import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {StateService} from '../../../../core/services/state/state.service';
import {JobService} from '../../../services/job.service';
import {JobReportService} from '../../../services/job-report.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {ComponentPrimitive} from '../../../../shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-jobs-report-mpo',
    templateUrl: './jobs-report-mpo.component.html',
    styleUrls: ['./jobs-report-mpo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsReportMpoComponent extends ComponentPrimitive implements OnInit {

    @Input() archive = false;
    @Input() mobile = false;
    @Output() openModQuickProfiler = new EventEmitter();
    traits: Array<string> = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];
    mpoScores: any = undefined;
    mpoGraphType = 'plain';
    currentLang = 'fr';
    hasSatellite;
    isSatellite: boolean;
    titleSep: string;
    reportDateFormat: string;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private translate: TranslateService,
        private jobReportService: JobReportService,
        private apiJobsService: ApiJobsService,
        public jobService: JobService,
        protected stateService: StateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.hasSatellite = this.stateService.hasSatellite;
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.reportDateFormat = TextHelper.dateFormat(this.translate.currentLang);

        this.checkMpoScores();

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.checkMpoScores();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.jobService.getReport(this.jobService.id);
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.reportDateFormat = TextHelper.dateFormat(res.lang);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    checkMpoScores() {
        this.mpoScores = (this.jobService.score) ? this.jobService.score[0] : undefined;
        if (this.mpoScores) {
            this.mpoGraphType = 'plain';
            this.isSatellite = (this.jobService.score[0] &&
                this.jobService.score[0].satellite &&
                this.jobService.score[0].satellite !== undefined);
        } else {
            this.mpoGraphType = 'inactive';
            this.isSatellite = false;
        }
    }

    onResize() {
    }

    useWithSatellite(event) {
        this.mpoScores.satellite = event.checked;
        this.subscriptions.add(this.apiJobsService.jobProfiler([this.jobService.id, 0], {score: this.mpoScores}, true)
            .subscribe());
    }

    modQuickProfiler() {
        this.openModQuickProfiler.emit();
    }

    /**
     * Getters
     */

    get state(): any {
        return this.stateService.jobs;
    }

    get traitsSummary(): any {
        if (this.jobService.jobReport) {
            return this.jobService.jobReport.traitsSummary;
        }
        return false;
    }

    get predictableBehavior(): any {
        if (this.jobService.jobReport) {
            return this.jobService.jobReport.predictableBehavior;
        }
        return false;
    }

    get profile(): any {
        if (this.jobService.jobReport) {
            return this.jobService.jobReport.profile;
        }
        return false;
    }

    get hasWarning(): boolean {
        return (this.jobService.jobReport && this.jobService.jobReport.warning);
    }
}
