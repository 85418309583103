import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {SharedModule} from '../../shared/shared.module';
import {AnalysisRoutingModule} from '../analysis-routing.module';
import {IdwMatricesModalComponent} from './components/web/idw-matrices-modal/idw-matrices-modal.component';
import {IdwMatrixListComponent} from './components/web/idw-matrix-list/idw-matrix-list.component';
import {IdwMatrixPersonModalComponent} from './components/web/idw-matrix-person-modal/idw-matrix-person-modal.component';
import {IdwMatrixReportComponent} from './components/web/idw-matrix-report/idw-matrix-report.component';
import {IdwMatrixSearchFiltersComponent} from './components/web/idw-matrix-search-filters/idw-matrix-search-filters.component';
import {IdwMatrixSelectedItemsComponent} from './components/web/idw-matrix-selected-items/idw-matrix-selected-items.component';
import {IdwMatrixSidebarComponent} from './components/web/idw-matrix-sidebar/idw-matrix-sidebar.component';
import {IdwMatrixAnalysisService} from './services/idw-matrix-analysis.service';
import {ApiIdwMatricesService} from './services/api-idw-matrices.service';
import {MainListIdwMatricesService} from './services/main-list-idw-matrices.service';
import {NgxLoadingModule} from 'ngx-loading';

import {TranslateModule} from '@ngx-translate/core';
import { IdwMatricesPdfModalComponent } from './components/web/idw-matrices-pdf-modal/idw-matrices-pdf-modal.component';
import { SidebarModule } from 'ng-sidebar';
import { MobileIdwMatrixListComponent } from './components/mobile/mobile-idw-matrix-list/mobile-idw-matrix-list.component';
import { MobileIdwMatricesEditComponent } from './components/mobile/mobile-idw-matrices-edit/mobile-idw-matrices-edit.component';
import { MobileIdwMatrixReportComponent } from './components/mobile/mobile-idw-matrix-report/mobile-idw-matrix-report.component';
import { MobileIdwMatrixPersonAddingComponent } from './components/mobile/mobile-idw-matrix-person-adding/mobile-idw-matrix-person-adding.component';
import { MobileIdwMatricesPdfComponent } from './components/mobile/mobile-idw-matrices-pdf/mobile-idw-matrices-pdf.component';
import {AnalysisSharedModule} from '../shared/analysis-shared.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {PeopleModule} from '../../people/people.module';
import {MatIconModule} from '@angular/material/icon';
import {RightMatchesModule} from '../right-matches/right-matches.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ModalModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgMultiSelectDropDownModule,
        AnalysisRoutingModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxLoadingModule,
        TranslateModule,
        SidebarModule,
        AnalysisSharedModule,
        MatTabsModule,
        MatGridListModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        PeopleModule,
        MatIconModule,
        RightMatchesModule,
        MatExpansionModule,
        MatCheckboxModule
    ],
    declarations: [IdwMatrixListComponent, IdwMatrixReportComponent, IdwMatrixSearchFiltersComponent,
        IdwMatrixSidebarComponent,
        IdwMatricesModalComponent,
        IdwMatrixPersonModalComponent,
        IdwMatrixSelectedItemsComponent,
        IdwMatricesPdfModalComponent,
        MobileIdwMatrixListComponent,
        MobileIdwMatricesEditComponent,
        MobileIdwMatrixReportComponent,
        MobileIdwMatrixPersonAddingComponent,
        MobileIdwMatricesPdfComponent],
    providers: [
        IdwMatrixAnalysisService,
        ApiIdwMatricesService,
        MainListIdwMatricesService
    ],
    exports: [
        IdwMatrixSearchFiltersComponent,
        IdwMatrixSidebarComponent,
        IdwMatrixListComponent
    ]
})
export class IdwMatricesModule {
}
