// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requestInfoSection {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.requestInfoSection .flex {
  display: flex;
  flex-direction: column;
}

.requestInfoSection h4 {
  margin-top: 5px;
}

.requestInfoSection .mat-form-field {
  width: 100%;
}

.autoGenCheck {
  padding-left: 10px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/request-form/mobile/mobile-survey-request-info/mobile-survey-request-info.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;AAER;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,qBAAA;AACJ","sourcesContent":[".requestInfoSection {\n    margin-bottom: 10px;\n    display: flex;\n    flex-direction: column;\n    .flex {\n        display: flex;\n        flex-direction: column;\n    }\n}\n\n.requestInfoSection h4 {\n    margin-top: 5px;\n}\n\n.requestInfoSection .mat-form-field {\n    width: 100%;\n}\n\n.autoGenCheck {\n    padding-left: 10px;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
