// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#backDrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 999999998;
}

#alertFrame {
  position: fixed;
  border: 1px solid #9A9A9A;
  background-color: white;
  padding: 0;
  z-index: 9999999999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  border-radius: 3px;
  box-shadow: 0 2px 2px 1px #DDD;
}

#innerFrame {
  padding: 10px;
}

.icon {
  width: 40px;
}

div.main-container {
  display: table;
}

div.main-container div.title-container {
  display: table-cell;
  vertical-align: middle;
}

h1 {
  font-size: 11pt;
  font-weight: bold;
  margin: 0 0 0 20px;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

.vtop {
  top: 0%;
}

.vcentered {
  top: 50%;
}

.vbottom {
  bottom: 0%;
}

.hleft {
  left: 0%;
}

.hcentered {
  left: 50%;
}

.hright {
  right: 0%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/alert/components/alert.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EAEA,gBAAA;EAEA,kBAAA;AADJ;;AAIA;EACI,eAAA;EACA,yBAAA;EACA,uBAAA;EACA,UAAA;EACA,mBAAA;EAEA,2BAAA;EACA,yBAAA;EAGA,iBAAA;EAEA,eAAA;EAEA,kBAAA;EAEA,8BAAA;AALJ;;AASA;EACI,aAAA;AANJ;;AASA;EACI,WAAA;AANJ;;AASA;EACI,cAAA;AANJ;;AASA;EACI,mBAAA;EACA,sBAAA;AANJ;;AASA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;AANJ;;AASA;EACI,SAAA;EACA,UAAA;AANJ;;AAcA;EACI,OAAA;AAXJ;;AAcA;EACI,QAAA;AAXJ;;AAcA;EACI,UAAA;AAXJ;;AAcA;EACI,QAAA;AAXJ;;AAcA;EACI,SAAA;AAXJ;;AAcA;EACI,SAAA;AAXJ","sourcesContent":["#backDrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n\n    overflow: hidden;\n\n    z-index: 999999998;\n}\n\n#alertFrame {\n    position: fixed;\n    border: 1px solid #9A9A9A;\n    background-color: white;\n    padding: 0;\n    z-index: 9999999999;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n\n    cursor: default;\n\n    border-radius: 3px;\n\n    box-shadow: 0 2px 2px 1px #DDD;\n\n}\n\n#innerFrame {\n    padding: 10px;\n }\n\n.icon {\n    width: 40px;\n}\n\ndiv.main-container {\n    display: table;\n}\n\ndiv.main-container div.title-container {\n    display: table-cell;\n    vertical-align: middle;\n}\n\nh1 {\n    font-size: 11pt;\n    font-weight: bold;\n    margin: 0 0 0 20px;\n    padding: 0;\n}\n\nh2 {\n    margin: 0;\n    padding: 0;\n\n}\n\np {\n\n}\n\n.vtop {\n    top: 0%;\n}\n\n.vcentered {\n    top: 50%;\n}\n\n.vbottom {\n    bottom: 0%;\n}\n\n.hleft {\n    left: 0%;\n}\n\n.hcentered {\n    left: 50%;\n}\n\n.hright {\n    right: 0%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
