import {ApplicationRef, Injectable} from '@angular/core';
import {ContentList} from '../../shared/classes/services/content_list';
import {List} from '../interfaces';
import {PersonService} from './person.service';
import {UserService} from '../../core/services/user/user.service';
import {CanonicalizeHelper} from '../../shared/helpers/canonicalize.helper';
import {ApiPeopleService} from './api-people.service';
import {ApiJobsService} from '../../jobs/services/api-jobs.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../core/services/state/state.service';
import {AlertService} from '../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {TestAndReportHandlingService} from './test-and-report-handling.service';
import {PeopleStateColumns} from '../../core/interfaces/state';
import {UserPreferencesService} from '../../administration/services/user-preferences.service';

@Injectable({
    providedIn: 'root'
})
export class PeopleList extends ContentList {
    public _opened;
    public switchOff: boolean;
    public clickMode = false;

    public listColumns;

    public loading = false;
    public url = 'people/list';
    public archive;
    public toList: List = null;
    public totalItems = 0;
    public recordsLoaded = 0;

    public jobsList;
    reportTypes = ['personality', 'communication', 'satellite', 'talents'];

    readonly columns = ['firstName', 'lastName', 'creationDate', 'jobTitle', 'organisation', 'subAccountName',
        'portraitName', 'status', 'statusPrb', 'recordType', 'departmentName'];
    readonly columnsName = {
        fr: {
            firstName: 'Prénom',
            lastName: 'Nom',
            creationDate: 'Date',
            jobTitle: 'Titre',
            organisation: 'Organisation',
            subAccountName: 'Sous-compte',
            'portraitName': 'Portrait',
            'status': 'MPO',
            'statusPrb': 'PCR',
            'recordType': 'Type',
            departmentName: 'Département'
        },
        en: {
            firstName: 'First Name',
            lastName: 'Last Name',
            creationDate: 'Date',
            jobTitle: 'Title',
            organisation: 'Organization',
            subAccountName: 'Sub-account',
            'portraitName': 'Portrait',
            'status': 'MPO',
            'statusPrb': 'PRB',
            'recordType': 'Type',
            departmentName: 'Department'
        }
    };

    public listColumnsInitialConfig: Array<PeopleStateColumns> = [
        {key: 'firstName', display: true},
        {key: 'lastName', display: true},
        {key: 'creationDate', display: true},
        {key: 'status', display: true},
        {key: 'statusPrb', display: true}
    ];

    constructor(
        public stateService: StateService,
        protected applicationRef: ApplicationRef,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        public user: UserService,
        public person: PersonService,
        protected translate: TranslateService,
        protected apiPeople: ApiPeopleService,
        protected apiJobs: ApiJobsService,
        protected alertService: AlertService,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        private userPreferencesService: UserPreferencesService
    ) {
        super(stateService, router, applicationRef, deviceService);
        this.setIacPermissions();
        this.checkUserPeopleDisplayPreferences();
        this.setColumnWithPermissions(this.stateService.session.sessionData.structure.testAccessPermissions, this.stateService.session.hasSatelliteRole);

    }

    /// METHODS ///////////////////////////////////////////////////////////////////////////////////////////////////////

    resetList() {
        this.toList = null;
    }

    setInitialColumnsList() {
        this.listColumnsInitialConfig = [
            {key: 'firstName', display: true},
            {key: 'lastName', display: true},
            {key: 'creationDate', display: true},
            {key: 'status', display: true},
            {key: 'statusPrb', display: true}
        ];
        if (this.sessionData.structure.testAccessPermissions.ra) {
            this.listColumnsInitialConfig.push({key: 'statusIac', display: true});
        }
    }

    setColumnWithPermissions(permissions, hasSatelliteRole) {
        if (hasSatelliteRole) {
            let index = this.columns.indexOf('portraitName');
            if (index > -1) {
                this.columns.splice(index, 1);
            }
            this.columnsName['fr']['portraitName'] = undefined;
            this.columnsName['en']['portraitName'] = undefined;
        }
        if (!hasSatelliteRole) {
            if (permissions.mpo && this.columns.indexOf('reportStatuses.mpo') === -1) {
                this.columns.push('reportStatuses.mpo');
                this.columnsName['fr']['reportStatuses.mpo'] = 'Personnalité';
                this.columnsName['en']['reportStatuses.mpo'] = 'Personality';
            }
            if (permissions.dit && this.columns.indexOf('reportStatuses.dit') === -1) {
                this.columns.push('reportStatuses.dit');
                this.columnsName['fr']['reportStatuses.dit'] = 'Communication';
                this.columnsName['en']['reportStatuses.dit'] = 'Communication';
            }
            if (permissions.talents && this.columns.indexOf('reportStatuses.talents') === -1) {
                this.columns.push('reportStatuses.talents');
                this.columnsName['fr']['reportStatuses.talents'] = 'Talent';
                this.columnsName['en']['reportStatuses.talents'] = 'Talent';
            }
            this.setIacPermissions();
        }
        if (permissions.satellite && this.columns.indexOf('reportStatuses.satellite') === -1) {
            this.columns.push('reportStatuses.satellite');
            this.columnsName['fr']['reportStatuses.satellite'] = 'Satellite';
            this.columnsName['en']['reportStatuses.satellite'] = 'Satellite';

        }
    }

    setIacPermissions() {
        if (this.sessionData.structure.testAccessPermissions.ra && this.columns.indexOf('reportStatuses.ra') === -1) {
            this.listColumnsInitialConfig.push({key: 'statusIac', display: true});
            this.columns.push('statusIac');
            let statusIacIndex = this.state.listColumns.findIndex(col => col.key === 'statusIac');
            if (statusIacIndex === -1) {
                this.state.listColumns.push({key: 'statusIac', display: true});
            }
            this.columnsName['fr']['statusIac'] = 'IAC';
            this.columnsName['en']['statusIac'] = 'CAI';

            this.columns.push('reportStatuses.ra');
            this.columnsName['fr']['reportStatuses.ra'] = 'Rapport IAC';
            this.columnsName['en']['reportStatuses.ra'] = 'CAI Report';
        }
    }

    goToPrec() {
        this.switchOffFilterPanelIndicator(true);

        if (!this.person.id) {
            this.revertToNews();
        }
    }

    checkUserPeopleDisplayPreferences() {
        let listColumn = this.listColumnsInitialConfig;
        let display = _.get(this.userData, 'preferences.people.display');
        if (display) {
            for (let k in display) {
                if (display.hasOwnProperty(k)) {
                    let colIndex = listColumn.findIndex(col => col.key === k);
                    if (colIndex > -1) {
                        listColumn[colIndex].display = display[k];
                    } else {
                        listColumn.push({key: this.userPreferencesService.plusToDot(k), display: display[k]});
                    }
                }
            }
        }
        this.listColumns = JSON.parse(JSON.stringify(listColumn));
    }

    checkArchiveSettings() {
        let prefix = (this.isMobile) ? 'm/' : '';
        if (this.actualModule.archive) {
            this.url = prefix + 'archives/list/people';
        }
    }

    checkJobList() {
        let jobListObservable = this.apiJobs.jobs([{'fields': 'id'}]).subscribe(
            (checkJobs) => {
                this.jobsList = checkJobs.data;
                jobListObservable.unsubscribe();
                this.applicationRef.tick();
            }
        );
    }

    testAccessPermissions() {
        if (_.get(this.sessionData, 'structure.testAccessPermissions')) {
            this.setColumnWithPermissions(this.sessionData.structure.testAccessPermissions, this.hasSatelliteRole);
        }
    }

    /**
     * the received list contains ONLY metadata. Report's
     * data is obtained via the GET /api/record/{recordId} route or the `record` method
     * obtained via the api-get-people.service service.
     *
     * @param params
     * @param recordsLoadedArg
     * @param total
     * @param hasSatelliteRole
     * @param append
     * @param archive
     * @param mobile
     */

    getListDuo(search = '', recordsLoadedArg = 0, total = 0, append = false) {
        let step = 100;
        let amountLoaded = (recordsLoadedArg + step > total && append) ? total - recordsLoadedArg : step;
        let recordsLoaded = append ? recordsLoadedArg : 0;

        let request = {
            cards: 0,
            limit: amountLoaded,
            skip: recordsLoaded,
            isArchived: 0,
            lang: this.translate.currentLang,
            order: {lastNameCanonical: 'asc'},
            reportStatuses: {mpo: 1},
            testStatuses: {mpo: ['C']},
            search: search
        };

        let listObservable = this.apiPeople.records([request]);

        /**
         * IMPORTANT: Cette méthode retourne (entre autre) un observable auquel il faut s'abonner (subscribe) pour obtenir les données.
         * Voir comment la méthode getList() ci-dessous est utilisée pour un exemple.
         */

        return {
            listObservable: listObservable,
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }

    getList(params, recordsLoadedArg, total, hasSatelliteRole = false, append = false, archive = false, mobile = false) {
        let step = 100;
        let amountLoaded = (recordsLoadedArg + step > total && append) ? total - recordsLoadedArg : step;
        let recordsLoaded = append ? recordsLoadedArg : 0;

        let request: any = {
            limit: amountLoaded,
            skip: recordsLoaded
        };

        let parameters = [
            'search',
            'evaluationStartDate',
            'evaluationEndDate',
            'recordType',
            'reportStatuses',
            'testStatuses',
            'subAccount',
            'department'
        ];

        for (let parameter of parameters) {
            if (params.listSearchFilters[parameter]) {
                if (parameter === 'reportStatuses') {
                    for (let report in params.listSearchFilters['reportStatuses']) {
                        if (params.listSearchFilters['reportStatuses'].hasOwnProperty(report)) {
                            if (params.listSearchFilters[parameter][report] === true) {
                                params.listSearchFilters[parameter][report] = 1;
                            } else if (params.listSearchFilters[parameter][report] === false) {
                                delete params.listSearchFilters[parameter][report];
                            }
                        }
                    }
                }
                request[parameter] = params.listSearchFilters[parameter];
            }
        }

        if (request.search && request.search.includes('idList=')) {
            request.idList = request.search.split('=')[1];
            delete request.search;
        }

        // subAccount handling
        if (request['subAccount'] && request['subAccount'].length > 0) {
            request['subAccount'] = request['subAccount'].toString();
        } else {
            delete request['subAccount'];
        }

        if (archive) {
            request['isArchived'] = 1;
        } else {
            request['isArchived'] = 0;
        }


        if (request.subAccount) {
            request.subAccount = request.subAccount.split(',');
        }

        // Build order parameter
        for (let index in _.uniqBy(params.listColumns, 'key')) {
            // noinspection JSUnfilteredForInLoop
            if (params.listColumns[index] && params.listColumns[index]['order']) {
                let orderObject = {};
                // noinspection JSUnfilteredForInLoop
                if (params.listColumns.hasOwnProperty(index)) {
                    let colKey: string;
                    switch (params.listColumns[index]['key']) {
                        case 'subAccountName':
                            colKey = 'subAccount';
                            break;
                        case 'portraitName':
                            colKey = 'portrait';
                            break;
                        case 'departmentName':
                            colKey = 'department';
                            break;
                        default:
                            colKey = params.listColumns[index]['key'];
                    }
                    orderObject[colKey] = params.listColumns[index]['order'];
                    request['order'] = orderObject;
                }
            }
        }

        request['cards'] = (this.stateService.people.listView === 'cards') ? 1 : 0;

        if (typeof request.department === 'string') {
            request.department = request.department.split(',');
        }

        request = CanonicalizeHelper.addSuffix('people', request);
        let listObservable = this.apiPeople.records([request]);

        return {
            listObservable: listObservable,
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }

    getRecordList(unsubscribe = true, callback = null) {
        let list = this.getList(this.stateService[this.actualModule.name], 0,
            null, false, false, this.actualModule.archive);
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                if (list && list.data) {
                    let listConverted = this.convertSubFields(list, this.sessionData);
                    this.toList = {
                        count: listConverted['count'],
                        data: []
                    };

                    // List creation
                    for (let item of listConverted.data) {
                        this.toList.data.push(item);
                    }
                }

                if (list && list.id) {
                    let listConverted = this.checkStatuses(list);
                    this.toList = {
                        count: 1,
                        data: [listConverted]
                    };
                    this.state.search = list.lastName + ' ' + list.firstName;
                }

                if (unsubscribe) {
                    listObservableSubscription.unsubscribe();
                }

                if (callback) {
                    this.alertService.setConfig(callback);
                }
                this.loading = false;
                this.stateService.people.stateChanged.next({toList: this.toList});
            });
    }

    checkStatuses(list) {
        let testStatuses = {
            mpo: 'N',
            prb: 'N',
            ra: 'N'
        };
        let reportStatuses = {
            mpo: false,
            prb: false,
            dit: false,
            talents: false,
            satellite: false,
            ra: undefined
        };

        let mpoEvaluation = _.get(list, 'evaluations.mpo');
        let mpoEvaluationInfo = _.get(mpoEvaluation, 'info');
        if (mpoEvaluation) {
            if (mpoEvaluationInfo) {
                if (_.get(mpoEvaluation, 'info.status')) {
                    testStatuses.mpo = 'C';
                    reportStatuses.mpo = true;
                } else {
                    testStatuses.mpo = 'H';
                }
            }
        }

        let mpoTypeEvaluation = _.get(mpoEvaluationInfo, 'type');
        if (typeof +mpoTypeEvaluation === 'number') {
            list.portrait = mpoTypeEvaluation;
        }
        let portrait =
            this.testAndReportHandlingService.renderPortrait(mpoTypeEvaluation);
        list.portraitName = this.translate.instant(portrait);

        let prbEvaluation = _.get(list, 'evaluations.prb');
        if (prbEvaluation) {
            if (_.get(prbEvaluation, 'info')) {
                if (_.get(prbEvaluation, 'info.size')) {
                    testStatuses.prb = 'C';
                    reportStatuses.prb = true;
                } else {
                    testStatuses.prb = 'H';
                }
            }
        }

        let ditEvaluation = _.get(list, 'evaluations.dit');
        if (ditEvaluation) {
            if (_.get(ditEvaluation, 'info')) {
                if (_.get(ditEvaluation, 'info.status')) {
                    testStatuses.mpo = 'C';
                    reportStatuses.dit = true;
                }
            }
        }

        let satEvaluation = _.get(list, 'evaluations.satellite');
        if (satEvaluation) {
            if (_.get(satEvaluation, 'info')) {
                if (_.get(satEvaluation, 'info.status')) {
                    testStatuses.mpo = 'C';
                    reportStatuses.satellite = true;
                }
            }
        }

        let talentsEvaluation = _.get(list, 'evaluations.talents');
        if (talentsEvaluation) {
            if (_.get(talentsEvaluation, 'info')) {
                if (_.get(talentsEvaluation, 'info.status')) {
                    testStatuses.mpo = 'C';
                    reportStatuses.talents = true;
                }
            }
        }

        let iacEvaluation = _.get(list, 'evaluations.iac');
        if (iacEvaluation) {
            if (_.get(iacEvaluation, 'info')) {
                if (_.get(iacEvaluation, 'info.status')) {
                    testStatuses.ra = 'C';
                    reportStatuses.ra = true;
                } else {
                    testStatuses.ra = 'H';
                }
            }
        }

        if (_.get(list, 'evaluations.requests')) {
            if (list.evaluations.requests[0]) {
                if (list.evaluations.requests[0].type &&
                    list.evaluations.requests[0].type.includes('mpo')) {
                    testStatuses.mpo = 'NC';
                    if (list.evaluations.requests[0].variant === '1,1') {
                        testStatuses.prb = 'NC';
                    }
                }
                if (list.evaluations.requests[0].type &&
                    list.evaluations.requests[0].type.includes('ra')) {
                    testStatuses.ra = 'NC';
                }
            }
        }
        list.testStatuses = testStatuses;
        list.reportStatuses = reportStatuses;

        if (list.creationDate) {
            list.creationDate = list.creationDate.split('T')[0];
        }

        if (list.recordType === 'employee') {
            list.recordType = this.translate.instant('commons.employee');
        } else if (list.recordType === 'candidate') {
            list.recordType = this.translate.instant('people.commons.candidate');
        } else {
            list.recordType = '';
        }

        if (Array.isArray(list.department) && list.department.length) {
            list.departmentName = list.department[0][this.translate.currentLang];
        }

        if (list.subAccount !== null && typeof list.subAccount === 'number') {
            let subAccounts = _.get(this.sessionData, 'accountData.subAccounts');
            if (subAccounts && subAccounts[list.subAccount]) {
                list.subAccountName = subAccounts[list.subAccount].name;
            }
        }

        return list;
    }

    /**
     * Will use sessionData to transform the subAccount number into subAccount's name
     *
     * @param list
     * @param sessionData
     * @returns any
     */
    convertSubFields(list, sessionData) {
        // tslint:disable-next-line:forin
        for (let record in list['data']) {
            if (list['data'].hasOwnProperty(record)) {

                if (list.data[record]['recordType']) {

                    if (list.data[record]['recordType'] === 'employee') {
                        list.data[record]['recordTypeTxt'] = this.translate.instant('commons.employee');
                    }
                    if (list.data[record]['recordType'] === 'candidate') {
                        list.data[record]['recordTypeTxt'] = this.translate.instant('people.commons.candidate');
                    }
                }

                // STATUS ////////////////////////////////////////////////////////

                list.data[record].statusTxt = {
                    IAC: this.translate.instant('commons.none'),
                    MPO: this.translate.instant('commons.none')
                };
                list.data[record].statusCode = {
                    IAC: 'N',
                    MPO: 'N'
                };

                // IAC ////////////////////////////

                // HAS IAC SCORED?
                if (this.recordHasAnIac(list.data[record])) {
                    // IAC ACTIVE ?
                    if (list.data[record].evaluations.iac.info.status) {
                        list.data[record]['statusTxt']['IAC'] = this.translate.instant('commons.completed');
                        list.data[record]['statusCode']['IAC'] = 'C';
                        // INACTIVE => ON HOLD
                    } else {
                        list.data[record]['statusTxt']['IAC'] = this.translate.instant('commons.onHold');
                        list.data[record]['statusCode']['IAC'] = 'H';
                    }
                    // NO IAC SCORED. HAS REQUEST PENDING?
                } else {
                    if (this.noRequest(list.data[record], 'IAC')) {
                        list.data[record]['statusTxt']['IAC'] = this.translate.instant('commons.none');
                        list.data[record]['statusCode']['IAC'] = 'N';
                    } else {
                        list.data[record]['statusTxt']['IAC'] = this.translate.instant('people.commons.notCompleted');
                        list.data[record]['statusCode']['IAC'] = 'NC';
                    }
                }

                // MPO ////////////////////////////
                // const thisOne = (list.data[record].id === '');
                // HAS MPO SCORED?
                if (this.recordHasAnEvaluation(list.data[record], 'general')) {
                    // if (thisOne) { console.log('scored'); }
                    // MPO ACTIVE ? (requires at least one report activated)
                    if (this.recordHasAnEvaluation(list.data[record], 'active')) {
                        // if (thisOne) { console.log('Active', this.noRequest(list.data[record], 'PRB')); }
                        // ACTIVE. Is there an PRB Standby?
                        if (this.noRequest(list.data[record], 'PRB')) {
                            list.data[record]['statusTxt']['MPO'] = this.translate.instant('commons.completed');
                            list.data[record]['statusCode']['MPO'] = 'C';
                            // PRB SENT & !ACTIVE => Not Completed
                        } else {
                            list.data[record]['statusTxt']['MPO'] = this.translate.instant('people.commons.notCompleted');
                            list.data[record]['statusCode']['MPO'] = 'NC';
                        }
                        // MPO PRESENT BUT INACTIVE => ON HOLD
                    } else {
                        list.data[record]['statusTxt']['MPO'] = this.translate.instant('commons.onHold');
                        list.data[record]['statusCode']['MPO'] = 'H';
                    }
                    // NO MPO SCORED. HAS REQUEST PENDING?
                } else {
                    if (this.noRequest(list.data[record], 'MPO')) {
                        list.data[record]['statusTxt']['MPO'] = this.translate.instant('commons.none');
                        list.data[record]['statusCode']['MPO'] = 'N';
                    } else {
                        list.data[record]['statusTxt']['MPO'] = this.translate.instant('people.commons.notCompleted');
                        list.data[record]['statusCode']['MPO'] = 'NC';
                    }
                }

                if (list.data[record]['evaluations'] &&
                    list.data[record]['evaluations']['mpo'] &&
                    list.data[record]['evaluations']['mpo']['info'] &&
                    list.data[record]['evaluations']['mpo']['info']['type']
                ) {
                    if (typeof +list.data[record]['evaluations']['mpo']['info']['type'] === 'number') {
                        list.data[record]['evaluations']['mpo']['info']['typeNumber']
                            = list.data[record]['evaluations']['mpo']['info']['type'];
                    }
                    list.data[record]['evaluations']['mpo']['info']['type'] =
                        this.testAndReportHandlingService.renderPortrait(list.data[record]['evaluations']['mpo']['info']['typeNumber']);
                }
            }
        }
        return list;
    }

    noRequest(record, type): boolean {
        if (type !== 'MPO' && type !== 'IAC' && type !== 'any' && type !== 'PRB') {
            throw 'Invalid type given. Unable to analyse request. Type must be in {\'MPO\', \'IAC\'}';
        }
        if (record.evaluations) {
            if ((!record.evaluations.requests || record.evaluations.requests.length === 0) && type === 'any') {
                // console.log('a');
                return true;
            } else if (
                (type === 'MPO' || type === 'IAC' || type === 'PRB') &&
                (typeof record.evaluations.requests !== 'undefined')
            ) {
                // console.log('b');
                for (let i in record.evaluations.requests) {
                    if (record.evaluations.requests.hasOwnProperty(i)) {
                        let request = record.evaluations.requests[i];
                        if ((request.type.includes('ra') && type === 'IAC')) {
                            return false;
                        }
                        if (type === 'MPO' || type === 'PRB') {
                            if (type === 'MPO' && (
                                request.type.includes('mpo') ||
                                request.type.includes('dit') ||
                                request.type.includes('talents') ||
                                request.type.includes('satellite')
                            )) {
                                if (request.variant !== '0,1') {
                                    return false;
                                }
                            } else if (type === 'PRB' && request.type.includes('mpo') && request.variant === '0,1') {
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        }
    }

    recordHasAnIac(record) {
        return !!(record.evaluations && record.evaluations.iac && (record.evaluations.iac.scores || this.user.isSender));
    }

    recordHasAnEvaluation(record, generalOrActive) {
        if (generalOrActive !== 'general' && generalOrActive !== 'active') {
            throw 'Invalid generalOrActive type in recordHasAnEvaluation()';
        }
        const types = ['mpo', 'dit', 'satellite', 'talents'];
        if (generalOrActive === 'general') {
            for (let type of types) {
                if (!!(record.evaluations && record.evaluations[type])) {
                    return true;
                }
            }
        } else if (generalOrActive === 'active') {
            for (let type of types) {
                if (!!(
                    record.evaluations &&
                    record.evaluations[type] &&
                    record.evaluations[type].info &&
                    record.evaluations[type].info.status
                )) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Update list on scroll
     */
    listScrolled(): void {
        if (this.toList['count'] && this.recordsLoaded >= this.toList['count']) {
            return;
        }
        let list = this.getList(this.stateService[this.actualModule.name], this.recordsLoaded,
            this.toList['count'], this.hasSatelliteRole, true, this.actualModule.archive);
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        let listObservableSubscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList['data'] =
                    [...this.toList['data'], ...list['data']];
                this.loading = false;
                listObservableSubscription.unsubscribe();
                this.applicationRef.tick();
            }
        );
    }

    openPeopleSidebar() {
        this._opened = true;
    }

    // ToDo extract to a service ou Util class: import * as util from 'path/to/util.ts' -> util.expand() ...
    expand(str, val = {}) {
        return str.split('.').reduceRight((acc, currentValue) => {
            return {[currentValue]: acc};
        }, val);
    }

    dateFormat() {
        if (this.translate.currentLang === 'fr') {
            return 'dd/mm/yyyy';
        }
        return 'mm/dd/yyyy';
    }

    revertToNews() {
        this.statePeople.sideBarStatus = 'recentActivities';
        this.statePeople.stateChanged.next({sideBarStatus: 'recentActivities'});
    }

    // TODO: remove if not used - refactoring
    //      seems like replaced by satelliteJobChange()
    /**
    profilerChange(profilerId): void {
        let satelliteObservableSubscription = this.apiPeople.editSatellite(
            [this.person.id],
            {
                satelliteJobId: profilerId
            }
        ).pipe(take(1)).subscribe(
            () => {
                // this.editRefresh('satellite');
                let recordObservableSubscription = this.apiPeople.record([this.person.id]).pipe(take(1)).subscribe(
                    // tslint:disable-next-line:no-shadowed-variable
                    res => {
                        if (res) {
                            this.person.personToDisplay(res);
                            for (let i in this.toList['data']) {
                                if (this.toList['data'].hasOwnProperty(i)) {
                                    if (this.toList['data'][i].id === this.person.id) {
                                        this.toList['data'][i] = res;
                                    }
                                }
                            }
                            recordObservableSubscription.unsubscribe();
                            this.applicationRef.tick();
                        }
                    }
                );
                satelliteObservableSubscription.unsubscribe();
            }
        );
    }*/

    /**
     * Activate report on modal's event
     * @param activationEvent
     */
    reportActivation(activationEvent): void {
        // need to trigger a little confirmation window here.
        let list = this.getList(this.stateService[this.actualModule.name], 0,
            null, this.stateService.session.hasSatelliteRole, false, this.actualModule.archive);
        this.recordsLoaded = list.recordsLoaded;
        list.listObservable.pipe(take(1)).subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList = this.convertSubFields(list, this.stateService.session.sessionData);
                this.setPersonId();
                this.state.report = {
                    type: activationEvent.reportType,
                    subType: null
                };
                this.stateService.people.stateChanged.next({'personId': this.person.id});
                this.applicationRef.tick();
            }
        );
    }

    revertStateToReports() {
        if (this.sideBarStatus === 'clickMode' || this.sideBarStatus === 'recentActivities') {
            this.sideBarStatus = 'reports';
        }
    }

    refreshPage() {
        this.getRecordList();
    }

    /**
     * Set selected person on event from shared/main-list/component/main-list
     * @param item
     */
    personSelected(item): void {
        let reportType = this.currentReportType(item[1]);
        this.state.report = {
            type: reportType,
            subType: null
        };

        if (!this.isMobile) {
            // open sidebar
            this._opened = true;

            if (!this.state.listAction) {
                this.state.listAction = '';
            }

            this.person.setPersonToDisplayWithId(item[0].id, 'resetSidebarToReport', this.actualModule.name);

        } else {
            this.person.setPersonToDisplayWithId(item[0].id, 'mobileView', this.actualModule.name, reportType);
        }
    }

    currentReportType(type): string {
        let reportType;
        if (type === 'null') {
            reportType = 'personality';
        } else {
            if (type === 'current') {
                reportType = this.state.reportType;
            } else {
                reportType = type;
            }
        }

        return reportType;
    }

    selectType(type) {
        this.stateService.people.recordType = (type === 'all') ? '' : type;
        this.getRecordList(true);
        this.cleanMultiselectMode(true);
        this.revertToNews();
    }

    setPersonId(id = this.person.id) {
        if (id) {
            let action = 'resetSidebarToReport';
            if (this.statePeople.sideBarStatus === 'searchFilters') {
                action = 'resetSidebarToFilters';
            }
            this.person.setPersonToDisplayWithId(id, action, this.actualModule.name);
        }
    }

    get specificState() {
        return (!this.actualModule.archive) ? 'people' : 'archivesPeople';
    }

    get statePeople() {
        return this.stateService[this.specificState];
    }

    get userPreferences() {
        return (this.sessionData && this.sessionData.userData) ? this.sessionData.userData.preferences : null;
    }

    get hasJobAccess() {
        return !!(this.stateService.hasPersonality || this.stateService.hasTalents || this.stateService.hasIac);
    }

    get totalListItems() {
        return (this.toList && this.toList.count !== this.totalItems) ? this.toList.count : null;
    }

    get resMention() {
        return (this.totalListItems > 1) ? 'commons.results' : 'commons.result';
    }

    get tableDefinition() {
        let listColumnsNonTrivial = _.compact(_.uniqBy(this.listColumns, 'key'));

        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumns;

        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            tableDefinition[index] = col;
        });
        const statusMpoIndex = tableDefinition.findIndex(col => (col.key === 'status' && col.display === true));
        const statusPrbIndex = tableDefinition.findIndex(col => (col.key === 'statusPrb' && col.display === true));
        const statusIacIndex = tableDefinition.findIndex(col => (col.key === 'statusIac' && col.display === true));
        const statusIacObj = tableDefinition.filter(col => (col.key === 'statusIac' && col.display === true));
        if (statusIacIndex > -1) {
            let newStatusIacIndex;
            // check if statusIac is part of the group of status & statusPrb
            if (statusPrbIndex > -1) {
                if ((statusIacIndex - statusPrbIndex) > 1) {
                    // remove statusIac with incorrect index from the tableDefinition
                    tableDefinition.splice(statusIacIndex, 1);
                    newStatusIacIndex = statusPrbIndex + 1;
                    // add statusIac after statusPrb
                    tableDefinition.splice(newStatusIacIndex, 0, statusIacObj[0]);
                }
            } else {
                // no PRB case
                if (statusMpoIndex > -1 && (statusIacIndex - statusMpoIndex) > 1) {
                    // remove statusIac with incorrect index from the tableDefinition
                    tableDefinition.splice(statusIacIndex, 1);
                    newStatusIacIndex = statusMpoIndex + 1;
                    // add statusIac after status
                    tableDefinition.splice(newStatusIacIndex, 0, statusIacObj[0]);
                }
            }
        }
        return tableDefinition;
    }

    get typeSelected() {
        let recordTypeFilter = this.state.state.listSearchFilters.recordType;
        switch (recordTypeFilter) {
            case 'employee':
                return 'employee';
            case 'candidate':
                return 'candidate';
            default:
                return 'all';
        }
    }

    // TODO: Probably not needed - refactoring
    /**
    get hiddenColumns(): any {
        return this.hiddenColumns;
    }

    set hiddenColumns(key) {
        this.hiddenColumns = key;
    }*/
}
