import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { PeopleSurveyRequestSendMethodsHelper } from './send-methods.helper';
import { PeopleSurveyRequestFormService} from '../../../../services/people-survey-request-form.service';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../../../../app/services/env.service';

@Component({
    selector: 'app-people-survey-request-send-methods',
    templateUrl: './send-methods.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestSendMethodsComponent implements OnInit, OnDestroy {
    @Input() sendMethod: string;
    @Input() tempUser: any;
    @Output() closeSendMethods: EventEmitter<any> = new EventEmitter();
    @Output() sendAnotherRequest: EventEmitter<any> = new EventEmitter();

    public onDestroy$: Subject<any> = new Subject();
    public instructionsMessages: any;
    public surveyLink: string;
    public decodedSurveyLink: string;
    public buttonText: string;
    public formSubmit$: Subject<any> = new Subject();
    @Input() public sendAnotherSurvey = false;
    public extraData$: Observable<any>;

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private http: HttpClient,
        private environment: EnvService
    ) {}

    ngOnInit() {
        this.getInstructionsMessages();
        this.http.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/sendingStats?' + this.sendMethod + '=1').subscribe();

        this.buttonText = PeopleSurveyRequestSendMethodsHelper.getButtonTranslation(this.sendMethod, this.sendAnotherSurvey);

        this.surveyLink = PeopleSurveyRequestSendMethodsHelper.getSurveyLink(
            this.tempUser.country, this.tempUser.language, this.tempUser.username, this.tempUser.tmpPwd, this.tempUser.isSatelliteOnly
        );
        this.decodedSurveyLink = decodeURIComponent(this.surveyLink);
    }

    ngOnDestroy() {
        this.formSubmit$.complete();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public toggleSendAnotherSurvey(): void {
        this.sendAnotherSurvey = !this.sendAnotherSurvey;
        this.buttonText = PeopleSurveyRequestSendMethodsHelper.getButtonTranslation(this.sendMethod, this.sendAnotherSurvey);
    }

    public submit(): void {
        this.formSubmit$.next();
    }

    public cancelRequest() {
        this.closeSendMethods.emit({submitted: true});
    }

    public afterSubmission(event): void {
        if (this.sendAnotherSurvey) {
            this.sendAnotherRequest.emit();
        } else {
            this.closeSendMethods.emit(event);
        }
    }

    private getInstructionsMessages(): void {
        this.peopleRequestFormService.extraData$.pipe(
            takeUntil(this.onDestroy$), distinctUntilChanged(isEqual)
        ).subscribe((requesterData: any) => {
            this.instructionsMessages = PeopleSurveyRequestSendMethodsHelper.getInstructionsMessages(this.tempUser.language, this.tempUser.variant, {extraData: requesterData});
        });
    }
}
