import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from '../../../shared/misc/text.helper';
import {JobService} from '../../services/job.service';

@Component({
  selector: 'app-jobs-report-iac',
  templateUrl: './jobs-report-iac.component.html',
  styleUrls: ['./jobs-report-iac.component.scss']
})
export class JobsReportIacComponent implements OnInit {

  @Input() currentLang = 'fr';
  @Output() openModRa = new EventEmitter();
  titleSep: string;
  dateFormat: string;

  constructor(
      public jobService: JobService,
      private translate: TranslateService
  ) {
  }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.jobService.getReport(this.jobService.id);
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.dateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );
    }

  get raTexts() {
    let txt;
      if (this.jobService.jobReport && this.jobService.jobReport.RAtexts) {
          txt = this.jobService.jobReport.RAtexts;
      }
    return txt;
  }

  modRa() {
      this.openModRa.emit();
  }

}
