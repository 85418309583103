import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {ArchivesRoutingModule} from "./archives-routing.module";

import {PeopleModule} from "../people/people.module";
import {JobsModule} from "../jobs/jobs.module";
import {AnalysisModule} from "../analysis/analysis.module";
import {SharedModule} from "../shared/shared.module";
import {ArchivesPeopleListComponent} from './archives-people-list/archives-people-list.component';
import {ArchivesJobsListComponent} from './archives-jobs-list/archives-jobs-list.component';
import {ArchivesRightmatchListComponent} from './archives-rightmatch-list/archives-rightmatch-list.component';
import {ArchivesGraphboardListComponent} from './archives-graphboard-list/archives-graphboard-list.component';
import {ArchivesIdwMatrixListComponent} from './archives-idw-matrix-list/archives-idw-matrix-list.component';
import {RightMatchesModule} from "../analysis/right-matches/right-matches.module";
import {GraphboardsModule} from "../analysis/graphboards/graphboards.module";
import {IdwMatricesModule} from "../analysis/idw-matrices/idw-matrices.module";
import { MobileArchivesPeopleListComponent } from './mobile/mobile-archives-people-list/mobile-archives-people-list.component';

@NgModule({
    imports: [
        CommonModule,
        ArchivesRoutingModule,
        PeopleModule,
        JobsModule,
        AnalysisModule,
        SharedModule,
        RightMatchesModule,
        GraphboardsModule,
        IdwMatricesModule
    ],
    declarations: [
        ArchivesPeopleListComponent,
        ArchivesJobsListComponent,
        ArchivesRightmatchListComponent,
        ArchivesGraphboardListComponent,
        ArchivesIdwMatrixListComponent,
        MobileArchivesPeopleListComponent
    ],
    providers: [

    ]
})
export class ArchivesModule {
}
