import {filter} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {ApiGetNewsService} from '../../../shared/news/services/api-get-news.service';
import {AlertService} from '../../../shared/alert/services/alert.service';
import {StateService} from '../../../core/services/state/state.service';
import {VersionCheckService} from '../../services/version-check.service';
import {environment} from '../../../../environments/environment';
import {JwtHelperService} from '../../services/jwt-helper.service';
import {Subscription} from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
declare let ga: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

    page: string;
    login = false;

    showNewsPanel = false;
    showCarousel = false;

    env = environment;

    config: any;

    update = 'fixed';
    assetsFolder = 'assets/';

    test = false;

    deviceInfo = null;
    isMobile = false;
    isTablet = false;
    isDesktopDevice = false;

    private subscriptions = new Subscription();

    constructor(public state: StateService,
                private router: Router,
                private alertService: AlertService,
                private apiGetNewsService: ApiGetNewsService,
                private changeDetectorRef: ChangeDetectorRef,
                private loc: Location,
                private versionCheckService: VersionCheckService,
                private jwtHelperService: JwtHelperService,
                private deviceService: DeviceDetectorService
    ) {
        this.epicFunction();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });
    }

    epicFunction() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    ngOnInit() {
        this.subscriptions.add(this.router.events.pipe(
            filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (event.url.charAt(0) === '/') {
                    event.url = event.url.substr(1);
                }
                let eventUrlArr = event.url.split('/');
                // noinspection OverlyComplexBooleanExpressionJS
                if (
                    eventUrlArr[0] === 'reset-pass' ||
                    eventUrlArr[0] === 'set-pass' ||
                    eventUrlArr[0] === 'login' ||
                    eventUrlArr[0] === ''
                ) {
                    if (event.url === '') {
                        event.url = '/';
                    }
                    this.page = event.url;
                } else {
                    this.page = '/';
                }
                this.changeDetectorRef.markForCheck();
                return;
            }));

        this.subscriptions.add(this.alertService.configWatch$.subscribe(
            (config) => {
                if (config) {
                    this.config = config;
                    this.changeDetectorRef.markForCheck();
                }
            }
        ));

        this.update = 'fixed';
        if (this.env.versionJson) {
            this.versionCheckService.initVersionCheck(this.env.consoleBaseUrl + 'version.json', this);
        }
        if (this.env.production) {
            let urlParams = this.loc.path().split('/');
            if (urlParams[1]) {
                this.page = urlParams[1].split(';')[0];
            } else {
                this.page = 'login';
            }
            if (
                !this.jwtHelperService.isTokenExpired() &&
                localStorage.getItem('remember_me') === 'true' &&
                this.state.session.isLoggedIn
            ) {
                this.router.navigate(['/people/list']);
                return;
            } else if (!this.env.loc) {
                this.state.session.isLoggedIn = false;
                // sessionStorage.clear();
                localStorage.removeItem('id_token');
                localStorage.setItem('is_logged', '');
            }
        }
    }
    get isSender() {
        if (this.state && this.state.session) {
            return this.state.session.isSender;
        }
        return false;
    }

    reloadPage() {
        location.reload();
    }

    log(isLogged) {
        if (isLogged) {
            this.state.session.isLoggedIn = true;
            const today = new Date();
            const yearAgo = moment(today).subtract(1, 'year').format('YYYY-MM-DD').toString();
            this.apiGetNewsService.news_messages(['fr', null, null, yearAgo]).subscribe(
                res => {
                    this.showCarousel = res['carousel-mobile'];
                    this.showNewsPanel = !res['carousel-mobile'] && res['display'];
                    this.changeDetectorRef.markForCheck();
                }
            );
        } else {
            if (this.test) {
                this.state.session.isLoggedIn = true;
            } else {
                this.state.session.isLoggedIn = false;
                // Clear session Data
                sessionStorage.clear();

                localStorage.removeItem('id_token');
                localStorage.setItem('is_logged', '');
            }
        }
    }

    showNews() {
        this.showNewsPanel = !this.showNewsPanel;
    }

    hideCarousel() {
        this.showCarousel = false;
        this.showNewsPanel = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
