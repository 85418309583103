import {Observable} from 'rxjs';
import {Api} from './api';

export abstract class ApiPost extends Api {

    /**
     *
     * Sends POST requests to the API.
     *
     * @param {string} route - See urlParams below
     * @param {Array<any>} urlParams - If urlParams[index] has to be inserted elsewhere than
     *                                 as as suffix, then insert 'UrlParameter' + index at
     *                                 that place in route. Otherwise, it is appended as suffix.
     * @param {*} data
     * @returns {Observable<any>}
     *
     */

    apiPost(route: string, urlParams: Array<any>, data: any): Observable<any> {
        return this.toApi(
            'post',
            route,
            urlParams,
            data
        );
    }

}
