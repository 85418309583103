import {ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
    selector: 'app-customizable-form',
    templateUrl: './customizable-form.component.html',
    styleUrls: ['./customizable-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizableFormComponent implements OnInit {

    mainWidth: string;
    sideWidth: string;

    idDash = {
        textbox: 0
    };

    types = [
        ['text', 'Une seule ligne'],
        ['multiline', 'Multiligne'],
        ['dropdown', 'Liste déroulante'],
        ['checkbox', 'Case à cocher'],
        ['phone', 'Téléphone'],
        ['email', 'E-mail'],
        ['multiselect', 'Multisélection'],
        ['date', 'Date'],
        ['datetime', 'Date/heure'],
        ['money', 'Devise'],
        ['url', 'URL']
    ];

    actualFocus: any = {};

    constructor(private dragulaService: DragulaService,
        private elmt: ElementRef,
        private renderer: Renderer2)
    {
        /**
        this.dragulaService.createGroup('first-bag', {
            copy: function (el, source) {
                return source === document.querySelector('#source');
            },
            accepts: function (el, target) {
                return target !== document.querySelector('#source');
            }
        });
        this.dragulaService.drop.subscribe((value) => {
            if (value.slice(1)[1]) {
                this.onDrop(value.slice(1));
            }
        });
        this.dragulaService.setOptions('second-bag', {
            moves: function (el, container, target) {
                return target.classList.contains('target');
            }
        });
         */
    }

    private onDrop(args) {
        if (args[0].classList[2] == 'seed') {
            for (let i = 0; i < 2; i++) {
                if (args[0].children[i].classList[0] == 'active') {
                    this.renderer.removeChild(args[0], args[0].children[i]);
                } else {
                    this.renderer.removeClass(args[0].children[i], 'inactive');
                }
            }
            this.renderer.removeClass(args[0], 'seed');
            this.renderer.setAttribute(args[0], 'id', args[0].classList[1] + '_' + this.idDash[args[0].classList[1]]);
            let thisId = args[0].classList[1] + '_' + this.idDash[args[0].classList[1]];
            this.renderer.listen(
                document.querySelector('#' + thisId),
                "click",
                (event) => {
                    this.onAction(event, 'text', 'focus', thisId);
                }
            );
            this.idDash[args[0].classList[1]]++;
        }
    }

    onAction(event, type, action, id) {
        if (action == 'focus') {
            this.onAction(null, this.actualFocus.type, 'blur', this.actualFocus.id);
            switch (type) {
            case 'text':
                this.renderer.addClass(
                    document.querySelector('#' + id),
                    'itemSelected'
                );
                this.renderer.addClass(
                    document.querySelector('#' + id + ' div table.inputConfigs tr td:first-child input[type=text]'),
                    'borderSelected'
                );
                break;
            }
            if (event) event.stopPropagation();
        } else if (action == 'blur') {
            switch (type) {
            case 'text':
                this.renderer.removeClass(
                    document.querySelector('#' + id),
                    'itemSelected'
                );
                this.renderer.removeClass(
                    document.querySelector('#' + id + ' div table.inputConfigs tr td:first-child input[type=text]'),
                    'borderSelected'
                );
                break;
            }
        }
        this.actualFocus = {
            id: id,
            type: type
        };
    }

    globalClick() {
        this.onAction(null, this.actualFocus.type, 'blur', this.actualFocus.id);
        this.actualFocus = {};
    }

    ngOnInit() {
        this.computeListWidth(window);
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth: number = 100;
        let innerWidth = startWidth.innerWidth;
        if(startWidth.innerWidth<1280){
            innerWidth = 1280;
        }
        this.mainWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
        return;
    }

}
