import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {IdwMatrixListService} from '../../../services/idw-matrix-list.service';
import {IdwMatrixModalHandlingService} from '../../../services/idw-matrix-modal-handling.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {PersonService} from '../../../../../people/services/person.service';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import * as _ from 'lodash';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PermissionsService} from 'src/app/shared/permissions/permissions.service';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {takeUntil} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-idw-matrix-sidebar',
    templateUrl: './idw-matrix-sidebar.component.html',
    styleUrls: ['./idw-matrix-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatrixSidebarComponent extends ComponentSidebar implements OnInit, OnChanges, OnDestroy {

    @Input() width = '';
    @Input() config: any;
    @Input() isLeftAndNotTop = true;

    maxPerPage;

    canDelete = false;
    currentLang: string;
    titleSep: string;

    onDestroy$: Subject<null> = new Subject();

    dateFormat;

    private subscriptions = new Subscription();

    constructor(protected router: Router,
                public stateService: StateService,
                public idwMatrix: IdwMatrixAnalysisService,
                public idwMatricesList: IdwMatrixListService,
                public idwMatrixModalHandlingService: IdwMatrixModalHandlingService,
                public person: PersonService,
                private apiIdwMatricesService: ApiIdwMatricesService,
                private alertService: AlertService,
                protected route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,
                protected translateService: TranslateService,
                private permissionsService: PermissionsService,
                protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
        this.informationVisibility = false;
    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        this.canDelete = this.permissionsService.canDelete();
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.itemToDisplayId) {
                    if (this.recordsUnique) {
                        this.maxPage = Math.ceil(this.recordsUnique.length / this.maxPerPage);
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    ngOnChanges(changes) {

        // prevent refresh upon change of width
        let keys = Object.keys(changes);
        let firstKey = keys[0];
        if (keys.length === 1 && firstKey === 'width') { return; }

        this.currentLang = this.translateService.currentLang;
        this.maxPerPage = (this.isLeftAndNotTop) ? 42 : 6;
        if (!this.isLeftAndNotTop) { this.width = null; }

        if (this.idwMatrix.itemToDisplay) {
            if (this.recordsUnique) {
                this.maxPage = Math.ceil(this.recordsUnique.length / this.maxPerPage);
            }
        }
    }

    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'navigate', null, 'communication');

    }

    removeItem(id) {
        let recordsUnique = JSON.parse(JSON.stringify(this.recordsUnique));
        let records = JSON.parse(JSON.stringify(this.idwMatrix.records));

        let newRecords = _.remove(records, function (i) {
            return i.id !== id;
        });
        let newRecordsUnique = _.remove(recordsUnique, function (i) {
            return i.id !== id;
        });
        let items = [];
        for (let record of newRecordsUnique) {
            items.push({
                type: 'trait',
                record: record.id
            });
            items.push({
                type: 'prb',
                record: record.id,
                dash: 0
            });
        }
        if (!items.length) {
            // for the case when we try to remove the last associated record
            items.push('0');
        }
        this.subscriptions.add(this.apiIdwMatricesService.putDitGram([this.idwMatrix.id], {'records': items}).subscribe(
            () => {
                this.idwMatrix.records = newRecords;
                this.idwMatricesList.idwMatrixState.stateChanged.next({
                    refreshComponent: true,
                    itemToDisplayId: this.idwMatrix.id
                });
            }
        ));
    }

    openEditModal() {
        this.idwMatrixModalHandlingService.openIdwMatrixModal({'status': 'edit'});
    }

    openPersonModal() {
        this.idwMatrixModalHandlingService.openIdwMatrixPersonModal();
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        let pAction;
        let paragraph;
        if (action === 'archive') {
            if (act) {
                p[0] = this.translateService.instant('analysis.idwMatrix.archived');
                h1[0] = this.translateService.instant('commons.archiving');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[1] = this.translateService.instant('commons.archivingError');

                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });
                const txt = {
                        paragraph: paragraph,
                        texth1: 'Confirmation',
                        position: 'center-center',
                        template: 'warningConfirm'
                    };
                this.alertService.setConfig(txt);
            } else {
                p[0] = this.translateService.instant('analysis.idwMatrix.reactivated');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[0] = this.translateService.instant('commons.reactivation');
                h1[1] = this.translateService.instant('commons.reactError');

                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });
                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            }
        } else if (action === 'delete') {
            p[0] = this.translateService.instant('analysis.commons.idwMatrixDeleted', {
                total: 1,
                plural: ''
            });
            h1[0] = this.translateService.instant('commons.deleted');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            pAction = this.translateService.instant('commons.deletionAction');
            paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                action: pAction,
                total: 1,
                plural: ''
            });

            const txt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txt);
        }
        this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm' && action) {
                    if (action === 'delete') {
                        this.apiIdwMatricesService.deleteDitGram([this.idwMatrix.id]).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                this.state.report.itemToDisplayId = undefined;

                                this.idwMatrix.resetItemToDisplay(this.idwMatricesList.specificState);
                                this.idwMatricesList.getIdwMatricesList(true);
                                if (this.config === 'list') {
                                    this.goToNews();
                                } else {
                                    this.goToList();
                                }
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        );
                    } else {
                        this.apiIdwMatricesService.putDitGram([this.idwMatrix.id], {archive: act}).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (action === 'archive') {
                                    this.state.report.itemToDisplayId = undefined;
                                }

                                this.idwMatrix.resetItemToDisplay(this.idwMatricesList.specificState);
                                this.idwMatricesList.getIdwMatricesList(true);
                                if (this.config === 'list') {
                                    this.goToNews();
                                } else {
                                    this.goToList();
                                }
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        );
                    }
                    action = undefined;
                }
            }
        );
        return;
    }

    onPdfShare() {
        if (this.idwMatrix.records && this.idwMatrix.records.length) {
            this.idwMatrixModalHandlingService.openIdwMatrixPdfModal();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get recordsUnique(): any {
        if (this.idwMatrix.records) {
            let records = this.idwMatrix.records.sort(
                function(a, b) {
                    let textA = a.firstName.toUpperCase();
                    let textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );
            return _.uniqBy(records, 'id');
        }
        return null;
    }
}
