import * as moment from 'moment';
import { Global } from '../global';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StateService } from '../../../core/services/state/state.service';

export abstract class Content extends Global {

    protected constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService);
    }

    dToMongoDate(dateObject: Date | { date: any }, name = null): string {
        let date = dateObject instanceof Date ? dateObject : dateObject.date;
        if (name !== null) {
            date = dateObject[name].date;
        }
        if (date instanceof Date) {
            let month = (date.getMonth() + 1 > 9) ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
            let day = (date.getDate() > 9) ? date.getDate() : '0' + date.getDate();
            return date.getFullYear() + '-' + month + '-' + day;
        } else if (date && date.day !== 0) {
            let month = (date.month > 9) ? date.month : '0' + date.month;
            let day = (date.day > 9) ? date.day : '0' + date.day;
            return date.year + '-' + month + '-' + day;
        } else {
            return '';
        }
    }

    /**
     * Convert a date by lang
     * @param date
     * @param lang
     * @returns date converted
     */
    public convertDate(date: string, lang = 'fr') {
        let convertedDate = null;
        if (date !== null && date !== undefined) {
            convertedDate = (lang === 'fr') ?
                moment(new Date(date)).format('DD/MM/YYYY') :
                moment(new Date(date)).format('MM/DD/YYYY');
        }
        return convertedDate;
    }

    public storeServiceData(dataName, arrayOfVariables, that) {
        let dataObject = {};
        for (let name of arrayOfVariables) {
            dataObject[name] = that[name];
        }
        sessionStorage.setItem(
            dataName,
            JSON.stringify(dataObject)
        );
    }

    public restoreServiceData(dataName, arrayOfVariables, that) {
        const storedService = JSON.parse(sessionStorage.getItem(dataName));
        if (storedService) {
            for (let name of arrayOfVariables) {
                that[name] = storedService[name];
            }
        }
    }
    get subAccountFilter() {
        return (this.state && this.state.listSearchFilters) ?
            this.state.listSearchFilters.subAccount :
            null;
    }

    get state() {
        return this.stateService[this.actualModule.name];
    }

}
