import {Location} from '@angular/common';
import {Component, EventEmitter, Input, NgZone, OnDestroy, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {ApiAdministrationService} from '../../services/api-administration.service';
import {StateService} from '../../../core/services/state/state.service';
import {AlertService} from '../../../shared/alert/services/alert.service';
import {AdminService} from '../../../app/services/admin.service';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountService} from '../../services/account.service';
import {AdminSidebar} from '../classes/admin_sidebar';
import {DeviceDetectorService} from 'ngx-device-detector';
import {EnvService} from '../../../app/services/env.service';

@Component({
    selector: 'app-administration-account-left-sidebar',
    templateUrl: './administration-account-left-sidebar.component.html',
    styleUrls: ['./administration-account-left-sidebar.component.scss']
})
export class AdministrationAccountLeftSidebarComponent extends AdminSidebar implements OnDestroy {

    @Input() accountId: string;
    @Input() accountNumber: string[];
    @Input() subAccountId: number;
    @Input() width: string;
    @Input() accountType: any = null;
    @Input() isUnlimited = false;
    @Input() itemCounts: any = null;
    @Input() small = false;

    @Output() transferCreditsModal = new EventEmitter();
    @Output() purchaseProductsModal = new EventEmitter();
    @Output() adjustmentCreditsModal = new EventEmitter();
    @Output() extendDemo = new EventEmitter();
    @Output() editRefresh = new EventEmitter();

    subscriptions = new Subscription();

    action: string;

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private apiAdministration: ApiAdministrationService,
        public translateService: TranslateService,
        private location: Location,
        private zone: NgZone,
        private adminService: AdminService,
        private userPreferencesService: UserPreferencesService,
        private alertService: AlertService,
        public stateService: StateService,
        public clientAccount: AccountService,
        public environment: EnvService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    act() {
        switch (this.action) {
            case 'report':
                window.open(this.makeReportLink(), '_blank');
                break;
            case 'connect':
                this.switchDatabase();
                break;
            case 'buyProducts':
                this.activatePurchaseProductsModal();
                break;
            case 'ajustCredits':
                this.activateAdjustmentCreditsModal();
                break;
            case 'transferCredits':
                this.activateTransferCreditsModal();
                break;
            case 'extendDemo':
                this.extendDemo.emit(true);
                break;
            case 'archive':
                const txtArchiveConf = (this.translateService.currentLang === 'fr') ? {
                    paragraph: 'Attention. Vous êtes sur le point d\'archiver '
                        + ' un compte. Désirez-vous continuer?',
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                } : {
                    paragraph: 'Warning. You are about to archive an account. Do you want to continue?',
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchiveConf);
                this.subscriptions.add(this.alertService.nextAction.subscribe(
                    res => {
                        if (res === 'confirm') {
                            this.subscriptions.add(this.apiAdministration.putAccount([this.accountId], {archive: true}).subscribe(
                                () => {
                                    const txtArchivePu = (this.translateService.currentLang === 'fr') ? {
                                        paragraph: 'Client archivé.',
                                        texth1: 'Archivage',
                                        position: 'top-right',
                                        type: 'green'
                                    } : {
                                        paragraph: 'Client archived.',
                                        texth1: 'Archivation',
                                        position: 'top-right',
                                        type: 'green'
                                    };
                                    this.alertService.setConfig(txtArchivePu);
                                    this.stateService.admin.stateChanged.next({refreshComponent: true});
                                },
                                () => {
                                    const txtArchiveErr = (this.translateService.currentLang === 'fr') ? {
                                        paragraph: 'Il y a eu un problème. Veuillez réessayer.',
                                        texth1: 'Archivage',
                                        position: 'center-center',
                                        type: 'red'
                                    } : {
                                        paragraph: 'There was a problem. Please try again.',
                                        texth1: 'Archiving',
                                        position: 'center-center',
                                        type: 'red'
                                    };
                                    this.alertService.setConfig(txtArchiveErr);
                                }
                            ));
                        }
                    }
                ));
                break;
            case 'delete':
                const txtDeleteConf = (this.translateService.currentLang === 'fr') ? {
                    paragraph: 'Attention. Vous êtes sur le point de supprimer '
                        + ' un compte. Désirez-vous continuer?',
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                } : {
                    paragraph: 'Warning. You are about to delete an account. Do you want to continue?',
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtDeleteConf);
                this.subscriptions.add(this.alertService.nextAction.subscribe(
                    res => {
                        if (res === 'confirm') {
                            this.subscriptions.add(this.apiAdministration.deleteAccount([this.accountId]).subscribe(
                                () => {
                                    const txtArchivePu = (this.translateService.currentLang === 'fr') ? {
                                        paragraph: 'Client supprimé.',
                                        texth1: 'Suppression',
                                        position: 'top-right',
                                        type: 'green'
                                    } : {
                                        paragraph: 'Client deleted',
                                        texth1: 'Deletion',
                                        position: 'center-center',
                                        type: 'red'
                                    };
                                    this.alertService.setConfig(txtArchivePu);
                                },
                                () => {
                                    const txtDeleteErr = (this.translateService.currentLang === 'fr') ? {
                                        paragraph: 'Il y a eu un problème. Veuillez réessayer.',
                                        texth1: 'Suppression',
                                        position: 'center-center',
                                        type: 'red'
                                    } : {
                                        paragraph: 'There was a problem. Please try again.',
                                        texth1: 'Deletion',
                                        position: 'center-center',
                                        type: 'red'
                                    };
                                    this.alertService.setConfig(txtDeleteErr);
                                }
                            ));
                        }
                    }
                ));
                break;
        }
    }

    activateTransferCreditsModal() {
        this.transferCreditsModal.emit();
    }

    activatePurchaseProductsModal() {
        this.purchaseProductsModal.emit();
    }

    activateAdjustmentCreditsModal() {
        this.adjustmentCreditsModal.emit();
    }

    switchDatabase() {
        this.subscriptions.add(this.apiAdministration.clientsSwitch([this.accountId]).subscribe(
            data => {
                // Reset state
                this.stateService.resetState('saveOrigin');

                // Clear session Data (to have a clean session for the switch user)
                let sessionStorageBk = JSON.stringify(sessionStorage);
                sessionStorage.clear();
                sessionStorage.setItem('origin', sessionStorageBk);

                // Add key isLoggedIn (why? we already have a `is_logged` var in local storage ...)
                sessionStorage.setItem('session.isLoggedIn', 'true');
                sessionStorage.setItem('reload', 'true');
                // store in local storage
                localStorage.setItem('is_logged', 'yes');
                localStorage.setItem('id_token', data.token);
                this.stateService.session.redirectUrl = '';


                // Init session
                this.subscriptions.add(this.adminService.getSession(this.translateService.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;

                        // Init preferences
                        this.userPreferencesService.emptyUserPreferences(
                            'administration',
                            this.stateService.session.sessionData.userData.id
                        );

                        this.router.navigate(['people']).then();
                    }
                ));
            }));
    }

    canDoTransaction(): boolean {
        // Only main admins and super admin can do transactions
        // ToDo: service with methods for all permissions
        return !!(this.permissionsRoles.includes('ROLE_MAIN_ADMIN')
            || this.permissionsRoles.includes('ROLE_SUPER_ADMIN')
        );
    }

    canBuyProducts(): boolean {
        // Only super admin can buy products
        // ToDo: service with methods for all permissions
        return (this.permissionsRoles.includes('ROLE_SUPER_ADMIN') && this.accountType !== 'demo');
    }

    canExtendDemo(): boolean {
        // Only super admin can buy products
        // ToDo: service with methods for all permissions
        return (this.permissionsRoles.includes('ROLE_SUPER_ADMIN') && this.accountType === 'demo');
    }

    canArchive(): boolean {
        // Only super admin can buy products
        // ToDo: service with methods for all permissions
        return (this.permissionsRoles.includes('ROLE_SUPER_ADMIN'));
    }

    canAdjust(): boolean {
        // Only super admin can adjust credits
        // ToDo: service with methods for all permissions
        return (this.permissionsRoles.includes('ROLE_SUPER_ADMIN'));
    }

    canEditSubAccount(): boolean {
        // Only main admins and super admin of Ngenio account can edit sub account
        // ToDo: service with methods for all permissions
        //
        return (
            this.sessionPermissions.isNgenio &&
            (this.permissionsRoles.includes('ROLE_SUPER_ADMIN') ||
                this.permissionsRoles.includes('ROLE_MAIN_ADMIN'))
        );
    }

    canSwitch(): boolean {
        // Only main admins and super admin can switch account
        // ToDo: service with methods for all permissions
        return (this.accountId !== this.sessionAccountData.id
            && (this.permissionsRoles.includes('ROLE_MAIN_ADMIN')
                || this.permissionsRoles.includes('ROLE_SUPER_ADMIN')
            )
        );
    }

    actionAvailable() {
        // noinspection OverlyComplexBooleanExpressionJS
        return !!(
            (this.canDoTransaction() && !this.isUnlimited) ||
            this.canSwitch() ||
            this.canBuyProducts() ||
            this.canAdjust() ||
            this.canExtendDemo() ||
            this.canArchive()
        );
    }

    goToList() {
        this.router.navigate(['admin/list']).then(() => {
        });
    }

    get assetsFiles() {
        let assets = (this.clientAccount.assets) ? this.clientAccount.assets : [];
        if (this.subAccountId) {
            if (this.clientAccount.assets) {
                assets = this.clientAccount.assets.filter(
                    asset => asset.subAccountId === this.subAccountId
                );
            }
        }
        return assets.length;
    }

    makeReportLink() {
        return this.environment.apiBaseUrl + 'api/accounts/report/' + this.makeToken();
    }

    makeToken() {
        return btoa(this.accountNumber + '_' + this.translateService.currentLang + '_' + this.makeid());
    }

    makeid(length = 10) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    get users() {
        return (this.clientAccount.users) ? this.clientAccount.users : {};
    }

    get subAccounts() {
        return (this.clientAccount.subAccounts) ? this.clientAccount.subAccounts : {};
    }

    get purchasesData() {
        return (this.clientAccount.purchases && this.clientAccount.purchases.data) ? this.clientAccount.purchases.data : {};
    }

    get clientDepartments() {
        return (this.clientAccount.departments) ? this.clientAccount.departments : {};
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
