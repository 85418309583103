import './polyfills';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { enableProdMode } from '@angular/core';
// import { EnvServiceProvider} from './app/app/services/env.service.provider';
import { AppModule } from './app/';

// import { Injector } from '@angular/core';
// import {EnvService} from './app/app/services/env.service';

// let injector = Injector.create([EnvServiceProvider]);
// let envService = injector.get(EnvService);

// console.log(envService);

// if (envService.production) {
//  enableProdMode();
// }

platformBrowserDynamic().bootstrapModule(AppModule);
