// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  margin: 40px 0 0 0;
  padding: 0;
}

table thead tr th {
  background-color: #EEE;
  color: #888;
}

tbody {
  display: block;
  overflow: auto;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}

table tbody tr td:first-child {
  color: #558cca;
}

table tbody tr td {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0;
}

.deleteAction {
  justify-content: right;
  width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-common-list/administration-common-list.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,sBAAA;EACA,WAAA;AACJ;;AAEA;EACI,cAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,mBAAA,EAAA,+CAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,sBAAA;EACA,WAAA;AACJ","sourcesContent":["h3 {\n    margin: 40px 0 0 0;\n    padding: 0;\n}\n\ntable thead tr th {\n    background-color: #EEE;\n    color: #888;\n}\n\ntbody {\n    display: block;\n    overflow: auto;\n}\n\nthead, tbody tr {\n    display: table;\n    width: 100%;\n    table-layout: fixed; /* even columns width , fix width of table too*/\n}\n\ntable tbody tr td:first-child {\n    color: #558cca;\n}\n\ntable tbody tr td {\n    cursor: pointer;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    border: 0;\n}\n\n.deleteAction {\n    justify-content: right;\n    width: 80px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
