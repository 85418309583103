import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuardService} from '../app/services/auth-guard.service';

import {MainToolsComponent} from './components/main-tools/main-tools.component';
import {MobileMainToolsComponent} from './components/mobile/mobile-main-tools/mobile-main-tools.component';

@NgModule({
    imports: [RouterModule.forChild([
        {
            path: 'tools',
            redirectTo: '/tools',
            pathMatch: 'full'
        },
        {
            path       : 'tools',
            component  : MainToolsComponent,
            canActivate: [AuthGuardService]
        },
        {
            path       : 'm/tools',
            component  : MobileMainToolsComponent,
            canActivate: [AuthGuardService]
        }
    ])
    ],
    exports: [
        RouterModule
    ]
})
export class ToolsRoutingModule {
}
