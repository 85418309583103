import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";

@Component({
    selector: 'app-main-list-search-filters',
    templateUrl: './main-list-search-filters.component.html',
    styleUrls: ['./main-list-search-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainListSearchFiltersComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
