export class TalentsDefinitions {

    convert: any = [
        'social',
        'service',
        'management',
        'operationnal',
        'task',
        'sales'
    ];

    // Parts of this may be in the DB (check spec for "text" themselves + "id" usage)Isn't that should be in the DB?
    // SPHERES D'APTITUDES
    aptitudes = {
        social: {
            id: 'social',
            title: {
                fr: ['Aptitudes sociales'],
                en: ['Social Aptitudes']
            },
            ttitle: {
                fr: ['Sociales'],
                en: ['Social']
            },
            titleCompetences: {
                fr: ['Competences sociales'],
                en: ['']
            },
            text: {
                en: 'Cluster of qualities that fosters harmonious and dynamic relationships in social, professional and personal spheres while working towards implied or explicit objectives.',
                fr: 'Ensemble des qualités favorisant des relations sociales, professionnelles et personnelles harmonieuses et dynamiques dans la réalisation d\'objectifs précis, déclarés ou non.'
            },
            content: [2, 4, 18, 20, 21, 23, 25, 36, 43, 44],
            coordinate: 0
        },
        service: {
            id: 'service',
            title: {
                fr: ['Aptitudes au service'],
                en: ['Service Aptitudes']
            },
            ttitle: {
                fr: ['Service'],
                en: ['Service']
            },
            titleCompetences: {
                fr: ['Competences au service'],
                en: ['']
            },
            text: {
                en: 'Propensity for listening and helpfulness while feeling a sense of duty to provide impeccable customer service in order to build and maintain long-term relationships.',
                fr: 'Ensemble des qualités d\'écoute et de serviabilité assurant un service à la clientèle impeccable et responsable menant à des relations durables.'
            },
            content: [1, 3, 19, 20, 22, 24, 32, 41, 43, 46],
            coordinate: 1
        },
        management: {
            id: 'management',
            title: {
                fr: ['Aptitudes de management'],
                en: ['Management Aptitudes']
            },
            ttitle: {
                fr: ['Management'],
                en: ['Management']
            },
            titleCompetences: {
                fr: ['Competences de management'],
                en: ['']
            },
            text: {
                en: 'Cluster of aptitudes that fosters an ability to deliver the expected results by orchestrating tasks among groups of individuals, while addressing problems and following strategies that have been identified.',
                fr: 'Ensemble des aptitudes favorisant l\'atteinte des résultats visés par l\'orchestration des activités d\'un groupe de personnes en tenant compte des problèmes et des stratégies proposées.'
            },
            content: [2, 5, 9, 11, 12, 14, 17, 34, 35, 37, 39, 40, 45],
            coordinate: 2
        },
        operationnal: {
            id: 'operationnal',
            title: {
                fr: ['Aptitudes opérationnelles'],
                en: ['Process Management Aptitudes']
            },
            ttitle: {
                fr: ['Opérationnelles'],
                en: ['Process Management']
            },
            titleCompetences: {
                fr: ['Competences opérationnelles'],
                en: ['']
            },
            text: {
                en: 'Cluster of characteristics that are conducive to planning, organizing and taking responsibility for work processes as well as coordinating tasks and teams in project management efforts.',
                fr: 'Ensemble des qualités favorisant la prise en charge, la planification et l\'organisation des processus de travail et la coordination des tâches pour la réalisation en équipe des projets.'
            },
            content: [6, 10, 11, 15, 16, 21, 28, 32],
            coordinate: 3
        },
        task: {
            id: 'task',
            title: {
                fr: ['Aptitudes à la tâche'],
                en: ['Task-Execution Aptitudes']
            },
            ttitle: {
                fr: ['Tâches'],
                en: ['Task-Execution']
            },
            titleCompetences: {
                fr: ['Competences à la tâche'],
                en: ['']
            },
            text: {
                en: 'Cluster of characteristics that are conducive to efficient task execution with a focus on precision, technical norms and current operating standards.',
                fr: 'Ensemble de dispositions favorisant l\'exécution efficace de travaux précis respectant à la fois les normes techniques et les règles de fonctionnement en vigueur.'
            },
            content: [3, 7, 26, 27, 29, 30, 31, 33],
            coordinate: 4
        },
        sales: {
            id: 'sales',
            title: {
                fr: ['Aptitudes à la vente'],
                en: ['Sales Aptitudes']
            },
            ttitle: {
                fr: ['Vente'],
                en: ['Sales']
            },
            titleCompetences: {
                fr: ['Competences à la vente'],
                en: ['']
            },
            text: {
                en: 'A range of talents that favors networking and the development of business relationships,  a pro-active sense of perseverance, and a willingness to seize business opportunities in order to reach sales objectives.',
                fr: 'Ensemble des talents visant à développer des relations commerciales et à saisir les opportunités d\'affaires pour atteindre les objectifs de vente, tant par le développement d\'un réseau de contacts que par des initiatives judicieuses et la persévérance dans l\'action.'
            },
            content: [2, 8, 10, 13, 14, 21, 23, 30, 35, 36, 38, 42],
            coordinate: 5
        }
    };

    talents = {
        en: [
            ['Flexibility'],
            ['Self-Confidence'],
            ['Consistency'],
            ['Creativity'],
            ['Stress', 'Management'],
            ['Multitasking'],
            ['Cautiousness'],
            ['Resilience'],
            ['Delegation', 'Skills'],
            ['Structured', 'Thinking'],
            ['Project', 'Management'],
            ['Change', 'Management'],
            ['Sense of', 'Initiative'],
            ['Results-driven'],
            ['Planning and', 'Organizing'],
            ['Problem Solving'],
            ['Leadership', 'Skills'],
            ['Interpersonal', 'Skills'],
            ['Listening', 'Skills'],
            ['Empathy'],
            ['Persuasiveness'],
            ['Attentiveness'],
            ['Sociability'],
            ['Tolerance'],
            ['Teamwork'],
            ['Analytical', 'Thinking'],
            ['Compliance'],
            ['Supervising'],
            ['Applied Technical', 'Thinking'],
            ['Perseverance'],
            ['Stringency'],
            ['Sense of Duty', 'and Responsability'],
            ['Attention to Detail'],
            ['Goal Setting'],
            ['Risk Taking'],
            ['Capacity for', 'Synthesis'],
            ['Decision Making'],
            ['Independent', 'Thinking'],
            ['Conceptual', 'Thinking'],
            ['Strategic Vision'],
            ['Helpfulness'],
            ['Sense of Urgency'],
            ['Concern for', 'Others'],
            ['Intuition'],
            ['Level-', 'headedness'], // need break
            ['Customer', 'Service']
        ],
        fr: [
            ['Flexibilité'], // 1
            ['Confiance', 'en soi'], //
            ['Constance'], //
            ['Créativité'], //
            ['Gestion', 'du stress'], // 5
            ['Multitâches'], //
            ['Prudence'], //
            ['Résilience'], //
            ['Délégation'], //
            ['Esprit', 'd\'organisation'], // 10
            ['Gestion', 'de projet'], //
            ['Gestion du', 'changement'], //
            ['Initiative'], //
            ['Orientation', 'résultat'], //
            ['Planification'], // 15
            ['Résolution de', 'problèmes'], //
            ['Savoir', 'diriger'], //
            ['Communication', 'interpersonnelle'], //
            ['Écoute'], //
            ['Empathie'], // 20
            ['Persuasion'], //
            ['Prévenance'], //
            ['Sociabilité'], //
            ['Tolérance'], //
            ['Travail', 'd\'équipe'], // 25
            ['Esprit', 'd\'analyse'], //
            ['Conformité'], //
            ['Contrôle'], //
            ['Pensée', 'technique'], //
            ['Persévérance'], // 30
            ['Rigueur'], //
            ['Sens des', 'responsabilités'], //
            ['Souci du', 'détail'], //
            ['Fixation', 'd\'objectifs'], //
            ['Prise de', 'risque'], // 35
            ['Esprit de', 'synthèse'], //
            ['Esprit de', 'décision'], //
            ['Indépendance', 'd\'esprit'], //
            ['Pensée', 'conceptuelle'], //
            ['Vision', 'stratégique'], // 40
            ['Serviabilité'], //
            ['Sens de', 'l\'urgence'], //
            ['Sensibilité', 'aux autres'], //
            ['Intuition'], //
            ['Contrôle', 'de soi'], // 45
            ['Service à', 'la clientèle']// 46
        ]
    };

    // Isn't that should be in the DB?
    talentsTxt = {
        en: [
            'Operating efficiently in constantly evolving environments and, when confronted with unforeseen circumstances, readjusting priorities to accommodate change.',
            'Acting with conviction and having confidence in one\'s own ability to overcome obstacles. Stating a position and defending a point of view.',
            'Ability to work patiently through disturbances or setbacks, and keep working persistently towards a given process or task without giving up.',
            'Ability to conceive of new ideas and develop innovative solutions to deal with existing problems.',
            'Ability to maintain high levels of performance despite difficult situations, under tight time constraints and irrespective of other stress-related factors.',
            'Ability to multi-task effectively and to shift focus easily and rapidly from one task to another.',
            'Behaving in a sensible and well-thought-out manner that accounts for the consequences of one\'s own actions, minimizes error and prevents damage or loss.',
            'Ability to bounce back from failure and continue performing effectively in the face of adversity, criticism or rejection.',
            'Assigning the right people to the right jobs. Establishing clearly defined responsibilities and objectives, while granting authority to make decisions and providing any necessary support.',
            'Using methodical thought processes, prioritizing tasks and efficiently managing workload to meet deadlines.',
            'Coordinating activities according to a plan, developing follow-up procedures and applying them methodically at every stage until goals are reached.',
            'Actively participating in change; gearing change to focus on positive operating enhancements in order to more effectively achieve changing objectives.',
            'Acting independently. Anticipating needs, taking advantage of opportunities and flagging problems in order to attain objectives.',
            'Being results-driven. Prioritizing achievement of results over other needs, by investing the necessary resources and making all necessary efforts to meet desired goals.',
            'Developing, implementing, evaluating and modifying plans to optimize the use of available resources and reach objectives.',
            'Ability to identify problems, determine cause and effect, and propose solutions using new approaches or an innovative point of view.',
            'Being a leader. Taking control of situations by establishing objectives and clearly communicating subsequent actions.',
            'Demonstrating ease in presenting ideas and doing so in a dynamic manner with a communication style that facilitates open exchange and is adapted to both audience and context.',
            'Allowing others to express themselves freely by being an attentive, open and active listener.',
            'Empathizing with others\' feelings, reactions or concerns by putting oneself in their shoes.',
            'Leveraging personal qualities, facts, arguments, and a range of communications techniques to convince others to adhere to one\'s own ideas, objectives or ways of doing things.',
            'A propensity to anticipate the needs and desires of others in a pleasant and polite manner.',
            'Initiating contact and building personal or professional relationships with others in a natural, friendly manner.',
            'Acknowledging that others think or act in unique ways that differ from oneself, and interacting without prejudice towards their distinct values or differences.',
            'Whether in a leadership or subordinate role, pro-actively working towards common objectives and showing support for team members to overcome team problems.',
            'Breaking down the fundamental components of a problem or situation, examining the relationship between them, verifying all pertinent facts and drawing an appropriate conclusion.',
            'Ability to adhere to operating policies and comply with  workplace norms and commonly-accepted industry standards.',
            'Overseeing the successful execution of operations and the achievement of expected results in accordance with a plan or established norms.',
            'Ability to apply specialized, theoretical knowledge to efficient operational uses.',
            'Capable of working in a steadfast and dedicated manner while remaining focused on key objectives for the task at hand.',
            'Establishing strict procedures, adhering to work methodologies and monitoring execution in order to guarantee high-quality results or reach very specific objectives.',
            'Ability to assume assigned responsibilities with dedication. Accepting the consequences of one\'s own decisions and actions while inspiring both confidence and trust. A good example for others.',
            'Systematic verification of work performed with special attention paid to precise details and overall quality.',
            'Clearly define sensible and achievable objectives whose attainment can be measured via well-established benchmarks.',
            'Ability to venture outside of personal and professional comfort zones by relying on one\'s own strengths and potential.',
            'Ability to bring together disparate elements to create a coherent entity or a big-picture overview in order to gain a new perspective.',
            'Being prone to making decisions, using sound judgment and  assessing situations in a logical manner. Acting or reacting quickly, regardless of risks.',
            'Operating according to one\'s own standards without being overly influenced by the opinions of others, nor expecting any assistance or support.',
            'Mentally organizing abstract concepts. Conceiving, developing or organizing ideas in a coherent manner to reach an objective.',
            'Soliciting scenarios from a variety of different perspectives and then advocating a direction to take, supported by facts and reliable information.',
            'Having the kindness and willingness to help others out when a need arises, despite circumstantial constraints.',
            'Being prone to react quickly to outside demands, get onto things right away and get things done fast.',
            'Being mindful of others, showing understanding to their needs and being able to respond with compassion and sympathy.',
            'Having the ability to sense or gather things and use instinctive know-how, without relying on reasoning skills.',
            'Being able to call upon reason to help master one\'s own emotions and reactions, in order to behave appropriately in difficult situations.',
            'Going above and beyond to provide quality service to clients and seeking to sustain high satisfaction levels over time.'
        ],
        fr: [
            'Fonctionner avec efficacité dans des environnements changeants et des circonstances imprévues, modifier ses priorités en accueillant le changement.',
            'Faire preuve d\'assurance et croire en ses capacités de surmonter les obstacles. Affirmer son point de vue et défendre une position.',
            'Capacité à supporter des désagréments et à démontrer de la constance dans ses démarches ou dans son travail malgré les dérangements.',
            'Capacité à imaginer et inventer de nouvelles idées ou des solutions originales aux problèmes courants.',
            'Capacité à maintenir un niveau de rendement élevé malgré les situations difficiles, les contraintes de temps et le niveau de stress ambiant.',
            'Pouvoir exécuter plusieurs tâches de façon simultanée et passer rapidement d\'une à l\'autre, quitte à revenir en arrière pour terminer.',
            'Adopter une conduite prudente et attentive, réfléchir aux conséquences de ses actes, éviter les erreurs, et prévenir tout dommage.',
            'Pouvoir rebondir à la suite d\'échec, continuer à fonctionner efficacement face à l\'adversité, la critique ou le rejet.',
            'Assigner les responsabilités et l\'autorité à des fonctions définies. Soumettre au responsable les activités et les problèmes pour la prise de décisions et les actions appropriées.',
            'Avoir de l\'ordre dans ses pensées. Ordonner rigoureusement son travail et structurer efficacement les tâches à effectuer dans les délais prescrits.',
            'Coordonner les activités selon le plan de projet, développer des mécanismes de suivi et les appliquer méthodiquement à chacune des étapes vers l\'atteinte des objectifs.',
            'Participer au changement et à l\'orienter vers l\'amélioration des méthodes de travail pour mieux atteindre des objectifs en évolution.',
            'Agir de façon autonome en anticipant les besoins, saisir les opportunités et prévenir les problèmes pour atteindre les objectifs.',
            'Considérer l\'atteinte des résultats comme prioritaire en investissant les ressources et les efforts nécessaires à l\'atteinte des objectifs.',
            'Développer, implanter, évaluer et modifier les plans en vue d\'atteindre les objectifs tout en assurant une bonne utilisation des ressources disponibles.',
            'Cerner les problèmes avec leurs causes et conséquences et proposer des solutions à partir de nouvelles perspectives.',
            'Adopter une position de commandement, prendre le contrôle de la situation en fixant les objectifs et en communiquant clairement les actions éventuelles.',
            'Démontrer beaucoup d\'aisance à présenter ses idées de façon dynamique dans un mode de communication ouvert à l\'échange et adapté tant à la compréhension des autres qu\'à la situation.',
            'Permettre aux autres de s\'exprimer librement en faisant preuve d\'attention, d\'ouverture et d\'écoute active.',
            'Comprendre les sentiments, les préoccupations ou les réactions des autres en se mettant à leur place.',
            'Utiliser autant ses qualités personnelles que des méthodes de communication, des faits et des arguments pour faire adhérer les autres à ses idées, à ses objectifs ou à ses façons de faire.',
            'Disposition à aller au-devant des besoins d\'autrui, de façon aimable et polie. Démontrer de la gentillesse.',
            'Entrer spontanément en contact avec les gens et établir facilement des relations sociales amicales ou professionnelles avec une attitude cordiale.',
            'Admettre chez les autres une manière de penser ou d\'agir différente des siennes, accepter de fonctionner sans idées préconçues avec des gens ayant des valeurs différentes.',
            'Contribuer activement à l\'atteinte d\'objectifs communs avec les membres d\'une équipe en restant solidaire face aux difficultés, tant comme leader que subordonné.',
            'Mettre en relief les éléments constitutifs d\'un problème ou d\'une situation, étudier la relation entre eux, vérifier les faits et en tirer une conclusion.',
            'Savoir adhérer aux politiques de fonctionnement et se conformer aux normes et aux usages de son milieu.',
            'Faire preuve d\'attention au bon déroulement des activités et à l\'atteinte des résultats attendus, conformément au plan ou selon les normes.',
            'Capacité à appliquer concrètement des connaissances théoriques spécialisées dans des mécanismes fonctionnels et efficaces.',
            'Pouvoir travailler de façon constante et déterminée en s\'investissant dans la tâche sans perdre l\'objectif de vue.',
            'Établir des procédures strictes, respecter les méthodes de travail et contrôler l\'exécution afin de garantir un résultat de qualité ou l\'atteinte précise de l\'objectif.',
            'Endosser les responsabilités qu\'on lui confie.  Se dédier à ses tâches et assumer les conséquences de ses décisions en inspirant confiance aux autres. Donner le bon exemple.',
            'Être capable d\'assurer une vérification systématique du travail et de porter une attention particulière à la précision des détails qui constituent la qualité de l\'ensemble.',
            'Définir clairement des buts réalistes et valables dont la réalisation peut être mesurée selon des étapes bien établies.',
            'Être capable de s\'aventurer au-delà de sa zone de confort personnel ou professionnel en se fiant à ses capacités et à son potentiel.',
            'Capacité à rassembler des éléments disparates pour en constituer un tout cohérent, une vue d\'ensemble pouvant offrir une nouvelle perspective.',
            'Être disposé à décider en évaluant la situation avec logique, à s\'engager en faisant preuve de jugement et à agir rapidement malgré les risques.',
            'Fonctionner selon ses propres normes en étant peu influencé par l\'opinion des autres ni en espérant de l\'aide ou de l\'appui.',
            'Organiser mentalement des notions abstraites. Concevoir, développer ou enchaîner des idées dans un tout cohérent correspondant à un objectif à réaliser.',
            'Privilégier et projeter des orientations claires basées sur des scénarios logiques élaborés à partir de recherches sérieuses et de renseignements fiables.',
            'Être disposé à toujours rendre service sur demande et avec gentillesse, malgré les contraintes.',
            'Être capable de réagir rapidement aux sollicitations, à commencer les tâches rapidement et à les terminer au plus vite.',
            'Prêter attention aux autres en faisant preuve d\'une capacité à comprendre leurs besoins  et à y répondre avec sympathie ou compassion.',
            'Démontrer de l\'habileté à sentir ou à deviner les choses, faire preuve d\'un savoir instinctif, sans l\'aide de son raisonnement.',
            'Être capable de maîtriser ses émotions et ses réactions en faisant appel à la raison. Se comporter de façon appropriée dans les situations difficiles.',
            'Offrir un service de qualité à la hauteur des besoins des clients et maintenir un haut niveau de satisfaction à long terme.'
        ]
    };

    talentsPolarities = {
        en: [
            {
                low: 'Musters sufficient adaptability to revise priorities when truly necessary. Last-minute changes or anything unforeseen may engender stress. Prefers a stable work setting.',
                med: 'Operates quite efficiently in a constantly evolving environment. When confronted with unforeseen events or elements, readjusts to accommodate changes.',
                high: 'Works well in a range of settings and easily adapts to situational changes. Can turn things around quickly when controversy or trouble strike, showing resilience and adaptability. '
            },
            {
                low: 'Posesses enough confidence to take charge of assigned day-to-day tasks. Expresses own views when in harmonious, non-threatening environments. Has a preference for following directives and instructions.',
                med: 'Acts with some conviction and is pretty confident in own ability to overcome obstacles. Can state a position and defend a point of view.',
                high: 'Expresses viewpoints with certitude. Clearly states expectations or needs. Being confident in the value of own ideas, submits or imposes opinions with a sense of conviction.'
            },
            {
                low: 'Prioritizes short-term demands and fast results. Tries to muster a little patience when facing hurdles or impediments in an attempt to get things back on track.',
                med: 'Works patiently and persistently on mandated tasks and processes, despite disturbances and setbacks. Generally won\'t give up. ',
                high: 'Remains constant throughout every stage of a mandate and persists regardless of any obstacles. Shows tolerance and understanding when others experience difficulty.'
            },
            {
                low: 'May suggest new approaches, methods or practical applications so long as they stem from proven ideas. Partakes in process enhancement when truly necessary.',
                med: 'Occasionally conceives of new ideas and develops innovative solutions to deal with existing problems.',
                high: 'Not short on curiosity, originality or daring. Loves to bring in new, innovative solutions. A willing supporter of brainstorming and new concepts.'
            },
            {
                low: 'Manages to maintain acceptable performance levels under short-term stress. However, may feel overwhelmed under constant stress or unyielding pressure.',
                med: 'Normally maintains good levels of performance in challenging or stressful situations, with tight deadlines or other pressures.',
                high: 'Has the ability to channel most types of stress. Takes on change by taking action and integrating it. Won\'t let stress become destructive. Takes on tight deadlines with enthusiasm.'
            },
            {
                low: 'Can alternate between tasks if necessary, but prefers finishing one task before undertaking the next. Stress sets in when multiple urgent tasks vie for attention all at once.',
                med: 'Can multi-task effectively, shifting focus from one task to another without much trouble.',
                high: 'Regularly executes several tasks at any given time, shifting nimbly from one to the other. Undertakes several mandates at once and is motivated by timelines.'
            },
            {
                low: 'Behaves sensibly in accordance with whatever rules are in place. Doesn\'t hesitate to take action,  assuming that errors will be corrected along the way.',
                med: 'Behaves sensibly and in a well-thought-out manner. Considers the consequences of own actions, minimizes errors and avoids damage or loss.',
                high: 'Exercises great care in everything. Seeks to prevent all possible mistakes by following rules to the letter. Has a heightened awareness and concern for the potential consequences of any actions.'
            },
            {
                low: 'Seeks to avoid the risk of failure or criticism. Can suffer self-doubt and lose confidence due to setbacks or perceived rejections.',
                med: 'Can bounce back from most failures and continue performing effectively after experiencing adversity, criticism or rejection.',
                high: 'Adapts to just about any situation and promptly bounces back from failure. Gets back in the saddle and keeps going despite adversity and setbacks.'
            },
            {
                low: 'Delegates tasks  to proven, trustworthy subordinates and contractors, while keeping a very close eye at regular intervals.',
                med: 'Usually assigns the right people to the right jobs. Establishes clear responsibilities and objectives, while granting authority to make decisions and providing necessary support.',
                high: 'Delegates both authority and duties without apprehension, and checks in sporadically on progress. Allows others leeway for action and decision-making.'
            },
            {
                low: 'Comfortable managing tasks with no specific structure to follow. Deadlines are approached with a calm and casual attitude, with problems getting handled on-the-fly as they arise. Aims to get things done simply and properly.',
                med: 'Uses methodical thought processes, prioritizes tasks well enough and manages workload to meet deadlines.',
                high: 'Seeks to enhance everything in the surrounding environment. Anticipates problems, establishes processes, builds work methods and respects deadlines. Establishes a thorough work structure - leaving nothing to chance.'
            },
            {
                low: 'Contributes to project delivery by simply executing assigned tasks and following the anticipated schedule. Coordinates tasks effectively with other players and ensures own work aligns with the specified objectives.',
                med: 'Coordinates activities in accordance with a plan, develops follow-up procedures and applies them methodically at every stage until goals are reached.',
                high: 'Can build comprehensive project management systems from the ground up with all the necessary checks and balances. Takes charge of projects, oversees all details and deadlines - leaving nothing to chance. Keeps the big picture in mind.'
            },
            {
                low: 'Assesses the pros and cons of change prior to making any commitment. After much deliberation, participates in change and supports it.',
                med: 'Participates in change; can focus on positive operating enhancements in order to help achieve changing objectives.',
                high: 'Initiates or partakes in change in order to reach performance goals. Creates or supports the adoption of effective implementation strategies. Encourages others to get on board.'
            },
            {
                low: 'Takes time to properly establish all needs and concerns, acting appropriately in accordance with directives and own schedule.  Aims to avoid potential predicaments and hurdles.',
                med: 'Often acts independently by anticipating needs, looking for opportunities and flagging problems to help attain objectives.',
                high: 'Takes action without waiting for further instruction. Has a sense of when to seize an opportunity. Anticipes needs and acts quickly. Approaches obstacles with a positive attitude.'
            },
            {
                low: 'Does not believe that results alone can be a measure of success. Works with commitment towards a goal, but with greater concern for atmosphere and well-being than most.',
                med: 'Understands results are a priority, and invests the necessary resources and efforts to meet desired goals.',
                high: 'Stays on track with great determination. Discerns key success factors and gets things done. Places successful performance above all other factors. Takes things all the way.'
            },
            {
                low: 'Manages to plan out and structure the basic essentials of any task or project, noting key deadlines and following a calendar in an acceptable fashion.',
                med: 'Plans out, structures, evaluates and modifies project and tasks and makes good use of available resources to reach objectives.',
                high: 'Able to structure and coordinate whatever tasks prove necessary to reach a desired goal. Plans out resource requirements while assessing costs and deadlines in a thorough step-by-step plan.'
            },
            {
                low: 'Notices problems and their impact. Looks to simple, tangible solutions as corrective measures.  Seeks assistance for more complex or serious issues.',
                med: 'Able to identify problems, then determine root causes and effects before proposing solutions, new approaches or an innovative point of view.',
                high: 'Finds complex problems enjoyable and envigorating. Explores all possible causes to identify underlying sources. Rationally deliberates various solutions prior to implementing novel action plans.'
            },
            {
                low: 'Grasps stated goals and communicates them forward. Seeks consensus on each step to be taken and provides support to those achieving things.',
                med: 'Understands how to be a leader. Takes control of situations by setting objectives and communicating subsequent actions.',
                high: 'Sets goals and directs the action. Faces challenges head-on. Explains what\'s at stake and how to move forward. Encourages and places trust in others.'
            },
            {
                low: 'Assesses the relevance of sharing information depending on which tasks are involved and on a need-to-know basis. Delivers messages centred on functional task requirements.',
                med: 'Demonstrates ease in presenting ideas and generally does so in a dynamic manner. Usually finds a communication style that facilitates open exchange; tries to adapt to both audience and context.',
                high: 'Advocates sharing of information and communicating openly with all people involved. Takes care to frame information in such as way as to enhance productivity. Builds a work climate based on trust.'
            },
            {
                low: 'Attempts to listen to what others have to say, mustering more energy when a subject is of personal interest. Strives to let others finish their ideas. Asks for clarifications when needed.',
                med: 'Allows others to express themselves freely by being an attentive, open and active listener.',
                high: 'Takes others\' feelings and words very seriously, but without passing judgment. Allows others to fully express themselves in order to fully understand where they are coming from. Reflects back what\'s been said to confirm a proper understanding.'
            },
            {
                low: 'Particularly when work is at stake, shows interest in others\' feelings and misgivings. Shows more interest in others\' views on common areas of interest.',
                med: 'Empathizes with others\' feelings, reactions or concerns by imagining what it\'s like being in their shoes.',
                high: 'Shows great interest in others\' feelings and viewpoints. Understands people\'s concerns and reassures them when needed. Maintains an objective viewpoint.'
            },
            {
                low: 'Sticks to fact-centred ideas, arguments and analysis to solicit agreement, rather than relying on interpersonal prowess. Avoids negotiating.',
                med: 'Leverages personal qualities, facts, arguments, and communications techniques when convincing others to adhere to  personal ideas, objectives or ways of doing things.',
                high: 'Enjoys captivating an audience and negotiating things. Finds it easy to influence and persuade people. Has no trouble rallying people to reach a goal.'
            },
            {
                low: 'Acts acceptably politely in most situations. Answers questions willingly, providing the required information or service.',
                med: 'Responds to (and often anticipates) the needs and desires of others in a pleasant and polite manner.',
                high: 'Gets ahead of the game by anticipating and discerning people\'s needs in order to respond in a most pleasant and polite manner. Avoids conflict.'
            },
            {
                low: 'Communicates freely when approached. Seeks positive and cordial exchanges once more familiar with someone. Not a fan of public or group address.',
                med: 'Initiates contact and builds personal or professional relationships with others in a natural, friendly manner.',
                high: 'Excels at connecting with people. Maintains a positive, warm and approachable demeanor. Creates opportunities for exchange and mutual respect. Enjoys an audience or public speaking.'
            },
            {
                low: 'Accepts that others hold different values. Accepts others using their own method, so long as results remain the same.',
                med: 'Acknowledges that others think or act in unique ways that differ from own standards, and interacts without prejudice towards their distinct values or differences.',
                high: 'Excels at accepting others exactly as they are. Respects and appreciates a rich and diverse range of cultures and personal styles. Generous of spirit and very understanding.'
            },
            {
                low: 'Executes work in accordance with own job priorities, coordinating tasks within own team. Responsive when asked directly for help. Contributes more effectively when interested.',
                med: 'Works pro-actively towards common objectives and shows support for team members to overcome team problems.',
                high: 'Fully aware that individual contributions are essential to team and group success. Remains focused on objectives and motivates others when delays and setbacks occur. Identifies obstacles and is committed to achieving common goals.'
            },
            {
                low: 'Looks at the various elements of a whole entity and appreciates coherence. Studies data and establishes basic relationships between elements. Validates information and proposes conclusions.',
                med: 'Breaks down the fundamental components of a problem or situation by studying the relationship between them with some diligence. Then validates any pertinent facts and draws conclusions.',
                high: 'Delights in thoroughly investigating anything. Seeks to understand how things work and how situations develop. Focuses and deliberates upon the full extent of any impacts. Being meticulous, draws conclusions only once results have been triple-checked.'
            },
            {
                low: 'Accepts the need to comply with existing policies and industry standards. Retains a certain level of independance and leeway within any job role.',
                med: 'Normally adheres to operating policies and complies with  workplace norms and commonly-accepted industry standards.',
                high: 'Readily implements recommended rules, regulations and procedures. Heightened ability to comply with regulatory standards. Respects authority and established hierarchies.'
            },
            {
                low: 'When supervising, occasionally checks in on projects and tasks at sporadic intervals to ensure things are going OK.',
                med: 'Oversees the successful execution of operations and the achievement of expected results in accordance with a plan or established norms.',
                high: 'Implements strict follow-ups on assigned work or responsibilities. Ensures deadlines are met and that completed work is fully compliant with all standards.'
            },
            {
                low: 'Appreciates when tools and processes work effectively and efficiently, in relation to their intended purpose. Able to see causes behind most malfunctions and report them to the right person.',
                med: 'When defects and malfunctions occur, usually puts specialized, theoretical knowledge to efficient operational use with an aim to resolve them.',
                high: 'Seeks to understand how systems operate and how things function. Capable of restoring operations in the event of a breakdown or failure. Able to apply theoretical concepts to practice.'
            },
            {
                low: 'Work pace can vary in relation to the level of priority level or the type of task undertaken. Highly responsive to urgent requests. Musters enough energy to stay on track despite distractions.',
                med: 'Capable of working in a somewhat steadfast and dedicated manner while remaining focused on key objectives for the task at hand.',
                high: 'Espouses long-term strategic vision. Follows through on anything undertaken. Is firm and tenacious, occasionally stubborn. Brings cohesion and certainty to a project.'
            },
            {
                low: 'Follows key procedures while working. Adopts own methods to better reach objectives. Sets occasional milestones or markers to check on progress and quality levels.',
                med: 'Establishes procedures, adheres to work methodologies and monitors execution in order to obtain high-quality results or reach objectives.',
                high: 'Establishes strict operating procedures. Values process flows and compliance with standards. Monitors and verifies execution. Maintains consistent quality controls.'
            },
            {
                low: 'Follows the general gist or essence of any important objectives. May take liberties or make compromises, assuming responsibility for what\'s been undertaken.',
                med: 'Can assume assigned responsibilities with suitable dedication. Accepts the consequences of own decisions and actions while maintaining confidence and trust. A good example for others.',
                high: 'Assumes assigned responsibilities with dedication and a great sense of duty. Is disciplined and conscientious. Accepts responsibility for all decisions and mistakes. Aspires to serve as a role model for others.'
            },
            {
                low: 'Pays attention to key details, ensuring globally that crucial quality and quantity markers are met. Takes personal responsibility for ensuring any work done delivers at least an acceptable global impression.',
                med: 'Performs standard verification of work, paying careful attention to key details and concern for overall accuracy and quality.',
                high: 'Personally ensures that all operations are performed accurately, in compliance with methods and with meticulous attention to detail. Relentlessly pursues product excellence. Carefully checks and verifies everything before signing off on anything.'
            },
            {
                low: 'When receiving objectives, ensures overall clarity and feasibility before starting. Sets out general steps to follow, then gets to work. Deadlines and action plan steps may not always be fully realistic.',
                med: 'Defines sensible and achievable objectives whose attainment can be measured via benchmarks. Deadlines and action plan steps are usually realistic.',
                high: 'Establishes and clearly articulates specific, feasible and realistic objectives. Develops an action plan with fixed milestones and measurable outcomes in order to assess results.'
            },
            {
                low: 'When faced with the unforeseeable, carefully asssesses risks. Possesses enough confidence in own abilities to venture a little out of the comfort zone, remaining on a heightened state of alert nonetheless.',
                med: 'Is quite willing and able to venture outside of personal and professional comfort zones when relying on own strengths and potential.',
                high: 'Maintains great confidence in own ability to face the unknown. Enjoys exploring new avenues and testing the waters, even when they result in failure and mean starting from scratch. Can venture into bold, ambitious and even risky projects.'
            },
            {
                low: 'Perceives different elements within an entity and categorizes them. Establishes basic relationships between various components in order to gather them into a generally coherent picture.',
                med: 'Can bring disparate elements together to create a coherent entity or a big-picture overview and gain a new perspective.',
                high: 'Ability to take in large amounts of information and summarize it all succinctly. Doesn\'t get bogged down in details. Adopts a holistic big-picture approach, while flagging evolving trends and opportunities for change.'
            },
            {
                low: 'Carefully assesses the situation and impending risks, thinking over the best decision or the number-one recommendation to make. Gathers support before taking action.',
                med: 'Makes decisions when needed, without too much fuss, using sound judgment and assessing situations. Considers risks and acts accordingly. ',
                high: 'Is very comfortable making difficult decisions. Examines pros and cons using logic and reason. A determined, action-oriented “go-getter”.'
            },
            {
                low: 'Seeks others\' opinions before settling on own opinion. Remains genuine enough, but nonetheless seeks approval.  Generally follows norms.',
                med: 'Can operate according to own standards without being overly influenced by the opinions of others. May occasionally seek assistance or support.',
                high: 'Investigates situations and evaluates facts before forming  personal opinions. Acts independently based on personal observations. Establishes own standards and processes. Is true to self and to own points of view.'
            },
            {
                low: 'Gathers useful ideas and focuses on making abstract concepts more digestable. Simplifies complex notions. Exchanges useful, usable ideas for concrete applications.',
                med: 'Can mentally organize abstract concepts. Conceives, develops or organizes ideas in a coherent manner to reach an objective.',
                high: 'Regularly deals with abstract concepts and turns them to deliverable projects. Readily understands concepts and ideas, and can operationalize them into large-scale action plans.'
            },
            {
                low: 'Shows concern for day-to-day operations insofar as they relate to own vision of reaching objectives. Makes sure tasks are done in accordance with strategies laid out in the action plan. ',
                med: 'Builds scenarios and solicits a variety of different perspectives but also focuses on getting tasks done and following an action plan, supported by facts and reliable information.',
                high: 'Draws upon instinct, logic and relevant research to obtain a clear-cut strategic vision and trajectory. Proposes (or imposes) a coherent, interdisciplinary framework to plan and execute tasks.'
            },
            {
                low: 'Willingly responds to any requests for help when available and when they fall within the job description.',
                med: 'Demonstrates the kindness and willingness to help others out when a need arises.',
                high: 'Pro-actively seeks opportunities to offer unsolicited advice or assistance, in an unimposing and diplomatic manner.'
            },
            {
                low: 'Aims to complete tasks on time but without undue pressure on self or others. Assesses time requirements and performance levels (own & others\') accordingly.',
                med: 'Generally responds quickly to outside demands even if under pressure. Gets onto things as soon as feasible and gets things done as soon as able.',
                high: 'Responds quickly and decisively to immediate input and demands. Will exert pressure to obtain timely results. Focuses on completing the task at hand in order to move swiftly on to the next.'
            },
            {
                low: 'Listens to any needs that are openly expressed and attempts to respond to them within the confines of own skillset. May show greater sympathy towards people with closer personal ties or who ask for help.',
                med: 'Is usually mindful of others, showing understanding for their needs and is able to respond with compassion and sympathy.',
                high: 'Is emotionally intuitive and interacts with others in a sensitive or empathetic manner. Can easily discern people\'s feelings or emotions. Expresses sympathy and compassion spontaneously.'
            },
            {
                low: 'Uses logic to explain own feelings and perceptions, which can occasionally prove to be right or well-timed. Generally calls upon reason, but may use instinct on occasion.',
                med: 'Often enough shows the ability to sense or gather things and use instinctive know-how, without relying on reasoning skills.',
                high: 'Often takes appropriate courses of action, even when clear indicators or benchmarks are lacking. Can instinctively grasp causes or foresee effects. Trusts gut instincts and feelings.'
            },
            {
                low: 'Calls upon reason in an attempt to control own reactions in tense or difficult situations. Does best to set aside emotions and do what\'s appropriate.',
                med: 'Is generally able to call upon reason to help master own emotions and reactions, in order to behave appropriately in difficult situations.',
                high: 'Remains calm and composed in stressful or difficult situations. Demonstrates low levels of emotional reactivity or nervousness. May seem indifferent.'
            },
            {
                low: 'Gives sufficient attention to any requests made within the expected job description and role, seeking to satisfy any needs expressed.',
                med: 'Provide quality service to clients and seeks to sustain high satisfaction levels.',
                high: 'Listens attentively or observes carefully to understand client needs. Solves problems quickly and ensures satisfaction with results. Follows up when needed.'
            },
        ],
        fr: [
            {
                low: 'Possède assez de souplesse pour modifier ses priorités au besoin. Peut ressentir du stress devant l\'imprévu ou les changements subits. Préfère un environnement stable.',
                med: 'Fonctionne avec efficacité dans des environnements changeants et des circonstances imprévues, modifie ses priorités en accueillant le changement.',
                high: 'Fonctionne bien dans différents environnements ou des situations changeantes. Se relève rapidement dans la controverse et les difficultés. Démontre de la résilience et s\'adapte.'
            },
            {
                low: 'Manifeste assez de confiance pour prendre en main ses tâches courantes. Exprime son point de vue dans un environnement harmonieux. Préfère suivre les directives et les consignes.',
                med: 'Fait preuve d\'assurance et croit en ses capacités de surmonter les obstacles. Affirme son point de vue et défend une position.',
                high: 'Exprime son point de vue avec certitude. Conscient de sa valeur, émet, voire impose, ses opinions avec conviction. Énonce clairement ses attentes ou ses besoins.'
            },
            {
                low: 'Privilégie le court terme et les résultats rapides. Tente de démontrer de la patience face aux contrariétés et de reprendre le cours du travail.',
                med: 'Capable de supporter des désagréments et à démontrer de la constance dans ses démarches ou dans son travail malgré les dérangements.',
                high: 'Persiste et demeure constant dans toutes les étapes de son travail malgré les contrariétés. Démontre de la compréhension et de la tolérance envers ceux qui éprouvent des difficultés.'

            },
            {
                low: 'Peut suggérer de nouvelles façons de faire et leurs applications pratiques tout en s\'en tenant aux idées éprouvées. Participe au besoin à l\'amélioration des processus.',
                med: 'Capable d\'imaginer et inventer de nouvelles idées ou des solutions originales aux problèmes courants.',
                high: 'Fait preuve d\'audace, de curiosité et d\'originalité. Veut apporter des solutions novatrices. Ouvert aux remue-méninges et aux nouveaux concepts.'
            },
            {
                low: 'Arrive à maintenir un niveau de rendement convenable en situation stressante. Peut cependant se sentir bousculé par un stress constant ou une pression continue.',
                med: 'Maintient un niveau de rendement élevé malgré les situations difficiles, les contraintes de temps et le niveau de stress ambiant.',
                high: 'Démontre une capacité à canaliser toutes sources de stress. S\'affirme dans le changement et redirige son action. Ne se laisse pas envahir par les effets nocifs du stress. Se sent stimulé par les échéanciers serrés.'
            },
            {
                low: 'Peut alterner d\'une tâche à une autre, mais préfère en terminer une avant d\'en commencer une autre. Vit de la pression quand plusieurs tâches urgentes réclament son attention.',
                med: 'Peut exécuter plusieurs tâches de façon simultanée et passer rapidement d\'une à l\'autre, quitte à revenir en arrière pour terminer.',
                high: 'Exécute régulièrement plusieurs tâches de façon simultanée. Passe facilement d\'une tâche à l\'autre. S\'engage dans plusieurs projets à la fois. Stimulé par une certaine pression du temps.'
            },
            {
                low: 'Adopte une conduite raisonnable en tenant compte des principales règles en vigueur. Passe rapidement à l\'action et corrige ses erreurs en cours de route.',
                med: 'Adopte une conduite prudente et attentive, réfléchit aux conséquences de ses actes, évite les erreurs, et prévient tout dommage.',
                high: 'Manifeste une grande prudence en tout. Cherche à prévenir toute erreur en suivant les règles. Exerce une grande vigilance quant aux conséquences possibles de ses gestes.'
            },
            {
                low: 'Cherche à éviter les critiques ou les risques d\'échec. Peut se remettre en question et perdre confiance lors de revers ou de rejets.',
                med: 'Peut rebondir à la suite d\'échec, continue à fonctionner efficacement face à l\'adversité, la critique ou le rejet.',
                high: 'Capacité à s\'adapter à toutes circonstances et à se relever rapidement à la suite d\'un échec. Reprend ses activités et continue malgré les épreuves ou les mésaventures.'
            },
            {
                low: 'Confie des tâches faisant partie de ses responsabilités à des subordonnés ou des collaborateurs ayant fait leurs preuves, tout en faisant des suivis serrés.',
                med: 'Assigne les responsabilités et l\'autorité à des fonctions définies. Soumet au titulaire les activités et les problèmes pour la prise de décisions et les actions appropriées.',
                high: 'Confie facilement autorité et responsabilités. Détermine le pouvoir décisionnel en laissant de la liberté d\'action. S\'assure de faire des suivis d\'étape.'
            },
            {
                low: 'À l\'aise pour effectuer ses tâches lorsque tout n\'est pas structuré. A une attitude calme et est peu préoccupé par les échéanciers. S\'occupe des problèmes quand ils se présentent. S\'organise pour réaliser ses tâches correctement.',
                med: 'A de l\'ordre dans ses pensées. Ordonne rigoureusement son travail et structure efficacement les tâches à effectuer dans les délais prescrits.',
                high: 'Cherche à optimiser tous les aspects de son environnement. Anticipe les problèmes et établit les méthodes et les séquences du travail. Établit une structure et ne laisse rien au hasard. Respecte parfaitement les échéanciers.'
            },
            {
                low: 'Participe à la réalisation des projets. Exécute ses tâches et respecte les délais prévus tels que planifiés. Coordonne ses activités avec les autres et s\'assure de la concordance de ses travaux avec les objectifs.',
                med: 'Coordonne les activités selon le plan de projet, développe des mécanismes de suivi et les applique méthodiquement à chacune des étapes vers l\'atteinte des objectifs.',
                high: 'Capable de développer des plans de réalisation des projets avec des mécanismes de contrôle. Se soucie des détails et de l\'échéancier. Ne laisse rien au hasard tout en maintenant une vision d\'ensemble. Prend possession du projet.'
            },
            {
                low: 'Évalue les difficultés et les avantages du changement proposé avant de s\'engager. Prend part aux démarches de changement après mûre considération et appuie le mouvement.',
                med: 'Capacité à participer au changement et à l\'orienter vers l\'amélioration des méthodes de travail pour mieux atteindre des objectifs en évolution.',
                high: 'Participe ou prend l\'initiative du changement pour atteindre le niveau de rendement souhaité. Soutient l\'adoption ou assure la mise en place de stratégies pertinentes. Encourage les autres à adopter le changement.'
            },
            {
                low: 'Prend le temps de cerner les besoins ou les problèmes qui se présentent. Prend les mesures appropriées selon les politiques en cours. Cherche à prévenir les ennuis ou à éviter les obstacles.',
                med: 'Agit de façon autonome en anticipant les besoins, saisit les opportunités et prévenir les problèmes pour atteindre les objectifs.',
                high: 'N\'attend pas les instructions pour agir. A du flair pour saisir les occasions. Anticipe les besoins et passe rapidement à l\'action. Réagit positivement face aux obstacles.'
            },
            {
                low: 'Estime que le résultat seul ne peut être considéré comme étant l\'unique facteur de succès. Fait preuve d\'engagement, mais accorde aussi de l\'importance à l\'ambiance et au bien-être dans l\'atteinte des résultats.',
                med: 'Place l\'atteinte des résultats au-dessus de toute autre considération en investissant les ressources et les efforts nécessaires à l\'atteinte des objectifs.',
                high: 'Garde le cap avec détermination. Évalue les facteurs critiques de succès et en mesure l\'application. Place l\'atteinte des résultats au-dessus de toute autre considération. Va jusqu\'au bout.'
            },
            {
                low: 'Peut planifier et structurer certaines activités essentielles à l\'exécution de ses tâches ou d\'un projet. Prend note des échéances et suit les étapes de réalisation.',
                med: 'Développe, implante, évalue et modifie les plans en vue d\'atteindre les objectifs tout en assurant une bonne utilisation des ressources disponibles.',
                high: 'Capable de structurer et d\'organiser toutes les activités nécessaires à la réalisation des objectifs. Prévoit les ressources et évalue autant les coûts que les échéanciers dans une planification rigoureuse.'
            },
            {
                low: 'Observe ce qui pose un problème et les effets que cela entraîne. Privilégie une approche simple et des solutions concrètes pour corriger les effets négatifs. Recherche de l\'aide pour les problèmes complexes ou importants.',
                med: 'Possède une habileté à cerner les problèmes avec leurs causes et conséquences et proposer des solutions à partir de nouvelles perspectives.',
                high: 'Est stimulé par les problèmes complexes. Cherche toutes les causes possibles pour en comprendre les sources. Considère différentes solutions logiques avant de mettre en œuvre un plan d\'action novateur.'
            },
            {
                low: 'Confirme les objectifs et communique les enjeux à ses collaborateurs. Recherche le consensus sur les mesures à prendre et soutient ses collaborateurs dans l\'action.',
                med: 'Adopte une position de commandement, prend le contrôle de la situation en fixant les objectifs et en communiquant clairement les actions éventuelles.',
                high: 'Décide et établit les objectifs. Commande l\'action. Relève les défis. Communique les enjeux et la direction à prendre. Accorde sa confiance aux autres et les encourage.'
            },
            {
                low: 'Évalue la pertinence de l\'information selon le travail et les besoins des personnes. Formule le message en fonction de la situation et de l\'objectif.',
                med: 'Démontre beaucoup d\'aisance à présenter ses idées de façon dynamique dans un mode de communication ouvert à l\'échange et adapté tant à la compréhension des autres qu\'à la situation.',
                high: 'Favorise l\'échange et la communication avec les personnes de son entourage. Cherche à formuler l\'information afin de faciliter le travail des autres. Crée un climat de confiance.'
            },
            {
                low: 'S\'applique à écouter ce que les autres ont à dire, surtout quand le sujet l\'intéresse. S\'efforce de ne pas interrompre avant la fin. Pose des questions de clarification au besoin.',
                med: 'Permet aux autres de s\'exprimer librement en faisant preuve d\'attention, d\'ouverture et d\'écoute active.',
                high: 'Prête une grande attention aux sentiments et aux paroles sans porter de jugement. Laisse l\'autre s\'exprimer jusqu\'au bout pour s\'assurer de bien comprendre. Reformule pour confirmer sa compréhension.'
            },
            {
                low: 'S\'intéresse aux sentiments ou aux soucis des autres lorsque cela sert au bon déroulement du travail. S\'intéresse à leur point de vue sur des sujets d\'intérêt commun.',
                med: 'Comprend les sentiments, les préoccupations ou les réactions des autres en se mettant à leur place.',
                high: 'Fait preuve de beaucoup d\'intérêt à l\'égard du point de vue et des sentiments des autres. Reconnaît leurs préoccupations, réconforte au besoin. Conserve son objectivité.'
            },
            {
                low: 'Expose des idées et utilise des arguments factuels pour susciter l\'adhésion. Préfère s\'en tenir aux faits et aux analyses plutôt qu\'à son entregent. Évite les négociations.',
                med: 'Utilise autant ses qualités personnelles que des méthodes de communication, des faits et des arguments pour faire adhérer les autres à ses idées, à ses objectifs ou à ses façons de faire.',
                high: 'Aime capter l\'attention et apprécie la négociation. A de la facilité à influencer et à persuader les autres. Amène les gens à participer à son objectif.'
            },
            {
                low: 'Reste correct et poli dans la plupart des situations. Répond aux questions et fournit l\'information ou le service demandé.',
                med: 'Possède une disposition à aller au-devant des besoins d\'autrui, de façon aimable et polie. Démontre de la gentillesse.',
                high: 'Va au-devant des autres pour cerner leurs besoins ou leurs désirs et y répondre de façon aimable et polie. Évite les situations conflictuelles.'
            },
            {
                low: 'Prend contact avec les personnes qui l\'approchent. Cherche à créer des échanges positifs après avoir fait plus ample connaissance. Préfère s\'adresser à une personne à la fois plutôt que prendre la parole en public.',
                med: 'Entre spontanément en contact avec les gens et établit facilement des relations sociales amicales ou professionnelles avec une attitude cordiale.',
                high: 'Excelle à établir des contacts avec les autres et à maintenir une attitude positive et chaleureuse. Crée une perspective d\'échange et de respect mutuel. Aime prendre la parole en public.'
            },
            {
                low: 'Accepte que d\'autres privilégient des valeurs différentes des siennes. Permet aux autres d\'utiliser leur propre méthode tant que le résultat demeure conforme.',
                med: 'Admet que les autres démontrent une manière de penser ou d\'agir différente des siennes, accepte de fonctionner sans idées préconçues avec des gens ayant des valeurs différentes.',
                high: 'Grande capacité à accueillir les autres tels qu\'ils sont. Respecte et apprécie la richesse et la diversité des cultures et des modes d\'expression. Témoigne de la bienveillance et de la compréhension.'
            },
            {
                low: 'Exécute son travail selon ses priorités et coordonne ses activités avec les membres de son équipe. Répond positivement lorsque sa participation est sollicitée. Apporte sa contribution aux projets qui l\'intéressent.',
                med: 'Contribue activement à l\'atteinte d\'objectifs communs avec les membres d\'une équipe en restant solidaire face aux difficultés, tant comme leader que subordonné.',
                high: 'Très conscient que sa contribution est essentielle au succès de l\'équipe. Garde les objectifs en tête et motive les autres lors de contretemps. Cerne les obstacles et demeure loyal à l\'objectif commun.'
            },
            {
                low: 'Considère l\'ensemble des éléments constituant un tout et en apprécie la cohérence. Considère les données et établit les relations entre elles. Valide les informations et propose des conclusions.',
                med: 'Peut mettre en relief les éléments constitutifs d\'un problème ou d\'une situation, étudier la relation entre eux, vérifier les faits et en tirer une conclusion.',
                high: 'Désire avant tout approfondir le sujet. Cherche à comprendre le fonctionnement des choses ou d\'une situation. Se concentre et analyse les conséquences sur l\'ensemble. Rigoureux, émet ses conclusions lorsque tout a été validé deux fois plutôt qu\'une.'
            },
            {
                low: 'Accepte de fonctionner selon les politiques en vigueur et les usages de son milieu. Préserve une dose d\'indépendance et de liberté d\'action dans ses fonctions.',
                med: 'Adhère volontiers aux politiques de fonctionnement et se conforme aux normes et aux usages de son milieu.',
                high: 'Adopte rapidement les règles et les procédures recommandées. Grande capacité à respecter les normes règlementaires. Respecte la hiérarchie.'
            },
            {
                low: 'Fait occasionnellement des suivis d\'étape sur le déroulement des travaux ou des projets sous sa responsabilité. S\'assure que les choses se déroulent bien.',
                med: 'Fait preuve d\'attention au bon déroulement des activités et à l\'atteinte des résultats attendus, conformément au plan ou selon les normes.',
                high: 'Exerce un contrôle serré sur tous les travaux ou toutes les activités sous sa responsabilité. S\'assure que tout se fait à temps dans le respect absolu de toutes les normes.'
            },
            {
                low: 'Apprécie le fonctionnement efficace des choses ou des équipements en fonction de leur usage. Peut cerner les causes de malfonctionnement et faire appel au bon intervenant.',
                med: 'Démontre une capacité à appliquer concrètement des connaissances théoriques spécialisées dans des mécanismes fonctionnels et efficaces.',
                high: 'Cherche à comprendre le fonctionnement des mécanismes ou des choses. Capable de rétablir les fonctionnalités lors de bris. Applique concrètement des notions théoriques.'
            },
            {
                low: 'Varie le rythme de ses interventions ou de ses activités en fonction des priorités. Répond rapidement aux demandes urgentes. S\'efforce de revenir à l\'objectif malgré les distractions.',
                med: 'Peut travailler de façon constante et déterminée en s\'investissant dans la tâche sans perdre l\'objectif de vue.',
                high: 'Adopte une vision à long terme. Va au bout des choses. Fait preuve de fermeté. Se montre tenace et résolu, jusqu\'à l\'entêtement. Apporte de la stabilité à un projet.'
            },
            {
                low: 'Tient compte des procédures prescrites dans ses activités mais adopte ses propres méthodes de travail pour bien atteindre l\'objectif. Pose des jalons pour valider la progression et la qualité du travail.',
                med: 'Établit des procédures strictes, respecte les méthodes de travail et contrôle l\'exécution afin de garantir un résultat de qualité ou l\'atteinte précise de l\'objectif.',
                high: 'Établit des procédures strictes. Attache une grande importance au déroulement du processus et au respect des normes. Contrôle l\'exécution. Assure une constance assidue dans le contrôle de la qualité.'
            },
            {
                low: 'Travaille en fonction des objectifs en suivant les grandes lignes. Peut prendre des libertés ou faire des compromis. Accepte les responsabilités qui lui incombent.',
                med: 'Endosse les responsabilités qu\'on lui confie.  Se dédie à ses tâches et assume les conséquences de ses décisions en inspirant confiance aux autres. Donne le bon exemple.',
                high: 'Endosse les responsabilités qui lui sont dévolues avec un grand sens du devoir. Se dédie totalement avec discipline et sérieux. Assume ses décisions ou ses erreurs. Se veut un modèle pour les autres.'
            },
            {
                low: 'Prête attention aux détails importants. S\'assure personnellement que la présentation et l\'ensemble du travail conviennent globalement aux attentes de qualité et de quantité.',
                med: 'Est  capable d\'assurer une vérification systématique du travail et de porter une attention particulière à la précision des détails qui constituent la qualité de l\'ensemble.',
                high: 'Tient à s\'assurer personnellement de l\'exactitude et de la minutie accordées tant au processus qu\'aux détails. Maintient toute son attention pour livrer un produit impeccable. Revérifie tout soigneusement avant de terminer.'
            },
            {
                low: 'Valide la clarté et la faisabilité des objectifs qui lui sont communiqués. Trace les grandes lignes d\'un plan d\'action à faire ratifier. Passe à l\'action sans toujours établir des étapes ou des échéanciers réalistes.',
                med: 'Peut définir clairement des buts réalistes et valables dont la réalisation peut être mesurée selon des étapes bien établies.',
                high: 'Fixe et articule clairement des objectifs spécifiques et réalistes. Établit un plan d\'action, les étapes de réalisation et les unités de mesure pour évaluer le résultat.'
            },
            {
                low: 'Évalue prudemment les risques face à l\'inconnu. A suffisamment confiance en ses capacités pour s\'aventurer un peu en dehors de sa zone de confort tout en restant vigilant.',
                med: 'Est capable de s\'aventurer au-delà de sa zone de confort personnel ou professionnel en se fiant à ses capacités et à son potentiel.',
                high: 'Démontre une grande confiance en ses capacités face à l\'inconnu. Aime explorer de nouvelles avenues et s\'y aventurer, quitte à se reprendre à la suite d\'un échec. Capable d\'envisager des projets audacieux.'
            },
            {
                low: 'Différencie les éléments distinctifs d\'un ensemble selon leur nature. Établit les relations entre les composantes pour les rassembler en un tout cohérent.',
                med: 'Possède une capacité à rassembler des éléments disparates pour en constituer un tout cohérent, une vue d\'ensemble pouvant offrir une nouvelle perspective.',
                high: 'Capacité à fusionner un grand nombre d\'informations. Évite le piège des détails et résume en peu de mots. Donne un avant-goût des nouveautés ou des changements possibles en apportant une vision d\'ensemble.'
            },
            {
                low: 'Évalue soigneusement la situation et ses enjeux. Réfléchit à la meilleure décision à prendre. Fait des recommandations en ce sens. Obtient de l\'appui avant de passer à l\'action.',
                med: 'Peut prendre des décisions  en évaluant la situation avec logique, s\'engager en faisant preuve de jugement et agir rapidement malgré les risques.',
                high: 'Très à l\'aise dans la prise de décisions risquées. Tient compte des pour et des contre selon un raisonnement logique. Déterminé, passe à l\'action.'
            },
            {
                low: 'Cherche à connaître l\'opinion des autres avant de se faire une idée sur un sujet. Reste authentique dans ses opinions tout en désirant être approuvé par les autres quant à ses conclusions. Respecte les normes en vigueur.',
                med: 'Fonctionne selon ses propres normes en étant peu influencé par l\'opinion des autres ni en espérant de l\'aide ou de l\'appui.',
                high: 'Prend connaissance d\'une situation ou évalue les faits avant de se faire une idée. Agit à sa guise selon ses observations et établit ses propres normes de fonctionnement. Demeure authentique dans ses opinions. Persiste dans son point de vue.'
            },
            {
                low: 'Rassemble les idées utiles et cherche à rendre concrets les concepts abstraits. Simplifie les notions complexes et échange des idées pouvant être appliquées dans le quotidien.',
                med: 'Peut organiser mentalement des notions abstraites. Concevoir, développer ou enchaîner des idées dans un tout cohérent correspondant à un objectif à réaliser.',
                high: 'Traite souvent des notions abstraites pour les développer en projets. Saisit rapidement les concepts et les idées pour en produire des plans d\'action d\'envergure.'
            },
            {
                low: 'Se préoccupe de l\'efficacité des opérations quotidiennes selon sa vision des objectifs. S\'assure que les activités sont conformes aux stratégies mises en avant dans le plan d\'action.',
                med: 'Capable de choisr et de projeter des orientations claires basées sur des scénarios logiques élaborés à partir de recherches sérieuses et de renseignements fiables.',
                high: 'Possède ou développe une vision très claire, à la fois intuitive et rationnelle, des orientations privilégiées selon des recherches pertinentes. Propose ou impose un cadre cohérent et convergent pour la planification et l\'exécution des travaux.'
            },
            {
                low: 'Répond volontiers aux demandes d\'aide dans la mesure de sa disponibilité et de ses responsabilités.',
                med: 'Est disposé à toujours rendre service sur demande et avec gentillesse, malgré les contraintes.',
                high: 'Reste à l\'affût des occasions d\'offrir son aide aux gens de son entourage sans attendre qu\'on lui demande, sans s\'imposer.'
            },
            {
                low: 'Cherche à terminer une tâche sans se mettre de pression indue ni trop en mettre sur les autres. Évalue le temps requis et mesure son rendement, ou celui des autres, en conséquence.',
                med: 'Est capable de réagir rapidement aux sollicitations, à commencer les tâches rapidement et à les terminer au plus vite.',
                high: 'Réagit vivement aux stimuli de l\'environnement et passe à l\'action rapidement. Peut faire pression pour obtenir un résultat au plus tôt. Pressé de terminer une chose pour passer à d\'autres.'
            },
            {
                low: 'Prête attention aux besoins qui lui sont exprimés et cherche à y répondre dans la mesure de ses capacités. Peut  manifester de la sympathie aux gens qui lui sont proches ou qui se confient.',
                med: 'Prête volontiers attention aux autres en faisant preuve d\'une capacité à comprendre leurs besoins  et à y répondre avec sympathie ou compassion.',
                high: 'Possède une sensibilité lui permettant une perception intuitive des personnes de son entourage. Perçoit facilement les sentiments ou les émotions. Manifeste spontanément sympathie et compassion.'
            },
            {
                low: 'Cherche à expliquer de façon logique des sentiments ou des perceptions qui se révèlent parfois exacts et opportuns. Fait habituellement appel à la raison mais se fie parfois aussi à son ressenti.',
                med: 'Démontre de l\'habileté à sentir ou à deviner les choses, faire preuve d\'un savoir instinctif, sans l\'aide de son raisonnement.',
                high: 'Démontre souvent une habileté à faire ce qui convient malgré l\'absence de points de références concrets. Peut déceler instinctivement les causes ou prévoir les effets. Fait confiance à ses feelings.'
            },
            {
                low: 'Fait appel à la raison pour tenter de maîtriser ses réactions lors de situations difficiles ou tendues. Cherche à faire ce qui convient malgré ses sentiments. ',
                med: 'Est capable de maîtriser ses émotions et ses réactions en faisant appel à la raison. Se comporte de façon appropriée dans les situations difficiles.',
                high: 'Démontre du sang-froid et reste calme  dans les situations stressantes ou difficiles. Manifeste peu de réactivité émotionnelle ou de nervosité. Peut paraître indifférent.'
            },
            {
                low: 'Prête attention aux requêtes qui lui sont faites dans le cadre de ses fonctions et et cherche à satisfaire les besoins exprimés.',
                med: 'Offre un service de qualité à la hauteur des besoins des clients et maintient un haut niveau de satisfaction à long terme.',
                high: 'Fait preuve d\'écoute ou d\'observation pour bien identifier les besoins des clients. Règle les problèmes rapidement et s\'assure de leur satisfaction. Effectue fréquemment des suivis.'
            }
        ]
    };

    interviewQuestions = {
        en: [
            [
                'Please tell us about the last time you had to deal with an unexpected curveball?',
                'Please tell us about a situation in which you had to change your priorities or planning due to a decision made by your superiors. How did you feel about this? Did you object or push back?',
                'How do you deal with constant change in a work environment?'
            ],
            [
                'Please discuss the last time one of your ideas or action plans were challenged. How did you react?',
                'Please talk about a time when you had to overcome an obstacle or problem?',
                'Please describe a bold or ambitious action that you undertook.'
            ],
            [
                'One often faces situations that delay or jeopardize a project. Have you been through a situation in which you did not lose sight of the key objective and showed determination and patience when faced with delays or setbacks? ',
                'Do you prefer dealing with several tasks promptly or in quick succession, or undertaking work that is medium or long term in length? Why?',
                'Please discuss the last time you were unable to meet your goals due to interferences or a lack of perseverance ?'
            ],
            [
                'Please tell us about an innovative solution that you put together?',
                'Please give an example of a unique or innovative solution that you implemented to solve a work-related problem?  ',
                'Please give an example of an idea or innovation that you conceived but were unable to develop because you weren\'t given permission to act on or execute it. Why did this happen? How did you react?'
            ],
            [
                'Please describe the most stressful work situation that you have been through. How did you cope with it?',
                'How do you leverage stress to reach your objectives? ',
                'Please tell us about a time you had trouble dealing with stress and its effect was detrimental on you.'
            ],
            [
                'Are you stimulated or turned off by a job that requires a large number or a wide variety of different tasks to be performed at the same time?',
                'Please discuss the last time you had to execute several tasks simultaneously. How many/Which tasks did you have to perform? Could you operate at the same pace for an extended period of time?',
                'When you have to perform several tasks simultaneously, what do you do to ensure nothing remains pending or "falls through the cracks"? '
            ],
            [
                'Please discuss the last time your cautiousness or risk-averse nature enabled you to avoid mistakes and prevent losses.',
                'How would you plan to mitigate potential damages or losses that may result from a poorly-conceived processes, behaviours or bad decisions?',
                'By nature, are you cautious or more of a risk taker? Give an example to explain why. '
            ],
            [
                'Please discuss the last time you had to "roll up your sleeves" and bounce back from a setback or failure.',
                'What is the most difficult situation you have experienced at work or during your career?',
                'What type of professional event or situation might you have trouble getting through or getting over? ',
            ],
            [
                'Please tell us about a situation in which you had to entrust another person to complete part of a project. What steps did you take to ensure the work performed was of high quality?  ',
                'What are the advantages and challenges involved in delegating responsibilities or decision-making authority?  ',
                'Please discuss a time when delegating did not work well or when you had an employee to whom it was difficult to delegate a new responsibility. What were the underlying reasons for the problem?',
            ],
            [
                'Please give us an example of something, such as a project, activity or trip, that you organized. What steps did you take or what methods did you use?  ',
                'How do you decide if a task is really important or merely urgent? How do you prioritize your work?',
                'What would you do if you had to manage a large-scale project on a very tight deadline? What actions would you take to ensure the project stayed on schedule and all deadlines were met?  '
            ],
            [
                'Please give us an example of a project you managed.',
                'Please talk about any obstacles or difficulties that you have come across. How did you get past them?',
                'When given a choice, do you prefer to develop your own plan and organize your own tasks to build a project or do you prefer to manage a project that is already developed and organized by one of your superiors? '
            ],
            [
                'Please tell us about an important change or decision that you had to accept or support even though you were not in total agreement with it. ',
                'At your work, please describe the most recent organizational change and how you participated in it.',
                'In the case of organizational change, how might you mitigate or overcome potential opposition or criticism from coworkers or subordinates. '
            ],
            [
                'Please explain what the word "proactive" means to you and describe a situation in which you were/that shows you can be proactive.',
                'Please tell us about an initiative you took in developing a project that had a positive impact on your department or company.',
                'Would you talk about a time when you volunteered to manage a project or deal with a problem? '
            ],
            [
                'Tell us how you feel about the proverb "The end justifies the means"? ',
                'Of what personal accomplishment are you most proud? Why?',
                'Would you please give details about a situation in which you invested a great deal of time and energy and went above-and-beyond to obtain a targeted result? '
            ],
            [
                'Please tell us about the last time you had to coordinate a project or task from start to finish (plan the schedule, stages/steps and actions) to complete it.',
                'Please give us an example of a situation in which you predicted or foresaw a potential problem – and how you used good project planning to mitigate any fallout. ',
                'When working on a project, what methods do you use to ensure rigorous monitoring of progress, schedules and allocated resources?'
            ],
            [
                'Tell us about a difficult situation that you resolved. How did you proceed?',
                'What type of problem is most difficult for you to solve or address? Technical? Political? Interpersonal? Or other?',
                'How do you react when faced with a problem for which there is no apparent solution? From what angle do you then proceed?   '
            ],
            [
                'How would you describe your leadership style? Which of the following words best describes your style: strategic, charismatic, operational, or democratic?',
                'How would you assert your supervisory authority after being promoted to a new managerial or executive position? ',
                'As a manager, how do you get the most out of your employees? Please describe a recent example of how you successfully accomplished this.'
            ],
            [
                'What words would your coworkers use to describe your communications style?',
                'Explain how you adapt communications to the needs of your audience and adapt the tone of the message you wish to convey?',
                'Do you prefer to communicate orally or in writing? Which way is most effective from your perspective?'
            ],
            [
                'Please tell us about a situation in which your failure to listen to people had adverse consequences?',
                'In your opinion, what are the distinguishing characteristics behind "good listening skills"? ',
                'In what type of situations do you have difficulty giving your complete attention to a coworker or counterpart?'
            ],
            [
                'Would you tell us about the last time someone confided in you? How did you react? How did you provide support?',
                'What approaches do you take in order to identify people\'s interests and concerns and respond to their needs?',
                'Please describe a time when you were able to resolve or diffuse a difficult situation by identifying or empathizing with someone else\'s point of view.   '
            ],
            [
                'Please talk about the last time you successfully convinced others that your opinion was well-founded and had merit. What key difficulties or objections did you have to overcome? ',
                'In your opinion, what elements would be needed to influence or persuade someone of something?',
                'Please tell us about a situation in which you managed to convince others to do something that they had initially opposed. '
            ],
            [
                'How do you identify what someone else needs or wants before he or she has a chance to express these feelings?',
                'Please explain what is meant by the expression "anticipating others\' needs" and give a concrete example of how you are capable of doing it.',
                'In terms of interpersonal relationships, what importance do you give to behaviours such as friendliness, kindness and consideration for others\' needs?'
            ],
            [
                'Please tell us about the last time you were at a gathering where you did not know anyone. How did you feel?',
                'How do you "break the ice" and establish personal or professional relationships with strangers at a social event?',
                'Have you ever spoken or made a formal presentation in public? Please tell us about your experience. '
            ],
            [
                'Can you describe in a situation in which your tolerance of others was severely tested? ',
                'Please describe a work situation in which you had to show  tolerance toward a coworker or client in order to successfully complete your mandate.',
                'When you work closely with others, do you readily adopt their work methods or do you prefer to impose your methods on them? Please give an example.'
            ],
            [
                'In your opinion, what are the key factors that help a team meet its goals? ',
                'Please tell us about the main challenges you faced when you last worked as a member of a team on a project. ',
                'When working on a project, how do you update your colleagues, managers or clients on progress made to date?   '
            ],
            [
                'What method do you use to ensure you have all the information needed to assess a situation from every angle? Please elaborate by citing an example of your method in action.',
                'When analyzing a situation, how do you verify or validate your hypotheses? Please discuss the last time you did this.',
                'Please discuss a situation in which your analysis led to a faulty or flawed conclusion. In hindsight, what would you have done differently?'
            ],
            [
                'Do you always comply with authority or existing hierarchies? What kind of situation might justify non-compliance? ',
                'Can you describe a situation in which you realized that regulations, standards or operating rules were not being followed? How did you react?',
                'Please discuss a situation in which you had to conform to a work-related policy or standard that you considered unreasonable. '
            ],
            [
                'What tools or methods do you use to follow up on ongoing files or work in progress? ',
                'When monitoring or following up on a file, what are the key elements to keep in mind? What would indicate to you that certain factors need special attention? ',
                'Are you at ease with a superior who closely monitors important and time-sensitive tasks which come under your responsibility?'
            ],
            [
                'Give some examples of how you have used your technical skills or specialized knowledge to assist your colleagues or coworkers.',
                'How do you react when a machine or piece of equipment doesn\'t work properly? Do you try to fix it yourself or do you seek help from a specialist?',
                'Please describe a situation in which you resolved a technical problem using your knowledge of theoretical concepts and principles.'
            ],
            [
                'Could you talk about the last time your determination or staying power was tested. On what project did this occur and what were the primary difficulties you had to overcome?',
                'Please discuss a situation in which you had to go to great lengths to meet objectives.',
                'Please tell me about a situation in which your tenacity produced positive results?'
            ],
            [
                'What methods or tools do you employ to ensure the quality of your own work?',
                'How do you ensure that your work is very closely aligned with job expectations or work-related guidelines, norms and standards?',
                'When overseeing a project, what methods do you employ to ensure progress is being made, schedules are being respected and resources are being allocated efficiently?'
            ],
            [
                'Please talk about a time when you had to make a difficult choice between your professional life and personal life.',
                'Everyone, at some point in his or her career, makes a mistake or bad decision. Would you please tell us about a decision you made that had unfortunate consequences. How did you handle the ensuing consequences?',
                'Please give us an example of when you summoned the courage to make an unpopular decision as part of your job responsibilities. '
            ],
            [
                'To what degree do you pay attention to detail in your daily work?',
                'Please describe a situation in which your attention to detail enabled you to detect an error that, if left undiscovered, could have had serious ramifications.  ',
                'When performing a task, what steps do you take to ensure no important details are overlooked?',
            ],
            [
                ' What criteria would you use to assess the soundness of an objective? (SMART)',
                'When you set an objective, how do you ensure it is relevant, realistic and measurable?',
                'How do you ensure long term objectives will be attained?'
            ],
            [
                'Please tell us about the last time you found yourself in a risky or unpredictable situation?',
                'Please tell us about a high-risk decision you had to make? ',
                'Please discuss a decision you made, knowing it would be hotly contested.'
            ],
            [
                'How do you go about collecting random elements and synthesizing them into a bigger picture? Please give an example based on your own experience.',
                'How do you explain a complex subject in simple terms to someone with little background knowledge or subject matter expertise.',
                'What do you do to gain or seek out a new perspective or outlook on a recurring problem?'
            ],
            [
                'Please talk about how you make decisions. Which of the following words best reflects your decision making process: consultative, expeditious, well-pondered, commanding, indecisive, consensual?',
                'Please discuss the most difficult decision you have ever had to make. Why was it so difficult to decide?',
                'Please discuss a situation in which your coworkers or colleagues participated in your decision making process. How did this occur and what was the result?'
            ],
            [
                'When you are convinced of something, how do you react when someone tries to change your mind?',
                'Describe a job or project which you successfully achieved despite being the only one to believe in it.',
                'Please discuss a situation in which you had to accept a decision that was contrary to your own opinion or conclusions. How did you react?'
            ],
            [
                'If I were to say "increase in profitability", could you – in a few minutes – identify the major challenges that would have to be addressed, and outline a plan of action to increase profitability?',
                'Please tell us about the last time you developed or designed a solution. How did you confirm or verify your ideas? By what means did you communicate them to coworkers? ',
                'Please tell us about the most recent time you had to use abstraction or theory to explain an idea or concept in order to achieve an objective or solve a problem.'
            ],
            [
                'What future do you see for this company and how can you contribute to that future eventuating?',
                'When preparing to meet objectives, what elements and scenarios do you consider in order to do so?',
                'Please discuss a time when you came up with a suggestion or proposed a plan to achieve a specific company objective? '
            ],
            [
                'Talk about a time when an overwhelmed coworker came to ask for your help while you were on a very tight deadline to deliver something. How did you manage the situation?',
                'Please recount an event where -  due to time pressures or other restrictions -  you were unable to help out a person who was truly in need of your assistance. How did you feel about it?',
                'Overall, do you find your coworkers to be equally as helpful, more helpful or less helpful than you generally are (towards each other, towards you)? Provide examples to explain.'
            ],
            [
                'Describe a time where you were called upon for an urgent project with an incredibly short deadline. Give details on the type of project, how you responded to the time pressures and describe the end result?',
                'Talk a little about a time when an immedaite supervisor assigned you an urgent task while you were already overloaded on other high-priority tasks. How did you feel about it and react to the request?',
                'When one of your urgent projects is being held up by other people who haven\'t delivered their part of the job, how do you tend to react? 1- You suffer in silence? 2- You talk to a superior? 3- You keep on calling to put the pressure on?'
            ],
            [
                'Provide an example of a situation where you showed empathy or sympathy for someone in the workplace.',
                'Tell us about a time when your concern for your teammates helped improve the overall work climate.',
                'Provide an example of when you\'ve witnessed a coworker being treated unfairly by other coworkers or a superior. How did you react?'
            ],
            [
                'Provide an example of a situation where you based a decision purely on intuition. How did it work out? In hindsight, would it have been better to have acted differently?',
                'In situations with no clear precedent to rely on, what do you do? 1- Wait for things to fall into place? 2- Go with your intuition? 3- Pass the problem on to someone else? ',
                'When answering multiple choice questionaires and you have no clue what to respond : 1- You skip the question and come back later? 2- You hazard a random guess? 3- You try to feel it or go with a hunch!'
            ],
            [
                'How do you react when you\'ve been thrust into the middle of an animated discussion or a difficult situation where everyone\'s bombarding you?',
                'Describe a time when you needed specific information from a particular individual in order to complete a job, only they seemed determined not to help you. How did you react?',
                'Describe a time when you\'ve gotten negative feedback from an employer, a coworker or client. How did you respond to it? Did anything productive come of it?'
            ],
            [
                'What does customer service mean to you? ',
                'Describe a time when a client was not truly satisfied. How did you approach it and what ended up happening?',
                'When handling many clients, it can prove hard to provide everyone with exceptional service. How do you go about prioritizing client needs?'
            ]
        ],
        fr: [
            [
                'Parlez-nous de la dernière fois où vous vous êtes heurté à un imprévu.',
                'Parlez-nous d\'une situation où vous avez dû modifier vos priorités ou votre plan à la suite de changements imposés par la direction. Comment vous êtes-vous senti? Avez-vous protesté?',
                'Comment réagissez-vous dans un environnement de travail en continuel changement?'
            ],
            [
                'Parlez-nous de la dernière fois que vos idées ou plans d’action ont été contestés. Comment avez-vous réagi?',
                'Parlez-nous d’une fois où vous avez dû surmonter un obstacle.',
                'Parlez-nous d’une démarche audacieuse que vous avez entreprise.'
            ],
            [
                'Nous sommes souvent confrontés à des situations qui retardent ou compromettent un projet. Pouvez-vous nous parler d’une situation que vous avez vécue qui démontre que vous avez la capacité de ne pas perdre de vue l’objectif et de faire preuve de constance ou de patience?',
                'Préférez-vous traiter plusieurs tâches rapidement ou vous engager dans des travaux à moyen ou long terme? Pourquoi?',
                'Parlez-nous de la dernière fois que vous n’avez pas atteint les objectifs fixés à cause d\'interférences ou d\'un manque de persévérance.'
            ],
            [
                'Parlez-nous d’une mesure ou d’une solution innovatrice que vous avez mise sur pied.',
                'Donnez-nous un exemple d\'une solution novatrice et unique que vous avez mise en œuvre pour régler un problème rencontré dans votre travail.',
                'Donnez-nous un exemple d\'une idée ou d\'une innovation que vous n\'avez pas pu développer pleinement ou qu\'on ne vous a pas permis d\'exécuter.'
            ],
            [
                'Parlez-nous de la situation de travail la plus stressante que vous avez vécue. Comment avez-vous fait pour passer au travers?',
                'Comment le stress vous aide-t-il à atteindre vos objectifs?',
                'Parlez-nous d’une situation où le stress a été néfaste pour vous.'
            ],
            [
                'Êtes-vous stimulé ou rebuté par une fonction qui demande un grand nombre ou une grande variété de tâches à traiter en parallèle?',
                'Parlez-nous de la dernière fois où vous avez exécuté plusieurs tâches simultanément. Quelles étaient les tâches? Combien de tâches exécutiez-vous en même temps? Et est-ce que c’est un rythme que vous pouvez tenir pendant une longue période?',
                'Lorsque vous devez exécuter plusieurs tâches en même temps, comment faites-vous pour vous assurer que rien ne reste en suspend ou ne « tombe entre deux chaises »?'
            ],
            [ // 7
                'Parlez-nous de la dernière fois où votre esprit prudent a permis d’éviter des erreurs ou de prévenir des dommages.',
                'Comment faites-vous pour anticiper les dommages ou les pertes qui pourraient résulter d’une mauvaise action ou d\'une mauvaise décision?',
                'Êtes-vous plutôt du type à prendre des risques ou à faire preuve de prudence? Expliquez par un exemple concret.'
            ],  // 8
            [
                'Parlez-nous de la dernière fois où vous avez dû vous relever les manches à la suite d\'un échec.',
                'Quelle a été la situation la plus difficile que vous ayez eu à vivre au travail ou dans votre vie professionnelle?',
                'Quel type d\'évènement professionnel serait pour vous le plus difficile à vivre et à surmonter?'
            ],
            [
                'Parlez-nous d’une situation ou vous avez dû confier à une autre personne la réalisation d’une partie d’un projet. Comment avez-vous procédé pour assurer la qualité de son travail?',
                'Quels sont, selon vous, les avantages et les difficultés à déléguer des responsabilités et de l\'autorité?',
                'Parlez-nous d\'une délégation qui n\'a pas fonctionné ou d\'un employé à qui il a été difficile de confier une nouvelle responsabilité. Quelles en étaient les causes?'
            ],
            [
                'Donnez-nous un exemple où vous avez organisé quelque chose (un projet, une activité, un voyage). Quelles méthodologies ou démarches avez-vous utilisées?',
                'Comment faites-vous pour déterminer si une tâche est réellement importante ou simplement urgente? Comment priorisez-vous?',
                'Que feriez-vous si vous étiez chargé de gérer un projet d’envergure en respectant un échéancier très serré? Quelles mesures prendriez-vous pour veiller à respecter toutes les échéances?'
            ],
            [
                'Donnez-nous un exemple d\'un projet que vous avez dirigé.',
                'Parlez-nous des barrières ou des obstacles que vous avez rencontrés. Comment les avez-vous surmontés?',
                'Préférez-vous développer vous-même le plan et l\'organisation des activités ou gérer un projet déjà organisé par une autorité supérieure?'
            ],
            [
                'Parlez-nous d\'un changement important auquel vous avez dû vous « soumettre » (un changement décidé par une autre personne).',
                'Décrivez-nous le dernier changement organisationnel que vous avez vécu et indiquez-nous comment vous y avez participé.',
                'Comment vous y prenez-vous pour prévenir ou surmonter les résistances au changement manifestées par des collègues ou subordonnés?'
            ],
            [
                'Expliquez-nous ce qu’est pour vous la proactivité et démontrez-nous par un fait que vous avez vécu que vous êtes capable d’en faire preuve.',
                'Parlez-nous d\'un projet que vous avez développé de votre propre initiative et qui a fait une différence dans votre service ou dans l\'entreprise.',
                'Parlez-nous d\'un moment où vous vous êtes porté volontaire pour prendre un dossier en main ou vous occuper d\'un problème.'
            ],
            [
                'Commentez ce proverbe « La fin justifie les moyens ».',
                'Parlez-nous de la réussite dont vous êtes le plus fier et pourquoi?',
                'Parlez-nous d\'une situation où vous avez dû vous investir à fond et même vous dépasser pour atteindre le résultat escompté.'
            ],
            [
                'Parlez-nous de la dernière fois où vous avez dû planifier le temps, les étapes et les activités nécessaires pour réaliser une tâche ou un projet.',
                'Donnez-nous un exemple d’une situation où vous aviez anticipé un problème que vous avez surmonté grâce à une bonne planification.',
                'Quelle méthode utilisez-vous pour contrôler de façon rigoureuse le progrès, les échéanciers et les ressources utilisées?'
            ],
            [
                'Parlez-nous d’une situation problématique que vous avez résolue. Comment avez-vous procédé?',
                'Quel type de problème vous cause le plus de difficulté à résoudre? Technique, politique, relationnel, etc.?',
                'Comment vous sentez-vous quand on vous soumet un problème en apparence insoluble? Par quel angle l\'attaquez-vous?'
            ],
            [
                'Décrivez-nous votre style de leadership? Parmi les styles de leadership suivants : Stratégique, Relationnel, Opérationnel ou Démocratique, lequel correspond le plus à votre style?',
                'Comment vous y prendriez-vous pour adopter efficacement une position de commande dans un nouveau poste de direction?',
                'En tant que manager, comment obtenez-vous le maximum de vos employés? Donnez-nous un exemple récent.'
            ],
            [
                'En quels mots vos collaborateurs décriraient-ils votre style de communication?',
                'Comment vous y prenez-vous pour adapter votre communication en fonction des besoins de l\'interlocuteur et de la nature du message?',
                'Préférez-vous communiquer verbalement ou par écrit? Quel mode est le plus efficace selon vous?'
            ],
            [
                'Parlez-nous d\'une situation où votre manque d\'écoute a eu des conséquences fâcheuses. Quelles en étaient les causes?',
                ' Quelles sont, d\'après vous, les caractéristiques d\'une bonne écoute?',
                'Dans quel type de situation trouvez-vous le plus difficile de prêter entièrement attention à votre interlocuteur?'
            ],
            [
                'Parlez-nous de la dernière fois que quelqu’un s’est confié à vous. Comment l’avez-vous soutenu?',
                'Quelles approches utilisez-vous pour bien cerner les préoccupations des autres et répondre à leurs besoins?',
                'Parlez-nous d\'une occasion où vous avez pu dénouer une situation difficile en vous plaçant du point de vue de l\'autre.'
            ],
            [
                'Parlez-nous de la dernière fois où vous avez réussi à convaincre une personne ou un groupe du bien fondé de votre opinion. Quels ont été les principaux obstacles ou les objections les plus difficiles à surmonter en cette occasion?',
                'Selon vous, quels sont les éléments clés qui permettent d’influencer ou de persuader autrui?',
                'Parlez-nous d’une situation où vous avez réussi à convaincre d’autres personnes de faire quelque chose à laquelle elles s\'opposaient au départ.'
            ],
            [
                'Comment faites-vous pour cerner les besoins ou les désirs d’autrui avant que ceux-ci ne les expriment?',
                'Expliquez-nous ce qu’est pour vous la prévenance et démontrez-nous par un exemple concret que vous êtes capable d’en faire preuve?',
                'Quelle place occupent l’amabilité et la prévenance dans vos relations avec les autres?'
            ],
            [
                'Parlez-nous de la dernière fois où vous vous êtes retrouvé dans une réunion où vous ne connaissiez personne. Comment vous sentiez-vous?',
                'Comment vous y prenez-vous pour établir rapidement des relations professionnelles ou amicales avec des inconnus dans une situation sociale?',
                'Avez-vous déjà pris la parole en public? Parlez-nous des circonstances.'
            ],
            [
                'Parlez-nous d’une situation où votre tolérance a été mise à rude épreuve.',
                'Décrivez une expérience que vous avez vécue au travail où vous avez dû faire preuve de tolérance envers un collègue ou un client afin de mener à bien un mandat.',
                'Lorsque vous travaillez avec d\'autres, acceptez-vous facilement leurs méthodes ou préférez-vous imposer les vôtres? Donnez-nous un exemple.'
            ],
            [
                'Selon vous quels sont les éléments essentiels qui permettent à une équipe d’atteindre les résultats?',
                'Parlez-nous des principaux défis que vous avez rencontrés lors du dernier travail d’équipe que vous avez accompli.',
                'Comment faites-vous pour tenir vos collègues, managers ou clients informés de l’avancement du projet?'
            ],
            [
                'Quelle méthode utilisez-vous pour vous assurer d’avoir toutes les données essentielles afin d’analyser une situation sous tous ses angles? Donnez-nous un exemple concret.',
                'Lorsque vous devez analyser une situation, comment faites-vous pour valider vos hypothèses? Parlez-nous de la dernière fois où vous avez eu à le faire.',
                'Parlez-nous d\'une situation où votre analyse vous a mené à une conclusion erronée. Qu\'auriez-vous dû faire différemment?'
            ],
            [
                'Est-ce que vous vous conformez toujours à l\'autorité ou à la hiérarchie en place? Quel type de situation justifierait de ne pas le faire?',
                'Décrivez une circonstance où vous avez été témoin d’une situation qui allait à l’encontre des règles en vigueur. Comment avez-vous réagi?',
                'Donnez-nous un exemple d’une situation où vous avez dû vous conformer à une politique ou à une norme qui vous paraissait excessive.'
            ],
            [
                'Quels sont les outils ou la méthode que vous utilisez pour assurer le suivi de vos dossiers?',
                'Selon vous, quels sont les éléments importants à contrôler pour suivre un dossier de près? Quels éléments vous indiquent que vous devez porter une attention plus particulière à certains aspects?',
                'Êtes-vous à l\'aise avec un supérieur qui exerce un contrôle assidu sur des tâches importantes et urgentes dont vous avez la responsabilité?'
            ],
            [
                'Donnez-nous quelques exemples où vous avez pu dépanner des collègues grâce à votre esprit technique.',
                'Lorsqu\'un appareil fonctionne mal, comment réagissez-vous? Voyez-vous ça comme un défi à le réparer vous-même ou faites-vous appel à un spécialiste?',
                'Donnez-nous un exemple de la résolution d\'un problème technique à partir de principes théoriques faisant partie de vos connaissances.'
            ],
            [
                'Parlez-nous de la dernière fois où vous avez dû faire preuve de persévérance. Quel était le projet et quels étaient les principaux obstacles?',
                'Parlez-nous d’une situation où vous avez dû redoubler d’efforts pour atteindre les objectifs.',
                'Parlez-nous d\'une situation où votre persévérance a été très bénéfique.'
            ],
            [
                'Quels sont les méthodes ou les outils que vous utilisez pour assurer la qualité de votre travail?',
                'Comment vous y prenez-vous pour vous assurer que votre travail corresponde rigoureusement aux attentes ou aux normes en vigueur?',
                'Lors du suivi d\'un projet, quelle méthode utilisez-vous pour contrôler de façon rigoureuse le progrès, les échéances et les ressources utilisées?'
            ],
            [
                'Parlez-nous d’une situation où vous avez eu un choix difficile à faire entre votre vie professionnelle et personnelle.',
                'Comme vous le savez, tout le monde fait des erreurs ou prend de mauvaises décisions. Pouvez-vous nous parler d’une décision que vous avez prise et qui a mal tourné? Comment avez-vous fait pour en assumer les conséquences?',
                'Donnez-nous un exemple où vous avez eu le courage de prendre une décision impopulaire faisant partie de vos responsabilités.'
            ],
            [
                'Quel est le degré d\'attention que vous portez aux détails dans vos travaux quotidiens?',
                'Parlez-nous d\'une situation où votre vérification attentive des détails a permis de déceler une erreur qui aurait pu avoir des conséquences importantes.',
                'Comment vous y prenez-vous pour vous assurer de ne pas oublier de détails importants lors de l\'exécution d\'une tâche?'
            ],
            [
                'Selon quels critères peut-on évaluer un bon objectif selon vous? (SMART)',
                'Lorsque vous fixez un objectif, comment faites-vous pour vous assurer que c’est un objectif valable, réaliste et mesurable?',
                'Comment faites-vous pour vous assurer que des objectifs à long terme seront atteints?'
            ],
            [
                'Parlez-nous de la dernière fois où vous vous êtes retrouvé dans une situation risquée et incertaine.',
                'Parlez-nous d’une décision risquée que vous avez prise.',
                'Parlez-nous d\'une décision que vous avez prise sachant qu\'il y aurait de vives réactions.'
            ],
            [
                'Parlez-nous de votre capacité à synthétiser l’information? Donnez-nous un exemple vécu.',
                'Comment vous y prenez-vous pour expliquer de façon simple un sujet complexe à une personne non-initiée?',
                'Comment faites-vous pour vous donner une nouvelle perspective par rapport aux mêmes problèmes récurrents?'
            ],
            [
                'Parlez-nous de votre processus de prise de décision. Lequel des mots suivants représente le mieux votre comportement lors de la prise de décision : consultatif, expéditif, réfléchi, directif, indécis, consensuel?',
                'Parlez-nous de la décision la plus difficile que vous avez eu à prendre. Pourquoi était-ce difficile d\'arriver à cette conclusion?',
                'Parlez-nous d\'une situation où des collègues ont pris part à votre processus de décision. Comment cela s\'est-il passé et qu\'en est-il résulté?'
            ],
            [
                'Lorsque vous êtes convaincu de quelque chose, comment réagissez-vous lorsque l’on tente de vous en dissuader?',
                'Parlez-nous d’une situation où vous avez réalisé quelque chose malgré le fait que vous étiez seul à y croire.',
                'Parlez-nous d’une situation où vous avez dû vous conformer à une décision allant à l\'encontre de votre opinion ou de vos conclusions. Comment avez-vous réagi?'
            ],
            [
                'Si je vous dis « augmentation de la profitabilité », en quelques minutes, seriez-vous en mesure de définir les principaux défis à relever et d\'esquisser un plan d\'action?',
                'Parlez-nous de la dernière fois où vous avez conçu une solution. Comment vous y êtes-vous pris pour valider vos idées? Sous quelle forme avez-vous communiqué ces idées à vos collaborateurs?',
                'Parlez-nous de la dernière fois où vous avez dû faire appel à des notions abstraites pour élaborer une idée ou un concept servant à réaliser un but ou à résoudre un problème.'
            ],
            [
                'Quelle est votre vision de l’avenir de l\'entreprise et comment croyez-vous pouvoir y contribuer?',
                'Sur quels types d’éléments basez-vous les différents scénarios que vous imaginez pour atteindre les objectifs?',
                'Parlez-nous d\'une situation où vous avez imaginé ou proposé des scénarios ou des orientations à prendre pour réaliser un des buts spécifiques de votre entreprise.'
            ],
            [
                'Parlez-nous d\'une situation où vous aviez un échéancier très serré à respecter et qu\'un collègues qui semblait vraiment dépassé est venu solliciter votre aide. Comment avez-vous abordé la situation?',
                'Parlez-nous d\'une situation où les contraintes ou la pression de temps ne vous ont pas permis de rendre le service dont quelqu\'un avait vraiment besoin. Comment vous êtes-vous senti?',
                'En général, trouvez-vous que vos collègues sont aussi serviables entre eux ou envers vous que vous l\'êtes envers eux? Donnez des exemples.'
            ],
            [
                'Décrivez une situation où vous avez été sollicité pour un projet urgent, devant être complété dans un très court délai. Quel était le projet? Comment avez-vous réagi face à la situation et quel a été le résultat?',
                'Décrivez une situation où votre supérieur immédiat vous a assigné un projet urgent alors que vous aviez déjà des priorités importantes. Comment vous êtes-vous senti et quelle a été votre réaction?',
                'Lorsqu\'un de vos projets urgents est en attente d\'éléments de la part de collègues qui tardent, comment réagissez-vous? 1- Vous rongez votre frein en silence? 2- Vous en parlez à votre supérieur? 3- Vous les appelez fréquemment pour mettre de la pression?'
            ],
            [
                'Parlez-nous d\'une situation de travail où vous avez su faire preuve d\'empathie ou de sympathie pour quelqu\'un.',
                'Parlez-nous d\'une situation où votre sensibilité à l\'égard des personnes de votre équipe a pu permettre une amélioration du climat et du rendement.',
                'Donnez-nous un exemple d\'une situation où vous avez été témoin qu\'une personne n\'a vraiment pas été traitée de façon équitable, soit par son supérieur immédiat ou par des collègues. Comment avez-vous géré la situation?'
            ],
            [
                'Donnez-nous un exemple d\'une situation où vous avez basé votre décision sur votre intuition. Quel a été le résultat? Aurait-il été meilleur si vous aviez agi différemment?',
                'Dans une situation où il n\'y a pas de balises d\'action claires, quelle est votre réaction? 1- Vous attendez que les choses se placent? 2- Vous agissez selon votre intuition? 3- Vous passez le problème à un autre?',
                'Lorsque vous répondez à un questionnaire à choix multiples, si vous ne connaissez aucune des réponses à une question : 1- Vous sautez la question quitte à y revenir? 2- Vous répondez au hasard? 3- Vous « devinez » la bonne réponse.'
            ],
            [
                'Comment réagissez-vous lorsque vous êtes au centre d\'une discussion vive ou d\'une situation difficile et que tout le monde vous pointe du doigt?',
                'Parlez-nous d\'une situation où vous aviez besoin d\'une information de la part d\'une personne précise pour terminer un projet important et qu\'elle n\'était vraiment pas disposée à vous aider. Comment avez-vous réagi?',
                'Décrivez-nous une situation où vous avez reçu des commentaires négatifs d\'un employeur, d\'un collègue ou d\'un client. Comment avez-vous réagi? Comment cela s\'est-il conclu?'
            ],
            [
                'Décrivez-nous ce que représente un excellent service à la clientèle pour vous.',
                'Parlez-nous d\'une situation où un client n\'était vraiment pas satisfait. Comment avez-vous approché la situation et quel a été le résultat?',
                'Lorsque l\'on travaille avec plusieurs clients, il est parfois difficile d\'offrir un excellent service à chacun. Comment procédez-vous pour prioriser les besoins de vos clients?'
            ]
        ]
    };

    // TODO: Move it in database
    talentsData = {
        en: [
            {
                title: ['Flexibility'],
                number: 16
            },
            {
                title: ['Self-Confidence'],
                number: 2
            },
            {
                title: ['Consistency'],
                number: 4
            },
            {
                title: ['Creativity'],
                number: 7
            },
            {
                title: ['Stress', 'Management'],
                number: 19
            },
            {
                title: ['Multitasking'],
                number: 23
            },
            {
                title: ['Cautiousness'],
                number: 32
            },
            {
                title: ['Resilience'],
                number: 33
            },
            {
                title: ['Delegation', 'Skills'],
                number: 8
            },
            {
                title: ['Structured', 'Thinking'],
                number: 14
            },
            {
                title: ['Project', 'Management'],
                number: 17
            },
            {
                title: ['Change', 'Management'],
                number: 18
            },
            {
                title: ['Sense of', 'Initiative'],
                number: 21
            },
            {
                title: ['Results-driven'],
                number: 24
            },
            {
                title: ['Planning and', 'Organizing'],
                number: 29
            },
            {
                title: ['Problem Solving'],
                number: 34
            },
            {
                title: ['Leadership', 'Skills'],
                number: 36
            },
            {
                title: ['Interpersonal', 'Skills'],
                number: 1
            },
            {
                title: ['Listening', 'Skills'],
                number: 9
            },
            {
                title: ['Empathy'],
                number: 10
            },
            {
                title: ['Persuasiveness'],
                number: 28
            },
            {
                title: ['Attentiveness'],
                number: 30
            },
            {
                title: ['Sociability'],
                number: 42
            },
            {
                title: ['Tolerance'],
                number: 44
            },
            {
                title: ['Teamwork'],
                number: 45
            },
            {
                title: ['Analytical', 'Thinking'],
                number: 11
            },
            {
                title: ['Compliance'],
                number: 3
            },
            {
                title: ['Supervising'],
                number: 5
            },
            {
                title: ['Applied Technical', 'Thinking'],
                number: 26
            },
            {
                title: ['Perseverance'],
                number: 27
            },
            {
                title: ['Stringency'],
                number: 35
            },
            {
                title: ['Sense of Duty', 'and Responsability'],
                number: 38
            },
            {
                title: ['Attention to Detail'],
                number: 43
            },
            {
                title: ['Goal Setting'],
                number: 15
            },
            {
                title: ['Risk Taking'],
                number: 31
            },
            {
                title: ['Capacity for', 'Synthesis'],
                number: 13
            },
            {
                title: ['Decision Making'],
                number: 12
            },
            {
                title: ['Independent', 'Thinking'],
                number: 20
            },
            {
                title: ['Conceptual', 'Thinking'],
                number: 25
            },
            {
                title: ['Strategic Vision'],
                number: 46
            },
            {
                title: ['Helpfulness'],
                number: 40
            },
            {
                title: ['Sense of Urgency'],
                number: 37
            },
            {
                title: ['Concern for', 'Others'],
                number: 39
            },
            {
                title: ['Intuition'],
                number: 22
            },
            {
                title: ['Level-', 'headedness'],
                number: 6
            },
            {
                title: ['Customer', 'Service'],
                number: 41
            },
        ],
        fr: [
            {
                title: ['Flexibilité'],
                number: 16
            },
            {
                title: ['Confiance', 'en soi'],
                number: 2
            },
            {
                title: ['Constance'],
                number: 4
            },
            {
                title: ['Créativité'],
                number: 7
            },
            {
                title: ['Gestion', 'du stress'],
                number: 19
            },
            {
                title: ['Multitâches'],
                number: 23
            },
            {
                title: ['Prudence'],
                number: 32
            },
            {
                title: ['Résilience'],
                number: 33
            },
            {
                title: ['Délégation'],
                number: 8
            },
            {
                title: ['Esprit', 'd\'organisation'],
                number: 14
            },
            {
                title: ['Gestion', 'de projet'],
                number: 17
            },
            {
                title: ['Gestion du', 'changement'],
                number: 18
            },
            {
                title: ['Initiative'],
                number: 21
            },
            {
                title: ['Orientation', 'résultat'],
                number: 24
            },
            {
                title: ['Planification'],
                number: 29
            },
            {
                title: ['Résolution de', 'problèmes'],
                number: 34
            },
            {
                title: ['Savoir', 'diriger'],
                number: 36
            },
            {
                title: ['Communication', 'interpersonnelle'],
                number: 1
            },
            {
                title: ['Écoute'],
                number: 9
            },
            {
                title: ['Empathie'],
                number: 10
            },
            {
                title: ['Persuasion'],
                number: 28
            },
            {
                title: ['Prévenance'],
                number: 30
            },
            {
                title: ['Sociabilité'],
                number: 42
            },
            {
                title: ['Tolérance'],
                number: 44
            },
            {
                title: ['Travail', 'd\'équipe'],
                number: 45
            },
            {
                title: ['Esprit', 'd\'analyse'],
                number: 11
            },
            {
                title: ['Conformité'],
                number: 3
            },
            {
                title: ['Contrôle'],
                number: 5
            },
            {
                title: ['Pensée', 'technique'],
                number: 26
            },
            {
                title: ['Persévérance'],
                number: 27
            },
            {
                title: ['Rigueur'],
                number: 35
            },
            {
                title: ['Sens des', 'responsabilités'],
                number: 38
            },
            {
                title: ['Souci du', 'détail'],
                number: 43
            },
            {
                title: ['Fixation', 'd\'objectifs'],
                number: 15
            },
            {
                title: ['Prise de', 'risque'],
                number: 31
            },
            {
                title: ['Esprit de', 'synthèse'],
                number: 13
            },
            {
                title: ['Esprit de', 'décision'],
                number: 12
            },
            {
                title: ['Indépendance', 'd\'esprit'],
                number: 20
            },
            {
                title: ['Pensée', 'conceptuelle'],
                number: 25
            },
            {
                title: ['Vision', 'stratégique'],
                number: 46
            },
            {
                title: ['Serviabilité'],
                number: 40
            },
            {
                title: ['Sens de', 'l\'urgence'],
                number: 37
            },
            {
                title: ['Sensibilité', 'aux autres'],
                number: 39
            },
            {
                title: ['Intuition'],
                number: 22
            },
            {
                title: ['Contrôle', 'de soi'],
                number: 6
            },
            {
                title: ['Service à', 'la clientèle'],
                number: 41
            },
        ]
    };

    public getTalentsData(lang: string): any {
        const fullTalentsData = this.talentsData[lang].map((item: any, index: number) => ({
            ...item,
            id: index,
            fullTitle: item.title.join(' '),
            text: this.talentsTxt[lang][index],
            polarities: this.talentsPolarities[lang][index],
            interviewQuestions: this.interviewQuestions[lang][index],
        }));

        return fullTalentsData;
    }
}
