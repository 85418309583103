import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TalentsDefinitions} from '../../talents/talentsDefinitions';

@Component({
    selector   : 'app-mini-mpo-graph',
    templateUrl: './mini-mpo-graph.component.html',
    styleUrls  : ['./mini-mpo-graph.component.scss']
})
export class MiniMpoGraphComponent implements OnChanges {

    @ViewChild('MiniMpoGraph', {static: true}) private chartContainer: ElementRef;
    @Input() score: any;
    @Input() id: any;
    @Input() interaction: any;
    @Input() noRemove: any = false;

    constructor() {
    }

    ngOnChanges() {
        this.createMiniMpoGraph({
            A: this.score.A,
            E: this.score.E,
            P: this.score.P,
            S: this.score.S
        });

    }

    createMiniMpoGraph(scores) {


        function uniq(a) {
            return Array.from(new Set(a));
        }

        let scoreArr = [scores['A'], scores['E'], scores['P'], scores['S']];
        let sorted = uniq(scoreArr.sort(function (a, b) {
            return a - b;
        }));

        const traits = ['A', 'E', 'P', 'S'];

        let depth = sorted.length - 1;

        sorted.forEach((score, index) => {
            traits.forEach(trait => {
                if (scores[trait] === score) {
                    scores[trait] = index;
                }
            });
        });

        let backgroundColor = '#EEE';
        let graphSize = '60';
        let circleStrokeWidth = 6;
        let circleStrokeColor = '#E2E2E2';
        if (this.interaction === 'cards') {
            backgroundColor = 'none';
            graphSize = '60';
            circleStrokeColor = '#E2E2E2';
            circleStrokeWidth = 6;
        }

        if (this.noRemove) { d3.select('#pastilleMPO_' + this.id).remove(); }

        let element = this.chartContainer.nativeElement;

        let svg = d3.select(element).append('svg').attr('id', 'pastilleMPO_' + this.id).attr('preserveAspectRatio', 'xMidYMid meet').attr('width', graphSize).
            attr('height', graphSize).attr('viewBox', '0 0 240 240');// .style('background-color', backgroundColor);

        const grids = [
            [120],
            [90, 150],
            [75, 120, 165],
            [66, 102, 138, 174]
        ];

        const height = {
            A: 66,
            E: 102,
            P: 138,
            S: 174
        };

        svg.append('circle').attr('cx', 120).attr('cy', 120).attr('r', 110).style('fill', 'white').
            style('stroke-width', circleStrokeWidth).
            style('stroke', circleStrokeColor);

        svg.append('line').attr('x1', grids[depth][scores.A]).attr('y1', height.A).attr('x2', grids[depth][scores.E]).
            attr('y2', height.E).attr('stroke-width', 2).attr('stroke', 'black');

        svg.append('line').attr('x1', grids[depth][scores.E]).attr('y1', height.E).attr('x2', grids[depth][scores.P]).
            attr('y2', height.P).attr('stroke-width', 2).attr('stroke', 'black');

        svg.append('line').attr('x1', grids[depth][scores.P]).attr('y1', height.P).attr('x2', grids[depth][scores.S]).
            attr('y2', height.S).attr('stroke-width', 2).attr('stroke', 'black');

        traits.forEach(trait => {
            let fill = '#FFF';
            if (this.interaction !== 'cards' && this.interaction !== 'sidebar') {
                if (trait === this.interaction[0] || trait === this.interaction[1]) {
                    fill = 'rgb(233,118,6)';
                }
            } else {
                fill = 'rgb(233,118,6)';
            }
            svg.append('circle').attr('cx', grids[depth][scores[trait]]).attr('cy', height[trait]).attr('r', 14).
                attr('stroke-width', 2).attr('stroke', '#232323').style('fill', fill);
        });
    }
}
