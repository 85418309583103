import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Output,
    OnInit,
    OnDestroy,
    EventEmitter
} from '@angular/core';
import * as _ from 'lodash';
import {StateService} from '../../../../../core/services/state/state.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {ApiPeopleService} from 'src/app/people/services/api-people.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mobile-idw-matrix-person-adding',
  templateUrl: './mobile-idw-matrix-person-adding.component.html',
  styleUrls: ['./mobile-idw-matrix-person-adding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileIdwMatrixPersonAddingComponent implements OnInit, OnDestroy {

    @Output() closePanel = new EventEmitter();

    searchTerm: any;
    records: Array<any>;
    selectedItems: Array<any> = [];

    recordsLoaded = 100;
    recordsLength: number;
    public loading = false;

    displayedType = 'dit';

    actual = '';

    private subscriptions = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private stateService: StateService,
                public idwMatrix: IdwMatrixAnalysisService,
                private apiIdwMatricesService: ApiIdwMatricesService,
                private apiPeopleService: ApiPeopleService) {
    }

    ngOnInit() {
        if (this.idwMatrix.itemToDisplay) {
            this.fetchRecords(this.displayedType);
            // remove prbs
            let newRecords = [];
            if (this.idwMatrix.records) {
                for (let item of this.idwMatrix.records) {
                    if (item.analysisType !== 'prb') {
                        newRecords.push(item);
                    }
                }
            }
            this.idwMatrix.records = newRecords;
            this.selectedItems = [];
            if (this.idwMatrix.records) {
                for (let record of this.idwMatrix.records) {
                    this.selectedItems.push({
                        type: 'trait',
                        record: record.id
                    });
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(this.displayedType, searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(this.displayedType, searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(this.displayedType, null);
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {
            return;
        }

        this.fetchRecords(this.displayedType, this.searchTerm, this.recordsLoaded, true);
    }

    fetchRecords(type, searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        let request = {
            'skip': skip,
            'search': searchTerm,
            'limit': this.recordsLoaded,
            'fields': 'id,firstName,lastName',
            'reportStatuses': {dit: 1}
        };

        this.subscriptions.add(this.apiPeopleService.records(
            [request]
        ).subscribe(
            (res) => {
                this.loadRecords(res, skip, concat);
            }
        ));
    }

    loadRecords(records, skip, concat) {
        // Get records loaded (add the new records from API)
        this.recordsLoaded = (skip) ?
            this.recordsLoaded + records.data.length :
            this.recordsLoaded;

        // Records data (merge new records from API)
        this.records = (concat) ?
            [...this.records, ...records.data] :
            records.data;

        // Total records
        this.recordsLength = records.count;

        // Reload component
        this.changeDetectorRef.markForCheck();

        this.loading = false;
    }

    predicateCheckBox(record) {
        return ((_.findIndex(this.selectedItems, function (i) {
                return (i.record === record.id);
            }
        )) !== -1);
    }

    onChecked(event, id: string) {
        if (event.checked || (event.target && event.target.checked)) {
            this.selectedItems.push({
                type: 'trait',
                record: id
            });
        } else {
            _.remove(this.selectedItems, {record: id});
        }
    }

    closeP() {
        this.closePanel.emit(true);
    }

    onSubmit() {
        let selectedItemsTemp = [];
        if (this.selectedItems.length > 0) {
            for (let item of this.selectedItems) {
                selectedItemsTemp.push({
                    type: 'prb',
                    record: item.record,
                    dash: 0
                });
            }
            this.selectedItems = this.selectedItems.concat(selectedItemsTemp);
            const data = {'records': this.selectedItems};

            this.subscriptions.add(this.apiIdwMatricesService.putDitGram([this.idwMatrix.id], data).subscribe(
                () => {
                    this.idwMatrix.setItemToDisplayWithId(this.idwMatrix.id);
                    this.closeP();
                }
            ));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
