// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.mat-input-element {
  color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app/components/resetpass/mobile/mobile-resetpass.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ","sourcesContent":["input.mat-input-element {\n    color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
