// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

.flat {
  margin: 0;
  padding: 0;
}

table#transaction {
  width: 100%;
}

table#transaction tr td {
  padding-bottom: 10px;
}

table#transaction tr td:first-child {
  font-family: MontserratSemiBold, sans-serif;
  width: 100px;
}

table#transaction tr td:nth-child(2) {
  padding-left: 10px;
}

textarea {
  width: 100%;
}

#insufficientCreditLevelWarning {
  padding-top: 2px;
  padding-left: 10px;
  color: indianred;
}

.modalButton:disabled {
  opacity: 0.65 !important;
  background-color: #999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-transactions-edit-modal/administration-transactions-edit-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,SAAA;EACA,UAAA;AAEJ;;AACA;EACI,WAAA;AAEJ;;AACA;EACI,oBAAA;AAEJ;;AACA;EACI,2CAAA;EACA,YAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,WAAA;AAEJ;;AACA;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,wBAAA;EACA,oCAAA;AAEJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n.flat {\n    margin: 0;\n    padding: 0;\n}\n\ntable#transaction {\n    width: 100%;\n}\n\ntable#transaction tr td {\n    padding-bottom: 10px;\n}\n\ntable#transaction tr td:first-child {\n    font-family: MontserratSemiBold, sans-serif;\n    width: 100px;\n}\n\ntable#transaction tr td:nth-child(2) {\n    padding-left: 10px;\n}\n\ntextarea {\n    width: 100%;\n}\n\n#insufficientCreditLevelWarning {\n    padding-top: 2px;\n    padding-left: 10px;\n    color: indianred;\n}\n\n.modalButton:disabled {\n    opacity: 0.65 !important;\n    background-color: #999999 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
