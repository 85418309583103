import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../core/services/state/state.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-jobs-multiselect-action',
    templateUrl: './jobs-multiselect-action.component.html',
    styleUrls: ['./jobs-multiselect-action.component.scss']
})
export class JobsMultiselectActionComponent implements OnInit, OnDestroy {

    @Input() multiselectData: any;

    public transfer: EventEmitter<any> = new EventEmitter();
    public close: EventEmitter<any> = new EventEmitter();

    container: any = null;
    location: string;
    subAccounts: any;
    departments: any;
    currentLang: string;

    isAccountsLoaded = false;
    isAllAccountsLoaded = false;
    searchTerm: any = null;
    selectedAccountNumber: any = null;
    iconsFolder = 'assets/header/';
    buttonsFolder = 'assets/buttons/';
    items: any = [];

    constructor(
        public bsModalRef: BsModalRef,
        private stateService: StateService,
        private apiAdministration: ApiAdministrationService,
        private translateService: TranslateService
    ) {
        this.subAccounts = this.stateService.session.sessionData.structure.subAccounts;
        this.departments = this.stateService.session.sessionData.structure.departments;
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.container = null;

        if (this.multiselectData.actionType === 'transferOtherAcc') {
            this.location = this.translateService.instant('commons.accounts');
        }
        if (this.multiselectData.actionType === 'subaccount') {
            this.selectedAccountNumber = true;
            this.location = this.translateService.instant('commons.subAccounts');
            this.location = this.location.toLowerCase();
        }
        if (this.multiselectData.actionType === 'department') {
            this.selectedAccountNumber = true;
            this.location = this.translateService.instant('commons.departments');
        }

        let counter = 0;
        let total = 0;
        let step = 50;
        let intervalId = setInterval(() => {
            if (!this.isAllAccountsLoaded) {
                counter = counter + 1;
                this.getAccountsList(total, step);
                total = total + step;
            }
            if (counter === 100 || this.isAllAccountsLoaded) {
                clearInterval(intervalId);
            }
        }, 1000);
    }

    getAccountsList(accountsLoaded, step) {
        let request: any = {
            namesOnly: 1,
            limit: step,
            skip: accountsLoaded
        };
        let search: Subscription = this.apiAdministration.clients([request]).subscribe(
            res => {
                if (res && res.data && res.data.length) {
                    this.isAccountsLoaded = true;
                    res.data.forEach(acc => {
                        this.items.push({
                            id: acc.id,
                            accountName: acc.accountName,
                            accountNumber: acc.accountNumber
                        });
                    });
                } else {
                    this.isAllAccountsLoaded = true;
                }
                search.unsubscribe();
            });
    }

    transferReports() {
        if (this.container != null && this.multiselectData.actionType !== 'transferOtherAcc') {
            this.container = (this.multiselectData.actionType === 'department') ? [this.container] : this.container;

            this.multiselectData['container'] = this.container;
            this.transfer.emit({multiselectData: this.multiselectData});
        }
        if (this.multiselectData.actionType === 'transferOtherAcc' && this.selectedAccountNumber) {
            this.multiselectData['container'] = this.selectedAccountNumber;
            this.transfer.emit({multiselectData: this.multiselectData});
        }
    }

    closeModal() {
        this.close.emit();
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        this.clearSearch();
        this.close.emit();
    }

    selectedItem(selItem) {
        if (this.searchTerm) {
            /**
             *  uncomment the lines below to cancel the automatic value selection by Enter button
             */
            // this.container = this.searchTerm;
            // this.searchTerm = null;
        }
        if (selItem && this.container === selItem.value) {
            this.selectedAccountNumber = selItem.item.accountNumber;
        }
    }

    onSearch(event) {
        this.selectedAccountNumber = null;
        if (this.container && event.keyCode === 13) {
            this.searchTerm = this.container;
        }
    }

    clearSearch() {
        this.searchTerm = null;
        this.container = null;
        this.selectedAccountNumber = null;
    }
}
