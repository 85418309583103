import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {EnvService} from '../../../../../app/services/env.service';
import {WindowRef} from '../../../../../app/services/window-ref.service';
import {JobSatisfactionTeamGraphHelper} from '../../commonClasses/jobSatisfactionTeamGraphHelper';

@Component({
  selector: 'app-job-satisfactions-pdf-modal',
  templateUrl: './job-satisfactions-pdf-modal.component.html',
  styleUrls: ['./job-satisfactions-pdf-modal.component.scss']
})
export class JobSatisfactionsPdfModalComponent implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    pdfStructure = {
        parts: {
            'analyst': '1',
            'frontPage': true,
            'team': true,
            'person': true,
            'personSummary': true,
            'personText': true
        },
        selectedPersons: []
    };

    selectMethod = false;
    method = 'pdf';
    share = 'none';

    pdfEmail: any;

    showPerson = true;

    nativeWindow: any;


    mailto = null;
    mailToJson = null;
    pdfToken = null;
    pdfReportUrl = null;

    serverText: string;

    dataBack = '';

    public subcriptions = new Subscription();

  constructor(
      public bsModalRef: BsModalRef,
      public translate: TranslateService,
      public jobSatisfaction: JobSatisfactionsAnalysisService,
      public apiJobSatisfaction: ApiJobSatisfactionsService,
      private environment: EnvService,
      private windowRef: WindowRef,
      public changeDetectorRef: ChangeDetectorRef
  ) {
      this.nativeWindow = windowRef.getNativeWindow();
  }

  ngOnInit(): void {
      if (this.jobSatisfaction.records && this.jobSatisfaction.records.length !== 0) {
          let data = JobSatisfactionTeamGraphHelper.setData(this.jobSatisfaction.records, 12);
          this.dataBack = JobSatisfactionTeamGraphHelper.setDataBack(data[data.length - 1]);
          this.changeDetectorRef.markForCheck();
      }
      this.initPdfModal();
  }
    initPdfModal() {
        // PDF structure
        this.pdfStructure = Object.assign({}, {
            parts: {
                'analyst': '1',
                'frontPage': true,
                'team': true,
                'person': true,
                'personSummary': true,
                'personText': true
            },
            selectedPersons: []
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: this.translate.instant('commons.jobSatisfactionReportTitle'),
            message: '',
        });


        // PDF link
        this.mailto = null;
        this.mailToJson = null;
        this.pdfToken = null;
        this.pdfReportUrl = null;

        this.showPerson = true;
        this.changeDetectorRef.markForCheck();
    }

    changeSelectMethodState() {
        if (this.method !== 'pdf') {
            this.selectMethod = false;
        }
    }
    handleReport() {
        if (this.method !== 'none') {
            switch (this.method) {
                case 'pdf':
                    this.downloadReport();
                    return;
                case 'cutpaste':
                    this.share = 'cutpaste';
                    this.getReportData({type: 'token'});
                    return;
                case 'mailto':
                    this.share = 'mailto';
                    this.getReportData({type: 'mailto'});
                    return;
                case 'server':
                    this.share = 'server';
                    this.getReportData({type: 'share'});
                    return;
            }
        }
        this.selectMethod = true;
        return;
    }

    downloadReport() {
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }
    getReportData(data) {
        // @ts-ignore
        this.pdfStructure.parts.analyst = (this.pdfStructure.parts.analyst === '1');
            let obs = this.apiJobSatisfaction.reportJobSatisfactions([this.jobSatisfaction.id], [
                this.pdfStructure.parts,
                this.translate.currentLang,
                this.dataBack
            ]);
            this.subcriptions.add(obs.subscribe(
                res => {
                    const reportName = this.translate.instant('commons.jobSatisfactionReportTitle');
                    this.pdfToken = res.pdfToken;

                    this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + encodeURI(this.pdfToken);

                    if (data.type === 'mailto') {
                        this.mailto = '?subject=' + this.translate.instant('commons.report') + ' '
                            + reportName + '&body=' +
                            this.translate.instant('jobSatisfactions.pdfModal.linkInstruction', {
                                reportName: reportName,
                            }) + '%0A%0A' + this.pdfReportUrl;
                        this.mailToJson = {
                            mailto: this.mailto
                        };
                    } else if ('share' === data.type) {
                        this.serverText = this.translate.instant('jobSatisfactions.pdfModal.linkInstruction', {
                            reportName: reportName,
                        }) + this.pdfReportUrl;
                    }

                    if (data.window && 'download' === data.type) {
                        data.window.document.location.href = this.pdfReportUrl;
                        this.closeModal();
                        return;
                    }

                    this.changeDetectorRef.markForCheck();
                }
            ));
    }

    togglePersons() {
        if (this.pdfStructure['parts']['person']) {
            this.showPerson = true;
            this.pdfStructure['parts']['personSummary'] = true;
            this.pdfStructure['parts']['personText'] = true;
        } else {
            this.showPerson = false;
            this.pdfStructure['parts']['personSummary'] = false;
            this.pdfStructure['parts']['personText'] = false;
        }
    }


    closeModal() {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.initPdfModal();
            this.method = 'pdf';
            this.share = 'none';
            this.selectMethod = false;
        });
        this.close.emit({closed: true});
        this.bsModalRef.hide();
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport d\' analyse de Bien-être (IBT)' : 'Wellbeing Potential Analysis Report';
        }

        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.jobSatisfaction.name,
            'jobSatisfaction',
            this.translate.currentLang
        ];

        this.subcriptions.add(this.apiJobSatisfaction.reportEmail(dataEmail).subscribe());
        this.closeModal();
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
        this.selectMethod = false;
    }

    ngOnDestroy() {
        this.subcriptions.unsubscribe();
        this.close.emit({closed: true});
    }

}
