// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-size: 17px;
  padding-bottom: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/mobile/mobile-jobs-profiler-email/mobile-jobs-profiler-email.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,qBAAA;AACJ","sourcesContent":["h2 {\n    font-size: 17px;\n    padding-bottom: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
