import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {UserService} from '../../../../../core/services/user/user.service';
import {AlertService} from '../../../../../shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ResponsiveHelper} from '../../../../../shared/helpers/responsive.helper';
import {take} from 'rxjs/operators';
import {
    AddPeopleAnalysisModalComponent
} from '../../../../shared/add-people-analysis-modal/add-people-analysis-modal.component';
import {ComponentList} from '../../../../../shared/classes/components/component_list';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {JobSatisfactionsListService} from '../../../services/job-satisfactions-list.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {JobSatisfactionsModalHandlingService} from '../../../services/job-satisfactions-modal-handling.service';
import {JobSatisfactionTeamGraphHelper} from '../../commonClasses/jobSatisfactionTeamGraphHelper';

@Component({
  selector: 'app-job-satisfactions-list',
  templateUrl: './job-satisfactions-list.component.html',
  styleUrls: ['./job-satisfactions-list.component.scss']
})
export class JobSatisfactionsListComponent extends ComponentList implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('gSidebar') gSidebar;
    displayConstant = {
        adjustment: {left: -5, right: -14},
        widthRatio: 2 / 3,
        leftColumnWidth: 400
    };
    action;
    listWidth: any;
    sideWidth: any;
    itemToDisplay: any;
    isSelected = 'none';
    jobSatisfactionModalStatus = 'new';
    peopleAnalysisModalRef: BsModalRef;

    private subscriptions = new Subscription();

    constructor(public router: Router,
                public jobSatisfactionAnalysis: JobSatisfactionsAnalysisService,
                public jobSatisfactionModalHandlingService: JobSatisfactionsModalHandlingService,
                private route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,  // Even if flagged as unused, it is used in getList(this, search) below
                public stateService: StateService,
                public user: UserService,
                public jobSatisfactionList: JobSatisfactionsListService,
                private apiJobSatisfaction: ApiJobSatisfactionsService,
                private alertService: AlertService,
                protected translateService: TranslateService,
                protected deviceService: DeviceDetectorService,
                private modalService: BsModalService
    ) {
        super(stateService, translateService, router, deviceService);
        if (this.user.user && !this.stateService.hasPersonality) {
            if (this.stateService.hasCommunication) {
                this.router.navigate(['/analysis/idwmatrix/list']).then(() => {});
            } else {
                this.router.navigate(['/people/list']).then(() => {});
            }
        }
        // set the sidebar status
        this.jobSatisfactionList._opened = !this.smallSize;
    }

    ngOnInit() {
        if (!this.jobSatisfactionList.archive) {
            this.setLastAddress();
        }
        // Close add to analysis modal
        this.subscriptions.add(this.modalService.onHidden.subscribe(() => {
            // Reset Multi-action
            this.stateService.jobSatisfaction.resetMultiAction();
            this.jobSatisfactionList.multiAction = null;
            // Reset itemToDisplay to reload the selected item after list update
            this.itemToDisplay = null;
            this.jobSatisfactionList.state.stateChanged.next(true);
        }));

        this.jobSatisfactionList.multiAction = null;
        this.jobSatisfactionList.checkModuleUrl();
        this.jobSatisfactionList.checkUserJobSatisfactionDisplayPreferences();

        // check items list without selection
        this.jobSatisfactionList.checkTotalItems();

        if (this.jobSatisfactionAnalysis.id) {
            let action = 'forceSidebarToReport';
            if (this.jobSatisfactionList.jobSatisfactionState.sideBarStatus === 'searchFilters') {
                action = 'resetSidebarToFilters';
            }
            this.jobSatisfactionAnalysis.setItemToDisplayWithId(this.jobSatisfactionAnalysis.id, this.jobSatisfactionList.specificState, action);
        }

        this.jobSatisfactionList.jobSatisfactionState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.jobSatisfactionList.getJobSatisfactionList(true, null, this);

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.jobSatisfactionList.getJobSatisfactionList(true, null, this);
            }
        ));

        // Set list width
        this.computeListWidth(window);
    }

    setSearchFilter(event) {
        this.jobSatisfactionList.setSearchFilter(event);
        this.refreshList();
    }

    setOrder(event) {
        this.jobSatisfactionList.setOrder(event);
        this.refreshList();
    }

    ngAfterViewInit() {
        if (this.stateService.jobSatisfaction.multiAction.ids.length !== 0) {
            this.jobSatisfactionList.multiAction = this.stateService.jobSatisfaction.multiAction;
            this.stateService.jobSatisfaction.resetMultiAction();

            if (this.jobSatisfactionList.multiAction['action'] === 'add') {
                // Open modal to add selected users to an existing analysis
                if (this.jobSatisfactionList.multiAction['data']) {
                    this.openJobSatisfactionPeopleToAnalysisModal();
                }
            } else if (this.jobSatisfactionList.multiAction['action'] === 'create') {
                this.jobSatisfactionModalHandlingService.openJobSatisfactionModal({status: 'new'});
            }
        }
    }

    multiActionDestroy() {
        this.jobSatisfactionList.multiAction = null;
    }

    refreshList() {
        this.jobSatisfactionList.getJobSatisfactionList(true, null, this);
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.jobSatisfactionList._opened = true;
            this.gSidebar.open();
        }
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);

        this.jobSatisfactionList._opened = !this.smallSize;
        this.closed = this.smallSize;
    }

    /**
     * Close Right Match sidebar tablet device
     */
    closeJobSatisfactionSidebar(closePeopleSidebar: boolean): void {
        this.stateService.jobSatisfaction.lastSideBarStatus = 'recentActivities';
        if (closePeopleSidebar && this.jobSatisfactionList._opened && this.smallSize) {
            this.jobSatisfactionList._opened = false;
            this.closed = true;
        }
    }

    itemSelected(event) {
        this.closed = false;
        this.stateService.jobSatisfaction.lastSideBarStatus = 'reports';
        this.jobSatisfactionList.itemSelected(event);
    }

    computeListWidth(window: Window): void {
        let checkWidth = ResponsiveHelper.computeListWidth(window.innerWidth, this.displayConstant);
        this.sideWidth = checkWidth.sideWidth;
        this.listWidth = checkWidth.listWidth;
        this.changeDetectorRef.markForCheck();
    }

    actOnRecords(event) {
        let total = event.ids.length;
        let handled = 0;
        this.action = event.actionType;
        if (this.action === 'delete') {
            let pAction = this.translateService.instant('commons.deletionAction');
            let plural = (event.ids.length > 1) ? 's' : '';
            let paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                action: pAction,
                total: total,
                plural: plural
            });
            let alertText: string;
            const deleteTxt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(deleteTxt);
            this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm' && this.action === 'delete') {
                        // Delete all selected ids
                        this.apiJobSatisfaction.deleteJobSatisfactions(event.ids).pipe(take(1)).subscribe(
                            () => {
                                this.jobSatisfactionList.loading = true;
                                paragraph = this.translateService.instant('analysis.commons.jobSatisfactionDeleted', {
                                    total: total,
                                    plural: plural
                                });
                                alertText = this.translateService.instant('analysis.commons.jobSatisfactionDeletion');
                                const txtDelete = {
                                    paragraph: paragraph,
                                    texth1: alertText,
                                    position: 'top-right',
                                    type: 'green'
                                };
                                this.jobSatisfactionList.getJobSatisfactionList(true, txtDelete, this);
                                this.jobSatisfactionAnalysis.resetItemToDisplay(this.specificState());
                                this.jobSatisfactionList.revertToNews();
                                this.jobSatisfactionList.cleanMultiselectMode(true);
                            },
                            () => {
                                this.jobSatisfactionList.loading = true;
                                const txtDeleteError = {
                                    paragraph: this.translateService.instant('commons.tryAgain'),
                                    texth1: this.translateService.instant('commons.wasError'),
                                    position: 'center-center',
                                    type: 'red'
                                };
                                this.jobSatisfactionList.getJobSatisfactionList(true, txtDeleteError, this);
                            }
                        );
                        this.action = undefined;
                    }
                }
            );
        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            let plural = (event.ids.length > 1) ? 's' : '';
            let pAction;
            let paragraph;
            switch (this.action) {
                case 'archive':
                    putObject = {archive: true};
                    p[0] = this.translateService.instant('analysis.jobSatisfaction.multipleArchived', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleArchiving');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.archivingError');
                    break;
                case 'reactivate':
                    putObject = {archive: false};
                    p[0] = this.translateService.instant('analysis.jobSatisfaction.multipleReactivated', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleReactivation');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.reactError');
                    break;
            }
            if (this.action === 'archive') {
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtWarningArchive = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtWarningArchive);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'archive') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.jobSatisfactionList.revertToNews();
                            this.jobSatisfactionList.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action === 'reactivate') {
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtArchivingConfirmation = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }
        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.action) {
            let handledCounter = handled;
            for (let id of event.ids) {
                this.apiJobSatisfaction.putJobSatisfaction([id], putObject).pipe(take(1)).subscribe(
                    () => {
                        handledCounter++;
                        if (handledCounter === total) {
                            this.jobSatisfactionList.loading = true;
                            this.jobSatisfactionList.getJobSatisfactionList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            }, this);
                            this.jobSatisfactionList.revertToNews();
                            this.jobSatisfactionList.cleanMultiselectMode(true);
                        }
                        this.refreshList();
                    },
                    () => {
                        this.jobSatisfactionList.loading = true;
                        this.jobSatisfactionList.getJobSatisfactionList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        }, this);
                    }
                );
            }
            this.action = undefined;
        }
    }

    openJobSatisfactionPeopleToAnalysisModal() {
        this.jobSatisfactionList.revertToNews();
        this.jobSatisfactionList.cleanMultiselectMode(true);
        this.apiJobSatisfaction.jobSatisfactions([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = this.jobSatisfactionList.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [record.id]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                modalTitle: 'commons.addToRm',
                analysisType: 'jobSatisfaction'
            };

            // Open modal
            this.peopleAnalysisModalRef = this.modalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
