import {JobStateColumns} from '../../interfaces/state';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';
// import {isUndefined} from 'util';

export class StateJobs extends AbstractState {

    // Structural elements ///////////////////////////////////////////////////////////////////////

    // Département, Compte, Demandeur
    readonly columns = ['jobTitle', 'location', 'date', 'subAccountName', 'requiredBy', 'departmentName'];
    readonly columnsName = {
        fr: {
            jobTitle: 'Titre',
            location: 'Lieu',
            date: 'Date',
            subAccountName: 'Sous-compte',
            requiredBy: 'Demandeur',
            departmentName: 'Département',
        },
        en: {
            jobTitle: 'Title',
            location: 'Location',
            date: 'Date',
            subAccountName: 'Sub-account',
            requiredBy: 'Requester',
            departmentName: 'Département',
        }
    };

    readonly filters = [
        'search',
        'jobStartDate',
        'jobEndDate',
        // "evaluationType",
        // "evaluationReport",
        'subAccount',
        'department'
    ];

    // private listColumnsInitialConfig: Array<JobStateColumns> = [
    //     {key: 'jobTitle', display: true},
    //     {key: 'location', display: true},
    //     {key: 'date', display: true, order: 'desc'}
    // ];

    readonly listColumnsInitialConfig: Array<JobStateColumns> = [
        {key: 'jobTitle', display: true},
        {key: 'location', display: true},
        {key: 'date', display: true, order: 'desc'}
    ];

    protected state: any = {
        jobToDisplayId: null,
        sideBarStatus: 'recentActivities',
        lastSideBarStatus: 'recentActivities',
        report: {},
        selectedTab: 0,
        listView: 'list',
        listSearchFilters: {},
        listColumns: this.listColumnsInitialConfig,
        reportBack: null,
        selectedInfoQuestionTab: 0,
        informationVisibility: true
    };

    // TODO: added for refactoring

    get search(): string {
        return this.listSearchFilters.search;
    }


    // MULTISELECT ///////////////////////////////////////////////////////////////////////////////

    public multiselect = [];

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    multiselectWatch = new BehaviorSubject(null);
    multiselectWatch$ = this.multiselectWatch.asObservable();

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    constructor(
        private translate
    ) {
        super();
        // Initialization from sessionStorage
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('jobs.' + String(item));
            }
        }
    }

    setColumnWithPermissions(permissions, hasSatelliteRole) {
        if (permissions.mpo && this.columns.indexOf('score') === -1) {
            this.columns.push('score');
            this.columnsName['fr']['score'] = 'Personnalité';
            this.columnsName['en']['score'] = 'Personality';
        }
        if (!hasSatelliteRole && permissions.talents && this.columns.indexOf('competences') === -1) {
            this.columns.push('competences');
            this.columnsName['fr']['competences'] = 'Compétences';
            this.columnsName['en']['competences'] = 'Talents';
        }

    }

    /**
     * Checks validity of the sent params and process data if needed.
     *
     * @param action
     * @param params
     * @param modState
     * @param url
     */
    /**
    protected actionPreProcessing(action, params, modState, url = null) {
        switch (action) {
            case 'refreshList':
                modState['listUpdate'] = '';
                return [url, modState];
            case 'resetSearchFilters':
                modState.listSearchFilters = {};
                modState.listColumns = this.listColumnsInitialConfig;
                if (!params['noRefresh']) {
                    modState['listUpdate'] = '';
                }
                return [url, modState];

            case 'setSearchFilters':
                for (let filter of this.filters) {
                    if (filter) {
                        if (!isUndefined(params[filter])) {
                            modState.listSearchFilters[filter] = params[filter];
                        }
                    }
                }
                modState['listUpdate'] = '';
                return [url, modState];

            case 'setListConfig':
                if (typeof(params.listView) !== 'undefined' &&
                    params.listView !== 'list' &&
                    params.listView !== 'cards'
                ) {
                    throw 'Illegal view for setter StateJobs._listView';
                }
                // View
                if (params.listView) {
                    modState.listView = params.listView;
                }
                // Display
                // Display
                if (params['key'] && params['display'] != null) {
                    let index = _.findIndex(modState.listColumns, function(o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    if (index > -1) {
                        if (params['display']) {
                            modState.listColumns[index] = {
                                key: params['key'],
                                display: params['display']
                            };
                        } else {
                            modState.listColumns.splice(index, 1);
                        }
                    } else {
                        if (params['display']) {
                            modState.listColumns.push({
                                key: params['key'],
                                display: params['display']
                            });
                        }
                    }
                }
                if (params['key'] != null && params['order']) {
                    // tslint:disable-next-line:forin
                    let index = _.findIndex(modState.listColumns, function(o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    for (let indexRemoved in modState.listColumns) {
                        if (
                            modState.listColumns &&
                            modState.listColumns[indexRemoved] && (
                                modState.listColumns[indexRemoved]['order'] === 'asc' ||
                                modState.listColumns[indexRemoved]['order'] === 'desc'
                            )
                        ) {
                            delete modState.listColumns[indexRemoved]['order'];
                        }
                    }
                    let column = this.state.listColumns[index];
                    if (column) {
                        column['order'] = params['order'];
                        // console.log(column['order']);
                        modState.listColumns[index] = column;
                    }
                }
                modState['listUpdate'] = '';
                return [url, modState];

            case 'setSideBarStatus': // was sideBarStatus
                if (params.sideBarStatus !== 'recentActivities' && params.sideBarStatus !== 'searchFilters' &&
                    params.sideBarStatus !== 'clickMode' && params.sideBarStatus !== 'reports'
                ) {
                    throw 'Illegal status for setter StateJobs.sideBarStatus';
                }
                modState['sideBarStatus'] = params.sideBarStatus;
                modState['jobToDisplayId'] = '';
                modState['reportType'] = null;
                modState['report'] = {};
                if (params.action) {
                    modState['action'] = params.action;
                }
                return [url, modState];

            case 'setReport':
                if (params.jobToDisplayId == null) {
                    throw 'jobTodisplayId must be non-null in setReport';
                }
                if (!params.reportType) {
                    params.reportType = 'mpo';
                }
                if (_.has(params, 'jobToDisplay')) {
                    _.unset(params, 'jobToDisplay');
                }

                if (params.reportBack) {
                    modState.reportBack = params.reportBack;
                    params.reportBack = null;
                }
                modState.report = params;
                modState['sideBarStatus'] = 'reports';
                modState['jobToDisplayUpdate'] = '';
                if (params.listUpdate) {
                    modState['listUpdate'] = '';
                    _.unset(params, 'listUpdate');
                }
                return [url, modState];

            case 'getFullReport':
                modState.report = params;
                url = url + '/' + params.jobToDisplayId + '/' + params.reportType;
                return [url, modState];

            default:
                throw 'action "' + action + '" is inexistant on state-jobs.preProcessing';
        }

    }
    */

    /**
     * Partition state's parameter in function of the curent URL definitions i.e. if parameter x
     * is not required on URL y, then don't emit it, else do so.
     *
     * @param url
     * @param params
     */
    protected urlProcessing(url, params) {
        return params;
    }

    /**
     * Replace old state with the new state. Perform extra operations if needed.
     *
     * @param type
     * @param param
     * @param value
     */
    protected actionPostProcessing(type, param, value = null) {
        if (type === 'optionalParams') {
            switch (param) {
                case 'listUpdate':
                    this.stateChanged.next(true);
                    return false;
                case 'jobToDisplayUpdate':
                    this.stateChanged.next(true);
                    return false;
                default:
                    break;
            }
        }
        return true;
    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    get sideBarStatus(): string {
        return this.state.sideBarStatus;
    }

    set lastSideBarStatus(status) {
        this.state.lastSideBarStatus = status;
    }

    get lastSideBarStatus(): string {
        return this.state.lastSideBarStatus;
    }

    get report(): any {
        return this.state.report;
    }

    set selectedTab(selectedTab) {
        this.state.selectedTab = selectedTab;
    }

    get selectedTab() {
        return this.state.selectedTab;
    }

    get jobToDisplayId() {
        return this.state.jobToDisplayId;
    }

    set jobToDisplayId(jobToDisplayId) {
        this.state.jobToDisplayId = jobToDisplayId;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    set listSearchFilters(filters) {
        this.state.listSearchFilters = filters;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }

    set listColumns(cols) {
        this.state.listColumns = cols;
    }

    set selectedInfoQuestionTab(tabNumber) {
        this.state.selectedInfoQuestionTab = tabNumber;
    }

    get selectedInfoQuestionTab(): number {
        return this.state.selectedInfoQuestionTab;
    }

    set informationVisibility(visibility) {
        this.state.informationVisibility = visibility;
    }

    get informationVisibility(): boolean {
        return this.state.informationVisibility;
    }

    get reportBack(): any {
        return this.state.reportBack;
    }

    set reportBack(reportBack) {
        this.state.reportBack = reportBack;
    }
    set display(type) {
        this.state.display = type;
    }

    get tableDefinition(): any {
        this.state.listColumns = _.uniqBy(this.state.listColumns, 'key');
let listColumnsNonTrivial = _.compact(this.state.listColumns);
        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumnsInitialConfig;
        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            /** Todo use current language */
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }
}
