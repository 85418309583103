import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';

export class StateIdwMatrix extends AbstractState {

    // Structural elements ///////////////////////////////////////////////////////////////////////
    //  TODO: Nombre de candidats

    readonly columns = ['name', 'date', 'subAccountName', 'consultant', 'departmentName'];
    readonly columnsName = {
        fr: {
            name: 'Nom',
            date: 'Date',
            subAccountName: 'Sous-compte',
            departmentName: 'Département',
            'consultant': 'Consultant'
        },
        en: {name: 'Name',
            date: 'Date',
            subAccountName: 'Sub-account',
            departmentName: 'Department',
            'consultant': 'Consultant'}
    };

    readonly filters = [
        'search',
        'idwMatrixStartDate',
        'idwMatrixEndDate',
        'subAccount',
        'department'
    ];

    private listColumnsInitialConfig: Array<any> = [
        {key: 'name', display: true},
        {key: 'date', display: true, order: 'desc'}
    ];

    protected state: any = {
        sideBarStatus: 'recentActivities',
        lastSideBarStatus: 'recentActivities',
        report: {},
        listView: 'list',
        listSearchFilters: {},
        listColumns: this.listColumnsInitialConfig
    };

    protected store: any = {
        multi: {
            action: '',
            idType: '',
            ids: [],
            data: []
        }
    };

    set search(search) {
        this.listSearchFilters.search = search;
    }

    get search(): string {
        return this.listSearchFilters.search;
    }

    // MULTISELECT ///////////////////////////////////////////////////////////////////////////////

    public multiselect = [];

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    multiselectWatch = new BehaviorSubject(null);
    multiselectWatch$ = this.multiselectWatch.asObservable();

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    constructor(
        private translate
    ) {
        super();
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('idwMatrix.' + String(item));
            }
        }
    }

    /**
     * Partition state's parameter in function of the curent URL definitions i.e. if parameter x
     * is not required on URL y, then don't emit it, else do so.
     *
     * @param url
     * @param params
     */
    protected urlProcessing(url, params) {
        return params;
    }

    /**
     * Replace old state with the new state. Perform extra operations if needed.
     *
     * @param type
     * @param param
     * @param value
     */
    protected actionPostProcessing(type, param, value = null) {
        if (type === 'optionalParams') {
            switch (param) {
            case 'listUpdate':
                this.stateChanged.next({listUpdate: true});
                return false;
            case 'itemToDisplayUpdate':
                this.stateChanged.next({itemToDisplayUpdate: true});
                return false;
            case 'setSearchFilters':
                this.stateChanged.next({setSearchFilters: true});
                return false;
            default:
                break;
            }
        }
        return true;
    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    get sideBarStatus(): string {
        return this.state.sideBarStatus;
    }

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    set lastSideBarStatus(status) {
        this.state.lastSideBarStatus = status;
    }

    get lastSideBarStatus(): string {
        return this.state.lastSideBarStatus;
    }

    get report(): any {
        return this.state.report;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    set listSearchFilters(filters) {
        this.state.listSearchFilters = filters;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }



    set listColumns(cols) {
        this.state.listColumns = cols;
    }

    get multiAction(): any {
        return this.store.multi;
    }

    set multiAction(val) {
        this.store.multi = val;
    }

    resetMultiAction() {
        this.store.multi = {
            action: '',
            idType: '',
            ids: [],
            data: []
        };
    }

    get tableDefinition(): any {
        this.state.listColumns = _.uniqBy(this.state.listColumns, 'key');
let listColumnsNonTrivial = _.compact(this.state.listColumns);
        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumnsInitialConfig;
        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            /** Todo use current language */
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }
}
