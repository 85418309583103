import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';
import {AccountService} from '../../../administration/services/account.service';
import {AccountTypesHelper} from '../../../administration/commons/AccountTypesHelper';

export class StateAdministration extends AbstractState {

    // Structural elements ///////////////////////////////////////////////////////////////////////

    readonly columns = [
        'name',
        'distributor',
        'partner',
        'totalCredits',
        'creationDate',
        'renewalDate',
        'type',
        'status'
    ];
    readonly columnsName = {
        fr: {
            'name': 'Nom',
            'type': 'Type',
            'distributor': 'Distributeur',
            'partner': 'Partenaire',
            'creationDate': 'Date',
            'renewalDate': 'Renouvellement',
            'totalCredits': 'Crédits',
            'lastTransactionLevel': 'Utilisation',
            'status': 'Statut'
        },
        en: {
            'name': 'Name',
            'type': 'Type',
            'distributor': 'Distributor',
            'lastTransactionLevel': 'Usage',
            'creationDate': 'Date',
            'renewalDate': 'Renewal',
            'status': 'Status',
            'partner': 'Partner',
            'totalCredits': 'Credits'
        }
    };
    readonly filters = [
        'search',
        'skip',
        'limit',
        'accountTypes',
        'isArchived',
        'isEnabled',
        'accountNumberIsUpInHierarchy',
        'clientStartDate',
        'clientEndDate'
    ];

    private listColumnsInitialConfig: any = [
        {key: 'name', display: true},
        {key: 'type', display: true},
        {key: 'subAccounts.0.name', display: false},
        {key: 'distributor', display: true},
        {key: 'hierarchy.distributor.accountName', display: false},
        {key: 'partner', display: false},
        {key: 'hierarchy.partner.accountName', display: false},
        {key: 'subAccounts.0.creationDate', display: false, order: 'desc'},
        {key: 'creationDate', display: true},
        {key: 'subAccounts[0].mpoProducts.renewalDate', display: false},
        {key: 'renewalDate', display: true},
        {key: 'status', display: true},
        {key: 'totalCredits', display: true},
        {key: 'lastTransactionLevel', display: true}
    ];

    public accountType;

    ////// STATE DEFINITION //////////////////////////////////////////////////////////////////////

    protected state: any = {
        sideBarStatus: 'recentActivities',
        listSearchFilters: {
            search: '',
            accountTypes: Array,
            archived: false,
            isEnabled: true,
            dateStart: null,
            dateEnd: null,
            subsidiary: null,
            distributor: null,
            partner: null
        },
        listView: 'list',
        listColumns: this.listColumnsInitialConfig,
        clientToDisplayId: null,
        client: null,
        typeSelected: 'clients'
    };

    // TODO: added for refactoring

    get search(): string {
        return this.listSearchFilters.search;
    }

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    clientToDisplayWatch = new BehaviorSubject(null);
    clientToDisplayWatch$ = this.clientToDisplayWatch.asObservable();

    listWatch = new BehaviorSubject(null);
    listWatch$ = this.listWatch.asObservable();

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    // CONSTRUCTOR ///////////////////////////////////////////////////////////////////////////////

    constructor(
        private translate
    ) {
        super();
        // Initialization from sessionStorage
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('administration.' + String(item));
            }
        }
    }

    // STATE LOGIC ///////////////////////////////////////////////////////////////////////////////

    actionPreProcessing(action, params, modState, url = null) {
        switch (action) {
        case 'getFilteredList':
            modState.listSearchFilters.search = params.search;
            modState.listSearchFilters.accountTypes = params.accountTypes;
            modState.listSearchFilters.isArchived = params.isArchived;
            modState.listSearchFilters.isEnabled = params.isEnabled;
            modState.listSearchFilters.subsidiary = params.subsidiary;
            modState.listSearchFilters.distributor = params.distributor;
            modState.listSearchFilters.partner = params.partner;
            modState.listSearchFilters.accountNumberIsUpInHierarchy = params.accountNumberIsUpInHierarchy;
            modState.listSearchFilters.clientEndDate = params.clientEndDate;
            modState.listSearchFilters.clientStartDate = params.clientStartDate;

            modState.typeSelected = params.typeSelected;
            modState['listUpdate'] = '';
            return [url, modState];
        case 'resetSearchFilters':
            let accountTypes = AccountTypesHelper.setCurrentAccountTypesArray(this.accountType);
            if (params && params.userPreferences && params.userPreferences.admin) {
                if (params.userPreferences.admin.search && params.userPreferences.admin.search.accountTypes) {
                    accountTypes = params.userPreferences.admin.search.accountTypes;
                }
            }

            modState.listSearchFilters = {
                search: '',
                accountTypes: accountTypes,
                archived: false,
                status: true,
                dateStart: null,
                dateEnd: null,
                subsidiary: null,
                distributor: null,
                partner: null
            };
            modState.listColumns = this.listColumnsInitialConfig;
            if (!params['noRefresh']) {
                modState['listUpdate'] = '';
            }
            return [url, modState];

        case 'setSideBarStatus':
            modState['sideBarStatus'] = params.sideBarStatus;
            return [url, modState];

        case 'setSearchFilters':
            for (let filter of this.filters) {
                if (filter) {
                    if (params[filter] !== undefined) {
                        modState.listSearchFilters[filter] = params[filter];
                    }
                }
            }
            modState['listUpdate'] = '';
            return [url, modState];

        case 'setListConfig':
            if (typeof(params.listView) !== 'undefined' &&
                params.listView !== 'list' &&
                params.listView !== 'cards'
            ) {
                throw 'Illegal view for setter StateArchivePeople._listView';
            }
            // View
            if (params.listView) {
                modState.listView = params.listView;
            }

            // Display
            if (params['key'] && params['display'] != null) { // visibility
                let indexToModify = this.columns.indexOf(params['key']);
                if (params['display']) {
                    modState.listColumns[indexToModify] = {
                        key: params['key'],
                        display: params['display']
                    };
                } else {
                    modState.listColumns.splice(indexToModify, 1);
                }
                // Order
            } else if (params['key'] !== null && params['order']) {
                switch (params['key']) {
                    case 'subAccounts[0].name':
                        params['key'] = 'subAccounts.0.name';
                        break;
                    case 'hierarchy[\'distributor\'].accountName':
                        params['key'] = 'hierarchy.distributor.accountName';
                        break;
                    case 'hierarchy[\'partner\'].accountName':
                        params['key'] = 'hierarchy.partner.accountName';
                        break;
                    case 'subAccounts[0].creationDate':
                        params['key'] = 'subAccounts.0.creationDate';
                        break;
                }
                // tslint:disable-next-line:forin
                for (let i in this.state.listColumns) {
                    let column = this.state.listColumns[i];
                    if (column && column.key === params['key']) {
                        column['order'] = params['order'];
                        modState.listColumns[i] = column;
                    } else {
                        if (column && column['order']) {
                            delete column['order'];
                            modState.listColumns[i] = column;
                        } else {
                            modState.listColumns[i] = column;
                        }
                    }
                }
            }
            modState['listUpdate'] = '';
            return [url, modState];

        case 'fullClient':
            modState.report = params;

            if (params.hasOwnProperty('subAccountId')) {
                url = url + '/' + params.clientToDisplayId;
            } else {
                url = url + '/' + params.clientToDisplayId;
            }

            return [url, modState];
        case 'setClient':
            if (params.clientToDisplayId === null) {
                throw 'clientTodisplayId must be non-null in setCient';
            }
            modState.clientToDisplayId = params['clientToDisplayId'];
            modState['sideBarStatus'] = 'clients';
            modState['clientUpdate'] = '';
            return [url, modState];
        default:
            throw 'Inexistent action on state-administration.preProcessing';
        }
    }

    ////////////////////////////////////////////////////////////////

    urlProcessing(url, params) {
        return params;
    }

    ////////////////////////////////////////////////////////////////
    actionPostProcessing(type, param, value) {
        if (type === 'optionalParams') {
            switch (param) {
            case 'listUpdate':
                this.listWatch.next(true);
                return false;
            case 'clientUpdate':
                this.clientToDisplayWatch.next(true);
                return false;
            default:
                break;
            }
        } else if (type === 'mendatoryParams') {
            switch (param) {
            case 'clientToDisplayId':
                this.state['clientToDisplayId'] = value;
                break;
            default:
                break;
            }
        }
        return true;

    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    // A "nuller"?
    forgetClientToDisplayId(): any {
        this.state.clientToDisplayId = null;
    }

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    get sideBarStatus(): string {
        return this.state.sideBarStatus;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    set listSearchFilters(filters) {
        this.state.listSearchFilters = filters;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }

    set listColumns(listColumns) {
        this.state.listColumns = listColumns;
    }

    get clientToDisplayId(): any {
        return this.state.clientToDisplayId;
    }

    set clientToDisplayId(id) {
        this.state.clientToDisplayId = id;
    }

    get typeSelected(): any {
        return this.state.typeSelected;
    }

    get tableDefinition(): any {
        this.state.listColumns = _.uniqBy(this.state.listColumns, 'key');
        const statusIacIndex = this.state.listColumns.findIndex(col => col.key === 'statusIac');
        if (statusIacIndex > -1) {
            // remove statusIac from the list if it was found
            this.state.listColumns.splice(statusIacIndex, 1);
        }
        let listColumnsNonTrivial = _.compact(this.state.listColumns);
        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumnsInitialConfig;
        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            /** Todo use current language */
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }
}
