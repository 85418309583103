import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import * as d3 from "d3";

@Component({
    selector: 'app-right-match-score-graph',
    templateUrl: './right-match-score-graph.component.html',
    styleUrls: ['./right-match-score-graph.component.scss']
})
export class RightMatchScoreGraphComponent implements OnInit {

    @ViewChild('RightMatchScoreGraph', {static: true}) private chartContainer: ElementRef;
    @Input() score: number;

    constructor() {
    }

    ngOnInit() {
        this.createRightMatchScoreGraph();
    }

    createRightMatchScoreGraph() {
        let category = Math.round(this.score / 100);
        let element = this.chartContainer.nativeElement;
        let svg = d3.select(element).append('svg').attr("preserveAspectRatio", "xMidYMid meet").
            attr("width", 180).
            attr("height", 180).
            attr("viewBox", "0 0 180 180");

        let endAngle = 2 * Math.PI * category / 5;
        if (category == 0) {
            endAngle = 0.1;
        }

        svg.append("text").attr("x", 90).
            attr("y", 100).
            text(category).
            attr("text-anchor", "middle").
            attr("font-family", "sans-serif").
            attr("font-size", 40).
            attr("fill", "rgb(227, 94, 36)");

        let arcBG = d3.arc().innerRadius(50).outerRadius(70).startAngle(0).endAngle(2 * Math.PI);

        svg.append("path").attr("transform", "translate(90,90)").attr("class", "arc").attr("d", arcBG).attr("fill", "#EEE");

        let arc = d3.arc().innerRadius(50).outerRadius(70).startAngle(0).endAngle(endAngle);

        svg.append("path").attr("transform", "translate(90,90)").attr("class", "arc").attr("d", arc).attr("fill", "#AAA");
    }
}
