type ParseMatchFunction = (match: string) => string;
type StringReplacerFunction = (substring: string, ...args: any[]) => string;

interface Rule {
    regex: RegExp;
    replacer: StringReplacerFunction | string;
}
export class MarkdownToHtmlHelper {
    static markdownToHtml(markdown: string): string {
        return MarkdownToHtmlHelper.parse(markdown);
    }

    /**
     * Converts mardown block elements to corresponding HTML
     */
    private static block(text: string) {
        return MarkdownToHtmlHelper.processMarkdown(
            text,
            [
                { regex: /<!--((.|\n)*?)-->/g, replacer: "<!--$1-->" },

                // pre format block
                {
                    regex: /^("""|```)(.*)\n((.*\n)*?)\1/gm,
                    replacer: (_, wrapper, classNames, text) =>
                        wrapper === '"""'
                            ? MarkdownToHtmlHelper.tag(
                                  "div",
                                  MarkdownToHtmlHelper.parse(text),
                                  { class: classNames }
                              )
                            : MarkdownToHtmlHelper.tag(
                                  "pre",
                                  MarkdownToHtmlHelper.tag(
                                      "code",
                                      MarkdownToHtmlHelper.encodeHtml(text),
                                      { class: classNames }
                                  )
                              ),
                },

                // blockquotes
                {
                    regex: /(^>.*\n?)+/gm,
                    replacer: MarkdownToHtmlHelper.chain(
                        "blockquote",
                        /^> ?(.*)$/gm,
                        "$1",
                        MarkdownToHtmlHelper.inline
                    ),
                },

                // tables
                {
                    regex: /((^|\n)\|.+)+/g,
                    replacer: MarkdownToHtmlHelper.chain(
                        "table",
                        /^.*(\n\|---.*?)?$/gm,
                        (match, subline) =>
                            MarkdownToHtmlHelper.chain(
                                "tr",
                                /\|(-?)([^|]+)\1(\|$)?/gm,
                                (match, type, text) =>
                                    MarkdownToHtmlHelper.tag(
                                        type || subline ? "th" : "td",
                                        MarkdownToHtmlHelper.inlineBlock(text)
                                    )
                            )(
                                match.slice(
                                    0,
                                    match.length - (subline || "").length
                                )
                            )
                    ),
                },

                // lists
                {
                    regex: /(?:(^|\n)([+-]|\d+\.) +(.*(\n[\t ]+.*)*))+/g,
                    replacer: MarkdownToHtmlHelper.list,
                },

                //anchor
                { regex: /#\[([^\]]+?)]/g, replacer: '<a name="$1"></a>' },

                // headlines
                {
                    regex: /^(#+) +(.*)$/gm,
                    replacer: (_, headerSyntax, headerText) =>
                        MarkdownToHtmlHelper.tag(
                            `h${headerSyntax.length}`,
                            MarkdownToHtmlHelper.inlineBlock(headerText)
                        ),
                },

                // horizontal rule
                { regex: /^(===+|---+)(?=\s*$)/gm, replacer: "<hr>" },
            ],
            MarkdownToHtmlHelper.parse
        );
    }

    /**
     * Chain string replacement methods and output a function that returns the tag
     * representation of the match.
     */
    private static chain(
        tagName: string,
        regex: RegExp,
        replacer: string | StringReplacerFunction,
        parser?: ParseMatchFunction
    ): ParseMatchFunction {
        return (match: string) => {
            match = match.replace(regex, replacer as string);
            return MarkdownToHtmlHelper.tag(
                tagName,
                parser ? parser(match) : match
            );
        };
    }

    /**
     * Encode html tags within the markdown output.
     */
    private static encodeHtml(text: string) {
        return text
            ? text
                  .replace(/"/g, "&quot;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
            : "";
    }

    /**
     * Converts inline markdown syntax to HTML
     */
    private static inline(text: string): string {
        return MarkdownToHtmlHelper.processMarkdown(
            text,
            [
                // - Bold => `**bold**`
                // - Italic => `*italic*` | `_italic_`
                // - Bold and Italic => `**_mixed_**` TODO this doesn't check for
                //   correctly matching tags.
                {
                    regex: /([*_]{1,3})((.|\n)+?)\1/g,
                    replacer: (_, tokens, content) => {
                        tokens = tokens.length;
                        content = MarkdownToHtmlHelper.inline(content);

                        if (tokens > 1) {
                            content = MarkdownToHtmlHelper.tag(
                                "strong",
                                content
                            );
                        }

                        if (tokens % 2) {
                            content = MarkdownToHtmlHelper.tag("em", content);
                        }

                        return content;
                    },
                },

                // - Underline => `~underline~`
                // - Strikethrough => `~~strike-through~~`
                // - Delete => `~~~delete~~`
                {
                    regex: /(~{1,3})((.|\n)+?)\1/g,
                    replacer: (_, tokens, content) =>
                        MarkdownToHtmlHelper.tag(
                            ["u", "s", "del"][tokens.length - 1],
                            MarkdownToHtmlHelper.inline(content)
                        ),
                },

                // - Replace remaining lines with a break tag => `<br />`
                { regex: / {2}\n|\n {2}/g, replacer: "<br />" },
            ],
            MarkdownToHtmlHelper.inline
        );
    }

    private static isString(value: any): value is string {
        return typeof value === "string";
    }

    /**
     * Converts inline code blocks and inline media
     * (links, images, and iframes)
     */
    private static inlineBlock(text = "", shouldInline = true) {
        // A collection of all the tags created so far.
        const gatheredTags: string[] = [];

        function injectInlineBlock(text: string): string {
            return text.replace(/\\(\d+)/g, (match, code) =>
                injectInlineBlock(gatheredTags[Number.parseInt(code, 10) - 1])
            );
        }

        text = text
            .trim()
            // inline code block
            .replace(
                /`([^`]*)`/g,
                (_, text) =>
                    `\\${gatheredTags.push(
                        MarkdownToHtmlHelper.tag(
                            "code",
                            MarkdownToHtmlHelper.encodeHtml(text)
                        )
                    )}`
            )
            // inline media (a / img / iframe)
            .replace(
                /[!&]?\[([!&]?\[.*?\)|[^\]]*?)]\((.*?)( .*?)?\)|(\w+:\/\/[\w!$'()*+,./-]+)/g,
                (match, text, href, title, link) => {
                    if (link) {
                        return shouldInline
                            ? `\\${gatheredTags.push(
                                  MarkdownToHtmlHelper.tag("a", link, {
                                      href: link,
                                  })
                              )}`
                            : match;
                    }

                    if (match[0] === "&") {
                        text = text.match(/^(.+),(.+),([^ \]]+)( ?.+?)?$/);
                        return `\\${gatheredTags.push(
                            MarkdownToHtmlHelper.tag("iframe", "", {
                                width: text[1],
                                height: text[2],
                                frameborder: text[3],
                                class: text[4],
                                src: href,
                                title,
                            })
                        )}`;
                    }

                    return `\\${gatheredTags.push(
                        match[0] === "!"
                            ? MarkdownToHtmlHelper.tag("img", "", {
                                  src: href,
                                  alt: text,
                                  title,
                              })
                            : MarkdownToHtmlHelper.tag(
                                  "a",
                                  MarkdownToHtmlHelper.inlineBlock(text, false),
                                  { href, title }
                              )
                    )}`;
                }
            );

        text = injectInlineBlock(shouldInline ? MarkdownToHtmlHelper.inline(text) : text);
        return text;
    }

    /**
     * Handle lists in markdown.
     */
    private static list(text: string) {
        return text.replace(
            /^(\s*)([*+-]|\d+\.) [\s\S]+?(?:\n{2,}(?! )(?=\S)|\s*$)/gm,
            (wholeList, m1, m2) => {
                const listType = m2.match(/\d+\./) ? "ol" : "ul";
                const start = m2.match(/\d+\./) ? parseInt(m2, 10) : undefined;

                let result = MarkdownToHtmlHelper.processMarkdown(
                    wholeList,
                    [
                        {
                            regex: /^(\s*)([*+-]|\d+\.) +([^\n]*)(?:\n(?!\1(?:[*+-]|\d+\.) )([\s\S]*))?/gm,
                            replacer: (_, m1, m2, item, rest) =>
                                `<li>${MarkdownToHtmlHelper.inlineBlock(
                                    item
                                )}${MarkdownToHtmlHelper.list(
                                    rest || ""
                                )}</li>`,
                        },
                    ],
                    (text) => text
                );

                result =
                    "\n" +
                    m1 +
                    `<${listType}${
                        start ? ` start="${start}"` : ""
                    }>${result}\n${m1}</${listType}>`;

                return result;
            }
        );
    }

    private static parse(text: string): string {
        text = text
            .replace(/[\b\v\f\r]/g, "")
            .replace(/\\./g, (match) => `&#${match.charCodeAt(1)};`);

        let temp = MarkdownToHtmlHelper.block(text);

        if (temp === text && !temp.match(/^\s*$/i)) {
            temp = MarkdownToHtmlHelper.inlineBlock(temp)
                // handle paragraphs
                .replace(/((.|\n)+?)(\n\n+|$)/g, (match, text) =>
                    MarkdownToHtmlHelper.tag("p", text)
                );
        }

        return temp.replace(/&#(\d+);/g, (_, code) =>
            String.fromCharCode(Number.parseInt(code, 10))
        );
    }

    /**
     * Process the markdown with the rules provided.
     */
    private static processMarkdown(
        text: string,
        rules: Rule[],
        parse: ParseMatchFunction
    ) {
        for (const rule of rules) {
            const { regex, replacer } = rule;
            const content = regex.exec(text);

            // No content found for the current rule therefore we can move to the next
            // one.
            if (!content) {
                continue;
            }

            // Keep track of where the original content ended in relation to the text
            // provided.
            const endOfContentIndex = content.index + content[0].length;

            const textBeforeHtmlReplacement = parse(
                text.slice(0, content.index)
            );
            const textAfterHtmlReplacement = parse(
                text.slice(endOfContentIndex)
            );

            // The replacement text that has been transformed to HTML.
            let htmlReplacement: string;

            if (MarkdownToHtmlHelper.isString(replacer)) {
                // String `Replacer`s only support replacing the first digit - like `$1`.
                htmlReplacement = replacer.replace(
                    /\$(\d)/g,
                    (_, firstDigit) => content[firstDigit]
                );
            } else {
                // With function `Replacer`s the whole match and all content is provided
                const [fullMatch, ...rest] = content;
                htmlReplacement = replacer(fullMatch, ...rest);
            }

            return `${textBeforeHtmlReplacement}${htmlReplacement}${textAfterHtmlReplacement}`;
        }

        // No matches found in loop so we can return the text unchanged.
        return text;
    }

    /**
     * Create a tag with the content provided.
     */
    private static tag(
        tag: string,
        text: string,
        attributes?: Record<string, string>
    ): string {
        return `<${
            tag +
            (attributes
                ? ` ${Object.keys(attributes)
                      .map((k) =>
                          attributes[k]
                              ? `${k}="${
                                    MarkdownToHtmlHelper.encodeHtml(
                                        attributes[k]
                                    ) || ""
                                }"`
                              : ""
                      )
                      .join(" ")}`
                : "")
        }>${text}</${tag}>`;
    }
}
