// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobsProfilerLabels h4 {
  padding: 0;
}

.jobsProfilerInputs input {
  width: 70%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  text-align: center;
  margin-top: 3px;
  padding-top: 0 !important;
}

.jobsProfilerFooter button:first-child {
  margin-right: 10px;
}

::ng-deep .jobsProfilerOptions {
  min-width: calc(100% + 20px) !important;
}

::ng-deep .jobsProfilerLabels .mat-grid-tile .mat-figure {
  justify-content: center;
}

::ng-deep .jobsProfilerInputs .mat-grid-tile .mat-figure {
  align-items: flex-start;
}

::ng-deep .jobsProfilerFooter .mat-grid-tile .mat-figure {
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/mobile/mobile-jobs-profiler/mobile-jobs-profiler.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,gDAAA;EACA,wEAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".jobsProfilerLabels h4 {\n    padding: 0;\n}\n\n.jobsProfilerInputs input {\n    width: 70%;\n    border: 1px solid #cccccc;\n    border-radius: 4px;\n    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);\n    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;\n    text-align: center;\n    margin-top: 3px;\n    padding-top: 0 !important;\n}\n\n.jobsProfilerFooter button:first-child {\n    margin-right: 10px;\n}\n\n::ng-deep .jobsProfilerOptions {\n    min-width: calc(100% + 20px) !important;\n}\n\n::ng-deep .jobsProfilerLabels .mat-grid-tile .mat-figure {\n    justify-content: center;\n}\n\n::ng-deep .jobsProfilerInputs .mat-grid-tile .mat-figure {\n    align-items: flex-start;\n}\n\n::ng-deep .jobsProfilerFooter .mat-grid-tile .mat-figure {\n    align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
