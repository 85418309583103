import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TalentsDefinitions} from '../../../../shared/talents/talentsDefinitions';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {JobService} from '../../../services/job.service';
import {StateService} from '../../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ComponentPrimitive} from '../../../../shared/classes/components/componentPrimitive';

@Component({
    selector: 'app-jobs-report-talents',
    templateUrl: './jobs-report-talents.component.html',
    styleUrls: ['./jobs-report-talents.component.scss']
})
export class JobsReportTalentsComponent extends ComponentPrimitive implements OnInit, OnDestroy {

    @Input() mobile = false;
    @Output() openModTalent = new EventEmitter();
    competencesOrdered: any = [];
    talents: any;
    talentsTexts: any;
    talentsPolarities: any;
    interviewQuestions: any;
    titleSep: string;
    reportDateFormat: string;

    private subscriptions = new Subscription();

    constructor(
        public translation: TranslateService,
        public jobService: JobService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected stateService: StateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translation, router, deviceService);
    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translation.currentLang);
        this.reportDateFormat = TextHelper.dateFormat(this.translation.currentLang);
        this.checkTalentsDefinitions();
        this.checkCompetences();

        this.jobState.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.checkCompetences();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.translation.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.jobService.getCompetencies(this.jobService.id);
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.reportDateFormat = TextHelper.dateFormat(res.lang);
                    this.checkTalentsDefinitions();
                    this.checkCompetences();
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    checkTalentsDefinitions() {
        this.talents = new TalentsDefinitions().talents[this.translation.currentLang];
        this.talentsTexts = new TalentsDefinitions().talentsTxt[this.translation.currentLang];
        this.talentsPolarities = new TalentsDefinitions().talentsPolarities[this.translation.currentLang];
        this.interviewQuestions = new TalentsDefinitions().interviewQuestions[this.translation.currentLang];
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    checkCompetences() {
        // fuseTalentName(talents[competence[0]]), competence[1], talentsTexts[competence[0]], talentsPolarities[competence[0]].high, talentsPolarities[competence[0]].low
        let competences = [];
        for (let competenceItem of this.jobService.competences) {
            // tslint:disable-next-line:forin
            for (let key in competenceItem) {
                if (competenceItem.hasOwnProperty(key)) {
                    let questions = [];
                    for (let question of this.interviewQuestions[+key]) {
                        questions.push(question);
                    }
                    competences.push(
                        [
                            this.fuseTalentName(this.talents[+key]),
                            competenceItem[key],
                            this.talentsTexts[+key],
                            this.talentsPolarities[+key].high,
                            this.talentsPolarities[+key].low,
                            questions
                        ]
                    );
                }
            }
        }
        this.competencesOrdered = competences.sort(this.orderTalents);
    }

    onResize() {
    }

    orderTalents(a, b) {
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) < 0) {
            return -1;
        }
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) > 0) {
            return 1;
        }
        return 0;
    }

    fuseTalentName(talent) {
        if (talent.length > 1) {
            return talent.join(' ');
        }
        return talent[0];
    }

    modTalent() {
        this.openModTalent.emit();
    }

    get jobState(): any {
        return this.stateService.jobs;
    }

    get talentsScores() {
        if (this.jobService.competences) {
            return this.jobService.competences[0];
        }
        return;
    }

}
