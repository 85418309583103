import * as _ from 'lodash';

export class ProductsHelper {
    public static productsGroupsNames(country = 'Canada') {
        if (country === 'Canada') {
            return ['MPOENT', 'MPOENTPR', 'MPOFSA', 'MPOFSAINT', 'MPOFORM', 'MPOPERS', 'MPOPRO'];
        }
        return ['MPOENTPR'];
    }

    public static createSkuOrder(availableProducts) {
        for (let i in availableProducts) {
            if (availableProducts.hasOwnProperty(i) && availableProducts[i].sku) {
                availableProducts[i].skuOrder = availableProducts[i].sku.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
                if (availableProducts[i].skuOrder[1]) {
                    availableProducts[i].skuOrder[1] = parseInt(availableProducts[i].skuOrder[1], 10);
                } else {
                    availableProducts[i].skuOrder[1] = 0;
                }
                if (!availableProducts[i].skuOrder[2]) {
                    availableProducts[i].skuOrder[2] = '';
                }
            }
        }

        return _.sortBy(
            availableProducts,
            ['skuOrder.0', 'skuOrder.2', 'skuOrder.1'],
            ['asc', 'asc', 'asc']
        );
    }

    public static productsGroups() {
        return {
            Canada: {
                MPOENT: [],
                MPOENTPR: [],
                MPOFSA: [],
                MPOFSAINT: [],
                MPOFORM: [],
                MPOPERS: [],
                MPOPRO: []
            },
            US: {
                MPOENTPR: []
            }
        };
    }
}
