import {Content} from './content';
import {StateService} from '../../../core/services/state/state.service';
import {SearchHelper} from '../../misc/search.helper';
import {Router} from '@angular/router';
import {ApplicationRef} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';

export abstract class ContentList extends Content {

    abstract url;
    abstract _opened;
    abstract switchOff: boolean;
    abstract clickMode: boolean;

    abstract columns;
    abstract listColumns;

    protected constructor(
        public stateService: StateService,
        protected router: Router,
        protected applicationRef: ApplicationRef,
        protected deviceService: DeviceDetectorService
    ) {
        super(router, deviceService, stateService);
    }

    switchMode() {
        this.clickMode = !this.clickMode;
        let newState = 'clickMode';
        if (this.sideBarStatus === newState) {
            this.clickMode = false;
        }
        if (this.clickMode) {
            this.state.sideBarStatus = newState;

            // open sidebar tablet device
            if (this.smallSize) {
                this._opened = true;
            }
        }  else { // reset
            this.cleanMultiselectMode(true);
            let goToReport = false;
            if (this.actualModule.name === 'people') {
                goToReport = (this.stateService.people.personToDisplayId !== null);
            }
            if (goToReport) {
                this.state.sideBarStatus = 'reports';
                this.state.report = {
                    type: this.state.reportType,
                    subType: null
                };
            } else {
                this.state.sideBarStatus = 'recentActivities';
                if (this._opened && this.smallSize) {
                    this._opened = false;
                }
            }
        }
        this.state.stateChanged.next({
            refreshList: true
        });
    }

    truncateDate(list) {
        // tslint:disable-next-line:forin
        for (let record in list['data']) {
            if (list['data'].hasOwnProperty(record)) {
                if (list.data[record].hasOwnProperty('date')) {
                    list.data[record]['date'] = list.data[record]['date'].substring(0, 10);
                }
                if (list['data'][record].hasOwnProperty('creationDate')) {
                    list['data'][record]['date'] = list.data[record]['creationDate'].substring(0, 10);
                }
            }
        }
        return list;
    }

    /**
     * Will use sessionData to transform the subAccount number into subAccount's name
     *
     * @param list
     * @returns any
     */
    setSubAccountName(list) {
        for (let record in list['data']) {
            if (list['data'].hasOwnProperty(record)) {
                if (this.sessionData.structure.subAccounts) {
                    list.data[record]['subAccountName'] = _.find(this.sessionData.structure.subAccounts, function (o) {
                        return (o.id === list.data[record].subAccount) ? o : undefined;
                    }).name;
                } else {
                    list.data[record]['subAccountName'] = '[resync data]';
                }
            }
        }
        return list;
    }

    revertToNews() {
        this.state.sideBarStatus = 'recentActivities';
    }

    transformDate(date): any {
        let dateArray = date.split('-');
        return {
            date: {
                year: parseInt(dateArray[0], 10),
                month: parseInt(dateArray[1], 10),
                day: parseInt(dateArray[2], 10)
            }
        };
    }

    cleanMultiselectMode(close) {
        this.state.multiselect = [];
        this.clickMode = false;
        if (close && this._opened && this.smallSize) {
            this._opened = false;
        }
    }

    toggleFilterPanel(): void {
        let sideBarStatus = (this.sideBarStatus === 'searchFilters') ? 'recentActivities' : 'searchFilters';
        this.sideBarStatus = sideBarStatus;
        this.stateService[this.actualModule.name].stateChanged.next({sideBarStatus: sideBarStatus});
        // this.switchOffFilterPanelIndicator(true, true);
        this.switchOff = false;
        this.clickMode = false;
        this.applicationRef.tick();
        // open sidebar
        this._opened = true;
    }

    switchOffFilterPanelIndicator(event, unselect = false) {
        this.switchOff = event;
        if (event && this._opened && this.smallSize) {
            this._opened = false;
        }
        this.applicationRef.tick();
        if (unselect) {
            this.switchMode();
        } else {
            this.stateService[this.actualModule.name].sideBarStatus = 'reports';
        }
    }

    setOrder(params) {
        // console.log(params, this.state.listColumns);
        if (params['key'] && params['order'] !== null && (typeof params['order'] !== 'undefined')) {
            // tslint:disable-next-line:forin
            let index = _.findIndex(this.state.listColumns, function (o) {
                if (o) {
                    return o.key === params['key'];
                }
            });

            for (let indexRemoved in this.state.listColumns) {
                if (
                    this.state.listColumns.hasOwnProperty(indexRemoved) &&
                    this.state.listColumns &&
                    this.state.listColumns[indexRemoved] && (
                        this.state.listColumns[indexRemoved]['order'] === 'asc' ||
                        this.state.listColumns[indexRemoved]['order'] === 'desc'
                    )
                ) {
                    delete this.state.listColumns[indexRemoved]['order'];
                }
            }
            let column = this.state.listColumns[index];
            if (column) {
                column['order'] = params['order'];
                this.state.listColumns[index] = column;
            }
            // console.log(this.state.listColumns[index]);
        }
    }

    checkReportType() {
        if (this.hasSatelliteRole) {
            this.state.reportType = 'satellite';
        }
    }

    checkSideBarStatus() {
        if (this.sideBarStatus === '') {
            this.state.sideBarStatus = 'recentActivities';
        }
    }

    initListAction() {
        this.state.listAction = '';
    }



    private findIndex(param) {
        return _.findIndex(this.listColumns, function (o) {
            if (o) {
                return o.key === param;
            }
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Mutators ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    get sessionData() {
        if (this.stateService.session) {
            return this.stateService.session.sessionData;
        }
        return;
    }

    get accountData() {
        return (this.sessionData) ? this.sessionData.accountData : null;
    }

    get userData() {
        return (this.sessionData) ? this.sessionData.userData : null;
    }

    get departments() {
        return (this.sessionData && this.sessionData.structure) ?
            this.sessionData.structure.departments :
            null;
    }

    get hasSatelliteRole() {
        if (this.stateService.session) {
            return this.stateService.session.hasSatelliteRole;
        }
        return;
    }

    set listSearchFilters(event) {
        this.state.listSearchFilters.search = event;
    }

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    get sideBarStatus() {
        return this.state.sideBarStatus;
    }

    set redirectTo(value) {
        this.state.report.redirectTo = value;
    }

    get redirectTo() {
        return this.state.report.redirectTo;
    }

    get listSearchFilters() {
        return this.state.listSearchFilters.search;
    }

    get evaluationStartDate() {
        return (this.state && this.state.listSearchFilters) ?
            this.state.listSearchFilters.evaluationStartDate :
            null;
    }

    get evaluationEndDate() {
        return (this.state && this.state.listSearchFilters) ?
            this.state.listSearchFilters.evaluationEndDate :
            null;
    }

    get selectionDone() {
        return SearchHelper.selectionDone(this.state);
    }

    get selectionByFilters() {
        return SearchHelper.selectionDone(this.state, 'search');
    }

}
