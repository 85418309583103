// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top {
  background-color: rgb(241, 241, 241);
  top: 0;
}

.tRight {
  height: 100vh;
}

.listingCell {
  border-top: none;
  padding: 0;
}

.listingCellName {
  width: calc(100% - 15px);
}

.listingCellX {
  width: 15px;
}

.unselectable {
  color: #e45656;
}

.errorMessage {
  position: relative;
}

.errorMark {
  position: absolute;
  border-radius: 9px;
  background: #f07b7e;
  padding: 0;
  width: 9px;
  height: 9px;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-selected-items/people-selected-items.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,MAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;AACJ","sourcesContent":[".top {\n    background-color: rgb(241, 241, 241);\n    top: 0;\n}\n\n.tRight {\n    height: 100vh;\n}\n\n.listingCell {\n    border-top: none;\n    padding:0;\n}\n\n.listingCellName {\n    width: calc(100% - 15px);\n}\n\n.listingCellX {\n    width: 15px;\n}\n\n.unselectable {\n    color: #e45656;\n}\n\n.errorMessage {\n    position: relative;\n}\n\n.errorMark {\n    position: absolute;\n    border-radius: 9px;\n    background: #f07b7e;\n    padding: 0;\n    width: 9px;\n    height: 9px;\n    top: 0;\n    left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
