import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    OnDestroy
} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {JobReportService} from '../../../services/job-report.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
// import {TalentsDefinitions} from '../../../../shared/talents/talentsDefinitions';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {ComponentFullView} from 'src/app/shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';
// import {environment} from '../../../../../environments/environment';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {TalentsDefinitions} from '../../../../shared/talents/talentsDefinitions';

@Component({
    selector: 'app-jobs-report',
    templateUrl: './mobile-jobs-report.component.html',
    styleUrls: ['./mobile-jobs-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileJobsReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    onDestroy$: Subject<null> = new Subject();

    buttonsFolder = '/assets/buttons/';
    display = 'report';
    pdfRequest = false;
    editRequest = false;
    actStatus = 'new';
    profilerEmailData: any;
    // profilerFormUrl: string = environment.profilerFormUrl;
    nativeWindow: any;
    resendData: any;
    expandInfoPanel = false;
    sendQByEmail = false;
    resendQ = false;
    activationRequestP = false;
    activationRequestC = false;
    fillQuickRA = false;
    refresh = false;
    tasksList: boolean;
    isSatellite: boolean;
    personToDisplayId: any;
    currentLang = 'fr';
    isLandscape = false;
    showPortraitButtons = false;
    idleTime = 0;
    // idleInterval;

    // Jobs
    jobType: string;
    talentsScoresKeys: Array<any> = undefined;
    talentsName: any;
    mpoGraphType = 'plain';
    mpoDate: number;
    report;
    sessionData: any;

    actualPage = 1;
    maxPerPage = 5;
    maxPage: any = null;

    orderedTalents = [];
    activationData = {
        reportType: undefined,
        status: undefined
    };
    reportType;
    titleSep: string;
    jobDateFormat: string;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        protected router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private apiJobs: ApiJobsService,
        public stateService: StateService,
        public translate: TranslateService,
        private jobReportService: JobReportService,
        public jobService: JobService,
        public windowRef: WindowRef,
        protected deviceService: DeviceDetectorService,
        private alertService: AlertService
    ) {
        super(stateService, translate, router, deviceService);
        this.nativeWindow = windowRef.getNativeWindow();
    }

    @HostListener('window:mousemove') refreshUserState() {
        this.idleTime = 0;
    }

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
        // if (this.showPortraitButtons) {
        //     this.idleInterval = setInterval(() => {
        //         this.idleTime++;
        //         if (this.idleTime > 3) { // 3 sec
        //             this.showPortraitButtons = false;
        //             clearInterval(this.idleInterval);
        //             this.changeDetectorRef.markForCheck();
        //         }
        //     }, 1000);
        // }
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    get state(): any {
        return this.stateService.jobs;
    }

    get raPermission() {
        return (this.stateService.session &&
            this.sessionData &&
            this.sessionData.userData &&
            this.sessionData.userData.permissions) ? this.sessionData.userData.permissions.ra : false;
    }

    get profilerStatus() {
        return (this.isComplete) ? 'jobs.sidebar.completed' : 'jobs.sidebar.notCompleted';
    }

    ngOnInit() {
        this.sessionData = this.stateService.session.sessionData;
        this.currentLang = this.translate.currentLang;
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
        let thisObject = this;
        window.addEventListener('orientationchange', function() {
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            thisObject.changeDetectorRef.markForCheck();
        }, false);

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.jobDateFormat = TextHelper.dateFormat(this.translate.currentLang);

        this.reportType = this.route.snapshot.url[4].path;
        this.resendData = {
            jobToDisplay: this.jobService.jobToDisplay,
            tempUser: null
        };
        this.jobService.checkReportDisplay(this.reportType);
        this.tasksList = this.jobService.checkTask();
        this.setJobType();
        this.setTalentsName();
        this.setTalentsScoresKeys();

        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.jobToDisplayId) {
                    this.tasksList = this.jobService.checkTask();
                    this.setJobType();
                    this.setTalentsName();
                    this.setTalentsScoresKeys();
                }
                if (res && res.tab) {
                    this.switchEvaluationType(res.tab);
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                    this.jobDateFormat = TextHelper.dateFormat(res.lang);
                }
            }
        );

        if (!this.state.reportBack || (this.state.reportBack && this.state.reportBack.module === 'jobs')) {
            // Change Route event
            this.stateService.session.actualModule = 'jobs';
        }

        let divs = Array.from(document.querySelectorAll('body > div:not(#menu_wrapper)')) as unknown as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style.overflowY = 'hidden';
        }
    }

    setJobType() {
        this.jobType = (this.jobService.type && this.jobService.type !== 'undefined')
            ? this.jobService.type
            : 'undefined';
    }

    goToList() {
        if (this.state.reportBack) {
            this.personToDisplayId = this.state.reportBack.personToDisplayId;
            if (this.personToDisplayId) {
                this.router.navigate([this.state.reportBack.to + this.personToDisplayId + '/' + this.state.reportBack.reportType]).then(() => {});
                this.state.reportBack = null;
            } else if (this.jobService.id) {
                this.stateService.navigate(
                    this.router, //
                    this.state.reportBack.to, //
                    this.state.reportBack.action, //
                    {
                        jobToDisplayId: this.jobService.id, //
                        reportType: this.state.reportBack.reportType
                    },
                    this.state.reportBack.module
                );
                this.state.reportBack = null;
            } else {
                let backTo = this.state.reportBack.to;
                this.state.reportBack = null;
                this.router.navigate([backTo]).then();
                // this.stateService.navigate(
                //     this.router,
                //     this.state.reportBack.to,
                //     this.state.reportBack.action,
                //     {
                //         itemToDisplayId: this.state.reportBack.id
                //     },
                //     this.state.reportBack.module
                // );
            }

        } else {
            this.stateService.navigate(
                this.router,
                'm/jobs/list',
                'backTo',
                {
                    backTo: true
                }
            );
        }
    }

    createRange(actualPage) {
        let items: number[] = [];
        let start = (actualPage - 1) * this.maxPerPage;
        let end = actualPage * this.maxPerPage;
        for (let i = start; i < end; i++) {
            items.push(i);
        }
        return items;
    }

    getRestItems(total, actualPage) {
        return total - (this.maxPerPage * (actualPage - 1));
    }

    goToPage(factor) {
        this.actualPage += factor;
    }

    getProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    jobEdit(goToTab = 0) {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
        this.activationRequestP = false;
        this.activationRequestC = false;
        if (goToTab) {
            this.stateService[this.specificState()].selectedTab = goToTab;
        }
        this.fillQuickRA = false;
        this.sendQByEmail = false;
        this.resendQ = false;
    }

    closeEditPanels() {
        this.editRequest = false;
    }

    closeAllRequestsPanels(event) {
        this.closeEditPanels();
        this.activationRequestP = false;
        this.activationRequestC = false;
        this.pdfRequest = false;
        this.fillQuickRA = false;
        this.sendQByEmail = false;
        this.resendQ = false;
        // switch to proper tab
        if (event !== null) {
            let switchTo = 'personality';
            if (event === 2 || event === 'talents') {
                switchTo = 'talents';
            } else if (event === 3 || event === 'ra') {
                switchTo = 'ra';
            }
            this.switchEvaluationType(switchTo);
        }

    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.closeEditPanels();
        this.activationRequestP = false;
        this.activationRequestC = false;
        this.fillQuickRA = false;
        this.sendQByEmail = false;
        this.resendQ = false;
    }

    toggleSendQByEmailForm() {
        if (!this.sendQByEmail && (this.jobType === 'quick' || this.jobType === 'short')) {
            const txtDeleteConf = {
                paragraph: this.translate.instant('jobs.sidebar.profileChangeWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txtDeleteConf);
            this.subscriptions.add(this.alertService.nextAction.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.sendQByEmail = !this.sendQByEmail;
                        this.changeDetectorRef.markForCheck();
                    }
                }
            ));
        } else {
            this.sendQByEmail = !this.sendQByEmail;
        }
    }

    // openProfilerForm() {
    //     if (this.jobType === 'quick' || this.jobType === 'short') {
    //         const txtDeleteConf = {
    //             paragraph: this.translate.instant('jobs.sidebar.profileChangeWarning'),
    //             texth1: 'Confirmation',
    //             position: 'center-center',
    //             template: 'warningConfirm'
    //         };
    //         this.alertService.setConfig(txtDeleteConf);
    //         this.subscriptions.add(this.alertService.nextAction.subscribe(
    //             res => {
    //                 if (res === 'confirm') {
    //                     this.profilerFormAction();
    //                 }
    //             }
    //         ));
    //     } else {
    //         this.profilerFormAction();
    //     }
    // }

    // profilerFormAction() {
    //     // Open window to avoid popup blocking
    //     let profilerWindow = this.nativeWindow.open('', '_blank');
    //
    //     this.subscriptions.add(this.apiJobs.profilerRequestToken([this.jobService.id], []).subscribe(
    //         res => {
    //             // Modification of URL in window open after getting data
    //             profilerWindow.document.location.href = this.profilerFormUrl + '/autoconnect/' + res.token;
    //         }
    //     ));
    // }

    resendProfiler() {
        this.expandInfoPanel = true;
        this.resendQ = true;
    }

    deleteProfile(action = 'deleteProfiler') {
        let p = [];
        let h1 = [];
        let actionObservable;
        if (action === 'deleteProfiler') {
            p[0] = this.translate.instant('jobs.commons.targetPersonProfileDeleted');
            h1[0] = this.translate.instant('commons.deleted');
            p[1] = this.translate.instant('commons.tryAgain');
            h1[1] = this.translate.instant('commons.wasError');

            const text = {
                paragraph: this.translate.instant('jobs.commons.targetPersonProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobs.deleteProfiler([this.jobService.id, 0]);
            this.action(actionObservable, action, p, h1);
        } else if (action === 'deleteJobCompetence') {
            p[0] = this.translate.instant('jobs.commons.targetCompProfileDeleted');
            h1[0] = this.translate.instant('commons.deleted');
            p[1] = this.translate.instant('commons.tryAgain');
            h1[1] = this.translate.instant('commons.wasError');

            const text = {
                paragraph: this.translate.instant('jobs.commons.targetCompProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobs.deleteCompetence([this.jobService.id, 0]);
            this.action(actionObservable, action, p, h1);
        } else if (action === 'deleteJobCai') {
            p[0] = this.translate.instant('jobs.commons.targetCaiProfileDeleted');
            h1[0] = this.translate.instant('commons.deleted');
            p[1] = this.translate.instant('commons.tryAgain');
            h1[1] = this.translate.instant('commons.wasError');

            const text = {
                paragraph: this.translate.instant('jobs.commons.targetCaiProfileWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(text);
            actionObservable = this.apiJobs.deleteIac([this.jobService.id]);
            this.action(actionObservable, action, p, h1);
        }
        return;
    }

    action(actionObservable, action, p, h1) {
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm') {
                    this.subscriptions.add(actionObservable.subscribe(
                        () => {

                            this.alertService.setConfig({
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            });

                            if (action === 'delete') {
                                this.jobService.resetItemToDisplay(this.specificState());
                            } else {
                                this.jobService.setItemToDisplayWithId(this.jobService.id);
                            }
                            if (action === 'delete' || action === 'archive') {
                                this.goToList();
                            }
                        },
                        (err) => {
                            if (err.error.code === 500 && action === 'deleteJobCai') {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                this.jobService.setItemToDisplayWithId(this.jobService.id);
                            } else {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        }
                    ));
                }
            }
        ));
    }

    activate(type) {
        let status = 'new';
        if (type === 'talents' && this.hasTalentsScores) {
            status = 'edit';
        }
        this.activationData = {
            reportType: type,
            status: status
        };
        if (type === 'personality') {
            this.jobEdit(1);
        }
        if (type === 'talents') {
            this.jobEdit(2);
        }
        if (type === 'ra') {
            this.jobEdit(3);
        }

        this.changeDetectorRef.markForCheck();
    }

    modQuickProfiler() {
        this.jobEdit(1);
        this.actStatus = 'edit';
    }

    modTalent() {
        this.jobEdit(2);
        this.actStatus = 'edit';
    }

    modRa() {
        this.jobEdit(3);
        this.actStatus = 'edit';
    }

    saveIacScore(event) {
        if (event['action'] === 'save' || event['action'] === 'update') {
            let put = !(event['action'] === 'save');
            this.apiJobs.jobIac([this.jobService.id], {raScore: parseInt(event['score'], 10)}, put).pipe(takeUntil(this.onDestroy$)).subscribe(
                () => {
                    this.jobService.setItemToDisplayWithId(this.jobService.id);
                    this.stateService.jobs.stateChanged.next({jobToDisplayId: this.jobService.id, tab: 'ra'});
                }
            );
        } else {
            throw 'invalid action in jobs-list.component.saveIacScore';
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    switchEvaluationType(newType) {
        if (this.actualModule.archive) {
            return;
        }
        if (newType === 'personality') {
            this.mpoGraphType = 'plain';
        }

        this.stateService[this.specificState()].report.reportType = newType;
        this.reportType = newType;

        if (!this.state.reportBack || (this.state.reportBack && this.state.reportBack.module === 'jobs')) {
            this.state.reportBack = null;
        }
        this.changeDetectorRef.markForCheck();
    }

    difDates(date1, date2) {
        let dateOnly1 = date1.split('T');
        let dateOnly2 = date2.split('T');
        return dateOnly1[0] !== dateOnly2[0];

    }

    orderJobTalents(a, b) {
        if (a[0] && b[0] && Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) < 0) {
            return -1;
        }
        if (a[0] && b[0] && Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) > 0) {
            return 1;
        }
        return 0;
    }

    setTalentsName() {
        this.talentsName = {
            fr: new TalentsDefinitions().talents.fr,
            en: new TalentsDefinitions().talents.en
        };
    }

    setTalentsScoresKeys() {
        if (this.jobService.competences) {
            if (this.talentsScores) {
                this.talentsScoresKeys = Object.keys(this.talentsScores);
                let talents = {};
                talents['en'] = [];
                talents['fr'] = [];
                for (let scoreKey of this.talentsScoresKeys) {
                    talents['en'].push([
                        this.fuseTalentName(this.talentsName['en'][scoreKey]),
                        this.talentsScores[scoreKey]
                    ]);
                    talents['fr'].push([
                        this.fuseTalentName(this.talentsName['fr'][scoreKey]),
                        this.talentsScores[scoreKey]
                    ]);
                }

                this.orderedTalents['en'] = talents['en'].sort(this.orderJobTalents);
                this.orderedTalents['fr'] = talents['fr'].sort(this.orderJobTalents);
                this.maxPage = Math.ceil(_.size(this.jobService.competences[0]) / this.maxPerPage);
            }
        } else {
            this.maxPage = null;
        }
    }

    fuseTalentName(talent) {
        if (talent && talent.length > 1) {
            return talent.join(' ');
        }
        return talent[0];
    }

    /**
     * @ilya 09/03/2021
     * This function is not necessary directly in the jobs-report component since it's used in 'app-jobs-report-mpo'
     useWithSatellite(event) {
        this.mpoScores.satellite = event.checked;
        this.subscriptions.add(this.apiJobs.jobProfiler([this.jobToDisplay.id, 0], {score: this.mpoScores})
            .subscribe());
    }
     **/

    // updateJobToDisplay(switchType = null, init = null) {
    //     let id = this.route.snapshot.url[3].path;
    //     // noinspection FunctionWithMoreThanThreeNegationsJS
    //     this.apiJobs.job([id]).pipe(takeUntil(this.onDestroy$)).subscribe(
    //         res => {
    //             if (!this.state.report.display) {
    //                 this.state.report.display = 'report';
    //             }
    //             if (!this.state.report.reportType) {
    //                 this.state.report.display = 'description';
    //                 this.state.report.reportType = 'mpo';
    //                 if (res.score) {
    //                     this.state.report.reportType = 'mpo';
    //                 } else if (res.competences) {
    //                     this.state.report.display = 'report';
    //                     this.state.report.reportType = 'talents';
    //                 }
    //             }
    //             if (!res.jobDescription || !res.jobDescription.tasks || res.jobDescription.tasks.length === 0) {
    //                 this.tasksList = false;
    //                 this.setJobToDisplay(res);
    //             } else {
    //                 res.jobDescription.tasks = _.orderBy(res.jobDescription.tasks, ['priority'], ['asc']);
    //                 this.setJobToDisplay(res);
    //                 this.tasksList = true;
    //             }
    //
    //             if (switchType) {
    //                 this.switchEvaluationType(switchType);
    //             }
    //
    //             this.apiCalled = true;
    //
    //             if (init === 'init') {
    //                 this.jobReportService.getReport(this, this.jobToDisplay.id);
    //             }
    //
    //             this.changeDetectorRef.markForCheck();
    //         }
    //     );
    // }

    jobIsBlank() {
        // noinspection OverlyComplexBooleanExpressionJS
        return (
            !this.jobService.jobDescription ||
            !this.jobService.tasks ||
            this.jobService.tasks.length === 0
        ) && !this.jobService.competences && !this.mpoScores;
    }

    comparePriority(a, b) {
        return a.priority - b.priority;
    }

    get colsNumber() {
        let col = 0;
        if (this.stateService.hasPersonality) {
            col++;
        }
        if (this.stateService.hasTalents) {
            col++;
        }
        if (this.stateService.hasIac) {
            col++;
        }
        return col;
    }

    get mpoScores() {
        if (this.jobService.score) {
            return this.jobService.score[0];
        }
        return;
    }

    get talentsScores() {
        if (this.jobService.competences) {
            return this.jobService.competences[0];
        }
        return;
    }

    get hasTalentsScores() {
        return !!(this.talentsScores && !(_.isEmpty(this.talentsScores)));
    }

    get isComplete() {
        return !!(this.jobService.score && this.jobService.score.length === 1);

    }

    get jobTasks() {
        if (this.jobService.tasks) {
            return this.jobService.tasks.sort(this.comparePriority);
        }
        return;
    }
}
