// Warning: This file is no longer used and is kept for legacy purposes only
// Use:
//      src/env.js for URLs
//      src/App/app.module.ts for whitelisting

export const environment = {
    production: true,
    versionJson: true,
    loc: false,
    apiBaseUrl: 'https://staging.ngenioconnect.com/',
    profilerUrl: 'https://profiler-staging.ngenioconnect.com/',
    evaluationFormUrl: 'https://staging.ngenioconnect.com/survey',
    consoleBaseUrl: 'https://staging.ngenioconnect.com/',
    pdfReportUrl: 'https://staging.ngenioconnect.com/api/report-pdf',
    satellite: 'https://staging.ngenioconnect.com/survey',
    jwtWhitelistedDomains:
        ['ngenioconnect.com',
        'staging.ngenioconnect.com',
        'profiler-staging.ngenioconnect.com',
        'satellite-staging.ngenioconnect.com']
};
