import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';

export class StateSession extends AbstractState {

    // STATE DEFINITION //////////////////////////////////////////////

    protected state = {
        isLoggedIn: false,
        redirectUrl: null,
        sessionData: {},
        actualModule: null,
        lastAddress: null,
        accountHasUnlimitedCredits: false
        // appLanguage: null
    };

    // WATCHES ///////////////////////////////////////////////////////

    sessionDataWatch = new BehaviorSubject(null);
    sessionDataWatch$ = this.sessionDataWatch.asObservable();

    sessionCreditUpdateWatch = new BehaviorSubject(null);
    sessionCreditUpdateWatch$ = this.sessionCreditUpdateWatch.asObservable();

    // languageWatch = new BehaviorSubject<any>(null);
    // languageWatch$ = this.languageWatch.asObservable();

    accountNumberWatch = new BehaviorSubject(null);
    accountNumberWatch$ = this.accountNumberWatch.asObservable();

    // sessionNavModule = new BehaviorSubject<any>(null);
    // sessionNavModule$ = this.sessionNavModule.asObservable();


    // CONSTRUCTOR ///////////////////////////////////////////////////

    constructor() {
        super();
        this.retrieveStore('session.isLoggedIn');
        this.retrieveStore('session.redirectUrl');
        this.retrieveStore('session.sessionData');
        this.retrieveStore('session.actualModule');
        this.retrieveStore('session.lastAddress');


    }

    // GETTERS AND SETTERS ////////////////////////////////////////////

    set isLoggedIn(value: any) {
        this.persistState('isLoggedIn', value);
    }

    set redirectUrl(value: any) {
        this.persistState('redirectUrl', value);
    }

    // set language(value: string){
    //     if(value!='en' && value!='fr') throw 'inexistant language!';
    //     this.state.appLanguage = value;
    //     this.languageWatch.next(value);
    // }

    get hasSatelliteRole(): boolean {
        return !!(
            this.sessionData &&
            this.sessionData.permissions &&
            this.sessionData.permissions.mainRole &&
            this.sessionData.permissions.mainRole === 'ROLE_USER_SATELLITE'
        );

    }
    set actualModule(value: any) {
        this.persistState('actualModule', value);
    }

    set lastAddress(value: any) {
        this.persistState('lastAddress', value);
    }

    get isLoggedIn(): any {
        return this.state.isLoggedIn;
    }

    get redirectUrl(): any {
        return this.state.redirectUrl;
    }

    get sessionData(): any {
        return this.state.sessionData;
    }

    get isAdmin() {
        let role;
        // noinspection OverlyComplexBooleanExpressionJS
        if (
            this.sessionData &&
            this.sessionData.permissions &&
            this.sessionData.permissions.userType
        ) {
            role = this.sessionData.permissions.userType;
        } else {
            role = 'rw';
        }
        return (role === 'admin' || role === 'm_admin' || role === 's_admin');
    }

    get isSender() {
        if (this.sessionData && this.sessionData.permissions) {
            return (this.sessionData.permissions.userType === 'snd' || this.sessionData.permissions.mainRole === 'ROLE_USER_SENDER');
        }
        return false;
    }

    get actualModule() {
        return this.state.actualModule;
    }

    get lastAddress() {
        return this.state.lastAddress;
    }

    set accountHasUnlimitedCredits(value: boolean) {
        this.state.accountHasUnlimitedCredits = value;
    }

    get accountHasUnlimitedCredits() {
        return this.state.accountHasUnlimitedCredits;
    }

    public persistState(key: string, val: any) {
        this.stateStore('session', key, val);
    }
    // Module name in navigation
    // setSessionNavModule(value: any) {
    //    this.sessionNavModule.next(value);
    // }
    get preferences() {
        if (
            this.state &&
            this.state.sessionData &&
            this.state.sessionData.hasOwnProperty('userData') &&
            this.state.sessionData['userData'].hasOwnProperty('preferences')
        ) {
            return this.state.sessionData['userData'].preferences;
        }
         return false;
    }

    set preferences(preferences) {
        if (
            this.state &&
            this.state.sessionData &&
            this.state.sessionData.hasOwnProperty('userData') &&
            this.state.sessionData['userData'].hasOwnProperty('preferences')
        ) {
            this.state.sessionData['userData'].preferences = preferences;
        }

    }
}

