// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PRBSection input {
  padding-left: 5px;
}

#candidateList {
  max-height: 408px;
  background-color: white;
  overflow-y: scroll;
  border-bottom: 1px solid #bbbbbb;
}

.candidateRow {
  border-bottom: 1px solid #dddddd;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  background-color: #eeeeee;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/mobile-user-list/mobile-user-list.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gCAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".PRBSection input {\n    padding-left: 5px;\n}\n\n#candidateList {\n    max-height: 408px;\n    background-color: white;\n    overflow-y: scroll;\n    border-bottom: 1px solid #bbbbbb;\n}\n\n.candidateRow {\n    border-bottom: 1px solid #dddddd;\n}\n\n.pointer {\n    cursor: pointer;\n}\n\n.pointer:hover {\n    background-color: #eeeeee;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
