import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, IterableDiffers, OnInit, Output
} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from 'src/app/core/services/state/state.service';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-graphboards-selected-items',
    templateUrl: './graphboards-selected-items.component.html',
    styleUrls: ['./graphboards-selected-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsSelectedItemsComponent extends ComponentSidebar implements OnInit {

    selectedItems: any = [];
    @Input() width = '';

    @Output() switchOff = new EventEmitter(); // Put the switch of the filter button off
    @Output() removeElmt = new EventEmitter();
    @Output() actionOnRecords = new EventEmitter();
    // @Output() completeAction = new EventEmitter();

    action: any;

    names: Array<Array<string>> = [];
    iterableDiffer: any;

    actualPage = 1;
    maxPerPage = 30; /** mod this */
    maxPage = 1;

    constructor(
        private _iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef,
        protected stateService: StateService,
        protected router: Router,
        public translateService: TranslateService,
        protected deviceService: DeviceDetectorService) {
            super(stateService, translateService, router, deviceService);
            this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.stateService[this.specificState()].multiselectWatch$.subscribe(
            (res) => {
                if (res) {
                    this.selectedItems = this.stateService[this.specificState()].multiselect;
                    this.updateList(this.selectedItems, this.maxPerPage);
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
    }

    act() {
        if (this.action) {
            let event = {
                actionType: this.action,
                ids: []
            };
            for (let item of this.selectedItems) {
                event.ids.push(item.id);
            }

            this.actionOnRecords.emit(event);
        }
    }

    removeItem(item) {
        this.state.multiselect.splice(
            this.selectedItems.indexOf(item),
            1
        );
        this.selectedItems = this.state.multiselect;
        this.state.multiselectWatch.next(true);

        this.changeDetectorRef.markForCheck();
    }

    onResize(event) {
        // this.informationVisibility = (event.target.innerHeight < 725);
    }

}
