// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.isBlank {
  color: #aaa;
  cursor: default;
}

::ng-deep .isBlank .buttonImage img {
  opacity: 0.5;
}

.sideBarImageContainer {
  margin-right: 3px;
}

.isAnalysis {
  padding: 5px 10px;
  margin-bottom: 3px;
  height: 32px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/nav-button/nav-button.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACA;EACI,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAEJ","sourcesContent":[".isBlank {\n    color: #aaa;\n    cursor: default;\n}\n\n::ng-deep .isBlank .buttonImage img {\n    opacity: 0.5;\n}\n\n.sideBarImageContainer {\n    margin-right: 3px\n}\n.isAnalysis {\n    padding: 5px 10px;\n    margin-bottom: 3px;\n    height: 32px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
