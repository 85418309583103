import {Component, EventEmitter, ChangeDetectionStrategy, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {RightMatchListService} from '../../../services/right-match-list.service';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {RightMatchSearchFiltersService} from '../../../services/right-match-search-filters.service';
import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../../../../../shared/helpers/custom-date-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
    selector: 'app-right-matches-list-search-filters',
    templateUrl: './right-matches-list-search-filters.component.html',
    styleUrls: ['./right-matches-list-search-filters.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMatchesListSearchFiltersComponent extends ComponentPrimitive implements OnInit {

    @Input() whereAmI: string;
    @Input() width: string;
    @Output() selectPreference = new EventEmitter;

    preferenceUrl: string;
    userDataId: string;

    date: any;

    maxNumberOfColumns = 5;
    columnsToSelect: number;

    private subscriptions = new Subscription();

    constructor(
        public rightMatchService: RightMatchListService,
        public rightMatchSearchFiltersService: RightMatchSearchFiltersService,
        public stateService: StateService,
        private userPreferencesService: UserPreferencesService,
        private changeDetectorRef: ChangeDetectorRef,
        private dateAdapter: DateAdapter<Date>,
        public translate: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translate,
            router,
            deviceService
        );
    }

    ngOnInit() {
        this.rightMatchSearchFiltersService.initDateFormat(this.dateFormat());
        this.rightMatchSearchFiltersService.setSubAccountsOptions();
        this.rightMatchSearchFiltersService.setDepartmentsOptions();

        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.rightMatchSearchFiltersService.initDateFormat(this.dateFormat());
            this.checkPeriodDates();
        }));

        this.rightMatchService.checkModuleUrl();
        this.rightMatchSearchFiltersService.checkPreferences(this.whereAmI);

        if (this.whereAmI === 'filter') {
            this.rightMatchSearchFiltersService.initInputState();
        }

        if (this.whereAmI === 'preferences') {

            let accountDataId = (this.rightMatchService.accountData) ? this.rightMatchService.accountData.id : '';
            this.userDataId = (this.rightMatchService.userData) ? this.rightMatchService.userData.id : '';
            this.preferenceUrl = 'admin/account/' + accountDataId + '/user/' + this.userDataId + '/preferences';

            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.rightMatchService.userPreferences, this.preferenceUrl);

            // Init state with session vars
            this.rightMatchSearchFiltersService.initInputStatePreferences(this);
            this.changeDetectorRef.markForCheck();
        }

        this.rightMatchSearchFiltersService.initSubAccountFilter();

        this.checkPeriodDates();

        this.rightMatchSearchFiltersService.initColumnsFilter();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.dateAdapter.setLocale(event.lang);
        });
    }

    setFilters(event: MatDatepickerInputEvent<Date>, filter) {
        switch (filter) {
            case 'rightMatchStartDate':
                this.rightMatchSearchFiltersService.setSearchFilter(event.value, 'rightMatchStartDate');
                break;
            case 'rightMatchEndDate':
                this.rightMatchSearchFiltersService.setSearchFilter(event.value , 'rightMatchEndDate');
                break;
            case 'subAccount':
                let subAccounts = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(subAccounts, 'subAccountCol', this.translate.instant('commons.subAccount'));
                this.rightMatchSearchFiltersService.subAccount(subAccounts, this, this.rightMatchService.specificState);
                break;
            case 'department':
                let deps = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(deps, 'depCol', this.translate.instant('commons.division'));
                this.rightMatchSearchFiltersService.onDepartmentOptionChange(deps, this, this.rightMatchService.specificState);
                break;
            case 'columnDisplay':
                this.rightMatchSearchFiltersService.columnDisplay(event, this, this.rightMatchService.specificState);
                break;
        }
        this.rightMatchService.getRightMatchList(true, null, this);
    }

    checkPeriodDates() {
        if (this.rightMatchService.evaluationStartDate) {
            let dateStartObject = new Date(this.rightMatchService.rightMatchStartDate);
            this.rightMatchSearchFiltersService.dateStartModel = dateStartObject;
            this.rightMatchSearchFiltersService.dateStart = dateStartObject;
        }

        if (this.rightMatchService.evaluationEndDate) {
            let dateEndObject = new Date(this.rightMatchService.rightMatchEndDate);
            this.rightMatchSearchFiltersService.dateEndModel = dateEndObject;
            this.rightMatchSearchFiltersService.dateEnd = dateEndObject;
        }
    }

    resetSearchFiltersAction(preferences = null) {
        this.rightMatchSearchFiltersService.resetSearchFiltersAction();

        if (this.whereAmI === 'filter') {
            this.rightMatchSearchFiltersService.resetSearchFilters();
        }
        for (let report in this.stateService[this.rightMatchService.specificState].listSearchFilters) {
            if (report && this.stateService[this.rightMatchService.specificState].listSearchFilters.hasOwnProperty(report)) {
                this.stateService[this.rightMatchService.specificState].listColumns = this.stateService[this.rightMatchService.specificState].listColumnsInitialConfig;
                if (report !== 'search') {
                    this.stateService[this.rightMatchService.specificState].listSearchFilters[report] = null;
                }
            }
        }

        if (preferences === null) {
            this.rightMatchService.setInitialColumnsList();
            this.rightMatchService.listColumns = JSON.parse(JSON.stringify(this.rightMatchService.listColumnsInitialConfig));
            // for (let col of this.stateService[this.rightMatchService.specificState].columns) {
            //     this.checkedColumn[col] = (_.find(this.rightMatchSearchFiltersService.listColumns, (o) => {
            //         if (o && o.display === true) {
            //             return o.key === col;
            //
            //         }
            //     })) ? true : null;
            // }
            //
            // let colSelected = 0;
            // for (let col of this.stateService[this.rightMatchService.specificState].columns) {
            //     if (col !== 'creationDate' && (_.find(this.rightMatchSearchFiltersService.listColumns, (o) => {
            //         if (o && o.display === true) {
            //             return o.key === col;
            //
            //         }
            //     })) ? true : null) {
            //         colSelected++;
            //     }
            // }
        } else {
            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.rightMatchService.userPreferences, this.preferenceUrl, 'rightMatch');
            this.rightMatchSearchFiltersService.initInputStatePreferences(this);
        }

        this.selectPreference.emit({
            type: 'selectVanilla'
        });
    }

    resetSearchFilters() {
        if (this.whereAmI === 'filter' && this.actualModule.name !== 'rightMatch') {
            const preferences = (this.userPreferencesService.isPreferenceSet('rightMatch')) ?
                this.stateService.session.preferences['rightMatch'] :
                null;
            this.resetSearchFiltersAction(preferences);
        } else {
            this.resetSearchFiltersAction();
            this.userPreferencesService.emptyUserPreferences('rightMatch', this.userDataId);
        }
        this.rightMatchService.getRightMatchList(true, null, this);
    }

    countColumnsToSelect() {
        let colSelected = 0;
        for (let col in this.rightMatchService.rightMatchState.listColumns) {
            if (this.rightMatchService.rightMatchState.listColumns.hasOwnProperty(col)) {
                if (this.rightMatchService.rightMatchState.listColumns[col] && this.rightMatchService.rightMatchState.listColumns[col].display) {
                    colSelected++;
                }
            }
        }
        this.columnsToSelect = this.maxNumberOfColumns - colSelected;
        return this.columnsToSelect;
    }
}
