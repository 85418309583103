import {Job} from '../../../jobs/models/job.model';

// import {Person} from "../../people/models/person.model";

export class RightMatch {
    id: string;
    name: string;
    subAccount: number;
    date: Date;
    creationDate: Date;
    consultant: string;
    profiler: Job;
    records: Array<any>; // ToDo: change to array of Person?
    department: any;

    constructor(options: {
        id?: string,
        name?: string,
        subAccount?: number,
        date?: Date,
        creationDate?: Date,
        consultant?: string,
        profiler?: Job,
        records?: Array<any>,
        department?: any
    } = {}) {
        this.id           = options.id;
        this.name         = options.name;
        this.subAccount   = options.subAccount;
        this.date         = options.date;
        this.creationDate = options.creationDate;
        this.consultant   = options.consultant;
        this.profiler     = options.profiler;
        this.records      = options.records;
        this.department   = options.department;
    }
}
