// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infoTable tr td:first-child {
  padding-right: 15px;
}

.topHead {
  height: 64px;
}

/**
@media (max-height:  725px ) {
    #informations {
        display: none;
    }
    #informationAltTitle {
        display: block !important;
    }
}



#informationAltTitle {
    display: none;
}

*/
/** affixing **/
.top {
  background-color: rgb(241, 241, 241);
  top: 0;
  z-index: 50 !important;
}

.fluid {
  position: relative;
  top: 125px;
}

.semiFluid {
  position: relative;
  top: 125px;
}

.blockBottom {
  position: fixed;
  padding-top: 20px;
}

.bbLeft {
  left: 102px;
  padding: 35px 20px 0 20px;
}

.bbRight {
  padding-right: 40px;
}

.cardEvaluation {
  padding-left: 2px;
  width: 63px;
  height: 63px;
  margin: 0 auto;
}

.cardEvaluationTitle {
  text-align: center;
}

select.void {
  background-color: transparent;
  box-shadow: none;
  height: 18px;
  border: 0;
  padding: inherit;
}

.extraPadding {
  padding-top: 2px;
}

select.void:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/administration/components/administration-sidebar/administration-sidebar.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;;;;;;;;;;;;;;;;CAAA;AAkBA,eAAA;AAEA;EACI,oCAAA;EACA,MAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;AADJ;;AAIA;EACI,eAAA;EACA,iBAAA;AADJ;;AAIA;EACI,WAAA;EACA,yBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,6BAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;AADJ;;AAKA;EACI,gBAAA;AAFJ;;AAKA;EACI,aAAA;AAFJ","sourcesContent":["#infoTable tr td:first-child {\n    padding-right: 15px;\n}\n\n.topHead {\n    height: 64px;\n}\n\n/**\n@media (max-height:  725px ) {\n    #informations {\n        display: none;\n    }\n    #informationAltTitle {\n        display: block !important;\n    }\n}\n\n\n\n#informationAltTitle {\n    display: none;\n}\n\n*/\n\n/** affixing **/\n\n.top {\n    background-color: rgb(241, 241, 241);\n    top: 0;\n    z-index: 50 !important;\n}\n\n.fluid {\n    position: relative;\n    top: 125px;\n}\n\n.semiFluid {\n    position: relative;\n    top: 125px;\n}\n\n.blockBottom {\n    position: fixed;\n    padding-top: 20px;\n}\n\n.bbLeft {\n    left: 102px;\n    padding: 35px 20px 0 20px;\n}\n\n.bbRight {\n    padding-right: 40px;\n}\n\n.cardEvaluation {\n    padding-left: 2px;\n    width: 63px;\n    height: 63px;\n    margin: 0 auto;\n}\n\n.cardEvaluationTitle {\n    text-align: center\n}\n\nselect.void {\n    background-color: transparent;\n    box-shadow: none;\n    height: 18px;\n    border: 0;\n    padding: inherit;\n\n}\n\n.extraPadding {\n    padding-top: 2px;\n}\n\nselect.void:focus {\n    outline: none;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
