import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuardService} from '../app/services/auth-guard.service';

import {AccountComponent} from './components/administration-account/administration-account.component';
import {UserDetailComponent} from './components/administration-user/administration-user.component';
import {AdministrationListComponent} from './components/administration-list/administration-list.component';
import {UserFormComponent} from './components/administration-user-form/administration-user-form.component';
import {UserPreferencesComponent} from './components/administration-user-preferences/administration-user-preferences.component';
import {MobileAdministrationListComponent} from './components/mobile/mobile-administration-list/mobile-administration-list.component';
import {AdministrationAccountFormComponent} from './components/administration-account-form/administration-account-form.component';

@NgModule({
    imports: [RouterModule.forChild([
        // admin list
        {
            path: 'admin',
            redirectTo: '/admin/list',
            pathMatch: 'full'
        },
        {
            path: 'admin/list',
            component: AdministrationListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/admin/list',
            component: MobileAdministrationListComponent,
            canActivate: [AuthGuardService]
        },

        // admin account and sub account forms
        {
            path: 'admin/account/new',
            component: AccountComponent,
            canActivate: [AuthGuardService]
        },
        /**
        {
            path: 'admin/account/edit/:accountDataId',
            component: AccountComponent,
            canActivate: [AuthGuardService]
        },*/
        {
            path: 'admin/account/:accountDataId/subaccount/new',
            component: AccountComponent,
            canActivate: [AuthGuardService]
        },
        /**
        {
            path: 'admin/account/:accountDataId/subaccount/edit/:subAccountId',
            component: AccountComponent,
            canActivate: [AuthGuardService]
        },*/

        // admin user forms
        {
            path: 'admin/account/:accountDataId/user/new',
            component: UserFormComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'admin/account/:accountDataId/user/edit/:userId',
            component: UserFormComponent,
            canActivate: [AuthGuardService]
        },

        // admin user detail page
        {
            path: 'admin/account/:accountDataId/user/:userId',
            component: UserDetailComponent,
            canActivate: [AuthGuardService]
        },

        // admin user preferences page
        {
            path: 'admin/account/:accountDataId/user/:userId/preferences',
            component: UserPreferencesComponent,
            canActivate: [AuthGuardService]
        },

        // admin account and sub account detail page
        {
            path: 'admin/account/:accountId',
            component: AccountComponent,
            canActivate: [AuthGuardService],
        },
        {
            path: 'admin/account/:accountId/subaccount/:subAccountId',
            component: AccountComponent,
            canActivate: [AuthGuardService],
            pathMatch: 'full'
        }
    ])],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {
}
