import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {ComponentPrimitive} from '../../../../../shared/classes/components/componentPrimitive';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PersonService} from '../../../../../people/services/person.service';
import {UserService} from '../../../../../core/services/user/user.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import * as moment from 'moment';

@Component({
    selector: 'app-job-satisfactions-person-card',
    templateUrl: './job-satisfactions-person-card.component.html',
    styleUrls: ['./job-satisfactions-person-card.component.scss']
})
export class JobSatisfactionsPersonCardComponent extends ComponentPrimitive implements OnChanges {

    @Input() record: any;
    @Input() job: any;
    @Input() orderedTalents: any;
    @Input() minimizeAll;
    @Input() listView = true;
    @Input() pos;
    @Input() type = 'rightMatch';
    @Output() openPcrReport = new EventEmitter;
    @Output() remove = new EventEmitter;

    // toggles
    informationVisibility = false;

    constructor(
        protected stateService: StateService,
        public translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        public cd: ChangeDetectorRef,
        public user: UserService,
        private person: PersonService,
        public jobSatisfaction: JobSatisfactionsAnalysisService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    openPcrReportPopUp(event) {
        this.openPcrReport.emit(event);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minimizeAll']) {
            this.informationVisibility = !changes['minimizeAll'].currentValue;
        }
        this.cd.markForCheck();
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'navigate');
    }

    redoPcr(record): any {
        let date = (record['prb'][0] && record['prb'][0]['date']) ? record['prb'][0]['date'] : null;
        // console.log(record['lastName'], date);
        if (date !== null) {
                let today = new Date();
                let pcrDate = new Date(date);
                let diffDate = (today.getTime() - pcrDate.getTime()) / 86400000;
                if (diffDate >= 120) {
                    return 2;
                } else if (diffDate >= 60) {
                    return 1;
                }
            return 0;
        }
        return false;
    }

    tooltipRePcrText(record) {
        let date = (record['prb'][0] && record['prb'][0]['date']) ? record['prb'][0]['date'] : null;
        if (date) {
        let pcrDate = new Date(date);
        return (this.translateService.currentLang === 'fr') ?
            'Dernier questionnaire Perception rempli le ' +  moment(pcrDate).format('DD/MM/YYYY') :
            'Last perception questionnaire filled on ' + moment(pcrDate).format('MM/DD/YYYY');
        }
        /**
        if (score === 2) {
            return '';
        } else if (score === 1) {
            return '';
        } else if (score === 0) {
            return '';
        }*/
    }

    get stateJobSatisfaction() {
        return this.stateService.jobSatisfaction;
    }

    get hasThreeReports() {
        return (
            this.user.hasIacAccess && this.state.displayPreferences.display.cai &&
            this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
            this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality
        );
    }

    get hasTwoReports() {
        return (
            !this.hasThreeReports &&
            (
                (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents
                ) || (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                ) ||
                (
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                )
            )
        );
    }

    get hasOneReport() {
        // console.log(this.hasThreeReports, this.hasTwoReports);
        return !(this.hasThreeReports || this.hasTwoReports);
    }

    get state() {
        return this.stateService.jobSatisfaction;
    }
}
