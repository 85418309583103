import {Component, OnInit} from '@angular/core';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-typeahead-accounts',
    templateUrl: './typeahead-accounts.component.html',
    styleUrls: ['./typeahead-accounts.component.scss']
})
export class TypeaheadAccountsComponent implements OnInit {

    selected: string;
    dataSearch: any;

    constructor(
        private apiAdministrationService: ApiAdministrationService
    ) {
    }

    ngOnInit() {
        let search: Subscription = this.apiAdministrationService.clients([{
            namesOnly: 1,
            limit: 50000
        }]).subscribe(
            res => {
                // console.log('res: ', res);
                search.unsubscribe();
            }
        );
    }

}
