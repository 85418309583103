import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-news-icon',
  templateUrl: './news-icon.component.html',
  styleUrls: ['./news-icon.component.scss']
})
export class NewsIconComponent implements OnInit {

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

}
