// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#logo_container {
  display: table;
}

#N_logo {
  padding-right: 5px;
}

.table_cell {
  display: table-cell;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/mobile-ng-nav-icon/mobile-ng-nav-icon.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,sBAAA;AADJ","sourcesContent":["\n\n#logo_container {\n    display: table;\n}\n\n#N_logo {\n    padding-right: 5px\n}\n\n.table_cell {\n    display: table-cell;\n    vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
