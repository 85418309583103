import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewChild,
    OnDestroy
} from '@angular/core';

import {Graphboard} from '../../../models/graphboards.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {Tools} from '../../../../../shared/misc/tools';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mobile-graphboards-edit',
  templateUrl: './mobile-graphboards-edit.component.html',
  styleUrls: ['./mobile-graphboards-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileGraphboardsEditComponent implements OnInit, OnChanges, OnDestroy {

    @Input() status = 'new';
    @Input() multiAction;

    @Output() close = new EventEmitter();

    @ViewChild('graphboardNgForm') graphboardNgForm;

    graphboard: Graphboard;
    graphboardForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    headerText: string;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    submitted = false;

    private subscriptions = new Subscription();

    constructor(
        public graphBoard: GraphBoardsAnalysisService,
        public graphBoardsList: GraphBoardsListService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private fb: UntypedFormBuilder,
        private stateService: StateService,
        private apiGraphboardsService: ApiGraphboardsService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.requestedBy = this.stateService.session.sessionData.userData.username;
        this.sessionStructure = this.stateService.session.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.graphboard = Object.assign({}, new Graphboard());
        }

        if (this.status === 'edit') {
            this.graphboard = Object.assign({}, this.graphBoard.itemToDisplay);
        }

        if (this.multiAction) {
            if (this.multiAction.ids.length !== 0) {
                this.headerText = (this.multiAction.ids.length === 1) ?
                    'L\'item sélectionné précédemment sera ajouté à l\'analyse automatiquement' :
                    'Les items sélectionnés précédemment seront ajoutés à l\'analyse automatiquement';
            }
        }

        this.initForm();
    }

    onCancel() {
        this.resetForm();
        this.close.emit(true);
    }

    formCheck(specificField = null) {
        if (this.graphboardNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.graphboardForm.controls[specificField], this.graphboardNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.graphboardForm.controls[field], this.graphboardNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.submitted = false;
        const departmentId = (this.graphboard.department && this.graphboard.department.length > 0)
            ? this.graphboard.department[0].id
            : null;


        let subAccount = (this.stateService.session.sessionData.structure.principalSubAccount) ?
            this.stateService.session.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.graphboard.subAccount;
        }


        this.graphboardForm = this.fb.group({
            name: [this.graphboard.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            description: [this.graphboard.description],
            department: [departmentId],
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;

        if (model.valid) {
            let data = model.value;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    saveNew(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiGraphboardsService.postGraphboard(data).subscribe(
            res => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    let alignment = [[], [], []];
                    let i = 0;
                    let type = (this.multiAction.idType === 'people') ? 'trait' : 'profiler';
                    for (let id of this.multiAction.ids) {
                        if (i === 3) {
                            i = 0;
                        }
                        alignment[i].push({
                            type: type,
                            record: id
                        });
                        i++;
                    }
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'alignment': alignment};
                    this.subscriptions.add(this.apiGraphboardsService.putGraphboard([res.id], data).subscribe(
                        () => {
                            this.graphBoard.setItemToDisplayWithId(res.id);
                            this.onCancel();
                        }
                    ));
                } else {
                    this.graphBoard.setItemToDisplayWithId(res.id);
                    this.onCancel();
                }
                this.graphBoardsList.getGraphBoardsList(true);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiGraphboardsService.putGraphboard([this.graphboard.id], data).subscribe(
            (res) => {
                this.graphBoard.setItemToDisplayWithId(res.id);
                this.onCancel();
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    resetForm() {
        this.submitted = false;
        // this.errorForm = null;
        this.graphboardForm.reset();
        this.graphboardNgForm.submitted = false;
        this.initForm();
    }

}
