import * as moment from 'moment';
import { PricesDefinitions } from 'src/app/shared/prices/pricesDefinitions';

export class PeopleSurveyRequestHelper {
    /**
     * Optimisation for *ngFor loop
     * @param index
     * @param item
     */
    public static trackById(index: number, item: any): any {
        return (item && item.id) ? item.id : index;
    }

    /**
     * Check if a report is selected
     * @param reports
     * @returns boolean
     */
    public static isReportSelected(reports: any): boolean {
        return reports.mpo || reports.satellite || reports.dit || reports.talents || reports.iac;
    }

    /**
     * Check if the IAC report is the only report selected
     * @param reports
     * @returns boolean
     */
    public static isOnlyIACSelected (reports: any): boolean {
        return !reports.mpo && !reports.satellite && !reports.dit && !reports.talents && reports.iac;
    }

    /**
     * Check if the MPO report is the only one selected
     * @param reports
     * @returns boolean
     */
    public static isOnlyMpoSelected(reports: any): boolean {
        return reports.mpo && !reports.satellite && !reports.dit && !reports.talents && !reports.iac;
    }

    /**
     * Check if a satellite report is selected
     * @param reports
     * @returns boolean
     */
    public static isSatelliteSelected(reports: any): boolean {
        return reports.satellite;
    }

    /**
     * Check if the Satellite report is the only one selected
     * @param reports
     * @returns boolean
     */
    public static isOnlySatelliteSelected(reports: any): boolean {
        return reports.satellite && !reports.mpo && !reports.dit && !reports.talents && !reports.iac;
    }

    /**
     * Check if the iac has been selected
     * @param reports
     * @returns boolean
     */
    public static hasIac(reports: any): boolean {
        return reports.iac;
    }

    /**
     * Check if the Talents report is the only one selected
     * @param reports
     * @returns boolean
     */
    public static isOnlyTalentsSelected(reports: any): boolean {
        return reports.talents && !reports.mpo && !reports.dit && !reports.satellite && !reports.iac;
    }

    /**
     * Check if the Talents and Satellite reports are the only ones selected
     * @param reports
     * @returns boolean
     */
    public static areTalentsAndSatelliteSelected(reports: any): boolean {
        return reports.talents && reports.satellite && !reports.mpo && !reports.dit && !reports.iac;
    }

    // ToDo: Move to a generic helper ?
    /**
     * Get principal sub account from session
     * @param sessionDataStructure - Session data structure
     * @returns Principal sub account id
     */
    public static getPrincipalSubAccountFromSession(sessionDataStructure: any): number {
        let principalSubAccount = 0;

        if (sessionDataStructure.subAccount && sessionDataStructure.subAccount.length > 0) {
            principalSubAccount = sessionDataStructure.subAccount[0].id;
        }

        if (sessionDataStructure.principalSubAccount !== undefined) {
            principalSubAccount = Number.parseInt(sessionDataStructure.principalSubAccount, 10);
        }

        return principalSubAccount;
    }

    public static canSelectOnlyTraitsOnly(reports) {
        return !!(!reports.mpo && !reports.dit);
    }



    /**
     * Get sub account credits from session
     * @param subAccountsFromSession
     * @param subAccountId
     * @returns Credits number or 'unlimited'
     */
    public static getSubAccountCreditsFromSession(subAccountsFromSession: any[], subAccountId: number): number | string {
        let subAccountCredits = 0;

        if (subAccountsFromSession && subAccountsFromSession.length > 0) {
            const [subAccountData]: any = subAccountsFromSession.filter((subAccount: any) => subAccount.id === subAccountId);
            if (subAccountData && subAccountData.id !== undefined) {
                subAccountCredits = subAccountData.unlimited ? 'unlimited' : subAccountData.credits;
            }
        }

        return subAccountCredits;
    }

    /**
     * Get sub account credits
     * @param credits
     * @param isUnlimited
     * @returns Credits number or 'unlimited'
     */
    public static getSubAccountCredits(credits: number, isUnlimited: boolean): number | string {
        if (isUnlimited) {
            return 'unlimited';
        }

        return credits;
    }

    /**
     * Calculate the credit cost
     * @param reports - Reports object
     * @param variant
     * @param firstPrb - Is is a first PRB
     * @returns - Credits cost
     */
    public static getCreditCost(reports: any, variant: string, firstPrb = true): number {
        let finalCost = 0;

        // ToDo: Use an API request to have the cost
        const reportsCost = {
            'mpo': {
                '1,1': PricesDefinitions.item.mpo,
                '1,0': PricesDefinitions.item.mpo,
                '0,1': (firstPrb) ?  PricesDefinitions.item.perception_initial :  PricesDefinitions.item.perception_subsequent
            },
            'dit': {
                '1,1': PricesDefinitions.item.dit,
                '1,0': PricesDefinitions.item.dit
            },
            'satellite': {
                '1,1': PricesDefinitions.item.satellite,
                '1,0': PricesDefinitions.item.satellite
            },
            'talents': {
                '1,1': PricesDefinitions.item.talents,
                '1,0': PricesDefinitions.item.talents
            },
            'iac': {
                '0,0': PricesDefinitions.item.iac,
                '1,1': PricesDefinitions.item.iac,
                '1,0': PricesDefinitions.item.iac,
            }
        };

        // Credits addition
        if (reports) {
            for (let [reportName, reportValue] of Object.entries(reports)) {
                if (reportValue && variant) {
                    finalCost += reportsCost[reportName][variant];
                }
            }
        }

        return finalCost;
    }

    /**
     * Check if there is enough credits
     * @param creditsCost
     * @param creditsAvailable
     * @returns - Has the sub account enough credits ?
     */
    public static hasEnoughCredits(creditsCost: number, creditsAvailable: number | string): boolean {
        if (typeof creditsAvailable === 'string' && creditsAvailable === 'unlimited') {
            return true;
        }
    
        if (typeof creditsAvailable === 'number') {
            return creditsCost <= creditsAvailable;
        }
    
        throw new Error('Invalid type for creditsAvailable');
    }

    /**
     * ToDo: Move to a generic helper ?
     * Convert a date by lang
     * @param date
     * @param lang
     * @returns date converted
     */
    public static convertDate(date: string, lang = 'fr') {
        return (lang === 'fr') ?
            moment(new Date(date)).format('DD/MM/YYYY') :
            moment(new Date(date)).format('MM/DD/YYYY');
    }

    /**
     * Get the lang and the country from norm
     * @param norm - norm value like "CA FR"
     */
    public static getLangAndCountryFromNorm(norm: string): any {
        const normData = norm.split(' ');
        return {country: normData[0].toLowerCase(), language: normData[1].toLowerCase()};
    }

    /**
     * Format request reports
     * @param reports
     */
    public static formatRequestReports(reports: any): Array<string> {
        let evaluationType = [];
        for (let evaluation in reports) {
            if (reports[evaluation]) {
                evaluationType.push(evaluation);
            }
        }

        return evaluationType;
    }

    /**
     * Clear null values
     * Not a pure function, input var is modified
     * @param formValues
     */
    public static clearRequestNullValue(formValues: any): void {
        for (let value in formValues) {
            if (formValues[value] === null) {
                delete formValues[value];
            }
        }
    }

    /**
     * Format the request form values to communicate with the API
     * @param requestFormValues
     */
    public static formatRequestValues(requestFormValues: any, researchAccount: any): any {
        let formattedValues = {...requestFormValues};

        formattedValues.type = PeopleSurveyRequestHelper.formatRequestReports(formattedValues.reportGroup);
        formattedValues.researchQuestions = formattedValues.researchQuestions || false;
        formattedValues.createTempUser = true;

        formattedValues.variant =  (['1,0', '1,1', '0,1'].includes(formattedValues.variant)) ?
            formattedValues.variant : '0,0';

        // Override when researchAccount
        if (!!(researchAccount)) {
            formattedValues.language = researchAccount.lang;
            formattedValues.country = researchAccount.cntry;
            formattedValues.country = researchAccount.locale;
            formattedValues.researchQuestions = true;
        }
        // Department is an array
        formattedValues.department = formattedValues.department ? [formattedValues.department] : null;
        delete formattedValues.sendingType;
        delete formattedValues.reportGroup;

        // Clear null values
        PeopleSurveyRequestHelper.clearRequestNullValue(formattedValues);

        return formattedValues;
    }

    /**
     * Can the account create DIT without MPO (depending on account type)
     * @param accountType
     */
    public static canAccountTypeCreateDitWithoutMpo(accountType: number): boolean {
        if (typeof accountType !== 'number') {
            return ['ngenio', 'subsidiary', 'distributor', 'partner', 'bizdev', 'discretionary'].includes(accountType);

        } else {
            return (accountType <= 3);
        }

    }
}
