import {List} from '../../../../people/interfaces';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../core/services/state/state.service';
import {SearchHelper} from 'src/app/shared/misc/search.helper';
import {ApiAdministrationService} from '../../../services/api-administration.service';
import {MainListAdministrationService} from '../../../services/main-list-administration.service';
import {UserPreferencesService} from '../../../services/user-preferences.service';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {AdminService} from '../../../../app/services/admin.service';
import {Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {AccountService} from '../../../services/account.service';
import {AccountTypesHelper} from '../../../commons/AccountTypesHelper';

@Component({
    selector: 'app-administration-list',
    templateUrl: './mobile-administration-list.component.html',
    styleUrls: ['./mobile-administration-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileAdministrationListComponent implements OnInit, OnDestroy {

    clickMode = false;
    isSelected = 'none';
    toList: List = {
        count: null,
        data: []
    };
    logoImg = '/assets/menu_gauche/MPO_32px.png';
    lastSearch: string;
    dateStart = '';
    dateEnd = '';

    dateStartModel = {};
    dateEndModel = {};

    public loading = false;
    totalItems = 0;
    recordsLoaded = 0; // How much records of the list is loaded
    clientToDisplay: any; // Client;
    clientToDisplayId: string;
    typeSelected: string;
    switchOff: boolean;
    tableDefinition: Array<any> = [];
    initList = false;

    clientStartDate;
    clientEndDate;

    status = true;
    archived = false;
    distributor: string;
    partner: string;
    currentAccountTypes;
    searchTerm: string;

    private subscriptions = new Subscription();
    public orderedBy;

    onDestroy$ = new Subject();

    constructor(public router: Router,
                private route: ActivatedRoute, // used in state do not remove
                public stateService: StateService,
                private alertService: AlertService,
                private apiAdministration: ApiAdministrationService,
                private mainListAdministrationService: MainListAdministrationService,
                private userPreferencesService: UserPreferencesService,
                private accountService: AccountService,
                public translate: TranslateService,
                private adminService: AdminService,
                private changeDetectorRef: ChangeDetectorRef  // Even if flagged as unused, it is used in getList(this, search) below
    ) {

        this.stateService.setPropagation(this, 'admin');

        this.orderedBy = this.state.listColumns.filter(col => col.order !== undefined);

        if (this.state.sideBarStatus === 'clickMode') {
            this.state.sideBarStatus = 'recentActivities';
        }
        if (
            this.stateService.session.sessionData.permissions.accountType === 'client' ||
            this.stateService.session.sessionData.permissions.accountType === 'demo'
        ) {

            // this.router.navigate(
            //     ['/m/admin/account/' +
            //     this.stateService.session.sessionData.accountData.id]).then(() => {});
        }

        this.currentAccountTypes = AccountTypesHelper.setCurrentAccountTypesArray(this.stateService.session.sessionData.permissions.accountType);

    }

    ngOnInit() {
        this.lastSearch = this.state.listSearchFilters.search;

        let initList = this.mainListAdministrationService.getList(
            {listSearchFilters: {}},
            0,
            null
        );
        initList.listObservable.subscribe(list => {
            this.totalItems = list.count;
            this.changeDetectorRef.markForCheck();
        });

        // WATCHES ////////////////////////////////////////////////////////////////////////
        // Update personToDisplay when needed
        this.subscriptions.add(this.state.clientToDisplayWatch$.subscribe(
            () => {
                if (this.toList) {
                    this.clientToDisplay =
                        this.setClientToDisplay(this.toList, this.state.clientToDisplayId);
                }
            }
        ));

        // Update list when needed
        this.subscriptions.add(this.state.listWatch$.subscribe(
            () => {
                this.loading = true;
                this.getAccountList();
            }
        ));

        // Reload data after language switch
        this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            // reset list
            this.toList = {
                count: null,
                data: []
            };

            this.loading = true;
            this.getAccountList();
        });

        this.loading = true;
        this.getAccountList();
    }

    refreshList() {
        this.getList();
    }

    goToPeople() {
        this.router.navigate(['m/people/']).then(() => {
        });
    }

    /**
     * SEARCH FILTER STUFF
     */
    resetSearchFilters() {
        this.searchTerm = undefined;

        this.navigate('resetSearchFilters', {
            resetSearchFilters: true
        });
    }


    setSearchFilter(searchTerm) {
        this.lastSearch = searchTerm;
        // Has changed
        if (this.searchTerm !== searchTerm || this.searchTerm === '') {
            this.searchTerm = searchTerm;
            this.getList();

            let activeElement = document.activeElement as HTMLElement;
            // remove focus from the search input
            activeElement.blur();

            // check the case when the button Search/Clear was clicked
            setTimeout(() => {
                if (document.activeElement.tagName === 'INPUT') {
                    activeElement = document.activeElement as HTMLElement;
                    activeElement.blur();
                }
            }, 0);
        }
    }

    getList() {
        if (this.loading === true) {
            return;
        }

        // re-init
        this.recordsLoaded = 0;
        this.toList = {
            count: null,
            data: []
        };

        let parentAccountNumber;

        if (this.partner || this.distributor) {
            parentAccountNumber = (this.partner) ? this.partner : this.distributor;
        }
        let isEnabled = this.status;
        let isArchived = this.archived;

        if (this.searchTerm && this.searchTerm !== '') {
            isEnabled = undefined;
            isArchived = undefined;
        }

        let payload = {
            search: this.searchTerm,
            accountTypes: this.currentAccountTypes,
            isArchived: isArchived,
            isEnabled: isEnabled,
            parentAccountNumber: parentAccountNumber,
            clientStartDate: this.getClientsDate(this.clientStartDate),
            clientEndDate: this.getClientsDate(this.clientEndDate),
            selfExclude: 1
        };

        this.navigate('getFilteredList', payload);
    }

    getClientsDate(date) {
        if (!date || !date.epoc) {
            return null;
        } else {
            return this.dpToMongoDate(date);
        }
    }

    //
    // filters for the list ***************************************************************
    //

    dpToMongoDate(dateObject) {
        if (dateObject.date.day === 0) {
            return '';
        } else {
            let month = (dateObject.date.month > 9) ? dateObject.date.month : '0' + dateObject.date.month;
            let day = (dateObject.date.day > 9) ? dateObject.date.day : '0' + dateObject.date.day;
            return dateObject.date.year + '-' + month + '-' + day;
        }
    }


    //
    // ************************************************************************************
    //
    getAccountList() {
        let list = this.mainListAdministrationService.getList(
            this.stateService.admin,
            this.recordsLoaded,
            (this.toList && this.toList['count']) ? this.toList['count'] : null
        );
        this.recordsLoaded = list.recordsLoaded;
        let listObservableSubscription: Subscription = list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList = this.setColumnsValues(list, this.stateService.session.sessionData);

                if (this.state.clientToDisplayId && !this.clientToDisplay) {
                    this.clientToDisplay =
                        this.setClientToDisplay(this.toList, this.state.clientToDisplayId);
                }
                this.initList = true;
                this.loading = false;
                listObservableSubscription.unsubscribe();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    editRefresh() {
        // update list
        this.state.listWatch.next(true);
    }

    /**
     * Will use sessionData to transform the subAccount number into subAccount's name
     *
     * @param list
     * @param sessionData
     * @returns {any}
     */
    setColumnsValues(list, sessionData) {

        let dateFormat = ('en' === this.translate.currentLang) ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
        // tslint:disable-next-line:forin
        for (let record in list['data']) {
            if (list['data'].hasOwnProperty(record)) {
                if (list.data[record]['creationDate']) {
                    list.data[record]['creationDate'] = moment.unix(list.data[record]['creationDate'].sec).format(dateFormat);
                }
                if (list.data[record]['status']) {
                    list.data[record]['status'] = ('en' === this.translate.currentLang) ? 'Active' : 'Actif';
                } else {
                    list.data[record]['status'] = ('en' === this.translate.currentLang) ? 'Inactive' : 'Inactif';
                }
                // unlimited credit translation
                if (list.data[record]['totalCredits'] && list.data[record]['totalCredits'] === 'unlimited') {
                    this.translate.get('commons.creditsUnlimitedList').pipe(takeUntil(this.onDestroy$)).subscribe((res: string) => {
                        list.data[record]['totalCredits'] = res;
                    });
                }
            }
        }
        return list;
    }

    setClientToDisplay(toList, clientToDisplayId) {
        if (toList) {
            for (let i in toList['data']) {
                if (toList['data'].hasOwnProperty(i)) {
                    if (toList['data'][i].id === clientToDisplayId) {
                        return toList['data'][i];
                    }
                }
            }
        }
        // fetchHere
        if (clientToDisplayId) {
            this.subscriptions.add(this.apiAdministration.client([clientToDisplayId]).subscribe(
                res => {
                    return (res) ? res : undefined;
                }
            ));
        }
    }

    /**
     * Update list on scroll
     */
    listScrolled(): void {
        if (this.toList['count'] && this.recordsLoaded >= this.toList['count']) {
            return;
        }
        let list = this.mainListAdministrationService.getList(
            this.stateService.admin,
            this.recordsLoaded,
            (this.toList && this.toList['count']) ? this.toList['count'] : null,
            true
        );
        this.recordsLoaded = list.recordsLoaded;
        this.loading = true;
        this.subscriptions.add(list.listObservable.subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            list => {
                this.toList['data'] =
                    [...this.toList['data'], ...this.setColumnsValues(list, this.stateService.session.sessionData)['data']];
                this.loading = false;
                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    clientSelected(item) {
        this.clientToDisplayId = item[0];
        this.navigate('setClient', {
            clientToDisplayId: item[0].id
        });
        if (this.stateService.session.sessionData.accountData.id !== item[0].id) {
            const txtAccountConf = (this.translate.currentLang === 'fr') ? {
                paragraph: 'Attention. Vous êtes sur le point de vous connecter au compte différent' +
                    '. Désirez-vous continuer?',
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            } : {
                paragraph: 'Warning. You are about to connect an another account. Do you want to continue?',
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txtAccountConf);
            this.subscriptions.add(this.alertService.nextAction.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.switchDatabase(item[0].id);
                    }
                }
            ));
        }
    }

    switchDatabase(clientId) {
        this.subscriptions.add(this.apiAdministration.clientsSwitch([clientId]).subscribe(
            data => {
                // Reset state
                this.stateService.resetState('saveOrigin');

                // Clear session Data (to have a clean session for the switch user)
                let sessionStorageBk = JSON.stringify(sessionStorage);
                sessionStorage.clear();
                sessionStorage.setItem('origin', sessionStorageBk);

                // Add key isLoggedIn (why? we already have a `is_logged` var in local storage ...)
                sessionStorage.setItem('session.isLoggedIn', 'true');
                sessionStorage.setItem('reload', 'true');
                // store in local storage
                localStorage.setItem('is_logged', 'yes');
                localStorage.setItem('id_token', data.token);
                this.stateService.session.redirectUrl = '';

                // Init session
                this.subscriptions.add(this.adminService.getSession(this.translate.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;

                        // Init preferences
                        this.userPreferencesService.emptyUserPreferences(
                            'admin',
                            this.stateService.session.sessionData.userData.id
                        );

                        this.router.navigate(['m/people']).then(() => {
                        });

                        const txtAccountPu = (this.translate.currentLang === 'fr') ? {
                            paragraph: 'Le compte est connecté.',
                            texth1: 'Administration',
                            position: 'top-right',
                            type: 'green'
                        } : {
                            paragraph: 'Account connected.',
                            texth1: 'Administration',
                            position: 'top-right',
                            type: 'green'
                        };
                        this.alertService.setConfig(txtAccountPu);
                    }
                ));
            }));
    }

    toggleFilterPanel(event: boolean): void {
        this.switchOff = false;
        this.navigate('setSideBarStatus', {
            sideBarStatus: (event) ? 'searchFilters' : 'recentActivities'
        });
    }

    getProperty(ctx, property) {
        if (ctx[property] && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    reorderingListsRows(event) {
        this.navigate('setListConfig', {
            key: event.key,
            order: event.order
        });
    }

    navigate(action, optParams) {
        this.stateService.navigate(
            this.router,
            'm/admin/list',
            action,
            optParams
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();

        this.subscriptions.unsubscribe();
    }

    get selectionDone() {
        return SearchHelper.selectionDone(this.state);
    }

    get selectionByFilters() {
        return SearchHelper.selectionDone(this.state, 'search');
    }

    get totalListItems() {
        return (this.toList && this.toList.count !== this.totalItems) ? this.toList.count : null;
    }

    get resMention() {
        return (this.totalListItems > 1) ? 'commons.results' : 'commons.result';
    }

    get state() {
        return this.stateService.admin;
    }

}
