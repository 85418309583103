// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prbWarning {
  border-style: solid;
  border-width: 3px;
  border-color: #000;
  text-align: center;
}

.prbWarningText {
  margin: auto;
  padding: 10px;
}

.prbWarningText p {
  padding: 0;
}

.ditPrbSelected {
  color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/web/people-report-communication/people-report-communication.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ;;AACA;EACI,UAAA;AAEJ;;AACA;EACI,WAAA;AAEJ","sourcesContent":[".prbWarning {\n    border-style: solid;\n    border-width: 3px;\n    border-color: #000;\n    text-align: center;\n}\n\n.prbWarningText {\n    margin: auto;\n    padding: 10px;\n}\n.prbWarningText p {\n    padding: 0;\n}\n\n.ditPrbSelected {\n    color: #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
