export class EvaluationRequest {
    types: any;
    type: Array<string>;
    satelliteJobId: string;
    researchQuestions: boolean;
    language: string;
    country: string;
    variant: string;
    autoGen: boolean;
    requestedBy: string;
    requestedByName: string;
    sendersMail: string;
    subAccount: number;
    firstName: string;
    lastName: string;
    organisation: string;
    jobTitle: string;
    jobType: string;
    jobId: string;
    recordId: string;
    email: string;
    department: string;
    status: number;
    createTempUser: boolean;
    recordType: string;
    sendingType: string;
    hasSurveyRa: boolean;

    constructor(options: {
            types?: any,
            type?: Array<string>,
            satelliteJobId?: string,
            researchQuestions?: boolean,
            language?: string,
            country?: string,
            variant?: string,
            autoGen?: boolean,
            requestedBy?: string,
            requestedByName?: string;
            sendersMail?: string,
            subAccount?: number,
            firstName?: string,
            lastName?: string,
            organisation?: string,
            jobTitle?: string,
            jobType?: string,
            jobId?: string,
            recordId?: string,
            email?: string,
            department?: string,
            status?: number,
            createTempUser?: boolean,
            recordType?: string,
            sendingType?: string,
            hasSurveyRa?: boolean;
    } = {}) {
        this.types = options.types || {'mpo': false, 'dit': false, 'satellite': false, 'talents': false};
        this.type = options.type;
        this.researchQuestions = options.researchQuestions || false;
        this.language = options.language || '';
        this.country = options.country || '';
        this.variant = options.variant || '';
        this.autoGen = options.autoGen || true;
        this.requestedBy = options.requestedBy || '';
        this.requestedByName = options.requestedByName || '';
        this.sendersMail = options.sendersMail || '';
        this.subAccount = options.subAccount;
        this.firstName = options.firstName || '';
        this.lastName = options.lastName || '';
        this.organisation = options.organisation || '';
        this.jobTitle = options.jobTitle || '';
        this.jobType = options.jobType || '';
        this.jobId = options.jobId || '';
        this.recordId = options.recordId || '';
        this.email = options.email || '';
        this.department = options.department || null;
        this.status = options.status || 0;
        this.createTempUser = options.createTempUser || true;
        this.recordType = options.recordType || '';
        this.sendingType = options.sendingType || '';
        this.hasSurveyRa = options.hasSurveyRa || false;
    }
}
