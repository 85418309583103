import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {FormTableComponent} from './components/form-table/form-table.component';
import {FormTableMobileComponent} from './components/form-table/mobile/form-table-mobile.component';
import {CheckboxInputDirective} from './directives/checkbox-input.directive';
import {OptionInputDirective} from './directives/option-input.directive';
import {RadioInputDirective} from './directives/radio-input.directive';
import {SelectInputDirective} from './directives/select-input.directive';

import {TextInputDirective} from './directives/text-input.directive';

import {DynamicFormService} from './services/dynamic-form.service';

@NgModule({
    imports     : [
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatRadioModule,
        MatSelectModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports     : [FormTableComponent, FormTableMobileComponent],
    declarations: [TextInputDirective, SelectInputDirective, OptionInputDirective, RadioInputDirective,
        CheckboxInputDirective, FormTableComponent, FormTableMobileComponent],
    providers   : [DynamicFormService]
})
export class NgenioFormsModule {
}
