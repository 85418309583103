import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';

import {ApiPeopleService} from '../../../services/api-people.service';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from 'src/app/core/services/state/state.service';
import {PersonService} from '../../../services/person.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {PeoplePdfModalComponentCommon} from '../../commonClasses/people-pdf-modal-common';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-people-pdf-modal',
    templateUrl: './people-pdf-modal.component.html',
    styleUrls: ['./people-pdf-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeoplePdfModalComponent extends PeoplePdfModalComponentCommon implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;


    selectMethod = false;

    serverText: string;

    method = 'pdf';
    share = 'none';

    constructor(
        public bsModalRef: BsModalRef,
        public person: PersonService,
        protected apiPeopleService: ApiPeopleService,
        protected changeDetectorRef: ChangeDetectorRef,
        private windowRef: WindowRef,
        protected state: StateService,
        protected apiJobService: ApiJobsService,
        protected translate: TranslateService,
        private environment: EnvService
    ) {
        super(
            translate,
            state,
            apiJobService,
            changeDetectorRef,
            apiPeopleService,
            person
        );
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.initPdfStructure();
        if (this.pdfStructure && this.pdfStructure.report) {
            let report = this.pdfStructure.report;
            if (report === 'personality') {
                report = 'mpo';
            }
            if (report === 'communication') {
                report = 'dit';
            }

            // check if we have no evaluation for the actual report
            if (this.person.evaluations && !this.person.evaluations[report]) {
                let defaultReport;
                for (let ev in this.person.evaluations) {
                    if (ev && this.person.evaluations.hasOwnProperty(ev)) {
                        // get the first available report to select by default
                        if (!defaultReport && this.person.evaluations[ev]) {
                            defaultReport = ev;
                        }
                    }
                }
                if (defaultReport) {
                    if (defaultReport === 'mpo') {
                        defaultReport = 'personality';
                    }
                    this.pdfStructure.report = defaultReport;
                    this.reportChange();
                }
            }
        }
    }

    getReportData(data: any) {
        let obs = this.getReportDataCommon();
        this.subcriptions.add(obs.subscribe(
            res => {
                this.pdfToken = res.pdfToken;

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + encodeURI(this.pdfToken);

                if (data.type === 'mailto') {
                    this.mailto = '?subject=' + this.translate.instant('commons.report') + ' '
                        + this.reportName + '&body=' +
                        this.translate.instant('people.pdfModal.linkInstruction', {
                            reportName: this.reportName,
                            firstName: this.person.firstName,
                            lastName: this.person.lastName
                        }) + '%0A%0A' + this.pdfReportUrl;
                    this.mailToJson = {
                        mailto: this.mailto
                    };
                } else if ('share' === data.type) {
                    this.serverText = this.translate.instant('people.pdfModal.linkInstruction', {
                        reportName: this.reportName,
                        firstName: this.person.firstName,
                        lastName: this.person.lastName
                    }) + this.pdfReportUrl;
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.closeModal();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport MPO' : 'MPO Report';
        }

        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.person.firstName + ' ' + this.person.lastName,
            'people',
            this.translate.currentLang
        ];

        this.subcriptions.add(this.apiPeopleService.reportEmail(dataEmail).subscribe());
        this.closeModal();
    }

    handleReport() {
        if (this.method !== 'none') {
            if (this.reportType === 'personality') {
                this.reportName = (this.translate.currentLang === 'fr') ?
                    'MPO Personnalité' :
                    'MPO Personality';
            }
            if (this.reportType === 'communication') {
                this.reportName = 'MPO Communication';
            }
            if (this.reportType === 'satellite') {
                this.reportName = 'MPO Satellite';
            }
            if (this.reportType === 'talents') {
                this.reportName = 'MPO Talents';
            }
            if (this.reportType === 'iac') {
                this.reportName = (this.translate.currentLang === 'fr') ? 'IAC' : 'CAI';
            }
            switch (this.method) {
                case 'pdf':
                    this.downloadReport();
                    return;
                case 'cutpaste':
                    this.share = 'cutpaste';
                    this.getReportData({type: 'token'});
                    return;
                case 'mailto':
                    this.share = 'mailto';
                    this.getReportData({type: 'mailto'});
                    return;
                case 'server':
                    this.share = 'server';
                    this.getReportData({type: 'share'});
                    return;
            }
        }
        this.selectMethod = true;
        return;
    }

    changeSelectMethodState() {
        if (this.method !== 'pdf') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
        this.selectMethod = false;
    }

    toggleGraphOnlyChk() {
        this.graphOnly = !this.graphOnly;
        this.toggleGraphOnly();
    }

    closeModal() {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.initPdfStructure();
            this.method = 'pdf';
            this.share = 'none';
            this.selectMethod = false;
        });
        this.close.emit({closed: true});
        this.bsModalRef.hide();
    }

    get reportType() {
        return (this.pdfStructure && this.pdfStructure.report) ? this.pdfStructure.report : null;
    }

    get stateSession() {
        return (this.state && this.state.session) ? this.state.session : null;
    }

    get raPermission() {
        return (this.stateSession &&
            this.stateSession.sessionData &&
            this.stateSession.sessionData.userData &&
            this.stateSession.sessionData.userData.permissions) ? this.stateSession.sessionData.userData.permissions.ra : false;
    }

    get testAccessPermissions() {
        return (this.stateSession &&
            this.stateSession.sessionData &&
            this.stateSession.sessionData.structure &&
            this.stateSession.sessionData.structure.testAccessPermissions) ? this.stateSession.sessionData.structure.testAccessPermissions : null;
    }

    ngOnDestroy() {
        this.subcriptions.unsubscribe();
        this.close.emit({closed: true});
    }

}

