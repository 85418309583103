import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-archives-rightmatch-list',
    templateUrl: './archives-rightmatch-list.component.html',
    styleUrls: ['./archives-rightmatch-list.component.scss']
})
export class ArchivesRightmatchListComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
