// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#innerTextServer {
  width: 100%;
  border: 1px solid #bbbbbb;
  padding: 10px;
  height: 160px;
  overflow-y: scroll;
}

.ng-touched.ng-invalid {
  border-color: #f44336;
}

::ng-deep .formWrapper .mat-grid-tile .mat-figure {
  justify-content: flex-end;
}

::ng-deep .formWrapper .mat-grid-tile .mat-figure button:first-child {
  margin-right: 10px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}
.flex-right :first-child {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/idw-matrices/components/mobile/mobile-idw-matrices-pdf/mobile-idw-matrices-pdf.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;AACJ;AAAI;EACI,kBAAA;AAER","sourcesContent":["#innerTextServer {\n    width: 100%;\n    border: 1px solid #bbbbbb;\n    padding: 10px;\n    height: 160px;\n    overflow-y: scroll;\n}\n\n.ng-touched.ng-invalid {\n    border-color: #f44336;\n}\n\n::ng-deep .formWrapper .mat-grid-tile .mat-figure {\n    justify-content: flex-end;\n}\n\n::ng-deep .formWrapper .mat-grid-tile .mat-figure button:first-child {\n    margin-right: 10px;\n}\n\n.flex-right {\n    display: flex;\n    justify-content: flex-end;\n    :first-child {\n        margin-right: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
