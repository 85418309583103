import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    OnChanges,
    OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {PersonService} from 'src/app/people/services/person.service';
import {AlertService} from '../../../../../shared/alert/services/alert.service';
import {Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PermissionsService} from 'src/app/shared/permissions/permissions.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {GraphBoardsModalHandlingService} from '../../../services/graphboards-modal-handling.service';
import {ComponentSidebar} from 'src/app/shared/classes/components/component_sidebar';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-graphboards-sidebar',
    templateUrl: './graphboards-sidebar.component.html',
    styleUrls: ['./graphboards-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsSidebarComponent extends ComponentSidebar implements OnInit, OnChanges, OnDestroy {

    itemList: any = [];

    @Input() width = '';
    @Input() config: any;
    @Input() isLeftAndNotTop = true;
    @Input() archivedRecords: any;

    maxPerPage: number;

    urlPath: string;
    titleSep: string;

    onDestroy$: Subject<null> = new Subject();

    private subscriptions = new Subscription();

    dateFormat;

    constructor(
        public person: PersonService,
        public graphBoard: GraphBoardsAnalysisService,
        public graphBoardsList: GraphBoardsListService,
        public graphBoardsModalHandlingService: GraphBoardsModalHandlingService,
        protected router: Router,
        protected stateService: StateService,
        protected route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private apiGraphboardsService: ApiGraphboardsService,
        private alertService: AlertService,
        protected translateService: TranslateService,
        private permissionsService: PermissionsService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnInit() {
        this.dateFormat = TextHelper.dateFormat(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );
        this.maxPerPage = (this.isLeftAndNotTop) ? 17 : 5;
        if (!this.isLeftAndNotTop) { this.width = null; }
        this.graphBoardsList.checkModuleUrl();
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);
        this.urlPath = this.route.snapshot.url[1].path;
        this.gActualPage['individus'] = 1;
        this.gActualPage['profiler'] = 1;

        this.checkAlignment();
        this.state.stateChanged$.subscribe(
            (res) => {
                if (res && res.action === 'itemSelected') {
                    this.checkAlignment();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    checkAlignment() {
        let itemList = {};
        this.itemList = {};
        itemList['individus'] = [];
        itemList['profiler'] = [];
        this.itemList['individus'] = [];
        this.itemList['profiler'] = [];
        if (this.graphBoard.alignment) {
            for (let line of this.graphBoard.alignment) {
                for (let item of line) {
                    let pushedItem = JSON.parse(JSON.stringify(item));
                    let analysisType = 'profiler';
                    if (item.analysisType === 'trait' || item.analysisType === 'prb') {
                        analysisType = 'individus';
                        if (item.analysisType === 'trait') {
                            pushedItem['hasTrait'] = true;
                        }
                        if (item.analysisType === 'prb') {
                            pushedItem['hasPrb'] = true;
                        }
                        for (let index in itemList['individus']) {
                            if (itemList['individus'].hasOwnProperty(index)) {
                                if (itemList['individus'][index].id === item.id) {
                                    pushedItem['hasTrait'] = true;
                                    pushedItem['hasPrb'] = true;
                                    itemList['individus'].splice(index, 1);
                                }
                            }
                        }
                        pushedItem.analysisType = 'individus';
                    }
                    if (item.analysisType === 'individus') {
                        analysisType = 'individus';
                    }
                    itemList[analysisType].push(pushedItem);
                }
            }
            itemList['cIndividus'] = (itemList['individus']) ? itemList['individus'].length : 0;
            itemList['cProfiler'] = (itemList['profiler']) ? itemList['profiler'].length : 0;

            this.gMaxPage['individus'] = Math.ceil(itemList['cIndividus'] / this.maxPerPage);
            this.gMaxPage['profiler'] = Math.ceil(itemList['cProfiler'] / this.maxPerPage);
            itemList['individus'].sort(
                function(a, b) {
                    let textA = a.firstName.toUpperCase();
                    let textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );
            itemList['profiler'].sort(
                function(a, b) {
                    let textA = a.jobTitle.toUpperCase();
                    let textB = b.jobTitle.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            );

            this.itemList = itemList;
        } else {
            this.itemList['cIndividus'] = 0;
            this.itemList['cProfiler'] = 0;
            this.gMaxPage['individus'] = 0;
            this.gMaxPage['profiler'] = 0;
        }
    }

    ngOnChanges(changes) {
        // prevent refresh upon change of width
        let keys = Object.keys(changes);
        let firstKey = keys[0];
        if (keys.length === 1 && firstKey === 'width') { return; }
    }

    notSpecified() {
        if (this.translateService.currentLang === 'fr') {
            return 'Non spécifié';
        } else {
            return 'Not specified';
        }
    }

    setSubAccountName(subAccountNumber) {
        let name = null;
        if (subAccountNumber >= 0) {
            for (let subAccData of this.stateService.session.sessionData.structure.subAccounts) {
                if (subAccData.id === subAccountNumber) {
                    name = subAccData.name;
                    return name;
                }
            }
        }
        return name;
    }

    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

    navigate(action, optParams) {
        this.stateService.navigate(
            this.router,
            this.graphBoardsList.url,
            action,
            optParams
        );
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'navigate');
    }

    onPdfShare() {
        if (this.itemList && (this.itemList['cIndividus'] > 0 || this.itemList['cProfiler'] > 0)) {
            this.graphBoardsModalHandlingService.openGraphBoardPdfModal(this.graphBoard.itemToDisplay);
        }
    }

    openEditModal() {
        this.graphBoardsModalHandlingService.openGraphBoardModal({'status': 'edit'});
    }

    openPersonModal() {
        this.graphBoardsModalHandlingService.openGraphBoardPersonModal(this.archivedRecords);
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        let pAction;
        let total = (this.translateService.currentLang === 'fr') ? 'un' : 'a';
        let paragraph;
        if (action === 'archive') {
            if (act) {
                p[0] = this.translateService.instant('analysis.graphBoard.multipleArchived', {total: total, plural: ''});
                h1[0] = this.translateService.instant('commons.archiving');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[1] = this.translateService.instant('commons.wasError');

                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: ''
                });
                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            } else {
                p[0] = this.translateService.instant('analysis.graphBoard.multipleReactivated', {total: total, plural: ''});
                h1[0] = this.translateService.instant('commons.reactivation');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[1] = this.translateService.instant('commons.wasError');

                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: ''
                });

                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            }

        } else if (action === 'delete') {
            p[0] = this.translateService.instant('analysis.commons.graphBoardDeleted', {
                total: total,
                plural: ''
            });
            h1[0] = this.translateService.instant('analysis.commons.graphBoardDeletion');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            pAction = this.translateService.instant('commons.deletionAction');
            paragraph = this.translateService.instant('analysis.graphBoard.actionConfirmation', {
                action: pAction,
                total: total,
                plural: ''
            });
            const txt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txt);
        }

        this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm' && action) {
                    if (action === 'delete') {
                        this.apiGraphboardsService.deleteGraphboard([this.graphBoard.id]).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                this.state.itemToDisplayId = undefined;
                                this.graphBoard.resetItemToDisplay(this.specificState());

                                this.graphBoardsList.getGraphBoardsList(true);
                                if (this.config === 'list') {
                                    this.goToNews();
                                } else {
                                    this.goToList();
                                }
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        );
                    } else {
                        this.apiGraphboardsService.putGraphboard([this.graphBoard.id], {archive: act}).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (action === 'archive') {
                                    this.state.itemToDisplayId = undefined;
                                    this.graphBoard.resetItemToDisplay(this.specificState());
                                }
                                this.graphBoardsList.getGraphBoardsList(true);
                                if (this.config === 'list') {
                                    this.goToNews();
                                } else {
                                    this.goToList();
                                }
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                            }
                        );
                    }
                    action = undefined;
                }
            }
        );
        return;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get canDelete() {
        return this.permissionsService.canDelete();
    }
}
