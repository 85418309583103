import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {RightMatchAnalysisService} from '../../../../../services/right-match-analysis.service';

@Component({
    selector: 'app-right-matches-person-sub-card-talents',
    templateUrl: './right-matches-person-sub-card-talents.component.html',
    styleUrls: ['./right-matches-person-sub-card-talents.component.scss',
        '../../right-matches-person-card.component.scss'
    ]
})
export class RightMatchesPersonSubCardTalentsComponent implements OnChanges {

    @Input() record;
    @Input() job;
    @Input() pos;
    @Input() informationVisibility = false;
    @Input() listView = true;

    @ViewChild('thisCard', {static: false}) thisCard;

    competenciesTotal = 0;

    constructor(
        public translateService: TranslateService,
        public rightMatch: RightMatchAnalysisService,
        public cd: ChangeDetectorRef
    ) {
    }

    ngOnChanges() {
        this.competenciesTotal = _.size(this.job['competences'][0]);
        this.cd.markForCheck();
    }

    onResize(event) {
        console.log(this.thisCard.nativeElement.offsetWidth);
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    get fakeLines() {
        let lengths = [];
        if (this.record['talents'].length === 0) {
            let availLength =
                [100, 110, 120, 130, 90, 100, 120, 140, 110, 120, 100, 110, 120, 130, 90, 100, 120, 140, 110, 120];
            for (let i = 0; i < _.size(this.job['competences'][0]); i++) {
                lengths.push(availLength[i]);
            }
        }
        return lengths;
    }

    get competenciesGreenTotal() {
        if (
            this.record &&
            this.record.displayValues &&
            this.record.displayValues.competencies &&
            this.record.displayValues.competencies.competenciesGreenTotal
        ) {
            return this.record.displayValues.competencies.competenciesGreenTotal;
        } else {
            return null;
        }
    }

    get competenciesYellowTotal() {
        if (
            this.record &&
            this.record.displayValues &&
            this.record.displayValues.competencies &&
            this.record.displayValues.competencies.competenciesYellowTotal
        ) {
            return this.record.displayValues.competencies.competenciesYellowTotal;
        } else {
            return null;
        }
    }

    get competenciesRedTotal() {
        if (
            this.record &&
            this.record.displayValues &&
            this.record.displayValues.competencies &&
            this.record.displayValues.competencies.competenciesRedTotal
        ) {
            return this.record.displayValues.competencies.competenciesRedTotal;
        } else {
            return null;
        }
    }
}
