import {Injector} from '@angular/core';
import {EnvServiceProvider} from '../../../../../app/services/env.service.provider';
import {EnvService} from '../../../../../app/services/env.service';

export class PeopleSurveyRequestSendMethodsHelper {

    /**
     * Get instructions text by lang
     * The lang is defined by the form, it is not the app language
     * @param lang
     * @param variant
     * @param options
     */
    public static getInstructionsMessages(lang: string, variant: string, options: any): any {

        const requesterName = options.extraData.requesterData.name;
        const requesterCompany = options.extraData.requesterData.company;
        const hasIac = options.extraData.hasIac;

        let time1 = 10;
        let time2 = 15;
        let arTime =
            ' 10 ' +
            ' إلى '
            + ' 15 ';
        let sectionsNumber = 2;
        let spanFrom = 1;
        let spanTo = 2;

        if (variant === '1,1') {
            time1 = 15;
            time2 = 20;
            arTime =
                ' 15 ' +
                ' إلى '
                + ' 20 ';
            sectionsNumber = 4;
            spanFrom = 1;
            spanTo = 4;
        }

        if (hasIac) {
            if (variant !== '0,0') {
                time1 = 25;
                time2 = 35;
                if (variant === '1,1') {
                    time1 = 30;
                    time2 = 40;
                }
                sectionsNumber += 1;
            } else {
                time1 = 10;
                time2 = 15;
                sectionsNumber = 1;
            }

        }


        let span = {
            en: '',
            fr: ''
        };

        if (spanTo === 4) {
            span = {
                en: '1 to 4',
                fr: '1 à 4'
            };
        } else if (spanTo === 2) {
            span = {
                en: '1 and 2',
                fr: '1 et 2'
            };
        }

        let instructions = {
            fr: {
                language: 'fr',
                emailSubject: 'Accès au questionnaire MPO',
                greetings: 'Bonjour',
                requesterIdentification: (requesterName || requesterCompany) ? `Vous recevez ce courriel à la demande de ${requesterName} de la compagnie ${requesterCompany}.` : null,
                condition: 'Afin que vous puissiez compléter le questionnaire MPO, un code d\'accès unique a été créé pour vous.'
                    + ` Pour obtenir des résultats précis, prévoyez de ${time1} à ${time2} minutes sans être dérangé et lisez attentivement les instructions au début de chaque section.`,
                username: 'Votre nom d\'utilisateur est :',
                password: 'Votre mot de passe est :',
                activate: 'Cliquez sur le lien suivant pour remplir le questionnaire :',
                call: 'Pour toute question, communiquez avec la personne qui vous a fait parvenir ce questionnaire.',
                thanks: 'Merci et bonne journée!',
                signature: 'L\'équipe de Ngenio'
            },
            en: {
                language: 'en',
                emailSubject: 'Access to the MPO Questionnaire',
                greetings: 'Dear',
                requesterIdentification: (requesterName || requesterCompany) ? `This email has been sent to you by ${requesterName} from ${requesterCompany}.` : null,
                condition: `In order to complete the MPO survey, a unique access code has been created for you.`
                    + `To get a proper result, allow ${time1} to ${time2}`
                    + ' minutes without any disturbances or distractions and read the instructions carefully at the beginning of each section.',
                username: 'Your username is:',
                password: 'Your password is:',
                activate: 'Fill the questionnaire by clicking on the following link:',
                call: 'If you have any questions, contact the person who sent you this questionnaire.',
                thanks: 'Thank you and have a nice day!',
                signature: 'The Ngenio team'
            },
            ar: {
                language: 'ar',
                emailSubject: 'MPO الدخول الى الأستبيان',
                greetings: 'مرحبا',
                requesterIdentification: (requesterName || requesterCompany) ?  `"تم إرسال هذا البريد الإلكتروني من ${requesterName} لشركة ${requesterCompany}."` : null,
                condition: `لتتمكن من استكمال استبيان MPO،  دقيقة, يتعين في` +
                    `انتظروا ${time1} إلى ${time2}`
                    + ' دقيقة دون ازعاج، و اقرؤوا التعليمات بعناية في بداية كل قسم.',
                username: 'اسم المستخدم  :',
                password: 'كلمة المرور  :',
                activate: 'يرجى تفعيل الجلسة الخاصة بك',
                call: 'لأي اسئلة، تواصلوا مع الشخص الذي ارسل إليكم الاستمارة.',
                thanks: 'شكرا و نتمنى لكم يوما سعيدا!',
                signature: 'فريق Ngenio'
            },
            es: {
                language: 'es',
                emailSubject: 'Cuestionario de Personalidad MPO',
                greetings: 'Buen día',
                requesterIdentification: (requesterName || requesterCompany) ? `Este correo electrónico fue enviado a usted por ${requesterName} de la compañía ${requesterCompany}.` : null,
                condition: `Con el fin de que usted pueda completar el cuestionario MPO, hemos creado un código exclusivo de acceso para usted. ` +
                    `Para obtener resultados precisos, permítase de ${time1} a ${time2}`
                    + ' sin ser molestado y lea las instrucciones cuidadosamente al comienzo de cada sección.',
                username: 'Su nombre de usuario es:',
                password: 'Su contraseña es:',
                activate: 'Haga clic en el siguiente enlace por completar el cuestionario:',
                call: 'Si tiene alguna pregunta, comuníquese con la persona que le envió este cuestionario.',
                thanks: 'Gracias y buen día.',
                signature: 'El equipo de Ngenio'
            }
        };

        if (!instructions[lang]) {
            // fallback to FR
            return instructions.fr;
        }

        return instructions[lang];
    }

    public static emailClientText(instructionsMessages, fullName, username, password, surveyLink, lang) {
        let emailClientText = '';
        emailClientText = `${instructionsMessages.greetings} ${fullName},%0A%0A`;
        // emailClientText += (instructionsMessages.requesterIdentification) ? instructionsMessages.requesterIdentification + `%0A%0A` : '';

        emailClientText += `${instructionsMessages.condition}%0A%0A`;
        emailClientText += `${instructionsMessages.username} ${username}%0A
                ${instructionsMessages.password} ${password}%0A%0A
                ${instructionsMessages.activate} ${surveyLink}%0A%0A
                ${instructionsMessages.call}%0A%0A
                ${instructionsMessages.thanks}%0A%0A
                ${instructionsMessages.signature}%0A%0A`;


        return emailClientText.replace(/(?:\r\n|\r|\n|\s{3,})/g, '').replace(/\s/g, '%20').replace(/&/g, '%26');
    }

    public static copyPasteText(instructionsMessages, username, password, surveyLink, lang) {
        let copyPasteText;

        copyPasteText = `
            <p>${instructionsMessages.condition}</p>
            `;
        copyPasteText += `<p xmlns="http://www.w3.org/1999/html">${instructionsMessages.username} ${username}</p>
            <p>${instructionsMessages.password} ${password}</p>
            <p>
                ${instructionsMessages.activate}
                ${surveyLink}
            </p>
            <p>
                ${instructionsMessages.call}
            </p>
            <p>
                ${instructionsMessages.thanks}</br>
                ${instructionsMessages.signature}
            </p>


            `;


        return copyPasteText;
    }

    /**
     * Get the external survey link
     * @param country
     * @param language
     * @param username
     * @param password
     * @param isSatellite
     */
    public static getSurveyLink(country: string, language: string, username: string, password: string, isSatellite: boolean): string {
        let injector = Injector.create([EnvServiceProvider]);
        let environment = injector.get(EnvService);

        let evaluationFormUrl = `${environment.evaluationFormUrl}/login?lang=${PeopleSurveyRequestSendMethodsHelper.getLinkLang(country, language)}&orgLang=${language}&username=${username}&password=${password}`;

        return encodeURIComponent(evaluationFormUrl);
    }

    /**
     * Get the lang for the survey (lang parameter, not orgLang parameter that is the requested lang)
     * @param country
     * @param language
     */
    public static getLinkLang(country: string, language: string) {
        if (language === 'es' || language === 'ar') {
            if (country === 'us') {
                return 'en';
            } else {
                return 'fr';
            }
        }
        return language;
    }

    /**
     * Get the translation string for the action button
     * @param step
     * @param sendAnotherSurvey
     */
    public static getButtonTranslation(step: string, sendAnotherSurvey: boolean): string {
        if (sendAnotherSurvey) {
            // return 'commons.continue';
        }

        if (step === 'server') {
            return 'commons.send';
        }

        return 'commons.finish';
    }

    /**
     *
     * @param lang
     * @param variant
     * @param requesterData
     * @param hasIac
     */
    public static getInstructionMessagesWrapper(lang, variant, requesterData, hasIac) {
        return this.getInstructionsMessages(
            lang,
            variant,
            {
                extraData: {
                    requesterData: {
                        name: requesterData.name,
                        company: requesterData.company
                    },
                    hasIac: hasIac
                }
            });
    }

    /**
     *
     * @param type
     * @param person
     * @param tempUser
     * @param lang
     * @param variant
     * @param requesterData
     * @param hasIac
     */
    public static initMessage(type, person, tempUser, lang, variant, requesterData, hasIac = false) {
        let injector = Injector.create([EnvServiceProvider]);
        let environment = injector.get(EnvService);

        let qLink = environment.evaluationFormUrl + '/login?lang=' + lang + '&orgLang=' + lang + '&username=' + tempUser.username +
            '&password=' + tempUser.tmpPwd;
        if ('mail' === type) {
            return PeopleSurveyRequestSendMethodsHelper.emailClientText(
                this.getInstructionMessagesWrapper(lang, variant, requesterData, hasIac),
                person.firstName + ' ' + person.lastName,
                tempUser.username,
                tempUser.tmpPwd,
                encodeURIComponent(qLink),
                lang
            );
        } else if ('cutnpaste' === type) {
            return PeopleSurveyRequestSendMethodsHelper.copyPasteText(
                this.getInstructionMessagesWrapper(lang, variant, requesterData, hasIac),
                tempUser.username,
                tempUser.tmpPwd,
                qLink,
                lang
            );
        }

    }
}
