import {OnDestroy, ElementRef, ViewChild, OnInit} from '@angular/core';
import {StateService} from '../../../../core/services/state/state.service';
import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-people-report-satellite',
    templateUrl: './people-report-satellite.component.html',
    styleUrls: ['./people-report-satellite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleReportSatelliteComponent implements OnInit, OnDestroy {

    @Input() mobile = false;
    @Input() satelliteScore;
    @ViewChild('noJobDiv') noJobDiv: ElementRef;

    traits: Array<string> = ['OR', 'SE', 'A', 'E', 'P', 'S', 'AD'];

    report: any;
    error: any;

    private subscriptions = new Subscription();
    currentLang;
    titleSep: string;
    dateFormat: string;

    constructor(private router: Router,
                protected translate: TranslateService,
                private changeDetectorRef: ChangeDetectorRef, // used in peopleReportService even if flagged otherwise
                private testAndReportHandlingService: TestAndReportHandlingService,
                private stateService: StateService,
                public person: PersonService
                ) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.report = null;
        this.error = null;
        this.getSatelliteReport();
        this.subscriptions.add(this.translate.onLangChange.subscribe((res) => {
            if (!(!this.person.satelliteScore || this.person.satelliteScore === 'noJobProfile')) {
                this.getSatelliteReport();
            }
            if (res) {
                this.titleSep = TextHelper.checkTitleSep(res.lang);
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        }));

        this.stateService.people.stateChanged$.subscribe(
            (res) => {
                if ((res && res.personId) || (res && res.profilerId && res.profilerId !== 'none')) {
                    this.getSatelliteReport();
                } else if (res && res.profilerId && res.profilerId === 'none') {
                    this.report = null;
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        if (!(!this.person.satelliteScore || this.person.satelliteScore === 'noJobProfile')) {
            setTimeout( () => {
                if (this.error) {
                    if (this.noJobDiv) {
                        let noReportMessage = '<i style="color: red;">' + this.error.message + '</i>';
                        if (this.error.code === 500) {
                            noReportMessage = '<b>' + this.translate.instant('people.reportSatellite.noReportFound') + '</b>' +
                                ' (<i style="color: red;">' + this.translate.instant('people.reportSatellite.serverError') + '</i>)';
                        }
                        this.noJobDiv.nativeElement.getElementsByTagName('span')[0].innerHTML = noReportMessage;
                    }
                }
            }, 400);
        }
    }

    getSatelliteReport() {
        if (this.person.satelliteScore && this.person.satelliteScore !== 'noJobProfile') {
            this.subscriptions.add(this.testAndReportHandlingService.getReport(
                'satellite',
                this.translate.currentLang
            ).subscribe(
                report => {
                    this.report = report;
                    this.changeDetectorRef.markForCheck();
                },
                err => console.log(err)
            ));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    goToJob(id: string) {
        let url = '';
        let backLink = 'people/report/';
        if (this.mobile) {
            url = 'm/';
            backLink = 'm/people/view/';
        }
        this.stateService.jobs.reportBack = {
            to: backLink,
            personToDisplayId: this.person.id,
            reportType: 'satellite',
            action: 'getFullReport',
            module: 'people'
        };
        this.router.navigate([url + 'jobs/report/' + id + '/mpo']).then(() => {});

        // TODO: Rewrite this - refactoring
        /**
        this.stateService.navigate(
            this.router,
            url + 'jobs/report/' + id + '/mpo',
            'setReport',
            {
                jobToDisplayId: id,
                reportType: 'mpo',
                display: 'report',
                reportBack: {
                    to: backLink,
                    personToDisplayId: this.person.id,
                    reportType: 'satellite',
                    action: 'getFullReport',
                    module: 'people'
                }
            },
            'jobs'
        );*/
    }

    moveTo(fragment) {
        const element = document.querySelector('#' + fragment);
        if (element) {
            element.scrollIntoView(true);
        }
        return;
    }

    get warnings(): any {
        if (
            this.report.info.evaluationWarnings.extremeScores ||
            this.report.info.evaluationWarnings.socialDesirability
        ) {
            return this.report.info.evaluationWarnings;
        }
        return false;
    }

    get state(): any {
        return this.stateService.people;
    }

    get profile(): any {

        if (_.get(this.report, 'evaluation.profile')) {
            return this.report.evaluation.profile;
        }

        if (_.get(this.report, 'profiler.profile')) {
            return this.report.profiler.profile;
        }

        return false;
    }


}
