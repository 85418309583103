// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#graphBlock {
  margin-top: 10px;
}

.raJobProfile {
  float: left;
  width: 20%;
}

.modLink {
  float: right;
  text-align: right;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/app/jobs/components/jobs-report-iac/jobs-report-iac.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,iBAAA;EACA,UAAA;AACJ","sourcesContent":["#graphBlock {\n    margin-top: 10px;\n}\n\n.raJobProfile {\n    float: left;\n    width: 20%;\n}\n\n.modLink {\n    float: right;\n    text-align: right;\n    width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
