import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PeopleSurveyRequestFormService} from '../../../../../services/people-survey-request-form.service';

@Component({
    selector: 'app-people-survey-request-credits-warning',
    template: `
    <div *ngIf="displayWarningMessage$ | async" style="margin-bottom: 15px; width: 100%">
        <div style="margin: auto; width: 80%; border: 2px solid rgb(240, 123, 126); padding: 5px; color: rgb(240, 123, 126); background-color: #f5f5f5">
            <div>
                {{ 'people.requestModal.cresditsWarningMessage' | translate }}
            </div>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestCreditsWarningComponent implements OnInit, OnDestroy {
    private onDestroy$: Subject<null> = new Subject();
    public displayWarningMessage$: Observable<boolean>;

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService
    ) {}

    ngOnInit() {
        this.displayWarningMessage$ = this.peopleRequestFormService.displayWarningMessage$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
