import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SharedModule} from '../shared/shared.module';
import {JobsListComponent} from './components/web/jobs-list/jobs-list.component';
import {JobsReportComponent} from './components/web/jobs-report/jobs-report.component';
import {JobsProfilerEmailModalComponent} from './components/web/jobs-profiler-email-modal/jobs-profiler-email-modal.component';
import {JobsDuplicateModalComponent} from './components/web/jobs-duplicate-modal/jobs-duplicate-modal.component';
import {UnaccessibleTestsModalComponent} from 'src/app/shared/components/unaccessible-tests-modal/unaccessible-tests-modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {NgenioFormsModule} from '../shared/forms/ngenio-forms.module';
import {JobsRoutingModule} from './jobs-routing.module';
import {ApiJobsService} from './services/api-jobs.service';
import {JobReportService} from './services/job-report.service';
import {JobsSidebarComponent} from './components/web/jobs-sidebar/jobs-sidebar.component';
import {JobsListSearchFiltersComponent} from './components/web/jobs-list-search-filters/jobs-list-search-filters.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {JobsReportMpoComponent} from './components/web/jobs-report-mpo/jobs-report-mpo.component';
import {JobsSelectedItemsComponent} from './components/web/jobs-selected-items/jobs-selected-items.component';
import { JobsMultiselectActionComponent } from './components/web/jobs-multiselect-action/jobs-multiselect-action.component';
import { NgxLoadingModule } from 'ngx-loading';
import { JobsPdfModalComponent } from './components/web/jobs-pdf-modal/jobs-pdf-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {WindowRef} from '../app/services/window-ref.service';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { JobsReportTalentsComponent } from './components/web/jobs-report-talents/jobs-report-talents.component';
import { TalentsModule } from '../shared/talents/talents.module';
import { SidebarModule } from 'ng-sidebar';
import { JobsIacModalComponent } from './components/jobs-iac-modal/jobs-iac-modal.component';
import { JobsReportIacComponent } from './components/jobs-report-iac/jobs-report-iac.component';
import { MobileJobsListComponent } from './components/mobile/mobile-jobs-list/mobile-jobs-list.component';
import { MobileJobsReportComponent } from './components/mobile/mobile-jobs-report/mobile-jobs-report.component';
import { MobileJobsEditComponent } from './components/mobile/mobile-jobs-edit/mobile-jobs-edit.component';
import { MobileJobsPdfComponent } from './components/mobile/mobile-jobs-pdf/mobile-jobs-pdf.component';
import { MobileJobsProfilerComponent } from './components/mobile/mobile-jobs-profiler/mobile-jobs-profiler.component';
import { MobileJobsCompetencesComponent } from './components/mobile/mobile-jobs-competences/mobile-jobs-competences.component';
import {PeopleModule} from '../people/people.module';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import { MobileJobsIacComponent } from './components/mobile/mobile-jobs-iac/mobile-jobs-iac.component';
import { NoJobModalComponent } from './components/web/no-job-modal/no-job-modal.component';
import { JobFakeReportComponent } from './components/web/job-fake-report/job-fake-report.component';
import { MobileJobsProfilerEmailComponent } from './components/mobile/mobile-jobs-profiler-email/mobile-jobs-profiler-email.component';
import { MobileJobsProfilerRequestResendComponent } from './components/mobile/mobile-jobs-profiler-request-resend/mobile-jobs-profiler-request-resend.component';
import { JobProfilerRequestResendModalComponent } from './components/web/job-profiler-request-resend-modal/job-profiler-request-resend-modal.component';
import { JobsEditModalComponent } from './components/web/jobs-edit-modal/jobs-edit-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        ModalModule,
        NgenioFormsModule,
        JobsRoutingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgMultiSelectDropDownModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        NgxLoadingModule,
        TranslateModule,
        TalentsModule,
        SidebarModule.forRoot(),
        MatButtonModule,
        MatGridListModule,
        PeopleModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTabsModule,
        TypeaheadModule
    ],
    declarations: [
        JobsListComponent, JobsReportComponent,
        JobsSidebarComponent, JobsListSearchFiltersComponent, JobsReportMpoComponent,
        JobsProfilerEmailModalComponent, JobsSelectedItemsComponent,
        JobsDuplicateModalComponent,
        JobsMultiselectActionComponent,
        JobsPdfModalComponent,
        JobsReportTalentsComponent,
        JobsIacModalComponent,
        JobsReportIacComponent,
        MobileJobsListComponent,
        MobileJobsReportComponent,
        MobileJobsEditComponent,
        MobileJobsPdfComponent,
        MobileJobsProfilerComponent,
        MobileJobsCompetencesComponent,
        MobileJobsIacComponent,
        NoJobModalComponent,
        JobFakeReportComponent,
        MobileJobsProfilerEmailComponent,
        MobileJobsProfilerRequestResendComponent,
        JobProfilerRequestResendModalComponent,
        JobsEditModalComponent
    ],
    providers: [
        ApiJobsService,
        JobReportService,
        WindowRef
    ],
    exports: [
        JobsListSearchFiltersComponent,
        JobsSidebarComponent,
        JobsSelectedItemsComponent,
        JobsListComponent,
        MobileJobsListComponent,
        MobileJobsReportComponent
    ]
})
export class JobsModule {
}
