import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {JobSatisfactionsGraphs} from '../job-satisfactions-graphs';
import {TranslateService} from '@ngx-translate/core';
import {JobSatisfaction} from '../../../analysis/jobSatisfactions/models/job-satisfaction.model';
import {
    JobSatisfactionsAnalysisService
} from '../../../analysis/jobSatisfactions/services/job-satisfactions-analysis.service';
import * as moment from 'moment';

@Component({
    selector: 'app-job-satisfactions-team-graphs',
    templateUrl: './job-satisfactions-team-graphs.component.html',
    styleUrls: ['./job-satisfactions-team-graphs.component.scss']
})
export class JobSatisfactionsTeamGraphsComponent extends JobSatisfactionsGraphs implements OnInit {


    @ViewChild('jobSatisfactionTeamGraph', {static: true}) private chartContainer: ElementRef;

    @Input() records: any = [];
    @Input() id = 'actual';
    prbs: any = [];
    actualIndex: number;
    localeName = 'fr-CA';


    constructor(
        public translate: TranslateService,
        public jobSatisfaction: JobSatisfactionsAnalysisService
    ) {
        super();
    }

    get hasPredecessor() {
        return (this.actualIndex > 0);
    }

    get hasSuccessor() {
        return (this.actualIndex < this.prbs.length - 1);
    }

    onPreviousYear() {
        if (this.hasPredecessor) {
            this.actualIndex--;
            this.graph();
        }
    }

    onNextYear() {
        if (this.hasSuccessor) {
            this.actualIndex++;
            this.graph();
        }
    }

    init(records) {
        this.prbs = this.setData(records);
        this.actualIndex = this.prbs.length - 1;
        this.setLocale();
        this.graph();
    }

    ngOnInit(): void {
        this.init(this.records);
        this.translate.onLangChange.subscribe(() => {
            this.setLocale();
        });
    }

    setLocale() {
        this.localeName = (this.translate.currentLang === 'fr') ? 'fr-CA' : 'en-US';
        d3.json('https://cdn.jsdelivr.net/npm/d3-time-format@3/locale/' + this.localeName + '.json').then((locale) => {
            // @ts-ignore
            d3.timeFormatDefaultLocale(locale);
        });
    }

    graph() {
        // set the dimensions and margins of the graph
        let margin = {top: 10, right: 30, bottom: 30, left: 60},
            width = 728.5 - margin.left - margin.right,
            height = 130 - margin.top - margin.bottom;

        let element = this.chartContainer.nativeElement;

        // Remove duplicates
        d3.select('#' + 'jobSatisfactionTeamGraph_' + this.id).remove();

        let svg = d3.select(element)
            .append('svg')
            .attr('id', 'jobSatisfactionTeamGraph_' + this.id)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '-20 -8 660 125')
            .attr('preserveAspectRatio', 'none');


        // Add X axis --> it is a date format
        let x = d3.scaleTime().domain(d3.extent(this.prbs[this.actualIndex], function (d: any) {
            return new Date(d.date);
        })).range([20, width]);

        // Add Y axis
        let y = d3.scaleLinear()
            .domain([0, 4])
            .range([height, 0]);
        this.writeGridEs(svg, width, height, x, y);

        // End of axis, remove first and last ticks
        // this.prbs.shift();
        // Gradient definition
        /**
         svg.append('linearGradient')
         .attr('id', 'svgGradient')
         .attr('gradientUnits', 'userSpaceOnUse')
         .attr('x1', 0)
         .attr('y1', 130 - margin.top - margin.bottom) // 110
         .attr('x2', 0)
         .attr('y2', 0)// 0
         .selectAll('stop')
         .data(d3.ticks(0, 2, 3))
         .join('stop')
         .attr('offset', d => d / 2)
         .attr('stop-color', d => this.color[d]);
         */
        // Add the curve
        svg
            .append('path')
            .datum(this.prbs[this.actualIndex])
            .attr('fill', 'none')
            .attr('stroke', '#666')
            .attr('stroke-width', 1.25)
            .attr('d', d3.line()
                .x(function (d: any) {
                    return x(new Date(d.date));
                })
                .y(function (d: any) {
                    return y(d.percent);
                })
                .curve(d3.curveMonotoneX));

        // Complete the curve

        let lastPoint = this.prbs[this.actualIndex][this.prbs.length - 1];
        if (lastPoint) {
        let lastColor = this.color[lastPoint.percent];
        let endLine = [
            lastPoint,
            {
                date: this.getLastDate(this.prbs[this.actualIndex][(this.prbs.length - 1)].date),
                percent: lastPoint.percent
            }
        ];

        svg
            .append('path')
            .datum(endLine)
            .attr('fill', 'none')
            .attr('stroke', '#666')
            .attr('stroke-width', 1.25)
            .attr('d', d3.line()
                .x(function (d: any) {
                    return x(new Date(d.date));
                })
                .y(function (d: any) {
                    return y(d.percent);
                })
            );
        }

        this.prbs[this.actualIndex].pop();

        // Add the points
        for (let datum of this.prbs[this.actualIndex]) {
            svg
                .append('circle')
                .datum(datum)
                .attr('fill', '#666')
                .attr('cx', function (d: any) {
                    return x(new Date(d.date));
                })
                .attr('cy', function (d: any) {
                    return y(d.percent);
                })
                .attr('r', 3.5);
        }
        // Text
        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 4)
            .attr('text-anchor', 'middle')
            .text('100%')
            .attr('font-size', 8);

        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 26)
            .attr('text-anchor', 'middle')
            .text('75%')
            .attr('font-size', 8);

        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 48)
            .attr('text-anchor', 'middle')
            .text('50%')
            .attr('font-size', 8);

        svg
            .append('text')
            .attr('x', -11)
            .attr('y', 73)
            .attr('text-anchor', 'middle')
            .text('25%')
            .attr('font-size', 8);


        ///// MOUSE ////////////

        // This allows to find the closest X index of the mouse:
        let bisect = d3.bisector(function (d: any) {
            return d.date;
        }).left;
        // Create the circle that travels along the curve of chart
        let focus = svg
            .append('g')
            .append('circle')
            .style('fill', 'none')
            .attr('stroke', '#bbb')
            .attr('stroke-width', 3)
            .attr('r', 4.5)
            .style('opacity', 0);

        // Create the text that travels along the curve of chart
        let focusText = svg
            .append('g')
            .append('text')
            .attr('class', 'focusText')
            .style('opacity', 0)
            .attr('text-anchor', 'left')
            .attr('alignment-baseline', 'middle');

        // Create a rect on top of the svg area: this rectangle recovers mouse position
        svg
            .append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseout', mouseout);

        // What happens when the mouse move -> show the annotations at the right positions.
        function mouseover() {
            focus.style('opacity', 1);
            focusText.style('opacity', 1);
            tooltip.style('visibility', 'visible');
        }

        let data = this.prbs[this.actualIndex];

        let tooltip = d3.select('body')
            .append('div')
            .style('position', 'absolute')
            .style('z-index', '100000000')
            .style('visibility', 'hidden')
            .style('background', '#ddd')
            .style('padding', '5px');

        let currentLang = this.translate.currentLang;

        function mousemove() {
            // recover coordinate we need
            let x0 = x.invert(d3.mouse(this)[0]).toISOString().split('T')[0];
            let i = bisect(data, x0, 0);
            let selectedData: any = data[i];
            let date = new Date(selectedData.date);
            let formatedDate = selectedData.date.split('-');
            if (currentLang === 'fr') {
                formatedDate = formatedDate[2] + '/' + formatedDate[1] + '/' + formatedDate[0];
            } else {
                formatedDate = formatedDate[1] + '/' + formatedDate[0] + '/' + formatedDate[0];
            }

            focus
                .attr('color', '#bbb')
                .attr('class', 'graphPopUp')
                .attr('cx', x(date))
                .attr('cy', y(selectedData.percent));
            tooltip
                .style('top', (d3.event.pageY - 10) + 'px')
                .style('left', (d3.event.pageX - 125) + 'px')
                .style('height', '48px')
                .html(
                    '<p><b>Date</b>&nbsp;: ' + formatedDate + '<br>' +
                    '<b>Individus</b>&nbsp;:' + data[i].amount + '</p>'
                );

            /**
             focus
             .attr('color', '#bbb')
             .attr('class', 'graphPopUp')
             .attr('cx', x(date))
             .attr('cy', y(selectedData.percent));
             d3.select('.focusText')
             .html(
             date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() +

             )
             .attr('x', x(date) + 15)
             .attr('y', y(selectedData.percent));*/

        }

        function mouseout() {
            focus.style('opacity', 0);
            focusText.style('opacity', 0);
            tooltip.style('visibility', 'hidden');
        }
    }

    setData(records) {
        if (records.length === 0) {
            return;
        }
        const extremalDates = this.getExtremalDates(records);
        let data = [];
        // for every month between min and max date initialize object with 0
        for (let i = extremalDates.minDate; i <= extremalDates.maxDate; i.setMonth(i.getMonth() + 1)) {
            i.setDate(1);
            data.push(i.toISOString().split('T')[0]);
        }

        let dataStructure = [];
        for (let i = 0; i < records.length; i++) {
            let record = records[i];
            let prbIndexes = [];
            for (let j = 0; j < record.prb.length; j++) {
                // Get prb dates rounded at beginning of month
                let prbDate: any = new Date(record.prb[j].date);
                let month = prbDate.getMonth() + 1;
                if (month < 10) {
                    month = '0' + month;
                }
                prbDate = prbDate.getFullYear() + '-' + month + '-01';
                prbIndexes.push(data.indexOf(prbDate));
            }
            if (prbIndexes.length > 0) {
                let currentPrbIndex = 0;
                let nextIndex = (1 < prbIndexes.length) ? [prbIndexes[1], 1] : [data.length - 1, 1];
                while (nextIndex[0] <= (data.length - 1)) {
                    for (let k = prbIndexes[currentPrbIndex]; k < nextIndex[0]; k++) {
                        if (dataStructure[k] === undefined) {
                            dataStructure[k] = {
                                date: null,
                                amount: 0,
                                bruteScore: 0,
                                percent: 0
                            };
                        }
                        dataStructure[k] = {
                            date: data[k],
                            amount: (dataStructure[k]) ? dataStructure[k].amount + 1 : 1,
                            bruteScore: (dataStructure[k]) ? dataStructure[k].bruteScore + this.teamBruteScore(record.prb[currentPrbIndex].jobSatisfactionScore) : this.teamBruteScore(record.prb.jobSatisfactionScore),
                            percent: 0,
                        };
                        dataStructure[k].percent = 4 * (dataStructure[k].bruteScore / (dataStructure[k].amount * 5));

                    }
                    if (nextIndex[0] === (data.length - 1)) {
                        break;
                    } else {
                        currentPrbIndex++;
                        nextIndex = (nextIndex[1] < prbIndexes.length - 1) ? [prbIndexes[nextIndex[1] + 1], nextIndex[1] + 1] : [data.length - 1, nextIndex[1] + 1];
                    }
                }
            }
        }
        /**
        let8 lastDate = new Date(dataStructure[dataStructure.length - 1].date);
        // get first day of next month & + 2 because month starts at 0
        let nextDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 2, 1);
        dataStructure[dataStructure.length] = {
            date: nextDate.toISOString().split('T')[0],
            amount: null,
            bruteScore: null,
            percent: dataStructure[dataStructure.length - 1].percent
        };*/
        let toAdd = 12 - (dataStructure.length % 12);
        // Set values for empty objects
        let tmpStructure = [];
        if (toAdd !== 12) {
            let lastDateArray = dataStructure[0].date.split('-');
            let month = Number(lastDateArray[1]);
            let year = Number(lastDateArray[0]);
            for (let add = 0; add < toAdd; add++) {
                month = (month === 1) ? 12 : month - 1;
                year = (month === 12) ? year - 1 : year;
                tmpStructure.unshift({
                    date: year + '-' + ((month < 10) ? '0' + month : month) + '-' + '01' ,
                    amount: 0,
                    bruteScore: 0,
                    percent: 0
                });
            }
        }
        dataStructure = tmpStructure.concat(dataStructure);
        // split array dataStructure in an array of arrays of length 12
        let dataStructureSplitted = [];
        for (let i = 0; i < dataStructure.length; i += 12) {
            dataStructureSplitted.push(dataStructure.slice(i, i + 12));
        }
        // update empty objects
        /**
        // make sure that every array has length 12
        for (let i = 0; i < dataStructureSplitted.length; i++) {
            if (dataStructureSplitted[i].length < 12) {
                let augmentedDataStructureI = [];
                let length = 12 - augmentedDataStructureI.length;
                for (let j = 0; j < length; j++) {
                    augmentedDataStructureI.push({
                        date: null,
                        amount: null,
                        bruteScore: null,
                        percent: null
                    });
                }
                dataStructureSplitted[i] = augmentedDataStructureI.concat(dataStructureSplitted[i]);
            }
        }
        */

        /// console.log(dataStructureSplitted);

        return dataStructureSplitted;
    }

    teamBruteScore(score) {
        switch (score) {
            case 2:
                return 5;
            case 1:
                return 3;
            case 0:
                return 0;
            default:
                return 0;
        }
    }

    getExtremalDates(records) {
        let minDate = new Date();
        let maxDate = new Date(); // Maxdate is today
        for (let i = 0; i < records.length; i++) {
            if (records[i].prb) {
                for (let j = 0; j < records.length; j++) {
                    if (records[i].prb[j] && records[i].prb[j].date) {
                        let date = new Date(records[i].prb[j].date);
                        if (date < minDate) {
                            minDate = new Date(records[i].prb[j].date);
                        }
                    }
                }
            }
        }
        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 1);
        if (maxDate.getMonth() === 11) {
            maxDate = new Date(maxDate.getFullYear() + 1, 0, 1);
        }
        return {
            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1),
            maxDate: maxDate
        };
    }


}
