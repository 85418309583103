import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from '../../../../../app/services/window-ref.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {EnvService} from '../../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-idw-matrices-pdf',
    templateUrl: './mobile-idw-matrices-pdf.component.html',
    styleUrls: ['./mobile-idw-matrices-pdf.component.scss']
})
export class MobileIdwMatricesPdfComponent implements OnInit, OnDestroy {

    @Output() close = new EventEmitter();

    pdfStructure: any;
    pdfEmail: any;
    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    mailto: string;
    serverText: string;

    parts: any = {
        traits: true,
        prb: true
    };

    optionsCheckboxes = {
        traits: true,
        prb: true
    };

    selectMethod = false;

    method = 'pdf';
    share = 'none';

    private subscriptions = new Subscription();

    constructor(
        private apiIdwMatrixService: ApiIdwMatricesService,
        private changeDetectorRef: ChangeDetectorRef,
        private windowRef: WindowRef,
        public idwMatrix: IdwMatrixAnalysisService,
        public translate: TranslateService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.initPdfStructure();
    }

    onChange(event) {
        if (event && event.source && event.source.id) {
            this.optionsCheckboxes[event.source.id] = event.checked;
            let totalChecked = 0;
            for (let opt in this.optionsCheckboxes) {
                if (opt && this.optionsCheckboxes.hasOwnProperty(opt) && this.optionsCheckboxes[opt]) {
                    totalChecked++;
                }
            }
            if (totalChecked < 1) {
                this.parts[event.source.id] = true;
                let currentCheckbox = document.getElementById(event.source.id);
                if (currentCheckbox) {
                    setTimeout(() => {
                        currentCheckbox.classList.add('mat-checkbox-checked');
                    });
                }
            }
        }
    }

    initPdfStructure() {
        // PDF structure
        this.parts = {
            traits: true,
            prb: true
        };
        this.optionsCheckboxes = {
            traits: true,
            prb: true
        };
        this.pdfStructure = Object.assign({}, {
            designDePoste: 0,
            graphiques: 0
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: (this.translate.currentLang === 'fr') ? 'Rapport Matrice DIT' : 'IDW Matrix Report',
            message: '',
        });

        // PDF link
        this.pdfToken = null;
        this.pdfReportUrl = this.environment.pdfReportUrl;
    }

    getReportData(data: any) {

        let obs = this.apiIdwMatrixService.reportDitgram([this.idwMatrix.id], [
            [
                3,
                (this.parts['traits']) ? 'traits' : null,
                (this.parts['prb']) ? 'prb' : null
            ],
            this.translate.currentLang
        ]);


        this.subscriptions.add(obs.subscribe(
            res => {
                this.pdfToken = encodeURI(res.pdfToken);

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + this.pdfToken;
                if (this.translate.currentLang === 'fr') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=Rapport pour la Matrice d\'équipe ' + this.idwMatrix.name + '&body=Vous pouvez voir le rapport pour' +
                            ' la Matrice d\'équipe ' + this.idwMatrix.name + ' en cliquant sur le lien suivant :%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `Vous pouvez voir le rapport pour la Matrice d'équipe ` +
                            this.idwMatrix.name +
                            ` en cliquant sur le lien suivant :

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                } else if (this.translate.currentLang === 'en') {
                    if ('mailto' === data.type) {
                        this.mailto = '?subject=' + this.idwMatrix.name + ' Team Matrix Report&body=You can see the report for' +
                            ' the Team Matrix ' + this.idwMatrix.name + ' by clicking here:%0A%0A ' + this.pdfReportUrl;
                    } else if ('share' === data.type) {
                        this.serverText = `VYou can see the report for the Team Matrix ` +
                            this.idwMatrix.name +
                            ` by clicking here:

                        ` + this.pdfReportUrl + encodeURI(this.pdfToken);
                    }
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.onComplete();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = (this.translate.currentLang === 'fr') ? 'Rapport Matrice DIT' : 'IDW Matrix Report';
        }
        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.idwMatrix.name,
            'idwMatrices',
            this.translate.currentLang

        ];

        this.subscriptions.add(this.apiIdwMatrixService.reportEmail(dataEmail).subscribe());

        this.onComplete();
    }

    handleReport() {
        switch (this.method) {
            case 'pdf':
                this.downloadReport();
                return;
            case 'cutpaste':
                this.share = 'cutpaste';
                this.getReportData({type: 'token'});
                return;
            case 'mailto':
                this.share = 'mailto';
                this.getReportData({type: 'mailto'});
                return;
            case 'server':
                this.share = 'server';
                this.getReportData({type: 'share'});
                return;
        }
        this.selectMethod = true;
        return;

    }

    changeSelectMethodState() {
        if (this.method !== 'none') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
    }

    onComplete() {
        this.cancel();
        this.initPdfStructure();
        this.close.emit();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
