import {Injectable} from '@angular/core';
import {ApiIdwMatricesService} from './api-idw-matrices.service';
import { CanonicalizeHelper } from 'src/app/shared/helpers/canonicalize.helper';

@Injectable()
export class MainListIdwMatricesService {

    constructor(private apiIdwMatricesService: ApiIdwMatricesService) {}

    getList(params, recordsLoaded, total, append = false, archive = false) {
        let step = 100;
        let amountLoaded = (recordsLoaded + step > total && append) ? total - recordsLoaded : step;
        recordsLoaded = append ? recordsLoaded : 0;

        let request: any = {
            limit: amountLoaded,
            skip: recordsLoaded
        };
        let parameters = [
            'search',
            'idwMatrixStartDate',
            'idwMatrixEndDate',
            'subAccount',
            'department'
        ];

        let arrayParameters = ['subAccount'];

        for (let parameter of parameters) {
            if (params.listSearchFilters[parameter]) {
                request[parameter] = params.listSearchFilters[parameter];
            }
        }

        for (let parameter of arrayParameters) { // subAccount handling
            if (request[parameter] && request[parameter].length > 0) {
                request[parameter] = request[parameter].toString();
            } else {
                delete request[parameter];
            }
        }

        if (request.subAccount) {
            request.subAccount = request.subAccount.split(',');
        }

        if (archive) {
            request['isArchived'] = 1;
        } else {
            request['isArchived'] = 0;
        }

        // Build order parameter
        for (let index in params.listColumns) {
            if (params.listColumns.hasOwnProperty(index)) {
                if (params.listColumns[index] && params.listColumns[index]['order']) {
                    let orderObject = {};
                    let colKey: string;
                    switch (params.listColumns[index]['key']) {
                        case 'subAccountName':
                            colKey = 'subAccount';
                            break;
                        case 'departmentName':
                            colKey = 'department';
                            break;
                        default:
                            colKey = params.listColumns[index]['key'];
                    }
                    orderObject[colKey] = params.listColumns[index]['order'];
                    request['order'] = orderObject;
                }
            }
        }
        if (typeof request.department === 'string') {
            request.department = request.department.split(',');
        }

        request = CanonicalizeHelper.addSuffix('idwMatrices', request);

        return {
            listObservable: this.apiIdwMatricesService.ditGrams([request]),
            recordsLoaded: recordsLoaded + amountLoaded
        };
    }
}
