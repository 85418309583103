import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuardService} from '../app/services/auth-guard.service';

import {JobsListComponent} from './components/web/jobs-list/jobs-list.component';
import {MobileJobsListComponent} from './components/mobile/mobile-jobs-list/mobile-jobs-list.component';
import {JobsReportComponent} from './components/web/jobs-report/jobs-report.component';
import {MobileJobsReportComponent} from './components/mobile/mobile-jobs-report/mobile-jobs-report.component';

@NgModule({
    imports: [RouterModule.forChild([
        {
            path: 'jobs',
            redirectTo: '/jobs/list',
            pathMatch: 'full'
        },
        {
            path: 'jobs/list',
            component: JobsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/jobs',
            redirectTo: '/m/jobs/list',
            pathMatch: 'full'
        },
        {
            path: 'm/jobs/list',
            component: MobileJobsListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'jobs/report/:jobToDisplayId/:reportType',
            component: JobsReportComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'm/jobs/report/:jobToDisplayId/:reportType',
            component: MobileJobsReportComponent,
            canActivate: [AuthGuardService]
        }])],
    exports: [RouterModule]
})
export class JobsRoutingModule {
}
