import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolsRoutingModule} from './tools-routing.module';
import {MainToolsComponent} from './components/main-tools/main-tools.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {ProfileSearchComponent} from './components/profile-search/profile-search.component';
import {TypeaheadAccountsComponent} from './components/typeahead-accounts/typeahead-accounts.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ProfileTransfersComponent} from './components/profile-transfers/profile-transfers.component';
import {FormsModule} from '@angular/forms';
import { HttpClientModule } from  '@angular/common/http';
import { MobileMainToolsComponent } from './components/mobile/mobile-main-tools/mobile-main-tools.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [MainToolsComponent, StatisticsComponent, ProfileSearchComponent, TypeaheadAccountsComponent, ProfileTransfersComponent, MobileMainToolsComponent],
    exports: [
        MainToolsComponent
    ],
    imports: [
        CommonModule,
        ToolsRoutingModule,
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
        FormsModule,
        HttpClientModule,
        TranslateModule
    ]
})
export class ToolsModule {
}
