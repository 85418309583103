import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PeopleSurveyRequestSendMethodsHelper} from '../send-methods.helper';

@Component({
    selector: 'app-people-survey-request-send-method-email-client',
    template: `
        <h3><strong>{{ 'commons.sendByMyEmail' | translate }}</strong></h3>
        <p>
            {{ 'commons.sendByYourEmailInstructions_client_1' | translate }}
            <span style="font-size: 1.3em; color: red">{{ 'commons.sendByYourEmailInstructions_client_2' | translate }}</span>&nbsp;
            <a style="font-size: 1.3em; color: red" href="{{ getSendMailToLink() }}" class="bbuttonHover">{{ 'commons.here' | translate }}</a>.
        </p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestSendMethodEmailClientComponent implements OnInit, OnDestroy {
    @Input() instructionsMessages: any;
    @Input() surveyLink: string;
    @Input() username: string;
    @Input() password: string;
    @Input() fullName: string;
    @Input() email: string;
    @Input() formSubmit$: Observable<any>;
    @Output() afterSubmission: EventEmitter<any> = new EventEmitter();

    public onDestroy$: Subject<any> = new Subject();

    constructor() {}

    ngOnInit() {
        this.formSubmit$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.afterSubmission.emit();
            // window.location.href = this.getSendMailToLink();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private getMailtoMessage(): string {
        const surveyLink: string = this.surveyLink.replace(/&/g, '%26');
        return PeopleSurveyRequestSendMethodsHelper.emailClientText(
            this.instructionsMessages, this.fullName, this.username, this.password, surveyLink, this.instructionsMessages.language
        );
    }

    public getSendMailToLink(): string {
        return `mailto:${this.email}?subject=${this.instructionsMessages.emailSubject}&body=${this.getMailtoMessage()}`;
    }
}
