export class User {
    id: string;
    username: string;
    password: string;
    email: string;
    role: Array<string>;
    accountNumber: number;

    constructor(options: {
        id?: string,
        username?: string,
        password?: string,
        email?: string,
        role?: Array<string>,
        accountNumber?: number,
    } = {}) {
        this.id = options.id;
        this.username = options.username;
        this.password = options.password;
        this.email = options.email;
        this.role = options.role;
        this.accountNumber = options.accountNumber;
    }
}

export class UserData {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    personalEmail: string;
    accountNumber: number;
    subAccountAccess: Array<number>;
    principalSubAccount: string;
    status: any;
    jobTitle: string;
    addressInfo: any;
    phone: string;
    fax: string;
    industry: number;
    jobType: number;
    // isAnalyst: any;
    isSatellite: any;
    formation: any;
    // formationType: number;
    // formationDate: Date;
    // trainer: string;
    // otherFormation: string;
    notes: string;
    language: string;
    // analystAgreement: any;
    // formationPlace: string;
    creationDate: any;
    createdBy: any;
    modificationDate: any;
    modifiedBy: any;
    preferences: any;
    permissions: any;
    assets: any;

    constructor(options: {
        id?: string,
        username?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        personalEmail?: string,
        accountNumber?: number,
        subAccountAccess?: Array<number>,
        principalSubAccount?: string;
        status?: any,
        jobTitle?: string,
        addressInfo?: any,
        phone?: string,
        fax?: string,
        industry?: number,
        jobType?: number,
        isAnalyst?: any,
        isSatellite?: any,
        formation?: any,
        // formationType?: number,
        // formationDate?: Date,
        // trainer?: string,
        // otherFormation?: string,
        notes?: string,
        language?: string,
        // analystAgreement?: any,
        // formationPlace?: string,
        creationDate?: any,
        createdBy?: any,
        modificationDate?: any,
        modifiedBy?: any,
        preferences?: any,
        permissions?: any,
        assets?: any
    } = {}) {
        this.id = options.id;
        this.username = options.username;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.email = options.email;
        this.personalEmail = options.personalEmail;
        this.accountNumber = options.accountNumber;
        this.subAccountAccess = options.subAccountAccess;
        this.principalSubAccount = options.principalSubAccount;
        this.status = options.status;
        this.jobTitle = options.jobTitle;
        this.addressInfo = options.addressInfo || {};
        this.phone = options.phone;
        this.industry = options.industry;
        this.jobType = options.jobType;
        // this.isAnalyst = options.isAnalyst;
        this.isSatellite = options.isSatellite;
        this.formation = options.formation;
        // this.formationType = options.formationType;
        // this.formationDate = options.formationDate;
        // this.trainer = options.trainer;
        // this.otherFormation = options.otherFormation;
        this.notes = options.notes;
        this.language = options.language;
        // this.analystAgreement = options.analystAgreement;
        // this.formationPlace = options.formationPlace;
        this.creationDate = options.creationDate;
        this.createdBy = options.createdBy || {};
        this.modificationDate = options.modificationDate;
        this.modifiedBy = options.modifiedBy || {};
        this.preferences = options.preferences;
        this.permissions = options.permissions;
        this.assets = options.assets;
    }
}
