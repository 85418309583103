import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {ApiRightMatchesService} from './api-right-matches.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {TalentsDefinitions} from '../../../shared/talents/talentsDefinitions';

@Injectable({
    providedIn: 'root'
})
export class RightMatchAnalysisService extends ContentItem {
    private rightMatch;

    readonly persistentVariables = [
        'rightMatch'
    ];

    constructor(
        private apiRightMatchesService: ApiRightMatchesService,
        private translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('rightMatchService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    setItemToDisplayWithId(id, specificState = 'rightMatch', action = null) {
        if (!id) { return; }
        let obsResult = {};
        let rightMatchObservable = this.apiRightMatchesService.rightMatch([id]).pipe(take(1)).subscribe(
            res => {
                if (res) {
                    this.rightMatch = res;
                    this.storeServiceData('rightMatchService', this.persistentVariables, this);
                    this.stateService[specificState].itemToDisplayId = res.id;
                    if (action === 'forceSidebarToReport' && this.isRightModule(specificState)) {
                        obsResult = this.resetSidebarToReport(specificState);
                    } else if (action === 'resetSidebarToFilters') {
                        this.stateService[specificState].sideBarStatus = 'searchFilters';
                    } else if (action !== 'newAnalysis') {
                        this.stateService[specificState].sideBarStatus = 'recentActivities';
                    }
                    obsResult['itemToDisplayId'] = res.id;
                    this.stateService[specificState].stateChanged.next(obsResult);
                    rightMatchObservable.unsubscribe();
                    if (action === 'mobileView') {
                        this.router.navigate(['m/analysis/rightmatch/report/' + res.id]).then(() => {});
                    }
                }
            }
        );
    }

    isRightModule(specificState) {
        if (specificState === 'rightMatch' && !this.archive) {
            return true;
        }
        return !!(specificState === 'archivesRightMatch' && this.archive);
    }

    get orderedTalents() {
        let orderedTalents = {
            'en': [],
            'fr': [],
        };
        let talentsName = {
            fr: new TalentsDefinitions().talents.fr,
            en: new TalentsDefinitions().talents.en
        };

        if (this.rightMatch && this.rightMatch.profiler && this.rightMatch.profiler['competences']) {
            let talentsScores = this.rightMatch.profiler['competences'];
            if (talentsScores && talentsScores[0]) {
                let talentsScoresKeys = Object.keys(talentsScores[0]);
                let talents = {};
                talents['en'] = [];
                talents['fr'] = [];
                for (let scoreKey of talentsScoresKeys) {
                    talents['en'].push([
                        this.fuseTalentName(talentsName['en'][scoreKey]),
                        talentsScores[0][scoreKey],
                        Number.parseInt(scoreKey, 10)
                    ]);
                    talents['fr'].push([
                        this.fuseTalentName(talentsName['fr'][scoreKey]),
                        talentsScores[0][scoreKey],
                        Number.parseInt(scoreKey, 10)
                    ]);
                }

                orderedTalents['en'] = (orderedTalents && orderedTalents['en']) ? talents['en'].sort(this.orderTalents) : null;
                orderedTalents['fr'] = (orderedTalents && orderedTalents['fr']) ? talents['fr'].sort(this.orderTalents) : null;
            }
        }
        return orderedTalents;
    }

    orderTalents(a, b) {
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) < 0) {
            return -1;
        }
        if (Intl.Collator('fr', {sensitivity: 'base', ignorePunctuation: true}).compare(a[0], b[0]) > 0) {
            return 1;
        }
        return 0;
    }


    fuseTalentName(talent) {
        if (talent && talent.length > 1) {
            return talent.join(' ');
        }
        return talent[0];
    }

    resetItemToDisplay(specificState = 'rightMatch') {
        this.itemToDisplay = {};
        this.stateService[specificState].stateChanged.next({
            'itemDeleted': true
        });
        this.stateService[specificState].report.itemToDisplayId = undefined;
        if (this.isMobile) {
            this.router.navigate(['m/analysis/rightmatch/list']).then(() => {
            });
        } else {
            this.stateService.rightMatch.sideBarStatus = 'recentActivities';
            this.stateService.archivesRightMatch.sideBarStatus = 'recentActivities';
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get itemToDisplay(): any {
        return _.get(this, 'rightMatch');
    }

    set itemToDisplay(item) {
        this.rightMatch = item;
        this.storeServiceData('rightMatchService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'rightMatch.id');
    }

    get name(): string {
        return _.get(this, 'rightMatch.name');
    }

    get consultant(): any {
        return _.get(this, 'rightMatch.consultant');
    }

    get subAccount(): any {
        return _.get(this, 'rightMatch.subAccount');
    }

    get profilerInfo(): any {
        return _.get(this, 'rightMatch.profiler');
    }

    get profilerId(): string {
        return _.get(this.profilerInfo, 'id');
    }

    get jobDescription() {
        return _.get(this.profilerInfo, 'jobDescription');
    }

    get jobDescriptionTasks() {
        return _.get(this.jobDescription, 'tasks');
    }

    get jobTitle(): string {
        return _.get(this.profilerInfo, 'jobTitle');
    }

    get date(): string {
        return _.get(this, 'rightMatch.date');
    }

    get creationDate(): string {
        return _.get(this, 'rightMatch.creationDate');
    }

    set records(records) {
        this.rightMatch.records = records;
        this.storeServiceData('rightMatchService', this.persistentVariables, this);
    }

    get competencies() {
        return _.get(this.profilerInfo, 'competences');
    }

    get records(): any {
        return _.get(this, 'rightMatch.records');
    }

    get department(): any {
        let department = _.get(this, 'rightMatch.department');
        if (department !== undefined && department.length > 0) {
            let currentLang = this.translateService.currentLang;
            if (currentLang === '' || currentLang === undefined) {
                currentLang = 'fr';
            }
            department = department[0][currentLang];
        }
        return department;
    }

    get archive() {
        return _.get(this, 'rightMatch.archive');
    }
}
