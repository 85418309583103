// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  background-color: #EEE;
}

#candidateList {
  height: 300px;
  background-color: white;
  overflow-y: scroll;
  border-top: 1px solid #BBB;
  border-bottom: 1px solid #BBB;
}

.text-danger {
  color: indianred;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/jobSatisfactions/components/web/job-satisfactions-modal/job-satisfactions-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,0BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".modal-body {\n    background-color: #EEE;\n}\n\n#candidateList {\n    height: 300px;\n    background-color: white;\n    overflow-y: scroll;\n    border-top: 1px solid #BBB;\n    border-bottom: 1px solid #BBB;\n}\n\n.text-danger {\n    color: indianred;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
