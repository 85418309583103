import {Component, EventEmitter, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ChangeDetectionStrategy} from '@angular/core';
import {Subscription} from 'rxjs';

import {StateService} from '../../../../../core/services/state/state.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {Tools} from '../../../../../shared/misc/tools';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {JobSatisfaction} from '../../../models/job-satisfaction.model';
import {ApiPeopleService} from '../../../../../people/services/api-people.service';

@Component({
    styleUrls: ['./job-satisfactions-modal.component.scss'],
    selector: 'app-job-satisfactions-modal',
    templateUrl: './job-satisfactions-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobSatisfactionsModalComponent implements OnInit, OnDestroy {
    @Input() status;
    @Input() multiAction;

    @ViewChild('jobSatisfactionNgForm') jobSatisfactionNgForm;
    public close: EventEmitter<any> = new EventEmitter();
    public multiActionDestroy: EventEmitter<any> = new EventEmitter();

    jobSatisfaction: JobSatisfaction;
    jobSatisfactionForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    headerText: string;

    submitted = false;


    warning = false;
    errorFields = [];

    private subscriptions = new Subscription();

    // Selecting people part
    public loading = false;
    searchTerm: string;
    actual = '';
    recordsLoaded = 100;
    recordsLength: number;
    records = [];
    selectedItems: Array<string> = [];

    constructor(
        public jobSatisfactionAnalysis: JobSatisfactionsAnalysisService,
        public bsModalRef: BsModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private router: Router,
        private stateService: StateService,
        private translate: TranslateService,
        private apiJobSatisfactionService: ApiJobSatisfactionsService,
        private apiPeopleService: ApiPeopleService
    ) {
    }

    ngOnInit() {
        this.headerText = (this.translate.currentLang === 'fr') ? 'Sélectionner des individus' : 'Select peope';
        this.requestedBy = this.sessionData.userData.username;
        this.sessionStructure = this.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.jobSatisfaction = Object.assign({}, new JobSatisfaction());
            this.jobSatisfaction.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.jobSatisfaction = Object.assign({}, this.jobSatisfactionAnalysis.itemToDisplay);
        }

        if (this.jobSatisfaction && this.jobSatisfaction.records) {
            for (let record of this.jobSatisfaction.records) {
                this.selectedItems.push(record.id);
            }
        }

        if (this.multiAction && this.multiAction.ids && this.multiAction.ids.length) {
            this.headerText = (this.multiAction.ids.length === 1) ?
                'analysisCommons.addCandidateToAnalysis' :
                'analysisCommons.addCandidatesToAnalysis';
        }
        this.fetchRecords();

        this.initForm();
    }

    closeModal(rmId = this.jobSatisfaction.id) {
        if (this.multiAction === true) {
            this.router.navigate(['/people/list']).then(() => {
            });
            return;
        }
        this.resetForm();
        this.close.emit({closed: true, id: rmId});
        this.bsModalRef.hide();
    }

    formCheck() {
        this.warning = false;
        this.errorFields = [];
        if (this.jobSatisfactionNgForm && this.submitted) {
            for (let field of ['name', 'subAccount']) {
                if (this.jobSatisfactionForm.controls[field].status === 'INVALID') {
                    this.warning = true;
                    this.errorFields.push(field);
                    this.changeDetectorRef.markForCheck();
                }
            }
            return (this.errorFields.length === 0);
        }
        return false;
    }

    initForm() {
        this.submitted = false;

        const departmentId = (this.jobSatisfaction.department && this.jobSatisfaction.department.length > 0)
            ? this.jobSatisfaction.department[0].id
            : null;

        let subAccount = (this.sessionData.structure.principalSubAccount) ?
            this.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.jobSatisfaction.subAccount;
        }

        this.jobSatisfactionForm = this.fb.group({
            name: [this.jobSatisfaction.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.jobSatisfaction.consultant],
            department: [departmentId]
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (model.valid) {
            // console.log(model.value);
            let data = model.value;
            data.records = this.selectedItems;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        } else {
            this.formCheck();
            this.changeDetectorRef.markForCheck();
        }
    }

    saveNew(data: JobSatisfaction) {
        // call api to save the modification
        this.subscriptions.add(this.apiJobSatisfactionService.postJobSatisfaction(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'records': this.multiAction.ids};
                    this.subscriptions.add(this.apiJobSatisfactionService.putJobSatisfaction([res.id], data).subscribe(
                        () => {
                            this.multiActionDestroy.emit({destroy: true});
                            this.closeModal(res.id);
                        }
                    ));
                } else {
                    this.closeModal(res.id);
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: JobSatisfaction) {
        // call api to save the modification
        this.subscriptions.add(this.apiJobSatisfactionService.putJobSatisfaction([this.jobSatisfaction.id], data).subscribe(
            () => {
                this.closeModal(this.jobSatisfaction.id);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.jobSatisfactionForm.reset();
            this.jobSatisfactionNgForm.submitted = false;
            this.initForm();
        });
    }

    assignRmId(val) {
        this.jobSatisfactionAnalysis.setItemToDisplayWithId(val);
    }

    onSearch(event, searchTerm, which) {
        if (which === 'return' && event.keyCode === 13) {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        } else if (which === 'click') {
            this.fetchRecords(searchTerm);
            this.actual = searchTerm;
        }
    }

    clearSearch() {
        this.actual = '';
        this.searchTerm = '';
        this.fetchRecords(null);
    }

    fetchRecords(searchTerm = null, skip = null, concat = false) {
        this.loading = true;
        this.subscriptions.add(this.apiPeopleService.records(
            [{
                'search': searchTerm,
                'skip': skip,
                'isArchived': 0,
                'reportStatuses[mpo]': 1,
                'limit': this.recordsLoaded
            }]
        ).subscribe(
            (res) => {
                // Get records loaded (add the new records from API)
                this.recordsLoaded = (skip) ?
                    this.recordsLoaded + res.data.length :
                    this.recordsLoaded;

                // Records data (merge new records from API)
                this.records = (concat) ?
                    [...this.records, ...res.data] :
                    res.data;

                // Total records
                this.recordsLength = res.count;

                // Reload component
                this.changeDetectorRef.markForCheck();

                this.loading = false;
            }
        ));
    }

    onChecked(id: string, event) {
        if (event.checked) {
            this.selectedItems.push(id);
        } else {
            this.selectedItems.splice(
                this.selectedItems.indexOf(id),
                1
            );
        }
    }

    onScroll() {
        if (this.recordsLoaded >= this.recordsLength) {

            return;
        }
        this.fetchRecords(this.searchTerm, this.recordsLoaded, true);
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    formatDateWithLang(date, lang) {
        if (date) {
            if (lang === 'fr') {
                return date.split('-').reverse().join('/');
            } else {
                return date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0];
            }
        }
        return;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}

