import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '../../../../core/services/state/state.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-jobs-pdf-modal',
    templateUrl: './jobs-pdf-modal.component.html',
    styleUrls: ['./jobs-pdf-modal.component.scss']
})

export class JobsPdfModalComponent implements OnInit, OnDestroy {

    @Input() config;
    @Input() jobToDisplay;
    public close: EventEmitter<any> = new EventEmitter();

    sessionData: any;

    pdfStructure: any;

    pdfEmail: any;
    pdfToken: string;
    pdfReportUrl: string;
    nativeWindow: any;

    mailto: string;
    serverText: string;

    selectMethod = false;

    method = 'pdf';
    share = 'none';

    private subscriptions = new Subscription();

    constructor(
        private apiJobsService: ApiJobsService,
        private changeDetectorRef: ChangeDetectorRef,
        public stateService: StateService,
        private windowRef: WindowRef,
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        public state: StateService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
    }

    ngOnInit() {
        this.sessionData = this.stateService.session.sessionData;
        this.initPdfStructure();
    }

    get raPermission() {
        return (this.stateService.session &&
            this.sessionData &&
            this.sessionData.userData &&
            this.sessionData.userData.permissions) ? this.sessionData.userData.permissions.ra : false;
    }

    hasJobDesign() {
        // noinspection OverlyComplexBooleanExpressionJS
        if (
            this.jobToDisplay &&
            this.jobToDisplay.jobDescription &&
            (
                this.jobToDisplay.jobDescription.purposeOfThePosition ||
                (this.jobToDisplay.jobDescription.tasks && this.jobToDisplay.jobDescription.tasks.length !== 0)
            )
        ) {
            return 1;
        }
        return 0;
    }

    hasProfile() {
        if (
            this.jobToDisplay &&
            this.jobToDisplay.score &&
            this.jobToDisplay.score.length > 0
        ) {
            return 1;
        }
        return 0;
    }

    hasCompetencies() {
        if (
            this.jobToDisplay &&
            this.jobToDisplay.competences &&
            this.jobToDisplay.competences[0] &&
            Object.keys(this.jobToDisplay.competences[0]).length > 0
        ) {
            return true;
        }
        return false;
    }

    hasRa() {
        if (
            this.jobToDisplay &&
            this.jobToDisplay.RA
        ) {
            return 1;
        }
        return 0;
    }

    initPdfStructure() {
        // PDF structure
        this.pdfStructure = Object.assign({}, {
            jobDesign: this.hasJobDesign(),
            profile: this.hasProfile(),
            graph: this.hasProfile(),
            competences: this.hasCompetencies(),
            intQuestions: this.hasCompetencies(),
            ra: this.hasRa()
        });

        // PDF email
        this.pdfEmail = Object.assign({}, {
            emailTo: '',
            subject: this.translate.instant('jobs.pdfModal.jobReport'),
            message: '',
        });

        // PDF link
        this.pdfToken = null;
        this.pdfReportUrl = this.environment.pdfReportUrl;
        this.changeDetectorRef.markForCheck();
    }

    sanitizePdfStructure() {
        for (let item of ['jobDesign', 'profile', 'graph', 'competences', 'intQuestions', 'ra']) {
            if (this.pdfStructure.hasOwnProperty(item)) {
                this.pdfStructure[item] = (this.pdfStructure[item] === true) ? 1 : this.pdfStructure[item];
            }
        }
    }

    getReportData(data: any) {
        this.sanitizePdfStructure();
        let sectionRequested = [];

        if (this.pdfStructure.jobDesign === 1) {
            sectionRequested.push('jobDesign');
        }
        if (this.pdfStructure.profile === 1) {
            sectionRequested.push('profile');
        }
        if (this.pdfStructure.graph === 1) {
            sectionRequested.push('graph');
        }
        if (this.pdfStructure.competences === 1) {
            sectionRequested.push('competences');
        }
        if (this.pdfStructure.intQuestions === 1) {
            sectionRequested.push('intQuestions');
        }
        if (this.pdfStructure.ra === 1) {
            sectionRequested.push('ra');
        }

        let obs = this.apiJobsService.reportJob([this.jobToDisplay.id], [
            sectionRequested,
            0,
            this.translate.currentLang
        ]);

        this.subscriptions.add(obs.subscribe(
            res => {
                this.pdfToken = encodeURI(res.pdfToken);

                this.pdfReportUrl = this.environment.pdfReportUrl + '?tokenDefinition=' + this.pdfToken;
                let emailSubject = this.translate.instant('jobs.pdfModal.jobReportFor', {
                    jobTitle: this.jobToDisplay.jobTitle
                });
                let linkInstruction = this.translate.instant('jobs.pdfModal.linkInstruction', {
                    jobTitle: this.jobToDisplay.jobTitle
                });

                if ('mailto' === data.type) {
                    this.mailto = '?subject=' + emailSubject + '&body=' + linkInstruction + '%0A%0A'
                        + this.pdfReportUrl;
                } else if ('share' === data.type) {
                    this.serverText = linkInstruction +
                        `

                        ` + this.pdfReportUrl + this.pdfToken;
                }

                if (data.window && 'download' === data.type) {
                    data.window.document.location.href = this.pdfReportUrl;
                    this.closeModal();
                    return;
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    downloadReport() {
        // Open window to avoid popup blocking
        let pdfWindow = this.nativeWindow.open('', '_blank');
        this.getReportData({type: 'download', window: pdfWindow});
    }

    sendEmailReport() {
        if (!this.pdfEmail || this.pdfEmail.subject === '') {
            this.pdfEmail.subject = this.translate.instant('jobs.pdfModal.jobReport');
        }
        const dataEmail = [
            this.pdfEmail.emailTo,
            this.pdfEmail.subject,
            this.pdfEmail.message,
            this.pdfReportUrl,
            this.jobToDisplay.jobTitle,
            'jobs',
            this.translate.currentLang
        ];

        this.subscriptions.add(this.apiJobsService.reportEmail(dataEmail).subscribe());

        this.closeModal();
    }

    handleReport() {

        switch (this.method) {
            case 'pdf':
                this.downloadReport();
                return;
            case 'cutpaste':
                this.share = 'cutpaste';
                this.getReportData({type: 'token'});
                return;
            case 'mailto':
                this.share = 'mailto';
                this.getReportData({type: 'mailto'});
                return;
            case 'server':
                this.share = 'server';
                this.getReportData({type: 'share'});
                return;
        }
        this.selectMethod = true;
        return;

    }

    changeSelectMethodState() {
        if (this.method !== 'none') {
            this.selectMethod = false;
        }
    }

    cancel() {
        this.share = 'none';
        this.method = 'pdf';
    }

    closeModal(jobId = this.jobToDisplay.id) {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.initPdfStructure();
            this.method = 'pdf';
            this.share = 'none';
            this.selectMethod = false;
        });
        this.close.emit({closed: true, id: jobId});
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }

}
