import {Injectable} from '@angular/core';
import {ApiPeopleService} from '../../../people/services/api-people.service';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class JobSatisfactionPcrReportService {

    id;
    personalityScores;
    prbScores;
    prbReport;
    info;
    jobSatisfactionScore;
    jobSatisfactionText;

    reportChanged = new BehaviorSubject(null);
    reportChanged$ = this.reportChanged.asObservable();

    constructor(
        protected apiPeople: ApiPeopleService,
        protected translate: TranslateService
    ) {
    }

    set personData(data) {
        this.id = data.id;
        this.personalityScores = data.personalityScores;
        this.prbScores = data.prbScores;
        this.jobSatisfactionScore = data.jobSatisfactionScore;
        this.jobSatisfactionText = this.produceTexts(this.jobSatisfactionScore);


        this.reportChanged.next(null);
        this.apiPeople.reportEvaluation(
            ['mpo'],
            [
                this.id,
                [4],
                0, // Index of the evaluation object is (so far) always 0
                0,
                this.translate.currentLang,
                0
            ]
        ).pipe(take(1)).subscribe(
            (res) => {
                this.info = res.info;

                this.prbReport = res.prb;
                this.reportChanged.next({
                    info: this.info,
                    prbReport: this.prbReport,
                    personalityScores: this.personalityScores,
                    prbScores: this.prbScores,
                    jobSatisfactionScore: this.jobSatisfactionScore,
                    jobSatisfactionText: this.jobSatisfactionText
                });
            }
        );
    }

    produceTexts(jobSatisfactionScore) {
        if (this.translate.currentLang === 'fr') {
            switch (jobSatisfactionScore) {
                case 2:
                    return 'Cet individu a un potentiel de bien-être élevé. Le comportement qu’il perçoit devoir adopter dans' +
                        ' son environnement de travail correspond bien à ses besoins motivationnels. Ce résultat devrait se ' +
                        'traduire par un niveau de bien-être tout à fait adéquat pour poursuivre facilement ses activités ' +
                        'professionnelles avec un minimum d’ajustements ou d’irritant.';
                case 1:
                    return 'Cet individu a un potentiel de bien-être moyen. Le comportement qu’il perçoit devoir adopter ' +
                        'dans son environnement de travail peut correspondre assez bien à ses besoins motivationnels avec ' +
                        'certains nombres d’ajustements ou d’irritants. Ce résultat devrait se traduire par un niveau de ' +
                        'bien-être variable ou parfois incertain. Une rencontre pour discuter des différents enjeux ressentis ' +
                        'par cet individu peut vous permettre d’apporter les changements nécessaires afin de maximiser son ' +
                        'bien-être dans votre organisation.';
                case 0:
                    return 'Cet individu a un potentiel de bien-être faible. Le comportement qu’il perçoit devoir adopter ' +
                        'dans son environnement de travail ne correspond pas du tout à ses besoins motivationnels. Cet ' +
                        'individu vit très probablement de grands irritants provoquant de l’insatisfaction. Une attention ' +
                        'particulière est fortement recommandée afin de discuter des changements nécessaires à apporter afin ' +
                        'que cet individu puisse progressivement augmenter son niveau de bien-être dans votre organisation.';
                default:
                    return 'Cet individu a un potentiel de bien-être non défini. Pour connaître son niveau de bien-être ' +
                        'potentiel, vous devez lui faire compléter un questionnaire de perception MPO en cliquant sur le ' +
                        'lien « Envoyer un nouveau questionnaire de perception » ci-bas.';
            }
        } else if (this.translate.currentLang === 'en') {
            switch (jobSatisfactionScore) {
                case 2:
                    return 'Indicates an individual with high wellbeing potential. This person’s perception of the work-related ' +
                        'behavior requirements seems to be a very good fit with them, motivationally speaking. This score gives a ' +
                        'sound workplace wellbeing level which suggests the person is comfortable with getting the role and tasks ' +
                        'done with little need for any behavioral adjustments and very few irritants.';
                case 1:
                    return 'Indicates an individual with average wellbeing potential. This person’s perception of ' +
                        'work-related behavior requirements could be a rather good fit with them, motivationally speaking, ' +
                        'but with some minor irritants or behavioral adjustments needed on their part. The score suggests ' +
                        'fluctuations in workplace wellbeing levels or perhaps some ambivalence. It could be worth meeting ' +
                        'to talk over what kind of work-related adjustments or accommodations might further optimize their ' +
                        'sense of wellbeing at work. ';
                case 0:
                    return 'Indicates an individual with low wellbeing potential. This person’s perception of work-related ' +
                        'behavior requirements seems to be quite a poor fit with them, motivationally speaking. There are' +
                        ' likely some major irritants causing job dissatisfaction. Some special attention would be highly recommended. ' +
                        'It would be worth talking over what kind of work-related adjustments or accommodations might foster a progressive ' +
                        'increase in their sense of wellbeing at work.';
                default:
                    return 'Wellbeing potential cannot be defined. To get this person’s workplace wellbeing profile, ' +
                        'they will need to fill out the MPO Perception of Required Behavior questionnaire. To do so, ' +
                        'you can click on the Send a new perception questionnaire link below.';
            }
        }
    }
}
