import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PeopleSurveyRequestHelper} from '../components/commonClasses/people-survey-request/people-survey-request.helper';

/**
 * Validator to ensure that a report is selected
 * @param control
 */
export function reportSelectedValidator(control: AbstractControl): ValidationErrors | null {
    if (!control) {
        return null;
    }

    return !PeopleSurveyRequestHelper.isReportSelected(control.value) ? {reportSelected: true} : null;
}
