// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infoTable tr td:first-child {
  padding-right: 15px;
}

.truncate {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topHead {
  height: 70px;
}

.topMenu, .bottomMenu {
  background-color: #eee;
  margin: 0 0 auto 102px;
  min-width: 550px;
}

.topMenu {
  padding-top: 20px;
  border-bottom: 1px solid #ddd;
  padding-left: 20px;
}

.bottomMenu {
  padding-top: 5px;
  border-top: 1px solid #ddd;
}

/**
@media (max-height:  725px ) {
    #informations {
        display: none;
    }
    #informationAltTitle {
        display: block !important;
    }
}



#informationAltTitle {
    display: none;
}

*/
/** affixing **/
.top {
  background-color: rgb(241, 241, 241);
  top: 0;
}

.fluid {
  position: relative;
  top: 125px;
}

.semiFluid {
  position: relative;
  top: 125px;
}

.blockBottom {
  position: fixed;
  padding-top: 20px;
}

.bbLeft {
  left: 102px;
  padding: 35px 20px 0 20px;
}

.bbRight {
  padding-right: 40px;
}

::ng-deep .idwMatrixLoading .backdrop, ::ng-deep .idwMatrixLoading .spinner-three-bounce {
  z-index: 1999 !important;
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/idw-matrices/components/web/idw-matrix-sidebar/idw-matrix-sidebar.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,sBAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,6BAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,0BAAA;AACJ;;AAEA;;;;;;;;;;;;;;;;CAAA;AAkBA,eAAA;AAEA;EACI,oCAAA;EACA,MAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;AADJ;;AAIA;EACI,eAAA;EACA,iBAAA;AADJ;;AAIA;EACI,WAAA;EACA,yBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,wBAAA;EACA,YAAA;AADJ","sourcesContent":["#infoTable tr td:first-child {\n    padding-right: 15px;\n}\n\n.truncate {\n    width: 80%;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.topHead {\n    height: 70px;\n}\n\n.topMenu, .bottomMenu {\n    background-color: #eee;\n    margin: 0 0 auto 102px;\n    min-width: 550px;\n}\n\n.topMenu {\n    padding-top: 20px;\n    border-bottom: 1px solid #ddd;\n    padding-left: 20px;\n}\n\n.bottomMenu {\n    padding-top: 5px;\n    border-top: 1px solid #ddd;\n}\n\n/**\n@media (max-height:  725px ) {\n    #informations {\n        display: none;\n    }\n    #informationAltTitle {\n        display: block !important;\n    }\n}\n\n\n\n#informationAltTitle {\n    display: none;\n}\n\n*/\n\n/** affixing **/\n\n.top {\n    background-color: rgb(241, 241, 241);\n    top: 0;\n}\n\n.fluid {\n    position: relative;\n    top: 125px;\n}\n\n.semiFluid {\n    position: relative;\n    top: 125px;\n}\n\n.blockBottom {\n    position: fixed;\n    padding-top: 20px;\n}\n\n.bbLeft {\n    left: 102px;\n    padding: 35px 20px 0 20px;\n}\n\n.bbRight {\n    padding-right: 40px;\n}\n\n::ng-deep .idwMatrixLoading .backdrop, ::ng-deep .idwMatrixLoading .spinner-three-bounce {\n    z-index: 1999 !important;\n    width: 400px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
