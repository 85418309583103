import {ComponentPrimitive} from './componentPrimitive';
import {StateService} from '../../../core/services/state/state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

export abstract class ComponentList extends ComponentPrimitive {
    protected constructor(
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translateService,
            router,
            deviceService
        );
    }
}
