import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomizableFormsModule} from './customizable-forms/customizable-forms.module';
import {MainListModule} from './main-list/main-list.module';
import {GraphicsModule} from './graphics/graphics.module';
import {NgenioFormsModule} from './forms/ngenio-forms.module';
import {InfopanelComponent} from './components/infopanel/infopanel.component';
import {AlertComponent} from './alert/components/alert.component';
import {ApiAdministrationService} from '../administration/services/api-administration.service';
import {ApiPeopleService} from '../people/services/api-people.service';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AlertService} from './alert/services/alert.service';
import { LocalLoaderComponent } from './components/local-loader/local-loader.component';
import { UnaccessibleTestsModalComponent } from './components/unaccessible-tests-modal/unaccessible-tests-modal.component';
import { FormsModule } from '@angular/forms';
// import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { NewsComponent } from './news/components/news/news.component';
import { NewsIconComponent } from './news/components/news-icon/news-icon.component';
import { MobileNgNavIconComponent } from './mobile-ng-nav-icon/mobile-ng-nav-icon.component';
import { NavButtonComponent } from './components/nav-button/nav-button.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MainListModule,
        NgenioFormsModule,
        GraphicsModule,
        TooltipModule.forRoot(),
        CustomizableFormsModule,
        FormsModule,
        // MatCarouselModule,
        TranslateModule
    ],
    exports: [
        MainListModule,
        CustomizableFormsModule,
        GraphicsModule,
        InfopanelComponent,
        AlertComponent,
        NewsComponent,
        LocalLoaderComponent,
        UnaccessibleTestsModalComponent,
        NewsIconComponent,
        MobileNgNavIconComponent,
        NavButtonComponent,
        CarouselComponent
    ],
    declarations: [ InfopanelComponent, AlertComponent, LocalLoaderComponent, UnaccessibleTestsModalComponent, NewsComponent, NewsIconComponent, MobileNgNavIconComponent, NavButtonComponent, CarouselComponent],
    providers: [ ApiAdministrationService, ApiPeopleService, AlertService ]
})
export class SharedModule {
}
