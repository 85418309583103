// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trait {
  border: 1px solid #AAA;
  background-color: #EEE;
}

.prb {
  border: 1px solid #AAA;
  background-color: #EEE;
}

.profiler {
  border: 1px solid #AAA;
  background-color: #EEE;
}

.ddborder {
  border: 1px solid #EEE;
}

.graphs {
  margin: 10px 5px;
}

.graphsPad {
  padding: 5px;
}

.truncate {
  margin: 0;
  padding: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameGb {
  max-width: 55%;
}

.jobTitle {
  max-width: 70%;
}

#button {
  margin-top: 15px;
  padding: 7px;
  background-color: #666;
  font-weight: bold;
  color: #FFF;
  width: 100px;
  text-align: center;
}

#button:hover {
  background-color: #999;
}

.graphExtraPad {
  padding-left: 20px;
  padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/graphboards/components/web/graphboards-report/graphboards-report.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,SAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".trait {\n    border: 1px solid #AAA;\n    background-color: #EEE;\n}\n\n.prb {\n    border: 1px solid #AAA;\n    background-color: #EEE;\n}\n\n.profiler {\n    border: 1px solid #AAA;\n    background-color: #EEE;\n}\n\n.ddborder {\n    border: 1px solid #EEE\n}\n\n.graphs {\n    margin: 10px 5px;\n}\n\n.graphsPad {\n    padding: 5px;\n}\n\n.truncate {\n    margin: 0;\n    padding: 1px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.nameGb {\n    max-width: 55%;\n}\n\n.jobTitle {\n    max-width: 70%;\n}\n\n#button {\n    margin-top: 15px;\n    padding: 7px;\n    background-color: #666;\n    font-weight: bold;\n    color: #FFF;\n    width: 100px;\n    text-align: center;\n}\n\n#button:hover {\n    background-color: #999;\n}\n\n.graphExtraPad {\n    padding-left: 20px;\n    padding-right: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
