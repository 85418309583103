// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbuttons {
  cursor: pointer;
  color: #999;
}

.hasNoSuccessor, .hasNoPredecessor {
  color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/graphics/job-satisfactions-team-graphs/job-satisfactions-team-graphs.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".navbuttons {\n    cursor: pointer;\n    color: #999;\n}\n\n.hasNoSuccessor, .hasNoPredecessor {\n    color: #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
