import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {GraphicsModule} from '../graphics/graphics.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainListComponent} from './components/web/main-list/main-list.component';
import {MainListSearchBarComponent} from './components/web/main-list-search-bar/main-list-search-bar.component';
import {MainListSearchFiltersComponent} from './components/web/main-list-search-filters/main-list-search-filters.component';
import {MatButtonModule} from '@angular/material/button';
import {ListFilterPipe} from '../pipes/list-filter.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {MobileMainListComponent} from './components/mobile/mobile-main-list/mobile-main-list.component';
import {MobileMainListSearchBarComponent} from './components/mobile/mobile-main-list-search-bar/mobile-main-list-search-bar.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        GraphicsModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        TypeaheadModule,
        DragDropModule,
        TooltipModule.forRoot()
    ],
    exports: [MainListComponent, MainListSearchBarComponent, MainListSearchFiltersComponent, InfiniteScrollModule, MobileMainListComponent, MobileMainListSearchBarComponent],
    declarations: [MainListComponent, MainListSearchBarComponent, MainListSearchFiltersComponent, ListFilterPipe, MobileMainListComponent, MobileMainListSearchBarComponent],
    providers   : []
})
export class MainListModule {
}
