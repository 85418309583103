import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService} from '../../../../../shared/alert/services/alert.service';
import {StateService} from '../../../../../core/services/state/state.service';
import {TranslateService} from '@ngx-translate/core';
import {PermissionsService} from '../../../../../shared/permissions/permissions.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UserService} from '../../../../../core/services/user/user.service';
import {TextHelper} from '../../../../../shared/misc/text.helper';
import {take, takeUntil} from 'rxjs/operators';
import {ComponentSidebar} from '../../../../../shared/classes/components/component_sidebar';
import * as _ from 'lodash';
import {JobSatisfactionsListService} from '../../../services/job-satisfactions-list.service';
import {JobSatisfactionsModalHandlingService} from '../../../services/job-satisfactions-modal-handling.service';
import {ApiJobSatisfactionsService} from '../../../services/api-job-satisfactions.service';
import {JobSatisfactionsAnalysisService} from '../../../services/job-satisfactions-analysis.service';
import * as moment from 'moment';

@Component({
    selector: 'app-job-satisfactions-sidebar',
    templateUrl: './job-satisfactions-sidebar.component.html',
    styleUrls: ['./job-satisfactions-sidebar.component.scss']
})
export class JobSatisfactionsSidebarComponent extends ComponentSidebar implements OnInit, OnChanges, OnDestroy {

    @Input() width = '';
    @Input() config: any;
    @Input() isLeftAndNotTop = true;
    @Input() tabletMode = false;

    currentLang: string;

    @Output() sortResults = new EventEmitter;
    @Output() closeSidebar = new EventEmitter;

    canDelete = false;
    titleSep: string;

    nameListing = {
        'green': [],
        'yellow': [],
        'red': [],
        'grey': [],
    };

    redoPcrListing = {
        'green': [],
        'yellow': [],
        'red': [],
        'grey': [],
    };

    onDestroy$: Subject<null> = new Subject();

    private subscriptions = new Subscription();

    countAndOver: any = {
        'green': '0',
        'yellow': '0',
        'red': '0',
        'grey': '0',
    };

    maxPerPage = 7;

    constructor(public jobSatisfaction: JobSatisfactionsAnalysisService,
                public jobSatisfactionListService: JobSatisfactionsListService,
                public jobSatisfactionModalHandlingService: JobSatisfactionsModalHandlingService,
                protected router: Router,
                private location: Location,
                protected route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,
                private apiJobSatisfactionService: ApiJobSatisfactionsService,
                private alertService: AlertService,
                public stateService: StateService,
                protected translateService: TranslateService,
                private permissionsService: PermissionsService,
                protected deviceService: DeviceDetectorService,
                public user: UserService
    ) {
        super(stateService, translateService, router, deviceService);
        this.stateService.jobSatisfaction.stateChanged$.subscribe(
            () => {
                this.sortParticipantsByColor();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    ngOnInit() {
        this.canDelete = this.permissionsService.canDelete();
        this.titleSep = TextHelper.checkTitleSep(this.translateService.currentLang);

        this.state.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    sortParticipantsByColor() {
        let count = {
            'green': 0,
            'yellow': 0,
            'red': 0,
            'grey': 0,
            'total': 0
        };

        let nameListing = {
            'green': [],
            'yellow': [],
            'red': [],
            'grey': [],
        };

        let redoPcrListing = {
            'green': [],
            'yellow': [],
            'red': [],
            'grey': [],
        };

        if (this.jobSatisfaction.records) {
            this.jobSatisfaction.records.forEach(function (record, index) {
                if (record.jobSatisfactionScore === 2) {
                    nameListing.green.push(record.firstName + ' ' + record.lastName);
                    redoPcrListing.green.push(this.redoPcr(record));
                    count['green']++;
                    count['total']++;
                } else if (record.jobSatisfactionScore === 1) {
                    nameListing.yellow.push(record.firstName + ' ' + record.lastName);
                    redoPcrListing.yellow.push(this.redoPcr(record));
                    count['yellow']++;
                    count['total']++;
                } else if (record.jobSatisfactionScore === 0) {
                    nameListing.red.push(record.firstName + ' ' + record.lastName);
                    redoPcrListing.red.push(this.redoPcr(record));
                    count['red']++;
                    count['total']++;
                } else {
                    nameListing.grey.push(record.firstName + ' ' + record.lastName);
                    redoPcrListing.grey.push(null);
                    count['grey']++;
                    count['total']++;
                }
            }, this);
        }

        this.nameListing = nameListing;
        this.redoPcrListing = redoPcrListing;

        this.countAndOver = {
            'green': (count['green'] !== 0) ? count['green'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'yellow': (count['yellow'] !== 0) ? count['yellow'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'red': (count['red'] !== 0) ? count['red'] + '/' + count['total'] : '0'  + '/' + count['total'],
            'grey': (count['grey'] !== 0) ? count['grey'] + '/' + count['total'] : '0'  + '/' + count['total']
        };
    }

    scrollToView(name) {
        name = name.replace(' ', '');
        let el = document.getElementById(name);
        if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    redoPcr(record): any {
        let date = (record['prb'][0] && record['prb'][0]['date']) ? record['prb'][0]['date'] : null;
        // console.log(record['lastName'], date);
        if (date !== null) {
            let today = new Date();
            let pcrDate = new Date(date);
            let diffDate = (today.getTime() - pcrDate.getTime()) / 86400000;
            if (diffDate >= 120) {
                return 2;
            } else if (diffDate >= 60) {
                return 1;
            }
            return 0;
        }
        return false;
    }

    ngOnChanges(changes) {
        this.sortParticipantsByColor();
        // prevent refresh upon change of width
        let keys = Object.keys(changes);
        let firstKey = keys[0];
        if (keys.length === 1 && firstKey === 'width') {
            return;
        }
        this.currentLang = this.translateService.currentLang;
        if (!this.isLeftAndNotTop) {
            this.width = null;
        }
        this.jobSatisfactionListService.checkModuleUrl();
        this.changeDetectorRef.markForCheck();
    }


    onResize(event) {
        this.informationVisibility = (event.target.innerHeight < 725);
    }

    sortResultsSidebar(event) {
        this.sortResults.emit(event);
    }

    modStatus(action, act = null) {
        let p = [];
        let h1 = [];
        let pAction;
        let paragraph;
        if (action === 'archive') {
            if (act) {
                p[0] = this.translateService.instant('analysis.jobSatisfaction.archived');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[0] = this.translateService.instant('commons.archiving');
                h1[1] = this.translateService.instant('commons.archivingError');
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });

                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            } else {
                p[0] = this.translateService.instant('analysis.jobSatisfaction.reactivated');
                p[1] = this.translateService.instant('commons.tryAgain');
                h1[0] = this.translateService.instant('commons.reactivation');
                h1[1] = this.translateService.instant('commons.reactError');
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                    action: pAction,
                    total: 1,
                    plural: ''
                });

                const txt = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txt);
            }
        } else if (action === 'delete') {
            p[0] = this.translateService.instant('analysis.commons.jobSatisfactionDeleted', {
                total: 1,
                plural: ''
            });
            h1[0] = this.translateService.instant('analysis.commons.jobSatisfactionDeletion');
            p[1] = this.translateService.instant('commons.tryAgain');
            h1[1] = this.translateService.instant('commons.wasError');

            pAction = this.translateService.instant('commons.deletionAction');
            paragraph = this.translateService.instant('analysis.jobSatisfaction.actionConfirmation', {
                action: pAction,
                total: 1,
                plural: ''
            });
            const txt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txt);
        }
        this.alertService.nextAction$.subscribe(
            res => {
                if (res === 'confirm' && action) {
                    if (action === 'delete') {
                        this.apiJobSatisfactionService.deleteJobSatisfaction([this.jobSatisfaction.id]).pipe(take(1)).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (this.config === 'list') {
                                    this.jobSatisfaction.resetItemToDisplay(this.specificState());
                                }
                                this.jobSatisfactionListService.getJobSatisfactionList(true);
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                                this.changeDetectorRef.markForCheck();
                            }
                        );
                    } else if (action) {
                        this.apiJobSatisfactionService.putJobSatisfaction([this.jobSatisfaction.id], {archive: act}).subscribe(
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[0],
                                    texth1: h1[0],
                                    position: 'top-right',
                                    type: 'green'
                                });
                                if (this.config === 'list') {
                                    this.jobSatisfaction.setItemToDisplayWithId(this.jobSatisfaction.id, this.specificState());
                                    if (this.smallSize) {
                                        this.switchOffPanelAction();
                                    }
                                } else {
                                    this.goToList();
                                }
                                this.jobSatisfactionListService.getJobSatisfactionList(true);
                                this.changeDetectorRef.markForCheck();
                            },
                            () => {
                                this.alertService.setConfig({
                                    paragraph: p[1],
                                    texth1: h1[1],
                                    position: 'center-center',
                                    type: 'red'
                                });
                                this.changeDetectorRef.markForCheck();
                            }
                        );
                    }
                    action = undefined;
                }
            }
        );
        return;
    }

    switchOffPanelAction() {
        this.closeSidebar.emit(true);
    }

    openEditModal() {
        this.jobSatisfactionModalHandlingService.openJobSatisfactionModal({'status': 'edit'});
    }

    openPdfModal() {
        if (this.jobSatisfaction.records && this.jobSatisfaction.records.length > 0) {
            this.jobSatisfactionModalHandlingService.openJobSatisfactionPdfModal();
        }
    }

    get totalParticipants(): number {
        return (
            this.countAndOver['green'] +
            this.countAndOver['yellow'] +
            this.countAndOver['red'] +
            this.countAndOver['grey']
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
