// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlineOverlay {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.65;
}

.noneTitle {
  position: absolute;
  top: 47%;
  left: 0;
  width: 100%;
  font-size: 4rem;
  text-align: center;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis/idw-matrices/components/web/idw-matrix-report/idw-matrix-report.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,OAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".outlineOverlay {\n    position: absolute;\n    top: 0px;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: white;\n    opacity: 0.65;\n}\n\n.noneTitle {\n    position: absolute;\n    top: 47%;\n    left: 0;\n    width: 100%;\n    font-size: 4rem;\n    text-align: center;\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
