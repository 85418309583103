import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-archives-people-list',
  templateUrl: './mobile-archives-people-list.component.html',
  styleUrls: ['./mobile-archives-people-list.component.scss']
})
export class MobileArchivesPeopleListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
