import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {ApiPeopleService} from '../../../people/services/api-people.service';
import {ApiJobsService} from '../../../jobs/services/api-jobs.service';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {PersonService} from '../../../people/services/person.service';
import {ComponentPrimitive} from '../../classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-infopanel',
    templateUrl: './infopanel.component.html',
    styleUrls: ['./infopanel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfopanelComponent extends ComponentPrimitive implements OnInit, OnDestroy {

    @Input() width = '';

    allData = [];
    allDataFinal = null;
    lastRequests: any;
    lastCompleted: any;
    lastJobs: any;
    nonActivated: any;
    visibility = 'lastCompleted';
    todo: any = 0;
    eStartDate: string;

    private subcriptions = new Subscription();

    constructor(protected stateService: StateService,
                protected router: Router,
                private apiPeopleService: ApiPeopleService,
                private apiJobsService: ApiJobsService,
                public person: PersonService,
                public translateService: TranslateService,
                private cd: ChangeDetectorRef,
                protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);
        const d = new Date();
        this.eStartDate = d.getFullYear() + '-01-01';
    }

    ngOnInit(): void {
        this.fetchNews();
        // this.stateService.people.stateChanged$.subscribe(
        //    (res) => {
        //        if (res && this.recentActivitiesLive) {
        //            this.fetchNews();
        //        }
        //    }
        // );

        this.subcriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                if (this.recentActivitiesLive) {
                    this.fetchNews();
                }
            }
        ));
    }

    reinitData() {
        this.allData = [];
        this.allDataFinal = null;
        this.lastRequests = null;
        this.lastCompleted = null;
        this.lastJobs = null;
        this.nonActivated = null;
        this.visibility = 'lastCompleted';
        this.todo = 0;
    }

    fetchNews() {
        this.reinitData();
        this.todo = 0;
        this.getLastCompleted();
        this.getLastRequests();
        this.getLastJobs();
        this.getNonActivated();
    }

    prepareData() {
        this.allDataFinal = _.orderBy(this.allData, ['sortDate'], ['desc']);
        this.allDataFinal.splice(11);
        this.cd.markForCheck();
    }

    produceDate(date) {
        if (date) {
            let dateArr = date.substring(0, 10).split('-');
            if (this.translateService.currentLang === 'fr') {
                return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
            }
            if (this.translateService.currentLang === 'en') {
                return dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
            }
        }
        return '';
    }

    getLastRequests() {
        let dataParams = {
            evaluationStartDate: this.eStartDate,
            testStatuses: { mpo: 'NC' },
            limit: 5
        };
        let subLR = this.apiPeopleService.records([dataParams]).subscribe(
            (res) => {
                if (res) {
                    this.fillLastRecords(res, 'lastRequests');
                    this.concatAllData('lastRequests');
                    this.todo++;
                    if (this.todo === 4) { this.prepareData();
                    subLR.unsubscribe();
                    }
                }
            }
        );
    }

    getLastJobs() {
        let dataParams = {
            jobStartDate: this.eStartDate,
            limit: 5
        };
        let subLJ = this.apiJobsService.jobs([dataParams]).subscribe(
            (res) => {
                if (res) {
                    this.fillLastRecords(res, 'lastJobs');
                    this.concatAllData('lastJobs');
                    this.todo++;
                    if (this.todo === 4) { this.prepareData();
                    subLJ.unsubscribe();
                    }
                }
            }
        );
    }

    fillLastRecords(records, elType) {
        if (records) {
            let lastRecords = Object.assign({}, records);
            lastRecords = lastRecords.data;
            if (!this[elType]) {
                this[elType] = lastRecords;
            } else {
                // check for the duplicates
                lastRecords.forEach((r, index) => {
                    if (this[elType].find((el) => el.id === r.id)) {
                        lastRecords.splice(index, 1);
                    }
                });
                // concat the new records
                this[elType] = this[elType].concat(lastRecords);
            }
            this[elType] = _.map(this[elType], function(element) {
                return _.extend({}, element, {type: elType});
            });
        }
    }

    concatAllData(elType) {
        if (!this.allData.length) {
            this.allData = this.allData.concat(this[elType]);
        } else if (this[elType]) {
            this[elType].forEach(rd => {
                if (this.allData.find((el) => el.id === rd.id) === undefined) {
                    this.allData.push(rd);
                }
            });
        }
    }

    getLastCompleted() {
        let dataParams = {
            evaluationStartDate: this.eStartDate,
            testStatuses: { mpo: 'C' },
            limit: 5
        };
        let subC = this.apiPeopleService.records([dataParams]).subscribe(
            (res) => {
                if (res) {
                    this.fillLastRecords(res, 'lastCompleted');
                    this.concatAllData('lastCompleted');
                    this.todo++;
                    if (this.todo === 4) { this.prepareData();
                    subC.unsubscribe();
                    }
                }
            }
        );
    }

    getNonActivated() {
        let dataParams = {
            evaluationStartDate: this.eStartDate,
            testStatuses: { mpo: 'H' },
            limit: 5
        };
        let subNA = this.apiPeopleService.records([dataParams]).subscribe(
            (res) => {
                if (res) {
                    this.fillLastRecords(res, 'nonActivated');
                    this.concatAllData('nonActivated');
                    this.todo++;
                    if (this.todo === 4) { this.prepareData();
                    subNA.unsubscribe();
                    }
                }
            }
        );
    }

    goToJob(id: string) {
        this.stateService.navigate(
            this.router,
            'jobs/report/' + id + '/mpo',
            'setReport',
            {jobToDisplayId: id, reportType: 'mpo', display: 'report'},
            'jobs'
        );
    }

    goToPerson(id: string) {
        this.person.setPersonToDisplayWithId(id, 'navigate');
    }

    activateSidePanel(id: string) {
        this.person.setPersonToDisplayWithId(id, 'forceSidebarToReport');
    }

    ngOnDestroy(): void {
        this.subcriptions.unsubscribe();
    }

    get recentActivitiesLive() {
        return (this.stateService[this.specificState()].sideBarStatus === 'recentActivities');
    }

}
