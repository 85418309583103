import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {TalentsDefinitions} from '../../../../shared/talents/talentsDefinitions';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import * as _ from 'lodash';
import {StateService} from '../../../../core/services/state/state.service';
import {Subscription} from 'rxjs';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-people-report-talents',
    templateUrl: './people-report-talents.component.html',
    styleUrls: ['./people-report-talents.component.scss']
})
export class PeopleReportTalentsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() small = false;
    @Input() mobile = false;
    @Input() hideGraph = false;

    @ViewChild('talentsReportWrap') talentsReportWrap;

    orderedScores;
    orderPres;
    jobCompetencies;
    isLandscape = false;
    graphWrapperWidth = '95%';

    report;
    talents;
    interviewQuestions;

    jobName = null;
    titleSep: string;
    dateFormat: string;

    private subscriptions = new Subscription();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        private apiJobs: ApiJobsService,
        private stateService: StateService,
        private testAndReportHandlingService: TestAndReportHandlingService,
        public person: PersonService,
        private router: Router) {

        this.talents = new TalentsDefinitions().talents;
        this.interviewQuestions = new TalentsDefinitions().interviewQuestions;
    }

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.subscriptions.add(this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            }
        }));

        this.getTalentsReport();

        this.stateService.people.stateChanged$.subscribe(
            res => {
                if (res && res.personId) {
                    this.getTalentsReport();
                }
                if (res && res.jobId) {
                    this.fetchJobName();
                }
                this.changeDetectorRef.markForCheck();
            }
        );

        this.translate.onLangChange.subscribe(
            () => {
                this.getTalentsReport();
            }
        );
    }

    getTalentsReport() {
        this.testAndReportHandlingService.getReport('talents', this.translate.currentLang).pipe(take(1))
            .subscribe(
                (report) => {
                    this.report = report;
                    this.orderPres = this.orderPresentation();
                    this.jobCompetencies = this.toArray(this.report.jobCompetencies);
                    this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
                    this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
                    this.changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    toArray(object) {
        let array = [];
        for (let element in object) {
            if (object.hasOwnProperty(element)) {
                array.push([element, object[element]]);
            }
        }
        return array;
    }

    ngOnChanges(changes) {
        if (this.mobile) {
            this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
            if (this.isLandscape) {
                this.graphWrapperWidth = '50%';
            }
            let thisObject = this;
            window.addEventListener('orientationchange', function() {
                thisObject.isLandscape = !thisObject.isLandscape;
                if (thisObject.isLandscape) {
                    thisObject.graphWrapperWidth = '50%';
                }
            });
        }

        this.testAndReportHandlingService.getReport('talents', this.translate.currentLang);

        if (this.person.jobId) {
            this.fetchJobName();
        }

        this.orderedScores = this.talentsOrderedScores;
        setTimeout(() => {
            this.orderedScores = this.talentsOrderedScores;
        }, 1000);

        this.changeDetectorRef.markForCheck();
    }

    onResize(event) {
        if (event) {
            this.orderedScores = this.talentsOrderedScores;
        }
    }

    orderPresentation() {
        let j = 0;
        let alphaTalents = [];
        for (let aptitude of this.report.texts.aptitudes.titles) {
            for (let index of this.report.texts.aptitudes.structure[j]) {
                alphaTalents.push({
                    title: this.report.texts.talents.titles[index - 1],
                    score: (this.person.talentsScore && this.person.talentsScore[0]) ? this.person.talentsScore[0][index - 1] : [],
                    text: this.report.texts.talents.description[index - 1]
                });
            }
            j++;
        }
        alphaTalents = _.uniqBy(alphaTalents, function (e) {
            return e.title;
        });
        alphaTalents = _.orderBy(alphaTalents, (talent) => {
            return _.deburr(talent.title);
        });
        let orderedTalents = [];
        let pair = [];
        for (let i = 0; i < 46; i++) {
            if (i % 2 === 0) {
                pair = [];
            }
            pair.push(alphaTalents[i]);
            if (i % 2 === 1) {
                orderedTalents.push(pair);
            }
        }
        return orderedTalents;
    }

    moveTo(fragment) {
        const element = document.querySelector('#' + fragment);
        if (element) {
            element.scrollIntoView(true);
        }
        return;
    }
    /**
     topFunction() {
        //this.router.navigateByUrl(window.location.href.substring(6) + '#top')
        //    .then(
        //        () =>
        //            this.router.navigateByUrl(window.location.href.substring(6))
        //    );
        let fullUrl = window.location.href.substring(6).split(';');
        let url = fullUrl[0].substr(1).split('/');
        url.shift();
        let parameters = {};
        for(let i=1; i<fullUrl.length; i++){
            let parameter = fullUrl[i].split('=');
            parameters[parameter[0]] = parameter[1];
        }
        console.log(parameters);
        this.router.navigate(url);
    }
     */

    public get maxColsNumber() {
        let wrapWidth = this.talentsReportWrap.nativeElement.offsetWidth;
        let colMax = Math.floor(wrapWidth / 400);
        if (colMax === 0) {
            colMax = 1;
        }
        return colMax;
    }

    get warnings(): any {
        if (
            this.report.info.evaluationWarnings.extremeScores ||
            this.report.info.evaluationWarnings.socialDesirability
        ) {
            return this.report.info.evaluationWarnings;
        }
        return false;
    }

    get talentsOrderedScores() {
        if (this.talentsReportWrap) {
            let colMax = this.maxColsNumber;
            let itemsMaxCol = Math.ceil(46 / colMax);

            let orderedScoresInit = [];
            let orderedScores = [];
            for (let i = 0; i < colMax; i++) {
                orderedScores.push([]);
            }

            for (let i in this.person.talentsScore[0]) {
                if (this.person.talentsScore[0].hasOwnProperty(i)) {
                    orderedScoresInit.push([i, this.person.talentsScore[0][i]]);
                }
            }
            orderedScoresInit.sort(function (a, b) {
                return a[1] > b[1] ? -1 : 1;
            });
            let currCol = 0;
            let numInCol = 0;
            for (let i = 0; i < 46; i++) {
                if (orderedScores[currCol]) {
                    orderedScores[currCol].push(orderedScoresInit[i]);
                    if (numInCol >= (itemsMaxCol - 1)) {
                        currCol++;
                        numInCol = -1;
                    }
                }
                numInCol++;
            }
            this.changeDetectorRef.markForCheck();
            return orderedScores;
        }
        return;
    }

    get reportHasJobCompetencies() {
        return !!(this.report && this.report.jobCompetencies && this.toArray(this.report.jobCompetencies).length !== 0);
    }

    fetchJobName() {
        this.subscriptions.add(this.apiJobs.job([this.person.jobId]).subscribe(
            (res) => {
                this.jobName = res.jobTitle;
                this.changeDetectorRef.markForCheck();
            },
            () => {
                this.jobName = undefined;
                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    fuseTalentName(talent) {
        if (talent.length > 1) {
            return talent.join(' ');
        }
        return talent[0];
    }

    ceil(number) {
        return Math.ceil(number);
    }

    get state(): any {
        return this.stateService.people;
    }

}
