// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alignCheck {
  width: 63%;
  float: left;
  text-align: right;
}

.alignRight {
  float: right;
}

.clear {
  clear: both;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/send-methods/mobile-send-methods/mobile-send-methods.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".alignCheck {\n    width: 63%;\n    float: left;\n    text-align: right;\n}\n\n.alignRight {\n    float: right;\n}\n\n.clear {\n    clear: both;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
