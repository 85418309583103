import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Content} from 'src/app/shared/classes/services/content';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AccountService} from './account.service';
import {ApiAdministrationService} from './api-administration.service';
import {AlertService} from '../../shared/alert/services/alert.service';
import {UserService} from './user.service';
import {AdminFilesModalComponent} from '../components/administration-files-modal/administration-files-modal.component';
import {PurchaseProductModalComponent} from '../components/administration-purchase-product-modal/administration-purchase-product-modal.component';
import {AdministrationDepartmentModalComponent} from '../components/administration-department-modal/administration-department-modal.component';
import {DeleteSubAccountModalComponent} from '../components/administration-delete-subaccount-modal/administration-delete-subaccount-modal.component';
import {PurchaseAdjustementModalComponent} from '../components/administration-purchase-adjustement-modal/administration-purchase-adjustement-modal.component';
import {MoveCreditModalComponent} from '../components/administration-move-credit-modal/administration-move-credit-modal.component';
import {Subscription} from 'rxjs';
import {
    AdministrationTransactionsFilterModalComponent
} from '../components/administration-transactions-filter-modal/administration-transactions-filter-modal.component';
import {
    AdministrationTransactionsEditModalComponent
} from '../components/administration-transactions-edit-modal/administration-transactions-edit-modal.component';

@Injectable({
    providedIn: 'root'
})
export class AdminModalHandlingService extends Content implements OnDestroy {
    userModalRef: BsModalRef;
    accountModalRef: BsModalRef;
    subAccountModalRef: BsModalRef;
    fileModalRef: BsModalRef;
    purchaseProductModalRef: BsModalRef;
    departmentModalRef: BsModalRef;
    deleteSubAccountModalRef: BsModalRef;
    purchaseAdjustmentModal: BsModalRef;
    moveCreditModalRef: BsModalRef;
    transactionsFilterRef: BsModalRef;
    transactionsEditRef: BsModalRef;

    private subscriptions = new Subscription();

    constructor(
        public clientAccount: AccountService,
        public clientUser: UserService,
        public alertService: AlertService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        private apiAdministrationService: ApiAdministrationService,
        private accountService: AccountService,
        protected stateService: StateService,
        private modalService: BsModalService
    ) {
        super(router, deviceService, stateService);
    }

    openFileModal(fileModalData: any, userId = null): void {
        let initialState;
        if (!userId) {
            initialState = {
                accountId: this.clientAccount.id,
                data: fileModalData
            };
        } else {
            initialState = {
                userId: userId
            };
        }
        this.fileModalRef = this.modalService.show(AdminFilesModalComponent, {initialState, backdrop: 'static'});
        this.fileModalRef.content.refresh.subscribe(res => {
            if (res.success) {
                if (!userId) {
                    this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
                } else {
                    this.clientUser.setUserToDisplayWithId(userId);
                }
            }
        });
    }

    openPurchaseProductsModal(selectedSubAccount = 0) {
        let accountToDisplay = this.clientAccount.accountToDisplay;
        accountToDisplay.selectedSubAccount = selectedSubAccount;
        const initialState = {
            account: accountToDisplay
        };
        this.purchaseProductModalRef = this.modalService.show(PurchaseProductModalComponent, {
            initialState,
            backdrop: 'static'
        });
        this.purchaseProductModalRef.content.editRefresh.subscribe(res => {
            if (res.success) {
                this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            }
        });
        this.purchaseProductModalRef.content.close.subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            this.accountService.productDescriptionChanged.next(true);
        });

    }

    openTransactionsFilterModal(initialState) {
        this.transactionsFilterRef = this.modalService.show(AdministrationTransactionsFilterModalComponent, {
            initialState,
            backdrop: 'static'
        });
    }

    openTransactionsEditModal(initialState) {
        this.transactionsEditRef = this.modalService.show(AdministrationTransactionsEditModalComponent, {
            initialState,
            backdrop: 'static'
        });
    }

    openDepartmentModal(departmentModalData = {}, action = 'add') {
        const initialState = {
            departmentModalAction: action,
            departmentModalData: departmentModalData,
            lang: this.translateService.currentLang
        };
        this.departmentModalRef = this.modalService.show(AdministrationDepartmentModalComponent, {
            initialState,
            backdrop: 'static'
        });
        this.departmentModalRef.content.departmentModResult.subscribe(res => {
            if (res) {
                this.processDepartmentModResult(res);
            }
        });
    }

    openAdjustmentCreditsModal() {
        const initialState = {
            account: this.clientAccount.accountToDisplay
        };
        this.purchaseAdjustmentModal = this.modalService.show(PurchaseAdjustementModalComponent, {
            initialState,
            backdrop: 'static'
        });
        this.purchaseAdjustmentModal.content.editRefresh.subscribe(res => {
            if (res.success) {
                this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            }
        });
        this.purchaseAdjustmentModal.content.close.subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            this.accountService.productDescriptionChanged.next(true);
        });
    }

    openTransferCreditsModal() {
        const initialState = {
            account: this.clientAccount.accountToDisplay
        };
        this.moveCreditModalRef = this.modalService.show(MoveCreditModalComponent, {initialState, backdrop: 'static'});
        this.moveCreditModalRef.content.editRefresh.subscribe(res => {
            if (res.success) {
                this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            }
        });
        this.moveCreditModalRef.content.close.subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
            this.accountService.productDescriptionChanged.next(true);
        });
    }

    processDepartmentModResult(event) {
        if (event.action === 'delete') {
            const txtDeleteConf = {
                paragraph: this.translateService.instant('admin.accountDetail.depDeletionConfirm'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(txtDeleteConf);
            this.subscriptions.add(this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm') {
                        this.deleteDepartment(event);
                    }
                }
            ));
        }
        if (event.action === 'add') {
            this.addDepartment(event);
        }

        if (event.action === 'edit') {
            this.editDepartment(event);
        }
    }

    addDepartment(data) {
        const params = {
            'fr': data['fr'],
            'en': data['en'],
            'accountId': this.clientAccount.id
        };

        this.subscriptions.add(this.apiAdministrationService.postDepartment(params).subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
        }));
    }

    editDepartment(data) {
        const params = {
            'fr': data['fr'],
            'en': data['en'],
            'clientId': this.clientAccount.id
        };

        this.subscriptions.add(this.apiAdministrationService.putDepartment([data.id], params).subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
        }));
    }

    deleteDepartment(data) {
        this.subscriptions.add(this.apiAdministrationService.deleteDepartment([data.id, this.clientAccount.id]).subscribe(() => {
            this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id);
        }));
    }

    deleteSubAccount(subAccountId) {
        const initialState = {
            account: this.clientAccount.accountToDisplay,
            subAccountId: subAccountId
        };
        this.deleteSubAccountModalRef = this.modalService.show(DeleteSubAccountModalComponent, {
            initialState,
            backdrop: 'static'
        });
        this.deleteSubAccountModalRef.content.close.subscribe(res => {
            if (res.isDeleted) {
                this.clientAccount.setAccountToDisplayWithId(this.clientAccount.id, 'admin', 'fullClient');
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
