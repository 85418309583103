import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ChangeDetectionStrategy} from '@angular/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiRightMatchesService} from '../../../services/api-right-matches.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';
import {RightMatch} from '../../../models/right-match.model';
import {Tools} from '../../../../../shared/misc/tools';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-right-matches-edit',
  templateUrl: './mobile-right-matches-edit.component.html',
  styleUrls: ['./mobile-right-matches-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileRightMatchesEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input() status;
    @Input() multiAction;

    @Output() close = new EventEmitter();

    @ViewChild('rightMatchNgForm') rightMatchNgForm;

    rightMatch: RightMatch;
    rightMatchForm: UntypedFormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    profilers: any;
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    public loading = false;

    headerText: string;

    submitted = false;

    private subscriptions = new Subscription();

    constructor(
        public rightMatchService: RightMatchAnalysisService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private router: Router,
        private stateService: StateService,
        private apiRightMatchesService: ApiRightMatchesService,
        private apiJobsService: ApiJobsService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.subscriptions.add(this.apiJobsService.jobs([{
            'hasScore': 1,
            'fields': 'id,jobTitle',
            'limit': 500,
            'isArchived': 0,
            'statuses[score]': 1,

            'order[date]': 'desc'
        }]).subscribe(
            (res) => {
                this.profilers = res.data;
                this.loading = false;
                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    ngOnChanges() {
        this.requestedBy = this.stateService.session.sessionData.userData.username;
        this.sessionStructure = this.stateService.session.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.rightMatch = Object.assign({}, new RightMatch());
            this.rightMatch.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.rightMatch = Object.assign({}, this.rightMatchService.itemToDisplay);
        }
        if (this.multiAction) {
            if (this.multiAction.ids.length !== 0) {
                this.headerText = (this.multiAction.ids.length === 1) ?
                    'Le candidat sélectionné précédemment sera ajouté à l\'analyse automatiquement' :
                    'Les candidats sélectionnés précédemment seront ajoutés à l\'analyse automatiquement';
            }
        }

        this.initForm();
    }

    formCheck(specificField = null) {
        if (this.rightMatchNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.rightMatchForm.controls[specificField], this.rightMatchNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.rightMatchForm.controls[field], this.rightMatchNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.submitted = false;

        let idProfiler = (this.rightMatch.profiler) ? this.rightMatch.profiler.id : '';
        if (this.rightMatch.profiler && typeof this.rightMatch.profiler === 'string') {
            idProfiler = this.rightMatch.profiler;
        }
        const departmentId = (this.rightMatch.department && this.rightMatch.department.length > 0)
            ? this.rightMatch.department[0].id
            : null;

        let subAccount = (this.stateService.session.sessionData.structure.principalSubAccount) ?
            this.stateService.session.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.rightMatch.subAccount;
        }

        this.rightMatchForm = this.fb.group({
            name: [this.rightMatch.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.rightMatch.consultant],
            profiler: [idProfiler],
            department: [departmentId]
        });
    }

    onSubmit(model: UntypedFormGroup) {
        this.submitted = true;
        if (model.valid) {
            // console.log(model.value);
            let data = model.value;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    saveNew(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiRightMatchesService.postRightMatch(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'records': this.multiAction.ids};
                    this.subscriptions.add(this.apiRightMatchesService.putRightMatch([res.id], data).subscribe(
                        () => {
                            this.rightMatchService.setItemToDisplayWithId(res.id);
                            this.stateService.rightMatch.stateChanged.next({
                                refreshComponent: true,
                                itemToDisplayId: res.id
                            });
                            this.onCancel();
                        }
                    ));
                } else {
                    this.rightMatchService.setItemToDisplayWithId(res.id);
                    this.stateService.rightMatch.stateChanged.next({
                        refreshComponent: true,
                        itemToDisplayId: res.id
                    });
                    this.onCancel();
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: RightMatch) {
        // call api to save the modification
        this.subscriptions.add(this.apiRightMatchesService.putRightMatch([this.rightMatch.id], data).subscribe(
            () => {
                this.rightMatchService.setItemToDisplayWithId(this.rightMatch.id);
                this.stateService.rightMatch.stateChanged.next({
                    refreshComponent: true,
                    itemToDisplayId: this.rightMatch.id
                });
                this.onCancel();
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    resetForm() {
        this.submitted = false;
        this.rightMatchForm.reset();
        this.rightMatchNgForm.submitted = false;
        this.initForm();
    }

    onCancel() {
        this.resetForm();
        this.close.emit(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
